.dashbord-banner {
  .banner-wrapper {
    overflow: hidden;

    & > div {
      margin-left: 0;
    }
  }

  .banner-card {
    width: 170.2px;
    min-width: 170.2px;
    margin: 1.5rem 0 1.5rem 0.5rem;
    padding: 0;
    background-color: #fff;
    border: 1px solid #dee2e6 !important;
    border-radius: 0.25rem !important;
    box-shadow: 0 .2rem .5rem rgba(0,0,0,.05);

    .spnimgbox {
      min-height: 110px;
    }

    .banner-image {
      height: 100%;
      width: 100%;
      max-width: 110px;
      max-height: 110px;
    }

    .product-text {
      line-height: 1.2em;

      p {
        margin-bottom: 0.5rem;
      }
      
      .price {
        font-size: 125%;
        font-weight: 700;
        color: #000;
      }
    }
    
    .shop-text {
      height: 69px;
      overflow: hidden;
      line-height: 1.2em;
    }
    
    .links {
      .fake-btn {
        background-color: rgb(102, 179, 50);
        text-transform: lowercase;
        font-variant: small-caps;
        font-weight: 600;
        letter-spacing: 0.05em;
        padding-top: 0.303rem;
        opacity: 1;
      }

      .link-p {
        color: rgb(102, 179, 50);
        cursor: default;
      }
    }
  }
}

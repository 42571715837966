html, html a, body {
  -webkit-font-smoothing: antialiased; }

a {
  transition: all 0.3s ease-in-out; }

body {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  color: #72777a;
  line-height: 1.5;
  letter-spacing: 0.2px;
  overflow-x: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.5; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: 300;
    color: #3d4142; }

p {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  line-height: 1.9; }

.lead {
  font-size: 18px; }

ul {
  margin-bottom: 0; }

a {
  color: #0f9aee; }
  a:hover, a:focus {
    text-decoration: none;
    color: #0c7bbe; }
  a:focus {
    outline: none; }
  a.text-gray:hover, a.text-gray:focus, a.text-gray.active {
    color: #313435 !important; }

:focus {
  outline: none; }

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.0625); }

.tootTipTopRight {
  position: absolute;
  right: 0px;
  top: 0px; }

.productimg {
  max-width: 42px;
  max-height: 42px; }

.lj-slider .pin {
  position: absolute;
  z-index: 1;
  width: 15px;
  height: 15px;
  -webkit-box-shadow: 0 0 1px 1px #fff, 0 0 1px 1px #aaa;
  box-shadow: 0 0 1px 1px #fff, 0 0 1px 1px #aaa;
  border-radius: 100%;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  cursor: pointer;
  margin-top: -6px;
  margin-left: -10px; }

.lj-slider.onlight {
  -webkit-box-shadow: 0 0 0 1px #aaa;
  box-shadow: 0 0 0 1px #aaa; }

.lj-slider {
  height: 10px; }

.slider {
  margin: 85px 0px 70px 0px;
  position: relative; }

.pinLabel .fa {
  padding: 5px; }

.pinLabel {
  display: block;
  width: 80px;
  text-align: center;
  margin-left: -20px;
  margin-top: 50px;
  border: 1px solid;
  background: white;
  word-break: keep-all;
  white-space: nowrap; }

.pinCnt:hover {
  z-index: 99999; }

.pinCnt {
  position: absolute;
  z-index: 99; }

.yours {
  z-index: 100; }

div.pin.a0,
span.a0 {
  background-color: rgba(75, 192, 130, 0.5);
  border: 1px solid #4bc082; }

div.pin.a1,
span.a1 {
  background-color: rgba(255, 159, 64, 0.5);
  border: 1px solid #ff9f40; }

div.pin.a2,
span.a2 {
  background-color: rgba(153, 102, 255, 0.5);
  border: 1px solid #9966ff; }

div.pin.a3,
span.a3 {
  background-color: rgba(255, 99, 132, 0.5);
  border: 1px solid #ff6384; }

.wrapLeggenda {
  margin-left: 10%; }

.leggenda {
  text-align: center;
  margin-left: 10px; }

.wrapLeggenda p {
  display: inline-block;
  margin-bottom: 0px; }

span.a0,
span.a1,
span.a2,
span.a3 {
  display: inline-block;
  width: 40px;
  height: 12px;
  margin-right: 5px; }

.speseSpedizione .pin p,
.sogliaGratis .pin p {
  display: none;
  opacity: 1;
  position: absolute;
  bottom: 65px;
  width: 242px;
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  -webkit-transition: all 1s ease;
  transition: all 1s;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 999; }

.speseSpedizione .pin:hover p,
.sogliaGratis .pin:hover p {
  display: inline-block;
  transition: all 1s; }

.lj-slider {
  height: 10px; }

.lj-slider .pin {
  width: 9px;
  height: 70px;
  margin-top: -30px;
  margin-left: 0px;
  border-radius: 3px; }

.sidebar {
  background-color: #fff;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all 0.2s ease;
  width: 280px;
  z-index: 1000; }
  .sidebar ul {
    list-style-type: none; }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .sidebar {
      width: 70px; }
      .sidebar .logoimg {
        display: none; }
      .sidebar .logoimgmini {
        display: block; }
      .sidebar .sidebar-inner .sidebar-logo {
        border-bottom: 1px solid transparent;
        padding: 0; }
        .sidebar .sidebar-inner .sidebar-logo a .logo {
          background-position: center center;
          width: 70px; }
      .sidebar .sidebar-inner .sidebar-menu {
        overflow-x: hidden; }
        .sidebar .sidebar-inner .sidebar-menu > li > a .title {
          display: none; }
        .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
          opacity: 0; }
        .sidebar .sidebar-inner .sidebar-menu li.dropdown.open ul.dropdown-menu {
          display: none !important; }
      .sidebar:hover {
        width: 280px; }
        .sidebar:hover .sidebar-inner .sidebar-logo {
          border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
          padding: 0 20px; }
        .sidebar:hover .sidebar-inner .sidebar-menu > li > a .title {
          display: inline-block; }
        .sidebar:hover .sidebar-inner .sidebar-menu li.dropdown .arrow {
          opacity: 1; }
        .sidebar:hover .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
          display: block !important; } }
  @media screen and (max-width: 991px) {
    .sidebar {
      left: -280px;
      width: calc(280px - 30px); } }

.logominicnt {
  display: none; }

.is-collapsed .logominicnt {
  display: block; }

.logoimgmini {
  width: 65px;
  padding: 10px;
  display: none; }

.logoimg {
  width: 250px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 13px; }

/*.is-collapsed .logoimg {
  display:none;
}
.is-collapsed .logoimgmini {
  display:block;
} */
.sidebar-inner {
  position: relative;
  height: 100%; }

.sidebar-logo {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  line-height: 0;
  padding: 0 5px;
  transition: all 0.2s ease; }
  .sidebar-logo a {
    display: inline-block;
    width: 100%; }
    .sidebar-logo a .logo {
      background-position: center left;
      background-repeat: no-repeat;
      display: inline-block;
      min-height: calc(65px - 1px);
      width: 100%;
      width: 70px; }
    .sidebar-logo a .logo-text {
      color: #313435; }
  .sidebar-logo .mobile-toggle {
    display: none;
    float: right;
    font-size: 18px;
    line-height: calc(65px - 1px); }
    .sidebar-logo .mobile-toggle a {
      color: #72777a; }
    @media screen and (max-width: 991px) {
      .sidebar-logo .mobile-toggle {
        display: inline-block; } }
    @media screen and (min-width: 992px) and (max-width: 1439px) {
      .sidebar-logo .mobile-toggle {
        display: none; } }

.sidebar-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  height: calc(100vh - 65px);
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative; }
  .sidebar-menu::before, .sidebar-menu::after {
    content: ' ';
    display: table; }
  .sidebar-menu::after {
    clear: both; }
  .sidebar-menu .dropdown-toggle::after {
    display: none; }
  .sidebar-menu .sidebar-link.active::before {
    background: #2196f3;
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    left: -4px;
    position: absolute;
    top: calc(50% - 4px);
    width: 8px; }
  .sidebar-menu li {
    position: relative; }
    .sidebar-menu li.dropdown .arrow {
      font-size: 10px;
      line-height: 40px;
      position: absolute;
      right: 30px;
      transition: all 0.05s ease-in; }
      @media screen and (max-width: 991px) {
        .sidebar-menu li.dropdown .arrow {
          right: 25px; } }
    .sidebar-menu li.dropdown.open > a {
      color: #313435; }
      .sidebar-menu li.dropdown.open > a .icon-holder {
        color: #0f9aee; }
      .sidebar-menu li.dropdown.open > a > .arrow {
        transform: rotate(90deg); }
    .sidebar-menu li.dropdown.open > .dropdown-menu {
      display: block; }
      .sidebar-menu li.dropdown.open > .dropdown-menu .dropdown-menu {
        padding-left: 20px; }
      .sidebar-menu li.dropdown.open > .dropdown-menu .arrow {
        line-height: 25px; }
    .sidebar-menu li a {
      color: #72777a;
      transition: all 0.3s ease; }
      .sidebar-menu li a:hover, .sidebar-menu li a:focus {
        color: #313435;
        text-decoration: none; }
        .sidebar-menu li a:hover .icon-holder, .sidebar-menu li a:focus .icon-holder {
          color: #0f9aee; }
  .sidebar-menu > li.dropdown ul.dropdown-menu {
    background-color: transparent;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    float: none;
    padding-left: 50px;
    padding-top: 0;
    position: relative;
    width: 100%; }
    .sidebar-menu > li.dropdown ul.dropdown-menu > li > a {
      display: block;
      padding: 10px 15px; }
      .sidebar-menu > li.dropdown ul.dropdown-menu > li > a:hover, .sidebar-menu > li.dropdown ul.dropdown-menu > li > a:focus {
        background-color: transparent;
        color: #313435; }
    .sidebar-menu > li.dropdown ul.dropdown-menu > li.active a {
      color: #313435; }
  .sidebar-menu > li > a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    padding: 5px 15px;
    position: relative;
    white-space: nowrap; }
    .sidebar-menu > li > a .icon-holder {
      border-radius: 6px;
      display: inline-block;
      font-size: 17px;
      height: 35px;
      left: 0;
      line-height: 35px;
      margin-right: 14px;
      position: relative;
      text-align: center;
      transition: all 0.3s ease;
      width: 35px; }

.is-collapsed .logoimg {
  display: none; }

.is-collapsed .logoimgmini {
  display: block; }

@media screen and (min-width: 1440px) {
  .is-collapsed .sidebar {
    width: 70px; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-logo {
      border-bottom: 1px solid transparent;
      padding: 0; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu {
      overflow-x: hidden; }
      .is-collapsed .sidebar .sidebar-inner .sidebar-menu > li > a .title {
        display: none; }
      .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
        opacity: 0; }
      .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown.open ul.dropdown-menu {
        display: none !important; }
    .is-collapsed .sidebar:hover {
      width: 280px; }
      .is-collapsed .sidebar:hover .logoimg {
        width: 250px;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 13px; }
      .is-collapsed .sidebar:hover .sidebar-inner .sidebar-logo {
        border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
        padding: 0 5px; }
      .is-collapsed .sidebar:hover .sidebar-inner .logoimgmini {
        display: none; }
      .is-collapsed .sidebar:hover .sidebar-inner .logoimg {
        display: block; }
      .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu > li > a .title {
        display: inline-block; }
      .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu li.dropdown .arrow {
        opacity: 1; }
      .is-collapsed .sidebar:hover .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
        display: block !important; } }

@media screen and (min-width: 992px) and (max-width: 1439px) {
  .is-collapsed .sidebar {
    width: 280px; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-logo {
      border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
      padding: 0 20px; }
      .is-collapsed .sidebar .sidebar-inner .sidebar-logo > a .logo {
        background-position: center left;
        width: 150px; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu > li > a .title {
      display: inline-block; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.dropdown .arrow {
      opacity: 1; }
    .is-collapsed .sidebar .sidebar-inner .sidebar-menu li.open > ul.dropdown-menu {
      display: block !important; } }

@media screen and (max-width: 991px) {
  .is-collapsed .sidebar {
    left: 0; } }

.header {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  display: block;
  margin-bottom: 0;
  padding: 0;
  position: fixed;
  transition: all 0.2s ease;
  width: calc(100% - 280px);
  z-index: 800; }
  @media screen and (max-width: 991px) {
    .header {
      width: 100%; } }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .header {
      width: calc(100% - 70px); } }
  .header .header-container {
    height: 65px; }
    .header .header-container::before, .header .header-container::after {
      content: ' ';
      display: table; }
    .header .header-container::after {
      clear: both; }
    .header .header-container .nav-left,
    .header .header-container .nav-right {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      position: relative; }
      .header .header-container .nav-left > li,
      .header .header-container .nav-right > li {
        float: left; }
        .header .header-container .nav-left > li > a,
        .header .header-container .nav-right > li > a {
          color: #72777a;
          display: block;
          line-height: 65px;
          min-height: 65px;
          padding: 0 15px;
          transition: all 0.2s ease-in-out; }
          .header .header-container .nav-left > li > a i,
          .header .header-container .nav-right > li > a i {
            font-size: 17px; }
          .header .header-container .nav-left > li > a:hover, .header .header-container .nav-left > li > a:focus,
          .header .header-container .nav-right > li > a:hover,
          .header .header-container .nav-right > li > a:focus {
            color: #313435;
            text-decoration: none; }
          @media screen and (max-width: 991px) {
            .header .header-container .nav-left > li > a,
            .header .header-container .nav-right > li > a {
              padding: 0 15px; } }
      .header .header-container .nav-left .notifications,
      .header .header-container .nav-right .notifications {
        position: relative; }
        .header .header-container .nav-left .notifications .counter,
        .header .header-container .nav-right .notifications .counter {
          background-color: #ff3c7e;
          border-radius: 50px;
          color: #fff;
          font-size: 10px;
          line-height: 1;
          padding: 3px 5.5px;
          position: absolute;
          right: 6px;
          top: 12px; }
        .header .header-container .nav-left .notifications .dropdown-menu,
        .header .header-container .nav-right .notifications .dropdown-menu {
          min-width: 350px;
          padding: 0; }
          @media screen and (max-width: 767px) {
            .header .header-container .nav-left .notifications .dropdown-menu,
            .header .header-container .nav-right .notifications .dropdown-menu {
              max-width: 300px; } }
    .header .header-container .dropdown-menu {
      display: block;
      margin: 0;
      transform-origin: top right;
      transform: scale(0, 0);
      transition: transform 0.15s ease-out; }
      .header .header-container .dropdown-menu .divider {
        border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
        height: 1px;
        overflow: hidden; }
      .header .header-container .dropdown-menu > li > a {
        transition: all 0.2s ease-out; }
    .header .header-container .show .dropdown-menu {
      transform: scale(1, 1); }
    @media screen and (max-width: 767px) {
      .header .header-container .tuonegozio {
        display: none; } }
    .header .header-container .dpscnt {
      margin-left: 10px;
      margin-top: 16px; }
      @media screen and (max-width: 424px) {
        .header .header-container .dpscnt {
          margin-top: -16px; } }
    .header .header-container .searchbox {
      float: right;
      width: 80px;
      margin-left: 5px;
      margin-top: 2px; }
    .header .header-container .maindatepicker {
      float: left;
      display: block; }
      @media screen and (min-width: 768px) {
        .header .header-container .maindatepicker {
          margin-left: 20px; } }
    .header .header-container .nav-left {
      width: 80%; }
      @media screen and (min-width: 768px) {
        .header .header-container .nav-left {
          float: left; } }
      @media screen and (min-width: 768px) {
        .header .header-container .nav-left {
          margin-left: 15px; } }
    .header .header-container .nav-right {
      display: inline-block;
      float: right; }
      @media screen and (max-width: 614px) {
        .header .header-container .nav-right {
          float: none; } }
      .header .header-container .nav-right .dropdown-menu {
        left: auto;
        right: 0; }
        .header .header-container .nav-right .dropdown-menu > li {
          width: 100%; }
          .header .header-container .nav-right .dropdown-menu > li > a {
            line-height: 1.5;
            min-height: auto;
            padding: 10px 15px; }
  .header .search-box .search-icon-close {
    display: none; }
  .header .search-box.active .search-icon {
    display: none; }
  .header .search-box.active .search-icon-close {
    display: inline-block; }
  .header .search-input {
    display: none; }
    .header .search-input.active {
      display: inline-block; }
    .header .search-input input {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      font-size: 18px;
      height: 40px;
      margin-top: 12px;
      outline: none;
      padding: 5px; }
      @media screen and (max-width: 767px) {
        .header .search-input input {
          width: 85px; } }
      .header .search-input input::-webkit-input-placeholder {
        color: #a6aaac;
        font-style: italic; }
      .header .search-input input:-moz-placeholder {
        color: #a6aaac;
        font-style: italic; }
      .header .search-input input::-moz-placeholder {
        color: #a6aaac;
        font-style: italic; }
      .header .search-input input:-ms-input-placeholder {
        color: #a6aaac;
        font-style: italic; }

.is-collapsed .header {
  width: calc(100% - 70px); }
  @media screen and (max-width: 991px) {
    .is-collapsed .header {
      width: 100%; } }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .is-collapsed .header {
      width: calc(100% - 280px); } }

.page-container {
  min-height: 100vh;
  padding-left: 280px;
  transition: all 0.2s ease; }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .page-container {
      padding-left: 70px; } }
  @media screen and (max-width: 991px) {
    .page-container {
      padding-left: 0; } }

.main-content {
  padding: 85px 20px 20px;
  min-height: calc(100vh - 61px); }
  @media screen and (max-width: 991px) {
    .main-content {
      padding: 85px 5px 5px; } }

.remain-height {
  height: calc(100vh - 126px); }

.full-container {
  left: 280px;
  min-height: calc(100vh - 65px);
  position: absolute;
  right: 0;
  top: 65px;
  transition: all 0.2s ease; }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .full-container {
      left: 0;
      padding-left: 70px; } }
  @media screen and (max-width: 991px) {
    .full-container {
      left: 0; } }

.is-collapsed .page-container {
  padding-left: 70px; }
  @media screen and (max-width: 991px) {
    .is-collapsed .page-container {
      padding-left: 0; } }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .is-collapsed .page-container {
      padding-left: 280px; } }

.is-collapsed .full-container {
  left: 70px; }
  @media screen and (max-width: 991px) {
    .is-collapsed .full-container {
      left: 0; } }
  @media screen and (min-width: 992px) and (max-width: 1439px) {
    .is-collapsed .full-container {
      left: 280px;
      padding-left: 0; } }

.progress {
  height: 4px;
  background-color: #eaeef3;
  border-radius: 4px;
  margin-bottom: 10px; }

.easy-pie-chart {
  position: relative; }
  .easy-pie-chart span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 0; }

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 17px;
  margin-bottom: 0; }

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -12px;
  border: 1px solid #e6eaf0;
  border-radius: 3px;
  background-color: #ffffff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  text-align: center;
  font-size: 10px !important;
  line-height: 17px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -12px;
  color: #b9c2d0; }

.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1; }

.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c";
  font-size: 13px; }

.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65; }

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #d3d9e3;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #7774e7;
  border-color: #7774e7; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #ff3c7e;
  border-color: #ff3c7e; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #0f9aee;
  border-color: #0f9aee; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #D5A20D;
  border-color: #D5A20D; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #37c936;
  border-color: #37c936; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #ffffff; }

.radio {
  padding-left: 20px; }

.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }

.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #428bca; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #428bca; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }

#loader {
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
  display: default; }

#loader.fadeOut {
  opacity: 0;
  display: none; }

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

footer {
  z-index: 1;
  position: relative; }

.switch {
  font-size: 1rem;
  position: relative; }
  .switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0; }
    .switch input + label {
      position: relative;
      min-width: calc(calc(2.375rem * .8) * 2);
      border-radius: calc(2.375rem * .8);
      height: calc(2.375rem * .8);
      line-height: calc(2.375rem * .8);
      display: inline-block;
      cursor: pointer;
      outline: none;
      user-select: none;
      vertical-align: middle;
      text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem); }
    .switch input + label::before,
    .switch input + label::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(calc(2.375rem * .8) * 2);
      bottom: 0;
      display: block; }
    .switch input + label::before {
      right: 0;
      background-color: #dee2e6;
      border-radius: calc(2.375rem * .8);
      transition: 0.2s all; }
    .switch input + label::after {
      top: 2px;
      left: 2px;
      width: calc(calc(2.375rem * .8) - calc(2px * 2));
      height: calc(calc(2.375rem * .8) - calc(2px * 2));
      border-radius: 50%;
      background-color: white;
      transition: 0.2s all; }
    .switch input:checked + label::before {
      background-color: #08d; }
    .switch input:checked + label::after {
      margin-left: calc(2.375rem * .8); }
    .switch input:focus + label::before {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(0, 136, 221, 0.25); }
    .switch input:disabled + label {
      color: #868e96;
      cursor: not-allowed; }
    .switch input:disabled + label::before {
      background-color: #e9ecef; }
  .switch.switch-xs {
    font-size: 0.5rem; }
    .switch.switch-xs input + label {
      min-width: calc(calc(1.5375rem * .8) * 2);
      height: calc(1.5375rem * .8);
      line-height: calc(1.5375rem * .8);
      text-indent: calc(calc(calc(1.5375rem * .8) * 2) + .5rem); }
    .switch.switch-xs input + label::before {
      width: calc(calc(1.5375rem * .8) * 2); }
    .switch.switch-xs input + label::after {
      width: calc(calc(1.5375rem * .8) - calc(2px * 2));
      height: calc(calc(1.5375rem * .8) - calc(2px * 2)); }
    .switch.switch-xs input:checked + label::after {
      margin-left: calc(1.5375rem * .8); }
  .switch.switch-sm {
    font-size: 0.875rem; }
    .switch.switch-sm input + label {
      min-width: calc(calc(1.9375rem * .8) * 2);
      height: calc(1.9375rem * .8);
      line-height: calc(1.9375rem * .8);
      text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem); }
    .switch.switch-sm input + label::before {
      width: calc(calc(1.9375rem * .8) * 2); }
    .switch.switch-sm input + label::after {
      width: calc(calc(1.9375rem * .8) - calc(2px * 2));
      height: calc(calc(1.9375rem * .8) - calc(2px * 2)); }
    .switch.switch-sm input:checked + label::after {
      margin-left: calc(1.9375rem * .8); }
  .switch.switch-lg {
    font-size: 1.25rem; }
    .switch.switch-lg input + label {
      min-width: calc(calc(3rem * .8) * 2);
      height: calc(3rem * .8);
      line-height: calc(3rem * .8);
      text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem); }
    .switch.switch-lg input + label::before {
      width: calc(calc(3rem * .8) * 2); }
    .switch.switch-lg input + label::after {
      width: calc(calc(3rem * .8) - calc(2px * 2));
      height: calc(calc(3rem * .8) - calc(2px * 2)); }
    .switch.switch-lg input:checked + label::after {
      margin-left: calc(3rem * .8); }
  .switch + .switch {
    margin-left: 1rem; }

.dashbord-banner .banner-wrapper {
  overflow: hidden; }
  .dashbord-banner .banner-wrapper > div {
    margin-left: 0; }

.dashbord-banner .banner-card {
  width: 170.2px;
  min-width: 170.2px;
  margin: 1.5rem 0 1.5rem 0.5rem;
  padding: 0;
  background-color: #fff;
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05); }
  .dashbord-banner .banner-card .spnimgbox {
    min-height: 110px; }
  .dashbord-banner .banner-card .banner-image {
    height: 100%;
    width: 100%;
    max-width: 110px;
    max-height: 110px; }
  .dashbord-banner .banner-card .product-text {
    line-height: 1.2em; }
    .dashbord-banner .banner-card .product-text p {
      margin-bottom: 0.5rem; }
    .dashbord-banner .banner-card .product-text .price {
      font-size: 125%;
      font-weight: 700;
      color: #000; }
  .dashbord-banner .banner-card .shop-text {
    height: 69px;
    overflow: hidden;
    line-height: 1.2em; }
  .dashbord-banner .banner-card .links .fake-btn {
    background-color: #66b332;
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: 600;
    letter-spacing: 0.05em;
    padding-top: 0.303rem;
    opacity: 1; }
  .dashbord-banner .banner-card .links .link-p {
    color: #66b332;
    cursor: default; }

#chat-sidebar {
  width: 250px;
  height: calc(100vh - 65px - 60px);
  overflow: auto; }
  @media screen and (max-width: 991px) {
    #chat-sidebar {
      transition: all 0.3s ease-in-out;
      margin-left: -250px; }
      #chat-sidebar.open {
        margin-left: 0; } }

#chat-box {
  height: calc(100vh - 65px - 60px);
  overflow: auto; }

.email-app .email-side-nav {
  background: #fff;
  position: fixed;
  border-right: 1px solid rgba(0, 0, 0, 0.0625);
  float: left;
  width: 250px;
  transition: all 0.3s ease-in-out; }
  @media screen and (max-width: 991px) {
    .email-app .email-side-nav {
      z-index: 1;
      left: -250px; } }

.email-app .email-wrapper {
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 100%;
  transition: all 0.3s ease-in-out; }
  @media screen and (max-width: 991px) {
    .email-app .email-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      overflow-x: hidden; } }
  @media screen and (min-width: 992px) {
    .email-app .email-wrapper {
      margin-left: 250px; } }
  .email-app .email-wrapper .email-list {
    position: relative;
    padding: 0;
    width: 100%;
    overflow-y: hidden;
    background-color: #fff;
    height: calc(100vh - 65px); }
    @media screen and (max-width: 991px) {
      .email-app .email-wrapper .email-list {
        max-height: calc(100vh - 65px); } }
    @media screen and (min-width: 992px) {
      .email-app .email-wrapper .email-list {
        width: 40%;
        border-right: 1px solid rgba(0, 0, 0, 0.0625);
        float: left; } }
  .email-app .email-wrapper .email-content {
    float: left;
    width: 60%;
    position: relative;
    padding: 0;
    background-color: #fff; }
    .email-app .email-wrapper .email-content.no-inbox-view {
      width: 100%; }
    @media screen and (max-width: 991px) {
      .email-app .email-wrapper .email-content {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        max-height: calc(100vh - 65px);
        overflow-y: scroll; }
        .email-app .email-wrapper .email-content.open {
          left: 0; } }
  .email-app .email-wrapper .email-compose {
    position: relative; }
    .email-app .email-wrapper .email-compose .email-compose-body {
      padding: 30px 20px; }

@media screen and (max-width: 991px) {
  .email-app.side-active .email-side-nav {
    left: 0; } }

@media screen and (max-width: 991px) {
  .email-app.side-active .email-wrapper {
    left: 250px; } }

.centerY {
  top: 50%;
  transform: translateY(-50%); }

.centerX {
  left: 50%;
  transform: translateX(-50%); }

.centerXY {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@media screen and (min-width: 1440px) {
  .centerY\@xl\+ {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@xl\+ {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@xl\+ {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 1200px) {
  .centerY\@lg\+ {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@lg\+ {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@lg\+ {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 992px) {
  .centerY\@md\+ {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@md\+ {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@md\+ {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 768px) {
  .centerY\@sm\+ {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@sm\+ {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@sm\+ {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 0) {
  .centerY\@xs\+ {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@xs\+ {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@xs\+ {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (max-width: 1439px) {
  .centerY\@xl- {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@xl- {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@xl- {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (max-width: 1199px) {
  .centerY\@lg- {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@lg- {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@lg- {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (max-width: 991px) {
  .centerY\@md- {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@md- {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@md- {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (max-width: 767px) {
  .centerY\@sm- {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@sm- {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@sm- {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .centerY\@lg {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@lg {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@lg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .centerY\@md {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@md {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@md {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .centerY\@sm {
    top: 50%;
    transform: translateY(-50%); }
  .centerX\@sm {
    left: 50%;
    transform: translateX(-50%); }
  .centerXY\@sm {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

[class*='gap'] {
  width: auto !important;
  overflow: hidden !important; }

.gapX-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }
  .gapX-0 > * {
    padding-left: 0px !important;
    padding-right: 0px !important; }

.gapY-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }
  .gapY-0 > * {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }

.gap-0 {
  margin: 0px !important; }
  .gap-0 > * {
    padding: 0px !important; }

@media screen and (min-width: 1440px) {
  .gapX-0\@xl\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@xl\+ > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@xl\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@xl\+ > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@xl\+ {
    margin: 0px !important; }
    .gap-0\@xl\+ > * {
      padding: 0px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-0\@lg\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@lg\+ > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@lg\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@lg\+ > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@lg\+ {
    margin: 0px !important; }
    .gap-0\@lg\+ > * {
      padding: 0px !important; } }

@media screen and (min-width: 992px) {
  .gapX-0\@md\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@md\+ > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@md\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@md\+ > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@md\+ {
    margin: 0px !important; }
    .gap-0\@md\+ > * {
      padding: 0px !important; } }

@media screen and (min-width: 768px) {
  .gapX-0\@sm\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@sm\+ > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@sm\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@sm\+ > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@sm\+ {
    margin: 0px !important; }
    .gap-0\@sm\+ > * {
      padding: 0px !important; } }

@media screen and (min-width: 0) {
  .gapX-0\@xs\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@xs\+ > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@xs\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@xs\+ > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@xs\+ {
    margin: 0px !important; }
    .gap-0\@xs\+ > * {
      padding: 0px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-0\@xl- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@xl- > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@xl- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@xl- > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@xl- {
    margin: 0px !important; }
    .gap-0\@xl- > * {
      padding: 0px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-0\@lg- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@lg- > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@lg- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@lg- > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@lg- {
    margin: 0px !important; }
    .gap-0\@lg- > * {
      padding: 0px !important; } }

@media screen and (max-width: 991px) {
  .gapX-0\@md- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@md- > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@md- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@md- > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@md- {
    margin: 0px !important; }
    .gap-0\@md- > * {
      padding: 0px !important; } }

@media screen and (max-width: 767px) {
  .gapX-0\@sm- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@sm- > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@sm- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@sm- > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@sm- {
    margin: 0px !important; }
    .gap-0\@sm- > * {
      padding: 0px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-0\@lg {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@lg > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@lg {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@lg > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@lg {
    margin: 0px !important; }
    .gap-0\@lg > * {
      padding: 0px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-0\@md {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@md > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@md {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@md > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@md {
    margin: 0px !important; }
    .gap-0\@md > * {
      padding: 0px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-0\@sm {
    margin-left: 0px !important;
    margin-right: 0px !important; }
    .gapX-0\@sm > * {
      padding-left: 0px !important;
      padding-right: 0px !important; }
  .gapY-0\@sm {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
    .gapY-0\@sm > * {
      padding-top: 0px !important;
      padding-bottom: 0px !important; }
  .gap-0\@sm {
    margin: 0px !important; }
    .gap-0\@sm > * {
      padding: 0px !important; } }

.gapX-1 {
  margin-left: -0.5px !important;
  margin-right: -0.5px !important; }
  .gapX-1 > * {
    padding-left: 0.5px !important;
    padding-right: 0.5px !important; }

.gapY-1 {
  margin-top: -0.5px !important;
  margin-bottom: -0.5px !important; }
  .gapY-1 > * {
    padding-top: 0.5px !important;
    padding-bottom: 0.5px !important; }

.gap-1 {
  margin: -0.5px !important; }
  .gap-1 > * {
    padding: 0.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-1\@xl\+ {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@xl\+ > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@xl\+ {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@xl\+ > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@xl\+ {
    margin: -0.5px !important; }
    .gap-1\@xl\+ > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-1\@lg\+ {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@lg\+ > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@lg\+ {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@lg\+ > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@lg\+ {
    margin: -0.5px !important; }
    .gap-1\@lg\+ > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-1\@md\+ {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@md\+ > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@md\+ {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@md\+ > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@md\+ {
    margin: -0.5px !important; }
    .gap-1\@md\+ > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-1\@sm\+ {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@sm\+ > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@sm\+ {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@sm\+ > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@sm\+ {
    margin: -0.5px !important; }
    .gap-1\@sm\+ > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-1\@xs\+ {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@xs\+ > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@xs\+ {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@xs\+ > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@xs\+ {
    margin: -0.5px !important; }
    .gap-1\@xs\+ > * {
      padding: 0.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-1\@xl- {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@xl- > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@xl- {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@xl- > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@xl- {
    margin: -0.5px !important; }
    .gap-1\@xl- > * {
      padding: 0.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-1\@lg- {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@lg- > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@lg- {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@lg- > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@lg- {
    margin: -0.5px !important; }
    .gap-1\@lg- > * {
      padding: 0.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-1\@md- {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@md- > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@md- {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@md- > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@md- {
    margin: -0.5px !important; }
    .gap-1\@md- > * {
      padding: 0.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-1\@sm- {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@sm- > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@sm- {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@sm- > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@sm- {
    margin: -0.5px !important; }
    .gap-1\@sm- > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-1\@lg {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@lg > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@lg {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@lg > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@lg {
    margin: -0.5px !important; }
    .gap-1\@lg > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-1\@md {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@md > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@md {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@md > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@md {
    margin: -0.5px !important; }
    .gap-1\@md > * {
      padding: 0.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-1\@sm {
    margin-left: -0.5px !important;
    margin-right: -0.5px !important; }
    .gapX-1\@sm > * {
      padding-left: 0.5px !important;
      padding-right: 0.5px !important; }
  .gapY-1\@sm {
    margin-top: -0.5px !important;
    margin-bottom: -0.5px !important; }
    .gapY-1\@sm > * {
      padding-top: 0.5px !important;
      padding-bottom: 0.5px !important; }
  .gap-1\@sm {
    margin: -0.5px !important; }
    .gap-1\@sm > * {
      padding: 0.5px !important; } }

.gapX-2 {
  margin-left: -1px !important;
  margin-right: -1px !important; }
  .gapX-2 > * {
    padding-left: 1px !important;
    padding-right: 1px !important; }

.gapY-2 {
  margin-top: -1px !important;
  margin-bottom: -1px !important; }
  .gapY-2 > * {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }

.gap-2 {
  margin: -1px !important; }
  .gap-2 > * {
    padding: 1px !important; }

@media screen and (min-width: 1440px) {
  .gapX-2\@xl\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@xl\+ > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@xl\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@xl\+ > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@xl\+ {
    margin: -1px !important; }
    .gap-2\@xl\+ > * {
      padding: 1px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-2\@lg\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@lg\+ > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@lg\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@lg\+ > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@lg\+ {
    margin: -1px !important; }
    .gap-2\@lg\+ > * {
      padding: 1px !important; } }

@media screen and (min-width: 992px) {
  .gapX-2\@md\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@md\+ > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@md\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@md\+ > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@md\+ {
    margin: -1px !important; }
    .gap-2\@md\+ > * {
      padding: 1px !important; } }

@media screen and (min-width: 768px) {
  .gapX-2\@sm\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@sm\+ > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@sm\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@sm\+ > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@sm\+ {
    margin: -1px !important; }
    .gap-2\@sm\+ > * {
      padding: 1px !important; } }

@media screen and (min-width: 0) {
  .gapX-2\@xs\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@xs\+ > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@xs\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@xs\+ > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@xs\+ {
    margin: -1px !important; }
    .gap-2\@xs\+ > * {
      padding: 1px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-2\@xl- {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@xl- > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@xl- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@xl- > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@xl- {
    margin: -1px !important; }
    .gap-2\@xl- > * {
      padding: 1px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-2\@lg- {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@lg- > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@lg- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@lg- > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@lg- {
    margin: -1px !important; }
    .gap-2\@lg- > * {
      padding: 1px !important; } }

@media screen and (max-width: 991px) {
  .gapX-2\@md- {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@md- > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@md- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@md- > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@md- {
    margin: -1px !important; }
    .gap-2\@md- > * {
      padding: 1px !important; } }

@media screen and (max-width: 767px) {
  .gapX-2\@sm- {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@sm- > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@sm- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@sm- > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@sm- {
    margin: -1px !important; }
    .gap-2\@sm- > * {
      padding: 1px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-2\@lg {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@lg > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@lg {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@lg > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@lg {
    margin: -1px !important; }
    .gap-2\@lg > * {
      padding: 1px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-2\@md {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@md > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@md {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@md > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@md {
    margin: -1px !important; }
    .gap-2\@md > * {
      padding: 1px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-2\@sm {
    margin-left: -1px !important;
    margin-right: -1px !important; }
    .gapX-2\@sm > * {
      padding-left: 1px !important;
      padding-right: 1px !important; }
  .gapY-2\@sm {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
    .gapY-2\@sm > * {
      padding-top: 1px !important;
      padding-bottom: 1px !important; }
  .gap-2\@sm {
    margin: -1px !important; }
    .gap-2\@sm > * {
      padding: 1px !important; } }

.gapX-3 {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important; }
  .gapX-3 > * {
    padding-left: 1.5px !important;
    padding-right: 1.5px !important; }

.gapY-3 {
  margin-top: -1.5px !important;
  margin-bottom: -1.5px !important; }
  .gapY-3 > * {
    padding-top: 1.5px !important;
    padding-bottom: 1.5px !important; }

.gap-3 {
  margin: -1.5px !important; }
  .gap-3 > * {
    padding: 1.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-3\@xl\+ {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@xl\+ > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@xl\+ {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@xl\+ > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@xl\+ {
    margin: -1.5px !important; }
    .gap-3\@xl\+ > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-3\@lg\+ {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@lg\+ > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@lg\+ {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@lg\+ > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@lg\+ {
    margin: -1.5px !important; }
    .gap-3\@lg\+ > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-3\@md\+ {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@md\+ > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@md\+ {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@md\+ > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@md\+ {
    margin: -1.5px !important; }
    .gap-3\@md\+ > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-3\@sm\+ {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@sm\+ > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@sm\+ {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@sm\+ > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@sm\+ {
    margin: -1.5px !important; }
    .gap-3\@sm\+ > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-3\@xs\+ {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@xs\+ > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@xs\+ {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@xs\+ > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@xs\+ {
    margin: -1.5px !important; }
    .gap-3\@xs\+ > * {
      padding: 1.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-3\@xl- {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@xl- > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@xl- {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@xl- > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@xl- {
    margin: -1.5px !important; }
    .gap-3\@xl- > * {
      padding: 1.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-3\@lg- {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@lg- > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@lg- {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@lg- > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@lg- {
    margin: -1.5px !important; }
    .gap-3\@lg- > * {
      padding: 1.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-3\@md- {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@md- > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@md- {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@md- > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@md- {
    margin: -1.5px !important; }
    .gap-3\@md- > * {
      padding: 1.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-3\@sm- {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@sm- > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@sm- {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@sm- > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@sm- {
    margin: -1.5px !important; }
    .gap-3\@sm- > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-3\@lg {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@lg > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@lg {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@lg > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@lg {
    margin: -1.5px !important; }
    .gap-3\@lg > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-3\@md {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@md > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@md {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@md > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@md {
    margin: -1.5px !important; }
    .gap-3\@md > * {
      padding: 1.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-3\@sm {
    margin-left: -1.5px !important;
    margin-right: -1.5px !important; }
    .gapX-3\@sm > * {
      padding-left: 1.5px !important;
      padding-right: 1.5px !important; }
  .gapY-3\@sm {
    margin-top: -1.5px !important;
    margin-bottom: -1.5px !important; }
    .gapY-3\@sm > * {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important; }
  .gap-3\@sm {
    margin: -1.5px !important; }
    .gap-3\@sm > * {
      padding: 1.5px !important; } }

.gapX-4 {
  margin-left: -2px !important;
  margin-right: -2px !important; }
  .gapX-4 > * {
    padding-left: 2px !important;
    padding-right: 2px !important; }

.gapY-4 {
  margin-top: -2px !important;
  margin-bottom: -2px !important; }
  .gapY-4 > * {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }

.gap-4 {
  margin: -2px !important; }
  .gap-4 > * {
    padding: 2px !important; }

@media screen and (min-width: 1440px) {
  .gapX-4\@xl\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@xl\+ > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@xl\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@xl\+ > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@xl\+ {
    margin: -2px !important; }
    .gap-4\@xl\+ > * {
      padding: 2px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-4\@lg\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@lg\+ > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@lg\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@lg\+ > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@lg\+ {
    margin: -2px !important; }
    .gap-4\@lg\+ > * {
      padding: 2px !important; } }

@media screen and (min-width: 992px) {
  .gapX-4\@md\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@md\+ > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@md\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@md\+ > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@md\+ {
    margin: -2px !important; }
    .gap-4\@md\+ > * {
      padding: 2px !important; } }

@media screen and (min-width: 768px) {
  .gapX-4\@sm\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@sm\+ > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@sm\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@sm\+ > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@sm\+ {
    margin: -2px !important; }
    .gap-4\@sm\+ > * {
      padding: 2px !important; } }

@media screen and (min-width: 0) {
  .gapX-4\@xs\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@xs\+ > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@xs\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@xs\+ > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@xs\+ {
    margin: -2px !important; }
    .gap-4\@xs\+ > * {
      padding: 2px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-4\@xl- {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@xl- > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@xl- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@xl- > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@xl- {
    margin: -2px !important; }
    .gap-4\@xl- > * {
      padding: 2px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-4\@lg- {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@lg- > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@lg- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@lg- > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@lg- {
    margin: -2px !important; }
    .gap-4\@lg- > * {
      padding: 2px !important; } }

@media screen and (max-width: 991px) {
  .gapX-4\@md- {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@md- > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@md- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@md- > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@md- {
    margin: -2px !important; }
    .gap-4\@md- > * {
      padding: 2px !important; } }

@media screen and (max-width: 767px) {
  .gapX-4\@sm- {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@sm- > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@sm- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@sm- > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@sm- {
    margin: -2px !important; }
    .gap-4\@sm- > * {
      padding: 2px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-4\@lg {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@lg > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@lg {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@lg > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@lg {
    margin: -2px !important; }
    .gap-4\@lg > * {
      padding: 2px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-4\@md {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@md > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@md {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@md > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@md {
    margin: -2px !important; }
    .gap-4\@md > * {
      padding: 2px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-4\@sm {
    margin-left: -2px !important;
    margin-right: -2px !important; }
    .gapX-4\@sm > * {
      padding-left: 2px !important;
      padding-right: 2px !important; }
  .gapY-4\@sm {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
    .gapY-4\@sm > * {
      padding-top: 2px !important;
      padding-bottom: 2px !important; }
  .gap-4\@sm {
    margin: -2px !important; }
    .gap-4\@sm > * {
      padding: 2px !important; } }

.gapX-5 {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important; }
  .gapX-5 > * {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important; }

.gapY-5 {
  margin-top: -2.5px !important;
  margin-bottom: -2.5px !important; }
  .gapY-5 > * {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important; }

.gap-5 {
  margin: -2.5px !important; }
  .gap-5 > * {
    padding: 2.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-5\@xl\+ {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@xl\+ > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@xl\+ {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@xl\+ > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@xl\+ {
    margin: -2.5px !important; }
    .gap-5\@xl\+ > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-5\@lg\+ {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@lg\+ > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@lg\+ {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@lg\+ > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@lg\+ {
    margin: -2.5px !important; }
    .gap-5\@lg\+ > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-5\@md\+ {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@md\+ > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@md\+ {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@md\+ > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@md\+ {
    margin: -2.5px !important; }
    .gap-5\@md\+ > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-5\@sm\+ {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@sm\+ > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@sm\+ {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@sm\+ > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@sm\+ {
    margin: -2.5px !important; }
    .gap-5\@sm\+ > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-5\@xs\+ {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@xs\+ > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@xs\+ {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@xs\+ > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@xs\+ {
    margin: -2.5px !important; }
    .gap-5\@xs\+ > * {
      padding: 2.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-5\@xl- {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@xl- > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@xl- {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@xl- > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@xl- {
    margin: -2.5px !important; }
    .gap-5\@xl- > * {
      padding: 2.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-5\@lg- {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@lg- > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@lg- {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@lg- > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@lg- {
    margin: -2.5px !important; }
    .gap-5\@lg- > * {
      padding: 2.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-5\@md- {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@md- > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@md- {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@md- > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@md- {
    margin: -2.5px !important; }
    .gap-5\@md- > * {
      padding: 2.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-5\@sm- {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@sm- > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@sm- {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@sm- > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@sm- {
    margin: -2.5px !important; }
    .gap-5\@sm- > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-5\@lg {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@lg > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@lg {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@lg > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@lg {
    margin: -2.5px !important; }
    .gap-5\@lg > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-5\@md {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@md > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@md {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@md > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@md {
    margin: -2.5px !important; }
    .gap-5\@md > * {
      padding: 2.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-5\@sm {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important; }
    .gapX-5\@sm > * {
      padding-left: 2.5px !important;
      padding-right: 2.5px !important; }
  .gapY-5\@sm {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important; }
    .gapY-5\@sm > * {
      padding-top: 2.5px !important;
      padding-bottom: 2.5px !important; }
  .gap-5\@sm {
    margin: -2.5px !important; }
    .gap-5\@sm > * {
      padding: 2.5px !important; } }

.gapX-10 {
  margin-left: -5px !important;
  margin-right: -5px !important; }
  .gapX-10 > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }

.gapY-10 {
  margin-top: -5px !important;
  margin-bottom: -5px !important; }
  .gapY-10 > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }

.gap-10 {
  margin: -5px !important; }
  .gap-10 > * {
    padding: 5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-10\@xl\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@xl\+ > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@xl\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@xl\+ > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@xl\+ {
    margin: -5px !important; }
    .gap-10\@xl\+ > * {
      padding: 5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-10\@lg\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@lg\+ > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@lg\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@lg\+ > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@lg\+ {
    margin: -5px !important; }
    .gap-10\@lg\+ > * {
      padding: 5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-10\@md\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@md\+ > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@md\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@md\+ > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@md\+ {
    margin: -5px !important; }
    .gap-10\@md\+ > * {
      padding: 5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-10\@sm\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@sm\+ > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@sm\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@sm\+ > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@sm\+ {
    margin: -5px !important; }
    .gap-10\@sm\+ > * {
      padding: 5px !important; } }

@media screen and (min-width: 0) {
  .gapX-10\@xs\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@xs\+ > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@xs\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@xs\+ > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@xs\+ {
    margin: -5px !important; }
    .gap-10\@xs\+ > * {
      padding: 5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-10\@xl- {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@xl- > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@xl- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@xl- > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@xl- {
    margin: -5px !important; }
    .gap-10\@xl- > * {
      padding: 5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-10\@lg- {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@lg- > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@lg- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@lg- > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@lg- {
    margin: -5px !important; }
    .gap-10\@lg- > * {
      padding: 5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-10\@md- {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@md- > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@md- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@md- > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@md- {
    margin: -5px !important; }
    .gap-10\@md- > * {
      padding: 5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-10\@sm- {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@sm- > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@sm- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@sm- > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@sm- {
    margin: -5px !important; }
    .gap-10\@sm- > * {
      padding: 5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-10\@lg {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@lg > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@lg {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@lg > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@lg {
    margin: -5px !important; }
    .gap-10\@lg > * {
      padding: 5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-10\@md {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@md > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@md {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@md > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@md {
    margin: -5px !important; }
    .gap-10\@md > * {
      padding: 5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-10\@sm {
    margin-left: -5px !important;
    margin-right: -5px !important; }
    .gapX-10\@sm > * {
      padding-left: 5px !important;
      padding-right: 5px !important; }
  .gapY-10\@sm {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
    .gapY-10\@sm > * {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .gap-10\@sm {
    margin: -5px !important; }
    .gap-10\@sm > * {
      padding: 5px !important; } }

.gapX-15 {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important; }
  .gapX-15 > * {
    padding-left: 7.5px !important;
    padding-right: 7.5px !important; }

.gapY-15 {
  margin-top: -7.5px !important;
  margin-bottom: -7.5px !important; }
  .gapY-15 > * {
    padding-top: 7.5px !important;
    padding-bottom: 7.5px !important; }

.gap-15 {
  margin: -7.5px !important; }
  .gap-15 > * {
    padding: 7.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-15\@xl\+ {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@xl\+ > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@xl\+ {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@xl\+ > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@xl\+ {
    margin: -7.5px !important; }
    .gap-15\@xl\+ > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-15\@lg\+ {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@lg\+ > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@lg\+ {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@lg\+ > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@lg\+ {
    margin: -7.5px !important; }
    .gap-15\@lg\+ > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-15\@md\+ {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@md\+ > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@md\+ {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@md\+ > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@md\+ {
    margin: -7.5px !important; }
    .gap-15\@md\+ > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-15\@sm\+ {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@sm\+ > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@sm\+ {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@sm\+ > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@sm\+ {
    margin: -7.5px !important; }
    .gap-15\@sm\+ > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-15\@xs\+ {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@xs\+ > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@xs\+ {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@xs\+ > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@xs\+ {
    margin: -7.5px !important; }
    .gap-15\@xs\+ > * {
      padding: 7.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-15\@xl- {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@xl- > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@xl- {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@xl- > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@xl- {
    margin: -7.5px !important; }
    .gap-15\@xl- > * {
      padding: 7.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-15\@lg- {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@lg- > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@lg- {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@lg- > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@lg- {
    margin: -7.5px !important; }
    .gap-15\@lg- > * {
      padding: 7.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-15\@md- {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@md- > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@md- {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@md- > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@md- {
    margin: -7.5px !important; }
    .gap-15\@md- > * {
      padding: 7.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-15\@sm- {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@sm- > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@sm- {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@sm- > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@sm- {
    margin: -7.5px !important; }
    .gap-15\@sm- > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-15\@lg {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@lg > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@lg {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@lg > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@lg {
    margin: -7.5px !important; }
    .gap-15\@lg > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-15\@md {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@md > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@md {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@md > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@md {
    margin: -7.5px !important; }
    .gap-15\@md > * {
      padding: 7.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-15\@sm {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }
    .gapX-15\@sm > * {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important; }
  .gapY-15\@sm {
    margin-top: -7.5px !important;
    margin-bottom: -7.5px !important; }
    .gapY-15\@sm > * {
      padding-top: 7.5px !important;
      padding-bottom: 7.5px !important; }
  .gap-15\@sm {
    margin: -7.5px !important; }
    .gap-15\@sm > * {
      padding: 7.5px !important; } }

.gapX-20 {
  margin-left: -10px !important;
  margin-right: -10px !important; }
  .gapX-20 > * {
    padding-left: 10px !important;
    padding-right: 10px !important; }

.gapY-20 {
  margin-top: -10px !important;
  margin-bottom: -10px !important; }
  .gapY-20 > * {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }

.gap-20 {
  margin: -10px !important; }
  .gap-20 > * {
    padding: 10px !important; }

@media screen and (min-width: 1440px) {
  .gapX-20\@xl\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@xl\+ > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@xl\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@xl\+ > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@xl\+ {
    margin: -10px !important; }
    .gap-20\@xl\+ > * {
      padding: 10px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-20\@lg\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@lg\+ > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@lg\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@lg\+ > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@lg\+ {
    margin: -10px !important; }
    .gap-20\@lg\+ > * {
      padding: 10px !important; } }

@media screen and (min-width: 992px) {
  .gapX-20\@md\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@md\+ > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@md\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@md\+ > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@md\+ {
    margin: -10px !important; }
    .gap-20\@md\+ > * {
      padding: 10px !important; } }

@media screen and (min-width: 768px) {
  .gapX-20\@sm\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@sm\+ > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@sm\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@sm\+ > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@sm\+ {
    margin: -10px !important; }
    .gap-20\@sm\+ > * {
      padding: 10px !important; } }

@media screen and (min-width: 0) {
  .gapX-20\@xs\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@xs\+ > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@xs\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@xs\+ > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@xs\+ {
    margin: -10px !important; }
    .gap-20\@xs\+ > * {
      padding: 10px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-20\@xl- {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@xl- > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@xl- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@xl- > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@xl- {
    margin: -10px !important; }
    .gap-20\@xl- > * {
      padding: 10px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-20\@lg- {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@lg- > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@lg- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@lg- > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@lg- {
    margin: -10px !important; }
    .gap-20\@lg- > * {
      padding: 10px !important; } }

@media screen and (max-width: 991px) {
  .gapX-20\@md- {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@md- > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@md- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@md- > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@md- {
    margin: -10px !important; }
    .gap-20\@md- > * {
      padding: 10px !important; } }

@media screen and (max-width: 767px) {
  .gapX-20\@sm- {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@sm- > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@sm- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@sm- > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@sm- {
    margin: -10px !important; }
    .gap-20\@sm- > * {
      padding: 10px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-20\@lg {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@lg > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@lg {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@lg > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@lg {
    margin: -10px !important; }
    .gap-20\@lg > * {
      padding: 10px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-20\@md {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@md > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@md {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@md > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@md {
    margin: -10px !important; }
    .gap-20\@md > * {
      padding: 10px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-20\@sm {
    margin-left: -10px !important;
    margin-right: -10px !important; }
    .gapX-20\@sm > * {
      padding-left: 10px !important;
      padding-right: 10px !important; }
  .gapY-20\@sm {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
    .gapY-20\@sm > * {
      padding-top: 10px !important;
      padding-bottom: 10px !important; }
  .gap-20\@sm {
    margin: -10px !important; }
    .gap-20\@sm > * {
      padding: 10px !important; } }

.gapX-25 {
  margin-left: -12.5px !important;
  margin-right: -12.5px !important; }
  .gapX-25 > * {
    padding-left: 12.5px !important;
    padding-right: 12.5px !important; }

.gapY-25 {
  margin-top: -12.5px !important;
  margin-bottom: -12.5px !important; }
  .gapY-25 > * {
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important; }

.gap-25 {
  margin: -12.5px !important; }
  .gap-25 > * {
    padding: 12.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-25\@xl\+ {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@xl\+ > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@xl\+ {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@xl\+ > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@xl\+ {
    margin: -12.5px !important; }
    .gap-25\@xl\+ > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-25\@lg\+ {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@lg\+ > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@lg\+ {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@lg\+ > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@lg\+ {
    margin: -12.5px !important; }
    .gap-25\@lg\+ > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-25\@md\+ {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@md\+ > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@md\+ {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@md\+ > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@md\+ {
    margin: -12.5px !important; }
    .gap-25\@md\+ > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-25\@sm\+ {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@sm\+ > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@sm\+ {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@sm\+ > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@sm\+ {
    margin: -12.5px !important; }
    .gap-25\@sm\+ > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-25\@xs\+ {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@xs\+ > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@xs\+ {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@xs\+ > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@xs\+ {
    margin: -12.5px !important; }
    .gap-25\@xs\+ > * {
      padding: 12.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-25\@xl- {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@xl- > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@xl- {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@xl- > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@xl- {
    margin: -12.5px !important; }
    .gap-25\@xl- > * {
      padding: 12.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-25\@lg- {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@lg- > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@lg- {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@lg- > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@lg- {
    margin: -12.5px !important; }
    .gap-25\@lg- > * {
      padding: 12.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-25\@md- {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@md- > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@md- {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@md- > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@md- {
    margin: -12.5px !important; }
    .gap-25\@md- > * {
      padding: 12.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-25\@sm- {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@sm- > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@sm- {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@sm- > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@sm- {
    margin: -12.5px !important; }
    .gap-25\@sm- > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-25\@lg {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@lg > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@lg {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@lg > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@lg {
    margin: -12.5px !important; }
    .gap-25\@lg > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-25\@md {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@md > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@md {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@md > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@md {
    margin: -12.5px !important; }
    .gap-25\@md > * {
      padding: 12.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-25\@sm {
    margin-left: -12.5px !important;
    margin-right: -12.5px !important; }
    .gapX-25\@sm > * {
      padding-left: 12.5px !important;
      padding-right: 12.5px !important; }
  .gapY-25\@sm {
    margin-top: -12.5px !important;
    margin-bottom: -12.5px !important; }
    .gapY-25\@sm > * {
      padding-top: 12.5px !important;
      padding-bottom: 12.5px !important; }
  .gap-25\@sm {
    margin: -12.5px !important; }
    .gap-25\@sm > * {
      padding: 12.5px !important; } }

.gapX-30 {
  margin-left: -15px !important;
  margin-right: -15px !important; }
  .gapX-30 > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }

.gapY-30 {
  margin-top: -15px !important;
  margin-bottom: -15px !important; }
  .gapY-30 > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }

.gap-30 {
  margin: -15px !important; }
  .gap-30 > * {
    padding: 15px !important; }

@media screen and (min-width: 1440px) {
  .gapX-30\@xl\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@xl\+ > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@xl\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@xl\+ > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@xl\+ {
    margin: -15px !important; }
    .gap-30\@xl\+ > * {
      padding: 15px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-30\@lg\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@lg\+ > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@lg\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@lg\+ > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@lg\+ {
    margin: -15px !important; }
    .gap-30\@lg\+ > * {
      padding: 15px !important; } }

@media screen and (min-width: 992px) {
  .gapX-30\@md\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@md\+ > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@md\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@md\+ > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@md\+ {
    margin: -15px !important; }
    .gap-30\@md\+ > * {
      padding: 15px !important; } }

@media screen and (min-width: 768px) {
  .gapX-30\@sm\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@sm\+ > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@sm\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@sm\+ > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@sm\+ {
    margin: -15px !important; }
    .gap-30\@sm\+ > * {
      padding: 15px !important; } }

@media screen and (min-width: 0) {
  .gapX-30\@xs\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@xs\+ > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@xs\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@xs\+ > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@xs\+ {
    margin: -15px !important; }
    .gap-30\@xs\+ > * {
      padding: 15px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-30\@xl- {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@xl- > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@xl- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@xl- > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@xl- {
    margin: -15px !important; }
    .gap-30\@xl- > * {
      padding: 15px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-30\@lg- {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@lg- > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@lg- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@lg- > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@lg- {
    margin: -15px !important; }
    .gap-30\@lg- > * {
      padding: 15px !important; } }

@media screen and (max-width: 991px) {
  .gapX-30\@md- {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@md- > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@md- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@md- > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@md- {
    margin: -15px !important; }
    .gap-30\@md- > * {
      padding: 15px !important; } }

@media screen and (max-width: 767px) {
  .gapX-30\@sm- {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@sm- > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@sm- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@sm- > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@sm- {
    margin: -15px !important; }
    .gap-30\@sm- > * {
      padding: 15px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-30\@lg {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@lg > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@lg {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@lg > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@lg {
    margin: -15px !important; }
    .gap-30\@lg > * {
      padding: 15px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-30\@md {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@md > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@md {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@md > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@md {
    margin: -15px !important; }
    .gap-30\@md > * {
      padding: 15px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-30\@sm {
    margin-left: -15px !important;
    margin-right: -15px !important; }
    .gapX-30\@sm > * {
      padding-left: 15px !important;
      padding-right: 15px !important; }
  .gapY-30\@sm {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
    .gapY-30\@sm > * {
      padding-top: 15px !important;
      padding-bottom: 15px !important; }
  .gap-30\@sm {
    margin: -15px !important; }
    .gap-30\@sm > * {
      padding: 15px !important; } }

.gapX-35 {
  margin-left: -17.5px !important;
  margin-right: -17.5px !important; }
  .gapX-35 > * {
    padding-left: 17.5px !important;
    padding-right: 17.5px !important; }

.gapY-35 {
  margin-top: -17.5px !important;
  margin-bottom: -17.5px !important; }
  .gapY-35 > * {
    padding-top: 17.5px !important;
    padding-bottom: 17.5px !important; }

.gap-35 {
  margin: -17.5px !important; }
  .gap-35 > * {
    padding: 17.5px !important; }

@media screen and (min-width: 1440px) {
  .gapX-35\@xl\+ {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@xl\+ > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@xl\+ {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@xl\+ > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@xl\+ {
    margin: -17.5px !important; }
    .gap-35\@xl\+ > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-35\@lg\+ {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@lg\+ > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@lg\+ {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@lg\+ > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@lg\+ {
    margin: -17.5px !important; }
    .gap-35\@lg\+ > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 992px) {
  .gapX-35\@md\+ {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@md\+ > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@md\+ {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@md\+ > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@md\+ {
    margin: -17.5px !important; }
    .gap-35\@md\+ > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 768px) {
  .gapX-35\@sm\+ {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@sm\+ > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@sm\+ {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@sm\+ > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@sm\+ {
    margin: -17.5px !important; }
    .gap-35\@sm\+ > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 0) {
  .gapX-35\@xs\+ {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@xs\+ > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@xs\+ {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@xs\+ > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@xs\+ {
    margin: -17.5px !important; }
    .gap-35\@xs\+ > * {
      padding: 17.5px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-35\@xl- {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@xl- > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@xl- {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@xl- > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@xl- {
    margin: -17.5px !important; }
    .gap-35\@xl- > * {
      padding: 17.5px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-35\@lg- {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@lg- > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@lg- {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@lg- > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@lg- {
    margin: -17.5px !important; }
    .gap-35\@lg- > * {
      padding: 17.5px !important; } }

@media screen and (max-width: 991px) {
  .gapX-35\@md- {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@md- > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@md- {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@md- > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@md- {
    margin: -17.5px !important; }
    .gap-35\@md- > * {
      padding: 17.5px !important; } }

@media screen and (max-width: 767px) {
  .gapX-35\@sm- {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@sm- > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@sm- {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@sm- > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@sm- {
    margin: -17.5px !important; }
    .gap-35\@sm- > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-35\@lg {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@lg > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@lg {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@lg > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@lg {
    margin: -17.5px !important; }
    .gap-35\@lg > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-35\@md {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@md > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@md {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@md > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@md {
    margin: -17.5px !important; }
    .gap-35\@md > * {
      padding: 17.5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-35\@sm {
    margin-left: -17.5px !important;
    margin-right: -17.5px !important; }
    .gapX-35\@sm > * {
      padding-left: 17.5px !important;
      padding-right: 17.5px !important; }
  .gapY-35\@sm {
    margin-top: -17.5px !important;
    margin-bottom: -17.5px !important; }
    .gapY-35\@sm > * {
      padding-top: 17.5px !important;
      padding-bottom: 17.5px !important; }
  .gap-35\@sm {
    margin: -17.5px !important; }
    .gap-35\@sm > * {
      padding: 17.5px !important; } }

.gapX-40 {
  margin-left: -20px !important;
  margin-right: -20px !important; }
  .gapX-40 > * {
    padding-left: 20px !important;
    padding-right: 20px !important; }

.gapY-40 {
  margin-top: -20px !important;
  margin-bottom: -20px !important; }
  .gapY-40 > * {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }

.gap-40 {
  margin: -20px !important; }
  .gap-40 > * {
    padding: 20px !important; }

@media screen and (min-width: 1440px) {
  .gapX-40\@xl\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@xl\+ > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@xl\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@xl\+ > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@xl\+ {
    margin: -20px !important; }
    .gap-40\@xl\+ > * {
      padding: 20px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-40\@lg\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@lg\+ > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@lg\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@lg\+ > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@lg\+ {
    margin: -20px !important; }
    .gap-40\@lg\+ > * {
      padding: 20px !important; } }

@media screen and (min-width: 992px) {
  .gapX-40\@md\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@md\+ > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@md\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@md\+ > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@md\+ {
    margin: -20px !important; }
    .gap-40\@md\+ > * {
      padding: 20px !important; } }

@media screen and (min-width: 768px) {
  .gapX-40\@sm\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@sm\+ > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@sm\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@sm\+ > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@sm\+ {
    margin: -20px !important; }
    .gap-40\@sm\+ > * {
      padding: 20px !important; } }

@media screen and (min-width: 0) {
  .gapX-40\@xs\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@xs\+ > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@xs\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@xs\+ > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@xs\+ {
    margin: -20px !important; }
    .gap-40\@xs\+ > * {
      padding: 20px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-40\@xl- {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@xl- > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@xl- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@xl- > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@xl- {
    margin: -20px !important; }
    .gap-40\@xl- > * {
      padding: 20px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-40\@lg- {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@lg- > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@lg- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@lg- > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@lg- {
    margin: -20px !important; }
    .gap-40\@lg- > * {
      padding: 20px !important; } }

@media screen and (max-width: 991px) {
  .gapX-40\@md- {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@md- > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@md- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@md- > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@md- {
    margin: -20px !important; }
    .gap-40\@md- > * {
      padding: 20px !important; } }

@media screen and (max-width: 767px) {
  .gapX-40\@sm- {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@sm- > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@sm- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@sm- > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@sm- {
    margin: -20px !important; }
    .gap-40\@sm- > * {
      padding: 20px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-40\@lg {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@lg > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@lg {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@lg > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@lg {
    margin: -20px !important; }
    .gap-40\@lg > * {
      padding: 20px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-40\@md {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@md > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@md {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@md > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@md {
    margin: -20px !important; }
    .gap-40\@md > * {
      padding: 20px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-40\@sm {
    margin-left: -20px !important;
    margin-right: -20px !important; }
    .gapX-40\@sm > * {
      padding-left: 20px !important;
      padding-right: 20px !important; }
  .gapY-40\@sm {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
    .gapY-40\@sm > * {
      padding-top: 20px !important;
      padding-bottom: 20px !important; }
  .gap-40\@sm {
    margin: -20px !important; }
    .gap-40\@sm > * {
      padding: 20px !important; } }

.gapX-50 {
  margin-left: -25px !important;
  margin-right: -25px !important; }
  .gapX-50 > * {
    padding-left: 25px !important;
    padding-right: 25px !important; }

.gapY-50 {
  margin-top: -25px !important;
  margin-bottom: -25px !important; }
  .gapY-50 > * {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }

.gap-50 {
  margin: -25px !important; }
  .gap-50 > * {
    padding: 25px !important; }

@media screen and (min-width: 1440px) {
  .gapX-50\@xl\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@xl\+ > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@xl\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@xl\+ > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@xl\+ {
    margin: -25px !important; }
    .gap-50\@xl\+ > * {
      padding: 25px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-50\@lg\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@lg\+ > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@lg\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@lg\+ > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@lg\+ {
    margin: -25px !important; }
    .gap-50\@lg\+ > * {
      padding: 25px !important; } }

@media screen and (min-width: 992px) {
  .gapX-50\@md\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@md\+ > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@md\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@md\+ > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@md\+ {
    margin: -25px !important; }
    .gap-50\@md\+ > * {
      padding: 25px !important; } }

@media screen and (min-width: 768px) {
  .gapX-50\@sm\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@sm\+ > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@sm\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@sm\+ > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@sm\+ {
    margin: -25px !important; }
    .gap-50\@sm\+ > * {
      padding: 25px !important; } }

@media screen and (min-width: 0) {
  .gapX-50\@xs\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@xs\+ > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@xs\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@xs\+ > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@xs\+ {
    margin: -25px !important; }
    .gap-50\@xs\+ > * {
      padding: 25px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-50\@xl- {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@xl- > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@xl- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@xl- > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@xl- {
    margin: -25px !important; }
    .gap-50\@xl- > * {
      padding: 25px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-50\@lg- {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@lg- > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@lg- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@lg- > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@lg- {
    margin: -25px !important; }
    .gap-50\@lg- > * {
      padding: 25px !important; } }

@media screen and (max-width: 991px) {
  .gapX-50\@md- {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@md- > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@md- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@md- > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@md- {
    margin: -25px !important; }
    .gap-50\@md- > * {
      padding: 25px !important; } }

@media screen and (max-width: 767px) {
  .gapX-50\@sm- {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@sm- > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@sm- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@sm- > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@sm- {
    margin: -25px !important; }
    .gap-50\@sm- > * {
      padding: 25px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-50\@lg {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@lg > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@lg {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@lg > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@lg {
    margin: -25px !important; }
    .gap-50\@lg > * {
      padding: 25px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-50\@md {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@md > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@md {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@md > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@md {
    margin: -25px !important; }
    .gap-50\@md > * {
      padding: 25px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-50\@sm {
    margin-left: -25px !important;
    margin-right: -25px !important; }
    .gapX-50\@sm > * {
      padding-left: 25px !important;
      padding-right: 25px !important; }
  .gapY-50\@sm {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
    .gapY-50\@sm > * {
      padding-top: 25px !important;
      padding-bottom: 25px !important; }
  .gap-50\@sm {
    margin: -25px !important; }
    .gap-50\@sm > * {
      padding: 25px !important; } }

.gapX-60 {
  margin-left: -30px !important;
  margin-right: -30px !important; }
  .gapX-60 > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }

.gapY-60 {
  margin-top: -30px !important;
  margin-bottom: -30px !important; }
  .gapY-60 > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }

.gap-60 {
  margin: -30px !important; }
  .gap-60 > * {
    padding: 30px !important; }

@media screen and (min-width: 1440px) {
  .gapX-60\@xl\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@xl\+ > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@xl\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@xl\+ > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@xl\+ {
    margin: -30px !important; }
    .gap-60\@xl\+ > * {
      padding: 30px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-60\@lg\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@lg\+ > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@lg\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@lg\+ > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@lg\+ {
    margin: -30px !important; }
    .gap-60\@lg\+ > * {
      padding: 30px !important; } }

@media screen and (min-width: 992px) {
  .gapX-60\@md\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@md\+ > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@md\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@md\+ > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@md\+ {
    margin: -30px !important; }
    .gap-60\@md\+ > * {
      padding: 30px !important; } }

@media screen and (min-width: 768px) {
  .gapX-60\@sm\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@sm\+ > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@sm\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@sm\+ > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@sm\+ {
    margin: -30px !important; }
    .gap-60\@sm\+ > * {
      padding: 30px !important; } }

@media screen and (min-width: 0) {
  .gapX-60\@xs\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@xs\+ > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@xs\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@xs\+ > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@xs\+ {
    margin: -30px !important; }
    .gap-60\@xs\+ > * {
      padding: 30px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-60\@xl- {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@xl- > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@xl- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@xl- > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@xl- {
    margin: -30px !important; }
    .gap-60\@xl- > * {
      padding: 30px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-60\@lg- {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@lg- > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@lg- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@lg- > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@lg- {
    margin: -30px !important; }
    .gap-60\@lg- > * {
      padding: 30px !important; } }

@media screen and (max-width: 991px) {
  .gapX-60\@md- {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@md- > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@md- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@md- > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@md- {
    margin: -30px !important; }
    .gap-60\@md- > * {
      padding: 30px !important; } }

@media screen and (max-width: 767px) {
  .gapX-60\@sm- {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@sm- > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@sm- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@sm- > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@sm- {
    margin: -30px !important; }
    .gap-60\@sm- > * {
      padding: 30px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-60\@lg {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@lg > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@lg {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@lg > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@lg {
    margin: -30px !important; }
    .gap-60\@lg > * {
      padding: 30px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-60\@md {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@md > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@md {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@md > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@md {
    margin: -30px !important; }
    .gap-60\@md > * {
      padding: 30px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-60\@sm {
    margin-left: -30px !important;
    margin-right: -30px !important; }
    .gapX-60\@sm > * {
      padding-left: 30px !important;
      padding-right: 30px !important; }
  .gapY-60\@sm {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
    .gapY-60\@sm > * {
      padding-top: 30px !important;
      padding-bottom: 30px !important; }
  .gap-60\@sm {
    margin: -30px !important; }
    .gap-60\@sm > * {
      padding: 30px !important; } }

.gapX-70 {
  margin-left: -35px !important;
  margin-right: -35px !important; }
  .gapX-70 > * {
    padding-left: 35px !important;
    padding-right: 35px !important; }

.gapY-70 {
  margin-top: -35px !important;
  margin-bottom: -35px !important; }
  .gapY-70 > * {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }

.gap-70 {
  margin: -35px !important; }
  .gap-70 > * {
    padding: 35px !important; }

@media screen and (min-width: 1440px) {
  .gapX-70\@xl\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@xl\+ > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@xl\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@xl\+ > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@xl\+ {
    margin: -35px !important; }
    .gap-70\@xl\+ > * {
      padding: 35px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-70\@lg\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@lg\+ > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@lg\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@lg\+ > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@lg\+ {
    margin: -35px !important; }
    .gap-70\@lg\+ > * {
      padding: 35px !important; } }

@media screen and (min-width: 992px) {
  .gapX-70\@md\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@md\+ > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@md\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@md\+ > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@md\+ {
    margin: -35px !important; }
    .gap-70\@md\+ > * {
      padding: 35px !important; } }

@media screen and (min-width: 768px) {
  .gapX-70\@sm\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@sm\+ > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@sm\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@sm\+ > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@sm\+ {
    margin: -35px !important; }
    .gap-70\@sm\+ > * {
      padding: 35px !important; } }

@media screen and (min-width: 0) {
  .gapX-70\@xs\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@xs\+ > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@xs\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@xs\+ > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@xs\+ {
    margin: -35px !important; }
    .gap-70\@xs\+ > * {
      padding: 35px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-70\@xl- {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@xl- > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@xl- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@xl- > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@xl- {
    margin: -35px !important; }
    .gap-70\@xl- > * {
      padding: 35px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-70\@lg- {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@lg- > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@lg- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@lg- > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@lg- {
    margin: -35px !important; }
    .gap-70\@lg- > * {
      padding: 35px !important; } }

@media screen and (max-width: 991px) {
  .gapX-70\@md- {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@md- > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@md- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@md- > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@md- {
    margin: -35px !important; }
    .gap-70\@md- > * {
      padding: 35px !important; } }

@media screen and (max-width: 767px) {
  .gapX-70\@sm- {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@sm- > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@sm- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@sm- > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@sm- {
    margin: -35px !important; }
    .gap-70\@sm- > * {
      padding: 35px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-70\@lg {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@lg > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@lg {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@lg > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@lg {
    margin: -35px !important; }
    .gap-70\@lg > * {
      padding: 35px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-70\@md {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@md > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@md {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@md > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@md {
    margin: -35px !important; }
    .gap-70\@md > * {
      padding: 35px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-70\@sm {
    margin-left: -35px !important;
    margin-right: -35px !important; }
    .gapX-70\@sm > * {
      padding-left: 35px !important;
      padding-right: 35px !important; }
  .gapY-70\@sm {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
    .gapY-70\@sm > * {
      padding-top: 35px !important;
      padding-bottom: 35px !important; }
  .gap-70\@sm {
    margin: -35px !important; }
    .gap-70\@sm > * {
      padding: 35px !important; } }

.gapX-80 {
  margin-left: -40px !important;
  margin-right: -40px !important; }
  .gapX-80 > * {
    padding-left: 40px !important;
    padding-right: 40px !important; }

.gapY-80 {
  margin-top: -40px !important;
  margin-bottom: -40px !important; }
  .gapY-80 > * {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }

.gap-80 {
  margin: -40px !important; }
  .gap-80 > * {
    padding: 40px !important; }

@media screen and (min-width: 1440px) {
  .gapX-80\@xl\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@xl\+ > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@xl\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@xl\+ > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@xl\+ {
    margin: -40px !important; }
    .gap-80\@xl\+ > * {
      padding: 40px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-80\@lg\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@lg\+ > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@lg\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@lg\+ > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@lg\+ {
    margin: -40px !important; }
    .gap-80\@lg\+ > * {
      padding: 40px !important; } }

@media screen and (min-width: 992px) {
  .gapX-80\@md\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@md\+ > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@md\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@md\+ > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@md\+ {
    margin: -40px !important; }
    .gap-80\@md\+ > * {
      padding: 40px !important; } }

@media screen and (min-width: 768px) {
  .gapX-80\@sm\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@sm\+ > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@sm\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@sm\+ > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@sm\+ {
    margin: -40px !important; }
    .gap-80\@sm\+ > * {
      padding: 40px !important; } }

@media screen and (min-width: 0) {
  .gapX-80\@xs\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@xs\+ > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@xs\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@xs\+ > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@xs\+ {
    margin: -40px !important; }
    .gap-80\@xs\+ > * {
      padding: 40px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-80\@xl- {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@xl- > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@xl- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@xl- > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@xl- {
    margin: -40px !important; }
    .gap-80\@xl- > * {
      padding: 40px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-80\@lg- {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@lg- > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@lg- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@lg- > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@lg- {
    margin: -40px !important; }
    .gap-80\@lg- > * {
      padding: 40px !important; } }

@media screen and (max-width: 991px) {
  .gapX-80\@md- {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@md- > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@md- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@md- > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@md- {
    margin: -40px !important; }
    .gap-80\@md- > * {
      padding: 40px !important; } }

@media screen and (max-width: 767px) {
  .gapX-80\@sm- {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@sm- > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@sm- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@sm- > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@sm- {
    margin: -40px !important; }
    .gap-80\@sm- > * {
      padding: 40px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-80\@lg {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@lg > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@lg {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@lg > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@lg {
    margin: -40px !important; }
    .gap-80\@lg > * {
      padding: 40px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-80\@md {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@md > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@md {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@md > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@md {
    margin: -40px !important; }
    .gap-80\@md > * {
      padding: 40px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-80\@sm {
    margin-left: -40px !important;
    margin-right: -40px !important; }
    .gapX-80\@sm > * {
      padding-left: 40px !important;
      padding-right: 40px !important; }
  .gapY-80\@sm {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
    .gapY-80\@sm > * {
      padding-top: 40px !important;
      padding-bottom: 40px !important; }
  .gap-80\@sm {
    margin: -40px !important; }
    .gap-80\@sm > * {
      padding: 40px !important; } }

.gapX-90 {
  margin-left: -45px !important;
  margin-right: -45px !important; }
  .gapX-90 > * {
    padding-left: 45px !important;
    padding-right: 45px !important; }

.gapY-90 {
  margin-top: -45px !important;
  margin-bottom: -45px !important; }
  .gapY-90 > * {
    padding-top: 45px !important;
    padding-bottom: 45px !important; }

.gap-90 {
  margin: -45px !important; }
  .gap-90 > * {
    padding: 45px !important; }

@media screen and (min-width: 1440px) {
  .gapX-90\@xl\+ {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@xl\+ > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@xl\+ {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@xl\+ > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@xl\+ {
    margin: -45px !important; }
    .gap-90\@xl\+ > * {
      padding: 45px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-90\@lg\+ {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@lg\+ > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@lg\+ {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@lg\+ > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@lg\+ {
    margin: -45px !important; }
    .gap-90\@lg\+ > * {
      padding: 45px !important; } }

@media screen and (min-width: 992px) {
  .gapX-90\@md\+ {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@md\+ > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@md\+ {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@md\+ > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@md\+ {
    margin: -45px !important; }
    .gap-90\@md\+ > * {
      padding: 45px !important; } }

@media screen and (min-width: 768px) {
  .gapX-90\@sm\+ {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@sm\+ > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@sm\+ {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@sm\+ > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@sm\+ {
    margin: -45px !important; }
    .gap-90\@sm\+ > * {
      padding: 45px !important; } }

@media screen and (min-width: 0) {
  .gapX-90\@xs\+ {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@xs\+ > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@xs\+ {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@xs\+ > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@xs\+ {
    margin: -45px !important; }
    .gap-90\@xs\+ > * {
      padding: 45px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-90\@xl- {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@xl- > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@xl- {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@xl- > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@xl- {
    margin: -45px !important; }
    .gap-90\@xl- > * {
      padding: 45px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-90\@lg- {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@lg- > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@lg- {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@lg- > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@lg- {
    margin: -45px !important; }
    .gap-90\@lg- > * {
      padding: 45px !important; } }

@media screen and (max-width: 991px) {
  .gapX-90\@md- {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@md- > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@md- {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@md- > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@md- {
    margin: -45px !important; }
    .gap-90\@md- > * {
      padding: 45px !important; } }

@media screen and (max-width: 767px) {
  .gapX-90\@sm- {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@sm- > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@sm- {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@sm- > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@sm- {
    margin: -45px !important; }
    .gap-90\@sm- > * {
      padding: 45px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-90\@lg {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@lg > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@lg {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@lg > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@lg {
    margin: -45px !important; }
    .gap-90\@lg > * {
      padding: 45px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-90\@md {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@md > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@md {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@md > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@md {
    margin: -45px !important; }
    .gap-90\@md > * {
      padding: 45px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-90\@sm {
    margin-left: -45px !important;
    margin-right: -45px !important; }
    .gapX-90\@sm > * {
      padding-left: 45px !important;
      padding-right: 45px !important; }
  .gapY-90\@sm {
    margin-top: -45px !important;
    margin-bottom: -45px !important; }
    .gapY-90\@sm > * {
      padding-top: 45px !important;
      padding-bottom: 45px !important; }
  .gap-90\@sm {
    margin: -45px !important; }
    .gap-90\@sm > * {
      padding: 45px !important; } }

.gapX-100 {
  margin-left: -50px !important;
  margin-right: -50px !important; }
  .gapX-100 > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }

.gapY-100 {
  margin-top: -50px !important;
  margin-bottom: -50px !important; }
  .gapY-100 > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }

.gap-100 {
  margin: -50px !important; }
  .gap-100 > * {
    padding: 50px !important; }

@media screen and (min-width: 1440px) {
  .gapX-100\@xl\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@xl\+ > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@xl\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@xl\+ > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@xl\+ {
    margin: -50px !important; }
    .gap-100\@xl\+ > * {
      padding: 50px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-100\@lg\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@lg\+ > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@lg\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@lg\+ > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@lg\+ {
    margin: -50px !important; }
    .gap-100\@lg\+ > * {
      padding: 50px !important; } }

@media screen and (min-width: 992px) {
  .gapX-100\@md\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@md\+ > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@md\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@md\+ > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@md\+ {
    margin: -50px !important; }
    .gap-100\@md\+ > * {
      padding: 50px !important; } }

@media screen and (min-width: 768px) {
  .gapX-100\@sm\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@sm\+ > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@sm\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@sm\+ > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@sm\+ {
    margin: -50px !important; }
    .gap-100\@sm\+ > * {
      padding: 50px !important; } }

@media screen and (min-width: 0) {
  .gapX-100\@xs\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@xs\+ > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@xs\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@xs\+ > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@xs\+ {
    margin: -50px !important; }
    .gap-100\@xs\+ > * {
      padding: 50px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-100\@xl- {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@xl- > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@xl- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@xl- > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@xl- {
    margin: -50px !important; }
    .gap-100\@xl- > * {
      padding: 50px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-100\@lg- {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@lg- > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@lg- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@lg- > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@lg- {
    margin: -50px !important; }
    .gap-100\@lg- > * {
      padding: 50px !important; } }

@media screen and (max-width: 991px) {
  .gapX-100\@md- {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@md- > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@md- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@md- > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@md- {
    margin: -50px !important; }
    .gap-100\@md- > * {
      padding: 50px !important; } }

@media screen and (max-width: 767px) {
  .gapX-100\@sm- {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@sm- > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@sm- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@sm- > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@sm- {
    margin: -50px !important; }
    .gap-100\@sm- > * {
      padding: 50px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-100\@lg {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@lg > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@lg {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@lg > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@lg {
    margin: -50px !important; }
    .gap-100\@lg > * {
      padding: 50px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-100\@md {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@md > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@md {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@md > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@md {
    margin: -50px !important; }
    .gap-100\@md > * {
      padding: 50px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-100\@sm {
    margin-left: -50px !important;
    margin-right: -50px !important; }
    .gapX-100\@sm > * {
      padding-left: 50px !important;
      padding-right: 50px !important; }
  .gapY-100\@sm {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
    .gapY-100\@sm > * {
      padding-top: 50px !important;
      padding-bottom: 50px !important; }
  .gap-100\@sm {
    margin: -50px !important; }
    .gap-100\@sm > * {
      padding: 50px !important; } }

.gapX-110 {
  margin-left: -55px !important;
  margin-right: -55px !important; }
  .gapX-110 > * {
    padding-left: 55px !important;
    padding-right: 55px !important; }

.gapY-110 {
  margin-top: -55px !important;
  margin-bottom: -55px !important; }
  .gapY-110 > * {
    padding-top: 55px !important;
    padding-bottom: 55px !important; }

.gap-110 {
  margin: -55px !important; }
  .gap-110 > * {
    padding: 55px !important; }

@media screen and (min-width: 1440px) {
  .gapX-110\@xl\+ {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@xl\+ > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@xl\+ {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@xl\+ > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@xl\+ {
    margin: -55px !important; }
    .gap-110\@xl\+ > * {
      padding: 55px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-110\@lg\+ {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@lg\+ > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@lg\+ {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@lg\+ > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@lg\+ {
    margin: -55px !important; }
    .gap-110\@lg\+ > * {
      padding: 55px !important; } }

@media screen and (min-width: 992px) {
  .gapX-110\@md\+ {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@md\+ > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@md\+ {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@md\+ > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@md\+ {
    margin: -55px !important; }
    .gap-110\@md\+ > * {
      padding: 55px !important; } }

@media screen and (min-width: 768px) {
  .gapX-110\@sm\+ {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@sm\+ > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@sm\+ {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@sm\+ > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@sm\+ {
    margin: -55px !important; }
    .gap-110\@sm\+ > * {
      padding: 55px !important; } }

@media screen and (min-width: 0) {
  .gapX-110\@xs\+ {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@xs\+ > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@xs\+ {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@xs\+ > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@xs\+ {
    margin: -55px !important; }
    .gap-110\@xs\+ > * {
      padding: 55px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-110\@xl- {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@xl- > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@xl- {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@xl- > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@xl- {
    margin: -55px !important; }
    .gap-110\@xl- > * {
      padding: 55px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-110\@lg- {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@lg- > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@lg- {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@lg- > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@lg- {
    margin: -55px !important; }
    .gap-110\@lg- > * {
      padding: 55px !important; } }

@media screen and (max-width: 991px) {
  .gapX-110\@md- {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@md- > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@md- {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@md- > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@md- {
    margin: -55px !important; }
    .gap-110\@md- > * {
      padding: 55px !important; } }

@media screen and (max-width: 767px) {
  .gapX-110\@sm- {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@sm- > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@sm- {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@sm- > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@sm- {
    margin: -55px !important; }
    .gap-110\@sm- > * {
      padding: 55px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-110\@lg {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@lg > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@lg {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@lg > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@lg {
    margin: -55px !important; }
    .gap-110\@lg > * {
      padding: 55px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-110\@md {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@md > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@md {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@md > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@md {
    margin: -55px !important; }
    .gap-110\@md > * {
      padding: 55px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-110\@sm {
    margin-left: -55px !important;
    margin-right: -55px !important; }
    .gapX-110\@sm > * {
      padding-left: 55px !important;
      padding-right: 55px !important; }
  .gapY-110\@sm {
    margin-top: -55px !important;
    margin-bottom: -55px !important; }
    .gapY-110\@sm > * {
      padding-top: 55px !important;
      padding-bottom: 55px !important; }
  .gap-110\@sm {
    margin: -55px !important; }
    .gap-110\@sm > * {
      padding: 55px !important; } }

.gapX-120 {
  margin-left: -60px !important;
  margin-right: -60px !important; }
  .gapX-120 > * {
    padding-left: 60px !important;
    padding-right: 60px !important; }

.gapY-120 {
  margin-top: -60px !important;
  margin-bottom: -60px !important; }
  .gapY-120 > * {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }

.gap-120 {
  margin: -60px !important; }
  .gap-120 > * {
    padding: 60px !important; }

@media screen and (min-width: 1440px) {
  .gapX-120\@xl\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@xl\+ > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@xl\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@xl\+ > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@xl\+ {
    margin: -60px !important; }
    .gap-120\@xl\+ > * {
      padding: 60px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-120\@lg\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@lg\+ > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@lg\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@lg\+ > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@lg\+ {
    margin: -60px !important; }
    .gap-120\@lg\+ > * {
      padding: 60px !important; } }

@media screen and (min-width: 992px) {
  .gapX-120\@md\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@md\+ > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@md\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@md\+ > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@md\+ {
    margin: -60px !important; }
    .gap-120\@md\+ > * {
      padding: 60px !important; } }

@media screen and (min-width: 768px) {
  .gapX-120\@sm\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@sm\+ > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@sm\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@sm\+ > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@sm\+ {
    margin: -60px !important; }
    .gap-120\@sm\+ > * {
      padding: 60px !important; } }

@media screen and (min-width: 0) {
  .gapX-120\@xs\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@xs\+ > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@xs\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@xs\+ > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@xs\+ {
    margin: -60px !important; }
    .gap-120\@xs\+ > * {
      padding: 60px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-120\@xl- {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@xl- > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@xl- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@xl- > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@xl- {
    margin: -60px !important; }
    .gap-120\@xl- > * {
      padding: 60px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-120\@lg- {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@lg- > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@lg- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@lg- > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@lg- {
    margin: -60px !important; }
    .gap-120\@lg- > * {
      padding: 60px !important; } }

@media screen and (max-width: 991px) {
  .gapX-120\@md- {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@md- > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@md- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@md- > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@md- {
    margin: -60px !important; }
    .gap-120\@md- > * {
      padding: 60px !important; } }

@media screen and (max-width: 767px) {
  .gapX-120\@sm- {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@sm- > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@sm- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@sm- > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@sm- {
    margin: -60px !important; }
    .gap-120\@sm- > * {
      padding: 60px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-120\@lg {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@lg > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@lg {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@lg > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@lg {
    margin: -60px !important; }
    .gap-120\@lg > * {
      padding: 60px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-120\@md {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@md > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@md {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@md > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@md {
    margin: -60px !important; }
    .gap-120\@md > * {
      padding: 60px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-120\@sm {
    margin-left: -60px !important;
    margin-right: -60px !important; }
    .gapX-120\@sm > * {
      padding-left: 60px !important;
      padding-right: 60px !important; }
  .gapY-120\@sm {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
    .gapY-120\@sm > * {
      padding-top: 60px !important;
      padding-bottom: 60px !important; }
  .gap-120\@sm {
    margin: -60px !important; }
    .gap-120\@sm > * {
      padding: 60px !important; } }

.gapX-130 {
  margin-left: -65px !important;
  margin-right: -65px !important; }
  .gapX-130 > * {
    padding-left: 65px !important;
    padding-right: 65px !important; }

.gapY-130 {
  margin-top: -65px !important;
  margin-bottom: -65px !important; }
  .gapY-130 > * {
    padding-top: 65px !important;
    padding-bottom: 65px !important; }

.gap-130 {
  margin: -65px !important; }
  .gap-130 > * {
    padding: 65px !important; }

@media screen and (min-width: 1440px) {
  .gapX-130\@xl\+ {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@xl\+ > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@xl\+ {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@xl\+ > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@xl\+ {
    margin: -65px !important; }
    .gap-130\@xl\+ > * {
      padding: 65px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-130\@lg\+ {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@lg\+ > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@lg\+ {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@lg\+ > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@lg\+ {
    margin: -65px !important; }
    .gap-130\@lg\+ > * {
      padding: 65px !important; } }

@media screen and (min-width: 992px) {
  .gapX-130\@md\+ {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@md\+ > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@md\+ {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@md\+ > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@md\+ {
    margin: -65px !important; }
    .gap-130\@md\+ > * {
      padding: 65px !important; } }

@media screen and (min-width: 768px) {
  .gapX-130\@sm\+ {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@sm\+ > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@sm\+ {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@sm\+ > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@sm\+ {
    margin: -65px !important; }
    .gap-130\@sm\+ > * {
      padding: 65px !important; } }

@media screen and (min-width: 0) {
  .gapX-130\@xs\+ {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@xs\+ > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@xs\+ {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@xs\+ > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@xs\+ {
    margin: -65px !important; }
    .gap-130\@xs\+ > * {
      padding: 65px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-130\@xl- {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@xl- > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@xl- {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@xl- > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@xl- {
    margin: -65px !important; }
    .gap-130\@xl- > * {
      padding: 65px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-130\@lg- {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@lg- > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@lg- {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@lg- > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@lg- {
    margin: -65px !important; }
    .gap-130\@lg- > * {
      padding: 65px !important; } }

@media screen and (max-width: 991px) {
  .gapX-130\@md- {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@md- > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@md- {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@md- > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@md- {
    margin: -65px !important; }
    .gap-130\@md- > * {
      padding: 65px !important; } }

@media screen and (max-width: 767px) {
  .gapX-130\@sm- {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@sm- > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@sm- {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@sm- > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@sm- {
    margin: -65px !important; }
    .gap-130\@sm- > * {
      padding: 65px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-130\@lg {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@lg > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@lg {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@lg > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@lg {
    margin: -65px !important; }
    .gap-130\@lg > * {
      padding: 65px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-130\@md {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@md > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@md {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@md > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@md {
    margin: -65px !important; }
    .gap-130\@md > * {
      padding: 65px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-130\@sm {
    margin-left: -65px !important;
    margin-right: -65px !important; }
    .gapX-130\@sm > * {
      padding-left: 65px !important;
      padding-right: 65px !important; }
  .gapY-130\@sm {
    margin-top: -65px !important;
    margin-bottom: -65px !important; }
    .gapY-130\@sm > * {
      padding-top: 65px !important;
      padding-bottom: 65px !important; }
  .gap-130\@sm {
    margin: -65px !important; }
    .gap-130\@sm > * {
      padding: 65px !important; } }

.gapX-140 {
  margin-left: -70px !important;
  margin-right: -70px !important; }
  .gapX-140 > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }

.gapY-140 {
  margin-top: -70px !important;
  margin-bottom: -70px !important; }
  .gapY-140 > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }

.gap-140 {
  margin: -70px !important; }
  .gap-140 > * {
    padding: 70px !important; }

@media screen and (min-width: 1440px) {
  .gapX-140\@xl\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@xl\+ > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@xl\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@xl\+ > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@xl\+ {
    margin: -70px !important; }
    .gap-140\@xl\+ > * {
      padding: 70px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-140\@lg\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@lg\+ > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@lg\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@lg\+ > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@lg\+ {
    margin: -70px !important; }
    .gap-140\@lg\+ > * {
      padding: 70px !important; } }

@media screen and (min-width: 992px) {
  .gapX-140\@md\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@md\+ > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@md\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@md\+ > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@md\+ {
    margin: -70px !important; }
    .gap-140\@md\+ > * {
      padding: 70px !important; } }

@media screen and (min-width: 768px) {
  .gapX-140\@sm\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@sm\+ > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@sm\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@sm\+ > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@sm\+ {
    margin: -70px !important; }
    .gap-140\@sm\+ > * {
      padding: 70px !important; } }

@media screen and (min-width: 0) {
  .gapX-140\@xs\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@xs\+ > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@xs\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@xs\+ > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@xs\+ {
    margin: -70px !important; }
    .gap-140\@xs\+ > * {
      padding: 70px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-140\@xl- {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@xl- > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@xl- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@xl- > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@xl- {
    margin: -70px !important; }
    .gap-140\@xl- > * {
      padding: 70px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-140\@lg- {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@lg- > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@lg- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@lg- > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@lg- {
    margin: -70px !important; }
    .gap-140\@lg- > * {
      padding: 70px !important; } }

@media screen and (max-width: 991px) {
  .gapX-140\@md- {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@md- > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@md- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@md- > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@md- {
    margin: -70px !important; }
    .gap-140\@md- > * {
      padding: 70px !important; } }

@media screen and (max-width: 767px) {
  .gapX-140\@sm- {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@sm- > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@sm- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@sm- > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@sm- {
    margin: -70px !important; }
    .gap-140\@sm- > * {
      padding: 70px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-140\@lg {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@lg > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@lg {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@lg > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@lg {
    margin: -70px !important; }
    .gap-140\@lg > * {
      padding: 70px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-140\@md {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@md > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@md {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@md > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@md {
    margin: -70px !important; }
    .gap-140\@md > * {
      padding: 70px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-140\@sm {
    margin-left: -70px !important;
    margin-right: -70px !important; }
    .gapX-140\@sm > * {
      padding-left: 70px !important;
      padding-right: 70px !important; }
  .gapY-140\@sm {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
    .gapY-140\@sm > * {
      padding-top: 70px !important;
      padding-bottom: 70px !important; }
  .gap-140\@sm {
    margin: -70px !important; }
    .gap-140\@sm > * {
      padding: 70px !important; } }

.gapX-150 {
  margin-left: -75px !important;
  margin-right: -75px !important; }
  .gapX-150 > * {
    padding-left: 75px !important;
    padding-right: 75px !important; }

.gapY-150 {
  margin-top: -75px !important;
  margin-bottom: -75px !important; }
  .gapY-150 > * {
    padding-top: 75px !important;
    padding-bottom: 75px !important; }

.gap-150 {
  margin: -75px !important; }
  .gap-150 > * {
    padding: 75px !important; }

@media screen and (min-width: 1440px) {
  .gapX-150\@xl\+ {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@xl\+ > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@xl\+ {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@xl\+ > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@xl\+ {
    margin: -75px !important; }
    .gap-150\@xl\+ > * {
      padding: 75px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-150\@lg\+ {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@lg\+ > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@lg\+ {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@lg\+ > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@lg\+ {
    margin: -75px !important; }
    .gap-150\@lg\+ > * {
      padding: 75px !important; } }

@media screen and (min-width: 992px) {
  .gapX-150\@md\+ {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@md\+ > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@md\+ {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@md\+ > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@md\+ {
    margin: -75px !important; }
    .gap-150\@md\+ > * {
      padding: 75px !important; } }

@media screen and (min-width: 768px) {
  .gapX-150\@sm\+ {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@sm\+ > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@sm\+ {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@sm\+ > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@sm\+ {
    margin: -75px !important; }
    .gap-150\@sm\+ > * {
      padding: 75px !important; } }

@media screen and (min-width: 0) {
  .gapX-150\@xs\+ {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@xs\+ > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@xs\+ {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@xs\+ > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@xs\+ {
    margin: -75px !important; }
    .gap-150\@xs\+ > * {
      padding: 75px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-150\@xl- {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@xl- > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@xl- {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@xl- > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@xl- {
    margin: -75px !important; }
    .gap-150\@xl- > * {
      padding: 75px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-150\@lg- {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@lg- > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@lg- {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@lg- > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@lg- {
    margin: -75px !important; }
    .gap-150\@lg- > * {
      padding: 75px !important; } }

@media screen and (max-width: 991px) {
  .gapX-150\@md- {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@md- > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@md- {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@md- > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@md- {
    margin: -75px !important; }
    .gap-150\@md- > * {
      padding: 75px !important; } }

@media screen and (max-width: 767px) {
  .gapX-150\@sm- {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@sm- > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@sm- {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@sm- > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@sm- {
    margin: -75px !important; }
    .gap-150\@sm- > * {
      padding: 75px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-150\@lg {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@lg > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@lg {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@lg > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@lg {
    margin: -75px !important; }
    .gap-150\@lg > * {
      padding: 75px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-150\@md {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@md > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@md {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@md > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@md {
    margin: -75px !important; }
    .gap-150\@md > * {
      padding: 75px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-150\@sm {
    margin-left: -75px !important;
    margin-right: -75px !important; }
    .gapX-150\@sm > * {
      padding-left: 75px !important;
      padding-right: 75px !important; }
  .gapY-150\@sm {
    margin-top: -75px !important;
    margin-bottom: -75px !important; }
    .gapY-150\@sm > * {
      padding-top: 75px !important;
      padding-bottom: 75px !important; }
  .gap-150\@sm {
    margin: -75px !important; }
    .gap-150\@sm > * {
      padding: 75px !important; } }

.gapX-160 {
  margin-left: -80px !important;
  margin-right: -80px !important; }
  .gapX-160 > * {
    padding-left: 80px !important;
    padding-right: 80px !important; }

.gapY-160 {
  margin-top: -80px !important;
  margin-bottom: -80px !important; }
  .gapY-160 > * {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }

.gap-160 {
  margin: -80px !important; }
  .gap-160 > * {
    padding: 80px !important; }

@media screen and (min-width: 1440px) {
  .gapX-160\@xl\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@xl\+ > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@xl\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@xl\+ > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@xl\+ {
    margin: -80px !important; }
    .gap-160\@xl\+ > * {
      padding: 80px !important; } }

@media screen and (min-width: 1200px) {
  .gapX-160\@lg\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@lg\+ > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@lg\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@lg\+ > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@lg\+ {
    margin: -80px !important; }
    .gap-160\@lg\+ > * {
      padding: 80px !important; } }

@media screen and (min-width: 992px) {
  .gapX-160\@md\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@md\+ > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@md\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@md\+ > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@md\+ {
    margin: -80px !important; }
    .gap-160\@md\+ > * {
      padding: 80px !important; } }

@media screen and (min-width: 768px) {
  .gapX-160\@sm\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@sm\+ > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@sm\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@sm\+ > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@sm\+ {
    margin: -80px !important; }
    .gap-160\@sm\+ > * {
      padding: 80px !important; } }

@media screen and (min-width: 0) {
  .gapX-160\@xs\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@xs\+ > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@xs\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@xs\+ > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@xs\+ {
    margin: -80px !important; }
    .gap-160\@xs\+ > * {
      padding: 80px !important; } }

@media screen and (max-width: 1439px) {
  .gapX-160\@xl- {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@xl- > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@xl- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@xl- > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@xl- {
    margin: -80px !important; }
    .gap-160\@xl- > * {
      padding: 80px !important; } }

@media screen and (max-width: 1199px) {
  .gapX-160\@lg- {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@lg- > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@lg- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@lg- > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@lg- {
    margin: -80px !important; }
    .gap-160\@lg- > * {
      padding: 80px !important; } }

@media screen and (max-width: 991px) {
  .gapX-160\@md- {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@md- > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@md- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@md- > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@md- {
    margin: -80px !important; }
    .gap-160\@md- > * {
      padding: 80px !important; } }

@media screen and (max-width: 767px) {
  .gapX-160\@sm- {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@sm- > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@sm- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@sm- > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@sm- {
    margin: -80px !important; }
    .gap-160\@sm- > * {
      padding: 80px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .gapX-160\@lg {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@lg > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@lg {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@lg > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@lg {
    margin: -80px !important; }
    .gap-160\@lg > * {
      padding: 80px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .gapX-160\@md {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@md > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@md {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@md > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@md {
    margin: -80px !important; }
    .gap-160\@md > * {
      padding: 80px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .gapX-160\@sm {
    margin-left: -80px !important;
    margin-right: -80px !important; }
    .gapX-160\@sm > * {
      padding-left: 80px !important;
      padding-right: 80px !important; }
  .gapY-160\@sm {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
    .gapY-160\@sm > * {
      padding-top: 80px !important;
      padding-bottom: 80px !important; }
  .gap-160\@sm {
    margin: -80px !important; }
    .gap-160\@sm > * {
      padding: 80px !important; } }

.peers {
  box-sizing: border-box;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: row wrap;
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0; }

.peer {
  display: block;
  height: auto;
  flex: 0 0 auto; }

.peer-greed {
  flex: 1 1 auto; }

.peers-greed > .peer,
.peers-greed > .peers {
  flex: 1 1 auto; }

.peer > img {
  max-width: none; }

.peer-greed > img {
  max-width: 100%; }

.layers {
  display: flex;
  flex-flow: column nowrap;
  align-items: center; }

.d-f {
  display: flex; }

.d-if {
  display: inline-flex; }

@media screen and (min-width: 1440px) {
  .d-f\@xl\+ {
    display: flex; }
  .d-if\@xl\+ {
    display: inline-flex; } }

@media screen and (min-width: 1200px) {
  .d-f\@lg\+ {
    display: flex; }
  .d-if\@lg\+ {
    display: inline-flex; } }

@media screen and (min-width: 992px) {
  .d-f\@md\+ {
    display: flex; }
  .d-if\@md\+ {
    display: inline-flex; } }

@media screen and (min-width: 768px) {
  .d-f\@sm\+ {
    display: flex; }
  .d-if\@sm\+ {
    display: inline-flex; } }

@media screen and (min-width: 0) {
  .d-f\@xs\+ {
    display: flex; }
  .d-if\@xs\+ {
    display: inline-flex; } }

@media screen and (max-width: 1439px) {
  .d-f\@xl- {
    display: flex; }
  .d-if\@xl- {
    display: inline-flex; } }

@media screen and (max-width: 1199px) {
  .d-f\@lg- {
    display: flex; }
  .d-if\@lg- {
    display: inline-flex; } }

@media screen and (max-width: 991px) {
  .d-f\@md- {
    display: flex; }
  .d-if\@md- {
    display: inline-flex; } }

@media screen and (max-width: 767px) {
  .d-f\@sm- {
    display: flex; }
  .d-if\@sm- {
    display: inline-flex; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .d-f\@lg {
    display: flex; }
  .d-if\@lg {
    display: inline-flex; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .d-f\@md {
    display: flex; }
  .d-if\@md {
    display: inline-flex; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .d-f\@sm {
    display: flex; }
  .d-if\@sm {
    display: inline-flex; } }

.fxw-w {
  flex-wrap: wrap; }

.fxw-wr {
  flex-wrap: wrap-reverse; }

.fxw-nw {
  flex-wrap: nowrap; }

@media screen and (min-width: 1440px) {
  .fxw-w\@xl\+ {
    flex-wrap: wrap; }
  .fxw-wr\@xl\+ {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@xl\+ {
    flex-wrap: nowrap; } }

@media screen and (min-width: 1200px) {
  .fxw-w\@lg\+ {
    flex-wrap: wrap; }
  .fxw-wr\@lg\+ {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@lg\+ {
    flex-wrap: nowrap; } }

@media screen and (min-width: 992px) {
  .fxw-w\@md\+ {
    flex-wrap: wrap; }
  .fxw-wr\@md\+ {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@md\+ {
    flex-wrap: nowrap; } }

@media screen and (min-width: 768px) {
  .fxw-w\@sm\+ {
    flex-wrap: wrap; }
  .fxw-wr\@sm\+ {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@sm\+ {
    flex-wrap: nowrap; } }

@media screen and (min-width: 0) {
  .fxw-w\@xs\+ {
    flex-wrap: wrap; }
  .fxw-wr\@xs\+ {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@xs\+ {
    flex-wrap: nowrap; } }

@media screen and (max-width: 1439px) {
  .fxw-w\@xl- {
    flex-wrap: wrap; }
  .fxw-wr\@xl- {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@xl- {
    flex-wrap: nowrap; } }

@media screen and (max-width: 1199px) {
  .fxw-w\@lg- {
    flex-wrap: wrap; }
  .fxw-wr\@lg- {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@lg- {
    flex-wrap: nowrap; } }

@media screen and (max-width: 991px) {
  .fxw-w\@md- {
    flex-wrap: wrap; }
  .fxw-wr\@md- {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@md- {
    flex-wrap: nowrap; } }

@media screen and (max-width: 767px) {
  .fxw-w\@sm- {
    flex-wrap: wrap; }
  .fxw-wr\@sm- {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@sm- {
    flex-wrap: nowrap; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fxw-w\@lg {
    flex-wrap: wrap; }
  .fxw-wr\@lg {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@lg {
    flex-wrap: nowrap; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fxw-w\@md {
    flex-wrap: wrap; }
  .fxw-wr\@md {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@md {
    flex-wrap: nowrap; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fxw-w\@sm {
    flex-wrap: wrap; }
  .fxw-wr\@sm {
    flex-wrap: wrap-reverse; }
  .fxw-nw\@sm {
    flex-wrap: nowrap; } }

.fxd-c {
  flex-direction: column; }

.fxd-cr {
  flex-direction: column-reverse; }

.fxd-r {
  flex-direction: row; }

.fxd-rr {
  flex-direction: row-reverse; }

@media screen and (min-width: 1440px) {
  .fxd-c\@xl\+ {
    flex-direction: column; }
  .fxd-cr\@xl\+ {
    flex-direction: column-reverse; }
  .fxd-r\@xl\+ {
    flex-direction: row; }
  .fxd-rr\@xl\+ {
    flex-direction: row-reverse; } }

@media screen and (min-width: 1200px) {
  .fxd-c\@lg\+ {
    flex-direction: column; }
  .fxd-cr\@lg\+ {
    flex-direction: column-reverse; }
  .fxd-r\@lg\+ {
    flex-direction: row; }
  .fxd-rr\@lg\+ {
    flex-direction: row-reverse; } }

@media screen and (min-width: 992px) {
  .fxd-c\@md\+ {
    flex-direction: column; }
  .fxd-cr\@md\+ {
    flex-direction: column-reverse; }
  .fxd-r\@md\+ {
    flex-direction: row; }
  .fxd-rr\@md\+ {
    flex-direction: row-reverse; } }

@media screen and (min-width: 768px) {
  .fxd-c\@sm\+ {
    flex-direction: column; }
  .fxd-cr\@sm\+ {
    flex-direction: column-reverse; }
  .fxd-r\@sm\+ {
    flex-direction: row; }
  .fxd-rr\@sm\+ {
    flex-direction: row-reverse; } }

@media screen and (min-width: 0) {
  .fxd-c\@xs\+ {
    flex-direction: column; }
  .fxd-cr\@xs\+ {
    flex-direction: column-reverse; }
  .fxd-r\@xs\+ {
    flex-direction: row; }
  .fxd-rr\@xs\+ {
    flex-direction: row-reverse; } }

@media screen and (max-width: 1439px) {
  .fxd-c\@xl- {
    flex-direction: column; }
  .fxd-cr\@xl- {
    flex-direction: column-reverse; }
  .fxd-r\@xl- {
    flex-direction: row; }
  .fxd-rr\@xl- {
    flex-direction: row-reverse; } }

@media screen and (max-width: 1199px) {
  .fxd-c\@lg- {
    flex-direction: column; }
  .fxd-cr\@lg- {
    flex-direction: column-reverse; }
  .fxd-r\@lg- {
    flex-direction: row; }
  .fxd-rr\@lg- {
    flex-direction: row-reverse; } }

@media screen and (max-width: 991px) {
  .fxd-c\@md- {
    flex-direction: column; }
  .fxd-cr\@md- {
    flex-direction: column-reverse; }
  .fxd-r\@md- {
    flex-direction: row; }
  .fxd-rr\@md- {
    flex-direction: row-reverse; } }

@media screen and (max-width: 767px) {
  .fxd-c\@sm- {
    flex-direction: column; }
  .fxd-cr\@sm- {
    flex-direction: column-reverse; }
  .fxd-r\@sm- {
    flex-direction: row; }
  .fxd-rr\@sm- {
    flex-direction: row-reverse; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fxd-c\@lg {
    flex-direction: column; }
  .fxd-cr\@lg {
    flex-direction: column-reverse; }
  .fxd-r\@lg {
    flex-direction: row; }
  .fxd-rr\@lg {
    flex-direction: row-reverse; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fxd-c\@md {
    flex-direction: column; }
  .fxd-cr\@md {
    flex-direction: column-reverse; }
  .fxd-r\@md {
    flex-direction: row; }
  .fxd-rr\@md {
    flex-direction: row-reverse; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fxd-c\@sm {
    flex-direction: column; }
  .fxd-cr\@sm {
    flex-direction: column-reverse; }
  .fxd-r\@sm {
    flex-direction: row; }
  .fxd-rr\@sm {
    flex-direction: row-reverse; } }

.ai-fs {
  align-items: flex-start; }

.ai-fe {
  align-items: flex-end; }

.ai-c {
  align-items: center; }

.ai-b {
  align-items: baseline; }

.ai-s {
  align-items: stretch; }

@media screen and (min-width: 1440px) {
  .ai-fs\@xl\+ {
    align-items: flex-start; }
  .ai-fe\@xl\+ {
    align-items: flex-end; }
  .ai-c\@xl\+ {
    align-items: center; }
  .ai-b\@xl\+ {
    align-items: baseline; }
  .ai-s\@xl\+ {
    align-items: stretch; } }

@media screen and (min-width: 1200px) {
  .ai-fs\@lg\+ {
    align-items: flex-start; }
  .ai-fe\@lg\+ {
    align-items: flex-end; }
  .ai-c\@lg\+ {
    align-items: center; }
  .ai-b\@lg\+ {
    align-items: baseline; }
  .ai-s\@lg\+ {
    align-items: stretch; } }

@media screen and (min-width: 992px) {
  .ai-fs\@md\+ {
    align-items: flex-start; }
  .ai-fe\@md\+ {
    align-items: flex-end; }
  .ai-c\@md\+ {
    align-items: center; }
  .ai-b\@md\+ {
    align-items: baseline; }
  .ai-s\@md\+ {
    align-items: stretch; } }

@media screen and (min-width: 768px) {
  .ai-fs\@sm\+ {
    align-items: flex-start; }
  .ai-fe\@sm\+ {
    align-items: flex-end; }
  .ai-c\@sm\+ {
    align-items: center; }
  .ai-b\@sm\+ {
    align-items: baseline; }
  .ai-s\@sm\+ {
    align-items: stretch; } }

@media screen and (min-width: 0) {
  .ai-fs\@xs\+ {
    align-items: flex-start; }
  .ai-fe\@xs\+ {
    align-items: flex-end; }
  .ai-c\@xs\+ {
    align-items: center; }
  .ai-b\@xs\+ {
    align-items: baseline; }
  .ai-s\@xs\+ {
    align-items: stretch; } }

@media screen and (max-width: 1439px) {
  .ai-fs\@xl- {
    align-items: flex-start; }
  .ai-fe\@xl- {
    align-items: flex-end; }
  .ai-c\@xl- {
    align-items: center; }
  .ai-b\@xl- {
    align-items: baseline; }
  .ai-s\@xl- {
    align-items: stretch; } }

@media screen and (max-width: 1199px) {
  .ai-fs\@lg- {
    align-items: flex-start; }
  .ai-fe\@lg- {
    align-items: flex-end; }
  .ai-c\@lg- {
    align-items: center; }
  .ai-b\@lg- {
    align-items: baseline; }
  .ai-s\@lg- {
    align-items: stretch; } }

@media screen and (max-width: 991px) {
  .ai-fs\@md- {
    align-items: flex-start; }
  .ai-fe\@md- {
    align-items: flex-end; }
  .ai-c\@md- {
    align-items: center; }
  .ai-b\@md- {
    align-items: baseline; }
  .ai-s\@md- {
    align-items: stretch; } }

@media screen and (max-width: 767px) {
  .ai-fs\@sm- {
    align-items: flex-start; }
  .ai-fe\@sm- {
    align-items: flex-end; }
  .ai-c\@sm- {
    align-items: center; }
  .ai-b\@sm- {
    align-items: baseline; }
  .ai-s\@sm- {
    align-items: stretch; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ai-fs\@lg {
    align-items: flex-start; }
  .ai-fe\@lg {
    align-items: flex-end; }
  .ai-c\@lg {
    align-items: center; }
  .ai-b\@lg {
    align-items: baseline; }
  .ai-s\@lg {
    align-items: stretch; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ai-fs\@md {
    align-items: flex-start; }
  .ai-fe\@md {
    align-items: flex-end; }
  .ai-c\@md {
    align-items: center; }
  .ai-b\@md {
    align-items: baseline; }
  .ai-s\@md {
    align-items: stretch; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ai-fs\@sm {
    align-items: flex-start; }
  .ai-fe\@sm {
    align-items: flex-end; }
  .ai-c\@sm {
    align-items: center; }
  .ai-b\@sm {
    align-items: baseline; }
  .ai-s\@sm {
    align-items: stretch; } }

.as-fs {
  align-self: flex-start; }

.as-fe {
  align-self: flex-end; }

.as-c {
  align-self: center; }

.as-b {
  align-self: baseline; }

.as-s {
  align-self: stretch; }

@media screen and (min-width: 1440px) {
  .as-fs\@xl\+ {
    align-self: flex-start; }
  .as-fe\@xl\+ {
    align-self: flex-end; }
  .as-c\@xl\+ {
    align-self: center; }
  .as-b\@xl\+ {
    align-self: baseline; }
  .as-s\@xl\+ {
    align-self: stretch; } }

@media screen and (min-width: 1200px) {
  .as-fs\@lg\+ {
    align-self: flex-start; }
  .as-fe\@lg\+ {
    align-self: flex-end; }
  .as-c\@lg\+ {
    align-self: center; }
  .as-b\@lg\+ {
    align-self: baseline; }
  .as-s\@lg\+ {
    align-self: stretch; } }

@media screen and (min-width: 992px) {
  .as-fs\@md\+ {
    align-self: flex-start; }
  .as-fe\@md\+ {
    align-self: flex-end; }
  .as-c\@md\+ {
    align-self: center; }
  .as-b\@md\+ {
    align-self: baseline; }
  .as-s\@md\+ {
    align-self: stretch; } }

@media screen and (min-width: 768px) {
  .as-fs\@sm\+ {
    align-self: flex-start; }
  .as-fe\@sm\+ {
    align-self: flex-end; }
  .as-c\@sm\+ {
    align-self: center; }
  .as-b\@sm\+ {
    align-self: baseline; }
  .as-s\@sm\+ {
    align-self: stretch; } }

@media screen and (min-width: 0) {
  .as-fs\@xs\+ {
    align-self: flex-start; }
  .as-fe\@xs\+ {
    align-self: flex-end; }
  .as-c\@xs\+ {
    align-self: center; }
  .as-b\@xs\+ {
    align-self: baseline; }
  .as-s\@xs\+ {
    align-self: stretch; } }

@media screen and (max-width: 1439px) {
  .as-fs\@xl- {
    align-self: flex-start; }
  .as-fe\@xl- {
    align-self: flex-end; }
  .as-c\@xl- {
    align-self: center; }
  .as-b\@xl- {
    align-self: baseline; }
  .as-s\@xl- {
    align-self: stretch; } }

@media screen and (max-width: 1199px) {
  .as-fs\@lg- {
    align-self: flex-start; }
  .as-fe\@lg- {
    align-self: flex-end; }
  .as-c\@lg- {
    align-self: center; }
  .as-b\@lg- {
    align-self: baseline; }
  .as-s\@lg- {
    align-self: stretch; } }

@media screen and (max-width: 991px) {
  .as-fs\@md- {
    align-self: flex-start; }
  .as-fe\@md- {
    align-self: flex-end; }
  .as-c\@md- {
    align-self: center; }
  .as-b\@md- {
    align-self: baseline; }
  .as-s\@md- {
    align-self: stretch; } }

@media screen and (max-width: 767px) {
  .as-fs\@sm- {
    align-self: flex-start; }
  .as-fe\@sm- {
    align-self: flex-end; }
  .as-c\@sm- {
    align-self: center; }
  .as-b\@sm- {
    align-self: baseline; }
  .as-s\@sm- {
    align-self: stretch; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .as-fs\@lg {
    align-self: flex-start; }
  .as-fe\@lg {
    align-self: flex-end; }
  .as-c\@lg {
    align-self: center; }
  .as-b\@lg {
    align-self: baseline; }
  .as-s\@lg {
    align-self: stretch; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .as-fs\@md {
    align-self: flex-start; }
  .as-fe\@md {
    align-self: flex-end; }
  .as-c\@md {
    align-self: center; }
  .as-b\@md {
    align-self: baseline; }
  .as-s\@md {
    align-self: stretch; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .as-fs\@sm {
    align-self: flex-start; }
  .as-fe\@sm {
    align-self: flex-end; }
  .as-c\@sm {
    align-self: center; }
  .as-b\@sm {
    align-self: baseline; }
  .as-s\@sm {
    align-self: stretch; } }

.ac-fs {
  align-content: flex-start; }

.ac-fe {
  align-content: flex-end; }

.ac-c {
  align-content: center; }

.ac-s {
  align-content: stretch; }

.ac-sb {
  align-content: space-between; }

.ac-sa {
  align-content: space-around; }

@media screen and (min-width: 1440px) {
  .ac-fs\@xl\+ {
    align-content: flex-start; }
  .ac-fe\@xl\+ {
    align-content: flex-end; }
  .ac-c\@xl\+ {
    align-content: center; }
  .ac-s\@xl\+ {
    align-content: stretch; }
  .ac-sb\@xl\+ {
    align-content: space-between; }
  .ac-sa\@xl\+ {
    align-content: space-around; } }

@media screen and (min-width: 1200px) {
  .ac-fs\@lg\+ {
    align-content: flex-start; }
  .ac-fe\@lg\+ {
    align-content: flex-end; }
  .ac-c\@lg\+ {
    align-content: center; }
  .ac-s\@lg\+ {
    align-content: stretch; }
  .ac-sb\@lg\+ {
    align-content: space-between; }
  .ac-sa\@lg\+ {
    align-content: space-around; } }

@media screen and (min-width: 992px) {
  .ac-fs\@md\+ {
    align-content: flex-start; }
  .ac-fe\@md\+ {
    align-content: flex-end; }
  .ac-c\@md\+ {
    align-content: center; }
  .ac-s\@md\+ {
    align-content: stretch; }
  .ac-sb\@md\+ {
    align-content: space-between; }
  .ac-sa\@md\+ {
    align-content: space-around; } }

@media screen and (min-width: 768px) {
  .ac-fs\@sm\+ {
    align-content: flex-start; }
  .ac-fe\@sm\+ {
    align-content: flex-end; }
  .ac-c\@sm\+ {
    align-content: center; }
  .ac-s\@sm\+ {
    align-content: stretch; }
  .ac-sb\@sm\+ {
    align-content: space-between; }
  .ac-sa\@sm\+ {
    align-content: space-around; } }

@media screen and (min-width: 0) {
  .ac-fs\@xs\+ {
    align-content: flex-start; }
  .ac-fe\@xs\+ {
    align-content: flex-end; }
  .ac-c\@xs\+ {
    align-content: center; }
  .ac-s\@xs\+ {
    align-content: stretch; }
  .ac-sb\@xs\+ {
    align-content: space-between; }
  .ac-sa\@xs\+ {
    align-content: space-around; } }

@media screen and (max-width: 1439px) {
  .ac-fs\@xl- {
    align-content: flex-start; }
  .ac-fe\@xl- {
    align-content: flex-end; }
  .ac-c\@xl- {
    align-content: center; }
  .ac-s\@xl- {
    align-content: stretch; }
  .ac-sb\@xl- {
    align-content: space-between; }
  .ac-sa\@xl- {
    align-content: space-around; } }

@media screen and (max-width: 1199px) {
  .ac-fs\@lg- {
    align-content: flex-start; }
  .ac-fe\@lg- {
    align-content: flex-end; }
  .ac-c\@lg- {
    align-content: center; }
  .ac-s\@lg- {
    align-content: stretch; }
  .ac-sb\@lg- {
    align-content: space-between; }
  .ac-sa\@lg- {
    align-content: space-around; } }

@media screen and (max-width: 991px) {
  .ac-fs\@md- {
    align-content: flex-start; }
  .ac-fe\@md- {
    align-content: flex-end; }
  .ac-c\@md- {
    align-content: center; }
  .ac-s\@md- {
    align-content: stretch; }
  .ac-sb\@md- {
    align-content: space-between; }
  .ac-sa\@md- {
    align-content: space-around; } }

@media screen and (max-width: 767px) {
  .ac-fs\@sm- {
    align-content: flex-start; }
  .ac-fe\@sm- {
    align-content: flex-end; }
  .ac-c\@sm- {
    align-content: center; }
  .ac-s\@sm- {
    align-content: stretch; }
  .ac-sb\@sm- {
    align-content: space-between; }
  .ac-sa\@sm- {
    align-content: space-around; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ac-fs\@lg {
    align-content: flex-start; }
  .ac-fe\@lg {
    align-content: flex-end; }
  .ac-c\@lg {
    align-content: center; }
  .ac-s\@lg {
    align-content: stretch; }
  .ac-sb\@lg {
    align-content: space-between; }
  .ac-sa\@lg {
    align-content: space-around; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ac-fs\@md {
    align-content: flex-start; }
  .ac-fe\@md {
    align-content: flex-end; }
  .ac-c\@md {
    align-content: center; }
  .ac-s\@md {
    align-content: stretch; }
  .ac-sb\@md {
    align-content: space-between; }
  .ac-sa\@md {
    align-content: space-around; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ac-fs\@sm {
    align-content: flex-start; }
  .ac-fe\@sm {
    align-content: flex-end; }
  .ac-c\@sm {
    align-content: center; }
  .ac-s\@sm {
    align-content: stretch; }
  .ac-sb\@sm {
    align-content: space-between; }
  .ac-sa\@sm {
    align-content: space-around; } }

.jc-fs {
  justify-content: flex-start; }

.jc-fe {
  justify-content: flex-end; }

.jc-c {
  justify-content: center; }

.jc-sb {
  justify-content: space-between; }

.jc-sa {
  justify-content: space-around; }

@media screen and (min-width: 1440px) {
  .jc-fs\@xl\+ {
    justify-content: flex-start; }
  .jc-fe\@xl\+ {
    justify-content: flex-end; }
  .jc-c\@xl\+ {
    justify-content: center; }
  .jc-sb\@xl\+ {
    justify-content: space-between; }
  .jc-sa\@xl\+ {
    justify-content: space-around; } }

@media screen and (min-width: 1200px) {
  .jc-fs\@lg\+ {
    justify-content: flex-start; }
  .jc-fe\@lg\+ {
    justify-content: flex-end; }
  .jc-c\@lg\+ {
    justify-content: center; }
  .jc-sb\@lg\+ {
    justify-content: space-between; }
  .jc-sa\@lg\+ {
    justify-content: space-around; } }

@media screen and (min-width: 992px) {
  .jc-fs\@md\+ {
    justify-content: flex-start; }
  .jc-fe\@md\+ {
    justify-content: flex-end; }
  .jc-c\@md\+ {
    justify-content: center; }
  .jc-sb\@md\+ {
    justify-content: space-between; }
  .jc-sa\@md\+ {
    justify-content: space-around; } }

@media screen and (min-width: 768px) {
  .jc-fs\@sm\+ {
    justify-content: flex-start; }
  .jc-fe\@sm\+ {
    justify-content: flex-end; }
  .jc-c\@sm\+ {
    justify-content: center; }
  .jc-sb\@sm\+ {
    justify-content: space-between; }
  .jc-sa\@sm\+ {
    justify-content: space-around; } }

@media screen and (min-width: 0) {
  .jc-fs\@xs\+ {
    justify-content: flex-start; }
  .jc-fe\@xs\+ {
    justify-content: flex-end; }
  .jc-c\@xs\+ {
    justify-content: center; }
  .jc-sb\@xs\+ {
    justify-content: space-between; }
  .jc-sa\@xs\+ {
    justify-content: space-around; } }

@media screen and (max-width: 1439px) {
  .jc-fs\@xl- {
    justify-content: flex-start; }
  .jc-fe\@xl- {
    justify-content: flex-end; }
  .jc-c\@xl- {
    justify-content: center; }
  .jc-sb\@xl- {
    justify-content: space-between; }
  .jc-sa\@xl- {
    justify-content: space-around; } }

@media screen and (max-width: 1199px) {
  .jc-fs\@lg- {
    justify-content: flex-start; }
  .jc-fe\@lg- {
    justify-content: flex-end; }
  .jc-c\@lg- {
    justify-content: center; }
  .jc-sb\@lg- {
    justify-content: space-between; }
  .jc-sa\@lg- {
    justify-content: space-around; } }

@media screen and (max-width: 991px) {
  .jc-fs\@md- {
    justify-content: flex-start; }
  .jc-fe\@md- {
    justify-content: flex-end; }
  .jc-c\@md- {
    justify-content: center; }
  .jc-sb\@md- {
    justify-content: space-between; }
  .jc-sa\@md- {
    justify-content: space-around; } }

@media screen and (max-width: 767px) {
  .jc-fs\@sm- {
    justify-content: flex-start; }
  .jc-fe\@sm- {
    justify-content: flex-end; }
  .jc-c\@sm- {
    justify-content: center; }
  .jc-sb\@sm- {
    justify-content: space-between; }
  .jc-sa\@sm- {
    justify-content: space-around; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .jc-fs\@lg {
    justify-content: flex-start; }
  .jc-fe\@lg {
    justify-content: flex-end; }
  .jc-c\@lg {
    justify-content: center; }
  .jc-sb\@lg {
    justify-content: space-between; }
  .jc-sa\@lg {
    justify-content: space-around; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .jc-fs\@md {
    justify-content: flex-start; }
  .jc-fe\@md {
    justify-content: flex-end; }
  .jc-c\@md {
    justify-content: center; }
  .jc-sb\@md {
    justify-content: space-between; }
  .jc-sa\@md {
    justify-content: space-around; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .jc-fs\@sm {
    justify-content: flex-start; }
  .jc-fe\@sm {
    justify-content: flex-end; }
  .jc-c\@sm {
    justify-content: center; }
  .jc-sb\@sm {
    justify-content: space-between; }
  .jc-sa\@sm {
    justify-content: space-around; } }

.fx-n {
  flex: none; }

.fx-1 {
  flex: 1; }

@media screen and (min-width: 1440px) {
  .fx-n\@xl\+ {
    flex: none; }
  .fx-1\@xl\+ {
    flex: 1; } }

@media screen and (min-width: 1200px) {
  .fx-n\@lg\+ {
    flex: none; }
  .fx-1\@lg\+ {
    flex: 1; } }

@media screen and (min-width: 992px) {
  .fx-n\@md\+ {
    flex: none; }
  .fx-1\@md\+ {
    flex: 1; } }

@media screen and (min-width: 768px) {
  .fx-n\@sm\+ {
    flex: none; }
  .fx-1\@sm\+ {
    flex: 1; } }

@media screen and (min-width: 0) {
  .fx-n\@xs\+ {
    flex: none; }
  .fx-1\@xs\+ {
    flex: 1; } }

@media screen and (max-width: 1439px) {
  .fx-n\@xl- {
    flex: none; }
  .fx-1\@xl- {
    flex: 1; } }

@media screen and (max-width: 1199px) {
  .fx-n\@lg- {
    flex: none; }
  .fx-1\@lg- {
    flex: 1; } }

@media screen and (max-width: 991px) {
  .fx-n\@md- {
    flex: none; }
  .fx-1\@md- {
    flex: 1; } }

@media screen and (max-width: 767px) {
  .fx-n\@sm- {
    flex: none; }
  .fx-1\@sm- {
    flex: 1; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fx-n\@lg {
    flex: none; }
  .fx-1\@lg {
    flex: 1; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fx-n\@md {
    flex: none; }
  .fx-1\@md {
    flex: 1; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fx-n\@sm {
    flex: none; }
  .fx-1\@sm {
    flex: 1; } }

.fxb-a {
  flex-basis: auto; }

.fxb-0 {
  flex-basis: 0; }

@media screen and (min-width: 1440px) {
  .fxb-a\@xl\+ {
    flex-basis: auto; }
  .fxb-0\@xl\+ {
    flex-basis: 0; } }

@media screen and (min-width: 1200px) {
  .fxb-a\@lg\+ {
    flex-basis: auto; }
  .fxb-0\@lg\+ {
    flex-basis: 0; } }

@media screen and (min-width: 992px) {
  .fxb-a\@md\+ {
    flex-basis: auto; }
  .fxb-0\@md\+ {
    flex-basis: 0; } }

@media screen and (min-width: 768px) {
  .fxb-a\@sm\+ {
    flex-basis: auto; }
  .fxb-0\@sm\+ {
    flex-basis: 0; } }

@media screen and (min-width: 0) {
  .fxb-a\@xs\+ {
    flex-basis: auto; }
  .fxb-0\@xs\+ {
    flex-basis: 0; } }

@media screen and (max-width: 1439px) {
  .fxb-a\@xl- {
    flex-basis: auto; }
  .fxb-0\@xl- {
    flex-basis: 0; } }

@media screen and (max-width: 1199px) {
  .fxb-a\@lg- {
    flex-basis: auto; }
  .fxb-0\@lg- {
    flex-basis: 0; } }

@media screen and (max-width: 991px) {
  .fxb-a\@md- {
    flex-basis: auto; }
  .fxb-0\@md- {
    flex-basis: 0; } }

@media screen and (max-width: 767px) {
  .fxb-a\@sm- {
    flex-basis: auto; }
  .fxb-0\@sm- {
    flex-basis: 0; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fxb-a\@lg {
    flex-basis: auto; }
  .fxb-0\@lg {
    flex-basis: 0; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fxb-a\@md {
    flex-basis: auto; }
  .fxb-0\@md {
    flex-basis: 0; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fxb-a\@sm {
    flex-basis: auto; }
  .fxb-0\@sm {
    flex-basis: 0; } }

.fxg-1 {
  flex-grow: 1; }

.fxg-0 {
  flex-grow: 0; }

@media screen and (min-width: 1440px) {
  .fxg-1\@xl\+ {
    flex-grow: 1; }
  .fxg-0\@xl\+ {
    flex-grow: 0; } }

@media screen and (min-width: 1200px) {
  .fxg-1\@lg\+ {
    flex-grow: 1; }
  .fxg-0\@lg\+ {
    flex-grow: 0; } }

@media screen and (min-width: 992px) {
  .fxg-1\@md\+ {
    flex-grow: 1; }
  .fxg-0\@md\+ {
    flex-grow: 0; } }

@media screen and (min-width: 768px) {
  .fxg-1\@sm\+ {
    flex-grow: 1; }
  .fxg-0\@sm\+ {
    flex-grow: 0; } }

@media screen and (min-width: 0) {
  .fxg-1\@xs\+ {
    flex-grow: 1; }
  .fxg-0\@xs\+ {
    flex-grow: 0; } }

@media screen and (max-width: 1439px) {
  .fxg-1\@xl- {
    flex-grow: 1; }
  .fxg-0\@xl- {
    flex-grow: 0; } }

@media screen and (max-width: 1199px) {
  .fxg-1\@lg- {
    flex-grow: 1; }
  .fxg-0\@lg- {
    flex-grow: 0; } }

@media screen and (max-width: 991px) {
  .fxg-1\@md- {
    flex-grow: 1; }
  .fxg-0\@md- {
    flex-grow: 0; } }

@media screen and (max-width: 767px) {
  .fxg-1\@sm- {
    flex-grow: 1; }
  .fxg-0\@sm- {
    flex-grow: 0; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fxg-1\@lg {
    flex-grow: 1; }
  .fxg-0\@lg {
    flex-grow: 0; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fxg-1\@md {
    flex-grow: 1; }
  .fxg-0\@md {
    flex-grow: 0; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fxg-1\@sm {
    flex-grow: 1; }
  .fxg-0\@sm {
    flex-grow: 0; } }

.fxs-1 {
  flex-shrink: 1; }

.fxs-0 {
  flex-shrink: 0; }

@media screen and (min-width: 1440px) {
  .fxs-1\@xl\+ {
    flex-shrink: 1; }
  .fxs-0\@xl\+ {
    flex-shrink: 0; } }

@media screen and (min-width: 1200px) {
  .fxs-1\@lg\+ {
    flex-shrink: 1; }
  .fxs-0\@lg\+ {
    flex-shrink: 0; } }

@media screen and (min-width: 992px) {
  .fxs-1\@md\+ {
    flex-shrink: 1; }
  .fxs-0\@md\+ {
    flex-shrink: 0; } }

@media screen and (min-width: 768px) {
  .fxs-1\@sm\+ {
    flex-shrink: 1; }
  .fxs-0\@sm\+ {
    flex-shrink: 0; } }

@media screen and (min-width: 0) {
  .fxs-1\@xs\+ {
    flex-shrink: 1; }
  .fxs-0\@xs\+ {
    flex-shrink: 0; } }

@media screen and (max-width: 1439px) {
  .fxs-1\@xl- {
    flex-shrink: 1; }
  .fxs-0\@xl- {
    flex-shrink: 0; } }

@media screen and (max-width: 1199px) {
  .fxs-1\@lg- {
    flex-shrink: 1; }
  .fxs-0\@lg- {
    flex-shrink: 0; } }

@media screen and (max-width: 991px) {
  .fxs-1\@md- {
    flex-shrink: 1; }
  .fxs-0\@md- {
    flex-shrink: 0; } }

@media screen and (max-width: 767px) {
  .fxs-1\@sm- {
    flex-shrink: 1; }
  .fxs-0\@sm- {
    flex-shrink: 0; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fxs-1\@lg {
    flex-shrink: 1; }
  .fxs-0\@lg {
    flex-shrink: 0; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fxs-1\@md {
    flex-shrink: 1; }
  .fxs-0\@md {
    flex-shrink: 0; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fxs-1\@sm {
    flex-shrink: 1; }
  .fxs-0\@sm {
    flex-shrink: 0; } }

.ord-0 {
  order: 0; }

@media screen and (min-width: 1440px) {
  .ord-0\@xl\+ {
    order: 0; } }

@media screen and (min-width: 1200px) {
  .ord-0\@lg\+ {
    order: 0; } }

@media screen and (min-width: 992px) {
  .ord-0\@md\+ {
    order: 0; } }

@media screen and (min-width: 768px) {
  .ord-0\@sm\+ {
    order: 0; } }

@media screen and (min-width: 0) {
  .ord-0\@xs\+ {
    order: 0; } }

@media screen and (max-width: 1439px) {
  .ord-0\@xl- {
    order: 0; } }

@media screen and (max-width: 1199px) {
  .ord-0\@lg- {
    order: 0; } }

@media screen and (max-width: 991px) {
  .ord-0\@md- {
    order: 0; } }

@media screen and (max-width: 767px) {
  .ord-0\@sm- {
    order: 0; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-0\@lg {
    order: 0; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-0\@md {
    order: 0; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-0\@sm {
    order: 0; } }

.ord-1 {
  order: 1; }

@media screen and (min-width: 1440px) {
  .ord-1\@xl\+ {
    order: 1; } }

@media screen and (min-width: 1200px) {
  .ord-1\@lg\+ {
    order: 1; } }

@media screen and (min-width: 992px) {
  .ord-1\@md\+ {
    order: 1; } }

@media screen and (min-width: 768px) {
  .ord-1\@sm\+ {
    order: 1; } }

@media screen and (min-width: 0) {
  .ord-1\@xs\+ {
    order: 1; } }

@media screen and (max-width: 1439px) {
  .ord-1\@xl- {
    order: 1; } }

@media screen and (max-width: 1199px) {
  .ord-1\@lg- {
    order: 1; } }

@media screen and (max-width: 991px) {
  .ord-1\@md- {
    order: 1; } }

@media screen and (max-width: 767px) {
  .ord-1\@sm- {
    order: 1; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-1\@lg {
    order: 1; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-1\@md {
    order: 1; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-1\@sm {
    order: 1; } }

.ord-2 {
  order: 2; }

@media screen and (min-width: 1440px) {
  .ord-2\@xl\+ {
    order: 2; } }

@media screen and (min-width: 1200px) {
  .ord-2\@lg\+ {
    order: 2; } }

@media screen and (min-width: 992px) {
  .ord-2\@md\+ {
    order: 2; } }

@media screen and (min-width: 768px) {
  .ord-2\@sm\+ {
    order: 2; } }

@media screen and (min-width: 0) {
  .ord-2\@xs\+ {
    order: 2; } }

@media screen and (max-width: 1439px) {
  .ord-2\@xl- {
    order: 2; } }

@media screen and (max-width: 1199px) {
  .ord-2\@lg- {
    order: 2; } }

@media screen and (max-width: 991px) {
  .ord-2\@md- {
    order: 2; } }

@media screen and (max-width: 767px) {
  .ord-2\@sm- {
    order: 2; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-2\@lg {
    order: 2; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-2\@md {
    order: 2; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-2\@sm {
    order: 2; } }

.ord-3 {
  order: 3; }

@media screen and (min-width: 1440px) {
  .ord-3\@xl\+ {
    order: 3; } }

@media screen and (min-width: 1200px) {
  .ord-3\@lg\+ {
    order: 3; } }

@media screen and (min-width: 992px) {
  .ord-3\@md\+ {
    order: 3; } }

@media screen and (min-width: 768px) {
  .ord-3\@sm\+ {
    order: 3; } }

@media screen and (min-width: 0) {
  .ord-3\@xs\+ {
    order: 3; } }

@media screen and (max-width: 1439px) {
  .ord-3\@xl- {
    order: 3; } }

@media screen and (max-width: 1199px) {
  .ord-3\@lg- {
    order: 3; } }

@media screen and (max-width: 991px) {
  .ord-3\@md- {
    order: 3; } }

@media screen and (max-width: 767px) {
  .ord-3\@sm- {
    order: 3; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-3\@lg {
    order: 3; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-3\@md {
    order: 3; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-3\@sm {
    order: 3; } }

.ord-4 {
  order: 4; }

@media screen and (min-width: 1440px) {
  .ord-4\@xl\+ {
    order: 4; } }

@media screen and (min-width: 1200px) {
  .ord-4\@lg\+ {
    order: 4; } }

@media screen and (min-width: 992px) {
  .ord-4\@md\+ {
    order: 4; } }

@media screen and (min-width: 768px) {
  .ord-4\@sm\+ {
    order: 4; } }

@media screen and (min-width: 0) {
  .ord-4\@xs\+ {
    order: 4; } }

@media screen and (max-width: 1439px) {
  .ord-4\@xl- {
    order: 4; } }

@media screen and (max-width: 1199px) {
  .ord-4\@lg- {
    order: 4; } }

@media screen and (max-width: 991px) {
  .ord-4\@md- {
    order: 4; } }

@media screen and (max-width: 767px) {
  .ord-4\@sm- {
    order: 4; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-4\@lg {
    order: 4; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-4\@md {
    order: 4; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-4\@sm {
    order: 4; } }

.ord-5 {
  order: 5; }

@media screen and (min-width: 1440px) {
  .ord-5\@xl\+ {
    order: 5; } }

@media screen and (min-width: 1200px) {
  .ord-5\@lg\+ {
    order: 5; } }

@media screen and (min-width: 992px) {
  .ord-5\@md\+ {
    order: 5; } }

@media screen and (min-width: 768px) {
  .ord-5\@sm\+ {
    order: 5; } }

@media screen and (min-width: 0) {
  .ord-5\@xs\+ {
    order: 5; } }

@media screen and (max-width: 1439px) {
  .ord-5\@xl- {
    order: 5; } }

@media screen and (max-width: 1199px) {
  .ord-5\@lg- {
    order: 5; } }

@media screen and (max-width: 991px) {
  .ord-5\@md- {
    order: 5; } }

@media screen and (max-width: 767px) {
  .ord-5\@sm- {
    order: 5; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-5\@lg {
    order: 5; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-5\@md {
    order: 5; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-5\@sm {
    order: 5; } }

.ord-6 {
  order: 6; }

@media screen and (min-width: 1440px) {
  .ord-6\@xl\+ {
    order: 6; } }

@media screen and (min-width: 1200px) {
  .ord-6\@lg\+ {
    order: 6; } }

@media screen and (min-width: 992px) {
  .ord-6\@md\+ {
    order: 6; } }

@media screen and (min-width: 768px) {
  .ord-6\@sm\+ {
    order: 6; } }

@media screen and (min-width: 0) {
  .ord-6\@xs\+ {
    order: 6; } }

@media screen and (max-width: 1439px) {
  .ord-6\@xl- {
    order: 6; } }

@media screen and (max-width: 1199px) {
  .ord-6\@lg- {
    order: 6; } }

@media screen and (max-width: 991px) {
  .ord-6\@md- {
    order: 6; } }

@media screen and (max-width: 767px) {
  .ord-6\@sm- {
    order: 6; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-6\@lg {
    order: 6; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-6\@md {
    order: 6; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-6\@sm {
    order: 6; } }

.ord-7 {
  order: 7; }

@media screen and (min-width: 1440px) {
  .ord-7\@xl\+ {
    order: 7; } }

@media screen and (min-width: 1200px) {
  .ord-7\@lg\+ {
    order: 7; } }

@media screen and (min-width: 992px) {
  .ord-7\@md\+ {
    order: 7; } }

@media screen and (min-width: 768px) {
  .ord-7\@sm\+ {
    order: 7; } }

@media screen and (min-width: 0) {
  .ord-7\@xs\+ {
    order: 7; } }

@media screen and (max-width: 1439px) {
  .ord-7\@xl- {
    order: 7; } }

@media screen and (max-width: 1199px) {
  .ord-7\@lg- {
    order: 7; } }

@media screen and (max-width: 991px) {
  .ord-7\@md- {
    order: 7; } }

@media screen and (max-width: 767px) {
  .ord-7\@sm- {
    order: 7; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-7\@lg {
    order: 7; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-7\@md {
    order: 7; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-7\@sm {
    order: 7; } }

.ord-8 {
  order: 8; }

@media screen and (min-width: 1440px) {
  .ord-8\@xl\+ {
    order: 8; } }

@media screen and (min-width: 1200px) {
  .ord-8\@lg\+ {
    order: 8; } }

@media screen and (min-width: 992px) {
  .ord-8\@md\+ {
    order: 8; } }

@media screen and (min-width: 768px) {
  .ord-8\@sm\+ {
    order: 8; } }

@media screen and (min-width: 0) {
  .ord-8\@xs\+ {
    order: 8; } }

@media screen and (max-width: 1439px) {
  .ord-8\@xl- {
    order: 8; } }

@media screen and (max-width: 1199px) {
  .ord-8\@lg- {
    order: 8; } }

@media screen and (max-width: 991px) {
  .ord-8\@md- {
    order: 8; } }

@media screen and (max-width: 767px) {
  .ord-8\@sm- {
    order: 8; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-8\@lg {
    order: 8; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-8\@md {
    order: 8; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-8\@sm {
    order: 8; } }

.ord-9 {
  order: 9; }

@media screen and (min-width: 1440px) {
  .ord-9\@xl\+ {
    order: 9; } }

@media screen and (min-width: 1200px) {
  .ord-9\@lg\+ {
    order: 9; } }

@media screen and (min-width: 992px) {
  .ord-9\@md\+ {
    order: 9; } }

@media screen and (min-width: 768px) {
  .ord-9\@sm\+ {
    order: 9; } }

@media screen and (min-width: 0) {
  .ord-9\@xs\+ {
    order: 9; } }

@media screen and (max-width: 1439px) {
  .ord-9\@xl- {
    order: 9; } }

@media screen and (max-width: 1199px) {
  .ord-9\@lg- {
    order: 9; } }

@media screen and (max-width: 991px) {
  .ord-9\@md- {
    order: 9; } }

@media screen and (max-width: 767px) {
  .ord-9\@sm- {
    order: 9; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-9\@lg {
    order: 9; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-9\@md {
    order: 9; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-9\@sm {
    order: 9; } }

.ord-10 {
  order: 10; }

@media screen and (min-width: 1440px) {
  .ord-10\@xl\+ {
    order: 10; } }

@media screen and (min-width: 1200px) {
  .ord-10\@lg\+ {
    order: 10; } }

@media screen and (min-width: 992px) {
  .ord-10\@md\+ {
    order: 10; } }

@media screen and (min-width: 768px) {
  .ord-10\@sm\+ {
    order: 10; } }

@media screen and (min-width: 0) {
  .ord-10\@xs\+ {
    order: 10; } }

@media screen and (max-width: 1439px) {
  .ord-10\@xl- {
    order: 10; } }

@media screen and (max-width: 1199px) {
  .ord-10\@lg- {
    order: 10; } }

@media screen and (max-width: 991px) {
  .ord-10\@md- {
    order: 10; } }

@media screen and (max-width: 767px) {
  .ord-10\@sm- {
    order: 10; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-10\@lg {
    order: 10; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-10\@md {
    order: 10; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-10\@sm {
    order: 10; } }

.ord-11 {
  order: 11; }

@media screen and (min-width: 1440px) {
  .ord-11\@xl\+ {
    order: 11; } }

@media screen and (min-width: 1200px) {
  .ord-11\@lg\+ {
    order: 11; } }

@media screen and (min-width: 992px) {
  .ord-11\@md\+ {
    order: 11; } }

@media screen and (min-width: 768px) {
  .ord-11\@sm\+ {
    order: 11; } }

@media screen and (min-width: 0) {
  .ord-11\@xs\+ {
    order: 11; } }

@media screen and (max-width: 1439px) {
  .ord-11\@xl- {
    order: 11; } }

@media screen and (max-width: 1199px) {
  .ord-11\@lg- {
    order: 11; } }

@media screen and (max-width: 991px) {
  .ord-11\@md- {
    order: 11; } }

@media screen and (max-width: 767px) {
  .ord-11\@sm- {
    order: 11; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-11\@lg {
    order: 11; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-11\@md {
    order: 11; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-11\@sm {
    order: 11; } }

.ord-12 {
  order: 12; }

@media screen and (min-width: 1440px) {
  .ord-12\@xl\+ {
    order: 12; } }

@media screen and (min-width: 1200px) {
  .ord-12\@lg\+ {
    order: 12; } }

@media screen and (min-width: 992px) {
  .ord-12\@md\+ {
    order: 12; } }

@media screen and (min-width: 768px) {
  .ord-12\@sm\+ {
    order: 12; } }

@media screen and (min-width: 0) {
  .ord-12\@xs\+ {
    order: 12; } }

@media screen and (max-width: 1439px) {
  .ord-12\@xl- {
    order: 12; } }

@media screen and (max-width: 1199px) {
  .ord-12\@lg- {
    order: 12; } }

@media screen and (max-width: 991px) {
  .ord-12\@md- {
    order: 12; } }

@media screen and (max-width: 767px) {
  .ord-12\@sm- {
    order: 12; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ord-12\@lg {
    order: 12; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ord-12\@md {
    order: 12; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ord-12\@sm {
    order: 12; } }

.d-b {
  display: block !important; }

.d-ib {
  display: inline-block !important; }

.d-i {
  display: inline !important; }

.d-tb {
  display: table !important; }

.d-tbc {
  display: table-cell !important; }

.d-n {
  display: none !important; }

@media screen and (min-width: 1440px) {
  .d-b\@xl\+ {
    display: block !important; }
  .d-ib\@xl\+ {
    display: inline-block !important; }
  .d-i\@xl\+ {
    display: inline !important; }
  .d-tb\@xl\+ {
    display: table !important; }
  .d-tbc\@xl\+ {
    display: table-cell !important; }
  .d-n\@xl\+ {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .d-b\@lg\+ {
    display: block !important; }
  .d-ib\@lg\+ {
    display: inline-block !important; }
  .d-i\@lg\+ {
    display: inline !important; }
  .d-tb\@lg\+ {
    display: table !important; }
  .d-tbc\@lg\+ {
    display: table-cell !important; }
  .d-n\@lg\+ {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .d-b\@md\+ {
    display: block !important; }
  .d-ib\@md\+ {
    display: inline-block !important; }
  .d-i\@md\+ {
    display: inline !important; }
  .d-tb\@md\+ {
    display: table !important; }
  .d-tbc\@md\+ {
    display: table-cell !important; }
  .d-n\@md\+ {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .d-b\@sm\+ {
    display: block !important; }
  .d-ib\@sm\+ {
    display: inline-block !important; }
  .d-i\@sm\+ {
    display: inline !important; }
  .d-tb\@sm\+ {
    display: table !important; }
  .d-tbc\@sm\+ {
    display: table-cell !important; }
  .d-n\@sm\+ {
    display: none !important; } }

@media screen and (min-width: 0) {
  .d-b\@xs\+ {
    display: block !important; }
  .d-ib\@xs\+ {
    display: inline-block !important; }
  .d-i\@xs\+ {
    display: inline !important; }
  .d-tb\@xs\+ {
    display: table !important; }
  .d-tbc\@xs\+ {
    display: table-cell !important; }
  .d-n\@xs\+ {
    display: none !important; } }

@media screen and (max-width: 1439px) {
  .d-b\@xl- {
    display: block !important; }
  .d-ib\@xl- {
    display: inline-block !important; }
  .d-i\@xl- {
    display: inline !important; }
  .d-tb\@xl- {
    display: table !important; }
  .d-tbc\@xl- {
    display: table-cell !important; }
  .d-n\@xl- {
    display: none !important; } }

@media screen and (max-width: 1199px) {
  .d-b\@lg- {
    display: block !important; }
  .d-ib\@lg- {
    display: inline-block !important; }
  .d-i\@lg- {
    display: inline !important; }
  .d-tb\@lg- {
    display: table !important; }
  .d-tbc\@lg- {
    display: table-cell !important; }
  .d-n\@lg- {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .d-b\@md- {
    display: block !important; }
  .d-ib\@md- {
    display: inline-block !important; }
  .d-i\@md- {
    display: inline !important; }
  .d-tb\@md- {
    display: table !important; }
  .d-tbc\@md- {
    display: table-cell !important; }
  .d-n\@md- {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .d-b\@sm- {
    display: block !important; }
  .d-ib\@sm- {
    display: inline-block !important; }
  .d-i\@sm- {
    display: inline !important; }
  .d-tb\@sm- {
    display: table !important; }
  .d-tbc\@sm- {
    display: table-cell !important; }
  .d-n\@sm- {
    display: none !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .d-b\@lg {
    display: block !important; }
  .d-ib\@lg {
    display: inline-block !important; }
  .d-i\@lg {
    display: inline !important; }
  .d-tb\@lg {
    display: table !important; }
  .d-tbc\@lg {
    display: table-cell !important; }
  .d-n\@lg {
    display: none !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .d-b\@md {
    display: block !important; }
  .d-ib\@md {
    display: inline-block !important; }
  .d-i\@md {
    display: inline !important; }
  .d-tb\@md {
    display: table !important; }
  .d-tbc\@md {
    display: table-cell !important; }
  .d-n\@md {
    display: none !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .d-b\@sm {
    display: block !important; }
  .d-ib\@sm {
    display: inline-block !important; }
  .d-i\@sm {
    display: inline !important; }
  .d-tb\@sm {
    display: table !important; }
  .d-tbc\@sm {
    display: table-cell !important; }
  .d-n\@sm {
    display: none !important; } }

.ov-h {
  overflow: hidden !important; }

.ov-a {
  overflow: auto !important; }

.ov-s {
  overflow: scroll !important; }

.ovY-h {
  overflow-y: hidden !important; }

.ovX-h {
  overflow-x: hidden !important; }

.ovY-a {
  overflow-y: auto !important; }

.ovX-a {
  overflow-x: auto !important; }

.ovY-s {
  overflow-y: scroll !important; }

.ovX-s {
  overflow-x: scroll !important; }

@media screen and (min-width: 1440px) {
  .ov-h\@xl\+ {
    overflow: hidden !important; }
  .ov-a\@xl\+ {
    overflow: auto !important; }
  .ov-s\@xl\+ {
    overflow: scroll !important; }
  .ovY-h\@xl\+ {
    overflow-y: hidden !important; }
  .ovX-h\@xl\+ {
    overflow-x: hidden !important; }
  .ovY-a\@xl\+ {
    overflow-y: auto !important; }
  .ovX-a\@xl\+ {
    overflow-x: auto !important; }
  .ovY-s\@xl\+ {
    overflow-y: scroll !important; }
  .ovX-s\@xl\+ {
    overflow-x: scroll !important; } }

@media screen and (min-width: 1200px) {
  .ov-h\@lg\+ {
    overflow: hidden !important; }
  .ov-a\@lg\+ {
    overflow: auto !important; }
  .ov-s\@lg\+ {
    overflow: scroll !important; }
  .ovY-h\@lg\+ {
    overflow-y: hidden !important; }
  .ovX-h\@lg\+ {
    overflow-x: hidden !important; }
  .ovY-a\@lg\+ {
    overflow-y: auto !important; }
  .ovX-a\@lg\+ {
    overflow-x: auto !important; }
  .ovY-s\@lg\+ {
    overflow-y: scroll !important; }
  .ovX-s\@lg\+ {
    overflow-x: scroll !important; } }

@media screen and (min-width: 992px) {
  .ov-h\@md\+ {
    overflow: hidden !important; }
  .ov-a\@md\+ {
    overflow: auto !important; }
  .ov-s\@md\+ {
    overflow: scroll !important; }
  .ovY-h\@md\+ {
    overflow-y: hidden !important; }
  .ovX-h\@md\+ {
    overflow-x: hidden !important; }
  .ovY-a\@md\+ {
    overflow-y: auto !important; }
  .ovX-a\@md\+ {
    overflow-x: auto !important; }
  .ovY-s\@md\+ {
    overflow-y: scroll !important; }
  .ovX-s\@md\+ {
    overflow-x: scroll !important; } }

@media screen and (min-width: 768px) {
  .ov-h\@sm\+ {
    overflow: hidden !important; }
  .ov-a\@sm\+ {
    overflow: auto !important; }
  .ov-s\@sm\+ {
    overflow: scroll !important; }
  .ovY-h\@sm\+ {
    overflow-y: hidden !important; }
  .ovX-h\@sm\+ {
    overflow-x: hidden !important; }
  .ovY-a\@sm\+ {
    overflow-y: auto !important; }
  .ovX-a\@sm\+ {
    overflow-x: auto !important; }
  .ovY-s\@sm\+ {
    overflow-y: scroll !important; }
  .ovX-s\@sm\+ {
    overflow-x: scroll !important; } }

@media screen and (min-width: 0) {
  .ov-h\@xs\+ {
    overflow: hidden !important; }
  .ov-a\@xs\+ {
    overflow: auto !important; }
  .ov-s\@xs\+ {
    overflow: scroll !important; }
  .ovY-h\@xs\+ {
    overflow-y: hidden !important; }
  .ovX-h\@xs\+ {
    overflow-x: hidden !important; }
  .ovY-a\@xs\+ {
    overflow-y: auto !important; }
  .ovX-a\@xs\+ {
    overflow-x: auto !important; }
  .ovY-s\@xs\+ {
    overflow-y: scroll !important; }
  .ovX-s\@xs\+ {
    overflow-x: scroll !important; } }

@media screen and (max-width: 1439px) {
  .ov-h\@xl- {
    overflow: hidden !important; }
  .ov-a\@xl- {
    overflow: auto !important; }
  .ov-s\@xl- {
    overflow: scroll !important; }
  .ovY-h\@xl- {
    overflow-y: hidden !important; }
  .ovX-h\@xl- {
    overflow-x: hidden !important; }
  .ovY-a\@xl- {
    overflow-y: auto !important; }
  .ovX-a\@xl- {
    overflow-x: auto !important; }
  .ovY-s\@xl- {
    overflow-y: scroll !important; }
  .ovX-s\@xl- {
    overflow-x: scroll !important; } }

@media screen and (max-width: 1199px) {
  .ov-h\@lg- {
    overflow: hidden !important; }
  .ov-a\@lg- {
    overflow: auto !important; }
  .ov-s\@lg- {
    overflow: scroll !important; }
  .ovY-h\@lg- {
    overflow-y: hidden !important; }
  .ovX-h\@lg- {
    overflow-x: hidden !important; }
  .ovY-a\@lg- {
    overflow-y: auto !important; }
  .ovX-a\@lg- {
    overflow-x: auto !important; }
  .ovY-s\@lg- {
    overflow-y: scroll !important; }
  .ovX-s\@lg- {
    overflow-x: scroll !important; } }

@media screen and (max-width: 991px) {
  .ov-h\@md- {
    overflow: hidden !important; }
  .ov-a\@md- {
    overflow: auto !important; }
  .ov-s\@md- {
    overflow: scroll !important; }
  .ovY-h\@md- {
    overflow-y: hidden !important; }
  .ovX-h\@md- {
    overflow-x: hidden !important; }
  .ovY-a\@md- {
    overflow-y: auto !important; }
  .ovX-a\@md- {
    overflow-x: auto !important; }
  .ovY-s\@md- {
    overflow-y: scroll !important; }
  .ovX-s\@md- {
    overflow-x: scroll !important; } }

@media screen and (max-width: 767px) {
  .ov-h\@sm- {
    overflow: hidden !important; }
  .ov-a\@sm- {
    overflow: auto !important; }
  .ov-s\@sm- {
    overflow: scroll !important; }
  .ovY-h\@sm- {
    overflow-y: hidden !important; }
  .ovX-h\@sm- {
    overflow-x: hidden !important; }
  .ovY-a\@sm- {
    overflow-y: auto !important; }
  .ovX-a\@sm- {
    overflow-x: auto !important; }
  .ovY-s\@sm- {
    overflow-y: scroll !important; }
  .ovX-s\@sm- {
    overflow-x: scroll !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ov-h\@lg {
    overflow: hidden !important; }
  .ov-a\@lg {
    overflow: auto !important; }
  .ov-s\@lg {
    overflow: scroll !important; }
  .ovY-h\@lg {
    overflow-y: hidden !important; }
  .ovX-h\@lg {
    overflow-x: hidden !important; }
  .ovY-a\@lg {
    overflow-y: auto !important; }
  .ovX-a\@lg {
    overflow-x: auto !important; }
  .ovY-s\@lg {
    overflow-y: scroll !important; }
  .ovX-s\@lg {
    overflow-x: scroll !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ov-h\@md {
    overflow: hidden !important; }
  .ov-a\@md {
    overflow: auto !important; }
  .ov-s\@md {
    overflow: scroll !important; }
  .ovY-h\@md {
    overflow-y: hidden !important; }
  .ovX-h\@md {
    overflow-x: hidden !important; }
  .ovY-a\@md {
    overflow-y: auto !important; }
  .ovX-a\@md {
    overflow-x: auto !important; }
  .ovY-s\@md {
    overflow-y: scroll !important; }
  .ovX-s\@md {
    overflow-x: scroll !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ov-h\@sm {
    overflow: hidden !important; }
  .ov-a\@sm {
    overflow: auto !important; }
  .ov-s\@sm {
    overflow: scroll !important; }
  .ovY-h\@sm {
    overflow-y: hidden !important; }
  .ovX-h\@sm {
    overflow-x: hidden !important; }
  .ovY-a\@sm {
    overflow-y: auto !important; }
  .ovX-a\@sm {
    overflow-x: auto !important; }
  .ovY-s\@sm {
    overflow-y: scroll !important; }
  .ovX-s\@sm {
    overflow-x: scroll !important; } }

.fl-l {
  float: left !important; }

.fl-r {
  float: right !important; }

.fl-n {
  float: none !important; }

@media screen and (min-width: 1440px) {
  .fl-l\@xl\+ {
    float: left !important; }
  .fl-r\@xl\+ {
    float: right !important; }
  .fl-n\@xl\+ {
    float: none !important; } }

@media screen and (min-width: 1200px) {
  .fl-l\@lg\+ {
    float: left !important; }
  .fl-r\@lg\+ {
    float: right !important; }
  .fl-n\@lg\+ {
    float: none !important; } }

@media screen and (min-width: 992px) {
  .fl-l\@md\+ {
    float: left !important; }
  .fl-r\@md\+ {
    float: right !important; }
  .fl-n\@md\+ {
    float: none !important; } }

@media screen and (min-width: 768px) {
  .fl-l\@sm\+ {
    float: left !important; }
  .fl-r\@sm\+ {
    float: right !important; }
  .fl-n\@sm\+ {
    float: none !important; } }

@media screen and (min-width: 0) {
  .fl-l\@xs\+ {
    float: left !important; }
  .fl-r\@xs\+ {
    float: right !important; }
  .fl-n\@xs\+ {
    float: none !important; } }

@media screen and (max-width: 1439px) {
  .fl-l\@xl- {
    float: left !important; }
  .fl-r\@xl- {
    float: right !important; }
  .fl-n\@xl- {
    float: none !important; } }

@media screen and (max-width: 1199px) {
  .fl-l\@lg- {
    float: left !important; }
  .fl-r\@lg- {
    float: right !important; }
  .fl-n\@lg- {
    float: none !important; } }

@media screen and (max-width: 991px) {
  .fl-l\@md- {
    float: left !important; }
  .fl-r\@md- {
    float: right !important; }
  .fl-n\@md- {
    float: none !important; } }

@media screen and (max-width: 767px) {
  .fl-l\@sm- {
    float: left !important; }
  .fl-r\@sm- {
    float: right !important; }
  .fl-n\@sm- {
    float: none !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .fl-l\@lg {
    float: left !important; }
  .fl-r\@lg {
    float: right !important; }
  .fl-n\@lg {
    float: none !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .fl-l\@md {
    float: left !important; }
  .fl-r\@md {
    float: right !important; }
  .fl-n\@md {
    float: none !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .fl-l\@sm {
    float: left !important; }
  .fl-r\@sm {
    float: right !important; }
  .fl-n\@sm {
    float: none !important; } }

.va-t {
  vertical-align: top !important; }

.va-m {
  vertical-align: middle !important; }

.va-b {
  vertical-align: bottom !important; }

@media screen and (min-width: 1440px) {
  .va-t\@xl\+ {
    vertical-align: top !important; }
  .va-m\@xl\+ {
    vertical-align: middle !important; }
  .va-b\@xl\+ {
    vertical-align: bottom !important; } }

@media screen and (min-width: 1200px) {
  .va-t\@lg\+ {
    vertical-align: top !important; }
  .va-m\@lg\+ {
    vertical-align: middle !important; }
  .va-b\@lg\+ {
    vertical-align: bottom !important; } }

@media screen and (min-width: 992px) {
  .va-t\@md\+ {
    vertical-align: top !important; }
  .va-m\@md\+ {
    vertical-align: middle !important; }
  .va-b\@md\+ {
    vertical-align: bottom !important; } }

@media screen and (min-width: 768px) {
  .va-t\@sm\+ {
    vertical-align: top !important; }
  .va-m\@sm\+ {
    vertical-align: middle !important; }
  .va-b\@sm\+ {
    vertical-align: bottom !important; } }

@media screen and (min-width: 0) {
  .va-t\@xs\+ {
    vertical-align: top !important; }
  .va-m\@xs\+ {
    vertical-align: middle !important; }
  .va-b\@xs\+ {
    vertical-align: bottom !important; } }

@media screen and (max-width: 1439px) {
  .va-t\@xl- {
    vertical-align: top !important; }
  .va-m\@xl- {
    vertical-align: middle !important; }
  .va-b\@xl- {
    vertical-align: bottom !important; } }

@media screen and (max-width: 1199px) {
  .va-t\@lg- {
    vertical-align: top !important; }
  .va-m\@lg- {
    vertical-align: middle !important; }
  .va-b\@lg- {
    vertical-align: bottom !important; } }

@media screen and (max-width: 991px) {
  .va-t\@md- {
    vertical-align: top !important; }
  .va-m\@md- {
    vertical-align: middle !important; }
  .va-b\@md- {
    vertical-align: bottom !important; } }

@media screen and (max-width: 767px) {
  .va-t\@sm- {
    vertical-align: top !important; }
  .va-m\@sm- {
    vertical-align: middle !important; }
  .va-b\@sm- {
    vertical-align: bottom !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .va-t\@lg {
    vertical-align: top !important; }
  .va-m\@lg {
    vertical-align: middle !important; }
  .va-b\@lg {
    vertical-align: bottom !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .va-t\@md {
    vertical-align: top !important; }
  .va-m\@md {
    vertical-align: middle !important; }
  .va-b\@md {
    vertical-align: bottom !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .va-t\@sm {
    vertical-align: top !important; }
  .va-m\@sm {
    vertical-align: middle !important; }
  .va-b\@sm {
    vertical-align: bottom !important; } }

.pos-s {
  position: static !important; }

.pos-st {
  position: sticky !important; }

.pos-r {
  position: relative !important; }

.pos-a {
  position: absolute !important; }

.pos-f {
  position: fixed !important; }

@media screen and (min-width: 1440px) {
  .pos-s\@xl\+ {
    position: static !important; }
  .pos-st\@xl\+ {
    position: sticky !important; }
  .pos-r\@xl\+ {
    position: relative !important; }
  .pos-a\@xl\+ {
    position: absolute !important; }
  .pos-f\@xl\+ {
    position: fixed !important; } }

@media screen and (min-width: 1200px) {
  .pos-s\@lg\+ {
    position: static !important; }
  .pos-st\@lg\+ {
    position: sticky !important; }
  .pos-r\@lg\+ {
    position: relative !important; }
  .pos-a\@lg\+ {
    position: absolute !important; }
  .pos-f\@lg\+ {
    position: fixed !important; } }

@media screen and (min-width: 992px) {
  .pos-s\@md\+ {
    position: static !important; }
  .pos-st\@md\+ {
    position: sticky !important; }
  .pos-r\@md\+ {
    position: relative !important; }
  .pos-a\@md\+ {
    position: absolute !important; }
  .pos-f\@md\+ {
    position: fixed !important; } }

@media screen and (min-width: 768px) {
  .pos-s\@sm\+ {
    position: static !important; }
  .pos-st\@sm\+ {
    position: sticky !important; }
  .pos-r\@sm\+ {
    position: relative !important; }
  .pos-a\@sm\+ {
    position: absolute !important; }
  .pos-f\@sm\+ {
    position: fixed !important; } }

@media screen and (min-width: 0) {
  .pos-s\@xs\+ {
    position: static !important; }
  .pos-st\@xs\+ {
    position: sticky !important; }
  .pos-r\@xs\+ {
    position: relative !important; }
  .pos-a\@xs\+ {
    position: absolute !important; }
  .pos-f\@xs\+ {
    position: fixed !important; } }

@media screen and (max-width: 1439px) {
  .pos-s\@xl- {
    position: static !important; }
  .pos-st\@xl- {
    position: sticky !important; }
  .pos-r\@xl- {
    position: relative !important; }
  .pos-a\@xl- {
    position: absolute !important; }
  .pos-f\@xl- {
    position: fixed !important; } }

@media screen and (max-width: 1199px) {
  .pos-s\@lg- {
    position: static !important; }
  .pos-st\@lg- {
    position: sticky !important; }
  .pos-r\@lg- {
    position: relative !important; }
  .pos-a\@lg- {
    position: absolute !important; }
  .pos-f\@lg- {
    position: fixed !important; } }

@media screen and (max-width: 991px) {
  .pos-s\@md- {
    position: static !important; }
  .pos-st\@md- {
    position: sticky !important; }
  .pos-r\@md- {
    position: relative !important; }
  .pos-a\@md- {
    position: absolute !important; }
  .pos-f\@md- {
    position: fixed !important; } }

@media screen and (max-width: 767px) {
  .pos-s\@sm- {
    position: static !important; }
  .pos-st\@sm- {
    position: sticky !important; }
  .pos-r\@sm- {
    position: relative !important; }
  .pos-a\@sm- {
    position: absolute !important; }
  .pos-f\@sm- {
    position: fixed !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .pos-s\@lg {
    position: static !important; }
  .pos-st\@lg {
    position: sticky !important; }
  .pos-r\@lg {
    position: relative !important; }
  .pos-a\@lg {
    position: absolute !important; }
  .pos-f\@lg {
    position: fixed !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .pos-s\@md {
    position: static !important; }
  .pos-st\@md {
    position: sticky !important; }
  .pos-r\@md {
    position: relative !important; }
  .pos-a\@md {
    position: absolute !important; }
  .pos-f\@md {
    position: fixed !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .pos-s\@sm {
    position: static !important; }
  .pos-st\@sm {
    position: sticky !important; }
  .pos-r\@sm {
    position: relative !important; }
  .pos-a\@sm {
    position: absolute !important; }
  .pos-f\@sm {
    position: fixed !important; } }

.z-0 {
  z-index: 0 !important; }

@media screen and (min-width: 1440px) {
  .z-0\@xl\+ {
    z-index: 0 !important; } }

@media screen and (min-width: 1200px) {
  .z-0\@lg\+ {
    z-index: 0 !important; } }

@media screen and (min-width: 992px) {
  .z-0\@md\+ {
    z-index: 0 !important; } }

@media screen and (min-width: 768px) {
  .z-0\@sm\+ {
    z-index: 0 !important; } }

@media screen and (min-width: 0) {
  .z-0\@xs\+ {
    z-index: 0 !important; } }

@media screen and (max-width: 1439px) {
  .z-0\@xl- {
    z-index: 0 !important; } }

@media screen and (max-width: 1199px) {
  .z-0\@lg- {
    z-index: 0 !important; } }

@media screen and (max-width: 991px) {
  .z-0\@md- {
    z-index: 0 !important; } }

@media screen and (max-width: 767px) {
  .z-0\@sm- {
    z-index: 0 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-0\@lg {
    z-index: 0 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-0\@md {
    z-index: 0 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-0\@sm {
    z-index: 0 !important; } }

.z-1 {
  z-index: 1000 !important; }

@media screen and (min-width: 1440px) {
  .z-1\@xl\+ {
    z-index: 1000 !important; } }

@media screen and (min-width: 1200px) {
  .z-1\@lg\+ {
    z-index: 1000 !important; } }

@media screen and (min-width: 992px) {
  .z-1\@md\+ {
    z-index: 1000 !important; } }

@media screen and (min-width: 768px) {
  .z-1\@sm\+ {
    z-index: 1000 !important; } }

@media screen and (min-width: 0) {
  .z-1\@xs\+ {
    z-index: 1000 !important; } }

@media screen and (max-width: 1439px) {
  .z-1\@xl- {
    z-index: 1000 !important; } }

@media screen and (max-width: 1199px) {
  .z-1\@lg- {
    z-index: 1000 !important; } }

@media screen and (max-width: 991px) {
  .z-1\@md- {
    z-index: 1000 !important; } }

@media screen and (max-width: 767px) {
  .z-1\@sm- {
    z-index: 1000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-1\@lg {
    z-index: 1000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-1\@md {
    z-index: 1000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-1\@sm {
    z-index: 1000 !important; } }

.z-2 {
  z-index: 2000 !important; }

@media screen and (min-width: 1440px) {
  .z-2\@xl\+ {
    z-index: 2000 !important; } }

@media screen and (min-width: 1200px) {
  .z-2\@lg\+ {
    z-index: 2000 !important; } }

@media screen and (min-width: 992px) {
  .z-2\@md\+ {
    z-index: 2000 !important; } }

@media screen and (min-width: 768px) {
  .z-2\@sm\+ {
    z-index: 2000 !important; } }

@media screen and (min-width: 0) {
  .z-2\@xs\+ {
    z-index: 2000 !important; } }

@media screen and (max-width: 1439px) {
  .z-2\@xl- {
    z-index: 2000 !important; } }

@media screen and (max-width: 1199px) {
  .z-2\@lg- {
    z-index: 2000 !important; } }

@media screen and (max-width: 991px) {
  .z-2\@md- {
    z-index: 2000 !important; } }

@media screen and (max-width: 767px) {
  .z-2\@sm- {
    z-index: 2000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-2\@lg {
    z-index: 2000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-2\@md {
    z-index: 2000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-2\@sm {
    z-index: 2000 !important; } }

.z-3 {
  z-index: 3000 !important; }

@media screen and (min-width: 1440px) {
  .z-3\@xl\+ {
    z-index: 3000 !important; } }

@media screen and (min-width: 1200px) {
  .z-3\@lg\+ {
    z-index: 3000 !important; } }

@media screen and (min-width: 992px) {
  .z-3\@md\+ {
    z-index: 3000 !important; } }

@media screen and (min-width: 768px) {
  .z-3\@sm\+ {
    z-index: 3000 !important; } }

@media screen and (min-width: 0) {
  .z-3\@xs\+ {
    z-index: 3000 !important; } }

@media screen and (max-width: 1439px) {
  .z-3\@xl- {
    z-index: 3000 !important; } }

@media screen and (max-width: 1199px) {
  .z-3\@lg- {
    z-index: 3000 !important; } }

@media screen and (max-width: 991px) {
  .z-3\@md- {
    z-index: 3000 !important; } }

@media screen and (max-width: 767px) {
  .z-3\@sm- {
    z-index: 3000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-3\@lg {
    z-index: 3000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-3\@md {
    z-index: 3000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-3\@sm {
    z-index: 3000 !important; } }

.z-4 {
  z-index: 4000 !important; }

@media screen and (min-width: 1440px) {
  .z-4\@xl\+ {
    z-index: 4000 !important; } }

@media screen and (min-width: 1200px) {
  .z-4\@lg\+ {
    z-index: 4000 !important; } }

@media screen and (min-width: 992px) {
  .z-4\@md\+ {
    z-index: 4000 !important; } }

@media screen and (min-width: 768px) {
  .z-4\@sm\+ {
    z-index: 4000 !important; } }

@media screen and (min-width: 0) {
  .z-4\@xs\+ {
    z-index: 4000 !important; } }

@media screen and (max-width: 1439px) {
  .z-4\@xl- {
    z-index: 4000 !important; } }

@media screen and (max-width: 1199px) {
  .z-4\@lg- {
    z-index: 4000 !important; } }

@media screen and (max-width: 991px) {
  .z-4\@md- {
    z-index: 4000 !important; } }

@media screen and (max-width: 767px) {
  .z-4\@sm- {
    z-index: 4000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-4\@lg {
    z-index: 4000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-4\@md {
    z-index: 4000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-4\@sm {
    z-index: 4000 !important; } }

.z-5 {
  z-index: 5000 !important; }

@media screen and (min-width: 1440px) {
  .z-5\@xl\+ {
    z-index: 5000 !important; } }

@media screen and (min-width: 1200px) {
  .z-5\@lg\+ {
    z-index: 5000 !important; } }

@media screen and (min-width: 992px) {
  .z-5\@md\+ {
    z-index: 5000 !important; } }

@media screen and (min-width: 768px) {
  .z-5\@sm\+ {
    z-index: 5000 !important; } }

@media screen and (min-width: 0) {
  .z-5\@xs\+ {
    z-index: 5000 !important; } }

@media screen and (max-width: 1439px) {
  .z-5\@xl- {
    z-index: 5000 !important; } }

@media screen and (max-width: 1199px) {
  .z-5\@lg- {
    z-index: 5000 !important; } }

@media screen and (max-width: 991px) {
  .z-5\@md- {
    z-index: 5000 !important; } }

@media screen and (max-width: 767px) {
  .z-5\@sm- {
    z-index: 5000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-5\@lg {
    z-index: 5000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-5\@md {
    z-index: 5000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-5\@sm {
    z-index: 5000 !important; } }

.z-6 {
  z-index: 6000 !important; }

@media screen and (min-width: 1440px) {
  .z-6\@xl\+ {
    z-index: 6000 !important; } }

@media screen and (min-width: 1200px) {
  .z-6\@lg\+ {
    z-index: 6000 !important; } }

@media screen and (min-width: 992px) {
  .z-6\@md\+ {
    z-index: 6000 !important; } }

@media screen and (min-width: 768px) {
  .z-6\@sm\+ {
    z-index: 6000 !important; } }

@media screen and (min-width: 0) {
  .z-6\@xs\+ {
    z-index: 6000 !important; } }

@media screen and (max-width: 1439px) {
  .z-6\@xl- {
    z-index: 6000 !important; } }

@media screen and (max-width: 1199px) {
  .z-6\@lg- {
    z-index: 6000 !important; } }

@media screen and (max-width: 991px) {
  .z-6\@md- {
    z-index: 6000 !important; } }

@media screen and (max-width: 767px) {
  .z-6\@sm- {
    z-index: 6000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-6\@lg {
    z-index: 6000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-6\@md {
    z-index: 6000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-6\@sm {
    z-index: 6000 !important; } }

.z-7 {
  z-index: 7000 !important; }

@media screen and (min-width: 1440px) {
  .z-7\@xl\+ {
    z-index: 7000 !important; } }

@media screen and (min-width: 1200px) {
  .z-7\@lg\+ {
    z-index: 7000 !important; } }

@media screen and (min-width: 992px) {
  .z-7\@md\+ {
    z-index: 7000 !important; } }

@media screen and (min-width: 768px) {
  .z-7\@sm\+ {
    z-index: 7000 !important; } }

@media screen and (min-width: 0) {
  .z-7\@xs\+ {
    z-index: 7000 !important; } }

@media screen and (max-width: 1439px) {
  .z-7\@xl- {
    z-index: 7000 !important; } }

@media screen and (max-width: 1199px) {
  .z-7\@lg- {
    z-index: 7000 !important; } }

@media screen and (max-width: 991px) {
  .z-7\@md- {
    z-index: 7000 !important; } }

@media screen and (max-width: 767px) {
  .z-7\@sm- {
    z-index: 7000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-7\@lg {
    z-index: 7000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-7\@md {
    z-index: 7000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-7\@sm {
    z-index: 7000 !important; } }

.z-8 {
  z-index: 8000 !important; }

@media screen and (min-width: 1440px) {
  .z-8\@xl\+ {
    z-index: 8000 !important; } }

@media screen and (min-width: 1200px) {
  .z-8\@lg\+ {
    z-index: 8000 !important; } }

@media screen and (min-width: 992px) {
  .z-8\@md\+ {
    z-index: 8000 !important; } }

@media screen and (min-width: 768px) {
  .z-8\@sm\+ {
    z-index: 8000 !important; } }

@media screen and (min-width: 0) {
  .z-8\@xs\+ {
    z-index: 8000 !important; } }

@media screen and (max-width: 1439px) {
  .z-8\@xl- {
    z-index: 8000 !important; } }

@media screen and (max-width: 1199px) {
  .z-8\@lg- {
    z-index: 8000 !important; } }

@media screen and (max-width: 991px) {
  .z-8\@md- {
    z-index: 8000 !important; } }

@media screen and (max-width: 767px) {
  .z-8\@sm- {
    z-index: 8000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-8\@lg {
    z-index: 8000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-8\@md {
    z-index: 8000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-8\@sm {
    z-index: 8000 !important; } }

.z-9 {
  z-index: 9000 !important; }

@media screen and (min-width: 1440px) {
  .z-9\@xl\+ {
    z-index: 9000 !important; } }

@media screen and (min-width: 1200px) {
  .z-9\@lg\+ {
    z-index: 9000 !important; } }

@media screen and (min-width: 992px) {
  .z-9\@md\+ {
    z-index: 9000 !important; } }

@media screen and (min-width: 768px) {
  .z-9\@sm\+ {
    z-index: 9000 !important; } }

@media screen and (min-width: 0) {
  .z-9\@xs\+ {
    z-index: 9000 !important; } }

@media screen and (max-width: 1439px) {
  .z-9\@xl- {
    z-index: 9000 !important; } }

@media screen and (max-width: 1199px) {
  .z-9\@lg- {
    z-index: 9000 !important; } }

@media screen and (max-width: 991px) {
  .z-9\@md- {
    z-index: 9000 !important; } }

@media screen and (max-width: 767px) {
  .z-9\@sm- {
    z-index: 9000 !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .z-9\@lg {
    z-index: 9000 !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .z-9\@md {
    z-index: 9000 !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .z-9\@sm {
    z-index: 9000 !important; } }

.lisp-i {
  list-style-position: inside; }

.lisp-o {
  list-style-position: outside; }

.lis-n {
  list-style: none; }

.list-c {
  list-style-type: circle; }

.list-s {
  list-style-type: square; }

.list-u {
  list-style-type: upper-roman; }

.list-l {
  list-style-type: lower-alpha; }

.m-0 {
  margin: 0px !important; }

.mT-0 {
  margin-top: 0px !important; }

.mR-0 {
  margin-right: 0px !important; }

.mB-0 {
  margin-bottom: 0px !important; }

.mL-0 {
  margin-left: 0px !important; }

.mY-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.mX-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.m-nv-0 {
  margin: -0px !important; }

.mT-nv-0 {
  margin-top: -0px !important; }

.mR-nv-0 {
  margin-right: -0px !important; }

.mB-nv-0 {
  margin-bottom: -0px !important; }

.mL-nv-0 {
  margin-left: -0px !important; }

.mY-nv-0 {
  margin-top: -0px !important;
  margin-bottom: -0px !important; }

.mX-nv-0 {
  margin-left: -0px !important;
  margin-right: -0px !important; }

@media screen and (min-width: 1440px) {
  .m-0\@xl\+ {
    margin: 0px !important; }
  .mT-0\@xl\+ {
    margin-top: 0px !important; }
  .mR-0\@xl\+ {
    margin-right: 0px !important; }
  .mB-0\@xl\+ {
    margin-bottom: 0px !important; }
  .mL-0\@xl\+ {
    margin-left: 0px !important; }
  .mY-0\@xl\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@xl\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@xl\+ {
    margin: -0px !important; }
  .mT-nv-0\@xl\+ {
    margin-top: -0px !important; }
  .mR-nv-0\@xl\+ {
    margin-right: -0px !important; }
  .mB-nv-0\@xl\+ {
    margin-bottom: -0px !important; }
  .mL-nv-0\@xl\+ {
    margin-left: -0px !important; }
  .mY-nv-0\@xl\+ {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@xl\+ {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 1200px) {
  .m-0\@lg\+ {
    margin: 0px !important; }
  .mT-0\@lg\+ {
    margin-top: 0px !important; }
  .mR-0\@lg\+ {
    margin-right: 0px !important; }
  .mB-0\@lg\+ {
    margin-bottom: 0px !important; }
  .mL-0\@lg\+ {
    margin-left: 0px !important; }
  .mY-0\@lg\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@lg\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@lg\+ {
    margin: -0px !important; }
  .mT-nv-0\@lg\+ {
    margin-top: -0px !important; }
  .mR-nv-0\@lg\+ {
    margin-right: -0px !important; }
  .mB-nv-0\@lg\+ {
    margin-bottom: -0px !important; }
  .mL-nv-0\@lg\+ {
    margin-left: -0px !important; }
  .mY-nv-0\@lg\+ {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@lg\+ {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 992px) {
  .m-0\@md\+ {
    margin: 0px !important; }
  .mT-0\@md\+ {
    margin-top: 0px !important; }
  .mR-0\@md\+ {
    margin-right: 0px !important; }
  .mB-0\@md\+ {
    margin-bottom: 0px !important; }
  .mL-0\@md\+ {
    margin-left: 0px !important; }
  .mY-0\@md\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@md\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@md\+ {
    margin: -0px !important; }
  .mT-nv-0\@md\+ {
    margin-top: -0px !important; }
  .mR-nv-0\@md\+ {
    margin-right: -0px !important; }
  .mB-nv-0\@md\+ {
    margin-bottom: -0px !important; }
  .mL-nv-0\@md\+ {
    margin-left: -0px !important; }
  .mY-nv-0\@md\+ {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@md\+ {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 768px) {
  .m-0\@sm\+ {
    margin: 0px !important; }
  .mT-0\@sm\+ {
    margin-top: 0px !important; }
  .mR-0\@sm\+ {
    margin-right: 0px !important; }
  .mB-0\@sm\+ {
    margin-bottom: 0px !important; }
  .mL-0\@sm\+ {
    margin-left: 0px !important; }
  .mY-0\@sm\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@sm\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@sm\+ {
    margin: -0px !important; }
  .mT-nv-0\@sm\+ {
    margin-top: -0px !important; }
  .mR-nv-0\@sm\+ {
    margin-right: -0px !important; }
  .mB-nv-0\@sm\+ {
    margin-bottom: -0px !important; }
  .mL-nv-0\@sm\+ {
    margin-left: -0px !important; }
  .mY-nv-0\@sm\+ {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@sm\+ {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 0) {
  .m-0\@xs\+ {
    margin: 0px !important; }
  .mT-0\@xs\+ {
    margin-top: 0px !important; }
  .mR-0\@xs\+ {
    margin-right: 0px !important; }
  .mB-0\@xs\+ {
    margin-bottom: 0px !important; }
  .mL-0\@xs\+ {
    margin-left: 0px !important; }
  .mY-0\@xs\+ {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@xs\+ {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@xs\+ {
    margin: -0px !important; }
  .mT-nv-0\@xs\+ {
    margin-top: -0px !important; }
  .mR-nv-0\@xs\+ {
    margin-right: -0px !important; }
  .mB-nv-0\@xs\+ {
    margin-bottom: -0px !important; }
  .mL-nv-0\@xs\+ {
    margin-left: -0px !important; }
  .mY-nv-0\@xs\+ {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@xs\+ {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (max-width: 1439px) {
  .m-0\@xl- {
    margin: 0px !important; }
  .mT-0\@xl- {
    margin-top: 0px !important; }
  .mR-0\@xl- {
    margin-right: 0px !important; }
  .mB-0\@xl- {
    margin-bottom: 0px !important; }
  .mL-0\@xl- {
    margin-left: 0px !important; }
  .mY-0\@xl- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@xl- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@xl- {
    margin: -0px !important; }
  .mT-nv-0\@xl- {
    margin-top: -0px !important; }
  .mR-nv-0\@xl- {
    margin-right: -0px !important; }
  .mB-nv-0\@xl- {
    margin-bottom: -0px !important; }
  .mL-nv-0\@xl- {
    margin-left: -0px !important; }
  .mY-nv-0\@xl- {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@xl- {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (max-width: 1199px) {
  .m-0\@lg- {
    margin: 0px !important; }
  .mT-0\@lg- {
    margin-top: 0px !important; }
  .mR-0\@lg- {
    margin-right: 0px !important; }
  .mB-0\@lg- {
    margin-bottom: 0px !important; }
  .mL-0\@lg- {
    margin-left: 0px !important; }
  .mY-0\@lg- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@lg- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@lg- {
    margin: -0px !important; }
  .mT-nv-0\@lg- {
    margin-top: -0px !important; }
  .mR-nv-0\@lg- {
    margin-right: -0px !important; }
  .mB-nv-0\@lg- {
    margin-bottom: -0px !important; }
  .mL-nv-0\@lg- {
    margin-left: -0px !important; }
  .mY-nv-0\@lg- {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@lg- {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (max-width: 991px) {
  .m-0\@md- {
    margin: 0px !important; }
  .mT-0\@md- {
    margin-top: 0px !important; }
  .mR-0\@md- {
    margin-right: 0px !important; }
  .mB-0\@md- {
    margin-bottom: 0px !important; }
  .mL-0\@md- {
    margin-left: 0px !important; }
  .mY-0\@md- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@md- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@md- {
    margin: -0px !important; }
  .mT-nv-0\@md- {
    margin-top: -0px !important; }
  .mR-nv-0\@md- {
    margin-right: -0px !important; }
  .mB-nv-0\@md- {
    margin-bottom: -0px !important; }
  .mL-nv-0\@md- {
    margin-left: -0px !important; }
  .mY-nv-0\@md- {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@md- {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (max-width: 767px) {
  .m-0\@sm- {
    margin: 0px !important; }
  .mT-0\@sm- {
    margin-top: 0px !important; }
  .mR-0\@sm- {
    margin-right: 0px !important; }
  .mB-0\@sm- {
    margin-bottom: 0px !important; }
  .mL-0\@sm- {
    margin-left: 0px !important; }
  .mY-0\@sm- {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@sm- {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@sm- {
    margin: -0px !important; }
  .mT-nv-0\@sm- {
    margin-top: -0px !important; }
  .mR-nv-0\@sm- {
    margin-right: -0px !important; }
  .mB-nv-0\@sm- {
    margin-bottom: -0px !important; }
  .mL-nv-0\@sm- {
    margin-left: -0px !important; }
  .mY-nv-0\@sm- {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@sm- {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-0\@lg {
    margin: 0px !important; }
  .mT-0\@lg {
    margin-top: 0px !important; }
  .mR-0\@lg {
    margin-right: 0px !important; }
  .mB-0\@lg {
    margin-bottom: 0px !important; }
  .mL-0\@lg {
    margin-left: 0px !important; }
  .mY-0\@lg {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@lg {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@lg {
    margin: -0px !important; }
  .mT-nv-0\@lg {
    margin-top: -0px !important; }
  .mR-nv-0\@lg {
    margin-right: -0px !important; }
  .mB-nv-0\@lg {
    margin-bottom: -0px !important; }
  .mL-nv-0\@lg {
    margin-left: -0px !important; }
  .mY-nv-0\@lg {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@lg {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-0\@md {
    margin: 0px !important; }
  .mT-0\@md {
    margin-top: 0px !important; }
  .mR-0\@md {
    margin-right: 0px !important; }
  .mB-0\@md {
    margin-bottom: 0px !important; }
  .mL-0\@md {
    margin-left: 0px !important; }
  .mY-0\@md {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@md {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@md {
    margin: -0px !important; }
  .mT-nv-0\@md {
    margin-top: -0px !important; }
  .mR-nv-0\@md {
    margin-right: -0px !important; }
  .mB-nv-0\@md {
    margin-bottom: -0px !important; }
  .mL-nv-0\@md {
    margin-left: -0px !important; }
  .mY-nv-0\@md {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@md {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-0\@sm {
    margin: 0px !important; }
  .mT-0\@sm {
    margin-top: 0px !important; }
  .mR-0\@sm {
    margin-right: 0px !important; }
  .mB-0\@sm {
    margin-bottom: 0px !important; }
  .mL-0\@sm {
    margin-left: 0px !important; }
  .mY-0\@sm {
    margin-top: 0px !important;
    margin-bottom: 0px !important; }
  .mX-0\@sm {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .m-nv-0\@sm {
    margin: -0px !important; }
  .mT-nv-0\@sm {
    margin-top: -0px !important; }
  .mR-nv-0\@sm {
    margin-right: -0px !important; }
  .mB-nv-0\@sm {
    margin-bottom: -0px !important; }
  .mL-nv-0\@sm {
    margin-left: -0px !important; }
  .mY-nv-0\@sm {
    margin-top: -0px !important;
    margin-bottom: -0px !important; }
  .mX-nv-0\@sm {
    margin-left: -0px !important;
    margin-right: -0px !important; } }

.m-1 {
  margin: 1px !important; }

.mT-1 {
  margin-top: 1px !important; }

.mR-1 {
  margin-right: 1px !important; }

.mB-1 {
  margin-bottom: 1px !important; }

.mL-1 {
  margin-left: 1px !important; }

.mY-1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }

.mX-1 {
  margin-left: 1px !important;
  margin-right: 1px !important; }

.m-nv-1 {
  margin: -1px !important; }

.mT-nv-1 {
  margin-top: -1px !important; }

.mR-nv-1 {
  margin-right: -1px !important; }

.mB-nv-1 {
  margin-bottom: -0px !important; }

.mL-nv-1 {
  margin-left: -1px !important; }

.mY-nv-1 {
  margin-top: -1px !important;
  margin-bottom: -1px !important; }

.mX-nv-1 {
  margin-left: -1px !important;
  margin-right: -1px !important; }

@media screen and (min-width: 1440px) {
  .m-1\@xl\+ {
    margin: 1px !important; }
  .mT-1\@xl\+ {
    margin-top: 1px !important; }
  .mR-1\@xl\+ {
    margin-right: 1px !important; }
  .mB-1\@xl\+ {
    margin-bottom: 1px !important; }
  .mL-1\@xl\+ {
    margin-left: 1px !important; }
  .mY-1\@xl\+ {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@xl\+ {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@xl\+ {
    margin: -1px !important; }
  .mT-nv-1\@xl\+ {
    margin-top: -1px !important; }
  .mR-nv-1\@xl\+ {
    margin-right: -1px !important; }
  .mB-nv-1\@xl\+ {
    margin-bottom: -1px !important; }
  .mL-nv-1\@xl\+ {
    margin-left: -1px !important; }
  .mY-nv-1\@xl\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@xl\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 1200px) {
  .m-1\@lg\+ {
    margin: 1px !important; }
  .mT-1\@lg\+ {
    margin-top: 1px !important; }
  .mR-1\@lg\+ {
    margin-right: 1px !important; }
  .mB-1\@lg\+ {
    margin-bottom: 1px !important; }
  .mL-1\@lg\+ {
    margin-left: 1px !important; }
  .mY-1\@lg\+ {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@lg\+ {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@lg\+ {
    margin: -1px !important; }
  .mT-nv-1\@lg\+ {
    margin-top: -1px !important; }
  .mR-nv-1\@lg\+ {
    margin-right: -1px !important; }
  .mB-nv-1\@lg\+ {
    margin-bottom: -1px !important; }
  .mL-nv-1\@lg\+ {
    margin-left: -1px !important; }
  .mY-nv-1\@lg\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@lg\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 992px) {
  .m-1\@md\+ {
    margin: 1px !important; }
  .mT-1\@md\+ {
    margin-top: 1px !important; }
  .mR-1\@md\+ {
    margin-right: 1px !important; }
  .mB-1\@md\+ {
    margin-bottom: 1px !important; }
  .mL-1\@md\+ {
    margin-left: 1px !important; }
  .mY-1\@md\+ {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@md\+ {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@md\+ {
    margin: -1px !important; }
  .mT-nv-1\@md\+ {
    margin-top: -1px !important; }
  .mR-nv-1\@md\+ {
    margin-right: -1px !important; }
  .mB-nv-1\@md\+ {
    margin-bottom: -1px !important; }
  .mL-nv-1\@md\+ {
    margin-left: -1px !important; }
  .mY-nv-1\@md\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@md\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 768px) {
  .m-1\@sm\+ {
    margin: 1px !important; }
  .mT-1\@sm\+ {
    margin-top: 1px !important; }
  .mR-1\@sm\+ {
    margin-right: 1px !important; }
  .mB-1\@sm\+ {
    margin-bottom: 1px !important; }
  .mL-1\@sm\+ {
    margin-left: 1px !important; }
  .mY-1\@sm\+ {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@sm\+ {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@sm\+ {
    margin: -1px !important; }
  .mT-nv-1\@sm\+ {
    margin-top: -1px !important; }
  .mR-nv-1\@sm\+ {
    margin-right: -1px !important; }
  .mB-nv-1\@sm\+ {
    margin-bottom: -1px !important; }
  .mL-nv-1\@sm\+ {
    margin-left: -1px !important; }
  .mY-nv-1\@sm\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@sm\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 0) {
  .m-1\@xs\+ {
    margin: 1px !important; }
  .mT-1\@xs\+ {
    margin-top: 1px !important; }
  .mR-1\@xs\+ {
    margin-right: 1px !important; }
  .mB-1\@xs\+ {
    margin-bottom: 1px !important; }
  .mL-1\@xs\+ {
    margin-left: 1px !important; }
  .mY-1\@xs\+ {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@xs\+ {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@xs\+ {
    margin: -1px !important; }
  .mT-nv-1\@xs\+ {
    margin-top: -1px !important; }
  .mR-nv-1\@xs\+ {
    margin-right: -1px !important; }
  .mB-nv-1\@xs\+ {
    margin-bottom: -1px !important; }
  .mL-nv-1\@xs\+ {
    margin-left: -1px !important; }
  .mY-nv-1\@xs\+ {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@xs\+ {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (max-width: 1439px) {
  .m-1\@xl- {
    margin: 1px !important; }
  .mT-1\@xl- {
    margin-top: 1px !important; }
  .mR-1\@xl- {
    margin-right: 1px !important; }
  .mB-1\@xl- {
    margin-bottom: 1px !important; }
  .mL-1\@xl- {
    margin-left: 1px !important; }
  .mY-1\@xl- {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@xl- {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@xl- {
    margin: -1px !important; }
  .mT-nv-1\@xl- {
    margin-top: -1px !important; }
  .mR-nv-1\@xl- {
    margin-right: -1px !important; }
  .mB-nv-1\@xl- {
    margin-bottom: -1px !important; }
  .mL-nv-1\@xl- {
    margin-left: -1px !important; }
  .mY-nv-1\@xl- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@xl- {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (max-width: 1199px) {
  .m-1\@lg- {
    margin: 1px !important; }
  .mT-1\@lg- {
    margin-top: 1px !important; }
  .mR-1\@lg- {
    margin-right: 1px !important; }
  .mB-1\@lg- {
    margin-bottom: 1px !important; }
  .mL-1\@lg- {
    margin-left: 1px !important; }
  .mY-1\@lg- {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@lg- {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@lg- {
    margin: -1px !important; }
  .mT-nv-1\@lg- {
    margin-top: -1px !important; }
  .mR-nv-1\@lg- {
    margin-right: -1px !important; }
  .mB-nv-1\@lg- {
    margin-bottom: -1px !important; }
  .mL-nv-1\@lg- {
    margin-left: -1px !important; }
  .mY-nv-1\@lg- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@lg- {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (max-width: 991px) {
  .m-1\@md- {
    margin: 1px !important; }
  .mT-1\@md- {
    margin-top: 1px !important; }
  .mR-1\@md- {
    margin-right: 1px !important; }
  .mB-1\@md- {
    margin-bottom: 1px !important; }
  .mL-1\@md- {
    margin-left: 1px !important; }
  .mY-1\@md- {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@md- {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@md- {
    margin: -1px !important; }
  .mT-nv-1\@md- {
    margin-top: -1px !important; }
  .mR-nv-1\@md- {
    margin-right: -1px !important; }
  .mB-nv-1\@md- {
    margin-bottom: -1px !important; }
  .mL-nv-1\@md- {
    margin-left: -1px !important; }
  .mY-nv-1\@md- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@md- {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (max-width: 767px) {
  .m-1\@sm- {
    margin: 1px !important; }
  .mT-1\@sm- {
    margin-top: 1px !important; }
  .mR-1\@sm- {
    margin-right: 1px !important; }
  .mB-1\@sm- {
    margin-bottom: 1px !important; }
  .mL-1\@sm- {
    margin-left: 1px !important; }
  .mY-1\@sm- {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@sm- {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@sm- {
    margin: -1px !important; }
  .mT-nv-1\@sm- {
    margin-top: -1px !important; }
  .mR-nv-1\@sm- {
    margin-right: -1px !important; }
  .mB-nv-1\@sm- {
    margin-bottom: -1px !important; }
  .mL-nv-1\@sm- {
    margin-left: -1px !important; }
  .mY-nv-1\@sm- {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@sm- {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-1\@lg {
    margin: 1px !important; }
  .mT-1\@lg {
    margin-top: 1px !important; }
  .mR-1\@lg {
    margin-right: 1px !important; }
  .mB-1\@lg {
    margin-bottom: 1px !important; }
  .mL-1\@lg {
    margin-left: 1px !important; }
  .mY-1\@lg {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@lg {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@lg {
    margin: -1px !important; }
  .mT-nv-1\@lg {
    margin-top: -1px !important; }
  .mR-nv-1\@lg {
    margin-right: -1px !important; }
  .mB-nv-1\@lg {
    margin-bottom: -1px !important; }
  .mL-nv-1\@lg {
    margin-left: -1px !important; }
  .mY-nv-1\@lg {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@lg {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-1\@md {
    margin: 1px !important; }
  .mT-1\@md {
    margin-top: 1px !important; }
  .mR-1\@md {
    margin-right: 1px !important; }
  .mB-1\@md {
    margin-bottom: 1px !important; }
  .mL-1\@md {
    margin-left: 1px !important; }
  .mY-1\@md {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@md {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@md {
    margin: -1px !important; }
  .mT-nv-1\@md {
    margin-top: -1px !important; }
  .mR-nv-1\@md {
    margin-right: -1px !important; }
  .mB-nv-1\@md {
    margin-bottom: -1px !important; }
  .mL-nv-1\@md {
    margin-left: -1px !important; }
  .mY-nv-1\@md {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@md {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-1\@sm {
    margin: 1px !important; }
  .mT-1\@sm {
    margin-top: 1px !important; }
  .mR-1\@sm {
    margin-right: 1px !important; }
  .mB-1\@sm {
    margin-bottom: 1px !important; }
  .mL-1\@sm {
    margin-left: 1px !important; }
  .mY-1\@sm {
    margin-top: 1px !important;
    margin-bottom: 1px !important; }
  .mX-1\@sm {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  .m-nv-1\@sm {
    margin: -1px !important; }
  .mT-nv-1\@sm {
    margin-top: -1px !important; }
  .mR-nv-1\@sm {
    margin-right: -1px !important; }
  .mB-nv-1\@sm {
    margin-bottom: -1px !important; }
  .mL-nv-1\@sm {
    margin-left: -1px !important; }
  .mY-nv-1\@sm {
    margin-top: -1px !important;
    margin-bottom: -1px !important; }
  .mX-nv-1\@sm {
    margin-left: -1px !important;
    margin-right: -1px !important; } }

.m-2 {
  margin: 2px !important; }

.mT-2 {
  margin-top: 2px !important; }

.mR-2 {
  margin-right: 2px !important; }

.mB-2 {
  margin-bottom: 2px !important; }

.mL-2 {
  margin-left: 2px !important; }

.mY-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }

.mX-2 {
  margin-left: 2px !important;
  margin-right: 2px !important; }

.m-nv-2 {
  margin: -2px !important; }

.mT-nv-2 {
  margin-top: -2px !important; }

.mR-nv-2 {
  margin-right: -2px !important; }

.mB-nv-2 {
  margin-bottom: -0px !important; }

.mL-nv-2 {
  margin-left: -2px !important; }

.mY-nv-2 {
  margin-top: -2px !important;
  margin-bottom: -2px !important; }

.mX-nv-2 {
  margin-left: -2px !important;
  margin-right: -2px !important; }

@media screen and (min-width: 1440px) {
  .m-2\@xl\+ {
    margin: 2px !important; }
  .mT-2\@xl\+ {
    margin-top: 2px !important; }
  .mR-2\@xl\+ {
    margin-right: 2px !important; }
  .mB-2\@xl\+ {
    margin-bottom: 2px !important; }
  .mL-2\@xl\+ {
    margin-left: 2px !important; }
  .mY-2\@xl\+ {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@xl\+ {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@xl\+ {
    margin: -2px !important; }
  .mT-nv-2\@xl\+ {
    margin-top: -2px !important; }
  .mR-nv-2\@xl\+ {
    margin-right: -2px !important; }
  .mB-nv-2\@xl\+ {
    margin-bottom: -2px !important; }
  .mL-nv-2\@xl\+ {
    margin-left: -2px !important; }
  .mY-nv-2\@xl\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@xl\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 1200px) {
  .m-2\@lg\+ {
    margin: 2px !important; }
  .mT-2\@lg\+ {
    margin-top: 2px !important; }
  .mR-2\@lg\+ {
    margin-right: 2px !important; }
  .mB-2\@lg\+ {
    margin-bottom: 2px !important; }
  .mL-2\@lg\+ {
    margin-left: 2px !important; }
  .mY-2\@lg\+ {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@lg\+ {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@lg\+ {
    margin: -2px !important; }
  .mT-nv-2\@lg\+ {
    margin-top: -2px !important; }
  .mR-nv-2\@lg\+ {
    margin-right: -2px !important; }
  .mB-nv-2\@lg\+ {
    margin-bottom: -2px !important; }
  .mL-nv-2\@lg\+ {
    margin-left: -2px !important; }
  .mY-nv-2\@lg\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@lg\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 992px) {
  .m-2\@md\+ {
    margin: 2px !important; }
  .mT-2\@md\+ {
    margin-top: 2px !important; }
  .mR-2\@md\+ {
    margin-right: 2px !important; }
  .mB-2\@md\+ {
    margin-bottom: 2px !important; }
  .mL-2\@md\+ {
    margin-left: 2px !important; }
  .mY-2\@md\+ {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@md\+ {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@md\+ {
    margin: -2px !important; }
  .mT-nv-2\@md\+ {
    margin-top: -2px !important; }
  .mR-nv-2\@md\+ {
    margin-right: -2px !important; }
  .mB-nv-2\@md\+ {
    margin-bottom: -2px !important; }
  .mL-nv-2\@md\+ {
    margin-left: -2px !important; }
  .mY-nv-2\@md\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@md\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 768px) {
  .m-2\@sm\+ {
    margin: 2px !important; }
  .mT-2\@sm\+ {
    margin-top: 2px !important; }
  .mR-2\@sm\+ {
    margin-right: 2px !important; }
  .mB-2\@sm\+ {
    margin-bottom: 2px !important; }
  .mL-2\@sm\+ {
    margin-left: 2px !important; }
  .mY-2\@sm\+ {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@sm\+ {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@sm\+ {
    margin: -2px !important; }
  .mT-nv-2\@sm\+ {
    margin-top: -2px !important; }
  .mR-nv-2\@sm\+ {
    margin-right: -2px !important; }
  .mB-nv-2\@sm\+ {
    margin-bottom: -2px !important; }
  .mL-nv-2\@sm\+ {
    margin-left: -2px !important; }
  .mY-nv-2\@sm\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@sm\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 0) {
  .m-2\@xs\+ {
    margin: 2px !important; }
  .mT-2\@xs\+ {
    margin-top: 2px !important; }
  .mR-2\@xs\+ {
    margin-right: 2px !important; }
  .mB-2\@xs\+ {
    margin-bottom: 2px !important; }
  .mL-2\@xs\+ {
    margin-left: 2px !important; }
  .mY-2\@xs\+ {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@xs\+ {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@xs\+ {
    margin: -2px !important; }
  .mT-nv-2\@xs\+ {
    margin-top: -2px !important; }
  .mR-nv-2\@xs\+ {
    margin-right: -2px !important; }
  .mB-nv-2\@xs\+ {
    margin-bottom: -2px !important; }
  .mL-nv-2\@xs\+ {
    margin-left: -2px !important; }
  .mY-nv-2\@xs\+ {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@xs\+ {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (max-width: 1439px) {
  .m-2\@xl- {
    margin: 2px !important; }
  .mT-2\@xl- {
    margin-top: 2px !important; }
  .mR-2\@xl- {
    margin-right: 2px !important; }
  .mB-2\@xl- {
    margin-bottom: 2px !important; }
  .mL-2\@xl- {
    margin-left: 2px !important; }
  .mY-2\@xl- {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@xl- {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@xl- {
    margin: -2px !important; }
  .mT-nv-2\@xl- {
    margin-top: -2px !important; }
  .mR-nv-2\@xl- {
    margin-right: -2px !important; }
  .mB-nv-2\@xl- {
    margin-bottom: -2px !important; }
  .mL-nv-2\@xl- {
    margin-left: -2px !important; }
  .mY-nv-2\@xl- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@xl- {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (max-width: 1199px) {
  .m-2\@lg- {
    margin: 2px !important; }
  .mT-2\@lg- {
    margin-top: 2px !important; }
  .mR-2\@lg- {
    margin-right: 2px !important; }
  .mB-2\@lg- {
    margin-bottom: 2px !important; }
  .mL-2\@lg- {
    margin-left: 2px !important; }
  .mY-2\@lg- {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@lg- {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@lg- {
    margin: -2px !important; }
  .mT-nv-2\@lg- {
    margin-top: -2px !important; }
  .mR-nv-2\@lg- {
    margin-right: -2px !important; }
  .mB-nv-2\@lg- {
    margin-bottom: -2px !important; }
  .mL-nv-2\@lg- {
    margin-left: -2px !important; }
  .mY-nv-2\@lg- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@lg- {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (max-width: 991px) {
  .m-2\@md- {
    margin: 2px !important; }
  .mT-2\@md- {
    margin-top: 2px !important; }
  .mR-2\@md- {
    margin-right: 2px !important; }
  .mB-2\@md- {
    margin-bottom: 2px !important; }
  .mL-2\@md- {
    margin-left: 2px !important; }
  .mY-2\@md- {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@md- {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@md- {
    margin: -2px !important; }
  .mT-nv-2\@md- {
    margin-top: -2px !important; }
  .mR-nv-2\@md- {
    margin-right: -2px !important; }
  .mB-nv-2\@md- {
    margin-bottom: -2px !important; }
  .mL-nv-2\@md- {
    margin-left: -2px !important; }
  .mY-nv-2\@md- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@md- {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (max-width: 767px) {
  .m-2\@sm- {
    margin: 2px !important; }
  .mT-2\@sm- {
    margin-top: 2px !important; }
  .mR-2\@sm- {
    margin-right: 2px !important; }
  .mB-2\@sm- {
    margin-bottom: 2px !important; }
  .mL-2\@sm- {
    margin-left: 2px !important; }
  .mY-2\@sm- {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@sm- {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@sm- {
    margin: -2px !important; }
  .mT-nv-2\@sm- {
    margin-top: -2px !important; }
  .mR-nv-2\@sm- {
    margin-right: -2px !important; }
  .mB-nv-2\@sm- {
    margin-bottom: -2px !important; }
  .mL-nv-2\@sm- {
    margin-left: -2px !important; }
  .mY-nv-2\@sm- {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@sm- {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-2\@lg {
    margin: 2px !important; }
  .mT-2\@lg {
    margin-top: 2px !important; }
  .mR-2\@lg {
    margin-right: 2px !important; }
  .mB-2\@lg {
    margin-bottom: 2px !important; }
  .mL-2\@lg {
    margin-left: 2px !important; }
  .mY-2\@lg {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@lg {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@lg {
    margin: -2px !important; }
  .mT-nv-2\@lg {
    margin-top: -2px !important; }
  .mR-nv-2\@lg {
    margin-right: -2px !important; }
  .mB-nv-2\@lg {
    margin-bottom: -2px !important; }
  .mL-nv-2\@lg {
    margin-left: -2px !important; }
  .mY-nv-2\@lg {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@lg {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-2\@md {
    margin: 2px !important; }
  .mT-2\@md {
    margin-top: 2px !important; }
  .mR-2\@md {
    margin-right: 2px !important; }
  .mB-2\@md {
    margin-bottom: 2px !important; }
  .mL-2\@md {
    margin-left: 2px !important; }
  .mY-2\@md {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@md {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@md {
    margin: -2px !important; }
  .mT-nv-2\@md {
    margin-top: -2px !important; }
  .mR-nv-2\@md {
    margin-right: -2px !important; }
  .mB-nv-2\@md {
    margin-bottom: -2px !important; }
  .mL-nv-2\@md {
    margin-left: -2px !important; }
  .mY-nv-2\@md {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@md {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-2\@sm {
    margin: 2px !important; }
  .mT-2\@sm {
    margin-top: 2px !important; }
  .mR-2\@sm {
    margin-right: 2px !important; }
  .mB-2\@sm {
    margin-bottom: 2px !important; }
  .mL-2\@sm {
    margin-left: 2px !important; }
  .mY-2\@sm {
    margin-top: 2px !important;
    margin-bottom: 2px !important; }
  .mX-2\@sm {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  .m-nv-2\@sm {
    margin: -2px !important; }
  .mT-nv-2\@sm {
    margin-top: -2px !important; }
  .mR-nv-2\@sm {
    margin-right: -2px !important; }
  .mB-nv-2\@sm {
    margin-bottom: -2px !important; }
  .mL-nv-2\@sm {
    margin-left: -2px !important; }
  .mY-nv-2\@sm {
    margin-top: -2px !important;
    margin-bottom: -2px !important; }
  .mX-nv-2\@sm {
    margin-left: -2px !important;
    margin-right: -2px !important; } }

.m-3 {
  margin: 3px !important; }

.mT-3 {
  margin-top: 3px !important; }

.mR-3 {
  margin-right: 3px !important; }

.mB-3 {
  margin-bottom: 3px !important; }

.mL-3 {
  margin-left: 3px !important; }

.mY-3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important; }

.mX-3 {
  margin-left: 3px !important;
  margin-right: 3px !important; }

.m-nv-3 {
  margin: -3px !important; }

.mT-nv-3 {
  margin-top: -3px !important; }

.mR-nv-3 {
  margin-right: -3px !important; }

.mB-nv-3 {
  margin-bottom: -0px !important; }

.mL-nv-3 {
  margin-left: -3px !important; }

.mY-nv-3 {
  margin-top: -3px !important;
  margin-bottom: -3px !important; }

.mX-nv-3 {
  margin-left: -3px !important;
  margin-right: -3px !important; }

@media screen and (min-width: 1440px) {
  .m-3\@xl\+ {
    margin: 3px !important; }
  .mT-3\@xl\+ {
    margin-top: 3px !important; }
  .mR-3\@xl\+ {
    margin-right: 3px !important; }
  .mB-3\@xl\+ {
    margin-bottom: 3px !important; }
  .mL-3\@xl\+ {
    margin-left: 3px !important; }
  .mY-3\@xl\+ {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@xl\+ {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@xl\+ {
    margin: -3px !important; }
  .mT-nv-3\@xl\+ {
    margin-top: -3px !important; }
  .mR-nv-3\@xl\+ {
    margin-right: -3px !important; }
  .mB-nv-3\@xl\+ {
    margin-bottom: -3px !important; }
  .mL-nv-3\@xl\+ {
    margin-left: -3px !important; }
  .mY-nv-3\@xl\+ {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@xl\+ {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 1200px) {
  .m-3\@lg\+ {
    margin: 3px !important; }
  .mT-3\@lg\+ {
    margin-top: 3px !important; }
  .mR-3\@lg\+ {
    margin-right: 3px !important; }
  .mB-3\@lg\+ {
    margin-bottom: 3px !important; }
  .mL-3\@lg\+ {
    margin-left: 3px !important; }
  .mY-3\@lg\+ {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@lg\+ {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@lg\+ {
    margin: -3px !important; }
  .mT-nv-3\@lg\+ {
    margin-top: -3px !important; }
  .mR-nv-3\@lg\+ {
    margin-right: -3px !important; }
  .mB-nv-3\@lg\+ {
    margin-bottom: -3px !important; }
  .mL-nv-3\@lg\+ {
    margin-left: -3px !important; }
  .mY-nv-3\@lg\+ {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@lg\+ {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 992px) {
  .m-3\@md\+ {
    margin: 3px !important; }
  .mT-3\@md\+ {
    margin-top: 3px !important; }
  .mR-3\@md\+ {
    margin-right: 3px !important; }
  .mB-3\@md\+ {
    margin-bottom: 3px !important; }
  .mL-3\@md\+ {
    margin-left: 3px !important; }
  .mY-3\@md\+ {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@md\+ {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@md\+ {
    margin: -3px !important; }
  .mT-nv-3\@md\+ {
    margin-top: -3px !important; }
  .mR-nv-3\@md\+ {
    margin-right: -3px !important; }
  .mB-nv-3\@md\+ {
    margin-bottom: -3px !important; }
  .mL-nv-3\@md\+ {
    margin-left: -3px !important; }
  .mY-nv-3\@md\+ {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@md\+ {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 768px) {
  .m-3\@sm\+ {
    margin: 3px !important; }
  .mT-3\@sm\+ {
    margin-top: 3px !important; }
  .mR-3\@sm\+ {
    margin-right: 3px !important; }
  .mB-3\@sm\+ {
    margin-bottom: 3px !important; }
  .mL-3\@sm\+ {
    margin-left: 3px !important; }
  .mY-3\@sm\+ {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@sm\+ {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@sm\+ {
    margin: -3px !important; }
  .mT-nv-3\@sm\+ {
    margin-top: -3px !important; }
  .mR-nv-3\@sm\+ {
    margin-right: -3px !important; }
  .mB-nv-3\@sm\+ {
    margin-bottom: -3px !important; }
  .mL-nv-3\@sm\+ {
    margin-left: -3px !important; }
  .mY-nv-3\@sm\+ {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@sm\+ {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 0) {
  .m-3\@xs\+ {
    margin: 3px !important; }
  .mT-3\@xs\+ {
    margin-top: 3px !important; }
  .mR-3\@xs\+ {
    margin-right: 3px !important; }
  .mB-3\@xs\+ {
    margin-bottom: 3px !important; }
  .mL-3\@xs\+ {
    margin-left: 3px !important; }
  .mY-3\@xs\+ {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@xs\+ {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@xs\+ {
    margin: -3px !important; }
  .mT-nv-3\@xs\+ {
    margin-top: -3px !important; }
  .mR-nv-3\@xs\+ {
    margin-right: -3px !important; }
  .mB-nv-3\@xs\+ {
    margin-bottom: -3px !important; }
  .mL-nv-3\@xs\+ {
    margin-left: -3px !important; }
  .mY-nv-3\@xs\+ {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@xs\+ {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (max-width: 1439px) {
  .m-3\@xl- {
    margin: 3px !important; }
  .mT-3\@xl- {
    margin-top: 3px !important; }
  .mR-3\@xl- {
    margin-right: 3px !important; }
  .mB-3\@xl- {
    margin-bottom: 3px !important; }
  .mL-3\@xl- {
    margin-left: 3px !important; }
  .mY-3\@xl- {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@xl- {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@xl- {
    margin: -3px !important; }
  .mT-nv-3\@xl- {
    margin-top: -3px !important; }
  .mR-nv-3\@xl- {
    margin-right: -3px !important; }
  .mB-nv-3\@xl- {
    margin-bottom: -3px !important; }
  .mL-nv-3\@xl- {
    margin-left: -3px !important; }
  .mY-nv-3\@xl- {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@xl- {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (max-width: 1199px) {
  .m-3\@lg- {
    margin: 3px !important; }
  .mT-3\@lg- {
    margin-top: 3px !important; }
  .mR-3\@lg- {
    margin-right: 3px !important; }
  .mB-3\@lg- {
    margin-bottom: 3px !important; }
  .mL-3\@lg- {
    margin-left: 3px !important; }
  .mY-3\@lg- {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@lg- {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@lg- {
    margin: -3px !important; }
  .mT-nv-3\@lg- {
    margin-top: -3px !important; }
  .mR-nv-3\@lg- {
    margin-right: -3px !important; }
  .mB-nv-3\@lg- {
    margin-bottom: -3px !important; }
  .mL-nv-3\@lg- {
    margin-left: -3px !important; }
  .mY-nv-3\@lg- {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@lg- {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (max-width: 991px) {
  .m-3\@md- {
    margin: 3px !important; }
  .mT-3\@md- {
    margin-top: 3px !important; }
  .mR-3\@md- {
    margin-right: 3px !important; }
  .mB-3\@md- {
    margin-bottom: 3px !important; }
  .mL-3\@md- {
    margin-left: 3px !important; }
  .mY-3\@md- {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@md- {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@md- {
    margin: -3px !important; }
  .mT-nv-3\@md- {
    margin-top: -3px !important; }
  .mR-nv-3\@md- {
    margin-right: -3px !important; }
  .mB-nv-3\@md- {
    margin-bottom: -3px !important; }
  .mL-nv-3\@md- {
    margin-left: -3px !important; }
  .mY-nv-3\@md- {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@md- {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (max-width: 767px) {
  .m-3\@sm- {
    margin: 3px !important; }
  .mT-3\@sm- {
    margin-top: 3px !important; }
  .mR-3\@sm- {
    margin-right: 3px !important; }
  .mB-3\@sm- {
    margin-bottom: 3px !important; }
  .mL-3\@sm- {
    margin-left: 3px !important; }
  .mY-3\@sm- {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@sm- {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@sm- {
    margin: -3px !important; }
  .mT-nv-3\@sm- {
    margin-top: -3px !important; }
  .mR-nv-3\@sm- {
    margin-right: -3px !important; }
  .mB-nv-3\@sm- {
    margin-bottom: -3px !important; }
  .mL-nv-3\@sm- {
    margin-left: -3px !important; }
  .mY-nv-3\@sm- {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@sm- {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-3\@lg {
    margin: 3px !important; }
  .mT-3\@lg {
    margin-top: 3px !important; }
  .mR-3\@lg {
    margin-right: 3px !important; }
  .mB-3\@lg {
    margin-bottom: 3px !important; }
  .mL-3\@lg {
    margin-left: 3px !important; }
  .mY-3\@lg {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@lg {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@lg {
    margin: -3px !important; }
  .mT-nv-3\@lg {
    margin-top: -3px !important; }
  .mR-nv-3\@lg {
    margin-right: -3px !important; }
  .mB-nv-3\@lg {
    margin-bottom: -3px !important; }
  .mL-nv-3\@lg {
    margin-left: -3px !important; }
  .mY-nv-3\@lg {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@lg {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-3\@md {
    margin: 3px !important; }
  .mT-3\@md {
    margin-top: 3px !important; }
  .mR-3\@md {
    margin-right: 3px !important; }
  .mB-3\@md {
    margin-bottom: 3px !important; }
  .mL-3\@md {
    margin-left: 3px !important; }
  .mY-3\@md {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@md {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@md {
    margin: -3px !important; }
  .mT-nv-3\@md {
    margin-top: -3px !important; }
  .mR-nv-3\@md {
    margin-right: -3px !important; }
  .mB-nv-3\@md {
    margin-bottom: -3px !important; }
  .mL-nv-3\@md {
    margin-left: -3px !important; }
  .mY-nv-3\@md {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@md {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-3\@sm {
    margin: 3px !important; }
  .mT-3\@sm {
    margin-top: 3px !important; }
  .mR-3\@sm {
    margin-right: 3px !important; }
  .mB-3\@sm {
    margin-bottom: 3px !important; }
  .mL-3\@sm {
    margin-left: 3px !important; }
  .mY-3\@sm {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .mX-3\@sm {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  .m-nv-3\@sm {
    margin: -3px !important; }
  .mT-nv-3\@sm {
    margin-top: -3px !important; }
  .mR-nv-3\@sm {
    margin-right: -3px !important; }
  .mB-nv-3\@sm {
    margin-bottom: -3px !important; }
  .mL-nv-3\@sm {
    margin-left: -3px !important; }
  .mY-nv-3\@sm {
    margin-top: -3px !important;
    margin-bottom: -3px !important; }
  .mX-nv-3\@sm {
    margin-left: -3px !important;
    margin-right: -3px !important; } }

.m-4 {
  margin: 4px !important; }

.mT-4 {
  margin-top: 4px !important; }

.mR-4 {
  margin-right: 4px !important; }

.mB-4 {
  margin-bottom: 4px !important; }

.mL-4 {
  margin-left: 4px !important; }

.mY-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.mX-4 {
  margin-left: 4px !important;
  margin-right: 4px !important; }

.m-nv-4 {
  margin: -4px !important; }

.mT-nv-4 {
  margin-top: -4px !important; }

.mR-nv-4 {
  margin-right: -4px !important; }

.mB-nv-4 {
  margin-bottom: -0px !important; }

.mL-nv-4 {
  margin-left: -4px !important; }

.mY-nv-4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important; }

.mX-nv-4 {
  margin-left: -4px !important;
  margin-right: -4px !important; }

@media screen and (min-width: 1440px) {
  .m-4\@xl\+ {
    margin: 4px !important; }
  .mT-4\@xl\+ {
    margin-top: 4px !important; }
  .mR-4\@xl\+ {
    margin-right: 4px !important; }
  .mB-4\@xl\+ {
    margin-bottom: 4px !important; }
  .mL-4\@xl\+ {
    margin-left: 4px !important; }
  .mY-4\@xl\+ {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@xl\+ {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@xl\+ {
    margin: -4px !important; }
  .mT-nv-4\@xl\+ {
    margin-top: -4px !important; }
  .mR-nv-4\@xl\+ {
    margin-right: -4px !important; }
  .mB-nv-4\@xl\+ {
    margin-bottom: -4px !important; }
  .mL-nv-4\@xl\+ {
    margin-left: -4px !important; }
  .mY-nv-4\@xl\+ {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@xl\+ {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 1200px) {
  .m-4\@lg\+ {
    margin: 4px !important; }
  .mT-4\@lg\+ {
    margin-top: 4px !important; }
  .mR-4\@lg\+ {
    margin-right: 4px !important; }
  .mB-4\@lg\+ {
    margin-bottom: 4px !important; }
  .mL-4\@lg\+ {
    margin-left: 4px !important; }
  .mY-4\@lg\+ {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@lg\+ {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@lg\+ {
    margin: -4px !important; }
  .mT-nv-4\@lg\+ {
    margin-top: -4px !important; }
  .mR-nv-4\@lg\+ {
    margin-right: -4px !important; }
  .mB-nv-4\@lg\+ {
    margin-bottom: -4px !important; }
  .mL-nv-4\@lg\+ {
    margin-left: -4px !important; }
  .mY-nv-4\@lg\+ {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@lg\+ {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 992px) {
  .m-4\@md\+ {
    margin: 4px !important; }
  .mT-4\@md\+ {
    margin-top: 4px !important; }
  .mR-4\@md\+ {
    margin-right: 4px !important; }
  .mB-4\@md\+ {
    margin-bottom: 4px !important; }
  .mL-4\@md\+ {
    margin-left: 4px !important; }
  .mY-4\@md\+ {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@md\+ {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@md\+ {
    margin: -4px !important; }
  .mT-nv-4\@md\+ {
    margin-top: -4px !important; }
  .mR-nv-4\@md\+ {
    margin-right: -4px !important; }
  .mB-nv-4\@md\+ {
    margin-bottom: -4px !important; }
  .mL-nv-4\@md\+ {
    margin-left: -4px !important; }
  .mY-nv-4\@md\+ {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@md\+ {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 768px) {
  .m-4\@sm\+ {
    margin: 4px !important; }
  .mT-4\@sm\+ {
    margin-top: 4px !important; }
  .mR-4\@sm\+ {
    margin-right: 4px !important; }
  .mB-4\@sm\+ {
    margin-bottom: 4px !important; }
  .mL-4\@sm\+ {
    margin-left: 4px !important; }
  .mY-4\@sm\+ {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@sm\+ {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@sm\+ {
    margin: -4px !important; }
  .mT-nv-4\@sm\+ {
    margin-top: -4px !important; }
  .mR-nv-4\@sm\+ {
    margin-right: -4px !important; }
  .mB-nv-4\@sm\+ {
    margin-bottom: -4px !important; }
  .mL-nv-4\@sm\+ {
    margin-left: -4px !important; }
  .mY-nv-4\@sm\+ {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@sm\+ {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 0) {
  .m-4\@xs\+ {
    margin: 4px !important; }
  .mT-4\@xs\+ {
    margin-top: 4px !important; }
  .mR-4\@xs\+ {
    margin-right: 4px !important; }
  .mB-4\@xs\+ {
    margin-bottom: 4px !important; }
  .mL-4\@xs\+ {
    margin-left: 4px !important; }
  .mY-4\@xs\+ {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@xs\+ {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@xs\+ {
    margin: -4px !important; }
  .mT-nv-4\@xs\+ {
    margin-top: -4px !important; }
  .mR-nv-4\@xs\+ {
    margin-right: -4px !important; }
  .mB-nv-4\@xs\+ {
    margin-bottom: -4px !important; }
  .mL-nv-4\@xs\+ {
    margin-left: -4px !important; }
  .mY-nv-4\@xs\+ {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@xs\+ {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (max-width: 1439px) {
  .m-4\@xl- {
    margin: 4px !important; }
  .mT-4\@xl- {
    margin-top: 4px !important; }
  .mR-4\@xl- {
    margin-right: 4px !important; }
  .mB-4\@xl- {
    margin-bottom: 4px !important; }
  .mL-4\@xl- {
    margin-left: 4px !important; }
  .mY-4\@xl- {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@xl- {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@xl- {
    margin: -4px !important; }
  .mT-nv-4\@xl- {
    margin-top: -4px !important; }
  .mR-nv-4\@xl- {
    margin-right: -4px !important; }
  .mB-nv-4\@xl- {
    margin-bottom: -4px !important; }
  .mL-nv-4\@xl- {
    margin-left: -4px !important; }
  .mY-nv-4\@xl- {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@xl- {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (max-width: 1199px) {
  .m-4\@lg- {
    margin: 4px !important; }
  .mT-4\@lg- {
    margin-top: 4px !important; }
  .mR-4\@lg- {
    margin-right: 4px !important; }
  .mB-4\@lg- {
    margin-bottom: 4px !important; }
  .mL-4\@lg- {
    margin-left: 4px !important; }
  .mY-4\@lg- {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@lg- {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@lg- {
    margin: -4px !important; }
  .mT-nv-4\@lg- {
    margin-top: -4px !important; }
  .mR-nv-4\@lg- {
    margin-right: -4px !important; }
  .mB-nv-4\@lg- {
    margin-bottom: -4px !important; }
  .mL-nv-4\@lg- {
    margin-left: -4px !important; }
  .mY-nv-4\@lg- {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@lg- {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (max-width: 991px) {
  .m-4\@md- {
    margin: 4px !important; }
  .mT-4\@md- {
    margin-top: 4px !important; }
  .mR-4\@md- {
    margin-right: 4px !important; }
  .mB-4\@md- {
    margin-bottom: 4px !important; }
  .mL-4\@md- {
    margin-left: 4px !important; }
  .mY-4\@md- {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@md- {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@md- {
    margin: -4px !important; }
  .mT-nv-4\@md- {
    margin-top: -4px !important; }
  .mR-nv-4\@md- {
    margin-right: -4px !important; }
  .mB-nv-4\@md- {
    margin-bottom: -4px !important; }
  .mL-nv-4\@md- {
    margin-left: -4px !important; }
  .mY-nv-4\@md- {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@md- {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (max-width: 767px) {
  .m-4\@sm- {
    margin: 4px !important; }
  .mT-4\@sm- {
    margin-top: 4px !important; }
  .mR-4\@sm- {
    margin-right: 4px !important; }
  .mB-4\@sm- {
    margin-bottom: 4px !important; }
  .mL-4\@sm- {
    margin-left: 4px !important; }
  .mY-4\@sm- {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@sm- {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@sm- {
    margin: -4px !important; }
  .mT-nv-4\@sm- {
    margin-top: -4px !important; }
  .mR-nv-4\@sm- {
    margin-right: -4px !important; }
  .mB-nv-4\@sm- {
    margin-bottom: -4px !important; }
  .mL-nv-4\@sm- {
    margin-left: -4px !important; }
  .mY-nv-4\@sm- {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@sm- {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-4\@lg {
    margin: 4px !important; }
  .mT-4\@lg {
    margin-top: 4px !important; }
  .mR-4\@lg {
    margin-right: 4px !important; }
  .mB-4\@lg {
    margin-bottom: 4px !important; }
  .mL-4\@lg {
    margin-left: 4px !important; }
  .mY-4\@lg {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@lg {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@lg {
    margin: -4px !important; }
  .mT-nv-4\@lg {
    margin-top: -4px !important; }
  .mR-nv-4\@lg {
    margin-right: -4px !important; }
  .mB-nv-4\@lg {
    margin-bottom: -4px !important; }
  .mL-nv-4\@lg {
    margin-left: -4px !important; }
  .mY-nv-4\@lg {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@lg {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-4\@md {
    margin: 4px !important; }
  .mT-4\@md {
    margin-top: 4px !important; }
  .mR-4\@md {
    margin-right: 4px !important; }
  .mB-4\@md {
    margin-bottom: 4px !important; }
  .mL-4\@md {
    margin-left: 4px !important; }
  .mY-4\@md {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@md {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@md {
    margin: -4px !important; }
  .mT-nv-4\@md {
    margin-top: -4px !important; }
  .mR-nv-4\@md {
    margin-right: -4px !important; }
  .mB-nv-4\@md {
    margin-bottom: -4px !important; }
  .mL-nv-4\@md {
    margin-left: -4px !important; }
  .mY-nv-4\@md {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@md {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-4\@sm {
    margin: 4px !important; }
  .mT-4\@sm {
    margin-top: 4px !important; }
  .mR-4\@sm {
    margin-right: 4px !important; }
  .mB-4\@sm {
    margin-bottom: 4px !important; }
  .mL-4\@sm {
    margin-left: 4px !important; }
  .mY-4\@sm {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .mX-4\@sm {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  .m-nv-4\@sm {
    margin: -4px !important; }
  .mT-nv-4\@sm {
    margin-top: -4px !important; }
  .mR-nv-4\@sm {
    margin-right: -4px !important; }
  .mB-nv-4\@sm {
    margin-bottom: -4px !important; }
  .mL-nv-4\@sm {
    margin-left: -4px !important; }
  .mY-nv-4\@sm {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .mX-nv-4\@sm {
    margin-left: -4px !important;
    margin-right: -4px !important; } }

.m-5 {
  margin: 5px !important; }

.mT-5 {
  margin-top: 5px !important; }

.mR-5 {
  margin-right: 5px !important; }

.mB-5 {
  margin-bottom: 5px !important; }

.mL-5 {
  margin-left: 5px !important; }

.mY-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.mX-5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.m-nv-5 {
  margin: -5px !important; }

.mT-nv-5 {
  margin-top: -5px !important; }

.mR-nv-5 {
  margin-right: -5px !important; }

.mB-nv-5 {
  margin-bottom: -5px !important; }

.mL-nv-5 {
  margin-left: -5px !important; }

.mY-nv-5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important; }

.mX-nv-5 {
  margin-left: -5px !important;
  margin-right: -5px !important; }

@media screen and (min-width: 1440px) {
  .m-5\@xl\+ {
    margin: 5px !important; }
  .mT-5\@xl\+ {
    margin-top: 5px !important; }
  .mR-5\@xl\+ {
    margin-right: 5px !important; }
  .mB-5\@xl\+ {
    margin-bottom: 5px !important; }
  .mL-5\@xl\+ {
    margin-left: 5px !important; }
  .mY-5\@xl\+ {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@xl\+ {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@xl\+ {
    margin: -5px !important; }
  .mT-nv-5\@xl\+ {
    margin-top: -5px !important; }
  .mR-nv-5\@xl\+ {
    margin-right: -5px !important; }
  .mB-nv-5\@xl\+ {
    margin-bottom: -5px !important; }
  .mL-nv-5\@xl\+ {
    margin-left: -5px !important; }
  .mY-nv-5\@xl\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@xl\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 1200px) {
  .m-5\@lg\+ {
    margin: 5px !important; }
  .mT-5\@lg\+ {
    margin-top: 5px !important; }
  .mR-5\@lg\+ {
    margin-right: 5px !important; }
  .mB-5\@lg\+ {
    margin-bottom: 5px !important; }
  .mL-5\@lg\+ {
    margin-left: 5px !important; }
  .mY-5\@lg\+ {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@lg\+ {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@lg\+ {
    margin: -5px !important; }
  .mT-nv-5\@lg\+ {
    margin-top: -5px !important; }
  .mR-nv-5\@lg\+ {
    margin-right: -5px !important; }
  .mB-nv-5\@lg\+ {
    margin-bottom: -5px !important; }
  .mL-nv-5\@lg\+ {
    margin-left: -5px !important; }
  .mY-nv-5\@lg\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@lg\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 992px) {
  .m-5\@md\+ {
    margin: 5px !important; }
  .mT-5\@md\+ {
    margin-top: 5px !important; }
  .mR-5\@md\+ {
    margin-right: 5px !important; }
  .mB-5\@md\+ {
    margin-bottom: 5px !important; }
  .mL-5\@md\+ {
    margin-left: 5px !important; }
  .mY-5\@md\+ {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@md\+ {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@md\+ {
    margin: -5px !important; }
  .mT-nv-5\@md\+ {
    margin-top: -5px !important; }
  .mR-nv-5\@md\+ {
    margin-right: -5px !important; }
  .mB-nv-5\@md\+ {
    margin-bottom: -5px !important; }
  .mL-nv-5\@md\+ {
    margin-left: -5px !important; }
  .mY-nv-5\@md\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@md\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 768px) {
  .m-5\@sm\+ {
    margin: 5px !important; }
  .mT-5\@sm\+ {
    margin-top: 5px !important; }
  .mR-5\@sm\+ {
    margin-right: 5px !important; }
  .mB-5\@sm\+ {
    margin-bottom: 5px !important; }
  .mL-5\@sm\+ {
    margin-left: 5px !important; }
  .mY-5\@sm\+ {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@sm\+ {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@sm\+ {
    margin: -5px !important; }
  .mT-nv-5\@sm\+ {
    margin-top: -5px !important; }
  .mR-nv-5\@sm\+ {
    margin-right: -5px !important; }
  .mB-nv-5\@sm\+ {
    margin-bottom: -5px !important; }
  .mL-nv-5\@sm\+ {
    margin-left: -5px !important; }
  .mY-nv-5\@sm\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@sm\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 0) {
  .m-5\@xs\+ {
    margin: 5px !important; }
  .mT-5\@xs\+ {
    margin-top: 5px !important; }
  .mR-5\@xs\+ {
    margin-right: 5px !important; }
  .mB-5\@xs\+ {
    margin-bottom: 5px !important; }
  .mL-5\@xs\+ {
    margin-left: 5px !important; }
  .mY-5\@xs\+ {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@xs\+ {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@xs\+ {
    margin: -5px !important; }
  .mT-nv-5\@xs\+ {
    margin-top: -5px !important; }
  .mR-nv-5\@xs\+ {
    margin-right: -5px !important; }
  .mB-nv-5\@xs\+ {
    margin-bottom: -5px !important; }
  .mL-nv-5\@xs\+ {
    margin-left: -5px !important; }
  .mY-nv-5\@xs\+ {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@xs\+ {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (max-width: 1439px) {
  .m-5\@xl- {
    margin: 5px !important; }
  .mT-5\@xl- {
    margin-top: 5px !important; }
  .mR-5\@xl- {
    margin-right: 5px !important; }
  .mB-5\@xl- {
    margin-bottom: 5px !important; }
  .mL-5\@xl- {
    margin-left: 5px !important; }
  .mY-5\@xl- {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@xl- {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@xl- {
    margin: -5px !important; }
  .mT-nv-5\@xl- {
    margin-top: -5px !important; }
  .mR-nv-5\@xl- {
    margin-right: -5px !important; }
  .mB-nv-5\@xl- {
    margin-bottom: -5px !important; }
  .mL-nv-5\@xl- {
    margin-left: -5px !important; }
  .mY-nv-5\@xl- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@xl- {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (max-width: 1199px) {
  .m-5\@lg- {
    margin: 5px !important; }
  .mT-5\@lg- {
    margin-top: 5px !important; }
  .mR-5\@lg- {
    margin-right: 5px !important; }
  .mB-5\@lg- {
    margin-bottom: 5px !important; }
  .mL-5\@lg- {
    margin-left: 5px !important; }
  .mY-5\@lg- {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@lg- {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@lg- {
    margin: -5px !important; }
  .mT-nv-5\@lg- {
    margin-top: -5px !important; }
  .mR-nv-5\@lg- {
    margin-right: -5px !important; }
  .mB-nv-5\@lg- {
    margin-bottom: -5px !important; }
  .mL-nv-5\@lg- {
    margin-left: -5px !important; }
  .mY-nv-5\@lg- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@lg- {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (max-width: 991px) {
  .m-5\@md- {
    margin: 5px !important; }
  .mT-5\@md- {
    margin-top: 5px !important; }
  .mR-5\@md- {
    margin-right: 5px !important; }
  .mB-5\@md- {
    margin-bottom: 5px !important; }
  .mL-5\@md- {
    margin-left: 5px !important; }
  .mY-5\@md- {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@md- {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@md- {
    margin: -5px !important; }
  .mT-nv-5\@md- {
    margin-top: -5px !important; }
  .mR-nv-5\@md- {
    margin-right: -5px !important; }
  .mB-nv-5\@md- {
    margin-bottom: -5px !important; }
  .mL-nv-5\@md- {
    margin-left: -5px !important; }
  .mY-nv-5\@md- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@md- {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (max-width: 767px) {
  .m-5\@sm- {
    margin: 5px !important; }
  .mT-5\@sm- {
    margin-top: 5px !important; }
  .mR-5\@sm- {
    margin-right: 5px !important; }
  .mB-5\@sm- {
    margin-bottom: 5px !important; }
  .mL-5\@sm- {
    margin-left: 5px !important; }
  .mY-5\@sm- {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@sm- {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@sm- {
    margin: -5px !important; }
  .mT-nv-5\@sm- {
    margin-top: -5px !important; }
  .mR-nv-5\@sm- {
    margin-right: -5px !important; }
  .mB-nv-5\@sm- {
    margin-bottom: -5px !important; }
  .mL-nv-5\@sm- {
    margin-left: -5px !important; }
  .mY-nv-5\@sm- {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@sm- {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-5\@lg {
    margin: 5px !important; }
  .mT-5\@lg {
    margin-top: 5px !important; }
  .mR-5\@lg {
    margin-right: 5px !important; }
  .mB-5\@lg {
    margin-bottom: 5px !important; }
  .mL-5\@lg {
    margin-left: 5px !important; }
  .mY-5\@lg {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@lg {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@lg {
    margin: -5px !important; }
  .mT-nv-5\@lg {
    margin-top: -5px !important; }
  .mR-nv-5\@lg {
    margin-right: -5px !important; }
  .mB-nv-5\@lg {
    margin-bottom: -5px !important; }
  .mL-nv-5\@lg {
    margin-left: -5px !important; }
  .mY-nv-5\@lg {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@lg {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-5\@md {
    margin: 5px !important; }
  .mT-5\@md {
    margin-top: 5px !important; }
  .mR-5\@md {
    margin-right: 5px !important; }
  .mB-5\@md {
    margin-bottom: 5px !important; }
  .mL-5\@md {
    margin-left: 5px !important; }
  .mY-5\@md {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@md {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@md {
    margin: -5px !important; }
  .mT-nv-5\@md {
    margin-top: -5px !important; }
  .mR-nv-5\@md {
    margin-right: -5px !important; }
  .mB-nv-5\@md {
    margin-bottom: -5px !important; }
  .mL-nv-5\@md {
    margin-left: -5px !important; }
  .mY-nv-5\@md {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@md {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-5\@sm {
    margin: 5px !important; }
  .mT-5\@sm {
    margin-top: 5px !important; }
  .mR-5\@sm {
    margin-right: 5px !important; }
  .mB-5\@sm {
    margin-bottom: 5px !important; }
  .mL-5\@sm {
    margin-left: 5px !important; }
  .mY-5\@sm {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .mX-5\@sm {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .m-nv-5\@sm {
    margin: -5px !important; }
  .mT-nv-5\@sm {
    margin-top: -5px !important; }
  .mR-nv-5\@sm {
    margin-right: -5px !important; }
  .mB-nv-5\@sm {
    margin-bottom: -5px !important; }
  .mL-nv-5\@sm {
    margin-left: -5px !important; }
  .mY-nv-5\@sm {
    margin-top: -5px !important;
    margin-bottom: -5px !important; }
  .mX-nv-5\@sm {
    margin-left: -5px !important;
    margin-right: -5px !important; } }

.m-10 {
  margin: 10px !important; }

.mT-10 {
  margin-top: 10px !important; }

.mR-10 {
  margin-right: 10px !important; }

.mB-10 {
  margin-bottom: 10px !important; }

.mL-10 {
  margin-left: 10px !important; }

.mY-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.mX-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.m-nv-10 {
  margin: -10px !important; }

.mT-nv-10 {
  margin-top: -10px !important; }

.mR-nv-10 {
  margin-right: -10px !important; }

.mB-nv-10 {
  margin-bottom: -10px !important; }

.mL-nv-10 {
  margin-left: -10px !important; }

.mY-nv-10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important; }

.mX-nv-10 {
  margin-left: -10px !important;
  margin-right: -10px !important; }

@media screen and (min-width: 1440px) {
  .m-10\@xl\+ {
    margin: 10px !important; }
  .mT-10\@xl\+ {
    margin-top: 10px !important; }
  .mR-10\@xl\+ {
    margin-right: 10px !important; }
  .mB-10\@xl\+ {
    margin-bottom: 10px !important; }
  .mL-10\@xl\+ {
    margin-left: 10px !important; }
  .mY-10\@xl\+ {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@xl\+ {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@xl\+ {
    margin: -10px !important; }
  .mT-nv-10\@xl\+ {
    margin-top: -10px !important; }
  .mR-nv-10\@xl\+ {
    margin-right: -10px !important; }
  .mB-nv-10\@xl\+ {
    margin-bottom: -10px !important; }
  .mL-nv-10\@xl\+ {
    margin-left: -10px !important; }
  .mY-nv-10\@xl\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@xl\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 1200px) {
  .m-10\@lg\+ {
    margin: 10px !important; }
  .mT-10\@lg\+ {
    margin-top: 10px !important; }
  .mR-10\@lg\+ {
    margin-right: 10px !important; }
  .mB-10\@lg\+ {
    margin-bottom: 10px !important; }
  .mL-10\@lg\+ {
    margin-left: 10px !important; }
  .mY-10\@lg\+ {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@lg\+ {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@lg\+ {
    margin: -10px !important; }
  .mT-nv-10\@lg\+ {
    margin-top: -10px !important; }
  .mR-nv-10\@lg\+ {
    margin-right: -10px !important; }
  .mB-nv-10\@lg\+ {
    margin-bottom: -10px !important; }
  .mL-nv-10\@lg\+ {
    margin-left: -10px !important; }
  .mY-nv-10\@lg\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@lg\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 992px) {
  .m-10\@md\+ {
    margin: 10px !important; }
  .mT-10\@md\+ {
    margin-top: 10px !important; }
  .mR-10\@md\+ {
    margin-right: 10px !important; }
  .mB-10\@md\+ {
    margin-bottom: 10px !important; }
  .mL-10\@md\+ {
    margin-left: 10px !important; }
  .mY-10\@md\+ {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@md\+ {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@md\+ {
    margin: -10px !important; }
  .mT-nv-10\@md\+ {
    margin-top: -10px !important; }
  .mR-nv-10\@md\+ {
    margin-right: -10px !important; }
  .mB-nv-10\@md\+ {
    margin-bottom: -10px !important; }
  .mL-nv-10\@md\+ {
    margin-left: -10px !important; }
  .mY-nv-10\@md\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@md\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 768px) {
  .m-10\@sm\+ {
    margin: 10px !important; }
  .mT-10\@sm\+ {
    margin-top: 10px !important; }
  .mR-10\@sm\+ {
    margin-right: 10px !important; }
  .mB-10\@sm\+ {
    margin-bottom: 10px !important; }
  .mL-10\@sm\+ {
    margin-left: 10px !important; }
  .mY-10\@sm\+ {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@sm\+ {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@sm\+ {
    margin: -10px !important; }
  .mT-nv-10\@sm\+ {
    margin-top: -10px !important; }
  .mR-nv-10\@sm\+ {
    margin-right: -10px !important; }
  .mB-nv-10\@sm\+ {
    margin-bottom: -10px !important; }
  .mL-nv-10\@sm\+ {
    margin-left: -10px !important; }
  .mY-nv-10\@sm\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@sm\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 0) {
  .m-10\@xs\+ {
    margin: 10px !important; }
  .mT-10\@xs\+ {
    margin-top: 10px !important; }
  .mR-10\@xs\+ {
    margin-right: 10px !important; }
  .mB-10\@xs\+ {
    margin-bottom: 10px !important; }
  .mL-10\@xs\+ {
    margin-left: 10px !important; }
  .mY-10\@xs\+ {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@xs\+ {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@xs\+ {
    margin: -10px !important; }
  .mT-nv-10\@xs\+ {
    margin-top: -10px !important; }
  .mR-nv-10\@xs\+ {
    margin-right: -10px !important; }
  .mB-nv-10\@xs\+ {
    margin-bottom: -10px !important; }
  .mL-nv-10\@xs\+ {
    margin-left: -10px !important; }
  .mY-nv-10\@xs\+ {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@xs\+ {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (max-width: 1439px) {
  .m-10\@xl- {
    margin: 10px !important; }
  .mT-10\@xl- {
    margin-top: 10px !important; }
  .mR-10\@xl- {
    margin-right: 10px !important; }
  .mB-10\@xl- {
    margin-bottom: 10px !important; }
  .mL-10\@xl- {
    margin-left: 10px !important; }
  .mY-10\@xl- {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@xl- {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@xl- {
    margin: -10px !important; }
  .mT-nv-10\@xl- {
    margin-top: -10px !important; }
  .mR-nv-10\@xl- {
    margin-right: -10px !important; }
  .mB-nv-10\@xl- {
    margin-bottom: -10px !important; }
  .mL-nv-10\@xl- {
    margin-left: -10px !important; }
  .mY-nv-10\@xl- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@xl- {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (max-width: 1199px) {
  .m-10\@lg- {
    margin: 10px !important; }
  .mT-10\@lg- {
    margin-top: 10px !important; }
  .mR-10\@lg- {
    margin-right: 10px !important; }
  .mB-10\@lg- {
    margin-bottom: 10px !important; }
  .mL-10\@lg- {
    margin-left: 10px !important; }
  .mY-10\@lg- {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@lg- {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@lg- {
    margin: -10px !important; }
  .mT-nv-10\@lg- {
    margin-top: -10px !important; }
  .mR-nv-10\@lg- {
    margin-right: -10px !important; }
  .mB-nv-10\@lg- {
    margin-bottom: -10px !important; }
  .mL-nv-10\@lg- {
    margin-left: -10px !important; }
  .mY-nv-10\@lg- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@lg- {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (max-width: 991px) {
  .m-10\@md- {
    margin: 10px !important; }
  .mT-10\@md- {
    margin-top: 10px !important; }
  .mR-10\@md- {
    margin-right: 10px !important; }
  .mB-10\@md- {
    margin-bottom: 10px !important; }
  .mL-10\@md- {
    margin-left: 10px !important; }
  .mY-10\@md- {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@md- {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@md- {
    margin: -10px !important; }
  .mT-nv-10\@md- {
    margin-top: -10px !important; }
  .mR-nv-10\@md- {
    margin-right: -10px !important; }
  .mB-nv-10\@md- {
    margin-bottom: -10px !important; }
  .mL-nv-10\@md- {
    margin-left: -10px !important; }
  .mY-nv-10\@md- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@md- {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (max-width: 767px) {
  .m-10\@sm- {
    margin: 10px !important; }
  .mT-10\@sm- {
    margin-top: 10px !important; }
  .mR-10\@sm- {
    margin-right: 10px !important; }
  .mB-10\@sm- {
    margin-bottom: 10px !important; }
  .mL-10\@sm- {
    margin-left: 10px !important; }
  .mY-10\@sm- {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@sm- {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@sm- {
    margin: -10px !important; }
  .mT-nv-10\@sm- {
    margin-top: -10px !important; }
  .mR-nv-10\@sm- {
    margin-right: -10px !important; }
  .mB-nv-10\@sm- {
    margin-bottom: -10px !important; }
  .mL-nv-10\@sm- {
    margin-left: -10px !important; }
  .mY-nv-10\@sm- {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@sm- {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-10\@lg {
    margin: 10px !important; }
  .mT-10\@lg {
    margin-top: 10px !important; }
  .mR-10\@lg {
    margin-right: 10px !important; }
  .mB-10\@lg {
    margin-bottom: 10px !important; }
  .mL-10\@lg {
    margin-left: 10px !important; }
  .mY-10\@lg {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@lg {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@lg {
    margin: -10px !important; }
  .mT-nv-10\@lg {
    margin-top: -10px !important; }
  .mR-nv-10\@lg {
    margin-right: -10px !important; }
  .mB-nv-10\@lg {
    margin-bottom: -10px !important; }
  .mL-nv-10\@lg {
    margin-left: -10px !important; }
  .mY-nv-10\@lg {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@lg {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-10\@md {
    margin: 10px !important; }
  .mT-10\@md {
    margin-top: 10px !important; }
  .mR-10\@md {
    margin-right: 10px !important; }
  .mB-10\@md {
    margin-bottom: 10px !important; }
  .mL-10\@md {
    margin-left: 10px !important; }
  .mY-10\@md {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@md {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@md {
    margin: -10px !important; }
  .mT-nv-10\@md {
    margin-top: -10px !important; }
  .mR-nv-10\@md {
    margin-right: -10px !important; }
  .mB-nv-10\@md {
    margin-bottom: -10px !important; }
  .mL-nv-10\@md {
    margin-left: -10px !important; }
  .mY-nv-10\@md {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@md {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-10\@sm {
    margin: 10px !important; }
  .mT-10\@sm {
    margin-top: 10px !important; }
  .mR-10\@sm {
    margin-right: 10px !important; }
  .mB-10\@sm {
    margin-bottom: 10px !important; }
  .mL-10\@sm {
    margin-left: 10px !important; }
  .mY-10\@sm {
    margin-top: 10px !important;
    margin-bottom: 10px !important; }
  .mX-10\@sm {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .m-nv-10\@sm {
    margin: -10px !important; }
  .mT-nv-10\@sm {
    margin-top: -10px !important; }
  .mR-nv-10\@sm {
    margin-right: -10px !important; }
  .mB-nv-10\@sm {
    margin-bottom: -10px !important; }
  .mL-nv-10\@sm {
    margin-left: -10px !important; }
  .mY-nv-10\@sm {
    margin-top: -10px !important;
    margin-bottom: -10px !important; }
  .mX-nv-10\@sm {
    margin-left: -10px !important;
    margin-right: -10px !important; } }

.m-15 {
  margin: 15px !important; }

.mT-15 {
  margin-top: 15px !important; }

.mR-15 {
  margin-right: 15px !important; }

.mB-15 {
  margin-bottom: 15px !important; }

.mL-15 {
  margin-left: 15px !important; }

.mY-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.mX-15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.m-nv-15 {
  margin: -15px !important; }

.mT-nv-15 {
  margin-top: -15px !important; }

.mR-nv-15 {
  margin-right: -15px !important; }

.mB-nv-15 {
  margin-bottom: -15px !important; }

.mL-nv-15 {
  margin-left: -15px !important; }

.mY-nv-15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important; }

.mX-nv-15 {
  margin-left: -15px !important;
  margin-right: -15px !important; }

@media screen and (min-width: 1440px) {
  .m-15\@xl\+ {
    margin: 15px !important; }
  .mT-15\@xl\+ {
    margin-top: 15px !important; }
  .mR-15\@xl\+ {
    margin-right: 15px !important; }
  .mB-15\@xl\+ {
    margin-bottom: 15px !important; }
  .mL-15\@xl\+ {
    margin-left: 15px !important; }
  .mY-15\@xl\+ {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@xl\+ {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@xl\+ {
    margin: -15px !important; }
  .mT-nv-15\@xl\+ {
    margin-top: -15px !important; }
  .mR-nv-15\@xl\+ {
    margin-right: -15px !important; }
  .mB-nv-15\@xl\+ {
    margin-bottom: -15px !important; }
  .mL-nv-15\@xl\+ {
    margin-left: -15px !important; }
  .mY-nv-15\@xl\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@xl\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 1200px) {
  .m-15\@lg\+ {
    margin: 15px !important; }
  .mT-15\@lg\+ {
    margin-top: 15px !important; }
  .mR-15\@lg\+ {
    margin-right: 15px !important; }
  .mB-15\@lg\+ {
    margin-bottom: 15px !important; }
  .mL-15\@lg\+ {
    margin-left: 15px !important; }
  .mY-15\@lg\+ {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@lg\+ {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@lg\+ {
    margin: -15px !important; }
  .mT-nv-15\@lg\+ {
    margin-top: -15px !important; }
  .mR-nv-15\@lg\+ {
    margin-right: -15px !important; }
  .mB-nv-15\@lg\+ {
    margin-bottom: -15px !important; }
  .mL-nv-15\@lg\+ {
    margin-left: -15px !important; }
  .mY-nv-15\@lg\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@lg\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 992px) {
  .m-15\@md\+ {
    margin: 15px !important; }
  .mT-15\@md\+ {
    margin-top: 15px !important; }
  .mR-15\@md\+ {
    margin-right: 15px !important; }
  .mB-15\@md\+ {
    margin-bottom: 15px !important; }
  .mL-15\@md\+ {
    margin-left: 15px !important; }
  .mY-15\@md\+ {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@md\+ {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@md\+ {
    margin: -15px !important; }
  .mT-nv-15\@md\+ {
    margin-top: -15px !important; }
  .mR-nv-15\@md\+ {
    margin-right: -15px !important; }
  .mB-nv-15\@md\+ {
    margin-bottom: -15px !important; }
  .mL-nv-15\@md\+ {
    margin-left: -15px !important; }
  .mY-nv-15\@md\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@md\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 768px) {
  .m-15\@sm\+ {
    margin: 15px !important; }
  .mT-15\@sm\+ {
    margin-top: 15px !important; }
  .mR-15\@sm\+ {
    margin-right: 15px !important; }
  .mB-15\@sm\+ {
    margin-bottom: 15px !important; }
  .mL-15\@sm\+ {
    margin-left: 15px !important; }
  .mY-15\@sm\+ {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@sm\+ {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@sm\+ {
    margin: -15px !important; }
  .mT-nv-15\@sm\+ {
    margin-top: -15px !important; }
  .mR-nv-15\@sm\+ {
    margin-right: -15px !important; }
  .mB-nv-15\@sm\+ {
    margin-bottom: -15px !important; }
  .mL-nv-15\@sm\+ {
    margin-left: -15px !important; }
  .mY-nv-15\@sm\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@sm\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 0) {
  .m-15\@xs\+ {
    margin: 15px !important; }
  .mT-15\@xs\+ {
    margin-top: 15px !important; }
  .mR-15\@xs\+ {
    margin-right: 15px !important; }
  .mB-15\@xs\+ {
    margin-bottom: 15px !important; }
  .mL-15\@xs\+ {
    margin-left: 15px !important; }
  .mY-15\@xs\+ {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@xs\+ {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@xs\+ {
    margin: -15px !important; }
  .mT-nv-15\@xs\+ {
    margin-top: -15px !important; }
  .mR-nv-15\@xs\+ {
    margin-right: -15px !important; }
  .mB-nv-15\@xs\+ {
    margin-bottom: -15px !important; }
  .mL-nv-15\@xs\+ {
    margin-left: -15px !important; }
  .mY-nv-15\@xs\+ {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@xs\+ {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (max-width: 1439px) {
  .m-15\@xl- {
    margin: 15px !important; }
  .mT-15\@xl- {
    margin-top: 15px !important; }
  .mR-15\@xl- {
    margin-right: 15px !important; }
  .mB-15\@xl- {
    margin-bottom: 15px !important; }
  .mL-15\@xl- {
    margin-left: 15px !important; }
  .mY-15\@xl- {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@xl- {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@xl- {
    margin: -15px !important; }
  .mT-nv-15\@xl- {
    margin-top: -15px !important; }
  .mR-nv-15\@xl- {
    margin-right: -15px !important; }
  .mB-nv-15\@xl- {
    margin-bottom: -15px !important; }
  .mL-nv-15\@xl- {
    margin-left: -15px !important; }
  .mY-nv-15\@xl- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@xl- {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (max-width: 1199px) {
  .m-15\@lg- {
    margin: 15px !important; }
  .mT-15\@lg- {
    margin-top: 15px !important; }
  .mR-15\@lg- {
    margin-right: 15px !important; }
  .mB-15\@lg- {
    margin-bottom: 15px !important; }
  .mL-15\@lg- {
    margin-left: 15px !important; }
  .mY-15\@lg- {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@lg- {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@lg- {
    margin: -15px !important; }
  .mT-nv-15\@lg- {
    margin-top: -15px !important; }
  .mR-nv-15\@lg- {
    margin-right: -15px !important; }
  .mB-nv-15\@lg- {
    margin-bottom: -15px !important; }
  .mL-nv-15\@lg- {
    margin-left: -15px !important; }
  .mY-nv-15\@lg- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@lg- {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (max-width: 991px) {
  .m-15\@md- {
    margin: 15px !important; }
  .mT-15\@md- {
    margin-top: 15px !important; }
  .mR-15\@md- {
    margin-right: 15px !important; }
  .mB-15\@md- {
    margin-bottom: 15px !important; }
  .mL-15\@md- {
    margin-left: 15px !important; }
  .mY-15\@md- {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@md- {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@md- {
    margin: -15px !important; }
  .mT-nv-15\@md- {
    margin-top: -15px !important; }
  .mR-nv-15\@md- {
    margin-right: -15px !important; }
  .mB-nv-15\@md- {
    margin-bottom: -15px !important; }
  .mL-nv-15\@md- {
    margin-left: -15px !important; }
  .mY-nv-15\@md- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@md- {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (max-width: 767px) {
  .m-15\@sm- {
    margin: 15px !important; }
  .mT-15\@sm- {
    margin-top: 15px !important; }
  .mR-15\@sm- {
    margin-right: 15px !important; }
  .mB-15\@sm- {
    margin-bottom: 15px !important; }
  .mL-15\@sm- {
    margin-left: 15px !important; }
  .mY-15\@sm- {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@sm- {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@sm- {
    margin: -15px !important; }
  .mT-nv-15\@sm- {
    margin-top: -15px !important; }
  .mR-nv-15\@sm- {
    margin-right: -15px !important; }
  .mB-nv-15\@sm- {
    margin-bottom: -15px !important; }
  .mL-nv-15\@sm- {
    margin-left: -15px !important; }
  .mY-nv-15\@sm- {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@sm- {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-15\@lg {
    margin: 15px !important; }
  .mT-15\@lg {
    margin-top: 15px !important; }
  .mR-15\@lg {
    margin-right: 15px !important; }
  .mB-15\@lg {
    margin-bottom: 15px !important; }
  .mL-15\@lg {
    margin-left: 15px !important; }
  .mY-15\@lg {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@lg {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@lg {
    margin: -15px !important; }
  .mT-nv-15\@lg {
    margin-top: -15px !important; }
  .mR-nv-15\@lg {
    margin-right: -15px !important; }
  .mB-nv-15\@lg {
    margin-bottom: -15px !important; }
  .mL-nv-15\@lg {
    margin-left: -15px !important; }
  .mY-nv-15\@lg {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@lg {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-15\@md {
    margin: 15px !important; }
  .mT-15\@md {
    margin-top: 15px !important; }
  .mR-15\@md {
    margin-right: 15px !important; }
  .mB-15\@md {
    margin-bottom: 15px !important; }
  .mL-15\@md {
    margin-left: 15px !important; }
  .mY-15\@md {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@md {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@md {
    margin: -15px !important; }
  .mT-nv-15\@md {
    margin-top: -15px !important; }
  .mR-nv-15\@md {
    margin-right: -15px !important; }
  .mB-nv-15\@md {
    margin-bottom: -15px !important; }
  .mL-nv-15\@md {
    margin-left: -15px !important; }
  .mY-nv-15\@md {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@md {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-15\@sm {
    margin: 15px !important; }
  .mT-15\@sm {
    margin-top: 15px !important; }
  .mR-15\@sm {
    margin-right: 15px !important; }
  .mB-15\@sm {
    margin-bottom: 15px !important; }
  .mL-15\@sm {
    margin-left: 15px !important; }
  .mY-15\@sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .mX-15\@sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .m-nv-15\@sm {
    margin: -15px !important; }
  .mT-nv-15\@sm {
    margin-top: -15px !important; }
  .mR-nv-15\@sm {
    margin-right: -15px !important; }
  .mB-nv-15\@sm {
    margin-bottom: -15px !important; }
  .mL-nv-15\@sm {
    margin-left: -15px !important; }
  .mY-nv-15\@sm {
    margin-top: -15px !important;
    margin-bottom: -15px !important; }
  .mX-nv-15\@sm {
    margin-left: -15px !important;
    margin-right: -15px !important; } }

.m-20 {
  margin: 20px !important; }

.mT-20 {
  margin-top: 20px !important; }

.mR-20 {
  margin-right: 20px !important; }

.mB-20 {
  margin-bottom: 20px !important; }

.mL-20 {
  margin-left: 20px !important; }

.mY-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.mX-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.m-nv-20 {
  margin: -20px !important; }

.mT-nv-20 {
  margin-top: -20px !important; }

.mR-nv-20 {
  margin-right: -20px !important; }

.mB-nv-20 {
  margin-bottom: -20px !important; }

.mL-nv-20 {
  margin-left: -20px !important; }

.mY-nv-20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important; }

.mX-nv-20 {
  margin-left: -20px !important;
  margin-right: -20px !important; }

@media screen and (min-width: 1440px) {
  .m-20\@xl\+ {
    margin: 20px !important; }
  .mT-20\@xl\+ {
    margin-top: 20px !important; }
  .mR-20\@xl\+ {
    margin-right: 20px !important; }
  .mB-20\@xl\+ {
    margin-bottom: 20px !important; }
  .mL-20\@xl\+ {
    margin-left: 20px !important; }
  .mY-20\@xl\+ {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@xl\+ {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@xl\+ {
    margin: -20px !important; }
  .mT-nv-20\@xl\+ {
    margin-top: -20px !important; }
  .mR-nv-20\@xl\+ {
    margin-right: -20px !important; }
  .mB-nv-20\@xl\+ {
    margin-bottom: -20px !important; }
  .mL-nv-20\@xl\+ {
    margin-left: -20px !important; }
  .mY-nv-20\@xl\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@xl\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 1200px) {
  .m-20\@lg\+ {
    margin: 20px !important; }
  .mT-20\@lg\+ {
    margin-top: 20px !important; }
  .mR-20\@lg\+ {
    margin-right: 20px !important; }
  .mB-20\@lg\+ {
    margin-bottom: 20px !important; }
  .mL-20\@lg\+ {
    margin-left: 20px !important; }
  .mY-20\@lg\+ {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@lg\+ {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@lg\+ {
    margin: -20px !important; }
  .mT-nv-20\@lg\+ {
    margin-top: -20px !important; }
  .mR-nv-20\@lg\+ {
    margin-right: -20px !important; }
  .mB-nv-20\@lg\+ {
    margin-bottom: -20px !important; }
  .mL-nv-20\@lg\+ {
    margin-left: -20px !important; }
  .mY-nv-20\@lg\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@lg\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 992px) {
  .m-20\@md\+ {
    margin: 20px !important; }
  .mT-20\@md\+ {
    margin-top: 20px !important; }
  .mR-20\@md\+ {
    margin-right: 20px !important; }
  .mB-20\@md\+ {
    margin-bottom: 20px !important; }
  .mL-20\@md\+ {
    margin-left: 20px !important; }
  .mY-20\@md\+ {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@md\+ {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@md\+ {
    margin: -20px !important; }
  .mT-nv-20\@md\+ {
    margin-top: -20px !important; }
  .mR-nv-20\@md\+ {
    margin-right: -20px !important; }
  .mB-nv-20\@md\+ {
    margin-bottom: -20px !important; }
  .mL-nv-20\@md\+ {
    margin-left: -20px !important; }
  .mY-nv-20\@md\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@md\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 768px) {
  .m-20\@sm\+ {
    margin: 20px !important; }
  .mT-20\@sm\+ {
    margin-top: 20px !important; }
  .mR-20\@sm\+ {
    margin-right: 20px !important; }
  .mB-20\@sm\+ {
    margin-bottom: 20px !important; }
  .mL-20\@sm\+ {
    margin-left: 20px !important; }
  .mY-20\@sm\+ {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@sm\+ {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@sm\+ {
    margin: -20px !important; }
  .mT-nv-20\@sm\+ {
    margin-top: -20px !important; }
  .mR-nv-20\@sm\+ {
    margin-right: -20px !important; }
  .mB-nv-20\@sm\+ {
    margin-bottom: -20px !important; }
  .mL-nv-20\@sm\+ {
    margin-left: -20px !important; }
  .mY-nv-20\@sm\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@sm\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 0) {
  .m-20\@xs\+ {
    margin: 20px !important; }
  .mT-20\@xs\+ {
    margin-top: 20px !important; }
  .mR-20\@xs\+ {
    margin-right: 20px !important; }
  .mB-20\@xs\+ {
    margin-bottom: 20px !important; }
  .mL-20\@xs\+ {
    margin-left: 20px !important; }
  .mY-20\@xs\+ {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@xs\+ {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@xs\+ {
    margin: -20px !important; }
  .mT-nv-20\@xs\+ {
    margin-top: -20px !important; }
  .mR-nv-20\@xs\+ {
    margin-right: -20px !important; }
  .mB-nv-20\@xs\+ {
    margin-bottom: -20px !important; }
  .mL-nv-20\@xs\+ {
    margin-left: -20px !important; }
  .mY-nv-20\@xs\+ {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@xs\+ {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (max-width: 1439px) {
  .m-20\@xl- {
    margin: 20px !important; }
  .mT-20\@xl- {
    margin-top: 20px !important; }
  .mR-20\@xl- {
    margin-right: 20px !important; }
  .mB-20\@xl- {
    margin-bottom: 20px !important; }
  .mL-20\@xl- {
    margin-left: 20px !important; }
  .mY-20\@xl- {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@xl- {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@xl- {
    margin: -20px !important; }
  .mT-nv-20\@xl- {
    margin-top: -20px !important; }
  .mR-nv-20\@xl- {
    margin-right: -20px !important; }
  .mB-nv-20\@xl- {
    margin-bottom: -20px !important; }
  .mL-nv-20\@xl- {
    margin-left: -20px !important; }
  .mY-nv-20\@xl- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@xl- {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (max-width: 1199px) {
  .m-20\@lg- {
    margin: 20px !important; }
  .mT-20\@lg- {
    margin-top: 20px !important; }
  .mR-20\@lg- {
    margin-right: 20px !important; }
  .mB-20\@lg- {
    margin-bottom: 20px !important; }
  .mL-20\@lg- {
    margin-left: 20px !important; }
  .mY-20\@lg- {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@lg- {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@lg- {
    margin: -20px !important; }
  .mT-nv-20\@lg- {
    margin-top: -20px !important; }
  .mR-nv-20\@lg- {
    margin-right: -20px !important; }
  .mB-nv-20\@lg- {
    margin-bottom: -20px !important; }
  .mL-nv-20\@lg- {
    margin-left: -20px !important; }
  .mY-nv-20\@lg- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@lg- {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (max-width: 991px) {
  .m-20\@md- {
    margin: 20px !important; }
  .mT-20\@md- {
    margin-top: 20px !important; }
  .mR-20\@md- {
    margin-right: 20px !important; }
  .mB-20\@md- {
    margin-bottom: 20px !important; }
  .mL-20\@md- {
    margin-left: 20px !important; }
  .mY-20\@md- {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@md- {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@md- {
    margin: -20px !important; }
  .mT-nv-20\@md- {
    margin-top: -20px !important; }
  .mR-nv-20\@md- {
    margin-right: -20px !important; }
  .mB-nv-20\@md- {
    margin-bottom: -20px !important; }
  .mL-nv-20\@md- {
    margin-left: -20px !important; }
  .mY-nv-20\@md- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@md- {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (max-width: 767px) {
  .m-20\@sm- {
    margin: 20px !important; }
  .mT-20\@sm- {
    margin-top: 20px !important; }
  .mR-20\@sm- {
    margin-right: 20px !important; }
  .mB-20\@sm- {
    margin-bottom: 20px !important; }
  .mL-20\@sm- {
    margin-left: 20px !important; }
  .mY-20\@sm- {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@sm- {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@sm- {
    margin: -20px !important; }
  .mT-nv-20\@sm- {
    margin-top: -20px !important; }
  .mR-nv-20\@sm- {
    margin-right: -20px !important; }
  .mB-nv-20\@sm- {
    margin-bottom: -20px !important; }
  .mL-nv-20\@sm- {
    margin-left: -20px !important; }
  .mY-nv-20\@sm- {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@sm- {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-20\@lg {
    margin: 20px !important; }
  .mT-20\@lg {
    margin-top: 20px !important; }
  .mR-20\@lg {
    margin-right: 20px !important; }
  .mB-20\@lg {
    margin-bottom: 20px !important; }
  .mL-20\@lg {
    margin-left: 20px !important; }
  .mY-20\@lg {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@lg {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@lg {
    margin: -20px !important; }
  .mT-nv-20\@lg {
    margin-top: -20px !important; }
  .mR-nv-20\@lg {
    margin-right: -20px !important; }
  .mB-nv-20\@lg {
    margin-bottom: -20px !important; }
  .mL-nv-20\@lg {
    margin-left: -20px !important; }
  .mY-nv-20\@lg {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@lg {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-20\@md {
    margin: 20px !important; }
  .mT-20\@md {
    margin-top: 20px !important; }
  .mR-20\@md {
    margin-right: 20px !important; }
  .mB-20\@md {
    margin-bottom: 20px !important; }
  .mL-20\@md {
    margin-left: 20px !important; }
  .mY-20\@md {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@md {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@md {
    margin: -20px !important; }
  .mT-nv-20\@md {
    margin-top: -20px !important; }
  .mR-nv-20\@md {
    margin-right: -20px !important; }
  .mB-nv-20\@md {
    margin-bottom: -20px !important; }
  .mL-nv-20\@md {
    margin-left: -20px !important; }
  .mY-nv-20\@md {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@md {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-20\@sm {
    margin: 20px !important; }
  .mT-20\@sm {
    margin-top: 20px !important; }
  .mR-20\@sm {
    margin-right: 20px !important; }
  .mB-20\@sm {
    margin-bottom: 20px !important; }
  .mL-20\@sm {
    margin-left: 20px !important; }
  .mY-20\@sm {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .mX-20\@sm {
    margin-left: 20px !important;
    margin-right: 20px !important; }
  .m-nv-20\@sm {
    margin: -20px !important; }
  .mT-nv-20\@sm {
    margin-top: -20px !important; }
  .mR-nv-20\@sm {
    margin-right: -20px !important; }
  .mB-nv-20\@sm {
    margin-bottom: -20px !important; }
  .mL-nv-20\@sm {
    margin-left: -20px !important; }
  .mY-nv-20\@sm {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .mX-nv-20\@sm {
    margin-left: -20px !important;
    margin-right: -20px !important; } }

.m-25 {
  margin: 25px !important; }

.mT-25 {
  margin-top: 25px !important; }

.mR-25 {
  margin-right: 25px !important; }

.mB-25 {
  margin-bottom: 25px !important; }

.mL-25 {
  margin-left: 25px !important; }

.mY-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.mX-25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.m-nv-25 {
  margin: -25px !important; }

.mT-nv-25 {
  margin-top: -25px !important; }

.mR-nv-25 {
  margin-right: -25px !important; }

.mB-nv-25 {
  margin-bottom: -25px !important; }

.mL-nv-25 {
  margin-left: -25px !important; }

.mY-nv-25 {
  margin-top: -25px !important;
  margin-bottom: -25px !important; }

.mX-nv-25 {
  margin-left: -25px !important;
  margin-right: -25px !important; }

@media screen and (min-width: 1440px) {
  .m-25\@xl\+ {
    margin: 25px !important; }
  .mT-25\@xl\+ {
    margin-top: 25px !important; }
  .mR-25\@xl\+ {
    margin-right: 25px !important; }
  .mB-25\@xl\+ {
    margin-bottom: 25px !important; }
  .mL-25\@xl\+ {
    margin-left: 25px !important; }
  .mY-25\@xl\+ {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@xl\+ {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@xl\+ {
    margin: -25px !important; }
  .mT-nv-25\@xl\+ {
    margin-top: -25px !important; }
  .mR-nv-25\@xl\+ {
    margin-right: -25px !important; }
  .mB-nv-25\@xl\+ {
    margin-bottom: -25px !important; }
  .mL-nv-25\@xl\+ {
    margin-left: -25px !important; }
  .mY-nv-25\@xl\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@xl\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 1200px) {
  .m-25\@lg\+ {
    margin: 25px !important; }
  .mT-25\@lg\+ {
    margin-top: 25px !important; }
  .mR-25\@lg\+ {
    margin-right: 25px !important; }
  .mB-25\@lg\+ {
    margin-bottom: 25px !important; }
  .mL-25\@lg\+ {
    margin-left: 25px !important; }
  .mY-25\@lg\+ {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@lg\+ {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@lg\+ {
    margin: -25px !important; }
  .mT-nv-25\@lg\+ {
    margin-top: -25px !important; }
  .mR-nv-25\@lg\+ {
    margin-right: -25px !important; }
  .mB-nv-25\@lg\+ {
    margin-bottom: -25px !important; }
  .mL-nv-25\@lg\+ {
    margin-left: -25px !important; }
  .mY-nv-25\@lg\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@lg\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 992px) {
  .m-25\@md\+ {
    margin: 25px !important; }
  .mT-25\@md\+ {
    margin-top: 25px !important; }
  .mR-25\@md\+ {
    margin-right: 25px !important; }
  .mB-25\@md\+ {
    margin-bottom: 25px !important; }
  .mL-25\@md\+ {
    margin-left: 25px !important; }
  .mY-25\@md\+ {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@md\+ {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@md\+ {
    margin: -25px !important; }
  .mT-nv-25\@md\+ {
    margin-top: -25px !important; }
  .mR-nv-25\@md\+ {
    margin-right: -25px !important; }
  .mB-nv-25\@md\+ {
    margin-bottom: -25px !important; }
  .mL-nv-25\@md\+ {
    margin-left: -25px !important; }
  .mY-nv-25\@md\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@md\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 768px) {
  .m-25\@sm\+ {
    margin: 25px !important; }
  .mT-25\@sm\+ {
    margin-top: 25px !important; }
  .mR-25\@sm\+ {
    margin-right: 25px !important; }
  .mB-25\@sm\+ {
    margin-bottom: 25px !important; }
  .mL-25\@sm\+ {
    margin-left: 25px !important; }
  .mY-25\@sm\+ {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@sm\+ {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@sm\+ {
    margin: -25px !important; }
  .mT-nv-25\@sm\+ {
    margin-top: -25px !important; }
  .mR-nv-25\@sm\+ {
    margin-right: -25px !important; }
  .mB-nv-25\@sm\+ {
    margin-bottom: -25px !important; }
  .mL-nv-25\@sm\+ {
    margin-left: -25px !important; }
  .mY-nv-25\@sm\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@sm\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 0) {
  .m-25\@xs\+ {
    margin: 25px !important; }
  .mT-25\@xs\+ {
    margin-top: 25px !important; }
  .mR-25\@xs\+ {
    margin-right: 25px !important; }
  .mB-25\@xs\+ {
    margin-bottom: 25px !important; }
  .mL-25\@xs\+ {
    margin-left: 25px !important; }
  .mY-25\@xs\+ {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@xs\+ {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@xs\+ {
    margin: -25px !important; }
  .mT-nv-25\@xs\+ {
    margin-top: -25px !important; }
  .mR-nv-25\@xs\+ {
    margin-right: -25px !important; }
  .mB-nv-25\@xs\+ {
    margin-bottom: -25px !important; }
  .mL-nv-25\@xs\+ {
    margin-left: -25px !important; }
  .mY-nv-25\@xs\+ {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@xs\+ {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (max-width: 1439px) {
  .m-25\@xl- {
    margin: 25px !important; }
  .mT-25\@xl- {
    margin-top: 25px !important; }
  .mR-25\@xl- {
    margin-right: 25px !important; }
  .mB-25\@xl- {
    margin-bottom: 25px !important; }
  .mL-25\@xl- {
    margin-left: 25px !important; }
  .mY-25\@xl- {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@xl- {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@xl- {
    margin: -25px !important; }
  .mT-nv-25\@xl- {
    margin-top: -25px !important; }
  .mR-nv-25\@xl- {
    margin-right: -25px !important; }
  .mB-nv-25\@xl- {
    margin-bottom: -25px !important; }
  .mL-nv-25\@xl- {
    margin-left: -25px !important; }
  .mY-nv-25\@xl- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@xl- {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (max-width: 1199px) {
  .m-25\@lg- {
    margin: 25px !important; }
  .mT-25\@lg- {
    margin-top: 25px !important; }
  .mR-25\@lg- {
    margin-right: 25px !important; }
  .mB-25\@lg- {
    margin-bottom: 25px !important; }
  .mL-25\@lg- {
    margin-left: 25px !important; }
  .mY-25\@lg- {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@lg- {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@lg- {
    margin: -25px !important; }
  .mT-nv-25\@lg- {
    margin-top: -25px !important; }
  .mR-nv-25\@lg- {
    margin-right: -25px !important; }
  .mB-nv-25\@lg- {
    margin-bottom: -25px !important; }
  .mL-nv-25\@lg- {
    margin-left: -25px !important; }
  .mY-nv-25\@lg- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@lg- {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (max-width: 991px) {
  .m-25\@md- {
    margin: 25px !important; }
  .mT-25\@md- {
    margin-top: 25px !important; }
  .mR-25\@md- {
    margin-right: 25px !important; }
  .mB-25\@md- {
    margin-bottom: 25px !important; }
  .mL-25\@md- {
    margin-left: 25px !important; }
  .mY-25\@md- {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@md- {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@md- {
    margin: -25px !important; }
  .mT-nv-25\@md- {
    margin-top: -25px !important; }
  .mR-nv-25\@md- {
    margin-right: -25px !important; }
  .mB-nv-25\@md- {
    margin-bottom: -25px !important; }
  .mL-nv-25\@md- {
    margin-left: -25px !important; }
  .mY-nv-25\@md- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@md- {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (max-width: 767px) {
  .m-25\@sm- {
    margin: 25px !important; }
  .mT-25\@sm- {
    margin-top: 25px !important; }
  .mR-25\@sm- {
    margin-right: 25px !important; }
  .mB-25\@sm- {
    margin-bottom: 25px !important; }
  .mL-25\@sm- {
    margin-left: 25px !important; }
  .mY-25\@sm- {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@sm- {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@sm- {
    margin: -25px !important; }
  .mT-nv-25\@sm- {
    margin-top: -25px !important; }
  .mR-nv-25\@sm- {
    margin-right: -25px !important; }
  .mB-nv-25\@sm- {
    margin-bottom: -25px !important; }
  .mL-nv-25\@sm- {
    margin-left: -25px !important; }
  .mY-nv-25\@sm- {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@sm- {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-25\@lg {
    margin: 25px !important; }
  .mT-25\@lg {
    margin-top: 25px !important; }
  .mR-25\@lg {
    margin-right: 25px !important; }
  .mB-25\@lg {
    margin-bottom: 25px !important; }
  .mL-25\@lg {
    margin-left: 25px !important; }
  .mY-25\@lg {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@lg {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@lg {
    margin: -25px !important; }
  .mT-nv-25\@lg {
    margin-top: -25px !important; }
  .mR-nv-25\@lg {
    margin-right: -25px !important; }
  .mB-nv-25\@lg {
    margin-bottom: -25px !important; }
  .mL-nv-25\@lg {
    margin-left: -25px !important; }
  .mY-nv-25\@lg {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@lg {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-25\@md {
    margin: 25px !important; }
  .mT-25\@md {
    margin-top: 25px !important; }
  .mR-25\@md {
    margin-right: 25px !important; }
  .mB-25\@md {
    margin-bottom: 25px !important; }
  .mL-25\@md {
    margin-left: 25px !important; }
  .mY-25\@md {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@md {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@md {
    margin: -25px !important; }
  .mT-nv-25\@md {
    margin-top: -25px !important; }
  .mR-nv-25\@md {
    margin-right: -25px !important; }
  .mB-nv-25\@md {
    margin-bottom: -25px !important; }
  .mL-nv-25\@md {
    margin-left: -25px !important; }
  .mY-nv-25\@md {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@md {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-25\@sm {
    margin: 25px !important; }
  .mT-25\@sm {
    margin-top: 25px !important; }
  .mR-25\@sm {
    margin-right: 25px !important; }
  .mB-25\@sm {
    margin-bottom: 25px !important; }
  .mL-25\@sm {
    margin-left: 25px !important; }
  .mY-25\@sm {
    margin-top: 25px !important;
    margin-bottom: 25px !important; }
  .mX-25\@sm {
    margin-left: 25px !important;
    margin-right: 25px !important; }
  .m-nv-25\@sm {
    margin: -25px !important; }
  .mT-nv-25\@sm {
    margin-top: -25px !important; }
  .mR-nv-25\@sm {
    margin-right: -25px !important; }
  .mB-nv-25\@sm {
    margin-bottom: -25px !important; }
  .mL-nv-25\@sm {
    margin-left: -25px !important; }
  .mY-nv-25\@sm {
    margin-top: -25px !important;
    margin-bottom: -25px !important; }
  .mX-nv-25\@sm {
    margin-left: -25px !important;
    margin-right: -25px !important; } }

.m-30 {
  margin: 30px !important; }

.mT-30 {
  margin-top: 30px !important; }

.mR-30 {
  margin-right: 30px !important; }

.mB-30 {
  margin-bottom: 30px !important; }

.mL-30 {
  margin-left: 30px !important; }

.mY-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.mX-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.m-nv-30 {
  margin: -30px !important; }

.mT-nv-30 {
  margin-top: -30px !important; }

.mR-nv-30 {
  margin-right: -30px !important; }

.mB-nv-30 {
  margin-bottom: -30px !important; }

.mL-nv-30 {
  margin-left: -30px !important; }

.mY-nv-30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important; }

.mX-nv-30 {
  margin-left: -30px !important;
  margin-right: -30px !important; }

@media screen and (min-width: 1440px) {
  .m-30\@xl\+ {
    margin: 30px !important; }
  .mT-30\@xl\+ {
    margin-top: 30px !important; }
  .mR-30\@xl\+ {
    margin-right: 30px !important; }
  .mB-30\@xl\+ {
    margin-bottom: 30px !important; }
  .mL-30\@xl\+ {
    margin-left: 30px !important; }
  .mY-30\@xl\+ {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@xl\+ {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@xl\+ {
    margin: -30px !important; }
  .mT-nv-30\@xl\+ {
    margin-top: -30px !important; }
  .mR-nv-30\@xl\+ {
    margin-right: -30px !important; }
  .mB-nv-30\@xl\+ {
    margin-bottom: -30px !important; }
  .mL-nv-30\@xl\+ {
    margin-left: -30px !important; }
  .mY-nv-30\@xl\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@xl\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 1200px) {
  .m-30\@lg\+ {
    margin: 30px !important; }
  .mT-30\@lg\+ {
    margin-top: 30px !important; }
  .mR-30\@lg\+ {
    margin-right: 30px !important; }
  .mB-30\@lg\+ {
    margin-bottom: 30px !important; }
  .mL-30\@lg\+ {
    margin-left: 30px !important; }
  .mY-30\@lg\+ {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@lg\+ {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@lg\+ {
    margin: -30px !important; }
  .mT-nv-30\@lg\+ {
    margin-top: -30px !important; }
  .mR-nv-30\@lg\+ {
    margin-right: -30px !important; }
  .mB-nv-30\@lg\+ {
    margin-bottom: -30px !important; }
  .mL-nv-30\@lg\+ {
    margin-left: -30px !important; }
  .mY-nv-30\@lg\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@lg\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 992px) {
  .m-30\@md\+ {
    margin: 30px !important; }
  .mT-30\@md\+ {
    margin-top: 30px !important; }
  .mR-30\@md\+ {
    margin-right: 30px !important; }
  .mB-30\@md\+ {
    margin-bottom: 30px !important; }
  .mL-30\@md\+ {
    margin-left: 30px !important; }
  .mY-30\@md\+ {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@md\+ {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@md\+ {
    margin: -30px !important; }
  .mT-nv-30\@md\+ {
    margin-top: -30px !important; }
  .mR-nv-30\@md\+ {
    margin-right: -30px !important; }
  .mB-nv-30\@md\+ {
    margin-bottom: -30px !important; }
  .mL-nv-30\@md\+ {
    margin-left: -30px !important; }
  .mY-nv-30\@md\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@md\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 768px) {
  .m-30\@sm\+ {
    margin: 30px !important; }
  .mT-30\@sm\+ {
    margin-top: 30px !important; }
  .mR-30\@sm\+ {
    margin-right: 30px !important; }
  .mB-30\@sm\+ {
    margin-bottom: 30px !important; }
  .mL-30\@sm\+ {
    margin-left: 30px !important; }
  .mY-30\@sm\+ {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@sm\+ {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@sm\+ {
    margin: -30px !important; }
  .mT-nv-30\@sm\+ {
    margin-top: -30px !important; }
  .mR-nv-30\@sm\+ {
    margin-right: -30px !important; }
  .mB-nv-30\@sm\+ {
    margin-bottom: -30px !important; }
  .mL-nv-30\@sm\+ {
    margin-left: -30px !important; }
  .mY-nv-30\@sm\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@sm\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 0) {
  .m-30\@xs\+ {
    margin: 30px !important; }
  .mT-30\@xs\+ {
    margin-top: 30px !important; }
  .mR-30\@xs\+ {
    margin-right: 30px !important; }
  .mB-30\@xs\+ {
    margin-bottom: 30px !important; }
  .mL-30\@xs\+ {
    margin-left: 30px !important; }
  .mY-30\@xs\+ {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@xs\+ {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@xs\+ {
    margin: -30px !important; }
  .mT-nv-30\@xs\+ {
    margin-top: -30px !important; }
  .mR-nv-30\@xs\+ {
    margin-right: -30px !important; }
  .mB-nv-30\@xs\+ {
    margin-bottom: -30px !important; }
  .mL-nv-30\@xs\+ {
    margin-left: -30px !important; }
  .mY-nv-30\@xs\+ {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@xs\+ {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (max-width: 1439px) {
  .m-30\@xl- {
    margin: 30px !important; }
  .mT-30\@xl- {
    margin-top: 30px !important; }
  .mR-30\@xl- {
    margin-right: 30px !important; }
  .mB-30\@xl- {
    margin-bottom: 30px !important; }
  .mL-30\@xl- {
    margin-left: 30px !important; }
  .mY-30\@xl- {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@xl- {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@xl- {
    margin: -30px !important; }
  .mT-nv-30\@xl- {
    margin-top: -30px !important; }
  .mR-nv-30\@xl- {
    margin-right: -30px !important; }
  .mB-nv-30\@xl- {
    margin-bottom: -30px !important; }
  .mL-nv-30\@xl- {
    margin-left: -30px !important; }
  .mY-nv-30\@xl- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@xl- {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (max-width: 1199px) {
  .m-30\@lg- {
    margin: 30px !important; }
  .mT-30\@lg- {
    margin-top: 30px !important; }
  .mR-30\@lg- {
    margin-right: 30px !important; }
  .mB-30\@lg- {
    margin-bottom: 30px !important; }
  .mL-30\@lg- {
    margin-left: 30px !important; }
  .mY-30\@lg- {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@lg- {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@lg- {
    margin: -30px !important; }
  .mT-nv-30\@lg- {
    margin-top: -30px !important; }
  .mR-nv-30\@lg- {
    margin-right: -30px !important; }
  .mB-nv-30\@lg- {
    margin-bottom: -30px !important; }
  .mL-nv-30\@lg- {
    margin-left: -30px !important; }
  .mY-nv-30\@lg- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@lg- {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (max-width: 991px) {
  .m-30\@md- {
    margin: 30px !important; }
  .mT-30\@md- {
    margin-top: 30px !important; }
  .mR-30\@md- {
    margin-right: 30px !important; }
  .mB-30\@md- {
    margin-bottom: 30px !important; }
  .mL-30\@md- {
    margin-left: 30px !important; }
  .mY-30\@md- {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@md- {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@md- {
    margin: -30px !important; }
  .mT-nv-30\@md- {
    margin-top: -30px !important; }
  .mR-nv-30\@md- {
    margin-right: -30px !important; }
  .mB-nv-30\@md- {
    margin-bottom: -30px !important; }
  .mL-nv-30\@md- {
    margin-left: -30px !important; }
  .mY-nv-30\@md- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@md- {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (max-width: 767px) {
  .m-30\@sm- {
    margin: 30px !important; }
  .mT-30\@sm- {
    margin-top: 30px !important; }
  .mR-30\@sm- {
    margin-right: 30px !important; }
  .mB-30\@sm- {
    margin-bottom: 30px !important; }
  .mL-30\@sm- {
    margin-left: 30px !important; }
  .mY-30\@sm- {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@sm- {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@sm- {
    margin: -30px !important; }
  .mT-nv-30\@sm- {
    margin-top: -30px !important; }
  .mR-nv-30\@sm- {
    margin-right: -30px !important; }
  .mB-nv-30\@sm- {
    margin-bottom: -30px !important; }
  .mL-nv-30\@sm- {
    margin-left: -30px !important; }
  .mY-nv-30\@sm- {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@sm- {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-30\@lg {
    margin: 30px !important; }
  .mT-30\@lg {
    margin-top: 30px !important; }
  .mR-30\@lg {
    margin-right: 30px !important; }
  .mB-30\@lg {
    margin-bottom: 30px !important; }
  .mL-30\@lg {
    margin-left: 30px !important; }
  .mY-30\@lg {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@lg {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@lg {
    margin: -30px !important; }
  .mT-nv-30\@lg {
    margin-top: -30px !important; }
  .mR-nv-30\@lg {
    margin-right: -30px !important; }
  .mB-nv-30\@lg {
    margin-bottom: -30px !important; }
  .mL-nv-30\@lg {
    margin-left: -30px !important; }
  .mY-nv-30\@lg {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@lg {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-30\@md {
    margin: 30px !important; }
  .mT-30\@md {
    margin-top: 30px !important; }
  .mR-30\@md {
    margin-right: 30px !important; }
  .mB-30\@md {
    margin-bottom: 30px !important; }
  .mL-30\@md {
    margin-left: 30px !important; }
  .mY-30\@md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@md {
    margin: -30px !important; }
  .mT-nv-30\@md {
    margin-top: -30px !important; }
  .mR-nv-30\@md {
    margin-right: -30px !important; }
  .mB-nv-30\@md {
    margin-bottom: -30px !important; }
  .mL-nv-30\@md {
    margin-left: -30px !important; }
  .mY-nv-30\@md {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@md {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-30\@sm {
    margin: 30px !important; }
  .mT-30\@sm {
    margin-top: 30px !important; }
  .mR-30\@sm {
    margin-right: 30px !important; }
  .mB-30\@sm {
    margin-bottom: 30px !important; }
  .mL-30\@sm {
    margin-left: 30px !important; }
  .mY-30\@sm {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .mX-30\@sm {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .m-nv-30\@sm {
    margin: -30px !important; }
  .mT-nv-30\@sm {
    margin-top: -30px !important; }
  .mR-nv-30\@sm {
    margin-right: -30px !important; }
  .mB-nv-30\@sm {
    margin-bottom: -30px !important; }
  .mL-nv-30\@sm {
    margin-left: -30px !important; }
  .mY-nv-30\@sm {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .mX-nv-30\@sm {
    margin-left: -30px !important;
    margin-right: -30px !important; } }

.m-35 {
  margin: 35px !important; }

.mT-35 {
  margin-top: 35px !important; }

.mR-35 {
  margin-right: 35px !important; }

.mB-35 {
  margin-bottom: 35px !important; }

.mL-35 {
  margin-left: 35px !important; }

.mY-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.mX-35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.m-nv-35 {
  margin: -35px !important; }

.mT-nv-35 {
  margin-top: -35px !important; }

.mR-nv-35 {
  margin-right: -35px !important; }

.mB-nv-35 {
  margin-bottom: -35px !important; }

.mL-nv-35 {
  margin-left: -35px !important; }

.mY-nv-35 {
  margin-top: -35px !important;
  margin-bottom: -35px !important; }

.mX-nv-35 {
  margin-left: -35px !important;
  margin-right: -35px !important; }

@media screen and (min-width: 1440px) {
  .m-35\@xl\+ {
    margin: 35px !important; }
  .mT-35\@xl\+ {
    margin-top: 35px !important; }
  .mR-35\@xl\+ {
    margin-right: 35px !important; }
  .mB-35\@xl\+ {
    margin-bottom: 35px !important; }
  .mL-35\@xl\+ {
    margin-left: 35px !important; }
  .mY-35\@xl\+ {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@xl\+ {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@xl\+ {
    margin: -35px !important; }
  .mT-nv-35\@xl\+ {
    margin-top: -35px !important; }
  .mR-nv-35\@xl\+ {
    margin-right: -35px !important; }
  .mB-nv-35\@xl\+ {
    margin-bottom: -35px !important; }
  .mL-nv-35\@xl\+ {
    margin-left: -35px !important; }
  .mY-nv-35\@xl\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@xl\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 1200px) {
  .m-35\@lg\+ {
    margin: 35px !important; }
  .mT-35\@lg\+ {
    margin-top: 35px !important; }
  .mR-35\@lg\+ {
    margin-right: 35px !important; }
  .mB-35\@lg\+ {
    margin-bottom: 35px !important; }
  .mL-35\@lg\+ {
    margin-left: 35px !important; }
  .mY-35\@lg\+ {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@lg\+ {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@lg\+ {
    margin: -35px !important; }
  .mT-nv-35\@lg\+ {
    margin-top: -35px !important; }
  .mR-nv-35\@lg\+ {
    margin-right: -35px !important; }
  .mB-nv-35\@lg\+ {
    margin-bottom: -35px !important; }
  .mL-nv-35\@lg\+ {
    margin-left: -35px !important; }
  .mY-nv-35\@lg\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@lg\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 992px) {
  .m-35\@md\+ {
    margin: 35px !important; }
  .mT-35\@md\+ {
    margin-top: 35px !important; }
  .mR-35\@md\+ {
    margin-right: 35px !important; }
  .mB-35\@md\+ {
    margin-bottom: 35px !important; }
  .mL-35\@md\+ {
    margin-left: 35px !important; }
  .mY-35\@md\+ {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@md\+ {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@md\+ {
    margin: -35px !important; }
  .mT-nv-35\@md\+ {
    margin-top: -35px !important; }
  .mR-nv-35\@md\+ {
    margin-right: -35px !important; }
  .mB-nv-35\@md\+ {
    margin-bottom: -35px !important; }
  .mL-nv-35\@md\+ {
    margin-left: -35px !important; }
  .mY-nv-35\@md\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@md\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 768px) {
  .m-35\@sm\+ {
    margin: 35px !important; }
  .mT-35\@sm\+ {
    margin-top: 35px !important; }
  .mR-35\@sm\+ {
    margin-right: 35px !important; }
  .mB-35\@sm\+ {
    margin-bottom: 35px !important; }
  .mL-35\@sm\+ {
    margin-left: 35px !important; }
  .mY-35\@sm\+ {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@sm\+ {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@sm\+ {
    margin: -35px !important; }
  .mT-nv-35\@sm\+ {
    margin-top: -35px !important; }
  .mR-nv-35\@sm\+ {
    margin-right: -35px !important; }
  .mB-nv-35\@sm\+ {
    margin-bottom: -35px !important; }
  .mL-nv-35\@sm\+ {
    margin-left: -35px !important; }
  .mY-nv-35\@sm\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@sm\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 0) {
  .m-35\@xs\+ {
    margin: 35px !important; }
  .mT-35\@xs\+ {
    margin-top: 35px !important; }
  .mR-35\@xs\+ {
    margin-right: 35px !important; }
  .mB-35\@xs\+ {
    margin-bottom: 35px !important; }
  .mL-35\@xs\+ {
    margin-left: 35px !important; }
  .mY-35\@xs\+ {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@xs\+ {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@xs\+ {
    margin: -35px !important; }
  .mT-nv-35\@xs\+ {
    margin-top: -35px !important; }
  .mR-nv-35\@xs\+ {
    margin-right: -35px !important; }
  .mB-nv-35\@xs\+ {
    margin-bottom: -35px !important; }
  .mL-nv-35\@xs\+ {
    margin-left: -35px !important; }
  .mY-nv-35\@xs\+ {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@xs\+ {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (max-width: 1439px) {
  .m-35\@xl- {
    margin: 35px !important; }
  .mT-35\@xl- {
    margin-top: 35px !important; }
  .mR-35\@xl- {
    margin-right: 35px !important; }
  .mB-35\@xl- {
    margin-bottom: 35px !important; }
  .mL-35\@xl- {
    margin-left: 35px !important; }
  .mY-35\@xl- {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@xl- {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@xl- {
    margin: -35px !important; }
  .mT-nv-35\@xl- {
    margin-top: -35px !important; }
  .mR-nv-35\@xl- {
    margin-right: -35px !important; }
  .mB-nv-35\@xl- {
    margin-bottom: -35px !important; }
  .mL-nv-35\@xl- {
    margin-left: -35px !important; }
  .mY-nv-35\@xl- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@xl- {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (max-width: 1199px) {
  .m-35\@lg- {
    margin: 35px !important; }
  .mT-35\@lg- {
    margin-top: 35px !important; }
  .mR-35\@lg- {
    margin-right: 35px !important; }
  .mB-35\@lg- {
    margin-bottom: 35px !important; }
  .mL-35\@lg- {
    margin-left: 35px !important; }
  .mY-35\@lg- {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@lg- {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@lg- {
    margin: -35px !important; }
  .mT-nv-35\@lg- {
    margin-top: -35px !important; }
  .mR-nv-35\@lg- {
    margin-right: -35px !important; }
  .mB-nv-35\@lg- {
    margin-bottom: -35px !important; }
  .mL-nv-35\@lg- {
    margin-left: -35px !important; }
  .mY-nv-35\@lg- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@lg- {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (max-width: 991px) {
  .m-35\@md- {
    margin: 35px !important; }
  .mT-35\@md- {
    margin-top: 35px !important; }
  .mR-35\@md- {
    margin-right: 35px !important; }
  .mB-35\@md- {
    margin-bottom: 35px !important; }
  .mL-35\@md- {
    margin-left: 35px !important; }
  .mY-35\@md- {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@md- {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@md- {
    margin: -35px !important; }
  .mT-nv-35\@md- {
    margin-top: -35px !important; }
  .mR-nv-35\@md- {
    margin-right: -35px !important; }
  .mB-nv-35\@md- {
    margin-bottom: -35px !important; }
  .mL-nv-35\@md- {
    margin-left: -35px !important; }
  .mY-nv-35\@md- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@md- {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (max-width: 767px) {
  .m-35\@sm- {
    margin: 35px !important; }
  .mT-35\@sm- {
    margin-top: 35px !important; }
  .mR-35\@sm- {
    margin-right: 35px !important; }
  .mB-35\@sm- {
    margin-bottom: 35px !important; }
  .mL-35\@sm- {
    margin-left: 35px !important; }
  .mY-35\@sm- {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@sm- {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@sm- {
    margin: -35px !important; }
  .mT-nv-35\@sm- {
    margin-top: -35px !important; }
  .mR-nv-35\@sm- {
    margin-right: -35px !important; }
  .mB-nv-35\@sm- {
    margin-bottom: -35px !important; }
  .mL-nv-35\@sm- {
    margin-left: -35px !important; }
  .mY-nv-35\@sm- {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@sm- {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-35\@lg {
    margin: 35px !important; }
  .mT-35\@lg {
    margin-top: 35px !important; }
  .mR-35\@lg {
    margin-right: 35px !important; }
  .mB-35\@lg {
    margin-bottom: 35px !important; }
  .mL-35\@lg {
    margin-left: 35px !important; }
  .mY-35\@lg {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@lg {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@lg {
    margin: -35px !important; }
  .mT-nv-35\@lg {
    margin-top: -35px !important; }
  .mR-nv-35\@lg {
    margin-right: -35px !important; }
  .mB-nv-35\@lg {
    margin-bottom: -35px !important; }
  .mL-nv-35\@lg {
    margin-left: -35px !important; }
  .mY-nv-35\@lg {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@lg {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-35\@md {
    margin: 35px !important; }
  .mT-35\@md {
    margin-top: 35px !important; }
  .mR-35\@md {
    margin-right: 35px !important; }
  .mB-35\@md {
    margin-bottom: 35px !important; }
  .mL-35\@md {
    margin-left: 35px !important; }
  .mY-35\@md {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@md {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@md {
    margin: -35px !important; }
  .mT-nv-35\@md {
    margin-top: -35px !important; }
  .mR-nv-35\@md {
    margin-right: -35px !important; }
  .mB-nv-35\@md {
    margin-bottom: -35px !important; }
  .mL-nv-35\@md {
    margin-left: -35px !important; }
  .mY-nv-35\@md {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@md {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-35\@sm {
    margin: 35px !important; }
  .mT-35\@sm {
    margin-top: 35px !important; }
  .mR-35\@sm {
    margin-right: 35px !important; }
  .mB-35\@sm {
    margin-bottom: 35px !important; }
  .mL-35\@sm {
    margin-left: 35px !important; }
  .mY-35\@sm {
    margin-top: 35px !important;
    margin-bottom: 35px !important; }
  .mX-35\@sm {
    margin-left: 35px !important;
    margin-right: 35px !important; }
  .m-nv-35\@sm {
    margin: -35px !important; }
  .mT-nv-35\@sm {
    margin-top: -35px !important; }
  .mR-nv-35\@sm {
    margin-right: -35px !important; }
  .mB-nv-35\@sm {
    margin-bottom: -35px !important; }
  .mL-nv-35\@sm {
    margin-left: -35px !important; }
  .mY-nv-35\@sm {
    margin-top: -35px !important;
    margin-bottom: -35px !important; }
  .mX-nv-35\@sm {
    margin-left: -35px !important;
    margin-right: -35px !important; } }

.m-40 {
  margin: 40px !important; }

.mT-40 {
  margin-top: 40px !important; }

.mR-40 {
  margin-right: 40px !important; }

.mB-40 {
  margin-bottom: 40px !important; }

.mL-40 {
  margin-left: 40px !important; }

.mY-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.mX-40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.m-nv-40 {
  margin: -40px !important; }

.mT-nv-40 {
  margin-top: -40px !important; }

.mR-nv-40 {
  margin-right: -40px !important; }

.mB-nv-40 {
  margin-bottom: -40px !important; }

.mL-nv-40 {
  margin-left: -40px !important; }

.mY-nv-40 {
  margin-top: -40px !important;
  margin-bottom: -40px !important; }

.mX-nv-40 {
  margin-left: -40px !important;
  margin-right: -40px !important; }

@media screen and (min-width: 1440px) {
  .m-40\@xl\+ {
    margin: 40px !important; }
  .mT-40\@xl\+ {
    margin-top: 40px !important; }
  .mR-40\@xl\+ {
    margin-right: 40px !important; }
  .mB-40\@xl\+ {
    margin-bottom: 40px !important; }
  .mL-40\@xl\+ {
    margin-left: 40px !important; }
  .mY-40\@xl\+ {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@xl\+ {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@xl\+ {
    margin: -40px !important; }
  .mT-nv-40\@xl\+ {
    margin-top: -40px !important; }
  .mR-nv-40\@xl\+ {
    margin-right: -40px !important; }
  .mB-nv-40\@xl\+ {
    margin-bottom: -40px !important; }
  .mL-nv-40\@xl\+ {
    margin-left: -40px !important; }
  .mY-nv-40\@xl\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@xl\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 1200px) {
  .m-40\@lg\+ {
    margin: 40px !important; }
  .mT-40\@lg\+ {
    margin-top: 40px !important; }
  .mR-40\@lg\+ {
    margin-right: 40px !important; }
  .mB-40\@lg\+ {
    margin-bottom: 40px !important; }
  .mL-40\@lg\+ {
    margin-left: 40px !important; }
  .mY-40\@lg\+ {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@lg\+ {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@lg\+ {
    margin: -40px !important; }
  .mT-nv-40\@lg\+ {
    margin-top: -40px !important; }
  .mR-nv-40\@lg\+ {
    margin-right: -40px !important; }
  .mB-nv-40\@lg\+ {
    margin-bottom: -40px !important; }
  .mL-nv-40\@lg\+ {
    margin-left: -40px !important; }
  .mY-nv-40\@lg\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@lg\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 992px) {
  .m-40\@md\+ {
    margin: 40px !important; }
  .mT-40\@md\+ {
    margin-top: 40px !important; }
  .mR-40\@md\+ {
    margin-right: 40px !important; }
  .mB-40\@md\+ {
    margin-bottom: 40px !important; }
  .mL-40\@md\+ {
    margin-left: 40px !important; }
  .mY-40\@md\+ {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@md\+ {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@md\+ {
    margin: -40px !important; }
  .mT-nv-40\@md\+ {
    margin-top: -40px !important; }
  .mR-nv-40\@md\+ {
    margin-right: -40px !important; }
  .mB-nv-40\@md\+ {
    margin-bottom: -40px !important; }
  .mL-nv-40\@md\+ {
    margin-left: -40px !important; }
  .mY-nv-40\@md\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@md\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 768px) {
  .m-40\@sm\+ {
    margin: 40px !important; }
  .mT-40\@sm\+ {
    margin-top: 40px !important; }
  .mR-40\@sm\+ {
    margin-right: 40px !important; }
  .mB-40\@sm\+ {
    margin-bottom: 40px !important; }
  .mL-40\@sm\+ {
    margin-left: 40px !important; }
  .mY-40\@sm\+ {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@sm\+ {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@sm\+ {
    margin: -40px !important; }
  .mT-nv-40\@sm\+ {
    margin-top: -40px !important; }
  .mR-nv-40\@sm\+ {
    margin-right: -40px !important; }
  .mB-nv-40\@sm\+ {
    margin-bottom: -40px !important; }
  .mL-nv-40\@sm\+ {
    margin-left: -40px !important; }
  .mY-nv-40\@sm\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@sm\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 0) {
  .m-40\@xs\+ {
    margin: 40px !important; }
  .mT-40\@xs\+ {
    margin-top: 40px !important; }
  .mR-40\@xs\+ {
    margin-right: 40px !important; }
  .mB-40\@xs\+ {
    margin-bottom: 40px !important; }
  .mL-40\@xs\+ {
    margin-left: 40px !important; }
  .mY-40\@xs\+ {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@xs\+ {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@xs\+ {
    margin: -40px !important; }
  .mT-nv-40\@xs\+ {
    margin-top: -40px !important; }
  .mR-nv-40\@xs\+ {
    margin-right: -40px !important; }
  .mB-nv-40\@xs\+ {
    margin-bottom: -40px !important; }
  .mL-nv-40\@xs\+ {
    margin-left: -40px !important; }
  .mY-nv-40\@xs\+ {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@xs\+ {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (max-width: 1439px) {
  .m-40\@xl- {
    margin: 40px !important; }
  .mT-40\@xl- {
    margin-top: 40px !important; }
  .mR-40\@xl- {
    margin-right: 40px !important; }
  .mB-40\@xl- {
    margin-bottom: 40px !important; }
  .mL-40\@xl- {
    margin-left: 40px !important; }
  .mY-40\@xl- {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@xl- {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@xl- {
    margin: -40px !important; }
  .mT-nv-40\@xl- {
    margin-top: -40px !important; }
  .mR-nv-40\@xl- {
    margin-right: -40px !important; }
  .mB-nv-40\@xl- {
    margin-bottom: -40px !important; }
  .mL-nv-40\@xl- {
    margin-left: -40px !important; }
  .mY-nv-40\@xl- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@xl- {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (max-width: 1199px) {
  .m-40\@lg- {
    margin: 40px !important; }
  .mT-40\@lg- {
    margin-top: 40px !important; }
  .mR-40\@lg- {
    margin-right: 40px !important; }
  .mB-40\@lg- {
    margin-bottom: 40px !important; }
  .mL-40\@lg- {
    margin-left: 40px !important; }
  .mY-40\@lg- {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@lg- {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@lg- {
    margin: -40px !important; }
  .mT-nv-40\@lg- {
    margin-top: -40px !important; }
  .mR-nv-40\@lg- {
    margin-right: -40px !important; }
  .mB-nv-40\@lg- {
    margin-bottom: -40px !important; }
  .mL-nv-40\@lg- {
    margin-left: -40px !important; }
  .mY-nv-40\@lg- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@lg- {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (max-width: 991px) {
  .m-40\@md- {
    margin: 40px !important; }
  .mT-40\@md- {
    margin-top: 40px !important; }
  .mR-40\@md- {
    margin-right: 40px !important; }
  .mB-40\@md- {
    margin-bottom: 40px !important; }
  .mL-40\@md- {
    margin-left: 40px !important; }
  .mY-40\@md- {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@md- {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@md- {
    margin: -40px !important; }
  .mT-nv-40\@md- {
    margin-top: -40px !important; }
  .mR-nv-40\@md- {
    margin-right: -40px !important; }
  .mB-nv-40\@md- {
    margin-bottom: -40px !important; }
  .mL-nv-40\@md- {
    margin-left: -40px !important; }
  .mY-nv-40\@md- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@md- {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (max-width: 767px) {
  .m-40\@sm- {
    margin: 40px !important; }
  .mT-40\@sm- {
    margin-top: 40px !important; }
  .mR-40\@sm- {
    margin-right: 40px !important; }
  .mB-40\@sm- {
    margin-bottom: 40px !important; }
  .mL-40\@sm- {
    margin-left: 40px !important; }
  .mY-40\@sm- {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@sm- {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@sm- {
    margin: -40px !important; }
  .mT-nv-40\@sm- {
    margin-top: -40px !important; }
  .mR-nv-40\@sm- {
    margin-right: -40px !important; }
  .mB-nv-40\@sm- {
    margin-bottom: -40px !important; }
  .mL-nv-40\@sm- {
    margin-left: -40px !important; }
  .mY-nv-40\@sm- {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@sm- {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-40\@lg {
    margin: 40px !important; }
  .mT-40\@lg {
    margin-top: 40px !important; }
  .mR-40\@lg {
    margin-right: 40px !important; }
  .mB-40\@lg {
    margin-bottom: 40px !important; }
  .mL-40\@lg {
    margin-left: 40px !important; }
  .mY-40\@lg {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@lg {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@lg {
    margin: -40px !important; }
  .mT-nv-40\@lg {
    margin-top: -40px !important; }
  .mR-nv-40\@lg {
    margin-right: -40px !important; }
  .mB-nv-40\@lg {
    margin-bottom: -40px !important; }
  .mL-nv-40\@lg {
    margin-left: -40px !important; }
  .mY-nv-40\@lg {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@lg {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-40\@md {
    margin: 40px !important; }
  .mT-40\@md {
    margin-top: 40px !important; }
  .mR-40\@md {
    margin-right: 40px !important; }
  .mB-40\@md {
    margin-bottom: 40px !important; }
  .mL-40\@md {
    margin-left: 40px !important; }
  .mY-40\@md {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@md {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@md {
    margin: -40px !important; }
  .mT-nv-40\@md {
    margin-top: -40px !important; }
  .mR-nv-40\@md {
    margin-right: -40px !important; }
  .mB-nv-40\@md {
    margin-bottom: -40px !important; }
  .mL-nv-40\@md {
    margin-left: -40px !important; }
  .mY-nv-40\@md {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@md {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-40\@sm {
    margin: 40px !important; }
  .mT-40\@sm {
    margin-top: 40px !important; }
  .mR-40\@sm {
    margin-right: 40px !important; }
  .mB-40\@sm {
    margin-bottom: 40px !important; }
  .mL-40\@sm {
    margin-left: 40px !important; }
  .mY-40\@sm {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .mX-40\@sm {
    margin-left: 40px !important;
    margin-right: 40px !important; }
  .m-nv-40\@sm {
    margin: -40px !important; }
  .mT-nv-40\@sm {
    margin-top: -40px !important; }
  .mR-nv-40\@sm {
    margin-right: -40px !important; }
  .mB-nv-40\@sm {
    margin-bottom: -40px !important; }
  .mL-nv-40\@sm {
    margin-left: -40px !important; }
  .mY-nv-40\@sm {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .mX-nv-40\@sm {
    margin-left: -40px !important;
    margin-right: -40px !important; } }

.m-50 {
  margin: 50px !important; }

.mT-50 {
  margin-top: 50px !important; }

.mR-50 {
  margin-right: 50px !important; }

.mB-50 {
  margin-bottom: 50px !important; }

.mL-50 {
  margin-left: 50px !important; }

.mY-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.mX-50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.m-nv-50 {
  margin: -50px !important; }

.mT-nv-50 {
  margin-top: -50px !important; }

.mR-nv-50 {
  margin-right: -50px !important; }

.mB-nv-50 {
  margin-bottom: -50px !important; }

.mL-nv-50 {
  margin-left: -50px !important; }

.mY-nv-50 {
  margin-top: -50px !important;
  margin-bottom: -50px !important; }

.mX-nv-50 {
  margin-left: -50px !important;
  margin-right: -50px !important; }

@media screen and (min-width: 1440px) {
  .m-50\@xl\+ {
    margin: 50px !important; }
  .mT-50\@xl\+ {
    margin-top: 50px !important; }
  .mR-50\@xl\+ {
    margin-right: 50px !important; }
  .mB-50\@xl\+ {
    margin-bottom: 50px !important; }
  .mL-50\@xl\+ {
    margin-left: 50px !important; }
  .mY-50\@xl\+ {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@xl\+ {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@xl\+ {
    margin: -50px !important; }
  .mT-nv-50\@xl\+ {
    margin-top: -50px !important; }
  .mR-nv-50\@xl\+ {
    margin-right: -50px !important; }
  .mB-nv-50\@xl\+ {
    margin-bottom: -50px !important; }
  .mL-nv-50\@xl\+ {
    margin-left: -50px !important; }
  .mY-nv-50\@xl\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@xl\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 1200px) {
  .m-50\@lg\+ {
    margin: 50px !important; }
  .mT-50\@lg\+ {
    margin-top: 50px !important; }
  .mR-50\@lg\+ {
    margin-right: 50px !important; }
  .mB-50\@lg\+ {
    margin-bottom: 50px !important; }
  .mL-50\@lg\+ {
    margin-left: 50px !important; }
  .mY-50\@lg\+ {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@lg\+ {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@lg\+ {
    margin: -50px !important; }
  .mT-nv-50\@lg\+ {
    margin-top: -50px !important; }
  .mR-nv-50\@lg\+ {
    margin-right: -50px !important; }
  .mB-nv-50\@lg\+ {
    margin-bottom: -50px !important; }
  .mL-nv-50\@lg\+ {
    margin-left: -50px !important; }
  .mY-nv-50\@lg\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@lg\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 992px) {
  .m-50\@md\+ {
    margin: 50px !important; }
  .mT-50\@md\+ {
    margin-top: 50px !important; }
  .mR-50\@md\+ {
    margin-right: 50px !important; }
  .mB-50\@md\+ {
    margin-bottom: 50px !important; }
  .mL-50\@md\+ {
    margin-left: 50px !important; }
  .mY-50\@md\+ {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@md\+ {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@md\+ {
    margin: -50px !important; }
  .mT-nv-50\@md\+ {
    margin-top: -50px !important; }
  .mR-nv-50\@md\+ {
    margin-right: -50px !important; }
  .mB-nv-50\@md\+ {
    margin-bottom: -50px !important; }
  .mL-nv-50\@md\+ {
    margin-left: -50px !important; }
  .mY-nv-50\@md\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@md\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 768px) {
  .m-50\@sm\+ {
    margin: 50px !important; }
  .mT-50\@sm\+ {
    margin-top: 50px !important; }
  .mR-50\@sm\+ {
    margin-right: 50px !important; }
  .mB-50\@sm\+ {
    margin-bottom: 50px !important; }
  .mL-50\@sm\+ {
    margin-left: 50px !important; }
  .mY-50\@sm\+ {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@sm\+ {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@sm\+ {
    margin: -50px !important; }
  .mT-nv-50\@sm\+ {
    margin-top: -50px !important; }
  .mR-nv-50\@sm\+ {
    margin-right: -50px !important; }
  .mB-nv-50\@sm\+ {
    margin-bottom: -50px !important; }
  .mL-nv-50\@sm\+ {
    margin-left: -50px !important; }
  .mY-nv-50\@sm\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@sm\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 0) {
  .m-50\@xs\+ {
    margin: 50px !important; }
  .mT-50\@xs\+ {
    margin-top: 50px !important; }
  .mR-50\@xs\+ {
    margin-right: 50px !important; }
  .mB-50\@xs\+ {
    margin-bottom: 50px !important; }
  .mL-50\@xs\+ {
    margin-left: 50px !important; }
  .mY-50\@xs\+ {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@xs\+ {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@xs\+ {
    margin: -50px !important; }
  .mT-nv-50\@xs\+ {
    margin-top: -50px !important; }
  .mR-nv-50\@xs\+ {
    margin-right: -50px !important; }
  .mB-nv-50\@xs\+ {
    margin-bottom: -50px !important; }
  .mL-nv-50\@xs\+ {
    margin-left: -50px !important; }
  .mY-nv-50\@xs\+ {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@xs\+ {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (max-width: 1439px) {
  .m-50\@xl- {
    margin: 50px !important; }
  .mT-50\@xl- {
    margin-top: 50px !important; }
  .mR-50\@xl- {
    margin-right: 50px !important; }
  .mB-50\@xl- {
    margin-bottom: 50px !important; }
  .mL-50\@xl- {
    margin-left: 50px !important; }
  .mY-50\@xl- {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@xl- {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@xl- {
    margin: -50px !important; }
  .mT-nv-50\@xl- {
    margin-top: -50px !important; }
  .mR-nv-50\@xl- {
    margin-right: -50px !important; }
  .mB-nv-50\@xl- {
    margin-bottom: -50px !important; }
  .mL-nv-50\@xl- {
    margin-left: -50px !important; }
  .mY-nv-50\@xl- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@xl- {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (max-width: 1199px) {
  .m-50\@lg- {
    margin: 50px !important; }
  .mT-50\@lg- {
    margin-top: 50px !important; }
  .mR-50\@lg- {
    margin-right: 50px !important; }
  .mB-50\@lg- {
    margin-bottom: 50px !important; }
  .mL-50\@lg- {
    margin-left: 50px !important; }
  .mY-50\@lg- {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@lg- {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@lg- {
    margin: -50px !important; }
  .mT-nv-50\@lg- {
    margin-top: -50px !important; }
  .mR-nv-50\@lg- {
    margin-right: -50px !important; }
  .mB-nv-50\@lg- {
    margin-bottom: -50px !important; }
  .mL-nv-50\@lg- {
    margin-left: -50px !important; }
  .mY-nv-50\@lg- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@lg- {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (max-width: 991px) {
  .m-50\@md- {
    margin: 50px !important; }
  .mT-50\@md- {
    margin-top: 50px !important; }
  .mR-50\@md- {
    margin-right: 50px !important; }
  .mB-50\@md- {
    margin-bottom: 50px !important; }
  .mL-50\@md- {
    margin-left: 50px !important; }
  .mY-50\@md- {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@md- {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@md- {
    margin: -50px !important; }
  .mT-nv-50\@md- {
    margin-top: -50px !important; }
  .mR-nv-50\@md- {
    margin-right: -50px !important; }
  .mB-nv-50\@md- {
    margin-bottom: -50px !important; }
  .mL-nv-50\@md- {
    margin-left: -50px !important; }
  .mY-nv-50\@md- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@md- {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (max-width: 767px) {
  .m-50\@sm- {
    margin: 50px !important; }
  .mT-50\@sm- {
    margin-top: 50px !important; }
  .mR-50\@sm- {
    margin-right: 50px !important; }
  .mB-50\@sm- {
    margin-bottom: 50px !important; }
  .mL-50\@sm- {
    margin-left: 50px !important; }
  .mY-50\@sm- {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@sm- {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@sm- {
    margin: -50px !important; }
  .mT-nv-50\@sm- {
    margin-top: -50px !important; }
  .mR-nv-50\@sm- {
    margin-right: -50px !important; }
  .mB-nv-50\@sm- {
    margin-bottom: -50px !important; }
  .mL-nv-50\@sm- {
    margin-left: -50px !important; }
  .mY-nv-50\@sm- {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@sm- {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-50\@lg {
    margin: 50px !important; }
  .mT-50\@lg {
    margin-top: 50px !important; }
  .mR-50\@lg {
    margin-right: 50px !important; }
  .mB-50\@lg {
    margin-bottom: 50px !important; }
  .mL-50\@lg {
    margin-left: 50px !important; }
  .mY-50\@lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@lg {
    margin: -50px !important; }
  .mT-nv-50\@lg {
    margin-top: -50px !important; }
  .mR-nv-50\@lg {
    margin-right: -50px !important; }
  .mB-nv-50\@lg {
    margin-bottom: -50px !important; }
  .mL-nv-50\@lg {
    margin-left: -50px !important; }
  .mY-nv-50\@lg {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@lg {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-50\@md {
    margin: 50px !important; }
  .mT-50\@md {
    margin-top: 50px !important; }
  .mR-50\@md {
    margin-right: 50px !important; }
  .mB-50\@md {
    margin-bottom: 50px !important; }
  .mL-50\@md {
    margin-left: 50px !important; }
  .mY-50\@md {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@md {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@md {
    margin: -50px !important; }
  .mT-nv-50\@md {
    margin-top: -50px !important; }
  .mR-nv-50\@md {
    margin-right: -50px !important; }
  .mB-nv-50\@md {
    margin-bottom: -50px !important; }
  .mL-nv-50\@md {
    margin-left: -50px !important; }
  .mY-nv-50\@md {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@md {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-50\@sm {
    margin: 50px !important; }
  .mT-50\@sm {
    margin-top: 50px !important; }
  .mR-50\@sm {
    margin-right: 50px !important; }
  .mB-50\@sm {
    margin-bottom: 50px !important; }
  .mL-50\@sm {
    margin-left: 50px !important; }
  .mY-50\@sm {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .mX-50\@sm {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .m-nv-50\@sm {
    margin: -50px !important; }
  .mT-nv-50\@sm {
    margin-top: -50px !important; }
  .mR-nv-50\@sm {
    margin-right: -50px !important; }
  .mB-nv-50\@sm {
    margin-bottom: -50px !important; }
  .mL-nv-50\@sm {
    margin-left: -50px !important; }
  .mY-nv-50\@sm {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .mX-nv-50\@sm {
    margin-left: -50px !important;
    margin-right: -50px !important; } }

.m-60 {
  margin: 60px !important; }

.mT-60 {
  margin-top: 60px !important; }

.mR-60 {
  margin-right: 60px !important; }

.mB-60 {
  margin-bottom: 60px !important; }

.mL-60 {
  margin-left: 60px !important; }

.mY-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.mX-60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.m-nv-60 {
  margin: -60px !important; }

.mT-nv-60 {
  margin-top: -60px !important; }

.mR-nv-60 {
  margin-right: -60px !important; }

.mB-nv-60 {
  margin-bottom: -60px !important; }

.mL-nv-60 {
  margin-left: -60px !important; }

.mY-nv-60 {
  margin-top: -60px !important;
  margin-bottom: -60px !important; }

.mX-nv-60 {
  margin-left: -60px !important;
  margin-right: -60px !important; }

@media screen and (min-width: 1440px) {
  .m-60\@xl\+ {
    margin: 60px !important; }
  .mT-60\@xl\+ {
    margin-top: 60px !important; }
  .mR-60\@xl\+ {
    margin-right: 60px !important; }
  .mB-60\@xl\+ {
    margin-bottom: 60px !important; }
  .mL-60\@xl\+ {
    margin-left: 60px !important; }
  .mY-60\@xl\+ {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@xl\+ {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@xl\+ {
    margin: -60px !important; }
  .mT-nv-60\@xl\+ {
    margin-top: -60px !important; }
  .mR-nv-60\@xl\+ {
    margin-right: -60px !important; }
  .mB-nv-60\@xl\+ {
    margin-bottom: -60px !important; }
  .mL-nv-60\@xl\+ {
    margin-left: -60px !important; }
  .mY-nv-60\@xl\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@xl\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 1200px) {
  .m-60\@lg\+ {
    margin: 60px !important; }
  .mT-60\@lg\+ {
    margin-top: 60px !important; }
  .mR-60\@lg\+ {
    margin-right: 60px !important; }
  .mB-60\@lg\+ {
    margin-bottom: 60px !important; }
  .mL-60\@lg\+ {
    margin-left: 60px !important; }
  .mY-60\@lg\+ {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@lg\+ {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@lg\+ {
    margin: -60px !important; }
  .mT-nv-60\@lg\+ {
    margin-top: -60px !important; }
  .mR-nv-60\@lg\+ {
    margin-right: -60px !important; }
  .mB-nv-60\@lg\+ {
    margin-bottom: -60px !important; }
  .mL-nv-60\@lg\+ {
    margin-left: -60px !important; }
  .mY-nv-60\@lg\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@lg\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 992px) {
  .m-60\@md\+ {
    margin: 60px !important; }
  .mT-60\@md\+ {
    margin-top: 60px !important; }
  .mR-60\@md\+ {
    margin-right: 60px !important; }
  .mB-60\@md\+ {
    margin-bottom: 60px !important; }
  .mL-60\@md\+ {
    margin-left: 60px !important; }
  .mY-60\@md\+ {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@md\+ {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@md\+ {
    margin: -60px !important; }
  .mT-nv-60\@md\+ {
    margin-top: -60px !important; }
  .mR-nv-60\@md\+ {
    margin-right: -60px !important; }
  .mB-nv-60\@md\+ {
    margin-bottom: -60px !important; }
  .mL-nv-60\@md\+ {
    margin-left: -60px !important; }
  .mY-nv-60\@md\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@md\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 768px) {
  .m-60\@sm\+ {
    margin: 60px !important; }
  .mT-60\@sm\+ {
    margin-top: 60px !important; }
  .mR-60\@sm\+ {
    margin-right: 60px !important; }
  .mB-60\@sm\+ {
    margin-bottom: 60px !important; }
  .mL-60\@sm\+ {
    margin-left: 60px !important; }
  .mY-60\@sm\+ {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@sm\+ {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@sm\+ {
    margin: -60px !important; }
  .mT-nv-60\@sm\+ {
    margin-top: -60px !important; }
  .mR-nv-60\@sm\+ {
    margin-right: -60px !important; }
  .mB-nv-60\@sm\+ {
    margin-bottom: -60px !important; }
  .mL-nv-60\@sm\+ {
    margin-left: -60px !important; }
  .mY-nv-60\@sm\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@sm\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 0) {
  .m-60\@xs\+ {
    margin: 60px !important; }
  .mT-60\@xs\+ {
    margin-top: 60px !important; }
  .mR-60\@xs\+ {
    margin-right: 60px !important; }
  .mB-60\@xs\+ {
    margin-bottom: 60px !important; }
  .mL-60\@xs\+ {
    margin-left: 60px !important; }
  .mY-60\@xs\+ {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@xs\+ {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@xs\+ {
    margin: -60px !important; }
  .mT-nv-60\@xs\+ {
    margin-top: -60px !important; }
  .mR-nv-60\@xs\+ {
    margin-right: -60px !important; }
  .mB-nv-60\@xs\+ {
    margin-bottom: -60px !important; }
  .mL-nv-60\@xs\+ {
    margin-left: -60px !important; }
  .mY-nv-60\@xs\+ {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@xs\+ {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (max-width: 1439px) {
  .m-60\@xl- {
    margin: 60px !important; }
  .mT-60\@xl- {
    margin-top: 60px !important; }
  .mR-60\@xl- {
    margin-right: 60px !important; }
  .mB-60\@xl- {
    margin-bottom: 60px !important; }
  .mL-60\@xl- {
    margin-left: 60px !important; }
  .mY-60\@xl- {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@xl- {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@xl- {
    margin: -60px !important; }
  .mT-nv-60\@xl- {
    margin-top: -60px !important; }
  .mR-nv-60\@xl- {
    margin-right: -60px !important; }
  .mB-nv-60\@xl- {
    margin-bottom: -60px !important; }
  .mL-nv-60\@xl- {
    margin-left: -60px !important; }
  .mY-nv-60\@xl- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@xl- {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (max-width: 1199px) {
  .m-60\@lg- {
    margin: 60px !important; }
  .mT-60\@lg- {
    margin-top: 60px !important; }
  .mR-60\@lg- {
    margin-right: 60px !important; }
  .mB-60\@lg- {
    margin-bottom: 60px !important; }
  .mL-60\@lg- {
    margin-left: 60px !important; }
  .mY-60\@lg- {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@lg- {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@lg- {
    margin: -60px !important; }
  .mT-nv-60\@lg- {
    margin-top: -60px !important; }
  .mR-nv-60\@lg- {
    margin-right: -60px !important; }
  .mB-nv-60\@lg- {
    margin-bottom: -60px !important; }
  .mL-nv-60\@lg- {
    margin-left: -60px !important; }
  .mY-nv-60\@lg- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@lg- {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (max-width: 991px) {
  .m-60\@md- {
    margin: 60px !important; }
  .mT-60\@md- {
    margin-top: 60px !important; }
  .mR-60\@md- {
    margin-right: 60px !important; }
  .mB-60\@md- {
    margin-bottom: 60px !important; }
  .mL-60\@md- {
    margin-left: 60px !important; }
  .mY-60\@md- {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@md- {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@md- {
    margin: -60px !important; }
  .mT-nv-60\@md- {
    margin-top: -60px !important; }
  .mR-nv-60\@md- {
    margin-right: -60px !important; }
  .mB-nv-60\@md- {
    margin-bottom: -60px !important; }
  .mL-nv-60\@md- {
    margin-left: -60px !important; }
  .mY-nv-60\@md- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@md- {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (max-width: 767px) {
  .m-60\@sm- {
    margin: 60px !important; }
  .mT-60\@sm- {
    margin-top: 60px !important; }
  .mR-60\@sm- {
    margin-right: 60px !important; }
  .mB-60\@sm- {
    margin-bottom: 60px !important; }
  .mL-60\@sm- {
    margin-left: 60px !important; }
  .mY-60\@sm- {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@sm- {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@sm- {
    margin: -60px !important; }
  .mT-nv-60\@sm- {
    margin-top: -60px !important; }
  .mR-nv-60\@sm- {
    margin-right: -60px !important; }
  .mB-nv-60\@sm- {
    margin-bottom: -60px !important; }
  .mL-nv-60\@sm- {
    margin-left: -60px !important; }
  .mY-nv-60\@sm- {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@sm- {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-60\@lg {
    margin: 60px !important; }
  .mT-60\@lg {
    margin-top: 60px !important; }
  .mR-60\@lg {
    margin-right: 60px !important; }
  .mB-60\@lg {
    margin-bottom: 60px !important; }
  .mL-60\@lg {
    margin-left: 60px !important; }
  .mY-60\@lg {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@lg {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@lg {
    margin: -60px !important; }
  .mT-nv-60\@lg {
    margin-top: -60px !important; }
  .mR-nv-60\@lg {
    margin-right: -60px !important; }
  .mB-nv-60\@lg {
    margin-bottom: -60px !important; }
  .mL-nv-60\@lg {
    margin-left: -60px !important; }
  .mY-nv-60\@lg {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@lg {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-60\@md {
    margin: 60px !important; }
  .mT-60\@md {
    margin-top: 60px !important; }
  .mR-60\@md {
    margin-right: 60px !important; }
  .mB-60\@md {
    margin-bottom: 60px !important; }
  .mL-60\@md {
    margin-left: 60px !important; }
  .mY-60\@md {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@md {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@md {
    margin: -60px !important; }
  .mT-nv-60\@md {
    margin-top: -60px !important; }
  .mR-nv-60\@md {
    margin-right: -60px !important; }
  .mB-nv-60\@md {
    margin-bottom: -60px !important; }
  .mL-nv-60\@md {
    margin-left: -60px !important; }
  .mY-nv-60\@md {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@md {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-60\@sm {
    margin: 60px !important; }
  .mT-60\@sm {
    margin-top: 60px !important; }
  .mR-60\@sm {
    margin-right: 60px !important; }
  .mB-60\@sm {
    margin-bottom: 60px !important; }
  .mL-60\@sm {
    margin-left: 60px !important; }
  .mY-60\@sm {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .mX-60\@sm {
    margin-left: 60px !important;
    margin-right: 60px !important; }
  .m-nv-60\@sm {
    margin: -60px !important; }
  .mT-nv-60\@sm {
    margin-top: -60px !important; }
  .mR-nv-60\@sm {
    margin-right: -60px !important; }
  .mB-nv-60\@sm {
    margin-bottom: -60px !important; }
  .mL-nv-60\@sm {
    margin-left: -60px !important; }
  .mY-nv-60\@sm {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .mX-nv-60\@sm {
    margin-left: -60px !important;
    margin-right: -60px !important; } }

.m-70 {
  margin: 70px !important; }

.mT-70 {
  margin-top: 70px !important; }

.mR-70 {
  margin-right: 70px !important; }

.mB-70 {
  margin-bottom: 70px !important; }

.mL-70 {
  margin-left: 70px !important; }

.mY-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.mX-70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.m-nv-70 {
  margin: -70px !important; }

.mT-nv-70 {
  margin-top: -70px !important; }

.mR-nv-70 {
  margin-right: -70px !important; }

.mB-nv-70 {
  margin-bottom: -70px !important; }

.mL-nv-70 {
  margin-left: -70px !important; }

.mY-nv-70 {
  margin-top: -70px !important;
  margin-bottom: -70px !important; }

.mX-nv-70 {
  margin-left: -70px !important;
  margin-right: -70px !important; }

@media screen and (min-width: 1440px) {
  .m-70\@xl\+ {
    margin: 70px !important; }
  .mT-70\@xl\+ {
    margin-top: 70px !important; }
  .mR-70\@xl\+ {
    margin-right: 70px !important; }
  .mB-70\@xl\+ {
    margin-bottom: 70px !important; }
  .mL-70\@xl\+ {
    margin-left: 70px !important; }
  .mY-70\@xl\+ {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@xl\+ {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@xl\+ {
    margin: -70px !important; }
  .mT-nv-70\@xl\+ {
    margin-top: -70px !important; }
  .mR-nv-70\@xl\+ {
    margin-right: -70px !important; }
  .mB-nv-70\@xl\+ {
    margin-bottom: -70px !important; }
  .mL-nv-70\@xl\+ {
    margin-left: -70px !important; }
  .mY-nv-70\@xl\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@xl\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 1200px) {
  .m-70\@lg\+ {
    margin: 70px !important; }
  .mT-70\@lg\+ {
    margin-top: 70px !important; }
  .mR-70\@lg\+ {
    margin-right: 70px !important; }
  .mB-70\@lg\+ {
    margin-bottom: 70px !important; }
  .mL-70\@lg\+ {
    margin-left: 70px !important; }
  .mY-70\@lg\+ {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@lg\+ {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@lg\+ {
    margin: -70px !important; }
  .mT-nv-70\@lg\+ {
    margin-top: -70px !important; }
  .mR-nv-70\@lg\+ {
    margin-right: -70px !important; }
  .mB-nv-70\@lg\+ {
    margin-bottom: -70px !important; }
  .mL-nv-70\@lg\+ {
    margin-left: -70px !important; }
  .mY-nv-70\@lg\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@lg\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 992px) {
  .m-70\@md\+ {
    margin: 70px !important; }
  .mT-70\@md\+ {
    margin-top: 70px !important; }
  .mR-70\@md\+ {
    margin-right: 70px !important; }
  .mB-70\@md\+ {
    margin-bottom: 70px !important; }
  .mL-70\@md\+ {
    margin-left: 70px !important; }
  .mY-70\@md\+ {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@md\+ {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@md\+ {
    margin: -70px !important; }
  .mT-nv-70\@md\+ {
    margin-top: -70px !important; }
  .mR-nv-70\@md\+ {
    margin-right: -70px !important; }
  .mB-nv-70\@md\+ {
    margin-bottom: -70px !important; }
  .mL-nv-70\@md\+ {
    margin-left: -70px !important; }
  .mY-nv-70\@md\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@md\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 768px) {
  .m-70\@sm\+ {
    margin: 70px !important; }
  .mT-70\@sm\+ {
    margin-top: 70px !important; }
  .mR-70\@sm\+ {
    margin-right: 70px !important; }
  .mB-70\@sm\+ {
    margin-bottom: 70px !important; }
  .mL-70\@sm\+ {
    margin-left: 70px !important; }
  .mY-70\@sm\+ {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@sm\+ {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@sm\+ {
    margin: -70px !important; }
  .mT-nv-70\@sm\+ {
    margin-top: -70px !important; }
  .mR-nv-70\@sm\+ {
    margin-right: -70px !important; }
  .mB-nv-70\@sm\+ {
    margin-bottom: -70px !important; }
  .mL-nv-70\@sm\+ {
    margin-left: -70px !important; }
  .mY-nv-70\@sm\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@sm\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 0) {
  .m-70\@xs\+ {
    margin: 70px !important; }
  .mT-70\@xs\+ {
    margin-top: 70px !important; }
  .mR-70\@xs\+ {
    margin-right: 70px !important; }
  .mB-70\@xs\+ {
    margin-bottom: 70px !important; }
  .mL-70\@xs\+ {
    margin-left: 70px !important; }
  .mY-70\@xs\+ {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@xs\+ {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@xs\+ {
    margin: -70px !important; }
  .mT-nv-70\@xs\+ {
    margin-top: -70px !important; }
  .mR-nv-70\@xs\+ {
    margin-right: -70px !important; }
  .mB-nv-70\@xs\+ {
    margin-bottom: -70px !important; }
  .mL-nv-70\@xs\+ {
    margin-left: -70px !important; }
  .mY-nv-70\@xs\+ {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@xs\+ {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (max-width: 1439px) {
  .m-70\@xl- {
    margin: 70px !important; }
  .mT-70\@xl- {
    margin-top: 70px !important; }
  .mR-70\@xl- {
    margin-right: 70px !important; }
  .mB-70\@xl- {
    margin-bottom: 70px !important; }
  .mL-70\@xl- {
    margin-left: 70px !important; }
  .mY-70\@xl- {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@xl- {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@xl- {
    margin: -70px !important; }
  .mT-nv-70\@xl- {
    margin-top: -70px !important; }
  .mR-nv-70\@xl- {
    margin-right: -70px !important; }
  .mB-nv-70\@xl- {
    margin-bottom: -70px !important; }
  .mL-nv-70\@xl- {
    margin-left: -70px !important; }
  .mY-nv-70\@xl- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@xl- {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (max-width: 1199px) {
  .m-70\@lg- {
    margin: 70px !important; }
  .mT-70\@lg- {
    margin-top: 70px !important; }
  .mR-70\@lg- {
    margin-right: 70px !important; }
  .mB-70\@lg- {
    margin-bottom: 70px !important; }
  .mL-70\@lg- {
    margin-left: 70px !important; }
  .mY-70\@lg- {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@lg- {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@lg- {
    margin: -70px !important; }
  .mT-nv-70\@lg- {
    margin-top: -70px !important; }
  .mR-nv-70\@lg- {
    margin-right: -70px !important; }
  .mB-nv-70\@lg- {
    margin-bottom: -70px !important; }
  .mL-nv-70\@lg- {
    margin-left: -70px !important; }
  .mY-nv-70\@lg- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@lg- {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (max-width: 991px) {
  .m-70\@md- {
    margin: 70px !important; }
  .mT-70\@md- {
    margin-top: 70px !important; }
  .mR-70\@md- {
    margin-right: 70px !important; }
  .mB-70\@md- {
    margin-bottom: 70px !important; }
  .mL-70\@md- {
    margin-left: 70px !important; }
  .mY-70\@md- {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@md- {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@md- {
    margin: -70px !important; }
  .mT-nv-70\@md- {
    margin-top: -70px !important; }
  .mR-nv-70\@md- {
    margin-right: -70px !important; }
  .mB-nv-70\@md- {
    margin-bottom: -70px !important; }
  .mL-nv-70\@md- {
    margin-left: -70px !important; }
  .mY-nv-70\@md- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@md- {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (max-width: 767px) {
  .m-70\@sm- {
    margin: 70px !important; }
  .mT-70\@sm- {
    margin-top: 70px !important; }
  .mR-70\@sm- {
    margin-right: 70px !important; }
  .mB-70\@sm- {
    margin-bottom: 70px !important; }
  .mL-70\@sm- {
    margin-left: 70px !important; }
  .mY-70\@sm- {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@sm- {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@sm- {
    margin: -70px !important; }
  .mT-nv-70\@sm- {
    margin-top: -70px !important; }
  .mR-nv-70\@sm- {
    margin-right: -70px !important; }
  .mB-nv-70\@sm- {
    margin-bottom: -70px !important; }
  .mL-nv-70\@sm- {
    margin-left: -70px !important; }
  .mY-nv-70\@sm- {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@sm- {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-70\@lg {
    margin: 70px !important; }
  .mT-70\@lg {
    margin-top: 70px !important; }
  .mR-70\@lg {
    margin-right: 70px !important; }
  .mB-70\@lg {
    margin-bottom: 70px !important; }
  .mL-70\@lg {
    margin-left: 70px !important; }
  .mY-70\@lg {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@lg {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@lg {
    margin: -70px !important; }
  .mT-nv-70\@lg {
    margin-top: -70px !important; }
  .mR-nv-70\@lg {
    margin-right: -70px !important; }
  .mB-nv-70\@lg {
    margin-bottom: -70px !important; }
  .mL-nv-70\@lg {
    margin-left: -70px !important; }
  .mY-nv-70\@lg {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@lg {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-70\@md {
    margin: 70px !important; }
  .mT-70\@md {
    margin-top: 70px !important; }
  .mR-70\@md {
    margin-right: 70px !important; }
  .mB-70\@md {
    margin-bottom: 70px !important; }
  .mL-70\@md {
    margin-left: 70px !important; }
  .mY-70\@md {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@md {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@md {
    margin: -70px !important; }
  .mT-nv-70\@md {
    margin-top: -70px !important; }
  .mR-nv-70\@md {
    margin-right: -70px !important; }
  .mB-nv-70\@md {
    margin-bottom: -70px !important; }
  .mL-nv-70\@md {
    margin-left: -70px !important; }
  .mY-nv-70\@md {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@md {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-70\@sm {
    margin: 70px !important; }
  .mT-70\@sm {
    margin-top: 70px !important; }
  .mR-70\@sm {
    margin-right: 70px !important; }
  .mB-70\@sm {
    margin-bottom: 70px !important; }
  .mL-70\@sm {
    margin-left: 70px !important; }
  .mY-70\@sm {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .mX-70\@sm {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .m-nv-70\@sm {
    margin: -70px !important; }
  .mT-nv-70\@sm {
    margin-top: -70px !important; }
  .mR-nv-70\@sm {
    margin-right: -70px !important; }
  .mB-nv-70\@sm {
    margin-bottom: -70px !important; }
  .mL-nv-70\@sm {
    margin-left: -70px !important; }
  .mY-nv-70\@sm {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .mX-nv-70\@sm {
    margin-left: -70px !important;
    margin-right: -70px !important; } }

.m-80 {
  margin: 80px !important; }

.mT-80 {
  margin-top: 80px !important; }

.mR-80 {
  margin-right: 80px !important; }

.mB-80 {
  margin-bottom: 80px !important; }

.mL-80 {
  margin-left: 80px !important; }

.mY-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.mX-80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.m-nv-80 {
  margin: -80px !important; }

.mT-nv-80 {
  margin-top: -80px !important; }

.mR-nv-80 {
  margin-right: -80px !important; }

.mB-nv-80 {
  margin-bottom: -80px !important; }

.mL-nv-80 {
  margin-left: -80px !important; }

.mY-nv-80 {
  margin-top: -80px !important;
  margin-bottom: -80px !important; }

.mX-nv-80 {
  margin-left: -80px !important;
  margin-right: -80px !important; }

@media screen and (min-width: 1440px) {
  .m-80\@xl\+ {
    margin: 80px !important; }
  .mT-80\@xl\+ {
    margin-top: 80px !important; }
  .mR-80\@xl\+ {
    margin-right: 80px !important; }
  .mB-80\@xl\+ {
    margin-bottom: 80px !important; }
  .mL-80\@xl\+ {
    margin-left: 80px !important; }
  .mY-80\@xl\+ {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@xl\+ {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@xl\+ {
    margin: -80px !important; }
  .mT-nv-80\@xl\+ {
    margin-top: -80px !important; }
  .mR-nv-80\@xl\+ {
    margin-right: -80px !important; }
  .mB-nv-80\@xl\+ {
    margin-bottom: -80px !important; }
  .mL-nv-80\@xl\+ {
    margin-left: -80px !important; }
  .mY-nv-80\@xl\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@xl\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 1200px) {
  .m-80\@lg\+ {
    margin: 80px !important; }
  .mT-80\@lg\+ {
    margin-top: 80px !important; }
  .mR-80\@lg\+ {
    margin-right: 80px !important; }
  .mB-80\@lg\+ {
    margin-bottom: 80px !important; }
  .mL-80\@lg\+ {
    margin-left: 80px !important; }
  .mY-80\@lg\+ {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@lg\+ {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@lg\+ {
    margin: -80px !important; }
  .mT-nv-80\@lg\+ {
    margin-top: -80px !important; }
  .mR-nv-80\@lg\+ {
    margin-right: -80px !important; }
  .mB-nv-80\@lg\+ {
    margin-bottom: -80px !important; }
  .mL-nv-80\@lg\+ {
    margin-left: -80px !important; }
  .mY-nv-80\@lg\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@lg\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 992px) {
  .m-80\@md\+ {
    margin: 80px !important; }
  .mT-80\@md\+ {
    margin-top: 80px !important; }
  .mR-80\@md\+ {
    margin-right: 80px !important; }
  .mB-80\@md\+ {
    margin-bottom: 80px !important; }
  .mL-80\@md\+ {
    margin-left: 80px !important; }
  .mY-80\@md\+ {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@md\+ {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@md\+ {
    margin: -80px !important; }
  .mT-nv-80\@md\+ {
    margin-top: -80px !important; }
  .mR-nv-80\@md\+ {
    margin-right: -80px !important; }
  .mB-nv-80\@md\+ {
    margin-bottom: -80px !important; }
  .mL-nv-80\@md\+ {
    margin-left: -80px !important; }
  .mY-nv-80\@md\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@md\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 768px) {
  .m-80\@sm\+ {
    margin: 80px !important; }
  .mT-80\@sm\+ {
    margin-top: 80px !important; }
  .mR-80\@sm\+ {
    margin-right: 80px !important; }
  .mB-80\@sm\+ {
    margin-bottom: 80px !important; }
  .mL-80\@sm\+ {
    margin-left: 80px !important; }
  .mY-80\@sm\+ {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@sm\+ {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@sm\+ {
    margin: -80px !important; }
  .mT-nv-80\@sm\+ {
    margin-top: -80px !important; }
  .mR-nv-80\@sm\+ {
    margin-right: -80px !important; }
  .mB-nv-80\@sm\+ {
    margin-bottom: -80px !important; }
  .mL-nv-80\@sm\+ {
    margin-left: -80px !important; }
  .mY-nv-80\@sm\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@sm\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 0) {
  .m-80\@xs\+ {
    margin: 80px !important; }
  .mT-80\@xs\+ {
    margin-top: 80px !important; }
  .mR-80\@xs\+ {
    margin-right: 80px !important; }
  .mB-80\@xs\+ {
    margin-bottom: 80px !important; }
  .mL-80\@xs\+ {
    margin-left: 80px !important; }
  .mY-80\@xs\+ {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@xs\+ {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@xs\+ {
    margin: -80px !important; }
  .mT-nv-80\@xs\+ {
    margin-top: -80px !important; }
  .mR-nv-80\@xs\+ {
    margin-right: -80px !important; }
  .mB-nv-80\@xs\+ {
    margin-bottom: -80px !important; }
  .mL-nv-80\@xs\+ {
    margin-left: -80px !important; }
  .mY-nv-80\@xs\+ {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@xs\+ {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (max-width: 1439px) {
  .m-80\@xl- {
    margin: 80px !important; }
  .mT-80\@xl- {
    margin-top: 80px !important; }
  .mR-80\@xl- {
    margin-right: 80px !important; }
  .mB-80\@xl- {
    margin-bottom: 80px !important; }
  .mL-80\@xl- {
    margin-left: 80px !important; }
  .mY-80\@xl- {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@xl- {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@xl- {
    margin: -80px !important; }
  .mT-nv-80\@xl- {
    margin-top: -80px !important; }
  .mR-nv-80\@xl- {
    margin-right: -80px !important; }
  .mB-nv-80\@xl- {
    margin-bottom: -80px !important; }
  .mL-nv-80\@xl- {
    margin-left: -80px !important; }
  .mY-nv-80\@xl- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@xl- {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (max-width: 1199px) {
  .m-80\@lg- {
    margin: 80px !important; }
  .mT-80\@lg- {
    margin-top: 80px !important; }
  .mR-80\@lg- {
    margin-right: 80px !important; }
  .mB-80\@lg- {
    margin-bottom: 80px !important; }
  .mL-80\@lg- {
    margin-left: 80px !important; }
  .mY-80\@lg- {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@lg- {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@lg- {
    margin: -80px !important; }
  .mT-nv-80\@lg- {
    margin-top: -80px !important; }
  .mR-nv-80\@lg- {
    margin-right: -80px !important; }
  .mB-nv-80\@lg- {
    margin-bottom: -80px !important; }
  .mL-nv-80\@lg- {
    margin-left: -80px !important; }
  .mY-nv-80\@lg- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@lg- {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (max-width: 991px) {
  .m-80\@md- {
    margin: 80px !important; }
  .mT-80\@md- {
    margin-top: 80px !important; }
  .mR-80\@md- {
    margin-right: 80px !important; }
  .mB-80\@md- {
    margin-bottom: 80px !important; }
  .mL-80\@md- {
    margin-left: 80px !important; }
  .mY-80\@md- {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@md- {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@md- {
    margin: -80px !important; }
  .mT-nv-80\@md- {
    margin-top: -80px !important; }
  .mR-nv-80\@md- {
    margin-right: -80px !important; }
  .mB-nv-80\@md- {
    margin-bottom: -80px !important; }
  .mL-nv-80\@md- {
    margin-left: -80px !important; }
  .mY-nv-80\@md- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@md- {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (max-width: 767px) {
  .m-80\@sm- {
    margin: 80px !important; }
  .mT-80\@sm- {
    margin-top: 80px !important; }
  .mR-80\@sm- {
    margin-right: 80px !important; }
  .mB-80\@sm- {
    margin-bottom: 80px !important; }
  .mL-80\@sm- {
    margin-left: 80px !important; }
  .mY-80\@sm- {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@sm- {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@sm- {
    margin: -80px !important; }
  .mT-nv-80\@sm- {
    margin-top: -80px !important; }
  .mR-nv-80\@sm- {
    margin-right: -80px !important; }
  .mB-nv-80\@sm- {
    margin-bottom: -80px !important; }
  .mL-nv-80\@sm- {
    margin-left: -80px !important; }
  .mY-nv-80\@sm- {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@sm- {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-80\@lg {
    margin: 80px !important; }
  .mT-80\@lg {
    margin-top: 80px !important; }
  .mR-80\@lg {
    margin-right: 80px !important; }
  .mB-80\@lg {
    margin-bottom: 80px !important; }
  .mL-80\@lg {
    margin-left: 80px !important; }
  .mY-80\@lg {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@lg {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@lg {
    margin: -80px !important; }
  .mT-nv-80\@lg {
    margin-top: -80px !important; }
  .mR-nv-80\@lg {
    margin-right: -80px !important; }
  .mB-nv-80\@lg {
    margin-bottom: -80px !important; }
  .mL-nv-80\@lg {
    margin-left: -80px !important; }
  .mY-nv-80\@lg {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@lg {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-80\@md {
    margin: 80px !important; }
  .mT-80\@md {
    margin-top: 80px !important; }
  .mR-80\@md {
    margin-right: 80px !important; }
  .mB-80\@md {
    margin-bottom: 80px !important; }
  .mL-80\@md {
    margin-left: 80px !important; }
  .mY-80\@md {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@md {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@md {
    margin: -80px !important; }
  .mT-nv-80\@md {
    margin-top: -80px !important; }
  .mR-nv-80\@md {
    margin-right: -80px !important; }
  .mB-nv-80\@md {
    margin-bottom: -80px !important; }
  .mL-nv-80\@md {
    margin-left: -80px !important; }
  .mY-nv-80\@md {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@md {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-80\@sm {
    margin: 80px !important; }
  .mT-80\@sm {
    margin-top: 80px !important; }
  .mR-80\@sm {
    margin-right: 80px !important; }
  .mB-80\@sm {
    margin-bottom: 80px !important; }
  .mL-80\@sm {
    margin-left: 80px !important; }
  .mY-80\@sm {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .mX-80\@sm {
    margin-left: 80px !important;
    margin-right: 80px !important; }
  .m-nv-80\@sm {
    margin: -80px !important; }
  .mT-nv-80\@sm {
    margin-top: -80px !important; }
  .mR-nv-80\@sm {
    margin-right: -80px !important; }
  .mB-nv-80\@sm {
    margin-bottom: -80px !important; }
  .mL-nv-80\@sm {
    margin-left: -80px !important; }
  .mY-nv-80\@sm {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .mX-nv-80\@sm {
    margin-left: -80px !important;
    margin-right: -80px !important; } }

.m-90 {
  margin: 90px !important; }

.mT-90 {
  margin-top: 90px !important; }

.mR-90 {
  margin-right: 90px !important; }

.mB-90 {
  margin-bottom: 90px !important; }

.mL-90 {
  margin-left: 90px !important; }

.mY-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.mX-90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.m-nv-90 {
  margin: -90px !important; }

.mT-nv-90 {
  margin-top: -90px !important; }

.mR-nv-90 {
  margin-right: -90px !important; }

.mB-nv-90 {
  margin-bottom: -90px !important; }

.mL-nv-90 {
  margin-left: -90px !important; }

.mY-nv-90 {
  margin-top: -90px !important;
  margin-bottom: -90px !important; }

.mX-nv-90 {
  margin-left: -90px !important;
  margin-right: -90px !important; }

@media screen and (min-width: 1440px) {
  .m-90\@xl\+ {
    margin: 90px !important; }
  .mT-90\@xl\+ {
    margin-top: 90px !important; }
  .mR-90\@xl\+ {
    margin-right: 90px !important; }
  .mB-90\@xl\+ {
    margin-bottom: 90px !important; }
  .mL-90\@xl\+ {
    margin-left: 90px !important; }
  .mY-90\@xl\+ {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@xl\+ {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@xl\+ {
    margin: -90px !important; }
  .mT-nv-90\@xl\+ {
    margin-top: -90px !important; }
  .mR-nv-90\@xl\+ {
    margin-right: -90px !important; }
  .mB-nv-90\@xl\+ {
    margin-bottom: -90px !important; }
  .mL-nv-90\@xl\+ {
    margin-left: -90px !important; }
  .mY-nv-90\@xl\+ {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@xl\+ {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 1200px) {
  .m-90\@lg\+ {
    margin: 90px !important; }
  .mT-90\@lg\+ {
    margin-top: 90px !important; }
  .mR-90\@lg\+ {
    margin-right: 90px !important; }
  .mB-90\@lg\+ {
    margin-bottom: 90px !important; }
  .mL-90\@lg\+ {
    margin-left: 90px !important; }
  .mY-90\@lg\+ {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@lg\+ {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@lg\+ {
    margin: -90px !important; }
  .mT-nv-90\@lg\+ {
    margin-top: -90px !important; }
  .mR-nv-90\@lg\+ {
    margin-right: -90px !important; }
  .mB-nv-90\@lg\+ {
    margin-bottom: -90px !important; }
  .mL-nv-90\@lg\+ {
    margin-left: -90px !important; }
  .mY-nv-90\@lg\+ {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@lg\+ {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 992px) {
  .m-90\@md\+ {
    margin: 90px !important; }
  .mT-90\@md\+ {
    margin-top: 90px !important; }
  .mR-90\@md\+ {
    margin-right: 90px !important; }
  .mB-90\@md\+ {
    margin-bottom: 90px !important; }
  .mL-90\@md\+ {
    margin-left: 90px !important; }
  .mY-90\@md\+ {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@md\+ {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@md\+ {
    margin: -90px !important; }
  .mT-nv-90\@md\+ {
    margin-top: -90px !important; }
  .mR-nv-90\@md\+ {
    margin-right: -90px !important; }
  .mB-nv-90\@md\+ {
    margin-bottom: -90px !important; }
  .mL-nv-90\@md\+ {
    margin-left: -90px !important; }
  .mY-nv-90\@md\+ {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@md\+ {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 768px) {
  .m-90\@sm\+ {
    margin: 90px !important; }
  .mT-90\@sm\+ {
    margin-top: 90px !important; }
  .mR-90\@sm\+ {
    margin-right: 90px !important; }
  .mB-90\@sm\+ {
    margin-bottom: 90px !important; }
  .mL-90\@sm\+ {
    margin-left: 90px !important; }
  .mY-90\@sm\+ {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@sm\+ {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@sm\+ {
    margin: -90px !important; }
  .mT-nv-90\@sm\+ {
    margin-top: -90px !important; }
  .mR-nv-90\@sm\+ {
    margin-right: -90px !important; }
  .mB-nv-90\@sm\+ {
    margin-bottom: -90px !important; }
  .mL-nv-90\@sm\+ {
    margin-left: -90px !important; }
  .mY-nv-90\@sm\+ {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@sm\+ {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 0) {
  .m-90\@xs\+ {
    margin: 90px !important; }
  .mT-90\@xs\+ {
    margin-top: 90px !important; }
  .mR-90\@xs\+ {
    margin-right: 90px !important; }
  .mB-90\@xs\+ {
    margin-bottom: 90px !important; }
  .mL-90\@xs\+ {
    margin-left: 90px !important; }
  .mY-90\@xs\+ {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@xs\+ {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@xs\+ {
    margin: -90px !important; }
  .mT-nv-90\@xs\+ {
    margin-top: -90px !important; }
  .mR-nv-90\@xs\+ {
    margin-right: -90px !important; }
  .mB-nv-90\@xs\+ {
    margin-bottom: -90px !important; }
  .mL-nv-90\@xs\+ {
    margin-left: -90px !important; }
  .mY-nv-90\@xs\+ {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@xs\+ {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (max-width: 1439px) {
  .m-90\@xl- {
    margin: 90px !important; }
  .mT-90\@xl- {
    margin-top: 90px !important; }
  .mR-90\@xl- {
    margin-right: 90px !important; }
  .mB-90\@xl- {
    margin-bottom: 90px !important; }
  .mL-90\@xl- {
    margin-left: 90px !important; }
  .mY-90\@xl- {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@xl- {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@xl- {
    margin: -90px !important; }
  .mT-nv-90\@xl- {
    margin-top: -90px !important; }
  .mR-nv-90\@xl- {
    margin-right: -90px !important; }
  .mB-nv-90\@xl- {
    margin-bottom: -90px !important; }
  .mL-nv-90\@xl- {
    margin-left: -90px !important; }
  .mY-nv-90\@xl- {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@xl- {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (max-width: 1199px) {
  .m-90\@lg- {
    margin: 90px !important; }
  .mT-90\@lg- {
    margin-top: 90px !important; }
  .mR-90\@lg- {
    margin-right: 90px !important; }
  .mB-90\@lg- {
    margin-bottom: 90px !important; }
  .mL-90\@lg- {
    margin-left: 90px !important; }
  .mY-90\@lg- {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@lg- {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@lg- {
    margin: -90px !important; }
  .mT-nv-90\@lg- {
    margin-top: -90px !important; }
  .mR-nv-90\@lg- {
    margin-right: -90px !important; }
  .mB-nv-90\@lg- {
    margin-bottom: -90px !important; }
  .mL-nv-90\@lg- {
    margin-left: -90px !important; }
  .mY-nv-90\@lg- {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@lg- {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (max-width: 991px) {
  .m-90\@md- {
    margin: 90px !important; }
  .mT-90\@md- {
    margin-top: 90px !important; }
  .mR-90\@md- {
    margin-right: 90px !important; }
  .mB-90\@md- {
    margin-bottom: 90px !important; }
  .mL-90\@md- {
    margin-left: 90px !important; }
  .mY-90\@md- {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@md- {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@md- {
    margin: -90px !important; }
  .mT-nv-90\@md- {
    margin-top: -90px !important; }
  .mR-nv-90\@md- {
    margin-right: -90px !important; }
  .mB-nv-90\@md- {
    margin-bottom: -90px !important; }
  .mL-nv-90\@md- {
    margin-left: -90px !important; }
  .mY-nv-90\@md- {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@md- {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (max-width: 767px) {
  .m-90\@sm- {
    margin: 90px !important; }
  .mT-90\@sm- {
    margin-top: 90px !important; }
  .mR-90\@sm- {
    margin-right: 90px !important; }
  .mB-90\@sm- {
    margin-bottom: 90px !important; }
  .mL-90\@sm- {
    margin-left: 90px !important; }
  .mY-90\@sm- {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@sm- {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@sm- {
    margin: -90px !important; }
  .mT-nv-90\@sm- {
    margin-top: -90px !important; }
  .mR-nv-90\@sm- {
    margin-right: -90px !important; }
  .mB-nv-90\@sm- {
    margin-bottom: -90px !important; }
  .mL-nv-90\@sm- {
    margin-left: -90px !important; }
  .mY-nv-90\@sm- {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@sm- {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-90\@lg {
    margin: 90px !important; }
  .mT-90\@lg {
    margin-top: 90px !important; }
  .mR-90\@lg {
    margin-right: 90px !important; }
  .mB-90\@lg {
    margin-bottom: 90px !important; }
  .mL-90\@lg {
    margin-left: 90px !important; }
  .mY-90\@lg {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@lg {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@lg {
    margin: -90px !important; }
  .mT-nv-90\@lg {
    margin-top: -90px !important; }
  .mR-nv-90\@lg {
    margin-right: -90px !important; }
  .mB-nv-90\@lg {
    margin-bottom: -90px !important; }
  .mL-nv-90\@lg {
    margin-left: -90px !important; }
  .mY-nv-90\@lg {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@lg {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-90\@md {
    margin: 90px !important; }
  .mT-90\@md {
    margin-top: 90px !important; }
  .mR-90\@md {
    margin-right: 90px !important; }
  .mB-90\@md {
    margin-bottom: 90px !important; }
  .mL-90\@md {
    margin-left: 90px !important; }
  .mY-90\@md {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@md {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@md {
    margin: -90px !important; }
  .mT-nv-90\@md {
    margin-top: -90px !important; }
  .mR-nv-90\@md {
    margin-right: -90px !important; }
  .mB-nv-90\@md {
    margin-bottom: -90px !important; }
  .mL-nv-90\@md {
    margin-left: -90px !important; }
  .mY-nv-90\@md {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@md {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-90\@sm {
    margin: 90px !important; }
  .mT-90\@sm {
    margin-top: 90px !important; }
  .mR-90\@sm {
    margin-right: 90px !important; }
  .mB-90\@sm {
    margin-bottom: 90px !important; }
  .mL-90\@sm {
    margin-left: 90px !important; }
  .mY-90\@sm {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .mX-90\@sm {
    margin-left: 90px !important;
    margin-right: 90px !important; }
  .m-nv-90\@sm {
    margin: -90px !important; }
  .mT-nv-90\@sm {
    margin-top: -90px !important; }
  .mR-nv-90\@sm {
    margin-right: -90px !important; }
  .mB-nv-90\@sm {
    margin-bottom: -90px !important; }
  .mL-nv-90\@sm {
    margin-left: -90px !important; }
  .mY-nv-90\@sm {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mX-nv-90\@sm {
    margin-left: -90px !important;
    margin-right: -90px !important; } }

.m-100 {
  margin: 100px !important; }

.mT-100 {
  margin-top: 100px !important; }

.mR-100 {
  margin-right: 100px !important; }

.mB-100 {
  margin-bottom: 100px !important; }

.mL-100 {
  margin-left: 100px !important; }

.mY-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.mX-100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.m-nv-100 {
  margin: -100px !important; }

.mT-nv-100 {
  margin-top: -100px !important; }

.mR-nv-100 {
  margin-right: -100px !important; }

.mB-nv-100 {
  margin-bottom: -100px !important; }

.mL-nv-100 {
  margin-left: -100px !important; }

.mY-nv-100 {
  margin-top: -100px !important;
  margin-bottom: -100px !important; }

.mX-nv-100 {
  margin-left: -100px !important;
  margin-right: -100px !important; }

@media screen and (min-width: 1440px) {
  .m-100\@xl\+ {
    margin: 100px !important; }
  .mT-100\@xl\+ {
    margin-top: 100px !important; }
  .mR-100\@xl\+ {
    margin-right: 100px !important; }
  .mB-100\@xl\+ {
    margin-bottom: 100px !important; }
  .mL-100\@xl\+ {
    margin-left: 100px !important; }
  .mY-100\@xl\+ {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@xl\+ {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@xl\+ {
    margin: -100px !important; }
  .mT-nv-100\@xl\+ {
    margin-top: -100px !important; }
  .mR-nv-100\@xl\+ {
    margin-right: -100px !important; }
  .mB-nv-100\@xl\+ {
    margin-bottom: -100px !important; }
  .mL-nv-100\@xl\+ {
    margin-left: -100px !important; }
  .mY-nv-100\@xl\+ {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@xl\+ {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 1200px) {
  .m-100\@lg\+ {
    margin: 100px !important; }
  .mT-100\@lg\+ {
    margin-top: 100px !important; }
  .mR-100\@lg\+ {
    margin-right: 100px !important; }
  .mB-100\@lg\+ {
    margin-bottom: 100px !important; }
  .mL-100\@lg\+ {
    margin-left: 100px !important; }
  .mY-100\@lg\+ {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@lg\+ {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@lg\+ {
    margin: -100px !important; }
  .mT-nv-100\@lg\+ {
    margin-top: -100px !important; }
  .mR-nv-100\@lg\+ {
    margin-right: -100px !important; }
  .mB-nv-100\@lg\+ {
    margin-bottom: -100px !important; }
  .mL-nv-100\@lg\+ {
    margin-left: -100px !important; }
  .mY-nv-100\@lg\+ {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@lg\+ {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 992px) {
  .m-100\@md\+ {
    margin: 100px !important; }
  .mT-100\@md\+ {
    margin-top: 100px !important; }
  .mR-100\@md\+ {
    margin-right: 100px !important; }
  .mB-100\@md\+ {
    margin-bottom: 100px !important; }
  .mL-100\@md\+ {
    margin-left: 100px !important; }
  .mY-100\@md\+ {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@md\+ {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@md\+ {
    margin: -100px !important; }
  .mT-nv-100\@md\+ {
    margin-top: -100px !important; }
  .mR-nv-100\@md\+ {
    margin-right: -100px !important; }
  .mB-nv-100\@md\+ {
    margin-bottom: -100px !important; }
  .mL-nv-100\@md\+ {
    margin-left: -100px !important; }
  .mY-nv-100\@md\+ {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@md\+ {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 768px) {
  .m-100\@sm\+ {
    margin: 100px !important; }
  .mT-100\@sm\+ {
    margin-top: 100px !important; }
  .mR-100\@sm\+ {
    margin-right: 100px !important; }
  .mB-100\@sm\+ {
    margin-bottom: 100px !important; }
  .mL-100\@sm\+ {
    margin-left: 100px !important; }
  .mY-100\@sm\+ {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@sm\+ {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@sm\+ {
    margin: -100px !important; }
  .mT-nv-100\@sm\+ {
    margin-top: -100px !important; }
  .mR-nv-100\@sm\+ {
    margin-right: -100px !important; }
  .mB-nv-100\@sm\+ {
    margin-bottom: -100px !important; }
  .mL-nv-100\@sm\+ {
    margin-left: -100px !important; }
  .mY-nv-100\@sm\+ {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@sm\+ {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 0) {
  .m-100\@xs\+ {
    margin: 100px !important; }
  .mT-100\@xs\+ {
    margin-top: 100px !important; }
  .mR-100\@xs\+ {
    margin-right: 100px !important; }
  .mB-100\@xs\+ {
    margin-bottom: 100px !important; }
  .mL-100\@xs\+ {
    margin-left: 100px !important; }
  .mY-100\@xs\+ {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@xs\+ {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@xs\+ {
    margin: -100px !important; }
  .mT-nv-100\@xs\+ {
    margin-top: -100px !important; }
  .mR-nv-100\@xs\+ {
    margin-right: -100px !important; }
  .mB-nv-100\@xs\+ {
    margin-bottom: -100px !important; }
  .mL-nv-100\@xs\+ {
    margin-left: -100px !important; }
  .mY-nv-100\@xs\+ {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@xs\+ {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (max-width: 1439px) {
  .m-100\@xl- {
    margin: 100px !important; }
  .mT-100\@xl- {
    margin-top: 100px !important; }
  .mR-100\@xl- {
    margin-right: 100px !important; }
  .mB-100\@xl- {
    margin-bottom: 100px !important; }
  .mL-100\@xl- {
    margin-left: 100px !important; }
  .mY-100\@xl- {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@xl- {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@xl- {
    margin: -100px !important; }
  .mT-nv-100\@xl- {
    margin-top: -100px !important; }
  .mR-nv-100\@xl- {
    margin-right: -100px !important; }
  .mB-nv-100\@xl- {
    margin-bottom: -100px !important; }
  .mL-nv-100\@xl- {
    margin-left: -100px !important; }
  .mY-nv-100\@xl- {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@xl- {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (max-width: 1199px) {
  .m-100\@lg- {
    margin: 100px !important; }
  .mT-100\@lg- {
    margin-top: 100px !important; }
  .mR-100\@lg- {
    margin-right: 100px !important; }
  .mB-100\@lg- {
    margin-bottom: 100px !important; }
  .mL-100\@lg- {
    margin-left: 100px !important; }
  .mY-100\@lg- {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@lg- {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@lg- {
    margin: -100px !important; }
  .mT-nv-100\@lg- {
    margin-top: -100px !important; }
  .mR-nv-100\@lg- {
    margin-right: -100px !important; }
  .mB-nv-100\@lg- {
    margin-bottom: -100px !important; }
  .mL-nv-100\@lg- {
    margin-left: -100px !important; }
  .mY-nv-100\@lg- {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@lg- {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (max-width: 991px) {
  .m-100\@md- {
    margin: 100px !important; }
  .mT-100\@md- {
    margin-top: 100px !important; }
  .mR-100\@md- {
    margin-right: 100px !important; }
  .mB-100\@md- {
    margin-bottom: 100px !important; }
  .mL-100\@md- {
    margin-left: 100px !important; }
  .mY-100\@md- {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@md- {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@md- {
    margin: -100px !important; }
  .mT-nv-100\@md- {
    margin-top: -100px !important; }
  .mR-nv-100\@md- {
    margin-right: -100px !important; }
  .mB-nv-100\@md- {
    margin-bottom: -100px !important; }
  .mL-nv-100\@md- {
    margin-left: -100px !important; }
  .mY-nv-100\@md- {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@md- {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (max-width: 767px) {
  .m-100\@sm- {
    margin: 100px !important; }
  .mT-100\@sm- {
    margin-top: 100px !important; }
  .mR-100\@sm- {
    margin-right: 100px !important; }
  .mB-100\@sm- {
    margin-bottom: 100px !important; }
  .mL-100\@sm- {
    margin-left: 100px !important; }
  .mY-100\@sm- {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@sm- {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@sm- {
    margin: -100px !important; }
  .mT-nv-100\@sm- {
    margin-top: -100px !important; }
  .mR-nv-100\@sm- {
    margin-right: -100px !important; }
  .mB-nv-100\@sm- {
    margin-bottom: -100px !important; }
  .mL-nv-100\@sm- {
    margin-left: -100px !important; }
  .mY-nv-100\@sm- {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@sm- {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-100\@lg {
    margin: 100px !important; }
  .mT-100\@lg {
    margin-top: 100px !important; }
  .mR-100\@lg {
    margin-right: 100px !important; }
  .mB-100\@lg {
    margin-bottom: 100px !important; }
  .mL-100\@lg {
    margin-left: 100px !important; }
  .mY-100\@lg {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@lg {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@lg {
    margin: -100px !important; }
  .mT-nv-100\@lg {
    margin-top: -100px !important; }
  .mR-nv-100\@lg {
    margin-right: -100px !important; }
  .mB-nv-100\@lg {
    margin-bottom: -100px !important; }
  .mL-nv-100\@lg {
    margin-left: -100px !important; }
  .mY-nv-100\@lg {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@lg {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-100\@md {
    margin: 100px !important; }
  .mT-100\@md {
    margin-top: 100px !important; }
  .mR-100\@md {
    margin-right: 100px !important; }
  .mB-100\@md {
    margin-bottom: 100px !important; }
  .mL-100\@md {
    margin-left: 100px !important; }
  .mY-100\@md {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@md {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@md {
    margin: -100px !important; }
  .mT-nv-100\@md {
    margin-top: -100px !important; }
  .mR-nv-100\@md {
    margin-right: -100px !important; }
  .mB-nv-100\@md {
    margin-bottom: -100px !important; }
  .mL-nv-100\@md {
    margin-left: -100px !important; }
  .mY-nv-100\@md {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@md {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-100\@sm {
    margin: 100px !important; }
  .mT-100\@sm {
    margin-top: 100px !important; }
  .mR-100\@sm {
    margin-right: 100px !important; }
  .mB-100\@sm {
    margin-bottom: 100px !important; }
  .mL-100\@sm {
    margin-left: 100px !important; }
  .mY-100\@sm {
    margin-top: 100px !important;
    margin-bottom: 100px !important; }
  .mX-100\@sm {
    margin-left: 100px !important;
    margin-right: 100px !important; }
  .m-nv-100\@sm {
    margin: -100px !important; }
  .mT-nv-100\@sm {
    margin-top: -100px !important; }
  .mR-nv-100\@sm {
    margin-right: -100px !important; }
  .mB-nv-100\@sm {
    margin-bottom: -100px !important; }
  .mL-nv-100\@sm {
    margin-left: -100px !important; }
  .mY-nv-100\@sm {
    margin-top: -100px !important;
    margin-bottom: -100px !important; }
  .mX-nv-100\@sm {
    margin-left: -100px !important;
    margin-right: -100px !important; } }

.m-110 {
  margin: 110px !important; }

.mT-110 {
  margin-top: 110px !important; }

.mR-110 {
  margin-right: 110px !important; }

.mB-110 {
  margin-bottom: 110px !important; }

.mL-110 {
  margin-left: 110px !important; }

.mY-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important; }

.mX-110 {
  margin-left: 110px !important;
  margin-right: 110px !important; }

.m-nv-110 {
  margin: -110px !important; }

.mT-nv-110 {
  margin-top: -110px !important; }

.mR-nv-110 {
  margin-right: -110px !important; }

.mB-nv-110 {
  margin-bottom: -110px !important; }

.mL-nv-110 {
  margin-left: -110px !important; }

.mY-nv-110 {
  margin-top: -110px !important;
  margin-bottom: -110px !important; }

.mX-nv-110 {
  margin-left: -110px !important;
  margin-right: -110px !important; }

@media screen and (min-width: 1440px) {
  .m-110\@xl\+ {
    margin: 110px !important; }
  .mT-110\@xl\+ {
    margin-top: 110px !important; }
  .mR-110\@xl\+ {
    margin-right: 110px !important; }
  .mB-110\@xl\+ {
    margin-bottom: 110px !important; }
  .mL-110\@xl\+ {
    margin-left: 110px !important; }
  .mY-110\@xl\+ {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@xl\+ {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@xl\+ {
    margin: -110px !important; }
  .mT-nv-110\@xl\+ {
    margin-top: -110px !important; }
  .mR-nv-110\@xl\+ {
    margin-right: -110px !important; }
  .mB-nv-110\@xl\+ {
    margin-bottom: -110px !important; }
  .mL-nv-110\@xl\+ {
    margin-left: -110px !important; }
  .mY-nv-110\@xl\+ {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@xl\+ {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 1200px) {
  .m-110\@lg\+ {
    margin: 110px !important; }
  .mT-110\@lg\+ {
    margin-top: 110px !important; }
  .mR-110\@lg\+ {
    margin-right: 110px !important; }
  .mB-110\@lg\+ {
    margin-bottom: 110px !important; }
  .mL-110\@lg\+ {
    margin-left: 110px !important; }
  .mY-110\@lg\+ {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@lg\+ {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@lg\+ {
    margin: -110px !important; }
  .mT-nv-110\@lg\+ {
    margin-top: -110px !important; }
  .mR-nv-110\@lg\+ {
    margin-right: -110px !important; }
  .mB-nv-110\@lg\+ {
    margin-bottom: -110px !important; }
  .mL-nv-110\@lg\+ {
    margin-left: -110px !important; }
  .mY-nv-110\@lg\+ {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@lg\+ {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 992px) {
  .m-110\@md\+ {
    margin: 110px !important; }
  .mT-110\@md\+ {
    margin-top: 110px !important; }
  .mR-110\@md\+ {
    margin-right: 110px !important; }
  .mB-110\@md\+ {
    margin-bottom: 110px !important; }
  .mL-110\@md\+ {
    margin-left: 110px !important; }
  .mY-110\@md\+ {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@md\+ {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@md\+ {
    margin: -110px !important; }
  .mT-nv-110\@md\+ {
    margin-top: -110px !important; }
  .mR-nv-110\@md\+ {
    margin-right: -110px !important; }
  .mB-nv-110\@md\+ {
    margin-bottom: -110px !important; }
  .mL-nv-110\@md\+ {
    margin-left: -110px !important; }
  .mY-nv-110\@md\+ {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@md\+ {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 768px) {
  .m-110\@sm\+ {
    margin: 110px !important; }
  .mT-110\@sm\+ {
    margin-top: 110px !important; }
  .mR-110\@sm\+ {
    margin-right: 110px !important; }
  .mB-110\@sm\+ {
    margin-bottom: 110px !important; }
  .mL-110\@sm\+ {
    margin-left: 110px !important; }
  .mY-110\@sm\+ {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@sm\+ {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@sm\+ {
    margin: -110px !important; }
  .mT-nv-110\@sm\+ {
    margin-top: -110px !important; }
  .mR-nv-110\@sm\+ {
    margin-right: -110px !important; }
  .mB-nv-110\@sm\+ {
    margin-bottom: -110px !important; }
  .mL-nv-110\@sm\+ {
    margin-left: -110px !important; }
  .mY-nv-110\@sm\+ {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@sm\+ {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 0) {
  .m-110\@xs\+ {
    margin: 110px !important; }
  .mT-110\@xs\+ {
    margin-top: 110px !important; }
  .mR-110\@xs\+ {
    margin-right: 110px !important; }
  .mB-110\@xs\+ {
    margin-bottom: 110px !important; }
  .mL-110\@xs\+ {
    margin-left: 110px !important; }
  .mY-110\@xs\+ {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@xs\+ {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@xs\+ {
    margin: -110px !important; }
  .mT-nv-110\@xs\+ {
    margin-top: -110px !important; }
  .mR-nv-110\@xs\+ {
    margin-right: -110px !important; }
  .mB-nv-110\@xs\+ {
    margin-bottom: -110px !important; }
  .mL-nv-110\@xs\+ {
    margin-left: -110px !important; }
  .mY-nv-110\@xs\+ {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@xs\+ {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (max-width: 1439px) {
  .m-110\@xl- {
    margin: 110px !important; }
  .mT-110\@xl- {
    margin-top: 110px !important; }
  .mR-110\@xl- {
    margin-right: 110px !important; }
  .mB-110\@xl- {
    margin-bottom: 110px !important; }
  .mL-110\@xl- {
    margin-left: 110px !important; }
  .mY-110\@xl- {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@xl- {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@xl- {
    margin: -110px !important; }
  .mT-nv-110\@xl- {
    margin-top: -110px !important; }
  .mR-nv-110\@xl- {
    margin-right: -110px !important; }
  .mB-nv-110\@xl- {
    margin-bottom: -110px !important; }
  .mL-nv-110\@xl- {
    margin-left: -110px !important; }
  .mY-nv-110\@xl- {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@xl- {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (max-width: 1199px) {
  .m-110\@lg- {
    margin: 110px !important; }
  .mT-110\@lg- {
    margin-top: 110px !important; }
  .mR-110\@lg- {
    margin-right: 110px !important; }
  .mB-110\@lg- {
    margin-bottom: 110px !important; }
  .mL-110\@lg- {
    margin-left: 110px !important; }
  .mY-110\@lg- {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@lg- {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@lg- {
    margin: -110px !important; }
  .mT-nv-110\@lg- {
    margin-top: -110px !important; }
  .mR-nv-110\@lg- {
    margin-right: -110px !important; }
  .mB-nv-110\@lg- {
    margin-bottom: -110px !important; }
  .mL-nv-110\@lg- {
    margin-left: -110px !important; }
  .mY-nv-110\@lg- {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@lg- {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (max-width: 991px) {
  .m-110\@md- {
    margin: 110px !important; }
  .mT-110\@md- {
    margin-top: 110px !important; }
  .mR-110\@md- {
    margin-right: 110px !important; }
  .mB-110\@md- {
    margin-bottom: 110px !important; }
  .mL-110\@md- {
    margin-left: 110px !important; }
  .mY-110\@md- {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@md- {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@md- {
    margin: -110px !important; }
  .mT-nv-110\@md- {
    margin-top: -110px !important; }
  .mR-nv-110\@md- {
    margin-right: -110px !important; }
  .mB-nv-110\@md- {
    margin-bottom: -110px !important; }
  .mL-nv-110\@md- {
    margin-left: -110px !important; }
  .mY-nv-110\@md- {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@md- {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (max-width: 767px) {
  .m-110\@sm- {
    margin: 110px !important; }
  .mT-110\@sm- {
    margin-top: 110px !important; }
  .mR-110\@sm- {
    margin-right: 110px !important; }
  .mB-110\@sm- {
    margin-bottom: 110px !important; }
  .mL-110\@sm- {
    margin-left: 110px !important; }
  .mY-110\@sm- {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@sm- {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@sm- {
    margin: -110px !important; }
  .mT-nv-110\@sm- {
    margin-top: -110px !important; }
  .mR-nv-110\@sm- {
    margin-right: -110px !important; }
  .mB-nv-110\@sm- {
    margin-bottom: -110px !important; }
  .mL-nv-110\@sm- {
    margin-left: -110px !important; }
  .mY-nv-110\@sm- {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@sm- {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-110\@lg {
    margin: 110px !important; }
  .mT-110\@lg {
    margin-top: 110px !important; }
  .mR-110\@lg {
    margin-right: 110px !important; }
  .mB-110\@lg {
    margin-bottom: 110px !important; }
  .mL-110\@lg {
    margin-left: 110px !important; }
  .mY-110\@lg {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@lg {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@lg {
    margin: -110px !important; }
  .mT-nv-110\@lg {
    margin-top: -110px !important; }
  .mR-nv-110\@lg {
    margin-right: -110px !important; }
  .mB-nv-110\@lg {
    margin-bottom: -110px !important; }
  .mL-nv-110\@lg {
    margin-left: -110px !important; }
  .mY-nv-110\@lg {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@lg {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-110\@md {
    margin: 110px !important; }
  .mT-110\@md {
    margin-top: 110px !important; }
  .mR-110\@md {
    margin-right: 110px !important; }
  .mB-110\@md {
    margin-bottom: 110px !important; }
  .mL-110\@md {
    margin-left: 110px !important; }
  .mY-110\@md {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@md {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@md {
    margin: -110px !important; }
  .mT-nv-110\@md {
    margin-top: -110px !important; }
  .mR-nv-110\@md {
    margin-right: -110px !important; }
  .mB-nv-110\@md {
    margin-bottom: -110px !important; }
  .mL-nv-110\@md {
    margin-left: -110px !important; }
  .mY-nv-110\@md {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@md {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-110\@sm {
    margin: 110px !important; }
  .mT-110\@sm {
    margin-top: 110px !important; }
  .mR-110\@sm {
    margin-right: 110px !important; }
  .mB-110\@sm {
    margin-bottom: 110px !important; }
  .mL-110\@sm {
    margin-left: 110px !important; }
  .mY-110\@sm {
    margin-top: 110px !important;
    margin-bottom: 110px !important; }
  .mX-110\@sm {
    margin-left: 110px !important;
    margin-right: 110px !important; }
  .m-nv-110\@sm {
    margin: -110px !important; }
  .mT-nv-110\@sm {
    margin-top: -110px !important; }
  .mR-nv-110\@sm {
    margin-right: -110px !important; }
  .mB-nv-110\@sm {
    margin-bottom: -110px !important; }
  .mL-nv-110\@sm {
    margin-left: -110px !important; }
  .mY-nv-110\@sm {
    margin-top: -110px !important;
    margin-bottom: -110px !important; }
  .mX-nv-110\@sm {
    margin-left: -110px !important;
    margin-right: -110px !important; } }

.m-120 {
  margin: 120px !important; }

.mT-120 {
  margin-top: 120px !important; }

.mR-120 {
  margin-right: 120px !important; }

.mB-120 {
  margin-bottom: 120px !important; }

.mL-120 {
  margin-left: 120px !important; }

.mY-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.mX-120 {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.m-nv-120 {
  margin: -120px !important; }

.mT-nv-120 {
  margin-top: -120px !important; }

.mR-nv-120 {
  margin-right: -120px !important; }

.mB-nv-120 {
  margin-bottom: -120px !important; }

.mL-nv-120 {
  margin-left: -120px !important; }

.mY-nv-120 {
  margin-top: -120px !important;
  margin-bottom: -120px !important; }

.mX-nv-120 {
  margin-left: -120px !important;
  margin-right: -120px !important; }

@media screen and (min-width: 1440px) {
  .m-120\@xl\+ {
    margin: 120px !important; }
  .mT-120\@xl\+ {
    margin-top: 120px !important; }
  .mR-120\@xl\+ {
    margin-right: 120px !important; }
  .mB-120\@xl\+ {
    margin-bottom: 120px !important; }
  .mL-120\@xl\+ {
    margin-left: 120px !important; }
  .mY-120\@xl\+ {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@xl\+ {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@xl\+ {
    margin: -120px !important; }
  .mT-nv-120\@xl\+ {
    margin-top: -120px !important; }
  .mR-nv-120\@xl\+ {
    margin-right: -120px !important; }
  .mB-nv-120\@xl\+ {
    margin-bottom: -120px !important; }
  .mL-nv-120\@xl\+ {
    margin-left: -120px !important; }
  .mY-nv-120\@xl\+ {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@xl\+ {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 1200px) {
  .m-120\@lg\+ {
    margin: 120px !important; }
  .mT-120\@lg\+ {
    margin-top: 120px !important; }
  .mR-120\@lg\+ {
    margin-right: 120px !important; }
  .mB-120\@lg\+ {
    margin-bottom: 120px !important; }
  .mL-120\@lg\+ {
    margin-left: 120px !important; }
  .mY-120\@lg\+ {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@lg\+ {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@lg\+ {
    margin: -120px !important; }
  .mT-nv-120\@lg\+ {
    margin-top: -120px !important; }
  .mR-nv-120\@lg\+ {
    margin-right: -120px !important; }
  .mB-nv-120\@lg\+ {
    margin-bottom: -120px !important; }
  .mL-nv-120\@lg\+ {
    margin-left: -120px !important; }
  .mY-nv-120\@lg\+ {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@lg\+ {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 992px) {
  .m-120\@md\+ {
    margin: 120px !important; }
  .mT-120\@md\+ {
    margin-top: 120px !important; }
  .mR-120\@md\+ {
    margin-right: 120px !important; }
  .mB-120\@md\+ {
    margin-bottom: 120px !important; }
  .mL-120\@md\+ {
    margin-left: 120px !important; }
  .mY-120\@md\+ {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@md\+ {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@md\+ {
    margin: -120px !important; }
  .mT-nv-120\@md\+ {
    margin-top: -120px !important; }
  .mR-nv-120\@md\+ {
    margin-right: -120px !important; }
  .mB-nv-120\@md\+ {
    margin-bottom: -120px !important; }
  .mL-nv-120\@md\+ {
    margin-left: -120px !important; }
  .mY-nv-120\@md\+ {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@md\+ {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 768px) {
  .m-120\@sm\+ {
    margin: 120px !important; }
  .mT-120\@sm\+ {
    margin-top: 120px !important; }
  .mR-120\@sm\+ {
    margin-right: 120px !important; }
  .mB-120\@sm\+ {
    margin-bottom: 120px !important; }
  .mL-120\@sm\+ {
    margin-left: 120px !important; }
  .mY-120\@sm\+ {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@sm\+ {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@sm\+ {
    margin: -120px !important; }
  .mT-nv-120\@sm\+ {
    margin-top: -120px !important; }
  .mR-nv-120\@sm\+ {
    margin-right: -120px !important; }
  .mB-nv-120\@sm\+ {
    margin-bottom: -120px !important; }
  .mL-nv-120\@sm\+ {
    margin-left: -120px !important; }
  .mY-nv-120\@sm\+ {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@sm\+ {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 0) {
  .m-120\@xs\+ {
    margin: 120px !important; }
  .mT-120\@xs\+ {
    margin-top: 120px !important; }
  .mR-120\@xs\+ {
    margin-right: 120px !important; }
  .mB-120\@xs\+ {
    margin-bottom: 120px !important; }
  .mL-120\@xs\+ {
    margin-left: 120px !important; }
  .mY-120\@xs\+ {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@xs\+ {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@xs\+ {
    margin: -120px !important; }
  .mT-nv-120\@xs\+ {
    margin-top: -120px !important; }
  .mR-nv-120\@xs\+ {
    margin-right: -120px !important; }
  .mB-nv-120\@xs\+ {
    margin-bottom: -120px !important; }
  .mL-nv-120\@xs\+ {
    margin-left: -120px !important; }
  .mY-nv-120\@xs\+ {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@xs\+ {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (max-width: 1439px) {
  .m-120\@xl- {
    margin: 120px !important; }
  .mT-120\@xl- {
    margin-top: 120px !important; }
  .mR-120\@xl- {
    margin-right: 120px !important; }
  .mB-120\@xl- {
    margin-bottom: 120px !important; }
  .mL-120\@xl- {
    margin-left: 120px !important; }
  .mY-120\@xl- {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@xl- {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@xl- {
    margin: -120px !important; }
  .mT-nv-120\@xl- {
    margin-top: -120px !important; }
  .mR-nv-120\@xl- {
    margin-right: -120px !important; }
  .mB-nv-120\@xl- {
    margin-bottom: -120px !important; }
  .mL-nv-120\@xl- {
    margin-left: -120px !important; }
  .mY-nv-120\@xl- {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@xl- {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (max-width: 1199px) {
  .m-120\@lg- {
    margin: 120px !important; }
  .mT-120\@lg- {
    margin-top: 120px !important; }
  .mR-120\@lg- {
    margin-right: 120px !important; }
  .mB-120\@lg- {
    margin-bottom: 120px !important; }
  .mL-120\@lg- {
    margin-left: 120px !important; }
  .mY-120\@lg- {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@lg- {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@lg- {
    margin: -120px !important; }
  .mT-nv-120\@lg- {
    margin-top: -120px !important; }
  .mR-nv-120\@lg- {
    margin-right: -120px !important; }
  .mB-nv-120\@lg- {
    margin-bottom: -120px !important; }
  .mL-nv-120\@lg- {
    margin-left: -120px !important; }
  .mY-nv-120\@lg- {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@lg- {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (max-width: 991px) {
  .m-120\@md- {
    margin: 120px !important; }
  .mT-120\@md- {
    margin-top: 120px !important; }
  .mR-120\@md- {
    margin-right: 120px !important; }
  .mB-120\@md- {
    margin-bottom: 120px !important; }
  .mL-120\@md- {
    margin-left: 120px !important; }
  .mY-120\@md- {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@md- {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@md- {
    margin: -120px !important; }
  .mT-nv-120\@md- {
    margin-top: -120px !important; }
  .mR-nv-120\@md- {
    margin-right: -120px !important; }
  .mB-nv-120\@md- {
    margin-bottom: -120px !important; }
  .mL-nv-120\@md- {
    margin-left: -120px !important; }
  .mY-nv-120\@md- {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@md- {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (max-width: 767px) {
  .m-120\@sm- {
    margin: 120px !important; }
  .mT-120\@sm- {
    margin-top: 120px !important; }
  .mR-120\@sm- {
    margin-right: 120px !important; }
  .mB-120\@sm- {
    margin-bottom: 120px !important; }
  .mL-120\@sm- {
    margin-left: 120px !important; }
  .mY-120\@sm- {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@sm- {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@sm- {
    margin: -120px !important; }
  .mT-nv-120\@sm- {
    margin-top: -120px !important; }
  .mR-nv-120\@sm- {
    margin-right: -120px !important; }
  .mB-nv-120\@sm- {
    margin-bottom: -120px !important; }
  .mL-nv-120\@sm- {
    margin-left: -120px !important; }
  .mY-nv-120\@sm- {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@sm- {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-120\@lg {
    margin: 120px !important; }
  .mT-120\@lg {
    margin-top: 120px !important; }
  .mR-120\@lg {
    margin-right: 120px !important; }
  .mB-120\@lg {
    margin-bottom: 120px !important; }
  .mL-120\@lg {
    margin-left: 120px !important; }
  .mY-120\@lg {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@lg {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@lg {
    margin: -120px !important; }
  .mT-nv-120\@lg {
    margin-top: -120px !important; }
  .mR-nv-120\@lg {
    margin-right: -120px !important; }
  .mB-nv-120\@lg {
    margin-bottom: -120px !important; }
  .mL-nv-120\@lg {
    margin-left: -120px !important; }
  .mY-nv-120\@lg {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@lg {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-120\@md {
    margin: 120px !important; }
  .mT-120\@md {
    margin-top: 120px !important; }
  .mR-120\@md {
    margin-right: 120px !important; }
  .mB-120\@md {
    margin-bottom: 120px !important; }
  .mL-120\@md {
    margin-left: 120px !important; }
  .mY-120\@md {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@md {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@md {
    margin: -120px !important; }
  .mT-nv-120\@md {
    margin-top: -120px !important; }
  .mR-nv-120\@md {
    margin-right: -120px !important; }
  .mB-nv-120\@md {
    margin-bottom: -120px !important; }
  .mL-nv-120\@md {
    margin-left: -120px !important; }
  .mY-nv-120\@md {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@md {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-120\@sm {
    margin: 120px !important; }
  .mT-120\@sm {
    margin-top: 120px !important; }
  .mR-120\@sm {
    margin-right: 120px !important; }
  .mB-120\@sm {
    margin-bottom: 120px !important; }
  .mL-120\@sm {
    margin-left: 120px !important; }
  .mY-120\@sm {
    margin-top: 120px !important;
    margin-bottom: 120px !important; }
  .mX-120\@sm {
    margin-left: 120px !important;
    margin-right: 120px !important; }
  .m-nv-120\@sm {
    margin: -120px !important; }
  .mT-nv-120\@sm {
    margin-top: -120px !important; }
  .mR-nv-120\@sm {
    margin-right: -120px !important; }
  .mB-nv-120\@sm {
    margin-bottom: -120px !important; }
  .mL-nv-120\@sm {
    margin-left: -120px !important; }
  .mY-nv-120\@sm {
    margin-top: -120px !important;
    margin-bottom: -120px !important; }
  .mX-nv-120\@sm {
    margin-left: -120px !important;
    margin-right: -120px !important; } }

.m-130 {
  margin: 130px !important; }

.mT-130 {
  margin-top: 130px !important; }

.mR-130 {
  margin-right: 130px !important; }

.mB-130 {
  margin-bottom: 130px !important; }

.mL-130 {
  margin-left: 130px !important; }

.mY-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important; }

.mX-130 {
  margin-left: 130px !important;
  margin-right: 130px !important; }

.m-nv-130 {
  margin: -130px !important; }

.mT-nv-130 {
  margin-top: -130px !important; }

.mR-nv-130 {
  margin-right: -130px !important; }

.mB-nv-130 {
  margin-bottom: -130px !important; }

.mL-nv-130 {
  margin-left: -130px !important; }

.mY-nv-130 {
  margin-top: -130px !important;
  margin-bottom: -130px !important; }

.mX-nv-130 {
  margin-left: -130px !important;
  margin-right: -130px !important; }

@media screen and (min-width: 1440px) {
  .m-130\@xl\+ {
    margin: 130px !important; }
  .mT-130\@xl\+ {
    margin-top: 130px !important; }
  .mR-130\@xl\+ {
    margin-right: 130px !important; }
  .mB-130\@xl\+ {
    margin-bottom: 130px !important; }
  .mL-130\@xl\+ {
    margin-left: 130px !important; }
  .mY-130\@xl\+ {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@xl\+ {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@xl\+ {
    margin: -130px !important; }
  .mT-nv-130\@xl\+ {
    margin-top: -130px !important; }
  .mR-nv-130\@xl\+ {
    margin-right: -130px !important; }
  .mB-nv-130\@xl\+ {
    margin-bottom: -130px !important; }
  .mL-nv-130\@xl\+ {
    margin-left: -130px !important; }
  .mY-nv-130\@xl\+ {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@xl\+ {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 1200px) {
  .m-130\@lg\+ {
    margin: 130px !important; }
  .mT-130\@lg\+ {
    margin-top: 130px !important; }
  .mR-130\@lg\+ {
    margin-right: 130px !important; }
  .mB-130\@lg\+ {
    margin-bottom: 130px !important; }
  .mL-130\@lg\+ {
    margin-left: 130px !important; }
  .mY-130\@lg\+ {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@lg\+ {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@lg\+ {
    margin: -130px !important; }
  .mT-nv-130\@lg\+ {
    margin-top: -130px !important; }
  .mR-nv-130\@lg\+ {
    margin-right: -130px !important; }
  .mB-nv-130\@lg\+ {
    margin-bottom: -130px !important; }
  .mL-nv-130\@lg\+ {
    margin-left: -130px !important; }
  .mY-nv-130\@lg\+ {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@lg\+ {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 992px) {
  .m-130\@md\+ {
    margin: 130px !important; }
  .mT-130\@md\+ {
    margin-top: 130px !important; }
  .mR-130\@md\+ {
    margin-right: 130px !important; }
  .mB-130\@md\+ {
    margin-bottom: 130px !important; }
  .mL-130\@md\+ {
    margin-left: 130px !important; }
  .mY-130\@md\+ {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@md\+ {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@md\+ {
    margin: -130px !important; }
  .mT-nv-130\@md\+ {
    margin-top: -130px !important; }
  .mR-nv-130\@md\+ {
    margin-right: -130px !important; }
  .mB-nv-130\@md\+ {
    margin-bottom: -130px !important; }
  .mL-nv-130\@md\+ {
    margin-left: -130px !important; }
  .mY-nv-130\@md\+ {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@md\+ {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 768px) {
  .m-130\@sm\+ {
    margin: 130px !important; }
  .mT-130\@sm\+ {
    margin-top: 130px !important; }
  .mR-130\@sm\+ {
    margin-right: 130px !important; }
  .mB-130\@sm\+ {
    margin-bottom: 130px !important; }
  .mL-130\@sm\+ {
    margin-left: 130px !important; }
  .mY-130\@sm\+ {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@sm\+ {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@sm\+ {
    margin: -130px !important; }
  .mT-nv-130\@sm\+ {
    margin-top: -130px !important; }
  .mR-nv-130\@sm\+ {
    margin-right: -130px !important; }
  .mB-nv-130\@sm\+ {
    margin-bottom: -130px !important; }
  .mL-nv-130\@sm\+ {
    margin-left: -130px !important; }
  .mY-nv-130\@sm\+ {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@sm\+ {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 0) {
  .m-130\@xs\+ {
    margin: 130px !important; }
  .mT-130\@xs\+ {
    margin-top: 130px !important; }
  .mR-130\@xs\+ {
    margin-right: 130px !important; }
  .mB-130\@xs\+ {
    margin-bottom: 130px !important; }
  .mL-130\@xs\+ {
    margin-left: 130px !important; }
  .mY-130\@xs\+ {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@xs\+ {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@xs\+ {
    margin: -130px !important; }
  .mT-nv-130\@xs\+ {
    margin-top: -130px !important; }
  .mR-nv-130\@xs\+ {
    margin-right: -130px !important; }
  .mB-nv-130\@xs\+ {
    margin-bottom: -130px !important; }
  .mL-nv-130\@xs\+ {
    margin-left: -130px !important; }
  .mY-nv-130\@xs\+ {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@xs\+ {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (max-width: 1439px) {
  .m-130\@xl- {
    margin: 130px !important; }
  .mT-130\@xl- {
    margin-top: 130px !important; }
  .mR-130\@xl- {
    margin-right: 130px !important; }
  .mB-130\@xl- {
    margin-bottom: 130px !important; }
  .mL-130\@xl- {
    margin-left: 130px !important; }
  .mY-130\@xl- {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@xl- {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@xl- {
    margin: -130px !important; }
  .mT-nv-130\@xl- {
    margin-top: -130px !important; }
  .mR-nv-130\@xl- {
    margin-right: -130px !important; }
  .mB-nv-130\@xl- {
    margin-bottom: -130px !important; }
  .mL-nv-130\@xl- {
    margin-left: -130px !important; }
  .mY-nv-130\@xl- {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@xl- {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (max-width: 1199px) {
  .m-130\@lg- {
    margin: 130px !important; }
  .mT-130\@lg- {
    margin-top: 130px !important; }
  .mR-130\@lg- {
    margin-right: 130px !important; }
  .mB-130\@lg- {
    margin-bottom: 130px !important; }
  .mL-130\@lg- {
    margin-left: 130px !important; }
  .mY-130\@lg- {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@lg- {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@lg- {
    margin: -130px !important; }
  .mT-nv-130\@lg- {
    margin-top: -130px !important; }
  .mR-nv-130\@lg- {
    margin-right: -130px !important; }
  .mB-nv-130\@lg- {
    margin-bottom: -130px !important; }
  .mL-nv-130\@lg- {
    margin-left: -130px !important; }
  .mY-nv-130\@lg- {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@lg- {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (max-width: 991px) {
  .m-130\@md- {
    margin: 130px !important; }
  .mT-130\@md- {
    margin-top: 130px !important; }
  .mR-130\@md- {
    margin-right: 130px !important; }
  .mB-130\@md- {
    margin-bottom: 130px !important; }
  .mL-130\@md- {
    margin-left: 130px !important; }
  .mY-130\@md- {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@md- {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@md- {
    margin: -130px !important; }
  .mT-nv-130\@md- {
    margin-top: -130px !important; }
  .mR-nv-130\@md- {
    margin-right: -130px !important; }
  .mB-nv-130\@md- {
    margin-bottom: -130px !important; }
  .mL-nv-130\@md- {
    margin-left: -130px !important; }
  .mY-nv-130\@md- {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@md- {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (max-width: 767px) {
  .m-130\@sm- {
    margin: 130px !important; }
  .mT-130\@sm- {
    margin-top: 130px !important; }
  .mR-130\@sm- {
    margin-right: 130px !important; }
  .mB-130\@sm- {
    margin-bottom: 130px !important; }
  .mL-130\@sm- {
    margin-left: 130px !important; }
  .mY-130\@sm- {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@sm- {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@sm- {
    margin: -130px !important; }
  .mT-nv-130\@sm- {
    margin-top: -130px !important; }
  .mR-nv-130\@sm- {
    margin-right: -130px !important; }
  .mB-nv-130\@sm- {
    margin-bottom: -130px !important; }
  .mL-nv-130\@sm- {
    margin-left: -130px !important; }
  .mY-nv-130\@sm- {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@sm- {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-130\@lg {
    margin: 130px !important; }
  .mT-130\@lg {
    margin-top: 130px !important; }
  .mR-130\@lg {
    margin-right: 130px !important; }
  .mB-130\@lg {
    margin-bottom: 130px !important; }
  .mL-130\@lg {
    margin-left: 130px !important; }
  .mY-130\@lg {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@lg {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@lg {
    margin: -130px !important; }
  .mT-nv-130\@lg {
    margin-top: -130px !important; }
  .mR-nv-130\@lg {
    margin-right: -130px !important; }
  .mB-nv-130\@lg {
    margin-bottom: -130px !important; }
  .mL-nv-130\@lg {
    margin-left: -130px !important; }
  .mY-nv-130\@lg {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@lg {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-130\@md {
    margin: 130px !important; }
  .mT-130\@md {
    margin-top: 130px !important; }
  .mR-130\@md {
    margin-right: 130px !important; }
  .mB-130\@md {
    margin-bottom: 130px !important; }
  .mL-130\@md {
    margin-left: 130px !important; }
  .mY-130\@md {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@md {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@md {
    margin: -130px !important; }
  .mT-nv-130\@md {
    margin-top: -130px !important; }
  .mR-nv-130\@md {
    margin-right: -130px !important; }
  .mB-nv-130\@md {
    margin-bottom: -130px !important; }
  .mL-nv-130\@md {
    margin-left: -130px !important; }
  .mY-nv-130\@md {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@md {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-130\@sm {
    margin: 130px !important; }
  .mT-130\@sm {
    margin-top: 130px !important; }
  .mR-130\@sm {
    margin-right: 130px !important; }
  .mB-130\@sm {
    margin-bottom: 130px !important; }
  .mL-130\@sm {
    margin-left: 130px !important; }
  .mY-130\@sm {
    margin-top: 130px !important;
    margin-bottom: 130px !important; }
  .mX-130\@sm {
    margin-left: 130px !important;
    margin-right: 130px !important; }
  .m-nv-130\@sm {
    margin: -130px !important; }
  .mT-nv-130\@sm {
    margin-top: -130px !important; }
  .mR-nv-130\@sm {
    margin-right: -130px !important; }
  .mB-nv-130\@sm {
    margin-bottom: -130px !important; }
  .mL-nv-130\@sm {
    margin-left: -130px !important; }
  .mY-nv-130\@sm {
    margin-top: -130px !important;
    margin-bottom: -130px !important; }
  .mX-nv-130\@sm {
    margin-left: -130px !important;
    margin-right: -130px !important; } }

.m-140 {
  margin: 140px !important; }

.mT-140 {
  margin-top: 140px !important; }

.mR-140 {
  margin-right: 140px !important; }

.mB-140 {
  margin-bottom: 140px !important; }

.mL-140 {
  margin-left: 140px !important; }

.mY-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.mX-140 {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.m-nv-140 {
  margin: -140px !important; }

.mT-nv-140 {
  margin-top: -140px !important; }

.mR-nv-140 {
  margin-right: -140px !important; }

.mB-nv-140 {
  margin-bottom: -140px !important; }

.mL-nv-140 {
  margin-left: -140px !important; }

.mY-nv-140 {
  margin-top: -140px !important;
  margin-bottom: -140px !important; }

.mX-nv-140 {
  margin-left: -140px !important;
  margin-right: -140px !important; }

@media screen and (min-width: 1440px) {
  .m-140\@xl\+ {
    margin: 140px !important; }
  .mT-140\@xl\+ {
    margin-top: 140px !important; }
  .mR-140\@xl\+ {
    margin-right: 140px !important; }
  .mB-140\@xl\+ {
    margin-bottom: 140px !important; }
  .mL-140\@xl\+ {
    margin-left: 140px !important; }
  .mY-140\@xl\+ {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@xl\+ {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@xl\+ {
    margin: -140px !important; }
  .mT-nv-140\@xl\+ {
    margin-top: -140px !important; }
  .mR-nv-140\@xl\+ {
    margin-right: -140px !important; }
  .mB-nv-140\@xl\+ {
    margin-bottom: -140px !important; }
  .mL-nv-140\@xl\+ {
    margin-left: -140px !important; }
  .mY-nv-140\@xl\+ {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@xl\+ {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 1200px) {
  .m-140\@lg\+ {
    margin: 140px !important; }
  .mT-140\@lg\+ {
    margin-top: 140px !important; }
  .mR-140\@lg\+ {
    margin-right: 140px !important; }
  .mB-140\@lg\+ {
    margin-bottom: 140px !important; }
  .mL-140\@lg\+ {
    margin-left: 140px !important; }
  .mY-140\@lg\+ {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@lg\+ {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@lg\+ {
    margin: -140px !important; }
  .mT-nv-140\@lg\+ {
    margin-top: -140px !important; }
  .mR-nv-140\@lg\+ {
    margin-right: -140px !important; }
  .mB-nv-140\@lg\+ {
    margin-bottom: -140px !important; }
  .mL-nv-140\@lg\+ {
    margin-left: -140px !important; }
  .mY-nv-140\@lg\+ {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@lg\+ {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 992px) {
  .m-140\@md\+ {
    margin: 140px !important; }
  .mT-140\@md\+ {
    margin-top: 140px !important; }
  .mR-140\@md\+ {
    margin-right: 140px !important; }
  .mB-140\@md\+ {
    margin-bottom: 140px !important; }
  .mL-140\@md\+ {
    margin-left: 140px !important; }
  .mY-140\@md\+ {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@md\+ {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@md\+ {
    margin: -140px !important; }
  .mT-nv-140\@md\+ {
    margin-top: -140px !important; }
  .mR-nv-140\@md\+ {
    margin-right: -140px !important; }
  .mB-nv-140\@md\+ {
    margin-bottom: -140px !important; }
  .mL-nv-140\@md\+ {
    margin-left: -140px !important; }
  .mY-nv-140\@md\+ {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@md\+ {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 768px) {
  .m-140\@sm\+ {
    margin: 140px !important; }
  .mT-140\@sm\+ {
    margin-top: 140px !important; }
  .mR-140\@sm\+ {
    margin-right: 140px !important; }
  .mB-140\@sm\+ {
    margin-bottom: 140px !important; }
  .mL-140\@sm\+ {
    margin-left: 140px !important; }
  .mY-140\@sm\+ {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@sm\+ {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@sm\+ {
    margin: -140px !important; }
  .mT-nv-140\@sm\+ {
    margin-top: -140px !important; }
  .mR-nv-140\@sm\+ {
    margin-right: -140px !important; }
  .mB-nv-140\@sm\+ {
    margin-bottom: -140px !important; }
  .mL-nv-140\@sm\+ {
    margin-left: -140px !important; }
  .mY-nv-140\@sm\+ {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@sm\+ {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 0) {
  .m-140\@xs\+ {
    margin: 140px !important; }
  .mT-140\@xs\+ {
    margin-top: 140px !important; }
  .mR-140\@xs\+ {
    margin-right: 140px !important; }
  .mB-140\@xs\+ {
    margin-bottom: 140px !important; }
  .mL-140\@xs\+ {
    margin-left: 140px !important; }
  .mY-140\@xs\+ {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@xs\+ {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@xs\+ {
    margin: -140px !important; }
  .mT-nv-140\@xs\+ {
    margin-top: -140px !important; }
  .mR-nv-140\@xs\+ {
    margin-right: -140px !important; }
  .mB-nv-140\@xs\+ {
    margin-bottom: -140px !important; }
  .mL-nv-140\@xs\+ {
    margin-left: -140px !important; }
  .mY-nv-140\@xs\+ {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@xs\+ {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (max-width: 1439px) {
  .m-140\@xl- {
    margin: 140px !important; }
  .mT-140\@xl- {
    margin-top: 140px !important; }
  .mR-140\@xl- {
    margin-right: 140px !important; }
  .mB-140\@xl- {
    margin-bottom: 140px !important; }
  .mL-140\@xl- {
    margin-left: 140px !important; }
  .mY-140\@xl- {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@xl- {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@xl- {
    margin: -140px !important; }
  .mT-nv-140\@xl- {
    margin-top: -140px !important; }
  .mR-nv-140\@xl- {
    margin-right: -140px !important; }
  .mB-nv-140\@xl- {
    margin-bottom: -140px !important; }
  .mL-nv-140\@xl- {
    margin-left: -140px !important; }
  .mY-nv-140\@xl- {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@xl- {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (max-width: 1199px) {
  .m-140\@lg- {
    margin: 140px !important; }
  .mT-140\@lg- {
    margin-top: 140px !important; }
  .mR-140\@lg- {
    margin-right: 140px !important; }
  .mB-140\@lg- {
    margin-bottom: 140px !important; }
  .mL-140\@lg- {
    margin-left: 140px !important; }
  .mY-140\@lg- {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@lg- {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@lg- {
    margin: -140px !important; }
  .mT-nv-140\@lg- {
    margin-top: -140px !important; }
  .mR-nv-140\@lg- {
    margin-right: -140px !important; }
  .mB-nv-140\@lg- {
    margin-bottom: -140px !important; }
  .mL-nv-140\@lg- {
    margin-left: -140px !important; }
  .mY-nv-140\@lg- {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@lg- {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (max-width: 991px) {
  .m-140\@md- {
    margin: 140px !important; }
  .mT-140\@md- {
    margin-top: 140px !important; }
  .mR-140\@md- {
    margin-right: 140px !important; }
  .mB-140\@md- {
    margin-bottom: 140px !important; }
  .mL-140\@md- {
    margin-left: 140px !important; }
  .mY-140\@md- {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@md- {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@md- {
    margin: -140px !important; }
  .mT-nv-140\@md- {
    margin-top: -140px !important; }
  .mR-nv-140\@md- {
    margin-right: -140px !important; }
  .mB-nv-140\@md- {
    margin-bottom: -140px !important; }
  .mL-nv-140\@md- {
    margin-left: -140px !important; }
  .mY-nv-140\@md- {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@md- {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (max-width: 767px) {
  .m-140\@sm- {
    margin: 140px !important; }
  .mT-140\@sm- {
    margin-top: 140px !important; }
  .mR-140\@sm- {
    margin-right: 140px !important; }
  .mB-140\@sm- {
    margin-bottom: 140px !important; }
  .mL-140\@sm- {
    margin-left: 140px !important; }
  .mY-140\@sm- {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@sm- {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@sm- {
    margin: -140px !important; }
  .mT-nv-140\@sm- {
    margin-top: -140px !important; }
  .mR-nv-140\@sm- {
    margin-right: -140px !important; }
  .mB-nv-140\@sm- {
    margin-bottom: -140px !important; }
  .mL-nv-140\@sm- {
    margin-left: -140px !important; }
  .mY-nv-140\@sm- {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@sm- {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-140\@lg {
    margin: 140px !important; }
  .mT-140\@lg {
    margin-top: 140px !important; }
  .mR-140\@lg {
    margin-right: 140px !important; }
  .mB-140\@lg {
    margin-bottom: 140px !important; }
  .mL-140\@lg {
    margin-left: 140px !important; }
  .mY-140\@lg {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@lg {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@lg {
    margin: -140px !important; }
  .mT-nv-140\@lg {
    margin-top: -140px !important; }
  .mR-nv-140\@lg {
    margin-right: -140px !important; }
  .mB-nv-140\@lg {
    margin-bottom: -140px !important; }
  .mL-nv-140\@lg {
    margin-left: -140px !important; }
  .mY-nv-140\@lg {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@lg {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-140\@md {
    margin: 140px !important; }
  .mT-140\@md {
    margin-top: 140px !important; }
  .mR-140\@md {
    margin-right: 140px !important; }
  .mB-140\@md {
    margin-bottom: 140px !important; }
  .mL-140\@md {
    margin-left: 140px !important; }
  .mY-140\@md {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@md {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@md {
    margin: -140px !important; }
  .mT-nv-140\@md {
    margin-top: -140px !important; }
  .mR-nv-140\@md {
    margin-right: -140px !important; }
  .mB-nv-140\@md {
    margin-bottom: -140px !important; }
  .mL-nv-140\@md {
    margin-left: -140px !important; }
  .mY-nv-140\@md {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@md {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-140\@sm {
    margin: 140px !important; }
  .mT-140\@sm {
    margin-top: 140px !important; }
  .mR-140\@sm {
    margin-right: 140px !important; }
  .mB-140\@sm {
    margin-bottom: 140px !important; }
  .mL-140\@sm {
    margin-left: 140px !important; }
  .mY-140\@sm {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .mX-140\@sm {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .m-nv-140\@sm {
    margin: -140px !important; }
  .mT-nv-140\@sm {
    margin-top: -140px !important; }
  .mR-nv-140\@sm {
    margin-right: -140px !important; }
  .mB-nv-140\@sm {
    margin-bottom: -140px !important; }
  .mL-nv-140\@sm {
    margin-left: -140px !important; }
  .mY-nv-140\@sm {
    margin-top: -140px !important;
    margin-bottom: -140px !important; }
  .mX-nv-140\@sm {
    margin-left: -140px !important;
    margin-right: -140px !important; } }

.m-150 {
  margin: 150px !important; }

.mT-150 {
  margin-top: 150px !important; }

.mR-150 {
  margin-right: 150px !important; }

.mB-150 {
  margin-bottom: 150px !important; }

.mL-150 {
  margin-left: 150px !important; }

.mY-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important; }

.mX-150 {
  margin-left: 150px !important;
  margin-right: 150px !important; }

.m-nv-150 {
  margin: -150px !important; }

.mT-nv-150 {
  margin-top: -150px !important; }

.mR-nv-150 {
  margin-right: -150px !important; }

.mB-nv-150 {
  margin-bottom: -150px !important; }

.mL-nv-150 {
  margin-left: -150px !important; }

.mY-nv-150 {
  margin-top: -150px !important;
  margin-bottom: -150px !important; }

.mX-nv-150 {
  margin-left: -150px !important;
  margin-right: -150px !important; }

@media screen and (min-width: 1440px) {
  .m-150\@xl\+ {
    margin: 150px !important; }
  .mT-150\@xl\+ {
    margin-top: 150px !important; }
  .mR-150\@xl\+ {
    margin-right: 150px !important; }
  .mB-150\@xl\+ {
    margin-bottom: 150px !important; }
  .mL-150\@xl\+ {
    margin-left: 150px !important; }
  .mY-150\@xl\+ {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@xl\+ {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@xl\+ {
    margin: -150px !important; }
  .mT-nv-150\@xl\+ {
    margin-top: -150px !important; }
  .mR-nv-150\@xl\+ {
    margin-right: -150px !important; }
  .mB-nv-150\@xl\+ {
    margin-bottom: -150px !important; }
  .mL-nv-150\@xl\+ {
    margin-left: -150px !important; }
  .mY-nv-150\@xl\+ {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@xl\+ {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 1200px) {
  .m-150\@lg\+ {
    margin: 150px !important; }
  .mT-150\@lg\+ {
    margin-top: 150px !important; }
  .mR-150\@lg\+ {
    margin-right: 150px !important; }
  .mB-150\@lg\+ {
    margin-bottom: 150px !important; }
  .mL-150\@lg\+ {
    margin-left: 150px !important; }
  .mY-150\@lg\+ {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@lg\+ {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@lg\+ {
    margin: -150px !important; }
  .mT-nv-150\@lg\+ {
    margin-top: -150px !important; }
  .mR-nv-150\@lg\+ {
    margin-right: -150px !important; }
  .mB-nv-150\@lg\+ {
    margin-bottom: -150px !important; }
  .mL-nv-150\@lg\+ {
    margin-left: -150px !important; }
  .mY-nv-150\@lg\+ {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@lg\+ {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 992px) {
  .m-150\@md\+ {
    margin: 150px !important; }
  .mT-150\@md\+ {
    margin-top: 150px !important; }
  .mR-150\@md\+ {
    margin-right: 150px !important; }
  .mB-150\@md\+ {
    margin-bottom: 150px !important; }
  .mL-150\@md\+ {
    margin-left: 150px !important; }
  .mY-150\@md\+ {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@md\+ {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@md\+ {
    margin: -150px !important; }
  .mT-nv-150\@md\+ {
    margin-top: -150px !important; }
  .mR-nv-150\@md\+ {
    margin-right: -150px !important; }
  .mB-nv-150\@md\+ {
    margin-bottom: -150px !important; }
  .mL-nv-150\@md\+ {
    margin-left: -150px !important; }
  .mY-nv-150\@md\+ {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@md\+ {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 768px) {
  .m-150\@sm\+ {
    margin: 150px !important; }
  .mT-150\@sm\+ {
    margin-top: 150px !important; }
  .mR-150\@sm\+ {
    margin-right: 150px !important; }
  .mB-150\@sm\+ {
    margin-bottom: 150px !important; }
  .mL-150\@sm\+ {
    margin-left: 150px !important; }
  .mY-150\@sm\+ {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@sm\+ {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@sm\+ {
    margin: -150px !important; }
  .mT-nv-150\@sm\+ {
    margin-top: -150px !important; }
  .mR-nv-150\@sm\+ {
    margin-right: -150px !important; }
  .mB-nv-150\@sm\+ {
    margin-bottom: -150px !important; }
  .mL-nv-150\@sm\+ {
    margin-left: -150px !important; }
  .mY-nv-150\@sm\+ {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@sm\+ {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 0) {
  .m-150\@xs\+ {
    margin: 150px !important; }
  .mT-150\@xs\+ {
    margin-top: 150px !important; }
  .mR-150\@xs\+ {
    margin-right: 150px !important; }
  .mB-150\@xs\+ {
    margin-bottom: 150px !important; }
  .mL-150\@xs\+ {
    margin-left: 150px !important; }
  .mY-150\@xs\+ {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@xs\+ {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@xs\+ {
    margin: -150px !important; }
  .mT-nv-150\@xs\+ {
    margin-top: -150px !important; }
  .mR-nv-150\@xs\+ {
    margin-right: -150px !important; }
  .mB-nv-150\@xs\+ {
    margin-bottom: -150px !important; }
  .mL-nv-150\@xs\+ {
    margin-left: -150px !important; }
  .mY-nv-150\@xs\+ {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@xs\+ {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (max-width: 1439px) {
  .m-150\@xl- {
    margin: 150px !important; }
  .mT-150\@xl- {
    margin-top: 150px !important; }
  .mR-150\@xl- {
    margin-right: 150px !important; }
  .mB-150\@xl- {
    margin-bottom: 150px !important; }
  .mL-150\@xl- {
    margin-left: 150px !important; }
  .mY-150\@xl- {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@xl- {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@xl- {
    margin: -150px !important; }
  .mT-nv-150\@xl- {
    margin-top: -150px !important; }
  .mR-nv-150\@xl- {
    margin-right: -150px !important; }
  .mB-nv-150\@xl- {
    margin-bottom: -150px !important; }
  .mL-nv-150\@xl- {
    margin-left: -150px !important; }
  .mY-nv-150\@xl- {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@xl- {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (max-width: 1199px) {
  .m-150\@lg- {
    margin: 150px !important; }
  .mT-150\@lg- {
    margin-top: 150px !important; }
  .mR-150\@lg- {
    margin-right: 150px !important; }
  .mB-150\@lg- {
    margin-bottom: 150px !important; }
  .mL-150\@lg- {
    margin-left: 150px !important; }
  .mY-150\@lg- {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@lg- {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@lg- {
    margin: -150px !important; }
  .mT-nv-150\@lg- {
    margin-top: -150px !important; }
  .mR-nv-150\@lg- {
    margin-right: -150px !important; }
  .mB-nv-150\@lg- {
    margin-bottom: -150px !important; }
  .mL-nv-150\@lg- {
    margin-left: -150px !important; }
  .mY-nv-150\@lg- {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@lg- {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (max-width: 991px) {
  .m-150\@md- {
    margin: 150px !important; }
  .mT-150\@md- {
    margin-top: 150px !important; }
  .mR-150\@md- {
    margin-right: 150px !important; }
  .mB-150\@md- {
    margin-bottom: 150px !important; }
  .mL-150\@md- {
    margin-left: 150px !important; }
  .mY-150\@md- {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@md- {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@md- {
    margin: -150px !important; }
  .mT-nv-150\@md- {
    margin-top: -150px !important; }
  .mR-nv-150\@md- {
    margin-right: -150px !important; }
  .mB-nv-150\@md- {
    margin-bottom: -150px !important; }
  .mL-nv-150\@md- {
    margin-left: -150px !important; }
  .mY-nv-150\@md- {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@md- {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (max-width: 767px) {
  .m-150\@sm- {
    margin: 150px !important; }
  .mT-150\@sm- {
    margin-top: 150px !important; }
  .mR-150\@sm- {
    margin-right: 150px !important; }
  .mB-150\@sm- {
    margin-bottom: 150px !important; }
  .mL-150\@sm- {
    margin-left: 150px !important; }
  .mY-150\@sm- {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@sm- {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@sm- {
    margin: -150px !important; }
  .mT-nv-150\@sm- {
    margin-top: -150px !important; }
  .mR-nv-150\@sm- {
    margin-right: -150px !important; }
  .mB-nv-150\@sm- {
    margin-bottom: -150px !important; }
  .mL-nv-150\@sm- {
    margin-left: -150px !important; }
  .mY-nv-150\@sm- {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@sm- {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-150\@lg {
    margin: 150px !important; }
  .mT-150\@lg {
    margin-top: 150px !important; }
  .mR-150\@lg {
    margin-right: 150px !important; }
  .mB-150\@lg {
    margin-bottom: 150px !important; }
  .mL-150\@lg {
    margin-left: 150px !important; }
  .mY-150\@lg {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@lg {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@lg {
    margin: -150px !important; }
  .mT-nv-150\@lg {
    margin-top: -150px !important; }
  .mR-nv-150\@lg {
    margin-right: -150px !important; }
  .mB-nv-150\@lg {
    margin-bottom: -150px !important; }
  .mL-nv-150\@lg {
    margin-left: -150px !important; }
  .mY-nv-150\@lg {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@lg {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-150\@md {
    margin: 150px !important; }
  .mT-150\@md {
    margin-top: 150px !important; }
  .mR-150\@md {
    margin-right: 150px !important; }
  .mB-150\@md {
    margin-bottom: 150px !important; }
  .mL-150\@md {
    margin-left: 150px !important; }
  .mY-150\@md {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@md {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@md {
    margin: -150px !important; }
  .mT-nv-150\@md {
    margin-top: -150px !important; }
  .mR-nv-150\@md {
    margin-right: -150px !important; }
  .mB-nv-150\@md {
    margin-bottom: -150px !important; }
  .mL-nv-150\@md {
    margin-left: -150px !important; }
  .mY-nv-150\@md {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@md {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-150\@sm {
    margin: 150px !important; }
  .mT-150\@sm {
    margin-top: 150px !important; }
  .mR-150\@sm {
    margin-right: 150px !important; }
  .mB-150\@sm {
    margin-bottom: 150px !important; }
  .mL-150\@sm {
    margin-left: 150px !important; }
  .mY-150\@sm {
    margin-top: 150px !important;
    margin-bottom: 150px !important; }
  .mX-150\@sm {
    margin-left: 150px !important;
    margin-right: 150px !important; }
  .m-nv-150\@sm {
    margin: -150px !important; }
  .mT-nv-150\@sm {
    margin-top: -150px !important; }
  .mR-nv-150\@sm {
    margin-right: -150px !important; }
  .mB-nv-150\@sm {
    margin-bottom: -150px !important; }
  .mL-nv-150\@sm {
    margin-left: -150px !important; }
  .mY-nv-150\@sm {
    margin-top: -150px !important;
    margin-bottom: -150px !important; }
  .mX-nv-150\@sm {
    margin-left: -150px !important;
    margin-right: -150px !important; } }

.m-160 {
  margin: 160px !important; }

.mT-160 {
  margin-top: 160px !important; }

.mR-160 {
  margin-right: 160px !important; }

.mB-160 {
  margin-bottom: 160px !important; }

.mL-160 {
  margin-left: 160px !important; }

.mY-160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important; }

.mX-160 {
  margin-left: 160px !important;
  margin-right: 160px !important; }

.m-nv-160 {
  margin: -160px !important; }

.mT-nv-160 {
  margin-top: -160px !important; }

.mR-nv-160 {
  margin-right: -160px !important; }

.mB-nv-160 {
  margin-bottom: -160px !important; }

.mL-nv-160 {
  margin-left: -160px !important; }

.mY-nv-160 {
  margin-top: -160px !important;
  margin-bottom: -160px !important; }

.mX-nv-160 {
  margin-left: -160px !important;
  margin-right: -160px !important; }

@media screen and (min-width: 1440px) {
  .m-160\@xl\+ {
    margin: 160px !important; }
  .mT-160\@xl\+ {
    margin-top: 160px !important; }
  .mR-160\@xl\+ {
    margin-right: 160px !important; }
  .mB-160\@xl\+ {
    margin-bottom: 160px !important; }
  .mL-160\@xl\+ {
    margin-left: 160px !important; }
  .mY-160\@xl\+ {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@xl\+ {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@xl\+ {
    margin: -160px !important; }
  .mT-nv-160\@xl\+ {
    margin-top: -160px !important; }
  .mR-nv-160\@xl\+ {
    margin-right: -160px !important; }
  .mB-nv-160\@xl\+ {
    margin-bottom: -160px !important; }
  .mL-nv-160\@xl\+ {
    margin-left: -160px !important; }
  .mY-nv-160\@xl\+ {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@xl\+ {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 1200px) {
  .m-160\@lg\+ {
    margin: 160px !important; }
  .mT-160\@lg\+ {
    margin-top: 160px !important; }
  .mR-160\@lg\+ {
    margin-right: 160px !important; }
  .mB-160\@lg\+ {
    margin-bottom: 160px !important; }
  .mL-160\@lg\+ {
    margin-left: 160px !important; }
  .mY-160\@lg\+ {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@lg\+ {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@lg\+ {
    margin: -160px !important; }
  .mT-nv-160\@lg\+ {
    margin-top: -160px !important; }
  .mR-nv-160\@lg\+ {
    margin-right: -160px !important; }
  .mB-nv-160\@lg\+ {
    margin-bottom: -160px !important; }
  .mL-nv-160\@lg\+ {
    margin-left: -160px !important; }
  .mY-nv-160\@lg\+ {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@lg\+ {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 992px) {
  .m-160\@md\+ {
    margin: 160px !important; }
  .mT-160\@md\+ {
    margin-top: 160px !important; }
  .mR-160\@md\+ {
    margin-right: 160px !important; }
  .mB-160\@md\+ {
    margin-bottom: 160px !important; }
  .mL-160\@md\+ {
    margin-left: 160px !important; }
  .mY-160\@md\+ {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@md\+ {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@md\+ {
    margin: -160px !important; }
  .mT-nv-160\@md\+ {
    margin-top: -160px !important; }
  .mR-nv-160\@md\+ {
    margin-right: -160px !important; }
  .mB-nv-160\@md\+ {
    margin-bottom: -160px !important; }
  .mL-nv-160\@md\+ {
    margin-left: -160px !important; }
  .mY-nv-160\@md\+ {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@md\+ {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 768px) {
  .m-160\@sm\+ {
    margin: 160px !important; }
  .mT-160\@sm\+ {
    margin-top: 160px !important; }
  .mR-160\@sm\+ {
    margin-right: 160px !important; }
  .mB-160\@sm\+ {
    margin-bottom: 160px !important; }
  .mL-160\@sm\+ {
    margin-left: 160px !important; }
  .mY-160\@sm\+ {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@sm\+ {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@sm\+ {
    margin: -160px !important; }
  .mT-nv-160\@sm\+ {
    margin-top: -160px !important; }
  .mR-nv-160\@sm\+ {
    margin-right: -160px !important; }
  .mB-nv-160\@sm\+ {
    margin-bottom: -160px !important; }
  .mL-nv-160\@sm\+ {
    margin-left: -160px !important; }
  .mY-nv-160\@sm\+ {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@sm\+ {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 0) {
  .m-160\@xs\+ {
    margin: 160px !important; }
  .mT-160\@xs\+ {
    margin-top: 160px !important; }
  .mR-160\@xs\+ {
    margin-right: 160px !important; }
  .mB-160\@xs\+ {
    margin-bottom: 160px !important; }
  .mL-160\@xs\+ {
    margin-left: 160px !important; }
  .mY-160\@xs\+ {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@xs\+ {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@xs\+ {
    margin: -160px !important; }
  .mT-nv-160\@xs\+ {
    margin-top: -160px !important; }
  .mR-nv-160\@xs\+ {
    margin-right: -160px !important; }
  .mB-nv-160\@xs\+ {
    margin-bottom: -160px !important; }
  .mL-nv-160\@xs\+ {
    margin-left: -160px !important; }
  .mY-nv-160\@xs\+ {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@xs\+ {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (max-width: 1439px) {
  .m-160\@xl- {
    margin: 160px !important; }
  .mT-160\@xl- {
    margin-top: 160px !important; }
  .mR-160\@xl- {
    margin-right: 160px !important; }
  .mB-160\@xl- {
    margin-bottom: 160px !important; }
  .mL-160\@xl- {
    margin-left: 160px !important; }
  .mY-160\@xl- {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@xl- {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@xl- {
    margin: -160px !important; }
  .mT-nv-160\@xl- {
    margin-top: -160px !important; }
  .mR-nv-160\@xl- {
    margin-right: -160px !important; }
  .mB-nv-160\@xl- {
    margin-bottom: -160px !important; }
  .mL-nv-160\@xl- {
    margin-left: -160px !important; }
  .mY-nv-160\@xl- {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@xl- {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (max-width: 1199px) {
  .m-160\@lg- {
    margin: 160px !important; }
  .mT-160\@lg- {
    margin-top: 160px !important; }
  .mR-160\@lg- {
    margin-right: 160px !important; }
  .mB-160\@lg- {
    margin-bottom: 160px !important; }
  .mL-160\@lg- {
    margin-left: 160px !important; }
  .mY-160\@lg- {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@lg- {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@lg- {
    margin: -160px !important; }
  .mT-nv-160\@lg- {
    margin-top: -160px !important; }
  .mR-nv-160\@lg- {
    margin-right: -160px !important; }
  .mB-nv-160\@lg- {
    margin-bottom: -160px !important; }
  .mL-nv-160\@lg- {
    margin-left: -160px !important; }
  .mY-nv-160\@lg- {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@lg- {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (max-width: 991px) {
  .m-160\@md- {
    margin: 160px !important; }
  .mT-160\@md- {
    margin-top: 160px !important; }
  .mR-160\@md- {
    margin-right: 160px !important; }
  .mB-160\@md- {
    margin-bottom: 160px !important; }
  .mL-160\@md- {
    margin-left: 160px !important; }
  .mY-160\@md- {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@md- {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@md- {
    margin: -160px !important; }
  .mT-nv-160\@md- {
    margin-top: -160px !important; }
  .mR-nv-160\@md- {
    margin-right: -160px !important; }
  .mB-nv-160\@md- {
    margin-bottom: -160px !important; }
  .mL-nv-160\@md- {
    margin-left: -160px !important; }
  .mY-nv-160\@md- {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@md- {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (max-width: 767px) {
  .m-160\@sm- {
    margin: 160px !important; }
  .mT-160\@sm- {
    margin-top: 160px !important; }
  .mR-160\@sm- {
    margin-right: 160px !important; }
  .mB-160\@sm- {
    margin-bottom: 160px !important; }
  .mL-160\@sm- {
    margin-left: 160px !important; }
  .mY-160\@sm- {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@sm- {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@sm- {
    margin: -160px !important; }
  .mT-nv-160\@sm- {
    margin-top: -160px !important; }
  .mR-nv-160\@sm- {
    margin-right: -160px !important; }
  .mB-nv-160\@sm- {
    margin-bottom: -160px !important; }
  .mL-nv-160\@sm- {
    margin-left: -160px !important; }
  .mY-nv-160\@sm- {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@sm- {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-160\@lg {
    margin: 160px !important; }
  .mT-160\@lg {
    margin-top: 160px !important; }
  .mR-160\@lg {
    margin-right: 160px !important; }
  .mB-160\@lg {
    margin-bottom: 160px !important; }
  .mL-160\@lg {
    margin-left: 160px !important; }
  .mY-160\@lg {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@lg {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@lg {
    margin: -160px !important; }
  .mT-nv-160\@lg {
    margin-top: -160px !important; }
  .mR-nv-160\@lg {
    margin-right: -160px !important; }
  .mB-nv-160\@lg {
    margin-bottom: -160px !important; }
  .mL-nv-160\@lg {
    margin-left: -160px !important; }
  .mY-nv-160\@lg {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@lg {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-160\@md {
    margin: 160px !important; }
  .mT-160\@md {
    margin-top: 160px !important; }
  .mR-160\@md {
    margin-right: 160px !important; }
  .mB-160\@md {
    margin-bottom: 160px !important; }
  .mL-160\@md {
    margin-left: 160px !important; }
  .mY-160\@md {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@md {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@md {
    margin: -160px !important; }
  .mT-nv-160\@md {
    margin-top: -160px !important; }
  .mR-nv-160\@md {
    margin-right: -160px !important; }
  .mB-nv-160\@md {
    margin-bottom: -160px !important; }
  .mL-nv-160\@md {
    margin-left: -160px !important; }
  .mY-nv-160\@md {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@md {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-160\@sm {
    margin: 160px !important; }
  .mT-160\@sm {
    margin-top: 160px !important; }
  .mR-160\@sm {
    margin-right: 160px !important; }
  .mB-160\@sm {
    margin-bottom: 160px !important; }
  .mL-160\@sm {
    margin-left: 160px !important; }
  .mY-160\@sm {
    margin-top: 160px !important;
    margin-bottom: 160px !important; }
  .mX-160\@sm {
    margin-left: 160px !important;
    margin-right: 160px !important; }
  .m-nv-160\@sm {
    margin: -160px !important; }
  .mT-nv-160\@sm {
    margin-top: -160px !important; }
  .mR-nv-160\@sm {
    margin-right: -160px !important; }
  .mB-nv-160\@sm {
    margin-bottom: -160px !important; }
  .mL-nv-160\@sm {
    margin-left: -160px !important; }
  .mY-nv-160\@sm {
    margin-top: -160px !important;
    margin-bottom: -160px !important; }
  .mX-nv-160\@sm {
    margin-left: -160px !important;
    margin-right: -160px !important; } }

.m-a {
  margin: auto !important; }

.mX-a {
  margin-left: auto !important;
  margin-right: auto !important; }

.mT-a {
  margin-top: auto !important; }

.mR-a {
  margin-right: auto !important; }

.mB-a {
  margin-bottom: auto !important; }

.mL-a {
  margin-left: auto !important; }

@media screen and (min-width: 1440px) {
  .m-a\@xl\+ {
    margin: auto !important; }
  .mX-a\@xl\+ {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@xl\+ {
    margin-top: auto !important; }
  .mR-a\@xl\+ {
    margin-right: auto !important; }
  .mB-a\@xl\+ {
    margin-bottom: auto !important; }
  .mL-a\@xl\+ {
    margin-left: auto !important; } }

@media screen and (min-width: 1200px) {
  .m-a\@lg\+ {
    margin: auto !important; }
  .mX-a\@lg\+ {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@lg\+ {
    margin-top: auto !important; }
  .mR-a\@lg\+ {
    margin-right: auto !important; }
  .mB-a\@lg\+ {
    margin-bottom: auto !important; }
  .mL-a\@lg\+ {
    margin-left: auto !important; } }

@media screen and (min-width: 992px) {
  .m-a\@md\+ {
    margin: auto !important; }
  .mX-a\@md\+ {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@md\+ {
    margin-top: auto !important; }
  .mR-a\@md\+ {
    margin-right: auto !important; }
  .mB-a\@md\+ {
    margin-bottom: auto !important; }
  .mL-a\@md\+ {
    margin-left: auto !important; } }

@media screen and (min-width: 768px) {
  .m-a\@sm\+ {
    margin: auto !important; }
  .mX-a\@sm\+ {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@sm\+ {
    margin-top: auto !important; }
  .mR-a\@sm\+ {
    margin-right: auto !important; }
  .mB-a\@sm\+ {
    margin-bottom: auto !important; }
  .mL-a\@sm\+ {
    margin-left: auto !important; } }

@media screen and (min-width: 0) {
  .m-a\@xs\+ {
    margin: auto !important; }
  .mX-a\@xs\+ {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@xs\+ {
    margin-top: auto !important; }
  .mR-a\@xs\+ {
    margin-right: auto !important; }
  .mB-a\@xs\+ {
    margin-bottom: auto !important; }
  .mL-a\@xs\+ {
    margin-left: auto !important; } }

@media screen and (max-width: 1439px) {
  .m-a\@xl- {
    margin: auto !important; }
  .mX-a\@xl- {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@xl- {
    margin-top: auto !important; }
  .mR-a\@xl- {
    margin-right: auto !important; }
  .mB-a\@xl- {
    margin-bottom: auto !important; }
  .mL-a\@xl- {
    margin-left: auto !important; } }

@media screen and (max-width: 1199px) {
  .m-a\@lg- {
    margin: auto !important; }
  .mX-a\@lg- {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@lg- {
    margin-top: auto !important; }
  .mR-a\@lg- {
    margin-right: auto !important; }
  .mB-a\@lg- {
    margin-bottom: auto !important; }
  .mL-a\@lg- {
    margin-left: auto !important; } }

@media screen and (max-width: 991px) {
  .m-a\@md- {
    margin: auto !important; }
  .mX-a\@md- {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@md- {
    margin-top: auto !important; }
  .mR-a\@md- {
    margin-right: auto !important; }
  .mB-a\@md- {
    margin-bottom: auto !important; }
  .mL-a\@md- {
    margin-left: auto !important; } }

@media screen and (max-width: 767px) {
  .m-a\@sm- {
    margin: auto !important; }
  .mX-a\@sm- {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@sm- {
    margin-top: auto !important; }
  .mR-a\@sm- {
    margin-right: auto !important; }
  .mB-a\@sm- {
    margin-bottom: auto !important; }
  .mL-a\@sm- {
    margin-left: auto !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .m-a\@lg {
    margin: auto !important; }
  .mX-a\@lg {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@lg {
    margin-top: auto !important; }
  .mR-a\@lg {
    margin-right: auto !important; }
  .mB-a\@lg {
    margin-bottom: auto !important; }
  .mL-a\@lg {
    margin-left: auto !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .m-a\@md {
    margin: auto !important; }
  .mX-a\@md {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@md {
    margin-top: auto !important; }
  .mR-a\@md {
    margin-right: auto !important; }
  .mB-a\@md {
    margin-bottom: auto !important; }
  .mL-a\@md {
    margin-left: auto !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .m-a\@sm {
    margin: auto !important; }
  .mX-a\@sm {
    margin-left: auto !important;
    margin-right: auto !important; }
  .mT-a\@sm {
    margin-top: auto !important; }
  .mR-a\@sm {
    margin-right: auto !important; }
  .mB-a\@sm {
    margin-bottom: auto !important; }
  .mL-a\@sm {
    margin-left: auto !important; } }

.bgsz-cv {
  background-size: cover; }

.bgsz-ct {
  background-size: contain; }

.bgsz-full {
  background-size: 100% 100%; }

.bgpX-c {
  background-position-x: center; }

.bgpX-t {
  background-position-x: top; }

.bgpX-r {
  background-position-x: right; }

.bgpX-l {
  background-position-x: left; }

.bgpX-b {
  background-position-x: bottom; }

.bgpY-c {
  background-position-y: center; }

.bgpY-t {
  background-position-y: top; }

.bgpY-r {
  background-position-y: right; }

.bgpY-l {
  background-position-y: left; }

.bgpY-b {
  background-position-y: bottom; }

.bgr-n {
  background-repeat: no-repeat; }

.bgr-y {
  background-repeat: repeat-y; }

.bgr-x {
  background-repeat: repeat-x; }

.of-ct {
  object-fit: contain; }

.of-cv {
  object-fit: cover; }

.of-f {
  object-fit: fill; }

.of-n {
  object-fit: none; }

.of-sd {
  object-fit: scale-down; }

.rsz-v {
  resize: vertical; }

.rsz-h {
  resize: horizontal; }

.op-0 {
  opacity: 0; }

.op-10p {
  opacity: 0.1; }

.op-20p {
  opacity: 0.2; }

.op-30p {
  opacity: 0.3; }

.op-40p {
  opacity: 0.4; }

.op-50p {
  opacity: 0.5; }

.op-60p {
  opacity: 0.6; }

.op-70p {
  opacity: 0.7; }

.op-80p {
  opacity: 0.8; }

.op-90p {
  opacity: 0.9; }

.op-100p {
  opacity: 1; }

.cur-na {
  cursor: not-allowed; }

.cur-p {
  cursor: pointer; }

.cur-a {
  cursor: auto; }

.vis-v {
  visibility: visible; }

.vis-h {
  visibility: hidden; }

.p-0 {
  padding: 0px !important; }

.pT-0 {
  padding-top: 0px !important; }

.pR-0 {
  padding-right: 0px !important; }

.pB-0 {
  padding-bottom: 0px !important; }

.pL-0 {
  padding-left: 0px !important; }

.pY-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.pX-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

@media screen and (min-width: 1440px) {
  .p-0\@xl\+ {
    padding: 0px !important; }
  .pT-0\@xl\+ {
    padding-top: 0px !important; }
  .pR-0\@xl\+ {
    padding-right: 0px !important; }
  .pB-0\@xl\+ {
    padding-bottom: 0px !important; }
  .pL-0\@xl\+ {
    padding-left: 0px !important; }
  .pY-0\@xl\+ {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@xl\+ {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 1200px) {
  .p-0\@lg\+ {
    padding: 0px !important; }
  .pT-0\@lg\+ {
    padding-top: 0px !important; }
  .pR-0\@lg\+ {
    padding-right: 0px !important; }
  .pB-0\@lg\+ {
    padding-bottom: 0px !important; }
  .pL-0\@lg\+ {
    padding-left: 0px !important; }
  .pY-0\@lg\+ {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@lg\+ {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 992px) {
  .p-0\@md\+ {
    padding: 0px !important; }
  .pT-0\@md\+ {
    padding-top: 0px !important; }
  .pR-0\@md\+ {
    padding-right: 0px !important; }
  .pB-0\@md\+ {
    padding-bottom: 0px !important; }
  .pL-0\@md\+ {
    padding-left: 0px !important; }
  .pY-0\@md\+ {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@md\+ {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 768px) {
  .p-0\@sm\+ {
    padding: 0px !important; }
  .pT-0\@sm\+ {
    padding-top: 0px !important; }
  .pR-0\@sm\+ {
    padding-right: 0px !important; }
  .pB-0\@sm\+ {
    padding-bottom: 0px !important; }
  .pL-0\@sm\+ {
    padding-left: 0px !important; }
  .pY-0\@sm\+ {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@sm\+ {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 0) {
  .p-0\@xs\+ {
    padding: 0px !important; }
  .pT-0\@xs\+ {
    padding-top: 0px !important; }
  .pR-0\@xs\+ {
    padding-right: 0px !important; }
  .pB-0\@xs\+ {
    padding-bottom: 0px !important; }
  .pL-0\@xs\+ {
    padding-left: 0px !important; }
  .pY-0\@xs\+ {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@xs\+ {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 1439px) {
  .p-0\@xl- {
    padding: 0px !important; }
  .pT-0\@xl- {
    padding-top: 0px !important; }
  .pR-0\@xl- {
    padding-right: 0px !important; }
  .pB-0\@xl- {
    padding-bottom: 0px !important; }
  .pL-0\@xl- {
    padding-left: 0px !important; }
  .pY-0\@xl- {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@xl- {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 1199px) {
  .p-0\@lg- {
    padding: 0px !important; }
  .pT-0\@lg- {
    padding-top: 0px !important; }
  .pR-0\@lg- {
    padding-right: 0px !important; }
  .pB-0\@lg- {
    padding-bottom: 0px !important; }
  .pL-0\@lg- {
    padding-left: 0px !important; }
  .pY-0\@lg- {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@lg- {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 991px) {
  .p-0\@md- {
    padding: 0px !important; }
  .pT-0\@md- {
    padding-top: 0px !important; }
  .pR-0\@md- {
    padding-right: 0px !important; }
  .pB-0\@md- {
    padding-bottom: 0px !important; }
  .pL-0\@md- {
    padding-left: 0px !important; }
  .pY-0\@md- {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@md- {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (max-width: 767px) {
  .p-0\@sm- {
    padding: 0px !important; }
  .pT-0\@sm- {
    padding-top: 0px !important; }
  .pR-0\@sm- {
    padding-right: 0px !important; }
  .pB-0\@sm- {
    padding-bottom: 0px !important; }
  .pL-0\@sm- {
    padding-left: 0px !important; }
  .pY-0\@sm- {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@sm- {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-0\@lg {
    padding: 0px !important; }
  .pT-0\@lg {
    padding-top: 0px !important; }
  .pR-0\@lg {
    padding-right: 0px !important; }
  .pB-0\@lg {
    padding-bottom: 0px !important; }
  .pL-0\@lg {
    padding-left: 0px !important; }
  .pY-0\@lg {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@lg {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-0\@md {
    padding: 0px !important; }
  .pT-0\@md {
    padding-top: 0px !important; }
  .pR-0\@md {
    padding-right: 0px !important; }
  .pB-0\@md {
    padding-bottom: 0px !important; }
  .pL-0\@md {
    padding-left: 0px !important; }
  .pY-0\@md {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@md {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-0\@sm {
    padding: 0px !important; }
  .pT-0\@sm {
    padding-top: 0px !important; }
  .pR-0\@sm {
    padding-right: 0px !important; }
  .pB-0\@sm {
    padding-bottom: 0px !important; }
  .pL-0\@sm {
    padding-left: 0px !important; }
  .pY-0\@sm {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .pX-0\@sm {
    padding-left: 0px !important;
    padding-right: 0px !important; } }

.p-1 {
  padding: 1px !important; }

.pT-1 {
  padding-top: 1px !important; }

.pR-1 {
  padding-right: 1px !important; }

.pB-1 {
  padding-bottom: 1px !important; }

.pL-1 {
  padding-left: 1px !important; }

.pY-1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }

.pX-1 {
  padding-left: 1px !important;
  padding-right: 1px !important; }

@media screen and (min-width: 1440px) {
  .p-1\@xl\+ {
    padding: 1px !important; }
  .pT-1\@xl\+ {
    padding-top: 1px !important; }
  .pR-1\@xl\+ {
    padding-right: 1px !important; }
  .pB-1\@xl\+ {
    padding-bottom: 1px !important; }
  .pL-1\@xl\+ {
    padding-left: 1px !important; }
  .pY-1\@xl\+ {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@xl\+ {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 1200px) {
  .p-1\@lg\+ {
    padding: 1px !important; }
  .pT-1\@lg\+ {
    padding-top: 1px !important; }
  .pR-1\@lg\+ {
    padding-right: 1px !important; }
  .pB-1\@lg\+ {
    padding-bottom: 1px !important; }
  .pL-1\@lg\+ {
    padding-left: 1px !important; }
  .pY-1\@lg\+ {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@lg\+ {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 992px) {
  .p-1\@md\+ {
    padding: 1px !important; }
  .pT-1\@md\+ {
    padding-top: 1px !important; }
  .pR-1\@md\+ {
    padding-right: 1px !important; }
  .pB-1\@md\+ {
    padding-bottom: 1px !important; }
  .pL-1\@md\+ {
    padding-left: 1px !important; }
  .pY-1\@md\+ {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@md\+ {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 768px) {
  .p-1\@sm\+ {
    padding: 1px !important; }
  .pT-1\@sm\+ {
    padding-top: 1px !important; }
  .pR-1\@sm\+ {
    padding-right: 1px !important; }
  .pB-1\@sm\+ {
    padding-bottom: 1px !important; }
  .pL-1\@sm\+ {
    padding-left: 1px !important; }
  .pY-1\@sm\+ {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@sm\+ {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 0) {
  .p-1\@xs\+ {
    padding: 1px !important; }
  .pT-1\@xs\+ {
    padding-top: 1px !important; }
  .pR-1\@xs\+ {
    padding-right: 1px !important; }
  .pB-1\@xs\+ {
    padding-bottom: 1px !important; }
  .pL-1\@xs\+ {
    padding-left: 1px !important; }
  .pY-1\@xs\+ {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@xs\+ {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (max-width: 1439px) {
  .p-1\@xl- {
    padding: 1px !important; }
  .pT-1\@xl- {
    padding-top: 1px !important; }
  .pR-1\@xl- {
    padding-right: 1px !important; }
  .pB-1\@xl- {
    padding-bottom: 1px !important; }
  .pL-1\@xl- {
    padding-left: 1px !important; }
  .pY-1\@xl- {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@xl- {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (max-width: 1199px) {
  .p-1\@lg- {
    padding: 1px !important; }
  .pT-1\@lg- {
    padding-top: 1px !important; }
  .pR-1\@lg- {
    padding-right: 1px !important; }
  .pB-1\@lg- {
    padding-bottom: 1px !important; }
  .pL-1\@lg- {
    padding-left: 1px !important; }
  .pY-1\@lg- {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@lg- {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (max-width: 991px) {
  .p-1\@md- {
    padding: 1px !important; }
  .pT-1\@md- {
    padding-top: 1px !important; }
  .pR-1\@md- {
    padding-right: 1px !important; }
  .pB-1\@md- {
    padding-bottom: 1px !important; }
  .pL-1\@md- {
    padding-left: 1px !important; }
  .pY-1\@md- {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@md- {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (max-width: 767px) {
  .p-1\@sm- {
    padding: 1px !important; }
  .pT-1\@sm- {
    padding-top: 1px !important; }
  .pR-1\@sm- {
    padding-right: 1px !important; }
  .pB-1\@sm- {
    padding-bottom: 1px !important; }
  .pL-1\@sm- {
    padding-left: 1px !important; }
  .pY-1\@sm- {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@sm- {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-1\@lg {
    padding: 1px !important; }
  .pT-1\@lg {
    padding-top: 1px !important; }
  .pR-1\@lg {
    padding-right: 1px !important; }
  .pB-1\@lg {
    padding-bottom: 1px !important; }
  .pL-1\@lg {
    padding-left: 1px !important; }
  .pY-1\@lg {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@lg {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-1\@md {
    padding: 1px !important; }
  .pT-1\@md {
    padding-top: 1px !important; }
  .pR-1\@md {
    padding-right: 1px !important; }
  .pB-1\@md {
    padding-bottom: 1px !important; }
  .pL-1\@md {
    padding-left: 1px !important; }
  .pY-1\@md {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@md {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-1\@sm {
    padding: 1px !important; }
  .pT-1\@sm {
    padding-top: 1px !important; }
  .pR-1\@sm {
    padding-right: 1px !important; }
  .pB-1\@sm {
    padding-bottom: 1px !important; }
  .pL-1\@sm {
    padding-left: 1px !important; }
  .pY-1\@sm {
    padding-top: 1px !important;
    padding-bottom: 1px !important; }
  .pX-1\@sm {
    padding-left: 1px !important;
    padding-right: 1px !important; } }

.p-2 {
  padding: 2px !important; }

.pT-2 {
  padding-top: 2px !important; }

.pR-2 {
  padding-right: 2px !important; }

.pB-2 {
  padding-bottom: 2px !important; }

.pL-2 {
  padding-left: 2px !important; }

.pY-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }

.pX-2 {
  padding-left: 2px !important;
  padding-right: 2px !important; }

@media screen and (min-width: 1440px) {
  .p-2\@xl\+ {
    padding: 2px !important; }
  .pT-2\@xl\+ {
    padding-top: 2px !important; }
  .pR-2\@xl\+ {
    padding-right: 2px !important; }
  .pB-2\@xl\+ {
    padding-bottom: 2px !important; }
  .pL-2\@xl\+ {
    padding-left: 2px !important; }
  .pY-2\@xl\+ {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@xl\+ {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 1200px) {
  .p-2\@lg\+ {
    padding: 2px !important; }
  .pT-2\@lg\+ {
    padding-top: 2px !important; }
  .pR-2\@lg\+ {
    padding-right: 2px !important; }
  .pB-2\@lg\+ {
    padding-bottom: 2px !important; }
  .pL-2\@lg\+ {
    padding-left: 2px !important; }
  .pY-2\@lg\+ {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@lg\+ {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 992px) {
  .p-2\@md\+ {
    padding: 2px !important; }
  .pT-2\@md\+ {
    padding-top: 2px !important; }
  .pR-2\@md\+ {
    padding-right: 2px !important; }
  .pB-2\@md\+ {
    padding-bottom: 2px !important; }
  .pL-2\@md\+ {
    padding-left: 2px !important; }
  .pY-2\@md\+ {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@md\+ {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 768px) {
  .p-2\@sm\+ {
    padding: 2px !important; }
  .pT-2\@sm\+ {
    padding-top: 2px !important; }
  .pR-2\@sm\+ {
    padding-right: 2px !important; }
  .pB-2\@sm\+ {
    padding-bottom: 2px !important; }
  .pL-2\@sm\+ {
    padding-left: 2px !important; }
  .pY-2\@sm\+ {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@sm\+ {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 0) {
  .p-2\@xs\+ {
    padding: 2px !important; }
  .pT-2\@xs\+ {
    padding-top: 2px !important; }
  .pR-2\@xs\+ {
    padding-right: 2px !important; }
  .pB-2\@xs\+ {
    padding-bottom: 2px !important; }
  .pL-2\@xs\+ {
    padding-left: 2px !important; }
  .pY-2\@xs\+ {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@xs\+ {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (max-width: 1439px) {
  .p-2\@xl- {
    padding: 2px !important; }
  .pT-2\@xl- {
    padding-top: 2px !important; }
  .pR-2\@xl- {
    padding-right: 2px !important; }
  .pB-2\@xl- {
    padding-bottom: 2px !important; }
  .pL-2\@xl- {
    padding-left: 2px !important; }
  .pY-2\@xl- {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@xl- {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (max-width: 1199px) {
  .p-2\@lg- {
    padding: 2px !important; }
  .pT-2\@lg- {
    padding-top: 2px !important; }
  .pR-2\@lg- {
    padding-right: 2px !important; }
  .pB-2\@lg- {
    padding-bottom: 2px !important; }
  .pL-2\@lg- {
    padding-left: 2px !important; }
  .pY-2\@lg- {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@lg- {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (max-width: 991px) {
  .p-2\@md- {
    padding: 2px !important; }
  .pT-2\@md- {
    padding-top: 2px !important; }
  .pR-2\@md- {
    padding-right: 2px !important; }
  .pB-2\@md- {
    padding-bottom: 2px !important; }
  .pL-2\@md- {
    padding-left: 2px !important; }
  .pY-2\@md- {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@md- {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (max-width: 767px) {
  .p-2\@sm- {
    padding: 2px !important; }
  .pT-2\@sm- {
    padding-top: 2px !important; }
  .pR-2\@sm- {
    padding-right: 2px !important; }
  .pB-2\@sm- {
    padding-bottom: 2px !important; }
  .pL-2\@sm- {
    padding-left: 2px !important; }
  .pY-2\@sm- {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@sm- {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-2\@lg {
    padding: 2px !important; }
  .pT-2\@lg {
    padding-top: 2px !important; }
  .pR-2\@lg {
    padding-right: 2px !important; }
  .pB-2\@lg {
    padding-bottom: 2px !important; }
  .pL-2\@lg {
    padding-left: 2px !important; }
  .pY-2\@lg {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@lg {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-2\@md {
    padding: 2px !important; }
  .pT-2\@md {
    padding-top: 2px !important; }
  .pR-2\@md {
    padding-right: 2px !important; }
  .pB-2\@md {
    padding-bottom: 2px !important; }
  .pL-2\@md {
    padding-left: 2px !important; }
  .pY-2\@md {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@md {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-2\@sm {
    padding: 2px !important; }
  .pT-2\@sm {
    padding-top: 2px !important; }
  .pR-2\@sm {
    padding-right: 2px !important; }
  .pB-2\@sm {
    padding-bottom: 2px !important; }
  .pL-2\@sm {
    padding-left: 2px !important; }
  .pY-2\@sm {
    padding-top: 2px !important;
    padding-bottom: 2px !important; }
  .pX-2\@sm {
    padding-left: 2px !important;
    padding-right: 2px !important; } }

.p-3 {
  padding: 3px !important; }

.pT-3 {
  padding-top: 3px !important; }

.pR-3 {
  padding-right: 3px !important; }

.pB-3 {
  padding-bottom: 3px !important; }

.pL-3 {
  padding-left: 3px !important; }

.pY-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important; }

.pX-3 {
  padding-left: 3px !important;
  padding-right: 3px !important; }

@media screen and (min-width: 1440px) {
  .p-3\@xl\+ {
    padding: 3px !important; }
  .pT-3\@xl\+ {
    padding-top: 3px !important; }
  .pR-3\@xl\+ {
    padding-right: 3px !important; }
  .pB-3\@xl\+ {
    padding-bottom: 3px !important; }
  .pL-3\@xl\+ {
    padding-left: 3px !important; }
  .pY-3\@xl\+ {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@xl\+ {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 1200px) {
  .p-3\@lg\+ {
    padding: 3px !important; }
  .pT-3\@lg\+ {
    padding-top: 3px !important; }
  .pR-3\@lg\+ {
    padding-right: 3px !important; }
  .pB-3\@lg\+ {
    padding-bottom: 3px !important; }
  .pL-3\@lg\+ {
    padding-left: 3px !important; }
  .pY-3\@lg\+ {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@lg\+ {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 992px) {
  .p-3\@md\+ {
    padding: 3px !important; }
  .pT-3\@md\+ {
    padding-top: 3px !important; }
  .pR-3\@md\+ {
    padding-right: 3px !important; }
  .pB-3\@md\+ {
    padding-bottom: 3px !important; }
  .pL-3\@md\+ {
    padding-left: 3px !important; }
  .pY-3\@md\+ {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@md\+ {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 768px) {
  .p-3\@sm\+ {
    padding: 3px !important; }
  .pT-3\@sm\+ {
    padding-top: 3px !important; }
  .pR-3\@sm\+ {
    padding-right: 3px !important; }
  .pB-3\@sm\+ {
    padding-bottom: 3px !important; }
  .pL-3\@sm\+ {
    padding-left: 3px !important; }
  .pY-3\@sm\+ {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@sm\+ {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 0) {
  .p-3\@xs\+ {
    padding: 3px !important; }
  .pT-3\@xs\+ {
    padding-top: 3px !important; }
  .pR-3\@xs\+ {
    padding-right: 3px !important; }
  .pB-3\@xs\+ {
    padding-bottom: 3px !important; }
  .pL-3\@xs\+ {
    padding-left: 3px !important; }
  .pY-3\@xs\+ {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@xs\+ {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (max-width: 1439px) {
  .p-3\@xl- {
    padding: 3px !important; }
  .pT-3\@xl- {
    padding-top: 3px !important; }
  .pR-3\@xl- {
    padding-right: 3px !important; }
  .pB-3\@xl- {
    padding-bottom: 3px !important; }
  .pL-3\@xl- {
    padding-left: 3px !important; }
  .pY-3\@xl- {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@xl- {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (max-width: 1199px) {
  .p-3\@lg- {
    padding: 3px !important; }
  .pT-3\@lg- {
    padding-top: 3px !important; }
  .pR-3\@lg- {
    padding-right: 3px !important; }
  .pB-3\@lg- {
    padding-bottom: 3px !important; }
  .pL-3\@lg- {
    padding-left: 3px !important; }
  .pY-3\@lg- {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@lg- {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (max-width: 991px) {
  .p-3\@md- {
    padding: 3px !important; }
  .pT-3\@md- {
    padding-top: 3px !important; }
  .pR-3\@md- {
    padding-right: 3px !important; }
  .pB-3\@md- {
    padding-bottom: 3px !important; }
  .pL-3\@md- {
    padding-left: 3px !important; }
  .pY-3\@md- {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@md- {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (max-width: 767px) {
  .p-3\@sm- {
    padding: 3px !important; }
  .pT-3\@sm- {
    padding-top: 3px !important; }
  .pR-3\@sm- {
    padding-right: 3px !important; }
  .pB-3\@sm- {
    padding-bottom: 3px !important; }
  .pL-3\@sm- {
    padding-left: 3px !important; }
  .pY-3\@sm- {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@sm- {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-3\@lg {
    padding: 3px !important; }
  .pT-3\@lg {
    padding-top: 3px !important; }
  .pR-3\@lg {
    padding-right: 3px !important; }
  .pB-3\@lg {
    padding-bottom: 3px !important; }
  .pL-3\@lg {
    padding-left: 3px !important; }
  .pY-3\@lg {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@lg {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-3\@md {
    padding: 3px !important; }
  .pT-3\@md {
    padding-top: 3px !important; }
  .pR-3\@md {
    padding-right: 3px !important; }
  .pB-3\@md {
    padding-bottom: 3px !important; }
  .pL-3\@md {
    padding-left: 3px !important; }
  .pY-3\@md {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@md {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-3\@sm {
    padding: 3px !important; }
  .pT-3\@sm {
    padding-top: 3px !important; }
  .pR-3\@sm {
    padding-right: 3px !important; }
  .pB-3\@sm {
    padding-bottom: 3px !important; }
  .pL-3\@sm {
    padding-left: 3px !important; }
  .pY-3\@sm {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .pX-3\@sm {
    padding-left: 3px !important;
    padding-right: 3px !important; } }

.p-4 {
  padding: 4px !important; }

.pT-4 {
  padding-top: 4px !important; }

.pR-4 {
  padding-right: 4px !important; }

.pB-4 {
  padding-bottom: 4px !important; }

.pL-4 {
  padding-left: 4px !important; }

.pY-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.pX-4 {
  padding-left: 4px !important;
  padding-right: 4px !important; }

@media screen and (min-width: 1440px) {
  .p-4\@xl\+ {
    padding: 4px !important; }
  .pT-4\@xl\+ {
    padding-top: 4px !important; }
  .pR-4\@xl\+ {
    padding-right: 4px !important; }
  .pB-4\@xl\+ {
    padding-bottom: 4px !important; }
  .pL-4\@xl\+ {
    padding-left: 4px !important; }
  .pY-4\@xl\+ {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@xl\+ {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 1200px) {
  .p-4\@lg\+ {
    padding: 4px !important; }
  .pT-4\@lg\+ {
    padding-top: 4px !important; }
  .pR-4\@lg\+ {
    padding-right: 4px !important; }
  .pB-4\@lg\+ {
    padding-bottom: 4px !important; }
  .pL-4\@lg\+ {
    padding-left: 4px !important; }
  .pY-4\@lg\+ {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@lg\+ {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 992px) {
  .p-4\@md\+ {
    padding: 4px !important; }
  .pT-4\@md\+ {
    padding-top: 4px !important; }
  .pR-4\@md\+ {
    padding-right: 4px !important; }
  .pB-4\@md\+ {
    padding-bottom: 4px !important; }
  .pL-4\@md\+ {
    padding-left: 4px !important; }
  .pY-4\@md\+ {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@md\+ {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 768px) {
  .p-4\@sm\+ {
    padding: 4px !important; }
  .pT-4\@sm\+ {
    padding-top: 4px !important; }
  .pR-4\@sm\+ {
    padding-right: 4px !important; }
  .pB-4\@sm\+ {
    padding-bottom: 4px !important; }
  .pL-4\@sm\+ {
    padding-left: 4px !important; }
  .pY-4\@sm\+ {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@sm\+ {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 0) {
  .p-4\@xs\+ {
    padding: 4px !important; }
  .pT-4\@xs\+ {
    padding-top: 4px !important; }
  .pR-4\@xs\+ {
    padding-right: 4px !important; }
  .pB-4\@xs\+ {
    padding-bottom: 4px !important; }
  .pL-4\@xs\+ {
    padding-left: 4px !important; }
  .pY-4\@xs\+ {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@xs\+ {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (max-width: 1439px) {
  .p-4\@xl- {
    padding: 4px !important; }
  .pT-4\@xl- {
    padding-top: 4px !important; }
  .pR-4\@xl- {
    padding-right: 4px !important; }
  .pB-4\@xl- {
    padding-bottom: 4px !important; }
  .pL-4\@xl- {
    padding-left: 4px !important; }
  .pY-4\@xl- {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@xl- {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (max-width: 1199px) {
  .p-4\@lg- {
    padding: 4px !important; }
  .pT-4\@lg- {
    padding-top: 4px !important; }
  .pR-4\@lg- {
    padding-right: 4px !important; }
  .pB-4\@lg- {
    padding-bottom: 4px !important; }
  .pL-4\@lg- {
    padding-left: 4px !important; }
  .pY-4\@lg- {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@lg- {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (max-width: 991px) {
  .p-4\@md- {
    padding: 4px !important; }
  .pT-4\@md- {
    padding-top: 4px !important; }
  .pR-4\@md- {
    padding-right: 4px !important; }
  .pB-4\@md- {
    padding-bottom: 4px !important; }
  .pL-4\@md- {
    padding-left: 4px !important; }
  .pY-4\@md- {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@md- {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (max-width: 767px) {
  .p-4\@sm- {
    padding: 4px !important; }
  .pT-4\@sm- {
    padding-top: 4px !important; }
  .pR-4\@sm- {
    padding-right: 4px !important; }
  .pB-4\@sm- {
    padding-bottom: 4px !important; }
  .pL-4\@sm- {
    padding-left: 4px !important; }
  .pY-4\@sm- {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@sm- {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-4\@lg {
    padding: 4px !important; }
  .pT-4\@lg {
    padding-top: 4px !important; }
  .pR-4\@lg {
    padding-right: 4px !important; }
  .pB-4\@lg {
    padding-bottom: 4px !important; }
  .pL-4\@lg {
    padding-left: 4px !important; }
  .pY-4\@lg {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@lg {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-4\@md {
    padding: 4px !important; }
  .pT-4\@md {
    padding-top: 4px !important; }
  .pR-4\@md {
    padding-right: 4px !important; }
  .pB-4\@md {
    padding-bottom: 4px !important; }
  .pL-4\@md {
    padding-left: 4px !important; }
  .pY-4\@md {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@md {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-4\@sm {
    padding: 4px !important; }
  .pT-4\@sm {
    padding-top: 4px !important; }
  .pR-4\@sm {
    padding-right: 4px !important; }
  .pB-4\@sm {
    padding-bottom: 4px !important; }
  .pL-4\@sm {
    padding-left: 4px !important; }
  .pY-4\@sm {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .pX-4\@sm {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

.p-5 {
  padding: 5px !important; }

.pT-5 {
  padding-top: 5px !important; }

.pR-5 {
  padding-right: 5px !important; }

.pB-5 {
  padding-bottom: 5px !important; }

.pL-5 {
  padding-left: 5px !important; }

.pY-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pX-5 {
  padding-left: 5px !important;
  padding-right: 5px !important; }

@media screen and (min-width: 1440px) {
  .p-5\@xl\+ {
    padding: 5px !important; }
  .pT-5\@xl\+ {
    padding-top: 5px !important; }
  .pR-5\@xl\+ {
    padding-right: 5px !important; }
  .pB-5\@xl\+ {
    padding-bottom: 5px !important; }
  .pL-5\@xl\+ {
    padding-left: 5px !important; }
  .pY-5\@xl\+ {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@xl\+ {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 1200px) {
  .p-5\@lg\+ {
    padding: 5px !important; }
  .pT-5\@lg\+ {
    padding-top: 5px !important; }
  .pR-5\@lg\+ {
    padding-right: 5px !important; }
  .pB-5\@lg\+ {
    padding-bottom: 5px !important; }
  .pL-5\@lg\+ {
    padding-left: 5px !important; }
  .pY-5\@lg\+ {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@lg\+ {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 992px) {
  .p-5\@md\+ {
    padding: 5px !important; }
  .pT-5\@md\+ {
    padding-top: 5px !important; }
  .pR-5\@md\+ {
    padding-right: 5px !important; }
  .pB-5\@md\+ {
    padding-bottom: 5px !important; }
  .pL-5\@md\+ {
    padding-left: 5px !important; }
  .pY-5\@md\+ {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@md\+ {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 768px) {
  .p-5\@sm\+ {
    padding: 5px !important; }
  .pT-5\@sm\+ {
    padding-top: 5px !important; }
  .pR-5\@sm\+ {
    padding-right: 5px !important; }
  .pB-5\@sm\+ {
    padding-bottom: 5px !important; }
  .pL-5\@sm\+ {
    padding-left: 5px !important; }
  .pY-5\@sm\+ {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@sm\+ {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 0) {
  .p-5\@xs\+ {
    padding: 5px !important; }
  .pT-5\@xs\+ {
    padding-top: 5px !important; }
  .pR-5\@xs\+ {
    padding-right: 5px !important; }
  .pB-5\@xs\+ {
    padding-bottom: 5px !important; }
  .pL-5\@xs\+ {
    padding-left: 5px !important; }
  .pY-5\@xs\+ {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@xs\+ {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (max-width: 1439px) {
  .p-5\@xl- {
    padding: 5px !important; }
  .pT-5\@xl- {
    padding-top: 5px !important; }
  .pR-5\@xl- {
    padding-right: 5px !important; }
  .pB-5\@xl- {
    padding-bottom: 5px !important; }
  .pL-5\@xl- {
    padding-left: 5px !important; }
  .pY-5\@xl- {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@xl- {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (max-width: 1199px) {
  .p-5\@lg- {
    padding: 5px !important; }
  .pT-5\@lg- {
    padding-top: 5px !important; }
  .pR-5\@lg- {
    padding-right: 5px !important; }
  .pB-5\@lg- {
    padding-bottom: 5px !important; }
  .pL-5\@lg- {
    padding-left: 5px !important; }
  .pY-5\@lg- {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@lg- {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (max-width: 991px) {
  .p-5\@md- {
    padding: 5px !important; }
  .pT-5\@md- {
    padding-top: 5px !important; }
  .pR-5\@md- {
    padding-right: 5px !important; }
  .pB-5\@md- {
    padding-bottom: 5px !important; }
  .pL-5\@md- {
    padding-left: 5px !important; }
  .pY-5\@md- {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@md- {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (max-width: 767px) {
  .p-5\@sm- {
    padding: 5px !important; }
  .pT-5\@sm- {
    padding-top: 5px !important; }
  .pR-5\@sm- {
    padding-right: 5px !important; }
  .pB-5\@sm- {
    padding-bottom: 5px !important; }
  .pL-5\@sm- {
    padding-left: 5px !important; }
  .pY-5\@sm- {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@sm- {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-5\@lg {
    padding: 5px !important; }
  .pT-5\@lg {
    padding-top: 5px !important; }
  .pR-5\@lg {
    padding-right: 5px !important; }
  .pB-5\@lg {
    padding-bottom: 5px !important; }
  .pL-5\@lg {
    padding-left: 5px !important; }
  .pY-5\@lg {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@lg {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-5\@md {
    padding: 5px !important; }
  .pT-5\@md {
    padding-top: 5px !important; }
  .pR-5\@md {
    padding-right: 5px !important; }
  .pB-5\@md {
    padding-bottom: 5px !important; }
  .pL-5\@md {
    padding-left: 5px !important; }
  .pY-5\@md {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@md {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-5\@sm {
    padding: 5px !important; }
  .pT-5\@sm {
    padding-top: 5px !important; }
  .pR-5\@sm {
    padding-right: 5px !important; }
  .pB-5\@sm {
    padding-bottom: 5px !important; }
  .pL-5\@sm {
    padding-left: 5px !important; }
  .pY-5\@sm {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .pX-5\@sm {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

.p-10 {
  padding: 10px !important; }

.pT-10 {
  padding-top: 10px !important; }

.pR-10 {
  padding-right: 10px !important; }

.pB-10 {
  padding-bottom: 10px !important; }

.pL-10 {
  padding-left: 10px !important; }

.pY-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.pX-10 {
  padding-left: 10px !important;
  padding-right: 10px !important; }

@media screen and (min-width: 1440px) {
  .p-10\@xl\+ {
    padding: 10px !important; }
  .pT-10\@xl\+ {
    padding-top: 10px !important; }
  .pR-10\@xl\+ {
    padding-right: 10px !important; }
  .pB-10\@xl\+ {
    padding-bottom: 10px !important; }
  .pL-10\@xl\+ {
    padding-left: 10px !important; }
  .pY-10\@xl\+ {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@xl\+ {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 1200px) {
  .p-10\@lg\+ {
    padding: 10px !important; }
  .pT-10\@lg\+ {
    padding-top: 10px !important; }
  .pR-10\@lg\+ {
    padding-right: 10px !important; }
  .pB-10\@lg\+ {
    padding-bottom: 10px !important; }
  .pL-10\@lg\+ {
    padding-left: 10px !important; }
  .pY-10\@lg\+ {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@lg\+ {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 992px) {
  .p-10\@md\+ {
    padding: 10px !important; }
  .pT-10\@md\+ {
    padding-top: 10px !important; }
  .pR-10\@md\+ {
    padding-right: 10px !important; }
  .pB-10\@md\+ {
    padding-bottom: 10px !important; }
  .pL-10\@md\+ {
    padding-left: 10px !important; }
  .pY-10\@md\+ {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@md\+ {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 768px) {
  .p-10\@sm\+ {
    padding: 10px !important; }
  .pT-10\@sm\+ {
    padding-top: 10px !important; }
  .pR-10\@sm\+ {
    padding-right: 10px !important; }
  .pB-10\@sm\+ {
    padding-bottom: 10px !important; }
  .pL-10\@sm\+ {
    padding-left: 10px !important; }
  .pY-10\@sm\+ {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@sm\+ {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 0) {
  .p-10\@xs\+ {
    padding: 10px !important; }
  .pT-10\@xs\+ {
    padding-top: 10px !important; }
  .pR-10\@xs\+ {
    padding-right: 10px !important; }
  .pB-10\@xs\+ {
    padding-bottom: 10px !important; }
  .pL-10\@xs\+ {
    padding-left: 10px !important; }
  .pY-10\@xs\+ {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@xs\+ {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 1439px) {
  .p-10\@xl- {
    padding: 10px !important; }
  .pT-10\@xl- {
    padding-top: 10px !important; }
  .pR-10\@xl- {
    padding-right: 10px !important; }
  .pB-10\@xl- {
    padding-bottom: 10px !important; }
  .pL-10\@xl- {
    padding-left: 10px !important; }
  .pY-10\@xl- {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@xl- {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 1199px) {
  .p-10\@lg- {
    padding: 10px !important; }
  .pT-10\@lg- {
    padding-top: 10px !important; }
  .pR-10\@lg- {
    padding-right: 10px !important; }
  .pB-10\@lg- {
    padding-bottom: 10px !important; }
  .pL-10\@lg- {
    padding-left: 10px !important; }
  .pY-10\@lg- {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@lg- {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 991px) {
  .p-10\@md- {
    padding: 10px !important; }
  .pT-10\@md- {
    padding-top: 10px !important; }
  .pR-10\@md- {
    padding-right: 10px !important; }
  .pB-10\@md- {
    padding-bottom: 10px !important; }
  .pL-10\@md- {
    padding-left: 10px !important; }
  .pY-10\@md- {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@md- {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (max-width: 767px) {
  .p-10\@sm- {
    padding: 10px !important; }
  .pT-10\@sm- {
    padding-top: 10px !important; }
  .pR-10\@sm- {
    padding-right: 10px !important; }
  .pB-10\@sm- {
    padding-bottom: 10px !important; }
  .pL-10\@sm- {
    padding-left: 10px !important; }
  .pY-10\@sm- {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@sm- {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-10\@lg {
    padding: 10px !important; }
  .pT-10\@lg {
    padding-top: 10px !important; }
  .pR-10\@lg {
    padding-right: 10px !important; }
  .pB-10\@lg {
    padding-bottom: 10px !important; }
  .pL-10\@lg {
    padding-left: 10px !important; }
  .pY-10\@lg {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@lg {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-10\@md {
    padding: 10px !important; }
  .pT-10\@md {
    padding-top: 10px !important; }
  .pR-10\@md {
    padding-right: 10px !important; }
  .pB-10\@md {
    padding-bottom: 10px !important; }
  .pL-10\@md {
    padding-left: 10px !important; }
  .pY-10\@md {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@md {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-10\@sm {
    padding: 10px !important; }
  .pT-10\@sm {
    padding-top: 10px !important; }
  .pR-10\@sm {
    padding-right: 10px !important; }
  .pB-10\@sm {
    padding-bottom: 10px !important; }
  .pL-10\@sm {
    padding-left: 10px !important; }
  .pY-10\@sm {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .pX-10\@sm {
    padding-left: 10px !important;
    padding-right: 10px !important; } }

.p-15 {
  padding: 15px !important; }

.pT-15 {
  padding-top: 15px !important; }

.pR-15 {
  padding-right: 15px !important; }

.pB-15 {
  padding-bottom: 15px !important; }

.pL-15 {
  padding-left: 15px !important; }

.pY-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pX-15 {
  padding-left: 15px !important;
  padding-right: 15px !important; }

@media screen and (min-width: 1440px) {
  .p-15\@xl\+ {
    padding: 15px !important; }
  .pT-15\@xl\+ {
    padding-top: 15px !important; }
  .pR-15\@xl\+ {
    padding-right: 15px !important; }
  .pB-15\@xl\+ {
    padding-bottom: 15px !important; }
  .pL-15\@xl\+ {
    padding-left: 15px !important; }
  .pY-15\@xl\+ {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@xl\+ {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 1200px) {
  .p-15\@lg\+ {
    padding: 15px !important; }
  .pT-15\@lg\+ {
    padding-top: 15px !important; }
  .pR-15\@lg\+ {
    padding-right: 15px !important; }
  .pB-15\@lg\+ {
    padding-bottom: 15px !important; }
  .pL-15\@lg\+ {
    padding-left: 15px !important; }
  .pY-15\@lg\+ {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@lg\+ {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 992px) {
  .p-15\@md\+ {
    padding: 15px !important; }
  .pT-15\@md\+ {
    padding-top: 15px !important; }
  .pR-15\@md\+ {
    padding-right: 15px !important; }
  .pB-15\@md\+ {
    padding-bottom: 15px !important; }
  .pL-15\@md\+ {
    padding-left: 15px !important; }
  .pY-15\@md\+ {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@md\+ {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 768px) {
  .p-15\@sm\+ {
    padding: 15px !important; }
  .pT-15\@sm\+ {
    padding-top: 15px !important; }
  .pR-15\@sm\+ {
    padding-right: 15px !important; }
  .pB-15\@sm\+ {
    padding-bottom: 15px !important; }
  .pL-15\@sm\+ {
    padding-left: 15px !important; }
  .pY-15\@sm\+ {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@sm\+ {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 0) {
  .p-15\@xs\+ {
    padding: 15px !important; }
  .pT-15\@xs\+ {
    padding-top: 15px !important; }
  .pR-15\@xs\+ {
    padding-right: 15px !important; }
  .pB-15\@xs\+ {
    padding-bottom: 15px !important; }
  .pL-15\@xs\+ {
    padding-left: 15px !important; }
  .pY-15\@xs\+ {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@xs\+ {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (max-width: 1439px) {
  .p-15\@xl- {
    padding: 15px !important; }
  .pT-15\@xl- {
    padding-top: 15px !important; }
  .pR-15\@xl- {
    padding-right: 15px !important; }
  .pB-15\@xl- {
    padding-bottom: 15px !important; }
  .pL-15\@xl- {
    padding-left: 15px !important; }
  .pY-15\@xl- {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@xl- {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (max-width: 1199px) {
  .p-15\@lg- {
    padding: 15px !important; }
  .pT-15\@lg- {
    padding-top: 15px !important; }
  .pR-15\@lg- {
    padding-right: 15px !important; }
  .pB-15\@lg- {
    padding-bottom: 15px !important; }
  .pL-15\@lg- {
    padding-left: 15px !important; }
  .pY-15\@lg- {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@lg- {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (max-width: 991px) {
  .p-15\@md- {
    padding: 15px !important; }
  .pT-15\@md- {
    padding-top: 15px !important; }
  .pR-15\@md- {
    padding-right: 15px !important; }
  .pB-15\@md- {
    padding-bottom: 15px !important; }
  .pL-15\@md- {
    padding-left: 15px !important; }
  .pY-15\@md- {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@md- {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (max-width: 767px) {
  .p-15\@sm- {
    padding: 15px !important; }
  .pT-15\@sm- {
    padding-top: 15px !important; }
  .pR-15\@sm- {
    padding-right: 15px !important; }
  .pB-15\@sm- {
    padding-bottom: 15px !important; }
  .pL-15\@sm- {
    padding-left: 15px !important; }
  .pY-15\@sm- {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@sm- {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-15\@lg {
    padding: 15px !important; }
  .pT-15\@lg {
    padding-top: 15px !important; }
  .pR-15\@lg {
    padding-right: 15px !important; }
  .pB-15\@lg {
    padding-bottom: 15px !important; }
  .pL-15\@lg {
    padding-left: 15px !important; }
  .pY-15\@lg {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@lg {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-15\@md {
    padding: 15px !important; }
  .pT-15\@md {
    padding-top: 15px !important; }
  .pR-15\@md {
    padding-right: 15px !important; }
  .pB-15\@md {
    padding-bottom: 15px !important; }
  .pL-15\@md {
    padding-left: 15px !important; }
  .pY-15\@md {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@md {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-15\@sm {
    padding: 15px !important; }
  .pT-15\@sm {
    padding-top: 15px !important; }
  .pR-15\@sm {
    padding-right: 15px !important; }
  .pB-15\@sm {
    padding-bottom: 15px !important; }
  .pL-15\@sm {
    padding-left: 15px !important; }
  .pY-15\@sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .pX-15\@sm {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

.p-20 {
  padding: 20px !important; }

.pT-20 {
  padding-top: 20px !important; }

.pR-20 {
  padding-right: 20px !important; }

.pB-20 {
  padding-bottom: 20px !important; }

.pL-20 {
  padding-left: 20px !important; }

.pY-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.pX-20 {
  padding-left: 20px !important;
  padding-right: 20px !important; }

@media screen and (min-width: 1440px) {
  .p-20\@xl\+ {
    padding: 20px !important; }
  .pT-20\@xl\+ {
    padding-top: 20px !important; }
  .pR-20\@xl\+ {
    padding-right: 20px !important; }
  .pB-20\@xl\+ {
    padding-bottom: 20px !important; }
  .pL-20\@xl\+ {
    padding-left: 20px !important; }
  .pY-20\@xl\+ {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@xl\+ {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 1200px) {
  .p-20\@lg\+ {
    padding: 20px !important; }
  .pT-20\@lg\+ {
    padding-top: 20px !important; }
  .pR-20\@lg\+ {
    padding-right: 20px !important; }
  .pB-20\@lg\+ {
    padding-bottom: 20px !important; }
  .pL-20\@lg\+ {
    padding-left: 20px !important; }
  .pY-20\@lg\+ {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@lg\+ {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 992px) {
  .p-20\@md\+ {
    padding: 20px !important; }
  .pT-20\@md\+ {
    padding-top: 20px !important; }
  .pR-20\@md\+ {
    padding-right: 20px !important; }
  .pB-20\@md\+ {
    padding-bottom: 20px !important; }
  .pL-20\@md\+ {
    padding-left: 20px !important; }
  .pY-20\@md\+ {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@md\+ {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 768px) {
  .p-20\@sm\+ {
    padding: 20px !important; }
  .pT-20\@sm\+ {
    padding-top: 20px !important; }
  .pR-20\@sm\+ {
    padding-right: 20px !important; }
  .pB-20\@sm\+ {
    padding-bottom: 20px !important; }
  .pL-20\@sm\+ {
    padding-left: 20px !important; }
  .pY-20\@sm\+ {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@sm\+ {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 0) {
  .p-20\@xs\+ {
    padding: 20px !important; }
  .pT-20\@xs\+ {
    padding-top: 20px !important; }
  .pR-20\@xs\+ {
    padding-right: 20px !important; }
  .pB-20\@xs\+ {
    padding-bottom: 20px !important; }
  .pL-20\@xs\+ {
    padding-left: 20px !important; }
  .pY-20\@xs\+ {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@xs\+ {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (max-width: 1439px) {
  .p-20\@xl- {
    padding: 20px !important; }
  .pT-20\@xl- {
    padding-top: 20px !important; }
  .pR-20\@xl- {
    padding-right: 20px !important; }
  .pB-20\@xl- {
    padding-bottom: 20px !important; }
  .pL-20\@xl- {
    padding-left: 20px !important; }
  .pY-20\@xl- {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@xl- {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (max-width: 1199px) {
  .p-20\@lg- {
    padding: 20px !important; }
  .pT-20\@lg- {
    padding-top: 20px !important; }
  .pR-20\@lg- {
    padding-right: 20px !important; }
  .pB-20\@lg- {
    padding-bottom: 20px !important; }
  .pL-20\@lg- {
    padding-left: 20px !important; }
  .pY-20\@lg- {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@lg- {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (max-width: 991px) {
  .p-20\@md- {
    padding: 20px !important; }
  .pT-20\@md- {
    padding-top: 20px !important; }
  .pR-20\@md- {
    padding-right: 20px !important; }
  .pB-20\@md- {
    padding-bottom: 20px !important; }
  .pL-20\@md- {
    padding-left: 20px !important; }
  .pY-20\@md- {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@md- {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (max-width: 767px) {
  .p-20\@sm- {
    padding: 20px !important; }
  .pT-20\@sm- {
    padding-top: 20px !important; }
  .pR-20\@sm- {
    padding-right: 20px !important; }
  .pB-20\@sm- {
    padding-bottom: 20px !important; }
  .pL-20\@sm- {
    padding-left: 20px !important; }
  .pY-20\@sm- {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@sm- {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-20\@lg {
    padding: 20px !important; }
  .pT-20\@lg {
    padding-top: 20px !important; }
  .pR-20\@lg {
    padding-right: 20px !important; }
  .pB-20\@lg {
    padding-bottom: 20px !important; }
  .pL-20\@lg {
    padding-left: 20px !important; }
  .pY-20\@lg {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@lg {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-20\@md {
    padding: 20px !important; }
  .pT-20\@md {
    padding-top: 20px !important; }
  .pR-20\@md {
    padding-right: 20px !important; }
  .pB-20\@md {
    padding-bottom: 20px !important; }
  .pL-20\@md {
    padding-left: 20px !important; }
  .pY-20\@md {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@md {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-20\@sm {
    padding: 20px !important; }
  .pT-20\@sm {
    padding-top: 20px !important; }
  .pR-20\@sm {
    padding-right: 20px !important; }
  .pB-20\@sm {
    padding-bottom: 20px !important; }
  .pL-20\@sm {
    padding-left: 20px !important; }
  .pY-20\@sm {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .pX-20\@sm {
    padding-left: 20px !important;
    padding-right: 20px !important; } }

.p-25 {
  padding: 25px !important; }

.pT-25 {
  padding-top: 25px !important; }

.pR-25 {
  padding-right: 25px !important; }

.pB-25 {
  padding-bottom: 25px !important; }

.pL-25 {
  padding-left: 25px !important; }

.pY-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }

.pX-25 {
  padding-left: 25px !important;
  padding-right: 25px !important; }

@media screen and (min-width: 1440px) {
  .p-25\@xl\+ {
    padding: 25px !important; }
  .pT-25\@xl\+ {
    padding-top: 25px !important; }
  .pR-25\@xl\+ {
    padding-right: 25px !important; }
  .pB-25\@xl\+ {
    padding-bottom: 25px !important; }
  .pL-25\@xl\+ {
    padding-left: 25px !important; }
  .pY-25\@xl\+ {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@xl\+ {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 1200px) {
  .p-25\@lg\+ {
    padding: 25px !important; }
  .pT-25\@lg\+ {
    padding-top: 25px !important; }
  .pR-25\@lg\+ {
    padding-right: 25px !important; }
  .pB-25\@lg\+ {
    padding-bottom: 25px !important; }
  .pL-25\@lg\+ {
    padding-left: 25px !important; }
  .pY-25\@lg\+ {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@lg\+ {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 992px) {
  .p-25\@md\+ {
    padding: 25px !important; }
  .pT-25\@md\+ {
    padding-top: 25px !important; }
  .pR-25\@md\+ {
    padding-right: 25px !important; }
  .pB-25\@md\+ {
    padding-bottom: 25px !important; }
  .pL-25\@md\+ {
    padding-left: 25px !important; }
  .pY-25\@md\+ {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@md\+ {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 768px) {
  .p-25\@sm\+ {
    padding: 25px !important; }
  .pT-25\@sm\+ {
    padding-top: 25px !important; }
  .pR-25\@sm\+ {
    padding-right: 25px !important; }
  .pB-25\@sm\+ {
    padding-bottom: 25px !important; }
  .pL-25\@sm\+ {
    padding-left: 25px !important; }
  .pY-25\@sm\+ {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@sm\+ {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 0) {
  .p-25\@xs\+ {
    padding: 25px !important; }
  .pT-25\@xs\+ {
    padding-top: 25px !important; }
  .pR-25\@xs\+ {
    padding-right: 25px !important; }
  .pB-25\@xs\+ {
    padding-bottom: 25px !important; }
  .pL-25\@xs\+ {
    padding-left: 25px !important; }
  .pY-25\@xs\+ {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@xs\+ {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (max-width: 1439px) {
  .p-25\@xl- {
    padding: 25px !important; }
  .pT-25\@xl- {
    padding-top: 25px !important; }
  .pR-25\@xl- {
    padding-right: 25px !important; }
  .pB-25\@xl- {
    padding-bottom: 25px !important; }
  .pL-25\@xl- {
    padding-left: 25px !important; }
  .pY-25\@xl- {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@xl- {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (max-width: 1199px) {
  .p-25\@lg- {
    padding: 25px !important; }
  .pT-25\@lg- {
    padding-top: 25px !important; }
  .pR-25\@lg- {
    padding-right: 25px !important; }
  .pB-25\@lg- {
    padding-bottom: 25px !important; }
  .pL-25\@lg- {
    padding-left: 25px !important; }
  .pY-25\@lg- {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@lg- {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (max-width: 991px) {
  .p-25\@md- {
    padding: 25px !important; }
  .pT-25\@md- {
    padding-top: 25px !important; }
  .pR-25\@md- {
    padding-right: 25px !important; }
  .pB-25\@md- {
    padding-bottom: 25px !important; }
  .pL-25\@md- {
    padding-left: 25px !important; }
  .pY-25\@md- {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@md- {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (max-width: 767px) {
  .p-25\@sm- {
    padding: 25px !important; }
  .pT-25\@sm- {
    padding-top: 25px !important; }
  .pR-25\@sm- {
    padding-right: 25px !important; }
  .pB-25\@sm- {
    padding-bottom: 25px !important; }
  .pL-25\@sm- {
    padding-left: 25px !important; }
  .pY-25\@sm- {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@sm- {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-25\@lg {
    padding: 25px !important; }
  .pT-25\@lg {
    padding-top: 25px !important; }
  .pR-25\@lg {
    padding-right: 25px !important; }
  .pB-25\@lg {
    padding-bottom: 25px !important; }
  .pL-25\@lg {
    padding-left: 25px !important; }
  .pY-25\@lg {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@lg {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-25\@md {
    padding: 25px !important; }
  .pT-25\@md {
    padding-top: 25px !important; }
  .pR-25\@md {
    padding-right: 25px !important; }
  .pB-25\@md {
    padding-bottom: 25px !important; }
  .pL-25\@md {
    padding-left: 25px !important; }
  .pY-25\@md {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@md {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-25\@sm {
    padding: 25px !important; }
  .pT-25\@sm {
    padding-top: 25px !important; }
  .pR-25\@sm {
    padding-right: 25px !important; }
  .pB-25\@sm {
    padding-bottom: 25px !important; }
  .pL-25\@sm {
    padding-left: 25px !important; }
  .pY-25\@sm {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
  .pX-25\@sm {
    padding-left: 25px !important;
    padding-right: 25px !important; } }

.p-30 {
  padding: 30px !important; }

.pT-30 {
  padding-top: 30px !important; }

.pR-30 {
  padding-right: 30px !important; }

.pB-30 {
  padding-bottom: 30px !important; }

.pL-30 {
  padding-left: 30px !important; }

.pY-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pX-30 {
  padding-left: 30px !important;
  padding-right: 30px !important; }

@media screen and (min-width: 1440px) {
  .p-30\@xl\+ {
    padding: 30px !important; }
  .pT-30\@xl\+ {
    padding-top: 30px !important; }
  .pR-30\@xl\+ {
    padding-right: 30px !important; }
  .pB-30\@xl\+ {
    padding-bottom: 30px !important; }
  .pL-30\@xl\+ {
    padding-left: 30px !important; }
  .pY-30\@xl\+ {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@xl\+ {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 1200px) {
  .p-30\@lg\+ {
    padding: 30px !important; }
  .pT-30\@lg\+ {
    padding-top: 30px !important; }
  .pR-30\@lg\+ {
    padding-right: 30px !important; }
  .pB-30\@lg\+ {
    padding-bottom: 30px !important; }
  .pL-30\@lg\+ {
    padding-left: 30px !important; }
  .pY-30\@lg\+ {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@lg\+ {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 992px) {
  .p-30\@md\+ {
    padding: 30px !important; }
  .pT-30\@md\+ {
    padding-top: 30px !important; }
  .pR-30\@md\+ {
    padding-right: 30px !important; }
  .pB-30\@md\+ {
    padding-bottom: 30px !important; }
  .pL-30\@md\+ {
    padding-left: 30px !important; }
  .pY-30\@md\+ {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@md\+ {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 768px) {
  .p-30\@sm\+ {
    padding: 30px !important; }
  .pT-30\@sm\+ {
    padding-top: 30px !important; }
  .pR-30\@sm\+ {
    padding-right: 30px !important; }
  .pB-30\@sm\+ {
    padding-bottom: 30px !important; }
  .pL-30\@sm\+ {
    padding-left: 30px !important; }
  .pY-30\@sm\+ {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@sm\+ {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 0) {
  .p-30\@xs\+ {
    padding: 30px !important; }
  .pT-30\@xs\+ {
    padding-top: 30px !important; }
  .pR-30\@xs\+ {
    padding-right: 30px !important; }
  .pB-30\@xs\+ {
    padding-bottom: 30px !important; }
  .pL-30\@xs\+ {
    padding-left: 30px !important; }
  .pY-30\@xs\+ {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@xs\+ {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (max-width: 1439px) {
  .p-30\@xl- {
    padding: 30px !important; }
  .pT-30\@xl- {
    padding-top: 30px !important; }
  .pR-30\@xl- {
    padding-right: 30px !important; }
  .pB-30\@xl- {
    padding-bottom: 30px !important; }
  .pL-30\@xl- {
    padding-left: 30px !important; }
  .pY-30\@xl- {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@xl- {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (max-width: 1199px) {
  .p-30\@lg- {
    padding: 30px !important; }
  .pT-30\@lg- {
    padding-top: 30px !important; }
  .pR-30\@lg- {
    padding-right: 30px !important; }
  .pB-30\@lg- {
    padding-bottom: 30px !important; }
  .pL-30\@lg- {
    padding-left: 30px !important; }
  .pY-30\@lg- {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@lg- {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (max-width: 991px) {
  .p-30\@md- {
    padding: 30px !important; }
  .pT-30\@md- {
    padding-top: 30px !important; }
  .pR-30\@md- {
    padding-right: 30px !important; }
  .pB-30\@md- {
    padding-bottom: 30px !important; }
  .pL-30\@md- {
    padding-left: 30px !important; }
  .pY-30\@md- {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@md- {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (max-width: 767px) {
  .p-30\@sm- {
    padding: 30px !important; }
  .pT-30\@sm- {
    padding-top: 30px !important; }
  .pR-30\@sm- {
    padding-right: 30px !important; }
  .pB-30\@sm- {
    padding-bottom: 30px !important; }
  .pL-30\@sm- {
    padding-left: 30px !important; }
  .pY-30\@sm- {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@sm- {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-30\@lg {
    padding: 30px !important; }
  .pT-30\@lg {
    padding-top: 30px !important; }
  .pR-30\@lg {
    padding-right: 30px !important; }
  .pB-30\@lg {
    padding-bottom: 30px !important; }
  .pL-30\@lg {
    padding-left: 30px !important; }
  .pY-30\@lg {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@lg {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-30\@md {
    padding: 30px !important; }
  .pT-30\@md {
    padding-top: 30px !important; }
  .pR-30\@md {
    padding-right: 30px !important; }
  .pB-30\@md {
    padding-bottom: 30px !important; }
  .pL-30\@md {
    padding-left: 30px !important; }
  .pY-30\@md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@md {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-30\@sm {
    padding: 30px !important; }
  .pT-30\@sm {
    padding-top: 30px !important; }
  .pR-30\@sm {
    padding-right: 30px !important; }
  .pB-30\@sm {
    padding-bottom: 30px !important; }
  .pL-30\@sm {
    padding-left: 30px !important; }
  .pY-30\@sm {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .pX-30\@sm {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

.p-35 {
  padding: 35px !important; }

.pT-35 {
  padding-top: 35px !important; }

.pR-35 {
  padding-right: 35px !important; }

.pB-35 {
  padding-bottom: 35px !important; }

.pL-35 {
  padding-left: 35px !important; }

.pY-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important; }

.pX-35 {
  padding-left: 35px !important;
  padding-right: 35px !important; }

@media screen and (min-width: 1440px) {
  .p-35\@xl\+ {
    padding: 35px !important; }
  .pT-35\@xl\+ {
    padding-top: 35px !important; }
  .pR-35\@xl\+ {
    padding-right: 35px !important; }
  .pB-35\@xl\+ {
    padding-bottom: 35px !important; }
  .pL-35\@xl\+ {
    padding-left: 35px !important; }
  .pY-35\@xl\+ {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@xl\+ {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 1200px) {
  .p-35\@lg\+ {
    padding: 35px !important; }
  .pT-35\@lg\+ {
    padding-top: 35px !important; }
  .pR-35\@lg\+ {
    padding-right: 35px !important; }
  .pB-35\@lg\+ {
    padding-bottom: 35px !important; }
  .pL-35\@lg\+ {
    padding-left: 35px !important; }
  .pY-35\@lg\+ {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@lg\+ {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 992px) {
  .p-35\@md\+ {
    padding: 35px !important; }
  .pT-35\@md\+ {
    padding-top: 35px !important; }
  .pR-35\@md\+ {
    padding-right: 35px !important; }
  .pB-35\@md\+ {
    padding-bottom: 35px !important; }
  .pL-35\@md\+ {
    padding-left: 35px !important; }
  .pY-35\@md\+ {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@md\+ {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 768px) {
  .p-35\@sm\+ {
    padding: 35px !important; }
  .pT-35\@sm\+ {
    padding-top: 35px !important; }
  .pR-35\@sm\+ {
    padding-right: 35px !important; }
  .pB-35\@sm\+ {
    padding-bottom: 35px !important; }
  .pL-35\@sm\+ {
    padding-left: 35px !important; }
  .pY-35\@sm\+ {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@sm\+ {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 0) {
  .p-35\@xs\+ {
    padding: 35px !important; }
  .pT-35\@xs\+ {
    padding-top: 35px !important; }
  .pR-35\@xs\+ {
    padding-right: 35px !important; }
  .pB-35\@xs\+ {
    padding-bottom: 35px !important; }
  .pL-35\@xs\+ {
    padding-left: 35px !important; }
  .pY-35\@xs\+ {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@xs\+ {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (max-width: 1439px) {
  .p-35\@xl- {
    padding: 35px !important; }
  .pT-35\@xl- {
    padding-top: 35px !important; }
  .pR-35\@xl- {
    padding-right: 35px !important; }
  .pB-35\@xl- {
    padding-bottom: 35px !important; }
  .pL-35\@xl- {
    padding-left: 35px !important; }
  .pY-35\@xl- {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@xl- {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (max-width: 1199px) {
  .p-35\@lg- {
    padding: 35px !important; }
  .pT-35\@lg- {
    padding-top: 35px !important; }
  .pR-35\@lg- {
    padding-right: 35px !important; }
  .pB-35\@lg- {
    padding-bottom: 35px !important; }
  .pL-35\@lg- {
    padding-left: 35px !important; }
  .pY-35\@lg- {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@lg- {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (max-width: 991px) {
  .p-35\@md- {
    padding: 35px !important; }
  .pT-35\@md- {
    padding-top: 35px !important; }
  .pR-35\@md- {
    padding-right: 35px !important; }
  .pB-35\@md- {
    padding-bottom: 35px !important; }
  .pL-35\@md- {
    padding-left: 35px !important; }
  .pY-35\@md- {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@md- {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (max-width: 767px) {
  .p-35\@sm- {
    padding: 35px !important; }
  .pT-35\@sm- {
    padding-top: 35px !important; }
  .pR-35\@sm- {
    padding-right: 35px !important; }
  .pB-35\@sm- {
    padding-bottom: 35px !important; }
  .pL-35\@sm- {
    padding-left: 35px !important; }
  .pY-35\@sm- {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@sm- {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-35\@lg {
    padding: 35px !important; }
  .pT-35\@lg {
    padding-top: 35px !important; }
  .pR-35\@lg {
    padding-right: 35px !important; }
  .pB-35\@lg {
    padding-bottom: 35px !important; }
  .pL-35\@lg {
    padding-left: 35px !important; }
  .pY-35\@lg {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@lg {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-35\@md {
    padding: 35px !important; }
  .pT-35\@md {
    padding-top: 35px !important; }
  .pR-35\@md {
    padding-right: 35px !important; }
  .pB-35\@md {
    padding-bottom: 35px !important; }
  .pL-35\@md {
    padding-left: 35px !important; }
  .pY-35\@md {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@md {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-35\@sm {
    padding: 35px !important; }
  .pT-35\@sm {
    padding-top: 35px !important; }
  .pR-35\@sm {
    padding-right: 35px !important; }
  .pB-35\@sm {
    padding-bottom: 35px !important; }
  .pL-35\@sm {
    padding-left: 35px !important; }
  .pY-35\@sm {
    padding-top: 35px !important;
    padding-bottom: 35px !important; }
  .pX-35\@sm {
    padding-left: 35px !important;
    padding-right: 35px !important; } }

.p-40 {
  padding: 40px !important; }

.pT-40 {
  padding-top: 40px !important; }

.pR-40 {
  padding-right: 40px !important; }

.pB-40 {
  padding-bottom: 40px !important; }

.pL-40 {
  padding-left: 40px !important; }

.pY-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.pX-40 {
  padding-left: 40px !important;
  padding-right: 40px !important; }

@media screen and (min-width: 1440px) {
  .p-40\@xl\+ {
    padding: 40px !important; }
  .pT-40\@xl\+ {
    padding-top: 40px !important; }
  .pR-40\@xl\+ {
    padding-right: 40px !important; }
  .pB-40\@xl\+ {
    padding-bottom: 40px !important; }
  .pL-40\@xl\+ {
    padding-left: 40px !important; }
  .pY-40\@xl\+ {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@xl\+ {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 1200px) {
  .p-40\@lg\+ {
    padding: 40px !important; }
  .pT-40\@lg\+ {
    padding-top: 40px !important; }
  .pR-40\@lg\+ {
    padding-right: 40px !important; }
  .pB-40\@lg\+ {
    padding-bottom: 40px !important; }
  .pL-40\@lg\+ {
    padding-left: 40px !important; }
  .pY-40\@lg\+ {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@lg\+ {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 992px) {
  .p-40\@md\+ {
    padding: 40px !important; }
  .pT-40\@md\+ {
    padding-top: 40px !important; }
  .pR-40\@md\+ {
    padding-right: 40px !important; }
  .pB-40\@md\+ {
    padding-bottom: 40px !important; }
  .pL-40\@md\+ {
    padding-left: 40px !important; }
  .pY-40\@md\+ {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@md\+ {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 768px) {
  .p-40\@sm\+ {
    padding: 40px !important; }
  .pT-40\@sm\+ {
    padding-top: 40px !important; }
  .pR-40\@sm\+ {
    padding-right: 40px !important; }
  .pB-40\@sm\+ {
    padding-bottom: 40px !important; }
  .pL-40\@sm\+ {
    padding-left: 40px !important; }
  .pY-40\@sm\+ {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@sm\+ {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 0) {
  .p-40\@xs\+ {
    padding: 40px !important; }
  .pT-40\@xs\+ {
    padding-top: 40px !important; }
  .pR-40\@xs\+ {
    padding-right: 40px !important; }
  .pB-40\@xs\+ {
    padding-bottom: 40px !important; }
  .pL-40\@xs\+ {
    padding-left: 40px !important; }
  .pY-40\@xs\+ {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@xs\+ {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (max-width: 1439px) {
  .p-40\@xl- {
    padding: 40px !important; }
  .pT-40\@xl- {
    padding-top: 40px !important; }
  .pR-40\@xl- {
    padding-right: 40px !important; }
  .pB-40\@xl- {
    padding-bottom: 40px !important; }
  .pL-40\@xl- {
    padding-left: 40px !important; }
  .pY-40\@xl- {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@xl- {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (max-width: 1199px) {
  .p-40\@lg- {
    padding: 40px !important; }
  .pT-40\@lg- {
    padding-top: 40px !important; }
  .pR-40\@lg- {
    padding-right: 40px !important; }
  .pB-40\@lg- {
    padding-bottom: 40px !important; }
  .pL-40\@lg- {
    padding-left: 40px !important; }
  .pY-40\@lg- {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@lg- {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (max-width: 991px) {
  .p-40\@md- {
    padding: 40px !important; }
  .pT-40\@md- {
    padding-top: 40px !important; }
  .pR-40\@md- {
    padding-right: 40px !important; }
  .pB-40\@md- {
    padding-bottom: 40px !important; }
  .pL-40\@md- {
    padding-left: 40px !important; }
  .pY-40\@md- {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@md- {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (max-width: 767px) {
  .p-40\@sm- {
    padding: 40px !important; }
  .pT-40\@sm- {
    padding-top: 40px !important; }
  .pR-40\@sm- {
    padding-right: 40px !important; }
  .pB-40\@sm- {
    padding-bottom: 40px !important; }
  .pL-40\@sm- {
    padding-left: 40px !important; }
  .pY-40\@sm- {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@sm- {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-40\@lg {
    padding: 40px !important; }
  .pT-40\@lg {
    padding-top: 40px !important; }
  .pR-40\@lg {
    padding-right: 40px !important; }
  .pB-40\@lg {
    padding-bottom: 40px !important; }
  .pL-40\@lg {
    padding-left: 40px !important; }
  .pY-40\@lg {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@lg {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-40\@md {
    padding: 40px !important; }
  .pT-40\@md {
    padding-top: 40px !important; }
  .pR-40\@md {
    padding-right: 40px !important; }
  .pB-40\@md {
    padding-bottom: 40px !important; }
  .pL-40\@md {
    padding-left: 40px !important; }
  .pY-40\@md {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@md {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-40\@sm {
    padding: 40px !important; }
  .pT-40\@sm {
    padding-top: 40px !important; }
  .pR-40\@sm {
    padding-right: 40px !important; }
  .pB-40\@sm {
    padding-bottom: 40px !important; }
  .pL-40\@sm {
    padding-left: 40px !important; }
  .pY-40\@sm {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .pX-40\@sm {
    padding-left: 40px !important;
    padding-right: 40px !important; } }

.p-50 {
  padding: 50px !important; }

.pT-50 {
  padding-top: 50px !important; }

.pR-50 {
  padding-right: 50px !important; }

.pB-50 {
  padding-bottom: 50px !important; }

.pL-50 {
  padding-left: 50px !important; }

.pY-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pX-50 {
  padding-left: 50px !important;
  padding-right: 50px !important; }

@media screen and (min-width: 1440px) {
  .p-50\@xl\+ {
    padding: 50px !important; }
  .pT-50\@xl\+ {
    padding-top: 50px !important; }
  .pR-50\@xl\+ {
    padding-right: 50px !important; }
  .pB-50\@xl\+ {
    padding-bottom: 50px !important; }
  .pL-50\@xl\+ {
    padding-left: 50px !important; }
  .pY-50\@xl\+ {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@xl\+ {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 1200px) {
  .p-50\@lg\+ {
    padding: 50px !important; }
  .pT-50\@lg\+ {
    padding-top: 50px !important; }
  .pR-50\@lg\+ {
    padding-right: 50px !important; }
  .pB-50\@lg\+ {
    padding-bottom: 50px !important; }
  .pL-50\@lg\+ {
    padding-left: 50px !important; }
  .pY-50\@lg\+ {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@lg\+ {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 992px) {
  .p-50\@md\+ {
    padding: 50px !important; }
  .pT-50\@md\+ {
    padding-top: 50px !important; }
  .pR-50\@md\+ {
    padding-right: 50px !important; }
  .pB-50\@md\+ {
    padding-bottom: 50px !important; }
  .pL-50\@md\+ {
    padding-left: 50px !important; }
  .pY-50\@md\+ {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@md\+ {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 768px) {
  .p-50\@sm\+ {
    padding: 50px !important; }
  .pT-50\@sm\+ {
    padding-top: 50px !important; }
  .pR-50\@sm\+ {
    padding-right: 50px !important; }
  .pB-50\@sm\+ {
    padding-bottom: 50px !important; }
  .pL-50\@sm\+ {
    padding-left: 50px !important; }
  .pY-50\@sm\+ {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@sm\+ {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 0) {
  .p-50\@xs\+ {
    padding: 50px !important; }
  .pT-50\@xs\+ {
    padding-top: 50px !important; }
  .pR-50\@xs\+ {
    padding-right: 50px !important; }
  .pB-50\@xs\+ {
    padding-bottom: 50px !important; }
  .pL-50\@xs\+ {
    padding-left: 50px !important; }
  .pY-50\@xs\+ {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@xs\+ {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (max-width: 1439px) {
  .p-50\@xl- {
    padding: 50px !important; }
  .pT-50\@xl- {
    padding-top: 50px !important; }
  .pR-50\@xl- {
    padding-right: 50px !important; }
  .pB-50\@xl- {
    padding-bottom: 50px !important; }
  .pL-50\@xl- {
    padding-left: 50px !important; }
  .pY-50\@xl- {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@xl- {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (max-width: 1199px) {
  .p-50\@lg- {
    padding: 50px !important; }
  .pT-50\@lg- {
    padding-top: 50px !important; }
  .pR-50\@lg- {
    padding-right: 50px !important; }
  .pB-50\@lg- {
    padding-bottom: 50px !important; }
  .pL-50\@lg- {
    padding-left: 50px !important; }
  .pY-50\@lg- {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@lg- {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (max-width: 991px) {
  .p-50\@md- {
    padding: 50px !important; }
  .pT-50\@md- {
    padding-top: 50px !important; }
  .pR-50\@md- {
    padding-right: 50px !important; }
  .pB-50\@md- {
    padding-bottom: 50px !important; }
  .pL-50\@md- {
    padding-left: 50px !important; }
  .pY-50\@md- {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@md- {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (max-width: 767px) {
  .p-50\@sm- {
    padding: 50px !important; }
  .pT-50\@sm- {
    padding-top: 50px !important; }
  .pR-50\@sm- {
    padding-right: 50px !important; }
  .pB-50\@sm- {
    padding-bottom: 50px !important; }
  .pL-50\@sm- {
    padding-left: 50px !important; }
  .pY-50\@sm- {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@sm- {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-50\@lg {
    padding: 50px !important; }
  .pT-50\@lg {
    padding-top: 50px !important; }
  .pR-50\@lg {
    padding-right: 50px !important; }
  .pB-50\@lg {
    padding-bottom: 50px !important; }
  .pL-50\@lg {
    padding-left: 50px !important; }
  .pY-50\@lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@lg {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-50\@md {
    padding: 50px !important; }
  .pT-50\@md {
    padding-top: 50px !important; }
  .pR-50\@md {
    padding-right: 50px !important; }
  .pB-50\@md {
    padding-bottom: 50px !important; }
  .pL-50\@md {
    padding-left: 50px !important; }
  .pY-50\@md {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@md {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-50\@sm {
    padding: 50px !important; }
  .pT-50\@sm {
    padding-top: 50px !important; }
  .pR-50\@sm {
    padding-right: 50px !important; }
  .pB-50\@sm {
    padding-bottom: 50px !important; }
  .pL-50\@sm {
    padding-left: 50px !important; }
  .pY-50\@sm {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .pX-50\@sm {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

.p-60 {
  padding: 60px !important; }

.pT-60 {
  padding-top: 60px !important; }

.pR-60 {
  padding-right: 60px !important; }

.pB-60 {
  padding-bottom: 60px !important; }

.pL-60 {
  padding-left: 60px !important; }

.pY-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.pX-60 {
  padding-left: 60px !important;
  padding-right: 60px !important; }

@media screen and (min-width: 1440px) {
  .p-60\@xl\+ {
    padding: 60px !important; }
  .pT-60\@xl\+ {
    padding-top: 60px !important; }
  .pR-60\@xl\+ {
    padding-right: 60px !important; }
  .pB-60\@xl\+ {
    padding-bottom: 60px !important; }
  .pL-60\@xl\+ {
    padding-left: 60px !important; }
  .pY-60\@xl\+ {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@xl\+ {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 1200px) {
  .p-60\@lg\+ {
    padding: 60px !important; }
  .pT-60\@lg\+ {
    padding-top: 60px !important; }
  .pR-60\@lg\+ {
    padding-right: 60px !important; }
  .pB-60\@lg\+ {
    padding-bottom: 60px !important; }
  .pL-60\@lg\+ {
    padding-left: 60px !important; }
  .pY-60\@lg\+ {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@lg\+ {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 992px) {
  .p-60\@md\+ {
    padding: 60px !important; }
  .pT-60\@md\+ {
    padding-top: 60px !important; }
  .pR-60\@md\+ {
    padding-right: 60px !important; }
  .pB-60\@md\+ {
    padding-bottom: 60px !important; }
  .pL-60\@md\+ {
    padding-left: 60px !important; }
  .pY-60\@md\+ {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@md\+ {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 768px) {
  .p-60\@sm\+ {
    padding: 60px !important; }
  .pT-60\@sm\+ {
    padding-top: 60px !important; }
  .pR-60\@sm\+ {
    padding-right: 60px !important; }
  .pB-60\@sm\+ {
    padding-bottom: 60px !important; }
  .pL-60\@sm\+ {
    padding-left: 60px !important; }
  .pY-60\@sm\+ {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@sm\+ {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 0) {
  .p-60\@xs\+ {
    padding: 60px !important; }
  .pT-60\@xs\+ {
    padding-top: 60px !important; }
  .pR-60\@xs\+ {
    padding-right: 60px !important; }
  .pB-60\@xs\+ {
    padding-bottom: 60px !important; }
  .pL-60\@xs\+ {
    padding-left: 60px !important; }
  .pY-60\@xs\+ {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@xs\+ {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (max-width: 1439px) {
  .p-60\@xl- {
    padding: 60px !important; }
  .pT-60\@xl- {
    padding-top: 60px !important; }
  .pR-60\@xl- {
    padding-right: 60px !important; }
  .pB-60\@xl- {
    padding-bottom: 60px !important; }
  .pL-60\@xl- {
    padding-left: 60px !important; }
  .pY-60\@xl- {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@xl- {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (max-width: 1199px) {
  .p-60\@lg- {
    padding: 60px !important; }
  .pT-60\@lg- {
    padding-top: 60px !important; }
  .pR-60\@lg- {
    padding-right: 60px !important; }
  .pB-60\@lg- {
    padding-bottom: 60px !important; }
  .pL-60\@lg- {
    padding-left: 60px !important; }
  .pY-60\@lg- {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@lg- {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (max-width: 991px) {
  .p-60\@md- {
    padding: 60px !important; }
  .pT-60\@md- {
    padding-top: 60px !important; }
  .pR-60\@md- {
    padding-right: 60px !important; }
  .pB-60\@md- {
    padding-bottom: 60px !important; }
  .pL-60\@md- {
    padding-left: 60px !important; }
  .pY-60\@md- {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@md- {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (max-width: 767px) {
  .p-60\@sm- {
    padding: 60px !important; }
  .pT-60\@sm- {
    padding-top: 60px !important; }
  .pR-60\@sm- {
    padding-right: 60px !important; }
  .pB-60\@sm- {
    padding-bottom: 60px !important; }
  .pL-60\@sm- {
    padding-left: 60px !important; }
  .pY-60\@sm- {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@sm- {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-60\@lg {
    padding: 60px !important; }
  .pT-60\@lg {
    padding-top: 60px !important; }
  .pR-60\@lg {
    padding-right: 60px !important; }
  .pB-60\@lg {
    padding-bottom: 60px !important; }
  .pL-60\@lg {
    padding-left: 60px !important; }
  .pY-60\@lg {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@lg {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-60\@md {
    padding: 60px !important; }
  .pT-60\@md {
    padding-top: 60px !important; }
  .pR-60\@md {
    padding-right: 60px !important; }
  .pB-60\@md {
    padding-bottom: 60px !important; }
  .pL-60\@md {
    padding-left: 60px !important; }
  .pY-60\@md {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@md {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-60\@sm {
    padding: 60px !important; }
  .pT-60\@sm {
    padding-top: 60px !important; }
  .pR-60\@sm {
    padding-right: 60px !important; }
  .pB-60\@sm {
    padding-bottom: 60px !important; }
  .pL-60\@sm {
    padding-left: 60px !important; }
  .pY-60\@sm {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .pX-60\@sm {
    padding-left: 60px !important;
    padding-right: 60px !important; } }

.p-70 {
  padding: 70px !important; }

.pT-70 {
  padding-top: 70px !important; }

.pR-70 {
  padding-right: 70px !important; }

.pB-70 {
  padding-bottom: 70px !important; }

.pL-70 {
  padding-left: 70px !important; }

.pY-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pX-70 {
  padding-left: 70px !important;
  padding-right: 70px !important; }

@media screen and (min-width: 1440px) {
  .p-70\@xl\+ {
    padding: 70px !important; }
  .pT-70\@xl\+ {
    padding-top: 70px !important; }
  .pR-70\@xl\+ {
    padding-right: 70px !important; }
  .pB-70\@xl\+ {
    padding-bottom: 70px !important; }
  .pL-70\@xl\+ {
    padding-left: 70px !important; }
  .pY-70\@xl\+ {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@xl\+ {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 1200px) {
  .p-70\@lg\+ {
    padding: 70px !important; }
  .pT-70\@lg\+ {
    padding-top: 70px !important; }
  .pR-70\@lg\+ {
    padding-right: 70px !important; }
  .pB-70\@lg\+ {
    padding-bottom: 70px !important; }
  .pL-70\@lg\+ {
    padding-left: 70px !important; }
  .pY-70\@lg\+ {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@lg\+ {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 992px) {
  .p-70\@md\+ {
    padding: 70px !important; }
  .pT-70\@md\+ {
    padding-top: 70px !important; }
  .pR-70\@md\+ {
    padding-right: 70px !important; }
  .pB-70\@md\+ {
    padding-bottom: 70px !important; }
  .pL-70\@md\+ {
    padding-left: 70px !important; }
  .pY-70\@md\+ {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@md\+ {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 768px) {
  .p-70\@sm\+ {
    padding: 70px !important; }
  .pT-70\@sm\+ {
    padding-top: 70px !important; }
  .pR-70\@sm\+ {
    padding-right: 70px !important; }
  .pB-70\@sm\+ {
    padding-bottom: 70px !important; }
  .pL-70\@sm\+ {
    padding-left: 70px !important; }
  .pY-70\@sm\+ {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@sm\+ {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 0) {
  .p-70\@xs\+ {
    padding: 70px !important; }
  .pT-70\@xs\+ {
    padding-top: 70px !important; }
  .pR-70\@xs\+ {
    padding-right: 70px !important; }
  .pB-70\@xs\+ {
    padding-bottom: 70px !important; }
  .pL-70\@xs\+ {
    padding-left: 70px !important; }
  .pY-70\@xs\+ {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@xs\+ {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (max-width: 1439px) {
  .p-70\@xl- {
    padding: 70px !important; }
  .pT-70\@xl- {
    padding-top: 70px !important; }
  .pR-70\@xl- {
    padding-right: 70px !important; }
  .pB-70\@xl- {
    padding-bottom: 70px !important; }
  .pL-70\@xl- {
    padding-left: 70px !important; }
  .pY-70\@xl- {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@xl- {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (max-width: 1199px) {
  .p-70\@lg- {
    padding: 70px !important; }
  .pT-70\@lg- {
    padding-top: 70px !important; }
  .pR-70\@lg- {
    padding-right: 70px !important; }
  .pB-70\@lg- {
    padding-bottom: 70px !important; }
  .pL-70\@lg- {
    padding-left: 70px !important; }
  .pY-70\@lg- {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@lg- {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (max-width: 991px) {
  .p-70\@md- {
    padding: 70px !important; }
  .pT-70\@md- {
    padding-top: 70px !important; }
  .pR-70\@md- {
    padding-right: 70px !important; }
  .pB-70\@md- {
    padding-bottom: 70px !important; }
  .pL-70\@md- {
    padding-left: 70px !important; }
  .pY-70\@md- {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@md- {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (max-width: 767px) {
  .p-70\@sm- {
    padding: 70px !important; }
  .pT-70\@sm- {
    padding-top: 70px !important; }
  .pR-70\@sm- {
    padding-right: 70px !important; }
  .pB-70\@sm- {
    padding-bottom: 70px !important; }
  .pL-70\@sm- {
    padding-left: 70px !important; }
  .pY-70\@sm- {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@sm- {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-70\@lg {
    padding: 70px !important; }
  .pT-70\@lg {
    padding-top: 70px !important; }
  .pR-70\@lg {
    padding-right: 70px !important; }
  .pB-70\@lg {
    padding-bottom: 70px !important; }
  .pL-70\@lg {
    padding-left: 70px !important; }
  .pY-70\@lg {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@lg {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-70\@md {
    padding: 70px !important; }
  .pT-70\@md {
    padding-top: 70px !important; }
  .pR-70\@md {
    padding-right: 70px !important; }
  .pB-70\@md {
    padding-bottom: 70px !important; }
  .pL-70\@md {
    padding-left: 70px !important; }
  .pY-70\@md {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@md {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-70\@sm {
    padding: 70px !important; }
  .pT-70\@sm {
    padding-top: 70px !important; }
  .pR-70\@sm {
    padding-right: 70px !important; }
  .pB-70\@sm {
    padding-bottom: 70px !important; }
  .pL-70\@sm {
    padding-left: 70px !important; }
  .pY-70\@sm {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .pX-70\@sm {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

.p-80 {
  padding: 80px !important; }

.pT-80 {
  padding-top: 80px !important; }

.pR-80 {
  padding-right: 80px !important; }

.pB-80 {
  padding-bottom: 80px !important; }

.pL-80 {
  padding-left: 80px !important; }

.pY-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.pX-80 {
  padding-left: 80px !important;
  padding-right: 80px !important; }

@media screen and (min-width: 1440px) {
  .p-80\@xl\+ {
    padding: 80px !important; }
  .pT-80\@xl\+ {
    padding-top: 80px !important; }
  .pR-80\@xl\+ {
    padding-right: 80px !important; }
  .pB-80\@xl\+ {
    padding-bottom: 80px !important; }
  .pL-80\@xl\+ {
    padding-left: 80px !important; }
  .pY-80\@xl\+ {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@xl\+ {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 1200px) {
  .p-80\@lg\+ {
    padding: 80px !important; }
  .pT-80\@lg\+ {
    padding-top: 80px !important; }
  .pR-80\@lg\+ {
    padding-right: 80px !important; }
  .pB-80\@lg\+ {
    padding-bottom: 80px !important; }
  .pL-80\@lg\+ {
    padding-left: 80px !important; }
  .pY-80\@lg\+ {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@lg\+ {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 992px) {
  .p-80\@md\+ {
    padding: 80px !important; }
  .pT-80\@md\+ {
    padding-top: 80px !important; }
  .pR-80\@md\+ {
    padding-right: 80px !important; }
  .pB-80\@md\+ {
    padding-bottom: 80px !important; }
  .pL-80\@md\+ {
    padding-left: 80px !important; }
  .pY-80\@md\+ {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@md\+ {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 768px) {
  .p-80\@sm\+ {
    padding: 80px !important; }
  .pT-80\@sm\+ {
    padding-top: 80px !important; }
  .pR-80\@sm\+ {
    padding-right: 80px !important; }
  .pB-80\@sm\+ {
    padding-bottom: 80px !important; }
  .pL-80\@sm\+ {
    padding-left: 80px !important; }
  .pY-80\@sm\+ {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@sm\+ {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 0) {
  .p-80\@xs\+ {
    padding: 80px !important; }
  .pT-80\@xs\+ {
    padding-top: 80px !important; }
  .pR-80\@xs\+ {
    padding-right: 80px !important; }
  .pB-80\@xs\+ {
    padding-bottom: 80px !important; }
  .pL-80\@xs\+ {
    padding-left: 80px !important; }
  .pY-80\@xs\+ {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@xs\+ {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (max-width: 1439px) {
  .p-80\@xl- {
    padding: 80px !important; }
  .pT-80\@xl- {
    padding-top: 80px !important; }
  .pR-80\@xl- {
    padding-right: 80px !important; }
  .pB-80\@xl- {
    padding-bottom: 80px !important; }
  .pL-80\@xl- {
    padding-left: 80px !important; }
  .pY-80\@xl- {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@xl- {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (max-width: 1199px) {
  .p-80\@lg- {
    padding: 80px !important; }
  .pT-80\@lg- {
    padding-top: 80px !important; }
  .pR-80\@lg- {
    padding-right: 80px !important; }
  .pB-80\@lg- {
    padding-bottom: 80px !important; }
  .pL-80\@lg- {
    padding-left: 80px !important; }
  .pY-80\@lg- {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@lg- {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (max-width: 991px) {
  .p-80\@md- {
    padding: 80px !important; }
  .pT-80\@md- {
    padding-top: 80px !important; }
  .pR-80\@md- {
    padding-right: 80px !important; }
  .pB-80\@md- {
    padding-bottom: 80px !important; }
  .pL-80\@md- {
    padding-left: 80px !important; }
  .pY-80\@md- {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@md- {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (max-width: 767px) {
  .p-80\@sm- {
    padding: 80px !important; }
  .pT-80\@sm- {
    padding-top: 80px !important; }
  .pR-80\@sm- {
    padding-right: 80px !important; }
  .pB-80\@sm- {
    padding-bottom: 80px !important; }
  .pL-80\@sm- {
    padding-left: 80px !important; }
  .pY-80\@sm- {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@sm- {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-80\@lg {
    padding: 80px !important; }
  .pT-80\@lg {
    padding-top: 80px !important; }
  .pR-80\@lg {
    padding-right: 80px !important; }
  .pB-80\@lg {
    padding-bottom: 80px !important; }
  .pL-80\@lg {
    padding-left: 80px !important; }
  .pY-80\@lg {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@lg {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-80\@md {
    padding: 80px !important; }
  .pT-80\@md {
    padding-top: 80px !important; }
  .pR-80\@md {
    padding-right: 80px !important; }
  .pB-80\@md {
    padding-bottom: 80px !important; }
  .pL-80\@md {
    padding-left: 80px !important; }
  .pY-80\@md {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@md {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-80\@sm {
    padding: 80px !important; }
  .pT-80\@sm {
    padding-top: 80px !important; }
  .pR-80\@sm {
    padding-right: 80px !important; }
  .pB-80\@sm {
    padding-bottom: 80px !important; }
  .pL-80\@sm {
    padding-left: 80px !important; }
  .pY-80\@sm {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .pX-80\@sm {
    padding-left: 80px !important;
    padding-right: 80px !important; } }

.p-90 {
  padding: 90px !important; }

.pT-90 {
  padding-top: 90px !important; }

.pR-90 {
  padding-right: 90px !important; }

.pB-90 {
  padding-bottom: 90px !important; }

.pL-90 {
  padding-left: 90px !important; }

.pY-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important; }

.pX-90 {
  padding-left: 90px !important;
  padding-right: 90px !important; }

@media screen and (min-width: 1440px) {
  .p-90\@xl\+ {
    padding: 90px !important; }
  .pT-90\@xl\+ {
    padding-top: 90px !important; }
  .pR-90\@xl\+ {
    padding-right: 90px !important; }
  .pB-90\@xl\+ {
    padding-bottom: 90px !important; }
  .pL-90\@xl\+ {
    padding-left: 90px !important; }
  .pY-90\@xl\+ {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@xl\+ {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 1200px) {
  .p-90\@lg\+ {
    padding: 90px !important; }
  .pT-90\@lg\+ {
    padding-top: 90px !important; }
  .pR-90\@lg\+ {
    padding-right: 90px !important; }
  .pB-90\@lg\+ {
    padding-bottom: 90px !important; }
  .pL-90\@lg\+ {
    padding-left: 90px !important; }
  .pY-90\@lg\+ {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@lg\+ {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 992px) {
  .p-90\@md\+ {
    padding: 90px !important; }
  .pT-90\@md\+ {
    padding-top: 90px !important; }
  .pR-90\@md\+ {
    padding-right: 90px !important; }
  .pB-90\@md\+ {
    padding-bottom: 90px !important; }
  .pL-90\@md\+ {
    padding-left: 90px !important; }
  .pY-90\@md\+ {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@md\+ {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 768px) {
  .p-90\@sm\+ {
    padding: 90px !important; }
  .pT-90\@sm\+ {
    padding-top: 90px !important; }
  .pR-90\@sm\+ {
    padding-right: 90px !important; }
  .pB-90\@sm\+ {
    padding-bottom: 90px !important; }
  .pL-90\@sm\+ {
    padding-left: 90px !important; }
  .pY-90\@sm\+ {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@sm\+ {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 0) {
  .p-90\@xs\+ {
    padding: 90px !important; }
  .pT-90\@xs\+ {
    padding-top: 90px !important; }
  .pR-90\@xs\+ {
    padding-right: 90px !important; }
  .pB-90\@xs\+ {
    padding-bottom: 90px !important; }
  .pL-90\@xs\+ {
    padding-left: 90px !important; }
  .pY-90\@xs\+ {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@xs\+ {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (max-width: 1439px) {
  .p-90\@xl- {
    padding: 90px !important; }
  .pT-90\@xl- {
    padding-top: 90px !important; }
  .pR-90\@xl- {
    padding-right: 90px !important; }
  .pB-90\@xl- {
    padding-bottom: 90px !important; }
  .pL-90\@xl- {
    padding-left: 90px !important; }
  .pY-90\@xl- {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@xl- {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (max-width: 1199px) {
  .p-90\@lg- {
    padding: 90px !important; }
  .pT-90\@lg- {
    padding-top: 90px !important; }
  .pR-90\@lg- {
    padding-right: 90px !important; }
  .pB-90\@lg- {
    padding-bottom: 90px !important; }
  .pL-90\@lg- {
    padding-left: 90px !important; }
  .pY-90\@lg- {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@lg- {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (max-width: 991px) {
  .p-90\@md- {
    padding: 90px !important; }
  .pT-90\@md- {
    padding-top: 90px !important; }
  .pR-90\@md- {
    padding-right: 90px !important; }
  .pB-90\@md- {
    padding-bottom: 90px !important; }
  .pL-90\@md- {
    padding-left: 90px !important; }
  .pY-90\@md- {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@md- {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (max-width: 767px) {
  .p-90\@sm- {
    padding: 90px !important; }
  .pT-90\@sm- {
    padding-top: 90px !important; }
  .pR-90\@sm- {
    padding-right: 90px !important; }
  .pB-90\@sm- {
    padding-bottom: 90px !important; }
  .pL-90\@sm- {
    padding-left: 90px !important; }
  .pY-90\@sm- {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@sm- {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-90\@lg {
    padding: 90px !important; }
  .pT-90\@lg {
    padding-top: 90px !important; }
  .pR-90\@lg {
    padding-right: 90px !important; }
  .pB-90\@lg {
    padding-bottom: 90px !important; }
  .pL-90\@lg {
    padding-left: 90px !important; }
  .pY-90\@lg {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@lg {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-90\@md {
    padding: 90px !important; }
  .pT-90\@md {
    padding-top: 90px !important; }
  .pR-90\@md {
    padding-right: 90px !important; }
  .pB-90\@md {
    padding-bottom: 90px !important; }
  .pL-90\@md {
    padding-left: 90px !important; }
  .pY-90\@md {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@md {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-90\@sm {
    padding: 90px !important; }
  .pT-90\@sm {
    padding-top: 90px !important; }
  .pR-90\@sm {
    padding-right: 90px !important; }
  .pB-90\@sm {
    padding-bottom: 90px !important; }
  .pL-90\@sm {
    padding-left: 90px !important; }
  .pY-90\@sm {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pX-90\@sm {
    padding-left: 90px !important;
    padding-right: 90px !important; } }

.p-100 {
  padding: 100px !important; }

.pT-100 {
  padding-top: 100px !important; }

.pR-100 {
  padding-right: 100px !important; }

.pB-100 {
  padding-bottom: 100px !important; }

.pL-100 {
  padding-left: 100px !important; }

.pY-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important; }

.pX-100 {
  padding-left: 100px !important;
  padding-right: 100px !important; }

@media screen and (min-width: 1440px) {
  .p-100\@xl\+ {
    padding: 100px !important; }
  .pT-100\@xl\+ {
    padding-top: 100px !important; }
  .pR-100\@xl\+ {
    padding-right: 100px !important; }
  .pB-100\@xl\+ {
    padding-bottom: 100px !important; }
  .pL-100\@xl\+ {
    padding-left: 100px !important; }
  .pY-100\@xl\+ {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@xl\+ {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 1200px) {
  .p-100\@lg\+ {
    padding: 100px !important; }
  .pT-100\@lg\+ {
    padding-top: 100px !important; }
  .pR-100\@lg\+ {
    padding-right: 100px !important; }
  .pB-100\@lg\+ {
    padding-bottom: 100px !important; }
  .pL-100\@lg\+ {
    padding-left: 100px !important; }
  .pY-100\@lg\+ {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@lg\+ {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 992px) {
  .p-100\@md\+ {
    padding: 100px !important; }
  .pT-100\@md\+ {
    padding-top: 100px !important; }
  .pR-100\@md\+ {
    padding-right: 100px !important; }
  .pB-100\@md\+ {
    padding-bottom: 100px !important; }
  .pL-100\@md\+ {
    padding-left: 100px !important; }
  .pY-100\@md\+ {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@md\+ {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 768px) {
  .p-100\@sm\+ {
    padding: 100px !important; }
  .pT-100\@sm\+ {
    padding-top: 100px !important; }
  .pR-100\@sm\+ {
    padding-right: 100px !important; }
  .pB-100\@sm\+ {
    padding-bottom: 100px !important; }
  .pL-100\@sm\+ {
    padding-left: 100px !important; }
  .pY-100\@sm\+ {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@sm\+ {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 0) {
  .p-100\@xs\+ {
    padding: 100px !important; }
  .pT-100\@xs\+ {
    padding-top: 100px !important; }
  .pR-100\@xs\+ {
    padding-right: 100px !important; }
  .pB-100\@xs\+ {
    padding-bottom: 100px !important; }
  .pL-100\@xs\+ {
    padding-left: 100px !important; }
  .pY-100\@xs\+ {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@xs\+ {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (max-width: 1439px) {
  .p-100\@xl- {
    padding: 100px !important; }
  .pT-100\@xl- {
    padding-top: 100px !important; }
  .pR-100\@xl- {
    padding-right: 100px !important; }
  .pB-100\@xl- {
    padding-bottom: 100px !important; }
  .pL-100\@xl- {
    padding-left: 100px !important; }
  .pY-100\@xl- {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@xl- {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (max-width: 1199px) {
  .p-100\@lg- {
    padding: 100px !important; }
  .pT-100\@lg- {
    padding-top: 100px !important; }
  .pR-100\@lg- {
    padding-right: 100px !important; }
  .pB-100\@lg- {
    padding-bottom: 100px !important; }
  .pL-100\@lg- {
    padding-left: 100px !important; }
  .pY-100\@lg- {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@lg- {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (max-width: 991px) {
  .p-100\@md- {
    padding: 100px !important; }
  .pT-100\@md- {
    padding-top: 100px !important; }
  .pR-100\@md- {
    padding-right: 100px !important; }
  .pB-100\@md- {
    padding-bottom: 100px !important; }
  .pL-100\@md- {
    padding-left: 100px !important; }
  .pY-100\@md- {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@md- {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (max-width: 767px) {
  .p-100\@sm- {
    padding: 100px !important; }
  .pT-100\@sm- {
    padding-top: 100px !important; }
  .pR-100\@sm- {
    padding-right: 100px !important; }
  .pB-100\@sm- {
    padding-bottom: 100px !important; }
  .pL-100\@sm- {
    padding-left: 100px !important; }
  .pY-100\@sm- {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@sm- {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-100\@lg {
    padding: 100px !important; }
  .pT-100\@lg {
    padding-top: 100px !important; }
  .pR-100\@lg {
    padding-right: 100px !important; }
  .pB-100\@lg {
    padding-bottom: 100px !important; }
  .pL-100\@lg {
    padding-left: 100px !important; }
  .pY-100\@lg {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@lg {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-100\@md {
    padding: 100px !important; }
  .pT-100\@md {
    padding-top: 100px !important; }
  .pR-100\@md {
    padding-right: 100px !important; }
  .pB-100\@md {
    padding-bottom: 100px !important; }
  .pL-100\@md {
    padding-left: 100px !important; }
  .pY-100\@md {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@md {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-100\@sm {
    padding: 100px !important; }
  .pT-100\@sm {
    padding-top: 100px !important; }
  .pR-100\@sm {
    padding-right: 100px !important; }
  .pB-100\@sm {
    padding-bottom: 100px !important; }
  .pL-100\@sm {
    padding-left: 100px !important; }
  .pY-100\@sm {
    padding-top: 100px !important;
    padding-bottom: 100px !important; }
  .pX-100\@sm {
    padding-left: 100px !important;
    padding-right: 100px !important; } }

.p-110 {
  padding: 110px !important; }

.pT-110 {
  padding-top: 110px !important; }

.pR-110 {
  padding-right: 110px !important; }

.pB-110 {
  padding-bottom: 110px !important; }

.pL-110 {
  padding-left: 110px !important; }

.pY-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important; }

.pX-110 {
  padding-left: 110px !important;
  padding-right: 110px !important; }

@media screen and (min-width: 1440px) {
  .p-110\@xl\+ {
    padding: 110px !important; }
  .pT-110\@xl\+ {
    padding-top: 110px !important; }
  .pR-110\@xl\+ {
    padding-right: 110px !important; }
  .pB-110\@xl\+ {
    padding-bottom: 110px !important; }
  .pL-110\@xl\+ {
    padding-left: 110px !important; }
  .pY-110\@xl\+ {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@xl\+ {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 1200px) {
  .p-110\@lg\+ {
    padding: 110px !important; }
  .pT-110\@lg\+ {
    padding-top: 110px !important; }
  .pR-110\@lg\+ {
    padding-right: 110px !important; }
  .pB-110\@lg\+ {
    padding-bottom: 110px !important; }
  .pL-110\@lg\+ {
    padding-left: 110px !important; }
  .pY-110\@lg\+ {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@lg\+ {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 992px) {
  .p-110\@md\+ {
    padding: 110px !important; }
  .pT-110\@md\+ {
    padding-top: 110px !important; }
  .pR-110\@md\+ {
    padding-right: 110px !important; }
  .pB-110\@md\+ {
    padding-bottom: 110px !important; }
  .pL-110\@md\+ {
    padding-left: 110px !important; }
  .pY-110\@md\+ {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@md\+ {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 768px) {
  .p-110\@sm\+ {
    padding: 110px !important; }
  .pT-110\@sm\+ {
    padding-top: 110px !important; }
  .pR-110\@sm\+ {
    padding-right: 110px !important; }
  .pB-110\@sm\+ {
    padding-bottom: 110px !important; }
  .pL-110\@sm\+ {
    padding-left: 110px !important; }
  .pY-110\@sm\+ {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@sm\+ {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 0) {
  .p-110\@xs\+ {
    padding: 110px !important; }
  .pT-110\@xs\+ {
    padding-top: 110px !important; }
  .pR-110\@xs\+ {
    padding-right: 110px !important; }
  .pB-110\@xs\+ {
    padding-bottom: 110px !important; }
  .pL-110\@xs\+ {
    padding-left: 110px !important; }
  .pY-110\@xs\+ {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@xs\+ {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (max-width: 1439px) {
  .p-110\@xl- {
    padding: 110px !important; }
  .pT-110\@xl- {
    padding-top: 110px !important; }
  .pR-110\@xl- {
    padding-right: 110px !important; }
  .pB-110\@xl- {
    padding-bottom: 110px !important; }
  .pL-110\@xl- {
    padding-left: 110px !important; }
  .pY-110\@xl- {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@xl- {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (max-width: 1199px) {
  .p-110\@lg- {
    padding: 110px !important; }
  .pT-110\@lg- {
    padding-top: 110px !important; }
  .pR-110\@lg- {
    padding-right: 110px !important; }
  .pB-110\@lg- {
    padding-bottom: 110px !important; }
  .pL-110\@lg- {
    padding-left: 110px !important; }
  .pY-110\@lg- {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@lg- {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (max-width: 991px) {
  .p-110\@md- {
    padding: 110px !important; }
  .pT-110\@md- {
    padding-top: 110px !important; }
  .pR-110\@md- {
    padding-right: 110px !important; }
  .pB-110\@md- {
    padding-bottom: 110px !important; }
  .pL-110\@md- {
    padding-left: 110px !important; }
  .pY-110\@md- {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@md- {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (max-width: 767px) {
  .p-110\@sm- {
    padding: 110px !important; }
  .pT-110\@sm- {
    padding-top: 110px !important; }
  .pR-110\@sm- {
    padding-right: 110px !important; }
  .pB-110\@sm- {
    padding-bottom: 110px !important; }
  .pL-110\@sm- {
    padding-left: 110px !important; }
  .pY-110\@sm- {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@sm- {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-110\@lg {
    padding: 110px !important; }
  .pT-110\@lg {
    padding-top: 110px !important; }
  .pR-110\@lg {
    padding-right: 110px !important; }
  .pB-110\@lg {
    padding-bottom: 110px !important; }
  .pL-110\@lg {
    padding-left: 110px !important; }
  .pY-110\@lg {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@lg {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-110\@md {
    padding: 110px !important; }
  .pT-110\@md {
    padding-top: 110px !important; }
  .pR-110\@md {
    padding-right: 110px !important; }
  .pB-110\@md {
    padding-bottom: 110px !important; }
  .pL-110\@md {
    padding-left: 110px !important; }
  .pY-110\@md {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@md {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-110\@sm {
    padding: 110px !important; }
  .pT-110\@sm {
    padding-top: 110px !important; }
  .pR-110\@sm {
    padding-right: 110px !important; }
  .pB-110\@sm {
    padding-bottom: 110px !important; }
  .pL-110\@sm {
    padding-left: 110px !important; }
  .pY-110\@sm {
    padding-top: 110px !important;
    padding-bottom: 110px !important; }
  .pX-110\@sm {
    padding-left: 110px !important;
    padding-right: 110px !important; } }

.p-120 {
  padding: 120px !important; }

.pT-120 {
  padding-top: 120px !important; }

.pR-120 {
  padding-right: 120px !important; }

.pB-120 {
  padding-bottom: 120px !important; }

.pL-120 {
  padding-left: 120px !important; }

.pY-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }

.pX-120 {
  padding-left: 120px !important;
  padding-right: 120px !important; }

@media screen and (min-width: 1440px) {
  .p-120\@xl\+ {
    padding: 120px !important; }
  .pT-120\@xl\+ {
    padding-top: 120px !important; }
  .pR-120\@xl\+ {
    padding-right: 120px !important; }
  .pB-120\@xl\+ {
    padding-bottom: 120px !important; }
  .pL-120\@xl\+ {
    padding-left: 120px !important; }
  .pY-120\@xl\+ {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@xl\+ {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 1200px) {
  .p-120\@lg\+ {
    padding: 120px !important; }
  .pT-120\@lg\+ {
    padding-top: 120px !important; }
  .pR-120\@lg\+ {
    padding-right: 120px !important; }
  .pB-120\@lg\+ {
    padding-bottom: 120px !important; }
  .pL-120\@lg\+ {
    padding-left: 120px !important; }
  .pY-120\@lg\+ {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@lg\+ {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 992px) {
  .p-120\@md\+ {
    padding: 120px !important; }
  .pT-120\@md\+ {
    padding-top: 120px !important; }
  .pR-120\@md\+ {
    padding-right: 120px !important; }
  .pB-120\@md\+ {
    padding-bottom: 120px !important; }
  .pL-120\@md\+ {
    padding-left: 120px !important; }
  .pY-120\@md\+ {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@md\+ {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 768px) {
  .p-120\@sm\+ {
    padding: 120px !important; }
  .pT-120\@sm\+ {
    padding-top: 120px !important; }
  .pR-120\@sm\+ {
    padding-right: 120px !important; }
  .pB-120\@sm\+ {
    padding-bottom: 120px !important; }
  .pL-120\@sm\+ {
    padding-left: 120px !important; }
  .pY-120\@sm\+ {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@sm\+ {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 0) {
  .p-120\@xs\+ {
    padding: 120px !important; }
  .pT-120\@xs\+ {
    padding-top: 120px !important; }
  .pR-120\@xs\+ {
    padding-right: 120px !important; }
  .pB-120\@xs\+ {
    padding-bottom: 120px !important; }
  .pL-120\@xs\+ {
    padding-left: 120px !important; }
  .pY-120\@xs\+ {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@xs\+ {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (max-width: 1439px) {
  .p-120\@xl- {
    padding: 120px !important; }
  .pT-120\@xl- {
    padding-top: 120px !important; }
  .pR-120\@xl- {
    padding-right: 120px !important; }
  .pB-120\@xl- {
    padding-bottom: 120px !important; }
  .pL-120\@xl- {
    padding-left: 120px !important; }
  .pY-120\@xl- {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@xl- {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (max-width: 1199px) {
  .p-120\@lg- {
    padding: 120px !important; }
  .pT-120\@lg- {
    padding-top: 120px !important; }
  .pR-120\@lg- {
    padding-right: 120px !important; }
  .pB-120\@lg- {
    padding-bottom: 120px !important; }
  .pL-120\@lg- {
    padding-left: 120px !important; }
  .pY-120\@lg- {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@lg- {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (max-width: 991px) {
  .p-120\@md- {
    padding: 120px !important; }
  .pT-120\@md- {
    padding-top: 120px !important; }
  .pR-120\@md- {
    padding-right: 120px !important; }
  .pB-120\@md- {
    padding-bottom: 120px !important; }
  .pL-120\@md- {
    padding-left: 120px !important; }
  .pY-120\@md- {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@md- {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (max-width: 767px) {
  .p-120\@sm- {
    padding: 120px !important; }
  .pT-120\@sm- {
    padding-top: 120px !important; }
  .pR-120\@sm- {
    padding-right: 120px !important; }
  .pB-120\@sm- {
    padding-bottom: 120px !important; }
  .pL-120\@sm- {
    padding-left: 120px !important; }
  .pY-120\@sm- {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@sm- {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-120\@lg {
    padding: 120px !important; }
  .pT-120\@lg {
    padding-top: 120px !important; }
  .pR-120\@lg {
    padding-right: 120px !important; }
  .pB-120\@lg {
    padding-bottom: 120px !important; }
  .pL-120\@lg {
    padding-left: 120px !important; }
  .pY-120\@lg {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@lg {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-120\@md {
    padding: 120px !important; }
  .pT-120\@md {
    padding-top: 120px !important; }
  .pR-120\@md {
    padding-right: 120px !important; }
  .pB-120\@md {
    padding-bottom: 120px !important; }
  .pL-120\@md {
    padding-left: 120px !important; }
  .pY-120\@md {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@md {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-120\@sm {
    padding: 120px !important; }
  .pT-120\@sm {
    padding-top: 120px !important; }
  .pR-120\@sm {
    padding-right: 120px !important; }
  .pB-120\@sm {
    padding-bottom: 120px !important; }
  .pL-120\@sm {
    padding-left: 120px !important; }
  .pY-120\@sm {
    padding-top: 120px !important;
    padding-bottom: 120px !important; }
  .pX-120\@sm {
    padding-left: 120px !important;
    padding-right: 120px !important; } }

.p-130 {
  padding: 130px !important; }

.pT-130 {
  padding-top: 130px !important; }

.pR-130 {
  padding-right: 130px !important; }

.pB-130 {
  padding-bottom: 130px !important; }

.pL-130 {
  padding-left: 130px !important; }

.pY-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important; }

.pX-130 {
  padding-left: 130px !important;
  padding-right: 130px !important; }

@media screen and (min-width: 1440px) {
  .p-130\@xl\+ {
    padding: 130px !important; }
  .pT-130\@xl\+ {
    padding-top: 130px !important; }
  .pR-130\@xl\+ {
    padding-right: 130px !important; }
  .pB-130\@xl\+ {
    padding-bottom: 130px !important; }
  .pL-130\@xl\+ {
    padding-left: 130px !important; }
  .pY-130\@xl\+ {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@xl\+ {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 1200px) {
  .p-130\@lg\+ {
    padding: 130px !important; }
  .pT-130\@lg\+ {
    padding-top: 130px !important; }
  .pR-130\@lg\+ {
    padding-right: 130px !important; }
  .pB-130\@lg\+ {
    padding-bottom: 130px !important; }
  .pL-130\@lg\+ {
    padding-left: 130px !important; }
  .pY-130\@lg\+ {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@lg\+ {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 992px) {
  .p-130\@md\+ {
    padding: 130px !important; }
  .pT-130\@md\+ {
    padding-top: 130px !important; }
  .pR-130\@md\+ {
    padding-right: 130px !important; }
  .pB-130\@md\+ {
    padding-bottom: 130px !important; }
  .pL-130\@md\+ {
    padding-left: 130px !important; }
  .pY-130\@md\+ {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@md\+ {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 768px) {
  .p-130\@sm\+ {
    padding: 130px !important; }
  .pT-130\@sm\+ {
    padding-top: 130px !important; }
  .pR-130\@sm\+ {
    padding-right: 130px !important; }
  .pB-130\@sm\+ {
    padding-bottom: 130px !important; }
  .pL-130\@sm\+ {
    padding-left: 130px !important; }
  .pY-130\@sm\+ {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@sm\+ {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 0) {
  .p-130\@xs\+ {
    padding: 130px !important; }
  .pT-130\@xs\+ {
    padding-top: 130px !important; }
  .pR-130\@xs\+ {
    padding-right: 130px !important; }
  .pB-130\@xs\+ {
    padding-bottom: 130px !important; }
  .pL-130\@xs\+ {
    padding-left: 130px !important; }
  .pY-130\@xs\+ {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@xs\+ {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (max-width: 1439px) {
  .p-130\@xl- {
    padding: 130px !important; }
  .pT-130\@xl- {
    padding-top: 130px !important; }
  .pR-130\@xl- {
    padding-right: 130px !important; }
  .pB-130\@xl- {
    padding-bottom: 130px !important; }
  .pL-130\@xl- {
    padding-left: 130px !important; }
  .pY-130\@xl- {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@xl- {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (max-width: 1199px) {
  .p-130\@lg- {
    padding: 130px !important; }
  .pT-130\@lg- {
    padding-top: 130px !important; }
  .pR-130\@lg- {
    padding-right: 130px !important; }
  .pB-130\@lg- {
    padding-bottom: 130px !important; }
  .pL-130\@lg- {
    padding-left: 130px !important; }
  .pY-130\@lg- {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@lg- {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (max-width: 991px) {
  .p-130\@md- {
    padding: 130px !important; }
  .pT-130\@md- {
    padding-top: 130px !important; }
  .pR-130\@md- {
    padding-right: 130px !important; }
  .pB-130\@md- {
    padding-bottom: 130px !important; }
  .pL-130\@md- {
    padding-left: 130px !important; }
  .pY-130\@md- {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@md- {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (max-width: 767px) {
  .p-130\@sm- {
    padding: 130px !important; }
  .pT-130\@sm- {
    padding-top: 130px !important; }
  .pR-130\@sm- {
    padding-right: 130px !important; }
  .pB-130\@sm- {
    padding-bottom: 130px !important; }
  .pL-130\@sm- {
    padding-left: 130px !important; }
  .pY-130\@sm- {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@sm- {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-130\@lg {
    padding: 130px !important; }
  .pT-130\@lg {
    padding-top: 130px !important; }
  .pR-130\@lg {
    padding-right: 130px !important; }
  .pB-130\@lg {
    padding-bottom: 130px !important; }
  .pL-130\@lg {
    padding-left: 130px !important; }
  .pY-130\@lg {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@lg {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-130\@md {
    padding: 130px !important; }
  .pT-130\@md {
    padding-top: 130px !important; }
  .pR-130\@md {
    padding-right: 130px !important; }
  .pB-130\@md {
    padding-bottom: 130px !important; }
  .pL-130\@md {
    padding-left: 130px !important; }
  .pY-130\@md {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@md {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-130\@sm {
    padding: 130px !important; }
  .pT-130\@sm {
    padding-top: 130px !important; }
  .pR-130\@sm {
    padding-right: 130px !important; }
  .pB-130\@sm {
    padding-bottom: 130px !important; }
  .pL-130\@sm {
    padding-left: 130px !important; }
  .pY-130\@sm {
    padding-top: 130px !important;
    padding-bottom: 130px !important; }
  .pX-130\@sm {
    padding-left: 130px !important;
    padding-right: 130px !important; } }

.p-140 {
  padding: 140px !important; }

.pT-140 {
  padding-top: 140px !important; }

.pR-140 {
  padding-right: 140px !important; }

.pB-140 {
  padding-bottom: 140px !important; }

.pL-140 {
  padding-left: 140px !important; }

.pY-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pX-140 {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media screen and (min-width: 1440px) {
  .p-140\@xl\+ {
    padding: 140px !important; }
  .pT-140\@xl\+ {
    padding-top: 140px !important; }
  .pR-140\@xl\+ {
    padding-right: 140px !important; }
  .pB-140\@xl\+ {
    padding-bottom: 140px !important; }
  .pL-140\@xl\+ {
    padding-left: 140px !important; }
  .pY-140\@xl\+ {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@xl\+ {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 1200px) {
  .p-140\@lg\+ {
    padding: 140px !important; }
  .pT-140\@lg\+ {
    padding-top: 140px !important; }
  .pR-140\@lg\+ {
    padding-right: 140px !important; }
  .pB-140\@lg\+ {
    padding-bottom: 140px !important; }
  .pL-140\@lg\+ {
    padding-left: 140px !important; }
  .pY-140\@lg\+ {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@lg\+ {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 992px) {
  .p-140\@md\+ {
    padding: 140px !important; }
  .pT-140\@md\+ {
    padding-top: 140px !important; }
  .pR-140\@md\+ {
    padding-right: 140px !important; }
  .pB-140\@md\+ {
    padding-bottom: 140px !important; }
  .pL-140\@md\+ {
    padding-left: 140px !important; }
  .pY-140\@md\+ {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@md\+ {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 768px) {
  .p-140\@sm\+ {
    padding: 140px !important; }
  .pT-140\@sm\+ {
    padding-top: 140px !important; }
  .pR-140\@sm\+ {
    padding-right: 140px !important; }
  .pB-140\@sm\+ {
    padding-bottom: 140px !important; }
  .pL-140\@sm\+ {
    padding-left: 140px !important; }
  .pY-140\@sm\+ {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@sm\+ {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 0) {
  .p-140\@xs\+ {
    padding: 140px !important; }
  .pT-140\@xs\+ {
    padding-top: 140px !important; }
  .pR-140\@xs\+ {
    padding-right: 140px !important; }
  .pB-140\@xs\+ {
    padding-bottom: 140px !important; }
  .pL-140\@xs\+ {
    padding-left: 140px !important; }
  .pY-140\@xs\+ {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@xs\+ {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (max-width: 1439px) {
  .p-140\@xl- {
    padding: 140px !important; }
  .pT-140\@xl- {
    padding-top: 140px !important; }
  .pR-140\@xl- {
    padding-right: 140px !important; }
  .pB-140\@xl- {
    padding-bottom: 140px !important; }
  .pL-140\@xl- {
    padding-left: 140px !important; }
  .pY-140\@xl- {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@xl- {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (max-width: 1199px) {
  .p-140\@lg- {
    padding: 140px !important; }
  .pT-140\@lg- {
    padding-top: 140px !important; }
  .pR-140\@lg- {
    padding-right: 140px !important; }
  .pB-140\@lg- {
    padding-bottom: 140px !important; }
  .pL-140\@lg- {
    padding-left: 140px !important; }
  .pY-140\@lg- {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@lg- {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (max-width: 991px) {
  .p-140\@md- {
    padding: 140px !important; }
  .pT-140\@md- {
    padding-top: 140px !important; }
  .pR-140\@md- {
    padding-right: 140px !important; }
  .pB-140\@md- {
    padding-bottom: 140px !important; }
  .pL-140\@md- {
    padding-left: 140px !important; }
  .pY-140\@md- {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@md- {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (max-width: 767px) {
  .p-140\@sm- {
    padding: 140px !important; }
  .pT-140\@sm- {
    padding-top: 140px !important; }
  .pR-140\@sm- {
    padding-right: 140px !important; }
  .pB-140\@sm- {
    padding-bottom: 140px !important; }
  .pL-140\@sm- {
    padding-left: 140px !important; }
  .pY-140\@sm- {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@sm- {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-140\@lg {
    padding: 140px !important; }
  .pT-140\@lg {
    padding-top: 140px !important; }
  .pR-140\@lg {
    padding-right: 140px !important; }
  .pB-140\@lg {
    padding-bottom: 140px !important; }
  .pL-140\@lg {
    padding-left: 140px !important; }
  .pY-140\@lg {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@lg {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-140\@md {
    padding: 140px !important; }
  .pT-140\@md {
    padding-top: 140px !important; }
  .pR-140\@md {
    padding-right: 140px !important; }
  .pB-140\@md {
    padding-bottom: 140px !important; }
  .pL-140\@md {
    padding-left: 140px !important; }
  .pY-140\@md {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@md {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-140\@sm {
    padding: 140px !important; }
  .pT-140\@sm {
    padding-top: 140px !important; }
  .pR-140\@sm {
    padding-right: 140px !important; }
  .pB-140\@sm {
    padding-bottom: 140px !important; }
  .pL-140\@sm {
    padding-left: 140px !important; }
  .pY-140\@sm {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .pX-140\@sm {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.p-150 {
  padding: 150px !important; }

.pT-150 {
  padding-top: 150px !important; }

.pR-150 {
  padding-right: 150px !important; }

.pB-150 {
  padding-bottom: 150px !important; }

.pL-150 {
  padding-left: 150px !important; }

.pY-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important; }

.pX-150 {
  padding-left: 150px !important;
  padding-right: 150px !important; }

@media screen and (min-width: 1440px) {
  .p-150\@xl\+ {
    padding: 150px !important; }
  .pT-150\@xl\+ {
    padding-top: 150px !important; }
  .pR-150\@xl\+ {
    padding-right: 150px !important; }
  .pB-150\@xl\+ {
    padding-bottom: 150px !important; }
  .pL-150\@xl\+ {
    padding-left: 150px !important; }
  .pY-150\@xl\+ {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@xl\+ {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 1200px) {
  .p-150\@lg\+ {
    padding: 150px !important; }
  .pT-150\@lg\+ {
    padding-top: 150px !important; }
  .pR-150\@lg\+ {
    padding-right: 150px !important; }
  .pB-150\@lg\+ {
    padding-bottom: 150px !important; }
  .pL-150\@lg\+ {
    padding-left: 150px !important; }
  .pY-150\@lg\+ {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@lg\+ {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 992px) {
  .p-150\@md\+ {
    padding: 150px !important; }
  .pT-150\@md\+ {
    padding-top: 150px !important; }
  .pR-150\@md\+ {
    padding-right: 150px !important; }
  .pB-150\@md\+ {
    padding-bottom: 150px !important; }
  .pL-150\@md\+ {
    padding-left: 150px !important; }
  .pY-150\@md\+ {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@md\+ {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 768px) {
  .p-150\@sm\+ {
    padding: 150px !important; }
  .pT-150\@sm\+ {
    padding-top: 150px !important; }
  .pR-150\@sm\+ {
    padding-right: 150px !important; }
  .pB-150\@sm\+ {
    padding-bottom: 150px !important; }
  .pL-150\@sm\+ {
    padding-left: 150px !important; }
  .pY-150\@sm\+ {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@sm\+ {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 0) {
  .p-150\@xs\+ {
    padding: 150px !important; }
  .pT-150\@xs\+ {
    padding-top: 150px !important; }
  .pR-150\@xs\+ {
    padding-right: 150px !important; }
  .pB-150\@xs\+ {
    padding-bottom: 150px !important; }
  .pL-150\@xs\+ {
    padding-left: 150px !important; }
  .pY-150\@xs\+ {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@xs\+ {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (max-width: 1439px) {
  .p-150\@xl- {
    padding: 150px !important; }
  .pT-150\@xl- {
    padding-top: 150px !important; }
  .pR-150\@xl- {
    padding-right: 150px !important; }
  .pB-150\@xl- {
    padding-bottom: 150px !important; }
  .pL-150\@xl- {
    padding-left: 150px !important; }
  .pY-150\@xl- {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@xl- {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (max-width: 1199px) {
  .p-150\@lg- {
    padding: 150px !important; }
  .pT-150\@lg- {
    padding-top: 150px !important; }
  .pR-150\@lg- {
    padding-right: 150px !important; }
  .pB-150\@lg- {
    padding-bottom: 150px !important; }
  .pL-150\@lg- {
    padding-left: 150px !important; }
  .pY-150\@lg- {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@lg- {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (max-width: 991px) {
  .p-150\@md- {
    padding: 150px !important; }
  .pT-150\@md- {
    padding-top: 150px !important; }
  .pR-150\@md- {
    padding-right: 150px !important; }
  .pB-150\@md- {
    padding-bottom: 150px !important; }
  .pL-150\@md- {
    padding-left: 150px !important; }
  .pY-150\@md- {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@md- {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (max-width: 767px) {
  .p-150\@sm- {
    padding: 150px !important; }
  .pT-150\@sm- {
    padding-top: 150px !important; }
  .pR-150\@sm- {
    padding-right: 150px !important; }
  .pB-150\@sm- {
    padding-bottom: 150px !important; }
  .pL-150\@sm- {
    padding-left: 150px !important; }
  .pY-150\@sm- {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@sm- {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-150\@lg {
    padding: 150px !important; }
  .pT-150\@lg {
    padding-top: 150px !important; }
  .pR-150\@lg {
    padding-right: 150px !important; }
  .pB-150\@lg {
    padding-bottom: 150px !important; }
  .pL-150\@lg {
    padding-left: 150px !important; }
  .pY-150\@lg {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@lg {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-150\@md {
    padding: 150px !important; }
  .pT-150\@md {
    padding-top: 150px !important; }
  .pR-150\@md {
    padding-right: 150px !important; }
  .pB-150\@md {
    padding-bottom: 150px !important; }
  .pL-150\@md {
    padding-left: 150px !important; }
  .pY-150\@md {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@md {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-150\@sm {
    padding: 150px !important; }
  .pT-150\@sm {
    padding-top: 150px !important; }
  .pR-150\@sm {
    padding-right: 150px !important; }
  .pB-150\@sm {
    padding-bottom: 150px !important; }
  .pL-150\@sm {
    padding-left: 150px !important; }
  .pY-150\@sm {
    padding-top: 150px !important;
    padding-bottom: 150px !important; }
  .pX-150\@sm {
    padding-left: 150px !important;
    padding-right: 150px !important; } }

.p-160 {
  padding: 160px !important; }

.pT-160 {
  padding-top: 160px !important; }

.pR-160 {
  padding-right: 160px !important; }

.pB-160 {
  padding-bottom: 160px !important; }

.pL-160 {
  padding-left: 160px !important; }

.pY-160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important; }

.pX-160 {
  padding-left: 160px !important;
  padding-right: 160px !important; }

@media screen and (min-width: 1440px) {
  .p-160\@xl\+ {
    padding: 160px !important; }
  .pT-160\@xl\+ {
    padding-top: 160px !important; }
  .pR-160\@xl\+ {
    padding-right: 160px !important; }
  .pB-160\@xl\+ {
    padding-bottom: 160px !important; }
  .pL-160\@xl\+ {
    padding-left: 160px !important; }
  .pY-160\@xl\+ {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@xl\+ {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 1200px) {
  .p-160\@lg\+ {
    padding: 160px !important; }
  .pT-160\@lg\+ {
    padding-top: 160px !important; }
  .pR-160\@lg\+ {
    padding-right: 160px !important; }
  .pB-160\@lg\+ {
    padding-bottom: 160px !important; }
  .pL-160\@lg\+ {
    padding-left: 160px !important; }
  .pY-160\@lg\+ {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@lg\+ {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 992px) {
  .p-160\@md\+ {
    padding: 160px !important; }
  .pT-160\@md\+ {
    padding-top: 160px !important; }
  .pR-160\@md\+ {
    padding-right: 160px !important; }
  .pB-160\@md\+ {
    padding-bottom: 160px !important; }
  .pL-160\@md\+ {
    padding-left: 160px !important; }
  .pY-160\@md\+ {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@md\+ {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 768px) {
  .p-160\@sm\+ {
    padding: 160px !important; }
  .pT-160\@sm\+ {
    padding-top: 160px !important; }
  .pR-160\@sm\+ {
    padding-right: 160px !important; }
  .pB-160\@sm\+ {
    padding-bottom: 160px !important; }
  .pL-160\@sm\+ {
    padding-left: 160px !important; }
  .pY-160\@sm\+ {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@sm\+ {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 0) {
  .p-160\@xs\+ {
    padding: 160px !important; }
  .pT-160\@xs\+ {
    padding-top: 160px !important; }
  .pR-160\@xs\+ {
    padding-right: 160px !important; }
  .pB-160\@xs\+ {
    padding-bottom: 160px !important; }
  .pL-160\@xs\+ {
    padding-left: 160px !important; }
  .pY-160\@xs\+ {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@xs\+ {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (max-width: 1439px) {
  .p-160\@xl- {
    padding: 160px !important; }
  .pT-160\@xl- {
    padding-top: 160px !important; }
  .pR-160\@xl- {
    padding-right: 160px !important; }
  .pB-160\@xl- {
    padding-bottom: 160px !important; }
  .pL-160\@xl- {
    padding-left: 160px !important; }
  .pY-160\@xl- {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@xl- {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (max-width: 1199px) {
  .p-160\@lg- {
    padding: 160px !important; }
  .pT-160\@lg- {
    padding-top: 160px !important; }
  .pR-160\@lg- {
    padding-right: 160px !important; }
  .pB-160\@lg- {
    padding-bottom: 160px !important; }
  .pL-160\@lg- {
    padding-left: 160px !important; }
  .pY-160\@lg- {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@lg- {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (max-width: 991px) {
  .p-160\@md- {
    padding: 160px !important; }
  .pT-160\@md- {
    padding-top: 160px !important; }
  .pR-160\@md- {
    padding-right: 160px !important; }
  .pB-160\@md- {
    padding-bottom: 160px !important; }
  .pL-160\@md- {
    padding-left: 160px !important; }
  .pY-160\@md- {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@md- {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (max-width: 767px) {
  .p-160\@sm- {
    padding: 160px !important; }
  .pT-160\@sm- {
    padding-top: 160px !important; }
  .pR-160\@sm- {
    padding-right: 160px !important; }
  .pB-160\@sm- {
    padding-bottom: 160px !important; }
  .pL-160\@sm- {
    padding-left: 160px !important; }
  .pY-160\@sm- {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@sm- {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .p-160\@lg {
    padding: 160px !important; }
  .pT-160\@lg {
    padding-top: 160px !important; }
  .pR-160\@lg {
    padding-right: 160px !important; }
  .pB-160\@lg {
    padding-bottom: 160px !important; }
  .pL-160\@lg {
    padding-left: 160px !important; }
  .pY-160\@lg {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@lg {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .p-160\@md {
    padding: 160px !important; }
  .pT-160\@md {
    padding-top: 160px !important; }
  .pR-160\@md {
    padding-right: 160px !important; }
  .pB-160\@md {
    padding-bottom: 160px !important; }
  .pL-160\@md {
    padding-left: 160px !important; }
  .pY-160\@md {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@md {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .p-160\@sm {
    padding: 160px !important; }
  .pT-160\@sm {
    padding-top: 160px !important; }
  .pR-160\@sm {
    padding-right: 160px !important; }
  .pB-160\@sm {
    padding-bottom: 160px !important; }
  .pL-160\@sm {
    padding-left: 160px !important; }
  .pY-160\@sm {
    padding-top: 160px !important;
    padding-bottom: 160px !important; }
  .pX-160\@sm {
    padding-left: 160px !important;
    padding-right: 160px !important; } }

.t-0 {
  top: 0px; }

.r-0 {
  right: 0px; }

.b-0 {
  bottom: 0px; }

.l-0 {
  left: 0px; }

@media screen and (min-width: 1440px) {
  .t-0\@xl\+ {
    top: 0px; }
  .r-0\@xl\+ {
    right: 0px; }
  .b-0\@xl\+ {
    bottom: 0px; }
  .l-0\@xl\+ {
    left: 0px; } }

@media screen and (min-width: 1200px) {
  .t-0\@lg\+ {
    top: 0px; }
  .r-0\@lg\+ {
    right: 0px; }
  .b-0\@lg\+ {
    bottom: 0px; }
  .l-0\@lg\+ {
    left: 0px; } }

@media screen and (min-width: 992px) {
  .t-0\@md\+ {
    top: 0px; }
  .r-0\@md\+ {
    right: 0px; }
  .b-0\@md\+ {
    bottom: 0px; }
  .l-0\@md\+ {
    left: 0px; } }

@media screen and (min-width: 768px) {
  .t-0\@sm\+ {
    top: 0px; }
  .r-0\@sm\+ {
    right: 0px; }
  .b-0\@sm\+ {
    bottom: 0px; }
  .l-0\@sm\+ {
    left: 0px; } }

@media screen and (min-width: 0) {
  .t-0\@xs\+ {
    top: 0px; }
  .r-0\@xs\+ {
    right: 0px; }
  .b-0\@xs\+ {
    bottom: 0px; }
  .l-0\@xs\+ {
    left: 0px; } }

@media screen and (max-width: 1439px) {
  .t-0\@xl- {
    top: 0px; }
  .r-0\@xl- {
    right: 0px; }
  .b-0\@xl- {
    bottom: 0px; }
  .l-0\@xl- {
    left: 0px; } }

@media screen and (max-width: 1199px) {
  .t-0\@lg- {
    top: 0px; }
  .r-0\@lg- {
    right: 0px; }
  .b-0\@lg- {
    bottom: 0px; }
  .l-0\@lg- {
    left: 0px; } }

@media screen and (max-width: 991px) {
  .t-0\@md- {
    top: 0px; }
  .r-0\@md- {
    right: 0px; }
  .b-0\@md- {
    bottom: 0px; }
  .l-0\@md- {
    left: 0px; } }

@media screen and (max-width: 767px) {
  .t-0\@sm- {
    top: 0px; }
  .r-0\@sm- {
    right: 0px; }
  .b-0\@sm- {
    bottom: 0px; }
  .l-0\@sm- {
    left: 0px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-0\@lg {
    top: 0px; }
  .r-0\@lg {
    right: 0px; }
  .b-0\@lg {
    bottom: 0px; }
  .l-0\@lg {
    left: 0px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-0\@md {
    top: 0px; }
  .r-0\@md {
    right: 0px; }
  .b-0\@md {
    bottom: 0px; }
  .l-0\@md {
    left: 0px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-0\@sm {
    top: 0px; }
  .r-0\@sm {
    right: 0px; }
  .b-0\@sm {
    bottom: 0px; }
  .l-0\@sm {
    left: 0px; } }

.t-1 {
  top: 1px; }

.r-1 {
  right: 1px; }

.b-1 {
  bottom: 1px; }

.l-1 {
  left: 1px; }

@media screen and (min-width: 1440px) {
  .t-1\@xl\+ {
    top: 1px; }
  .r-1\@xl\+ {
    right: 1px; }
  .b-1\@xl\+ {
    bottom: 1px; }
  .l-1\@xl\+ {
    left: 1px; } }

@media screen and (min-width: 1200px) {
  .t-1\@lg\+ {
    top: 1px; }
  .r-1\@lg\+ {
    right: 1px; }
  .b-1\@lg\+ {
    bottom: 1px; }
  .l-1\@lg\+ {
    left: 1px; } }

@media screen and (min-width: 992px) {
  .t-1\@md\+ {
    top: 1px; }
  .r-1\@md\+ {
    right: 1px; }
  .b-1\@md\+ {
    bottom: 1px; }
  .l-1\@md\+ {
    left: 1px; } }

@media screen and (min-width: 768px) {
  .t-1\@sm\+ {
    top: 1px; }
  .r-1\@sm\+ {
    right: 1px; }
  .b-1\@sm\+ {
    bottom: 1px; }
  .l-1\@sm\+ {
    left: 1px; } }

@media screen and (min-width: 0) {
  .t-1\@xs\+ {
    top: 1px; }
  .r-1\@xs\+ {
    right: 1px; }
  .b-1\@xs\+ {
    bottom: 1px; }
  .l-1\@xs\+ {
    left: 1px; } }

@media screen and (max-width: 1439px) {
  .t-1\@xl- {
    top: 1px; }
  .r-1\@xl- {
    right: 1px; }
  .b-1\@xl- {
    bottom: 1px; }
  .l-1\@xl- {
    left: 1px; } }

@media screen and (max-width: 1199px) {
  .t-1\@lg- {
    top: 1px; }
  .r-1\@lg- {
    right: 1px; }
  .b-1\@lg- {
    bottom: 1px; }
  .l-1\@lg- {
    left: 1px; } }

@media screen and (max-width: 991px) {
  .t-1\@md- {
    top: 1px; }
  .r-1\@md- {
    right: 1px; }
  .b-1\@md- {
    bottom: 1px; }
  .l-1\@md- {
    left: 1px; } }

@media screen and (max-width: 767px) {
  .t-1\@sm- {
    top: 1px; }
  .r-1\@sm- {
    right: 1px; }
  .b-1\@sm- {
    bottom: 1px; }
  .l-1\@sm- {
    left: 1px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-1\@lg {
    top: 1px; }
  .r-1\@lg {
    right: 1px; }
  .b-1\@lg {
    bottom: 1px; }
  .l-1\@lg {
    left: 1px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-1\@md {
    top: 1px; }
  .r-1\@md {
    right: 1px; }
  .b-1\@md {
    bottom: 1px; }
  .l-1\@md {
    left: 1px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-1\@sm {
    top: 1px; }
  .r-1\@sm {
    right: 1px; }
  .b-1\@sm {
    bottom: 1px; }
  .l-1\@sm {
    left: 1px; } }

.t-2 {
  top: 2px; }

.r-2 {
  right: 2px; }

.b-2 {
  bottom: 2px; }

.l-2 {
  left: 2px; }

@media screen and (min-width: 1440px) {
  .t-2\@xl\+ {
    top: 2px; }
  .r-2\@xl\+ {
    right: 2px; }
  .b-2\@xl\+ {
    bottom: 2px; }
  .l-2\@xl\+ {
    left: 2px; } }

@media screen and (min-width: 1200px) {
  .t-2\@lg\+ {
    top: 2px; }
  .r-2\@lg\+ {
    right: 2px; }
  .b-2\@lg\+ {
    bottom: 2px; }
  .l-2\@lg\+ {
    left: 2px; } }

@media screen and (min-width: 992px) {
  .t-2\@md\+ {
    top: 2px; }
  .r-2\@md\+ {
    right: 2px; }
  .b-2\@md\+ {
    bottom: 2px; }
  .l-2\@md\+ {
    left: 2px; } }

@media screen and (min-width: 768px) {
  .t-2\@sm\+ {
    top: 2px; }
  .r-2\@sm\+ {
    right: 2px; }
  .b-2\@sm\+ {
    bottom: 2px; }
  .l-2\@sm\+ {
    left: 2px; } }

@media screen and (min-width: 0) {
  .t-2\@xs\+ {
    top: 2px; }
  .r-2\@xs\+ {
    right: 2px; }
  .b-2\@xs\+ {
    bottom: 2px; }
  .l-2\@xs\+ {
    left: 2px; } }

@media screen and (max-width: 1439px) {
  .t-2\@xl- {
    top: 2px; }
  .r-2\@xl- {
    right: 2px; }
  .b-2\@xl- {
    bottom: 2px; }
  .l-2\@xl- {
    left: 2px; } }

@media screen and (max-width: 1199px) {
  .t-2\@lg- {
    top: 2px; }
  .r-2\@lg- {
    right: 2px; }
  .b-2\@lg- {
    bottom: 2px; }
  .l-2\@lg- {
    left: 2px; } }

@media screen and (max-width: 991px) {
  .t-2\@md- {
    top: 2px; }
  .r-2\@md- {
    right: 2px; }
  .b-2\@md- {
    bottom: 2px; }
  .l-2\@md- {
    left: 2px; } }

@media screen and (max-width: 767px) {
  .t-2\@sm- {
    top: 2px; }
  .r-2\@sm- {
    right: 2px; }
  .b-2\@sm- {
    bottom: 2px; }
  .l-2\@sm- {
    left: 2px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-2\@lg {
    top: 2px; }
  .r-2\@lg {
    right: 2px; }
  .b-2\@lg {
    bottom: 2px; }
  .l-2\@lg {
    left: 2px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-2\@md {
    top: 2px; }
  .r-2\@md {
    right: 2px; }
  .b-2\@md {
    bottom: 2px; }
  .l-2\@md {
    left: 2px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-2\@sm {
    top: 2px; }
  .r-2\@sm {
    right: 2px; }
  .b-2\@sm {
    bottom: 2px; }
  .l-2\@sm {
    left: 2px; } }

.t-3 {
  top: 3px; }

.r-3 {
  right: 3px; }

.b-3 {
  bottom: 3px; }

.l-3 {
  left: 3px; }

@media screen and (min-width: 1440px) {
  .t-3\@xl\+ {
    top: 3px; }
  .r-3\@xl\+ {
    right: 3px; }
  .b-3\@xl\+ {
    bottom: 3px; }
  .l-3\@xl\+ {
    left: 3px; } }

@media screen and (min-width: 1200px) {
  .t-3\@lg\+ {
    top: 3px; }
  .r-3\@lg\+ {
    right: 3px; }
  .b-3\@lg\+ {
    bottom: 3px; }
  .l-3\@lg\+ {
    left: 3px; } }

@media screen and (min-width: 992px) {
  .t-3\@md\+ {
    top: 3px; }
  .r-3\@md\+ {
    right: 3px; }
  .b-3\@md\+ {
    bottom: 3px; }
  .l-3\@md\+ {
    left: 3px; } }

@media screen and (min-width: 768px) {
  .t-3\@sm\+ {
    top: 3px; }
  .r-3\@sm\+ {
    right: 3px; }
  .b-3\@sm\+ {
    bottom: 3px; }
  .l-3\@sm\+ {
    left: 3px; } }

@media screen and (min-width: 0) {
  .t-3\@xs\+ {
    top: 3px; }
  .r-3\@xs\+ {
    right: 3px; }
  .b-3\@xs\+ {
    bottom: 3px; }
  .l-3\@xs\+ {
    left: 3px; } }

@media screen and (max-width: 1439px) {
  .t-3\@xl- {
    top: 3px; }
  .r-3\@xl- {
    right: 3px; }
  .b-3\@xl- {
    bottom: 3px; }
  .l-3\@xl- {
    left: 3px; } }

@media screen and (max-width: 1199px) {
  .t-3\@lg- {
    top: 3px; }
  .r-3\@lg- {
    right: 3px; }
  .b-3\@lg- {
    bottom: 3px; }
  .l-3\@lg- {
    left: 3px; } }

@media screen and (max-width: 991px) {
  .t-3\@md- {
    top: 3px; }
  .r-3\@md- {
    right: 3px; }
  .b-3\@md- {
    bottom: 3px; }
  .l-3\@md- {
    left: 3px; } }

@media screen and (max-width: 767px) {
  .t-3\@sm- {
    top: 3px; }
  .r-3\@sm- {
    right: 3px; }
  .b-3\@sm- {
    bottom: 3px; }
  .l-3\@sm- {
    left: 3px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-3\@lg {
    top: 3px; }
  .r-3\@lg {
    right: 3px; }
  .b-3\@lg {
    bottom: 3px; }
  .l-3\@lg {
    left: 3px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-3\@md {
    top: 3px; }
  .r-3\@md {
    right: 3px; }
  .b-3\@md {
    bottom: 3px; }
  .l-3\@md {
    left: 3px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-3\@sm {
    top: 3px; }
  .r-3\@sm {
    right: 3px; }
  .b-3\@sm {
    bottom: 3px; }
  .l-3\@sm {
    left: 3px; } }

.t-4 {
  top: 4px; }

.r-4 {
  right: 4px; }

.b-4 {
  bottom: 4px; }

.l-4 {
  left: 4px; }

@media screen and (min-width: 1440px) {
  .t-4\@xl\+ {
    top: 4px; }
  .r-4\@xl\+ {
    right: 4px; }
  .b-4\@xl\+ {
    bottom: 4px; }
  .l-4\@xl\+ {
    left: 4px; } }

@media screen and (min-width: 1200px) {
  .t-4\@lg\+ {
    top: 4px; }
  .r-4\@lg\+ {
    right: 4px; }
  .b-4\@lg\+ {
    bottom: 4px; }
  .l-4\@lg\+ {
    left: 4px; } }

@media screen and (min-width: 992px) {
  .t-4\@md\+ {
    top: 4px; }
  .r-4\@md\+ {
    right: 4px; }
  .b-4\@md\+ {
    bottom: 4px; }
  .l-4\@md\+ {
    left: 4px; } }

@media screen and (min-width: 768px) {
  .t-4\@sm\+ {
    top: 4px; }
  .r-4\@sm\+ {
    right: 4px; }
  .b-4\@sm\+ {
    bottom: 4px; }
  .l-4\@sm\+ {
    left: 4px; } }

@media screen and (min-width: 0) {
  .t-4\@xs\+ {
    top: 4px; }
  .r-4\@xs\+ {
    right: 4px; }
  .b-4\@xs\+ {
    bottom: 4px; }
  .l-4\@xs\+ {
    left: 4px; } }

@media screen and (max-width: 1439px) {
  .t-4\@xl- {
    top: 4px; }
  .r-4\@xl- {
    right: 4px; }
  .b-4\@xl- {
    bottom: 4px; }
  .l-4\@xl- {
    left: 4px; } }

@media screen and (max-width: 1199px) {
  .t-4\@lg- {
    top: 4px; }
  .r-4\@lg- {
    right: 4px; }
  .b-4\@lg- {
    bottom: 4px; }
  .l-4\@lg- {
    left: 4px; } }

@media screen and (max-width: 991px) {
  .t-4\@md- {
    top: 4px; }
  .r-4\@md- {
    right: 4px; }
  .b-4\@md- {
    bottom: 4px; }
  .l-4\@md- {
    left: 4px; } }

@media screen and (max-width: 767px) {
  .t-4\@sm- {
    top: 4px; }
  .r-4\@sm- {
    right: 4px; }
  .b-4\@sm- {
    bottom: 4px; }
  .l-4\@sm- {
    left: 4px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-4\@lg {
    top: 4px; }
  .r-4\@lg {
    right: 4px; }
  .b-4\@lg {
    bottom: 4px; }
  .l-4\@lg {
    left: 4px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-4\@md {
    top: 4px; }
  .r-4\@md {
    right: 4px; }
  .b-4\@md {
    bottom: 4px; }
  .l-4\@md {
    left: 4px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-4\@sm {
    top: 4px; }
  .r-4\@sm {
    right: 4px; }
  .b-4\@sm {
    bottom: 4px; }
  .l-4\@sm {
    left: 4px; } }

.t-5 {
  top: 5px; }

.r-5 {
  right: 5px; }

.b-5 {
  bottom: 5px; }

.l-5 {
  left: 5px; }

@media screen and (min-width: 1440px) {
  .t-5\@xl\+ {
    top: 5px; }
  .r-5\@xl\+ {
    right: 5px; }
  .b-5\@xl\+ {
    bottom: 5px; }
  .l-5\@xl\+ {
    left: 5px; } }

@media screen and (min-width: 1200px) {
  .t-5\@lg\+ {
    top: 5px; }
  .r-5\@lg\+ {
    right: 5px; }
  .b-5\@lg\+ {
    bottom: 5px; }
  .l-5\@lg\+ {
    left: 5px; } }

@media screen and (min-width: 992px) {
  .t-5\@md\+ {
    top: 5px; }
  .r-5\@md\+ {
    right: 5px; }
  .b-5\@md\+ {
    bottom: 5px; }
  .l-5\@md\+ {
    left: 5px; } }

@media screen and (min-width: 768px) {
  .t-5\@sm\+ {
    top: 5px; }
  .r-5\@sm\+ {
    right: 5px; }
  .b-5\@sm\+ {
    bottom: 5px; }
  .l-5\@sm\+ {
    left: 5px; } }

@media screen and (min-width: 0) {
  .t-5\@xs\+ {
    top: 5px; }
  .r-5\@xs\+ {
    right: 5px; }
  .b-5\@xs\+ {
    bottom: 5px; }
  .l-5\@xs\+ {
    left: 5px; } }

@media screen and (max-width: 1439px) {
  .t-5\@xl- {
    top: 5px; }
  .r-5\@xl- {
    right: 5px; }
  .b-5\@xl- {
    bottom: 5px; }
  .l-5\@xl- {
    left: 5px; } }

@media screen and (max-width: 1199px) {
  .t-5\@lg- {
    top: 5px; }
  .r-5\@lg- {
    right: 5px; }
  .b-5\@lg- {
    bottom: 5px; }
  .l-5\@lg- {
    left: 5px; } }

@media screen and (max-width: 991px) {
  .t-5\@md- {
    top: 5px; }
  .r-5\@md- {
    right: 5px; }
  .b-5\@md- {
    bottom: 5px; }
  .l-5\@md- {
    left: 5px; } }

@media screen and (max-width: 767px) {
  .t-5\@sm- {
    top: 5px; }
  .r-5\@sm- {
    right: 5px; }
  .b-5\@sm- {
    bottom: 5px; }
  .l-5\@sm- {
    left: 5px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-5\@lg {
    top: 5px; }
  .r-5\@lg {
    right: 5px; }
  .b-5\@lg {
    bottom: 5px; }
  .l-5\@lg {
    left: 5px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-5\@md {
    top: 5px; }
  .r-5\@md {
    right: 5px; }
  .b-5\@md {
    bottom: 5px; }
  .l-5\@md {
    left: 5px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-5\@sm {
    top: 5px; }
  .r-5\@sm {
    right: 5px; }
  .b-5\@sm {
    bottom: 5px; }
  .l-5\@sm {
    left: 5px; } }

.t-10 {
  top: 10px; }

.r-10 {
  right: 10px; }

.b-10 {
  bottom: 10px; }

.l-10 {
  left: 10px; }

@media screen and (min-width: 1440px) {
  .t-10\@xl\+ {
    top: 10px; }
  .r-10\@xl\+ {
    right: 10px; }
  .b-10\@xl\+ {
    bottom: 10px; }
  .l-10\@xl\+ {
    left: 10px; } }

@media screen and (min-width: 1200px) {
  .t-10\@lg\+ {
    top: 10px; }
  .r-10\@lg\+ {
    right: 10px; }
  .b-10\@lg\+ {
    bottom: 10px; }
  .l-10\@lg\+ {
    left: 10px; } }

@media screen and (min-width: 992px) {
  .t-10\@md\+ {
    top: 10px; }
  .r-10\@md\+ {
    right: 10px; }
  .b-10\@md\+ {
    bottom: 10px; }
  .l-10\@md\+ {
    left: 10px; } }

@media screen and (min-width: 768px) {
  .t-10\@sm\+ {
    top: 10px; }
  .r-10\@sm\+ {
    right: 10px; }
  .b-10\@sm\+ {
    bottom: 10px; }
  .l-10\@sm\+ {
    left: 10px; } }

@media screen and (min-width: 0) {
  .t-10\@xs\+ {
    top: 10px; }
  .r-10\@xs\+ {
    right: 10px; }
  .b-10\@xs\+ {
    bottom: 10px; }
  .l-10\@xs\+ {
    left: 10px; } }

@media screen and (max-width: 1439px) {
  .t-10\@xl- {
    top: 10px; }
  .r-10\@xl- {
    right: 10px; }
  .b-10\@xl- {
    bottom: 10px; }
  .l-10\@xl- {
    left: 10px; } }

@media screen and (max-width: 1199px) {
  .t-10\@lg- {
    top: 10px; }
  .r-10\@lg- {
    right: 10px; }
  .b-10\@lg- {
    bottom: 10px; }
  .l-10\@lg- {
    left: 10px; } }

@media screen and (max-width: 991px) {
  .t-10\@md- {
    top: 10px; }
  .r-10\@md- {
    right: 10px; }
  .b-10\@md- {
    bottom: 10px; }
  .l-10\@md- {
    left: 10px; } }

@media screen and (max-width: 767px) {
  .t-10\@sm- {
    top: 10px; }
  .r-10\@sm- {
    right: 10px; }
  .b-10\@sm- {
    bottom: 10px; }
  .l-10\@sm- {
    left: 10px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-10\@lg {
    top: 10px; }
  .r-10\@lg {
    right: 10px; }
  .b-10\@lg {
    bottom: 10px; }
  .l-10\@lg {
    left: 10px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-10\@md {
    top: 10px; }
  .r-10\@md {
    right: 10px; }
  .b-10\@md {
    bottom: 10px; }
  .l-10\@md {
    left: 10px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-10\@sm {
    top: 10px; }
  .r-10\@sm {
    right: 10px; }
  .b-10\@sm {
    bottom: 10px; }
  .l-10\@sm {
    left: 10px; } }

.t-15 {
  top: 15px; }

.r-15 {
  right: 15px; }

.b-15 {
  bottom: 15px; }

.l-15 {
  left: 15px; }

@media screen and (min-width: 1440px) {
  .t-15\@xl\+ {
    top: 15px; }
  .r-15\@xl\+ {
    right: 15px; }
  .b-15\@xl\+ {
    bottom: 15px; }
  .l-15\@xl\+ {
    left: 15px; } }

@media screen and (min-width: 1200px) {
  .t-15\@lg\+ {
    top: 15px; }
  .r-15\@lg\+ {
    right: 15px; }
  .b-15\@lg\+ {
    bottom: 15px; }
  .l-15\@lg\+ {
    left: 15px; } }

@media screen and (min-width: 992px) {
  .t-15\@md\+ {
    top: 15px; }
  .r-15\@md\+ {
    right: 15px; }
  .b-15\@md\+ {
    bottom: 15px; }
  .l-15\@md\+ {
    left: 15px; } }

@media screen and (min-width: 768px) {
  .t-15\@sm\+ {
    top: 15px; }
  .r-15\@sm\+ {
    right: 15px; }
  .b-15\@sm\+ {
    bottom: 15px; }
  .l-15\@sm\+ {
    left: 15px; } }

@media screen and (min-width: 0) {
  .t-15\@xs\+ {
    top: 15px; }
  .r-15\@xs\+ {
    right: 15px; }
  .b-15\@xs\+ {
    bottom: 15px; }
  .l-15\@xs\+ {
    left: 15px; } }

@media screen and (max-width: 1439px) {
  .t-15\@xl- {
    top: 15px; }
  .r-15\@xl- {
    right: 15px; }
  .b-15\@xl- {
    bottom: 15px; }
  .l-15\@xl- {
    left: 15px; } }

@media screen and (max-width: 1199px) {
  .t-15\@lg- {
    top: 15px; }
  .r-15\@lg- {
    right: 15px; }
  .b-15\@lg- {
    bottom: 15px; }
  .l-15\@lg- {
    left: 15px; } }

@media screen and (max-width: 991px) {
  .t-15\@md- {
    top: 15px; }
  .r-15\@md- {
    right: 15px; }
  .b-15\@md- {
    bottom: 15px; }
  .l-15\@md- {
    left: 15px; } }

@media screen and (max-width: 767px) {
  .t-15\@sm- {
    top: 15px; }
  .r-15\@sm- {
    right: 15px; }
  .b-15\@sm- {
    bottom: 15px; }
  .l-15\@sm- {
    left: 15px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-15\@lg {
    top: 15px; }
  .r-15\@lg {
    right: 15px; }
  .b-15\@lg {
    bottom: 15px; }
  .l-15\@lg {
    left: 15px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-15\@md {
    top: 15px; }
  .r-15\@md {
    right: 15px; }
  .b-15\@md {
    bottom: 15px; }
  .l-15\@md {
    left: 15px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-15\@sm {
    top: 15px; }
  .r-15\@sm {
    right: 15px; }
  .b-15\@sm {
    bottom: 15px; }
  .l-15\@sm {
    left: 15px; } }

.t-20 {
  top: 20px; }

.r-20 {
  right: 20px; }

.b-20 {
  bottom: 20px; }

.l-20 {
  left: 20px; }

@media screen and (min-width: 1440px) {
  .t-20\@xl\+ {
    top: 20px; }
  .r-20\@xl\+ {
    right: 20px; }
  .b-20\@xl\+ {
    bottom: 20px; }
  .l-20\@xl\+ {
    left: 20px; } }

@media screen and (min-width: 1200px) {
  .t-20\@lg\+ {
    top: 20px; }
  .r-20\@lg\+ {
    right: 20px; }
  .b-20\@lg\+ {
    bottom: 20px; }
  .l-20\@lg\+ {
    left: 20px; } }

@media screen and (min-width: 992px) {
  .t-20\@md\+ {
    top: 20px; }
  .r-20\@md\+ {
    right: 20px; }
  .b-20\@md\+ {
    bottom: 20px; }
  .l-20\@md\+ {
    left: 20px; } }

@media screen and (min-width: 768px) {
  .t-20\@sm\+ {
    top: 20px; }
  .r-20\@sm\+ {
    right: 20px; }
  .b-20\@sm\+ {
    bottom: 20px; }
  .l-20\@sm\+ {
    left: 20px; } }

@media screen and (min-width: 0) {
  .t-20\@xs\+ {
    top: 20px; }
  .r-20\@xs\+ {
    right: 20px; }
  .b-20\@xs\+ {
    bottom: 20px; }
  .l-20\@xs\+ {
    left: 20px; } }

@media screen and (max-width: 1439px) {
  .t-20\@xl- {
    top: 20px; }
  .r-20\@xl- {
    right: 20px; }
  .b-20\@xl- {
    bottom: 20px; }
  .l-20\@xl- {
    left: 20px; } }

@media screen and (max-width: 1199px) {
  .t-20\@lg- {
    top: 20px; }
  .r-20\@lg- {
    right: 20px; }
  .b-20\@lg- {
    bottom: 20px; }
  .l-20\@lg- {
    left: 20px; } }

@media screen and (max-width: 991px) {
  .t-20\@md- {
    top: 20px; }
  .r-20\@md- {
    right: 20px; }
  .b-20\@md- {
    bottom: 20px; }
  .l-20\@md- {
    left: 20px; } }

@media screen and (max-width: 767px) {
  .t-20\@sm- {
    top: 20px; }
  .r-20\@sm- {
    right: 20px; }
  .b-20\@sm- {
    bottom: 20px; }
  .l-20\@sm- {
    left: 20px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-20\@lg {
    top: 20px; }
  .r-20\@lg {
    right: 20px; }
  .b-20\@lg {
    bottom: 20px; }
  .l-20\@lg {
    left: 20px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-20\@md {
    top: 20px; }
  .r-20\@md {
    right: 20px; }
  .b-20\@md {
    bottom: 20px; }
  .l-20\@md {
    left: 20px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-20\@sm {
    top: 20px; }
  .r-20\@sm {
    right: 20px; }
  .b-20\@sm {
    bottom: 20px; }
  .l-20\@sm {
    left: 20px; } }

.t-25 {
  top: 25px; }

.r-25 {
  right: 25px; }

.b-25 {
  bottom: 25px; }

.l-25 {
  left: 25px; }

@media screen and (min-width: 1440px) {
  .t-25\@xl\+ {
    top: 25px; }
  .r-25\@xl\+ {
    right: 25px; }
  .b-25\@xl\+ {
    bottom: 25px; }
  .l-25\@xl\+ {
    left: 25px; } }

@media screen and (min-width: 1200px) {
  .t-25\@lg\+ {
    top: 25px; }
  .r-25\@lg\+ {
    right: 25px; }
  .b-25\@lg\+ {
    bottom: 25px; }
  .l-25\@lg\+ {
    left: 25px; } }

@media screen and (min-width: 992px) {
  .t-25\@md\+ {
    top: 25px; }
  .r-25\@md\+ {
    right: 25px; }
  .b-25\@md\+ {
    bottom: 25px; }
  .l-25\@md\+ {
    left: 25px; } }

@media screen and (min-width: 768px) {
  .t-25\@sm\+ {
    top: 25px; }
  .r-25\@sm\+ {
    right: 25px; }
  .b-25\@sm\+ {
    bottom: 25px; }
  .l-25\@sm\+ {
    left: 25px; } }

@media screen and (min-width: 0) {
  .t-25\@xs\+ {
    top: 25px; }
  .r-25\@xs\+ {
    right: 25px; }
  .b-25\@xs\+ {
    bottom: 25px; }
  .l-25\@xs\+ {
    left: 25px; } }

@media screen and (max-width: 1439px) {
  .t-25\@xl- {
    top: 25px; }
  .r-25\@xl- {
    right: 25px; }
  .b-25\@xl- {
    bottom: 25px; }
  .l-25\@xl- {
    left: 25px; } }

@media screen and (max-width: 1199px) {
  .t-25\@lg- {
    top: 25px; }
  .r-25\@lg- {
    right: 25px; }
  .b-25\@lg- {
    bottom: 25px; }
  .l-25\@lg- {
    left: 25px; } }

@media screen and (max-width: 991px) {
  .t-25\@md- {
    top: 25px; }
  .r-25\@md- {
    right: 25px; }
  .b-25\@md- {
    bottom: 25px; }
  .l-25\@md- {
    left: 25px; } }

@media screen and (max-width: 767px) {
  .t-25\@sm- {
    top: 25px; }
  .r-25\@sm- {
    right: 25px; }
  .b-25\@sm- {
    bottom: 25px; }
  .l-25\@sm- {
    left: 25px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-25\@lg {
    top: 25px; }
  .r-25\@lg {
    right: 25px; }
  .b-25\@lg {
    bottom: 25px; }
  .l-25\@lg {
    left: 25px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-25\@md {
    top: 25px; }
  .r-25\@md {
    right: 25px; }
  .b-25\@md {
    bottom: 25px; }
  .l-25\@md {
    left: 25px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-25\@sm {
    top: 25px; }
  .r-25\@sm {
    right: 25px; }
  .b-25\@sm {
    bottom: 25px; }
  .l-25\@sm {
    left: 25px; } }

.t-30 {
  top: 30px; }

.r-30 {
  right: 30px; }

.b-30 {
  bottom: 30px; }

.l-30 {
  left: 30px; }

@media screen and (min-width: 1440px) {
  .t-30\@xl\+ {
    top: 30px; }
  .r-30\@xl\+ {
    right: 30px; }
  .b-30\@xl\+ {
    bottom: 30px; }
  .l-30\@xl\+ {
    left: 30px; } }

@media screen and (min-width: 1200px) {
  .t-30\@lg\+ {
    top: 30px; }
  .r-30\@lg\+ {
    right: 30px; }
  .b-30\@lg\+ {
    bottom: 30px; }
  .l-30\@lg\+ {
    left: 30px; } }

@media screen and (min-width: 992px) {
  .t-30\@md\+ {
    top: 30px; }
  .r-30\@md\+ {
    right: 30px; }
  .b-30\@md\+ {
    bottom: 30px; }
  .l-30\@md\+ {
    left: 30px; } }

@media screen and (min-width: 768px) {
  .t-30\@sm\+ {
    top: 30px; }
  .r-30\@sm\+ {
    right: 30px; }
  .b-30\@sm\+ {
    bottom: 30px; }
  .l-30\@sm\+ {
    left: 30px; } }

@media screen and (min-width: 0) {
  .t-30\@xs\+ {
    top: 30px; }
  .r-30\@xs\+ {
    right: 30px; }
  .b-30\@xs\+ {
    bottom: 30px; }
  .l-30\@xs\+ {
    left: 30px; } }

@media screen and (max-width: 1439px) {
  .t-30\@xl- {
    top: 30px; }
  .r-30\@xl- {
    right: 30px; }
  .b-30\@xl- {
    bottom: 30px; }
  .l-30\@xl- {
    left: 30px; } }

@media screen and (max-width: 1199px) {
  .t-30\@lg- {
    top: 30px; }
  .r-30\@lg- {
    right: 30px; }
  .b-30\@lg- {
    bottom: 30px; }
  .l-30\@lg- {
    left: 30px; } }

@media screen and (max-width: 991px) {
  .t-30\@md- {
    top: 30px; }
  .r-30\@md- {
    right: 30px; }
  .b-30\@md- {
    bottom: 30px; }
  .l-30\@md- {
    left: 30px; } }

@media screen and (max-width: 767px) {
  .t-30\@sm- {
    top: 30px; }
  .r-30\@sm- {
    right: 30px; }
  .b-30\@sm- {
    bottom: 30px; }
  .l-30\@sm- {
    left: 30px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-30\@lg {
    top: 30px; }
  .r-30\@lg {
    right: 30px; }
  .b-30\@lg {
    bottom: 30px; }
  .l-30\@lg {
    left: 30px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-30\@md {
    top: 30px; }
  .r-30\@md {
    right: 30px; }
  .b-30\@md {
    bottom: 30px; }
  .l-30\@md {
    left: 30px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-30\@sm {
    top: 30px; }
  .r-30\@sm {
    right: 30px; }
  .b-30\@sm {
    bottom: 30px; }
  .l-30\@sm {
    left: 30px; } }

.t-35 {
  top: 35px; }

.r-35 {
  right: 35px; }

.b-35 {
  bottom: 35px; }

.l-35 {
  left: 35px; }

@media screen and (min-width: 1440px) {
  .t-35\@xl\+ {
    top: 35px; }
  .r-35\@xl\+ {
    right: 35px; }
  .b-35\@xl\+ {
    bottom: 35px; }
  .l-35\@xl\+ {
    left: 35px; } }

@media screen and (min-width: 1200px) {
  .t-35\@lg\+ {
    top: 35px; }
  .r-35\@lg\+ {
    right: 35px; }
  .b-35\@lg\+ {
    bottom: 35px; }
  .l-35\@lg\+ {
    left: 35px; } }

@media screen and (min-width: 992px) {
  .t-35\@md\+ {
    top: 35px; }
  .r-35\@md\+ {
    right: 35px; }
  .b-35\@md\+ {
    bottom: 35px; }
  .l-35\@md\+ {
    left: 35px; } }

@media screen and (min-width: 768px) {
  .t-35\@sm\+ {
    top: 35px; }
  .r-35\@sm\+ {
    right: 35px; }
  .b-35\@sm\+ {
    bottom: 35px; }
  .l-35\@sm\+ {
    left: 35px; } }

@media screen and (min-width: 0) {
  .t-35\@xs\+ {
    top: 35px; }
  .r-35\@xs\+ {
    right: 35px; }
  .b-35\@xs\+ {
    bottom: 35px; }
  .l-35\@xs\+ {
    left: 35px; } }

@media screen and (max-width: 1439px) {
  .t-35\@xl- {
    top: 35px; }
  .r-35\@xl- {
    right: 35px; }
  .b-35\@xl- {
    bottom: 35px; }
  .l-35\@xl- {
    left: 35px; } }

@media screen and (max-width: 1199px) {
  .t-35\@lg- {
    top: 35px; }
  .r-35\@lg- {
    right: 35px; }
  .b-35\@lg- {
    bottom: 35px; }
  .l-35\@lg- {
    left: 35px; } }

@media screen and (max-width: 991px) {
  .t-35\@md- {
    top: 35px; }
  .r-35\@md- {
    right: 35px; }
  .b-35\@md- {
    bottom: 35px; }
  .l-35\@md- {
    left: 35px; } }

@media screen and (max-width: 767px) {
  .t-35\@sm- {
    top: 35px; }
  .r-35\@sm- {
    right: 35px; }
  .b-35\@sm- {
    bottom: 35px; }
  .l-35\@sm- {
    left: 35px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-35\@lg {
    top: 35px; }
  .r-35\@lg {
    right: 35px; }
  .b-35\@lg {
    bottom: 35px; }
  .l-35\@lg {
    left: 35px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-35\@md {
    top: 35px; }
  .r-35\@md {
    right: 35px; }
  .b-35\@md {
    bottom: 35px; }
  .l-35\@md {
    left: 35px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-35\@sm {
    top: 35px; }
  .r-35\@sm {
    right: 35px; }
  .b-35\@sm {
    bottom: 35px; }
  .l-35\@sm {
    left: 35px; } }

.t-40 {
  top: 40px; }

.r-40 {
  right: 40px; }

.b-40 {
  bottom: 40px; }

.l-40 {
  left: 40px; }

@media screen and (min-width: 1440px) {
  .t-40\@xl\+ {
    top: 40px; }
  .r-40\@xl\+ {
    right: 40px; }
  .b-40\@xl\+ {
    bottom: 40px; }
  .l-40\@xl\+ {
    left: 40px; } }

@media screen and (min-width: 1200px) {
  .t-40\@lg\+ {
    top: 40px; }
  .r-40\@lg\+ {
    right: 40px; }
  .b-40\@lg\+ {
    bottom: 40px; }
  .l-40\@lg\+ {
    left: 40px; } }

@media screen and (min-width: 992px) {
  .t-40\@md\+ {
    top: 40px; }
  .r-40\@md\+ {
    right: 40px; }
  .b-40\@md\+ {
    bottom: 40px; }
  .l-40\@md\+ {
    left: 40px; } }

@media screen and (min-width: 768px) {
  .t-40\@sm\+ {
    top: 40px; }
  .r-40\@sm\+ {
    right: 40px; }
  .b-40\@sm\+ {
    bottom: 40px; }
  .l-40\@sm\+ {
    left: 40px; } }

@media screen and (min-width: 0) {
  .t-40\@xs\+ {
    top: 40px; }
  .r-40\@xs\+ {
    right: 40px; }
  .b-40\@xs\+ {
    bottom: 40px; }
  .l-40\@xs\+ {
    left: 40px; } }

@media screen and (max-width: 1439px) {
  .t-40\@xl- {
    top: 40px; }
  .r-40\@xl- {
    right: 40px; }
  .b-40\@xl- {
    bottom: 40px; }
  .l-40\@xl- {
    left: 40px; } }

@media screen and (max-width: 1199px) {
  .t-40\@lg- {
    top: 40px; }
  .r-40\@lg- {
    right: 40px; }
  .b-40\@lg- {
    bottom: 40px; }
  .l-40\@lg- {
    left: 40px; } }

@media screen and (max-width: 991px) {
  .t-40\@md- {
    top: 40px; }
  .r-40\@md- {
    right: 40px; }
  .b-40\@md- {
    bottom: 40px; }
  .l-40\@md- {
    left: 40px; } }

@media screen and (max-width: 767px) {
  .t-40\@sm- {
    top: 40px; }
  .r-40\@sm- {
    right: 40px; }
  .b-40\@sm- {
    bottom: 40px; }
  .l-40\@sm- {
    left: 40px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-40\@lg {
    top: 40px; }
  .r-40\@lg {
    right: 40px; }
  .b-40\@lg {
    bottom: 40px; }
  .l-40\@lg {
    left: 40px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-40\@md {
    top: 40px; }
  .r-40\@md {
    right: 40px; }
  .b-40\@md {
    bottom: 40px; }
  .l-40\@md {
    left: 40px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-40\@sm {
    top: 40px; }
  .r-40\@sm {
    right: 40px; }
  .b-40\@sm {
    bottom: 40px; }
  .l-40\@sm {
    left: 40px; } }

.t-50 {
  top: 50px; }

.r-50 {
  right: 50px; }

.b-50 {
  bottom: 50px; }

.l-50 {
  left: 50px; }

@media screen and (min-width: 1440px) {
  .t-50\@xl\+ {
    top: 50px; }
  .r-50\@xl\+ {
    right: 50px; }
  .b-50\@xl\+ {
    bottom: 50px; }
  .l-50\@xl\+ {
    left: 50px; } }

@media screen and (min-width: 1200px) {
  .t-50\@lg\+ {
    top: 50px; }
  .r-50\@lg\+ {
    right: 50px; }
  .b-50\@lg\+ {
    bottom: 50px; }
  .l-50\@lg\+ {
    left: 50px; } }

@media screen and (min-width: 992px) {
  .t-50\@md\+ {
    top: 50px; }
  .r-50\@md\+ {
    right: 50px; }
  .b-50\@md\+ {
    bottom: 50px; }
  .l-50\@md\+ {
    left: 50px; } }

@media screen and (min-width: 768px) {
  .t-50\@sm\+ {
    top: 50px; }
  .r-50\@sm\+ {
    right: 50px; }
  .b-50\@sm\+ {
    bottom: 50px; }
  .l-50\@sm\+ {
    left: 50px; } }

@media screen and (min-width: 0) {
  .t-50\@xs\+ {
    top: 50px; }
  .r-50\@xs\+ {
    right: 50px; }
  .b-50\@xs\+ {
    bottom: 50px; }
  .l-50\@xs\+ {
    left: 50px; } }

@media screen and (max-width: 1439px) {
  .t-50\@xl- {
    top: 50px; }
  .r-50\@xl- {
    right: 50px; }
  .b-50\@xl- {
    bottom: 50px; }
  .l-50\@xl- {
    left: 50px; } }

@media screen and (max-width: 1199px) {
  .t-50\@lg- {
    top: 50px; }
  .r-50\@lg- {
    right: 50px; }
  .b-50\@lg- {
    bottom: 50px; }
  .l-50\@lg- {
    left: 50px; } }

@media screen and (max-width: 991px) {
  .t-50\@md- {
    top: 50px; }
  .r-50\@md- {
    right: 50px; }
  .b-50\@md- {
    bottom: 50px; }
  .l-50\@md- {
    left: 50px; } }

@media screen and (max-width: 767px) {
  .t-50\@sm- {
    top: 50px; }
  .r-50\@sm- {
    right: 50px; }
  .b-50\@sm- {
    bottom: 50px; }
  .l-50\@sm- {
    left: 50px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-50\@lg {
    top: 50px; }
  .r-50\@lg {
    right: 50px; }
  .b-50\@lg {
    bottom: 50px; }
  .l-50\@lg {
    left: 50px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-50\@md {
    top: 50px; }
  .r-50\@md {
    right: 50px; }
  .b-50\@md {
    bottom: 50px; }
  .l-50\@md {
    left: 50px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-50\@sm {
    top: 50px; }
  .r-50\@sm {
    right: 50px; }
  .b-50\@sm {
    bottom: 50px; }
  .l-50\@sm {
    left: 50px; } }

.t-60 {
  top: 60px; }

.r-60 {
  right: 60px; }

.b-60 {
  bottom: 60px; }

.l-60 {
  left: 60px; }

@media screen and (min-width: 1440px) {
  .t-60\@xl\+ {
    top: 60px; }
  .r-60\@xl\+ {
    right: 60px; }
  .b-60\@xl\+ {
    bottom: 60px; }
  .l-60\@xl\+ {
    left: 60px; } }

@media screen and (min-width: 1200px) {
  .t-60\@lg\+ {
    top: 60px; }
  .r-60\@lg\+ {
    right: 60px; }
  .b-60\@lg\+ {
    bottom: 60px; }
  .l-60\@lg\+ {
    left: 60px; } }

@media screen and (min-width: 992px) {
  .t-60\@md\+ {
    top: 60px; }
  .r-60\@md\+ {
    right: 60px; }
  .b-60\@md\+ {
    bottom: 60px; }
  .l-60\@md\+ {
    left: 60px; } }

@media screen and (min-width: 768px) {
  .t-60\@sm\+ {
    top: 60px; }
  .r-60\@sm\+ {
    right: 60px; }
  .b-60\@sm\+ {
    bottom: 60px; }
  .l-60\@sm\+ {
    left: 60px; } }

@media screen and (min-width: 0) {
  .t-60\@xs\+ {
    top: 60px; }
  .r-60\@xs\+ {
    right: 60px; }
  .b-60\@xs\+ {
    bottom: 60px; }
  .l-60\@xs\+ {
    left: 60px; } }

@media screen and (max-width: 1439px) {
  .t-60\@xl- {
    top: 60px; }
  .r-60\@xl- {
    right: 60px; }
  .b-60\@xl- {
    bottom: 60px; }
  .l-60\@xl- {
    left: 60px; } }

@media screen and (max-width: 1199px) {
  .t-60\@lg- {
    top: 60px; }
  .r-60\@lg- {
    right: 60px; }
  .b-60\@lg- {
    bottom: 60px; }
  .l-60\@lg- {
    left: 60px; } }

@media screen and (max-width: 991px) {
  .t-60\@md- {
    top: 60px; }
  .r-60\@md- {
    right: 60px; }
  .b-60\@md- {
    bottom: 60px; }
  .l-60\@md- {
    left: 60px; } }

@media screen and (max-width: 767px) {
  .t-60\@sm- {
    top: 60px; }
  .r-60\@sm- {
    right: 60px; }
  .b-60\@sm- {
    bottom: 60px; }
  .l-60\@sm- {
    left: 60px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-60\@lg {
    top: 60px; }
  .r-60\@lg {
    right: 60px; }
  .b-60\@lg {
    bottom: 60px; }
  .l-60\@lg {
    left: 60px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-60\@md {
    top: 60px; }
  .r-60\@md {
    right: 60px; }
  .b-60\@md {
    bottom: 60px; }
  .l-60\@md {
    left: 60px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-60\@sm {
    top: 60px; }
  .r-60\@sm {
    right: 60px; }
  .b-60\@sm {
    bottom: 60px; }
  .l-60\@sm {
    left: 60px; } }

.t-70 {
  top: 70px; }

.r-70 {
  right: 70px; }

.b-70 {
  bottom: 70px; }

.l-70 {
  left: 70px; }

@media screen and (min-width: 1440px) {
  .t-70\@xl\+ {
    top: 70px; }
  .r-70\@xl\+ {
    right: 70px; }
  .b-70\@xl\+ {
    bottom: 70px; }
  .l-70\@xl\+ {
    left: 70px; } }

@media screen and (min-width: 1200px) {
  .t-70\@lg\+ {
    top: 70px; }
  .r-70\@lg\+ {
    right: 70px; }
  .b-70\@lg\+ {
    bottom: 70px; }
  .l-70\@lg\+ {
    left: 70px; } }

@media screen and (min-width: 992px) {
  .t-70\@md\+ {
    top: 70px; }
  .r-70\@md\+ {
    right: 70px; }
  .b-70\@md\+ {
    bottom: 70px; }
  .l-70\@md\+ {
    left: 70px; } }

@media screen and (min-width: 768px) {
  .t-70\@sm\+ {
    top: 70px; }
  .r-70\@sm\+ {
    right: 70px; }
  .b-70\@sm\+ {
    bottom: 70px; }
  .l-70\@sm\+ {
    left: 70px; } }

@media screen and (min-width: 0) {
  .t-70\@xs\+ {
    top: 70px; }
  .r-70\@xs\+ {
    right: 70px; }
  .b-70\@xs\+ {
    bottom: 70px; }
  .l-70\@xs\+ {
    left: 70px; } }

@media screen and (max-width: 1439px) {
  .t-70\@xl- {
    top: 70px; }
  .r-70\@xl- {
    right: 70px; }
  .b-70\@xl- {
    bottom: 70px; }
  .l-70\@xl- {
    left: 70px; } }

@media screen and (max-width: 1199px) {
  .t-70\@lg- {
    top: 70px; }
  .r-70\@lg- {
    right: 70px; }
  .b-70\@lg- {
    bottom: 70px; }
  .l-70\@lg- {
    left: 70px; } }

@media screen and (max-width: 991px) {
  .t-70\@md- {
    top: 70px; }
  .r-70\@md- {
    right: 70px; }
  .b-70\@md- {
    bottom: 70px; }
  .l-70\@md- {
    left: 70px; } }

@media screen and (max-width: 767px) {
  .t-70\@sm- {
    top: 70px; }
  .r-70\@sm- {
    right: 70px; }
  .b-70\@sm- {
    bottom: 70px; }
  .l-70\@sm- {
    left: 70px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-70\@lg {
    top: 70px; }
  .r-70\@lg {
    right: 70px; }
  .b-70\@lg {
    bottom: 70px; }
  .l-70\@lg {
    left: 70px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-70\@md {
    top: 70px; }
  .r-70\@md {
    right: 70px; }
  .b-70\@md {
    bottom: 70px; }
  .l-70\@md {
    left: 70px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-70\@sm {
    top: 70px; }
  .r-70\@sm {
    right: 70px; }
  .b-70\@sm {
    bottom: 70px; }
  .l-70\@sm {
    left: 70px; } }

.t-80 {
  top: 80px; }

.r-80 {
  right: 80px; }

.b-80 {
  bottom: 80px; }

.l-80 {
  left: 80px; }

@media screen and (min-width: 1440px) {
  .t-80\@xl\+ {
    top: 80px; }
  .r-80\@xl\+ {
    right: 80px; }
  .b-80\@xl\+ {
    bottom: 80px; }
  .l-80\@xl\+ {
    left: 80px; } }

@media screen and (min-width: 1200px) {
  .t-80\@lg\+ {
    top: 80px; }
  .r-80\@lg\+ {
    right: 80px; }
  .b-80\@lg\+ {
    bottom: 80px; }
  .l-80\@lg\+ {
    left: 80px; } }

@media screen and (min-width: 992px) {
  .t-80\@md\+ {
    top: 80px; }
  .r-80\@md\+ {
    right: 80px; }
  .b-80\@md\+ {
    bottom: 80px; }
  .l-80\@md\+ {
    left: 80px; } }

@media screen and (min-width: 768px) {
  .t-80\@sm\+ {
    top: 80px; }
  .r-80\@sm\+ {
    right: 80px; }
  .b-80\@sm\+ {
    bottom: 80px; }
  .l-80\@sm\+ {
    left: 80px; } }

@media screen and (min-width: 0) {
  .t-80\@xs\+ {
    top: 80px; }
  .r-80\@xs\+ {
    right: 80px; }
  .b-80\@xs\+ {
    bottom: 80px; }
  .l-80\@xs\+ {
    left: 80px; } }

@media screen and (max-width: 1439px) {
  .t-80\@xl- {
    top: 80px; }
  .r-80\@xl- {
    right: 80px; }
  .b-80\@xl- {
    bottom: 80px; }
  .l-80\@xl- {
    left: 80px; } }

@media screen and (max-width: 1199px) {
  .t-80\@lg- {
    top: 80px; }
  .r-80\@lg- {
    right: 80px; }
  .b-80\@lg- {
    bottom: 80px; }
  .l-80\@lg- {
    left: 80px; } }

@media screen and (max-width: 991px) {
  .t-80\@md- {
    top: 80px; }
  .r-80\@md- {
    right: 80px; }
  .b-80\@md- {
    bottom: 80px; }
  .l-80\@md- {
    left: 80px; } }

@media screen and (max-width: 767px) {
  .t-80\@sm- {
    top: 80px; }
  .r-80\@sm- {
    right: 80px; }
  .b-80\@sm- {
    bottom: 80px; }
  .l-80\@sm- {
    left: 80px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-80\@lg {
    top: 80px; }
  .r-80\@lg {
    right: 80px; }
  .b-80\@lg {
    bottom: 80px; }
  .l-80\@lg {
    left: 80px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-80\@md {
    top: 80px; }
  .r-80\@md {
    right: 80px; }
  .b-80\@md {
    bottom: 80px; }
  .l-80\@md {
    left: 80px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-80\@sm {
    top: 80px; }
  .r-80\@sm {
    right: 80px; }
  .b-80\@sm {
    bottom: 80px; }
  .l-80\@sm {
    left: 80px; } }

.t-90 {
  top: 90px; }

.r-90 {
  right: 90px; }

.b-90 {
  bottom: 90px; }

.l-90 {
  left: 90px; }

@media screen and (min-width: 1440px) {
  .t-90\@xl\+ {
    top: 90px; }
  .r-90\@xl\+ {
    right: 90px; }
  .b-90\@xl\+ {
    bottom: 90px; }
  .l-90\@xl\+ {
    left: 90px; } }

@media screen and (min-width: 1200px) {
  .t-90\@lg\+ {
    top: 90px; }
  .r-90\@lg\+ {
    right: 90px; }
  .b-90\@lg\+ {
    bottom: 90px; }
  .l-90\@lg\+ {
    left: 90px; } }

@media screen and (min-width: 992px) {
  .t-90\@md\+ {
    top: 90px; }
  .r-90\@md\+ {
    right: 90px; }
  .b-90\@md\+ {
    bottom: 90px; }
  .l-90\@md\+ {
    left: 90px; } }

@media screen and (min-width: 768px) {
  .t-90\@sm\+ {
    top: 90px; }
  .r-90\@sm\+ {
    right: 90px; }
  .b-90\@sm\+ {
    bottom: 90px; }
  .l-90\@sm\+ {
    left: 90px; } }

@media screen and (min-width: 0) {
  .t-90\@xs\+ {
    top: 90px; }
  .r-90\@xs\+ {
    right: 90px; }
  .b-90\@xs\+ {
    bottom: 90px; }
  .l-90\@xs\+ {
    left: 90px; } }

@media screen and (max-width: 1439px) {
  .t-90\@xl- {
    top: 90px; }
  .r-90\@xl- {
    right: 90px; }
  .b-90\@xl- {
    bottom: 90px; }
  .l-90\@xl- {
    left: 90px; } }

@media screen and (max-width: 1199px) {
  .t-90\@lg- {
    top: 90px; }
  .r-90\@lg- {
    right: 90px; }
  .b-90\@lg- {
    bottom: 90px; }
  .l-90\@lg- {
    left: 90px; } }

@media screen and (max-width: 991px) {
  .t-90\@md- {
    top: 90px; }
  .r-90\@md- {
    right: 90px; }
  .b-90\@md- {
    bottom: 90px; }
  .l-90\@md- {
    left: 90px; } }

@media screen and (max-width: 767px) {
  .t-90\@sm- {
    top: 90px; }
  .r-90\@sm- {
    right: 90px; }
  .b-90\@sm- {
    bottom: 90px; }
  .l-90\@sm- {
    left: 90px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-90\@lg {
    top: 90px; }
  .r-90\@lg {
    right: 90px; }
  .b-90\@lg {
    bottom: 90px; }
  .l-90\@lg {
    left: 90px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-90\@md {
    top: 90px; }
  .r-90\@md {
    right: 90px; }
  .b-90\@md {
    bottom: 90px; }
  .l-90\@md {
    left: 90px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-90\@sm {
    top: 90px; }
  .r-90\@sm {
    right: 90px; }
  .b-90\@sm {
    bottom: 90px; }
  .l-90\@sm {
    left: 90px; } }

.t-100 {
  top: 100px; }

.r-100 {
  right: 100px; }

.b-100 {
  bottom: 100px; }

.l-100 {
  left: 100px; }

@media screen and (min-width: 1440px) {
  .t-100\@xl\+ {
    top: 100px; }
  .r-100\@xl\+ {
    right: 100px; }
  .b-100\@xl\+ {
    bottom: 100px; }
  .l-100\@xl\+ {
    left: 100px; } }

@media screen and (min-width: 1200px) {
  .t-100\@lg\+ {
    top: 100px; }
  .r-100\@lg\+ {
    right: 100px; }
  .b-100\@lg\+ {
    bottom: 100px; }
  .l-100\@lg\+ {
    left: 100px; } }

@media screen and (min-width: 992px) {
  .t-100\@md\+ {
    top: 100px; }
  .r-100\@md\+ {
    right: 100px; }
  .b-100\@md\+ {
    bottom: 100px; }
  .l-100\@md\+ {
    left: 100px; } }

@media screen and (min-width: 768px) {
  .t-100\@sm\+ {
    top: 100px; }
  .r-100\@sm\+ {
    right: 100px; }
  .b-100\@sm\+ {
    bottom: 100px; }
  .l-100\@sm\+ {
    left: 100px; } }

@media screen and (min-width: 0) {
  .t-100\@xs\+ {
    top: 100px; }
  .r-100\@xs\+ {
    right: 100px; }
  .b-100\@xs\+ {
    bottom: 100px; }
  .l-100\@xs\+ {
    left: 100px; } }

@media screen and (max-width: 1439px) {
  .t-100\@xl- {
    top: 100px; }
  .r-100\@xl- {
    right: 100px; }
  .b-100\@xl- {
    bottom: 100px; }
  .l-100\@xl- {
    left: 100px; } }

@media screen and (max-width: 1199px) {
  .t-100\@lg- {
    top: 100px; }
  .r-100\@lg- {
    right: 100px; }
  .b-100\@lg- {
    bottom: 100px; }
  .l-100\@lg- {
    left: 100px; } }

@media screen and (max-width: 991px) {
  .t-100\@md- {
    top: 100px; }
  .r-100\@md- {
    right: 100px; }
  .b-100\@md- {
    bottom: 100px; }
  .l-100\@md- {
    left: 100px; } }

@media screen and (max-width: 767px) {
  .t-100\@sm- {
    top: 100px; }
  .r-100\@sm- {
    right: 100px; }
  .b-100\@sm- {
    bottom: 100px; }
  .l-100\@sm- {
    left: 100px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-100\@lg {
    top: 100px; }
  .r-100\@lg {
    right: 100px; }
  .b-100\@lg {
    bottom: 100px; }
  .l-100\@lg {
    left: 100px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-100\@md {
    top: 100px; }
  .r-100\@md {
    right: 100px; }
  .b-100\@md {
    bottom: 100px; }
  .l-100\@md {
    left: 100px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-100\@sm {
    top: 100px; }
  .r-100\@sm {
    right: 100px; }
  .b-100\@sm {
    bottom: 100px; }
  .l-100\@sm {
    left: 100px; } }

.t-110 {
  top: 110px; }

.r-110 {
  right: 110px; }

.b-110 {
  bottom: 110px; }

.l-110 {
  left: 110px; }

@media screen and (min-width: 1440px) {
  .t-110\@xl\+ {
    top: 110px; }
  .r-110\@xl\+ {
    right: 110px; }
  .b-110\@xl\+ {
    bottom: 110px; }
  .l-110\@xl\+ {
    left: 110px; } }

@media screen and (min-width: 1200px) {
  .t-110\@lg\+ {
    top: 110px; }
  .r-110\@lg\+ {
    right: 110px; }
  .b-110\@lg\+ {
    bottom: 110px; }
  .l-110\@lg\+ {
    left: 110px; } }

@media screen and (min-width: 992px) {
  .t-110\@md\+ {
    top: 110px; }
  .r-110\@md\+ {
    right: 110px; }
  .b-110\@md\+ {
    bottom: 110px; }
  .l-110\@md\+ {
    left: 110px; } }

@media screen and (min-width: 768px) {
  .t-110\@sm\+ {
    top: 110px; }
  .r-110\@sm\+ {
    right: 110px; }
  .b-110\@sm\+ {
    bottom: 110px; }
  .l-110\@sm\+ {
    left: 110px; } }

@media screen and (min-width: 0) {
  .t-110\@xs\+ {
    top: 110px; }
  .r-110\@xs\+ {
    right: 110px; }
  .b-110\@xs\+ {
    bottom: 110px; }
  .l-110\@xs\+ {
    left: 110px; } }

@media screen and (max-width: 1439px) {
  .t-110\@xl- {
    top: 110px; }
  .r-110\@xl- {
    right: 110px; }
  .b-110\@xl- {
    bottom: 110px; }
  .l-110\@xl- {
    left: 110px; } }

@media screen and (max-width: 1199px) {
  .t-110\@lg- {
    top: 110px; }
  .r-110\@lg- {
    right: 110px; }
  .b-110\@lg- {
    bottom: 110px; }
  .l-110\@lg- {
    left: 110px; } }

@media screen and (max-width: 991px) {
  .t-110\@md- {
    top: 110px; }
  .r-110\@md- {
    right: 110px; }
  .b-110\@md- {
    bottom: 110px; }
  .l-110\@md- {
    left: 110px; } }

@media screen and (max-width: 767px) {
  .t-110\@sm- {
    top: 110px; }
  .r-110\@sm- {
    right: 110px; }
  .b-110\@sm- {
    bottom: 110px; }
  .l-110\@sm- {
    left: 110px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-110\@lg {
    top: 110px; }
  .r-110\@lg {
    right: 110px; }
  .b-110\@lg {
    bottom: 110px; }
  .l-110\@lg {
    left: 110px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-110\@md {
    top: 110px; }
  .r-110\@md {
    right: 110px; }
  .b-110\@md {
    bottom: 110px; }
  .l-110\@md {
    left: 110px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-110\@sm {
    top: 110px; }
  .r-110\@sm {
    right: 110px; }
  .b-110\@sm {
    bottom: 110px; }
  .l-110\@sm {
    left: 110px; } }

.t-120 {
  top: 120px; }

.r-120 {
  right: 120px; }

.b-120 {
  bottom: 120px; }

.l-120 {
  left: 120px; }

@media screen and (min-width: 1440px) {
  .t-120\@xl\+ {
    top: 120px; }
  .r-120\@xl\+ {
    right: 120px; }
  .b-120\@xl\+ {
    bottom: 120px; }
  .l-120\@xl\+ {
    left: 120px; } }

@media screen and (min-width: 1200px) {
  .t-120\@lg\+ {
    top: 120px; }
  .r-120\@lg\+ {
    right: 120px; }
  .b-120\@lg\+ {
    bottom: 120px; }
  .l-120\@lg\+ {
    left: 120px; } }

@media screen and (min-width: 992px) {
  .t-120\@md\+ {
    top: 120px; }
  .r-120\@md\+ {
    right: 120px; }
  .b-120\@md\+ {
    bottom: 120px; }
  .l-120\@md\+ {
    left: 120px; } }

@media screen and (min-width: 768px) {
  .t-120\@sm\+ {
    top: 120px; }
  .r-120\@sm\+ {
    right: 120px; }
  .b-120\@sm\+ {
    bottom: 120px; }
  .l-120\@sm\+ {
    left: 120px; } }

@media screen and (min-width: 0) {
  .t-120\@xs\+ {
    top: 120px; }
  .r-120\@xs\+ {
    right: 120px; }
  .b-120\@xs\+ {
    bottom: 120px; }
  .l-120\@xs\+ {
    left: 120px; } }

@media screen and (max-width: 1439px) {
  .t-120\@xl- {
    top: 120px; }
  .r-120\@xl- {
    right: 120px; }
  .b-120\@xl- {
    bottom: 120px; }
  .l-120\@xl- {
    left: 120px; } }

@media screen and (max-width: 1199px) {
  .t-120\@lg- {
    top: 120px; }
  .r-120\@lg- {
    right: 120px; }
  .b-120\@lg- {
    bottom: 120px; }
  .l-120\@lg- {
    left: 120px; } }

@media screen and (max-width: 991px) {
  .t-120\@md- {
    top: 120px; }
  .r-120\@md- {
    right: 120px; }
  .b-120\@md- {
    bottom: 120px; }
  .l-120\@md- {
    left: 120px; } }

@media screen and (max-width: 767px) {
  .t-120\@sm- {
    top: 120px; }
  .r-120\@sm- {
    right: 120px; }
  .b-120\@sm- {
    bottom: 120px; }
  .l-120\@sm- {
    left: 120px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-120\@lg {
    top: 120px; }
  .r-120\@lg {
    right: 120px; }
  .b-120\@lg {
    bottom: 120px; }
  .l-120\@lg {
    left: 120px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-120\@md {
    top: 120px; }
  .r-120\@md {
    right: 120px; }
  .b-120\@md {
    bottom: 120px; }
  .l-120\@md {
    left: 120px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-120\@sm {
    top: 120px; }
  .r-120\@sm {
    right: 120px; }
  .b-120\@sm {
    bottom: 120px; }
  .l-120\@sm {
    left: 120px; } }

.t-130 {
  top: 130px; }

.r-130 {
  right: 130px; }

.b-130 {
  bottom: 130px; }

.l-130 {
  left: 130px; }

@media screen and (min-width: 1440px) {
  .t-130\@xl\+ {
    top: 130px; }
  .r-130\@xl\+ {
    right: 130px; }
  .b-130\@xl\+ {
    bottom: 130px; }
  .l-130\@xl\+ {
    left: 130px; } }

@media screen and (min-width: 1200px) {
  .t-130\@lg\+ {
    top: 130px; }
  .r-130\@lg\+ {
    right: 130px; }
  .b-130\@lg\+ {
    bottom: 130px; }
  .l-130\@lg\+ {
    left: 130px; } }

@media screen and (min-width: 992px) {
  .t-130\@md\+ {
    top: 130px; }
  .r-130\@md\+ {
    right: 130px; }
  .b-130\@md\+ {
    bottom: 130px; }
  .l-130\@md\+ {
    left: 130px; } }

@media screen and (min-width: 768px) {
  .t-130\@sm\+ {
    top: 130px; }
  .r-130\@sm\+ {
    right: 130px; }
  .b-130\@sm\+ {
    bottom: 130px; }
  .l-130\@sm\+ {
    left: 130px; } }

@media screen and (min-width: 0) {
  .t-130\@xs\+ {
    top: 130px; }
  .r-130\@xs\+ {
    right: 130px; }
  .b-130\@xs\+ {
    bottom: 130px; }
  .l-130\@xs\+ {
    left: 130px; } }

@media screen and (max-width: 1439px) {
  .t-130\@xl- {
    top: 130px; }
  .r-130\@xl- {
    right: 130px; }
  .b-130\@xl- {
    bottom: 130px; }
  .l-130\@xl- {
    left: 130px; } }

@media screen and (max-width: 1199px) {
  .t-130\@lg- {
    top: 130px; }
  .r-130\@lg- {
    right: 130px; }
  .b-130\@lg- {
    bottom: 130px; }
  .l-130\@lg- {
    left: 130px; } }

@media screen and (max-width: 991px) {
  .t-130\@md- {
    top: 130px; }
  .r-130\@md- {
    right: 130px; }
  .b-130\@md- {
    bottom: 130px; }
  .l-130\@md- {
    left: 130px; } }

@media screen and (max-width: 767px) {
  .t-130\@sm- {
    top: 130px; }
  .r-130\@sm- {
    right: 130px; }
  .b-130\@sm- {
    bottom: 130px; }
  .l-130\@sm- {
    left: 130px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-130\@lg {
    top: 130px; }
  .r-130\@lg {
    right: 130px; }
  .b-130\@lg {
    bottom: 130px; }
  .l-130\@lg {
    left: 130px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-130\@md {
    top: 130px; }
  .r-130\@md {
    right: 130px; }
  .b-130\@md {
    bottom: 130px; }
  .l-130\@md {
    left: 130px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-130\@sm {
    top: 130px; }
  .r-130\@sm {
    right: 130px; }
  .b-130\@sm {
    bottom: 130px; }
  .l-130\@sm {
    left: 130px; } }

.t-140 {
  top: 140px; }

.r-140 {
  right: 140px; }

.b-140 {
  bottom: 140px; }

.l-140 {
  left: 140px; }

@media screen and (min-width: 1440px) {
  .t-140\@xl\+ {
    top: 140px; }
  .r-140\@xl\+ {
    right: 140px; }
  .b-140\@xl\+ {
    bottom: 140px; }
  .l-140\@xl\+ {
    left: 140px; } }

@media screen and (min-width: 1200px) {
  .t-140\@lg\+ {
    top: 140px; }
  .r-140\@lg\+ {
    right: 140px; }
  .b-140\@lg\+ {
    bottom: 140px; }
  .l-140\@lg\+ {
    left: 140px; } }

@media screen and (min-width: 992px) {
  .t-140\@md\+ {
    top: 140px; }
  .r-140\@md\+ {
    right: 140px; }
  .b-140\@md\+ {
    bottom: 140px; }
  .l-140\@md\+ {
    left: 140px; } }

@media screen and (min-width: 768px) {
  .t-140\@sm\+ {
    top: 140px; }
  .r-140\@sm\+ {
    right: 140px; }
  .b-140\@sm\+ {
    bottom: 140px; }
  .l-140\@sm\+ {
    left: 140px; } }

@media screen and (min-width: 0) {
  .t-140\@xs\+ {
    top: 140px; }
  .r-140\@xs\+ {
    right: 140px; }
  .b-140\@xs\+ {
    bottom: 140px; }
  .l-140\@xs\+ {
    left: 140px; } }

@media screen and (max-width: 1439px) {
  .t-140\@xl- {
    top: 140px; }
  .r-140\@xl- {
    right: 140px; }
  .b-140\@xl- {
    bottom: 140px; }
  .l-140\@xl- {
    left: 140px; } }

@media screen and (max-width: 1199px) {
  .t-140\@lg- {
    top: 140px; }
  .r-140\@lg- {
    right: 140px; }
  .b-140\@lg- {
    bottom: 140px; }
  .l-140\@lg- {
    left: 140px; } }

@media screen and (max-width: 991px) {
  .t-140\@md- {
    top: 140px; }
  .r-140\@md- {
    right: 140px; }
  .b-140\@md- {
    bottom: 140px; }
  .l-140\@md- {
    left: 140px; } }

@media screen and (max-width: 767px) {
  .t-140\@sm- {
    top: 140px; }
  .r-140\@sm- {
    right: 140px; }
  .b-140\@sm- {
    bottom: 140px; }
  .l-140\@sm- {
    left: 140px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-140\@lg {
    top: 140px; }
  .r-140\@lg {
    right: 140px; }
  .b-140\@lg {
    bottom: 140px; }
  .l-140\@lg {
    left: 140px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-140\@md {
    top: 140px; }
  .r-140\@md {
    right: 140px; }
  .b-140\@md {
    bottom: 140px; }
  .l-140\@md {
    left: 140px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-140\@sm {
    top: 140px; }
  .r-140\@sm {
    right: 140px; }
  .b-140\@sm {
    bottom: 140px; }
  .l-140\@sm {
    left: 140px; } }

.t-150 {
  top: 150px; }

.r-150 {
  right: 150px; }

.b-150 {
  bottom: 150px; }

.l-150 {
  left: 150px; }

@media screen and (min-width: 1440px) {
  .t-150\@xl\+ {
    top: 150px; }
  .r-150\@xl\+ {
    right: 150px; }
  .b-150\@xl\+ {
    bottom: 150px; }
  .l-150\@xl\+ {
    left: 150px; } }

@media screen and (min-width: 1200px) {
  .t-150\@lg\+ {
    top: 150px; }
  .r-150\@lg\+ {
    right: 150px; }
  .b-150\@lg\+ {
    bottom: 150px; }
  .l-150\@lg\+ {
    left: 150px; } }

@media screen and (min-width: 992px) {
  .t-150\@md\+ {
    top: 150px; }
  .r-150\@md\+ {
    right: 150px; }
  .b-150\@md\+ {
    bottom: 150px; }
  .l-150\@md\+ {
    left: 150px; } }

@media screen and (min-width: 768px) {
  .t-150\@sm\+ {
    top: 150px; }
  .r-150\@sm\+ {
    right: 150px; }
  .b-150\@sm\+ {
    bottom: 150px; }
  .l-150\@sm\+ {
    left: 150px; } }

@media screen and (min-width: 0) {
  .t-150\@xs\+ {
    top: 150px; }
  .r-150\@xs\+ {
    right: 150px; }
  .b-150\@xs\+ {
    bottom: 150px; }
  .l-150\@xs\+ {
    left: 150px; } }

@media screen and (max-width: 1439px) {
  .t-150\@xl- {
    top: 150px; }
  .r-150\@xl- {
    right: 150px; }
  .b-150\@xl- {
    bottom: 150px; }
  .l-150\@xl- {
    left: 150px; } }

@media screen and (max-width: 1199px) {
  .t-150\@lg- {
    top: 150px; }
  .r-150\@lg- {
    right: 150px; }
  .b-150\@lg- {
    bottom: 150px; }
  .l-150\@lg- {
    left: 150px; } }

@media screen and (max-width: 991px) {
  .t-150\@md- {
    top: 150px; }
  .r-150\@md- {
    right: 150px; }
  .b-150\@md- {
    bottom: 150px; }
  .l-150\@md- {
    left: 150px; } }

@media screen and (max-width: 767px) {
  .t-150\@sm- {
    top: 150px; }
  .r-150\@sm- {
    right: 150px; }
  .b-150\@sm- {
    bottom: 150px; }
  .l-150\@sm- {
    left: 150px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-150\@lg {
    top: 150px; }
  .r-150\@lg {
    right: 150px; }
  .b-150\@lg {
    bottom: 150px; }
  .l-150\@lg {
    left: 150px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-150\@md {
    top: 150px; }
  .r-150\@md {
    right: 150px; }
  .b-150\@md {
    bottom: 150px; }
  .l-150\@md {
    left: 150px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-150\@sm {
    top: 150px; }
  .r-150\@sm {
    right: 150px; }
  .b-150\@sm {
    bottom: 150px; }
  .l-150\@sm {
    left: 150px; } }

.t-160 {
  top: 160px; }

.r-160 {
  right: 160px; }

.b-160 {
  bottom: 160px; }

.l-160 {
  left: 160px; }

@media screen and (min-width: 1440px) {
  .t-160\@xl\+ {
    top: 160px; }
  .r-160\@xl\+ {
    right: 160px; }
  .b-160\@xl\+ {
    bottom: 160px; }
  .l-160\@xl\+ {
    left: 160px; } }

@media screen and (min-width: 1200px) {
  .t-160\@lg\+ {
    top: 160px; }
  .r-160\@lg\+ {
    right: 160px; }
  .b-160\@lg\+ {
    bottom: 160px; }
  .l-160\@lg\+ {
    left: 160px; } }

@media screen and (min-width: 992px) {
  .t-160\@md\+ {
    top: 160px; }
  .r-160\@md\+ {
    right: 160px; }
  .b-160\@md\+ {
    bottom: 160px; }
  .l-160\@md\+ {
    left: 160px; } }

@media screen and (min-width: 768px) {
  .t-160\@sm\+ {
    top: 160px; }
  .r-160\@sm\+ {
    right: 160px; }
  .b-160\@sm\+ {
    bottom: 160px; }
  .l-160\@sm\+ {
    left: 160px; } }

@media screen and (min-width: 0) {
  .t-160\@xs\+ {
    top: 160px; }
  .r-160\@xs\+ {
    right: 160px; }
  .b-160\@xs\+ {
    bottom: 160px; }
  .l-160\@xs\+ {
    left: 160px; } }

@media screen and (max-width: 1439px) {
  .t-160\@xl- {
    top: 160px; }
  .r-160\@xl- {
    right: 160px; }
  .b-160\@xl- {
    bottom: 160px; }
  .l-160\@xl- {
    left: 160px; } }

@media screen and (max-width: 1199px) {
  .t-160\@lg- {
    top: 160px; }
  .r-160\@lg- {
    right: 160px; }
  .b-160\@lg- {
    bottom: 160px; }
  .l-160\@lg- {
    left: 160px; } }

@media screen and (max-width: 991px) {
  .t-160\@md- {
    top: 160px; }
  .r-160\@md- {
    right: 160px; }
  .b-160\@md- {
    bottom: 160px; }
  .l-160\@md- {
    left: 160px; } }

@media screen and (max-width: 767px) {
  .t-160\@sm- {
    top: 160px; }
  .r-160\@sm- {
    right: 160px; }
  .b-160\@sm- {
    bottom: 160px; }
  .l-160\@sm- {
    left: 160px; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .t-160\@lg {
    top: 160px; }
  .r-160\@lg {
    right: 160px; }
  .b-160\@lg {
    bottom: 160px; }
  .l-160\@lg {
    left: 160px; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .t-160\@md {
    top: 160px; }
  .r-160\@md {
    right: 160px; }
  .b-160\@md {
    bottom: 160px; }
  .l-160\@md {
    left: 160px; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .t-160\@sm {
    top: 160px; }
  .r-160\@sm {
    right: 160px; }
  .b-160\@sm {
    bottom: 160px; }
  .l-160\@sm {
    left: 160px; } }

.tl-50p {
  top: 50%;
  left: 50%; }

.tr-50p {
  top: 50%;
  right: 50%; }

.t-50p {
  top: 50%; }

.r-50p {
  right: 50%; }

.b-50p {
  bottom: 50%; }

.l-50p {
  left: 50%; }

@media screen and (min-width: 1440px) {
  .tl-50p\@xl\+ {
    top: 50%;
    left: 50%; }
  .tr-50p\@xl\+ {
    top: 50%;
    right: 50%; }
  .t-50p\@xl\+ {
    top: 50%; }
  .r-50p\@xl\+ {
    right: 50%; }
  .b-50p\@xl\+ {
    bottom: 50%; }
  .l-50p\@xl\+ {
    left: 50%; } }

@media screen and (min-width: 1200px) {
  .tl-50p\@lg\+ {
    top: 50%;
    left: 50%; }
  .tr-50p\@lg\+ {
    top: 50%;
    right: 50%; }
  .t-50p\@lg\+ {
    top: 50%; }
  .r-50p\@lg\+ {
    right: 50%; }
  .b-50p\@lg\+ {
    bottom: 50%; }
  .l-50p\@lg\+ {
    left: 50%; } }

@media screen and (min-width: 992px) {
  .tl-50p\@md\+ {
    top: 50%;
    left: 50%; }
  .tr-50p\@md\+ {
    top: 50%;
    right: 50%; }
  .t-50p\@md\+ {
    top: 50%; }
  .r-50p\@md\+ {
    right: 50%; }
  .b-50p\@md\+ {
    bottom: 50%; }
  .l-50p\@md\+ {
    left: 50%; } }

@media screen and (min-width: 768px) {
  .tl-50p\@sm\+ {
    top: 50%;
    left: 50%; }
  .tr-50p\@sm\+ {
    top: 50%;
    right: 50%; }
  .t-50p\@sm\+ {
    top: 50%; }
  .r-50p\@sm\+ {
    right: 50%; }
  .b-50p\@sm\+ {
    bottom: 50%; }
  .l-50p\@sm\+ {
    left: 50%; } }

@media screen and (min-width: 0) {
  .tl-50p\@xs\+ {
    top: 50%;
    left: 50%; }
  .tr-50p\@xs\+ {
    top: 50%;
    right: 50%; }
  .t-50p\@xs\+ {
    top: 50%; }
  .r-50p\@xs\+ {
    right: 50%; }
  .b-50p\@xs\+ {
    bottom: 50%; }
  .l-50p\@xs\+ {
    left: 50%; } }

@media screen and (max-width: 1439px) {
  .tl-50p\@xl- {
    top: 50%;
    left: 50%; }
  .tr-50p\@xl- {
    top: 50%;
    right: 50%; }
  .t-50p\@xl- {
    top: 50%; }
  .r-50p\@xl- {
    right: 50%; }
  .b-50p\@xl- {
    bottom: 50%; }
  .l-50p\@xl- {
    left: 50%; } }

@media screen and (max-width: 1199px) {
  .tl-50p\@lg- {
    top: 50%;
    left: 50%; }
  .tr-50p\@lg- {
    top: 50%;
    right: 50%; }
  .t-50p\@lg- {
    top: 50%; }
  .r-50p\@lg- {
    right: 50%; }
  .b-50p\@lg- {
    bottom: 50%; }
  .l-50p\@lg- {
    left: 50%; } }

@media screen and (max-width: 991px) {
  .tl-50p\@md- {
    top: 50%;
    left: 50%; }
  .tr-50p\@md- {
    top: 50%;
    right: 50%; }
  .t-50p\@md- {
    top: 50%; }
  .r-50p\@md- {
    right: 50%; }
  .b-50p\@md- {
    bottom: 50%; }
  .l-50p\@md- {
    left: 50%; } }

@media screen and (max-width: 767px) {
  .tl-50p\@sm- {
    top: 50%;
    left: 50%; }
  .tr-50p\@sm- {
    top: 50%;
    right: 50%; }
  .t-50p\@sm- {
    top: 50%; }
  .r-50p\@sm- {
    right: 50%; }
  .b-50p\@sm- {
    bottom: 50%; }
  .l-50p\@sm- {
    left: 50%; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .tl-50p\@lg {
    top: 50%;
    left: 50%; }
  .tr-50p\@lg {
    top: 50%;
    right: 50%; }
  .t-50p\@lg {
    top: 50%; }
  .r-50p\@lg {
    right: 50%; }
  .b-50p\@lg {
    bottom: 50%; }
  .l-50p\@lg {
    left: 50%; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .tl-50p\@md {
    top: 50%;
    left: 50%; }
  .tr-50p\@md {
    top: 50%;
    right: 50%; }
  .t-50p\@md {
    top: 50%; }
  .r-50p\@md {
    right: 50%; }
  .b-50p\@md {
    bottom: 50%; }
  .l-50p\@md {
    left: 50%; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .tl-50p\@sm {
    top: 50%;
    left: 50%; }
  .tr-50p\@sm {
    top: 50%;
    right: 50%; }
  .t-50p\@sm {
    top: 50%; }
  .r-50p\@sm {
    right: 50%; }
  .b-50p\@sm {
    bottom: 50%; }
  .l-50p\@sm {
    left: 50%; } }

.w-1\/4r, .sz-1\/4r {
  width: 0.25rem; }

.w-1\/2r, .sz-1\/2r {
  width: 0.5rem; }

.w-3\/4r, .sz-3\/4r {
  width: 0.75rem; }

.w-1r, .sz-1r {
  width: 1rem; }

.w-3\/2r, .sz-3\/2r {
  width: 1.5rem; }

.w-2r, .sz-2r {
  width: 2rem; }

.w-5\/2r, .sz-5\/2r {
  width: 2.5rem; }

.w-3r, .sz-3r {
  width: 3rem; }

.w-7\/2r, .sz-7\/2r {
  width: 3.5rem; }

.w-4r, .sz-4r {
  width: 4rem; }

.w-9\/2r, .sz-9\/2r {
  width: 4.5rem; }

.w-5r, .sz-5r {
  width: 5rem; }

.w-11\/2r, .sz-11\/2r {
  width: 5.5rem; }

.w-6r, .sz-6r {
  width: 6rem; }

@media screen and (min-width: 1440px) {
  .w-1\/4r\@xl\+, .sz-1\/4r\@xl\+ {
    width: 0.25rem; }
  .w-1\/2r\@xl\+, .sz-1\/2r\@xl\+ {
    width: 0.5rem; }
  .w-3\/4r\@xl\+, .sz-3\/4r\@xl\+ {
    width: 0.75rem; }
  .w-1r\@xl\+, .sz-1r\@xl\+ {
    width: 1rem; }
  .w-3\/2r\@xl\+, .sz-3\/2r\@xl\+ {
    width: 1.5rem; }
  .w-2r\@xl\+, .sz-2r\@xl\+ {
    width: 2rem; }
  .w-5\/2r\@xl\+, .sz-5\/2r\@xl\+ {
    width: 2.5rem; }
  .w-3r\@xl\+, .sz-3r\@xl\+ {
    width: 3rem; }
  .w-7\/2r\@xl\+, .sz-7\/2r\@xl\+ {
    width: 3.5rem; }
  .w-4r\@xl\+, .sz-4r\@xl\+ {
    width: 4rem; }
  .w-9\/2r\@xl\+, .sz-9\/2r\@xl\+ {
    width: 4.5rem; }
  .w-5r\@xl\+, .sz-5r\@xl\+ {
    width: 5rem; }
  .w-11\/2r\@xl\+, .sz-11\/2r\@xl\+ {
    width: 5.5rem; }
  .w-6r\@xl\+, .sz-6r\@xl\+ {
    width: 6rem; } }

@media screen and (min-width: 1200px) {
  .w-1\/4r\@lg\+, .sz-1\/4r\@lg\+ {
    width: 0.25rem; }
  .w-1\/2r\@lg\+, .sz-1\/2r\@lg\+ {
    width: 0.5rem; }
  .w-3\/4r\@lg\+, .sz-3\/4r\@lg\+ {
    width: 0.75rem; }
  .w-1r\@lg\+, .sz-1r\@lg\+ {
    width: 1rem; }
  .w-3\/2r\@lg\+, .sz-3\/2r\@lg\+ {
    width: 1.5rem; }
  .w-2r\@lg\+, .sz-2r\@lg\+ {
    width: 2rem; }
  .w-5\/2r\@lg\+, .sz-5\/2r\@lg\+ {
    width: 2.5rem; }
  .w-3r\@lg\+, .sz-3r\@lg\+ {
    width: 3rem; }
  .w-7\/2r\@lg\+, .sz-7\/2r\@lg\+ {
    width: 3.5rem; }
  .w-4r\@lg\+, .sz-4r\@lg\+ {
    width: 4rem; }
  .w-9\/2r\@lg\+, .sz-9\/2r\@lg\+ {
    width: 4.5rem; }
  .w-5r\@lg\+, .sz-5r\@lg\+ {
    width: 5rem; }
  .w-11\/2r\@lg\+, .sz-11\/2r\@lg\+ {
    width: 5.5rem; }
  .w-6r\@lg\+, .sz-6r\@lg\+ {
    width: 6rem; } }

@media screen and (min-width: 992px) {
  .w-1\/4r\@md\+, .sz-1\/4r\@md\+ {
    width: 0.25rem; }
  .w-1\/2r\@md\+, .sz-1\/2r\@md\+ {
    width: 0.5rem; }
  .w-3\/4r\@md\+, .sz-3\/4r\@md\+ {
    width: 0.75rem; }
  .w-1r\@md\+, .sz-1r\@md\+ {
    width: 1rem; }
  .w-3\/2r\@md\+, .sz-3\/2r\@md\+ {
    width: 1.5rem; }
  .w-2r\@md\+, .sz-2r\@md\+ {
    width: 2rem; }
  .w-5\/2r\@md\+, .sz-5\/2r\@md\+ {
    width: 2.5rem; }
  .w-3r\@md\+, .sz-3r\@md\+ {
    width: 3rem; }
  .w-7\/2r\@md\+, .sz-7\/2r\@md\+ {
    width: 3.5rem; }
  .w-4r\@md\+, .sz-4r\@md\+ {
    width: 4rem; }
  .w-9\/2r\@md\+, .sz-9\/2r\@md\+ {
    width: 4.5rem; }
  .w-5r\@md\+, .sz-5r\@md\+ {
    width: 5rem; }
  .w-11\/2r\@md\+, .sz-11\/2r\@md\+ {
    width: 5.5rem; }
  .w-6r\@md\+, .sz-6r\@md\+ {
    width: 6rem; } }

@media screen and (min-width: 768px) {
  .w-1\/4r\@sm\+, .sz-1\/4r\@sm\+ {
    width: 0.25rem; }
  .w-1\/2r\@sm\+, .sz-1\/2r\@sm\+ {
    width: 0.5rem; }
  .w-3\/4r\@sm\+, .sz-3\/4r\@sm\+ {
    width: 0.75rem; }
  .w-1r\@sm\+, .sz-1r\@sm\+ {
    width: 1rem; }
  .w-3\/2r\@sm\+, .sz-3\/2r\@sm\+ {
    width: 1.5rem; }
  .w-2r\@sm\+, .sz-2r\@sm\+ {
    width: 2rem; }
  .w-5\/2r\@sm\+, .sz-5\/2r\@sm\+ {
    width: 2.5rem; }
  .w-3r\@sm\+, .sz-3r\@sm\+ {
    width: 3rem; }
  .w-7\/2r\@sm\+, .sz-7\/2r\@sm\+ {
    width: 3.5rem; }
  .w-4r\@sm\+, .sz-4r\@sm\+ {
    width: 4rem; }
  .w-9\/2r\@sm\+, .sz-9\/2r\@sm\+ {
    width: 4.5rem; }
  .w-5r\@sm\+, .sz-5r\@sm\+ {
    width: 5rem; }
  .w-11\/2r\@sm\+, .sz-11\/2r\@sm\+ {
    width: 5.5rem; }
  .w-6r\@sm\+, .sz-6r\@sm\+ {
    width: 6rem; } }

@media screen and (min-width: 0) {
  .w-1\/4r\@xs\+, .sz-1\/4r\@xs\+ {
    width: 0.25rem; }
  .w-1\/2r\@xs\+, .sz-1\/2r\@xs\+ {
    width: 0.5rem; }
  .w-3\/4r\@xs\+, .sz-3\/4r\@xs\+ {
    width: 0.75rem; }
  .w-1r\@xs\+, .sz-1r\@xs\+ {
    width: 1rem; }
  .w-3\/2r\@xs\+, .sz-3\/2r\@xs\+ {
    width: 1.5rem; }
  .w-2r\@xs\+, .sz-2r\@xs\+ {
    width: 2rem; }
  .w-5\/2r\@xs\+, .sz-5\/2r\@xs\+ {
    width: 2.5rem; }
  .w-3r\@xs\+, .sz-3r\@xs\+ {
    width: 3rem; }
  .w-7\/2r\@xs\+, .sz-7\/2r\@xs\+ {
    width: 3.5rem; }
  .w-4r\@xs\+, .sz-4r\@xs\+ {
    width: 4rem; }
  .w-9\/2r\@xs\+, .sz-9\/2r\@xs\+ {
    width: 4.5rem; }
  .w-5r\@xs\+, .sz-5r\@xs\+ {
    width: 5rem; }
  .w-11\/2r\@xs\+, .sz-11\/2r\@xs\+ {
    width: 5.5rem; }
  .w-6r\@xs\+, .sz-6r\@xs\+ {
    width: 6rem; } }

@media screen and (max-width: 1439px) {
  .w-1\/4r\@xl-, .sz-1\/4r\@xl- {
    width: 0.25rem; }
  .w-1\/2r\@xl-, .sz-1\/2r\@xl- {
    width: 0.5rem; }
  .w-3\/4r\@xl-, .sz-3\/4r\@xl- {
    width: 0.75rem; }
  .w-1r\@xl-, .sz-1r\@xl- {
    width: 1rem; }
  .w-3\/2r\@xl-, .sz-3\/2r\@xl- {
    width: 1.5rem; }
  .w-2r\@xl-, .sz-2r\@xl- {
    width: 2rem; }
  .w-5\/2r\@xl-, .sz-5\/2r\@xl- {
    width: 2.5rem; }
  .w-3r\@xl-, .sz-3r\@xl- {
    width: 3rem; }
  .w-7\/2r\@xl-, .sz-7\/2r\@xl- {
    width: 3.5rem; }
  .w-4r\@xl-, .sz-4r\@xl- {
    width: 4rem; }
  .w-9\/2r\@xl-, .sz-9\/2r\@xl- {
    width: 4.5rem; }
  .w-5r\@xl-, .sz-5r\@xl- {
    width: 5rem; }
  .w-11\/2r\@xl-, .sz-11\/2r\@xl- {
    width: 5.5rem; }
  .w-6r\@xl-, .sz-6r\@xl- {
    width: 6rem; } }

@media screen and (max-width: 1199px) {
  .w-1\/4r\@lg-, .sz-1\/4r\@lg- {
    width: 0.25rem; }
  .w-1\/2r\@lg-, .sz-1\/2r\@lg- {
    width: 0.5rem; }
  .w-3\/4r\@lg-, .sz-3\/4r\@lg- {
    width: 0.75rem; }
  .w-1r\@lg-, .sz-1r\@lg- {
    width: 1rem; }
  .w-3\/2r\@lg-, .sz-3\/2r\@lg- {
    width: 1.5rem; }
  .w-2r\@lg-, .sz-2r\@lg- {
    width: 2rem; }
  .w-5\/2r\@lg-, .sz-5\/2r\@lg- {
    width: 2.5rem; }
  .w-3r\@lg-, .sz-3r\@lg- {
    width: 3rem; }
  .w-7\/2r\@lg-, .sz-7\/2r\@lg- {
    width: 3.5rem; }
  .w-4r\@lg-, .sz-4r\@lg- {
    width: 4rem; }
  .w-9\/2r\@lg-, .sz-9\/2r\@lg- {
    width: 4.5rem; }
  .w-5r\@lg-, .sz-5r\@lg- {
    width: 5rem; }
  .w-11\/2r\@lg-, .sz-11\/2r\@lg- {
    width: 5.5rem; }
  .w-6r\@lg-, .sz-6r\@lg- {
    width: 6rem; } }

@media screen and (max-width: 991px) {
  .w-1\/4r\@md-, .sz-1\/4r\@md- {
    width: 0.25rem; }
  .w-1\/2r\@md-, .sz-1\/2r\@md- {
    width: 0.5rem; }
  .w-3\/4r\@md-, .sz-3\/4r\@md- {
    width: 0.75rem; }
  .w-1r\@md-, .sz-1r\@md- {
    width: 1rem; }
  .w-3\/2r\@md-, .sz-3\/2r\@md- {
    width: 1.5rem; }
  .w-2r\@md-, .sz-2r\@md- {
    width: 2rem; }
  .w-5\/2r\@md-, .sz-5\/2r\@md- {
    width: 2.5rem; }
  .w-3r\@md-, .sz-3r\@md- {
    width: 3rem; }
  .w-7\/2r\@md-, .sz-7\/2r\@md- {
    width: 3.5rem; }
  .w-4r\@md-, .sz-4r\@md- {
    width: 4rem; }
  .w-9\/2r\@md-, .sz-9\/2r\@md- {
    width: 4.5rem; }
  .w-5r\@md-, .sz-5r\@md- {
    width: 5rem; }
  .w-11\/2r\@md-, .sz-11\/2r\@md- {
    width: 5.5rem; }
  .w-6r\@md-, .sz-6r\@md- {
    width: 6rem; } }

@media screen and (max-width: 767px) {
  .w-1\/4r\@sm-, .sz-1\/4r\@sm- {
    width: 0.25rem; }
  .w-1\/2r\@sm-, .sz-1\/2r\@sm- {
    width: 0.5rem; }
  .w-3\/4r\@sm-, .sz-3\/4r\@sm- {
    width: 0.75rem; }
  .w-1r\@sm-, .sz-1r\@sm- {
    width: 1rem; }
  .w-3\/2r\@sm-, .sz-3\/2r\@sm- {
    width: 1.5rem; }
  .w-2r\@sm-, .sz-2r\@sm- {
    width: 2rem; }
  .w-5\/2r\@sm-, .sz-5\/2r\@sm- {
    width: 2.5rem; }
  .w-3r\@sm-, .sz-3r\@sm- {
    width: 3rem; }
  .w-7\/2r\@sm-, .sz-7\/2r\@sm- {
    width: 3.5rem; }
  .w-4r\@sm-, .sz-4r\@sm- {
    width: 4rem; }
  .w-9\/2r\@sm-, .sz-9\/2r\@sm- {
    width: 4.5rem; }
  .w-5r\@sm-, .sz-5r\@sm- {
    width: 5rem; }
  .w-11\/2r\@sm-, .sz-11\/2r\@sm- {
    width: 5.5rem; }
  .w-6r\@sm-, .sz-6r\@sm- {
    width: 6rem; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .w-1\/4r\@lg, .sz-1\/4r\@lg {
    width: 0.25rem; }
  .w-1\/2r\@lg, .sz-1\/2r\@lg {
    width: 0.5rem; }
  .w-3\/4r\@lg, .sz-3\/4r\@lg {
    width: 0.75rem; }
  .w-1r\@lg, .sz-1r\@lg {
    width: 1rem; }
  .w-3\/2r\@lg, .sz-3\/2r\@lg {
    width: 1.5rem; }
  .w-2r\@lg, .sz-2r\@lg {
    width: 2rem; }
  .w-5\/2r\@lg, .sz-5\/2r\@lg {
    width: 2.5rem; }
  .w-3r\@lg, .sz-3r\@lg {
    width: 3rem; }
  .w-7\/2r\@lg, .sz-7\/2r\@lg {
    width: 3.5rem; }
  .w-4r\@lg, .sz-4r\@lg {
    width: 4rem; }
  .w-9\/2r\@lg, .sz-9\/2r\@lg {
    width: 4.5rem; }
  .w-5r\@lg, .sz-5r\@lg {
    width: 5rem; }
  .w-11\/2r\@lg, .sz-11\/2r\@lg {
    width: 5.5rem; }
  .w-6r\@lg, .sz-6r\@lg {
    width: 6rem; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .w-1\/4r\@md, .sz-1\/4r\@md {
    width: 0.25rem; }
  .w-1\/2r\@md, .sz-1\/2r\@md {
    width: 0.5rem; }
  .w-3\/4r\@md, .sz-3\/4r\@md {
    width: 0.75rem; }
  .w-1r\@md, .sz-1r\@md {
    width: 1rem; }
  .w-3\/2r\@md, .sz-3\/2r\@md {
    width: 1.5rem; }
  .w-2r\@md, .sz-2r\@md {
    width: 2rem; }
  .w-5\/2r\@md, .sz-5\/2r\@md {
    width: 2.5rem; }
  .w-3r\@md, .sz-3r\@md {
    width: 3rem; }
  .w-7\/2r\@md, .sz-7\/2r\@md {
    width: 3.5rem; }
  .w-4r\@md, .sz-4r\@md {
    width: 4rem; }
  .w-9\/2r\@md, .sz-9\/2r\@md {
    width: 4.5rem; }
  .w-5r\@md, .sz-5r\@md {
    width: 5rem; }
  .w-11\/2r\@md, .sz-11\/2r\@md {
    width: 5.5rem; }
  .w-6r\@md, .sz-6r\@md {
    width: 6rem; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .w-1\/4r\@sm, .sz-1\/4r\@sm {
    width: 0.25rem; }
  .w-1\/2r\@sm, .sz-1\/2r\@sm {
    width: 0.5rem; }
  .w-3\/4r\@sm, .sz-3\/4r\@sm {
    width: 0.75rem; }
  .w-1r\@sm, .sz-1r\@sm {
    width: 1rem; }
  .w-3\/2r\@sm, .sz-3\/2r\@sm {
    width: 1.5rem; }
  .w-2r\@sm, .sz-2r\@sm {
    width: 2rem; }
  .w-5\/2r\@sm, .sz-5\/2r\@sm {
    width: 2.5rem; }
  .w-3r\@sm, .sz-3r\@sm {
    width: 3rem; }
  .w-7\/2r\@sm, .sz-7\/2r\@sm {
    width: 3.5rem; }
  .w-4r\@sm, .sz-4r\@sm {
    width: 4rem; }
  .w-9\/2r\@sm, .sz-9\/2r\@sm {
    width: 4.5rem; }
  .w-5r\@sm, .sz-5r\@sm {
    width: 5rem; }
  .w-11\/2r\@sm, .sz-11\/2r\@sm {
    width: 5.5rem; }
  .w-6r\@sm, .sz-6r\@sm {
    width: 6rem; } }

.w-0 {
  width: 0px; }

.w-10p {
  width: 10%; }

.w-20p {
  width: 20%; }

.w-30p {
  width: 30%; }

.w-40p {
  width: 40%; }

.w-50p {
  width: 50%; }

.w-60p {
  width: 60%; }

.w-70p {
  width: 70%; }

.w-80p {
  width: 80%; }

.w-90p {
  width: 90%; }

.w-100p {
  width: 100%; }

.w-1px {
  width: 1px; }

.w-a {
  width: auto; }

@media screen and (min-width: 1440px) {
  .w-0\@xl\+ {
    width: 0px; }
  .w-10p\@xl\+ {
    width: 10%; }
  .w-20p\@xl\+ {
    width: 20%; }
  .w-30p\@xl\+ {
    width: 30%; }
  .w-40p\@xl\+ {
    width: 40%; }
  .w-50p\@xl\+ {
    width: 50%; }
  .w-60p\@xl\+ {
    width: 60%; }
  .w-70p\@xl\+ {
    width: 70%; }
  .w-80p\@xl\+ {
    width: 80%; }
  .w-90p\@xl\+ {
    width: 90%; }
  .w-100p\@xl\+ {
    width: 100%; }
  .w-1px\@xl\+ {
    width: 1px; }
  .w-a\@xl\+ {
    width: auto; } }

@media screen and (min-width: 1200px) {
  .w-0\@lg\+ {
    width: 0px; }
  .w-10p\@lg\+ {
    width: 10%; }
  .w-20p\@lg\+ {
    width: 20%; }
  .w-30p\@lg\+ {
    width: 30%; }
  .w-40p\@lg\+ {
    width: 40%; }
  .w-50p\@lg\+ {
    width: 50%; }
  .w-60p\@lg\+ {
    width: 60%; }
  .w-70p\@lg\+ {
    width: 70%; }
  .w-80p\@lg\+ {
    width: 80%; }
  .w-90p\@lg\+ {
    width: 90%; }
  .w-100p\@lg\+ {
    width: 100%; }
  .w-1px\@lg\+ {
    width: 1px; }
  .w-a\@lg\+ {
    width: auto; } }

@media screen and (min-width: 992px) {
  .w-0\@md\+ {
    width: 0px; }
  .w-10p\@md\+ {
    width: 10%; }
  .w-20p\@md\+ {
    width: 20%; }
  .w-30p\@md\+ {
    width: 30%; }
  .w-40p\@md\+ {
    width: 40%; }
  .w-50p\@md\+ {
    width: 50%; }
  .w-60p\@md\+ {
    width: 60%; }
  .w-70p\@md\+ {
    width: 70%; }
  .w-80p\@md\+ {
    width: 80%; }
  .w-90p\@md\+ {
    width: 90%; }
  .w-100p\@md\+ {
    width: 100%; }
  .w-1px\@md\+ {
    width: 1px; }
  .w-a\@md\+ {
    width: auto; } }

@media screen and (min-width: 768px) {
  .w-0\@sm\+ {
    width: 0px; }
  .w-10p\@sm\+ {
    width: 10%; }
  .w-20p\@sm\+ {
    width: 20%; }
  .w-30p\@sm\+ {
    width: 30%; }
  .w-40p\@sm\+ {
    width: 40%; }
  .w-50p\@sm\+ {
    width: 50%; }
  .w-60p\@sm\+ {
    width: 60%; }
  .w-70p\@sm\+ {
    width: 70%; }
  .w-80p\@sm\+ {
    width: 80%; }
  .w-90p\@sm\+ {
    width: 90%; }
  .w-100p\@sm\+ {
    width: 100%; }
  .w-1px\@sm\+ {
    width: 1px; }
  .w-a\@sm\+ {
    width: auto; } }

@media screen and (min-width: 0) {
  .w-0\@xs\+ {
    width: 0px; }
  .w-10p\@xs\+ {
    width: 10%; }
  .w-20p\@xs\+ {
    width: 20%; }
  .w-30p\@xs\+ {
    width: 30%; }
  .w-40p\@xs\+ {
    width: 40%; }
  .w-50p\@xs\+ {
    width: 50%; }
  .w-60p\@xs\+ {
    width: 60%; }
  .w-70p\@xs\+ {
    width: 70%; }
  .w-80p\@xs\+ {
    width: 80%; }
  .w-90p\@xs\+ {
    width: 90%; }
  .w-100p\@xs\+ {
    width: 100%; }
  .w-1px\@xs\+ {
    width: 1px; }
  .w-a\@xs\+ {
    width: auto; } }

@media screen and (max-width: 1439px) {
  .w-0\@xl- {
    width: 0px; }
  .w-10p\@xl- {
    width: 10%; }
  .w-20p\@xl- {
    width: 20%; }
  .w-30p\@xl- {
    width: 30%; }
  .w-40p\@xl- {
    width: 40%; }
  .w-50p\@xl- {
    width: 50%; }
  .w-60p\@xl- {
    width: 60%; }
  .w-70p\@xl- {
    width: 70%; }
  .w-80p\@xl- {
    width: 80%; }
  .w-90p\@xl- {
    width: 90%; }
  .w-100p\@xl- {
    width: 100%; }
  .w-1px\@xl- {
    width: 1px; }
  .w-a\@xl- {
    width: auto; } }

@media screen and (max-width: 1199px) {
  .w-0\@lg- {
    width: 0px; }
  .w-10p\@lg- {
    width: 10%; }
  .w-20p\@lg- {
    width: 20%; }
  .w-30p\@lg- {
    width: 30%; }
  .w-40p\@lg- {
    width: 40%; }
  .w-50p\@lg- {
    width: 50%; }
  .w-60p\@lg- {
    width: 60%; }
  .w-70p\@lg- {
    width: 70%; }
  .w-80p\@lg- {
    width: 80%; }
  .w-90p\@lg- {
    width: 90%; }
  .w-100p\@lg- {
    width: 100%; }
  .w-1px\@lg- {
    width: 1px; }
  .w-a\@lg- {
    width: auto; } }

@media screen and (max-width: 991px) {
  .w-0\@md- {
    width: 0px; }
  .w-10p\@md- {
    width: 10%; }
  .w-20p\@md- {
    width: 20%; }
  .w-30p\@md- {
    width: 30%; }
  .w-40p\@md- {
    width: 40%; }
  .w-50p\@md- {
    width: 50%; }
  .w-60p\@md- {
    width: 60%; }
  .w-70p\@md- {
    width: 70%; }
  .w-80p\@md- {
    width: 80%; }
  .w-90p\@md- {
    width: 90%; }
  .w-100p\@md- {
    width: 100%; }
  .w-1px\@md- {
    width: 1px; }
  .w-a\@md- {
    width: auto; } }

@media screen and (max-width: 767px) {
  .w-0\@sm- {
    width: 0px; }
  .w-10p\@sm- {
    width: 10%; }
  .w-20p\@sm- {
    width: 20%; }
  .w-30p\@sm- {
    width: 30%; }
  .w-40p\@sm- {
    width: 40%; }
  .w-50p\@sm- {
    width: 50%; }
  .w-60p\@sm- {
    width: 60%; }
  .w-70p\@sm- {
    width: 70%; }
  .w-80p\@sm- {
    width: 80%; }
  .w-90p\@sm- {
    width: 90%; }
  .w-100p\@sm- {
    width: 100%; }
  .w-1px\@sm- {
    width: 1px; }
  .w-a\@sm- {
    width: auto; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .w-0\@lg {
    width: 0px; }
  .w-10p\@lg {
    width: 10%; }
  .w-20p\@lg {
    width: 20%; }
  .w-30p\@lg {
    width: 30%; }
  .w-40p\@lg {
    width: 40%; }
  .w-50p\@lg {
    width: 50%; }
  .w-60p\@lg {
    width: 60%; }
  .w-70p\@lg {
    width: 70%; }
  .w-80p\@lg {
    width: 80%; }
  .w-90p\@lg {
    width: 90%; }
  .w-100p\@lg {
    width: 100%; }
  .w-1px\@lg {
    width: 1px; }
  .w-a\@lg {
    width: auto; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .w-0\@md {
    width: 0px; }
  .w-10p\@md {
    width: 10%; }
  .w-20p\@md {
    width: 20%; }
  .w-30p\@md {
    width: 30%; }
  .w-40p\@md {
    width: 40%; }
  .w-50p\@md {
    width: 50%; }
  .w-60p\@md {
    width: 60%; }
  .w-70p\@md {
    width: 70%; }
  .w-80p\@md {
    width: 80%; }
  .w-90p\@md {
    width: 90%; }
  .w-100p\@md {
    width: 100%; }
  .w-1px\@md {
    width: 1px; }
  .w-a\@md {
    width: auto; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .w-0\@sm {
    width: 0px; }
  .w-10p\@sm {
    width: 10%; }
  .w-20p\@sm {
    width: 20%; }
  .w-30p\@sm {
    width: 30%; }
  .w-40p\@sm {
    width: 40%; }
  .w-50p\@sm {
    width: 50%; }
  .w-60p\@sm {
    width: 60%; }
  .w-70p\@sm {
    width: 70%; }
  .w-80p\@sm {
    width: 80%; }
  .w-90p\@sm {
    width: 90%; }
  .w-100p\@sm {
    width: 100%; }
  .w-1px\@sm {
    width: 1px; }
  .w-a\@sm {
    width: auto; } }

.h-1\/4r, .sz-1\/4r {
  height: 0.25rem; }

.h-1\/2r, .sz-1\/2r {
  height: 0.5rem; }

.h-3\/4r, .sz-3\/4r {
  height: 0.75rem; }

.h-1r, .sz-1r {
  height: 1rem; }

.h-3\/2r, .sz-3\/2r {
  height: 1.5rem; }

.h-2r, .sz-2r {
  height: 2rem; }

.h-5\/2r, .sz-5\/2r {
  height: 2.5rem; }

.h-3r, .sz-3r {
  height: 3rem; }

.h-7\/2r, .sz-7\/2r {
  height: 3.5rem; }

.h-4r, .sz-4r {
  height: 4rem; }

.h-9\/2r, .sz-9\/2r {
  height: 4.5rem; }

.h-5r, .sz-5r {
  height: 5rem; }

.h-11\/2r, .sz-11\/2r {
  height: 5.5rem; }

.h-6r, .sz-6r {
  height: 6rem; }

@media screen and (min-width: 1440px) {
  .h-1\/4r\@xl\+, .sz-1\/4r\@xl\+ {
    height: 0.25rem; }
  .h-1\/2r\@xl\+, .sz-1\/2r\@xl\+ {
    height: 0.5rem; }
  .h-3\/4r\@xl\+, .sz-3\/4r\@xl\+ {
    height: 0.75rem; }
  .h-1r\@xl\+, .sz-1r\@xl\+ {
    height: 1rem; }
  .h-3\/2r\@xl\+, .sz-3\/2r\@xl\+ {
    height: 1.5rem; }
  .h-2r\@xl\+, .sz-2r\@xl\+ {
    height: 2rem; }
  .h-5\/2r\@xl\+, .sz-5\/2r\@xl\+ {
    height: 2.5rem; }
  .h-3r\@xl\+, .sz-3r\@xl\+ {
    height: 3rem; }
  .h-7\/2r\@xl\+, .sz-7\/2r\@xl\+ {
    height: 3.5rem; }
  .h-4r\@xl\+, .sz-4r\@xl\+ {
    height: 4rem; }
  .h-9\/2r\@xl\+, .sz-9\/2r\@xl\+ {
    height: 4.5rem; }
  .h-5r\@xl\+, .sz-5r\@xl\+ {
    height: 5rem; }
  .h-11\/2r\@xl\+, .sz-11\/2r\@xl\+ {
    height: 5.5rem; }
  .h-6r\@xl\+, .sz-6r\@xl\+ {
    height: 6rem; } }

@media screen and (min-width: 1200px) {
  .h-1\/4r\@lg\+, .sz-1\/4r\@lg\+ {
    height: 0.25rem; }
  .h-1\/2r\@lg\+, .sz-1\/2r\@lg\+ {
    height: 0.5rem; }
  .h-3\/4r\@lg\+, .sz-3\/4r\@lg\+ {
    height: 0.75rem; }
  .h-1r\@lg\+, .sz-1r\@lg\+ {
    height: 1rem; }
  .h-3\/2r\@lg\+, .sz-3\/2r\@lg\+ {
    height: 1.5rem; }
  .h-2r\@lg\+, .sz-2r\@lg\+ {
    height: 2rem; }
  .h-5\/2r\@lg\+, .sz-5\/2r\@lg\+ {
    height: 2.5rem; }
  .h-3r\@lg\+, .sz-3r\@lg\+ {
    height: 3rem; }
  .h-7\/2r\@lg\+, .sz-7\/2r\@lg\+ {
    height: 3.5rem; }
  .h-4r\@lg\+, .sz-4r\@lg\+ {
    height: 4rem; }
  .h-9\/2r\@lg\+, .sz-9\/2r\@lg\+ {
    height: 4.5rem; }
  .h-5r\@lg\+, .sz-5r\@lg\+ {
    height: 5rem; }
  .h-11\/2r\@lg\+, .sz-11\/2r\@lg\+ {
    height: 5.5rem; }
  .h-6r\@lg\+, .sz-6r\@lg\+ {
    height: 6rem; } }

@media screen and (min-width: 992px) {
  .h-1\/4r\@md\+, .sz-1\/4r\@md\+ {
    height: 0.25rem; }
  .h-1\/2r\@md\+, .sz-1\/2r\@md\+ {
    height: 0.5rem; }
  .h-3\/4r\@md\+, .sz-3\/4r\@md\+ {
    height: 0.75rem; }
  .h-1r\@md\+, .sz-1r\@md\+ {
    height: 1rem; }
  .h-3\/2r\@md\+, .sz-3\/2r\@md\+ {
    height: 1.5rem; }
  .h-2r\@md\+, .sz-2r\@md\+ {
    height: 2rem; }
  .h-5\/2r\@md\+, .sz-5\/2r\@md\+ {
    height: 2.5rem; }
  .h-3r\@md\+, .sz-3r\@md\+ {
    height: 3rem; }
  .h-7\/2r\@md\+, .sz-7\/2r\@md\+ {
    height: 3.5rem; }
  .h-4r\@md\+, .sz-4r\@md\+ {
    height: 4rem; }
  .h-9\/2r\@md\+, .sz-9\/2r\@md\+ {
    height: 4.5rem; }
  .h-5r\@md\+, .sz-5r\@md\+ {
    height: 5rem; }
  .h-11\/2r\@md\+, .sz-11\/2r\@md\+ {
    height: 5.5rem; }
  .h-6r\@md\+, .sz-6r\@md\+ {
    height: 6rem; } }

@media screen and (min-width: 768px) {
  .h-1\/4r\@sm\+, .sz-1\/4r\@sm\+ {
    height: 0.25rem; }
  .h-1\/2r\@sm\+, .sz-1\/2r\@sm\+ {
    height: 0.5rem; }
  .h-3\/4r\@sm\+, .sz-3\/4r\@sm\+ {
    height: 0.75rem; }
  .h-1r\@sm\+, .sz-1r\@sm\+ {
    height: 1rem; }
  .h-3\/2r\@sm\+, .sz-3\/2r\@sm\+ {
    height: 1.5rem; }
  .h-2r\@sm\+, .sz-2r\@sm\+ {
    height: 2rem; }
  .h-5\/2r\@sm\+, .sz-5\/2r\@sm\+ {
    height: 2.5rem; }
  .h-3r\@sm\+, .sz-3r\@sm\+ {
    height: 3rem; }
  .h-7\/2r\@sm\+, .sz-7\/2r\@sm\+ {
    height: 3.5rem; }
  .h-4r\@sm\+, .sz-4r\@sm\+ {
    height: 4rem; }
  .h-9\/2r\@sm\+, .sz-9\/2r\@sm\+ {
    height: 4.5rem; }
  .h-5r\@sm\+, .sz-5r\@sm\+ {
    height: 5rem; }
  .h-11\/2r\@sm\+, .sz-11\/2r\@sm\+ {
    height: 5.5rem; }
  .h-6r\@sm\+, .sz-6r\@sm\+ {
    height: 6rem; } }

@media screen and (min-width: 0) {
  .h-1\/4r\@xs\+, .sz-1\/4r\@xs\+ {
    height: 0.25rem; }
  .h-1\/2r\@xs\+, .sz-1\/2r\@xs\+ {
    height: 0.5rem; }
  .h-3\/4r\@xs\+, .sz-3\/4r\@xs\+ {
    height: 0.75rem; }
  .h-1r\@xs\+, .sz-1r\@xs\+ {
    height: 1rem; }
  .h-3\/2r\@xs\+, .sz-3\/2r\@xs\+ {
    height: 1.5rem; }
  .h-2r\@xs\+, .sz-2r\@xs\+ {
    height: 2rem; }
  .h-5\/2r\@xs\+, .sz-5\/2r\@xs\+ {
    height: 2.5rem; }
  .h-3r\@xs\+, .sz-3r\@xs\+ {
    height: 3rem; }
  .h-7\/2r\@xs\+, .sz-7\/2r\@xs\+ {
    height: 3.5rem; }
  .h-4r\@xs\+, .sz-4r\@xs\+ {
    height: 4rem; }
  .h-9\/2r\@xs\+, .sz-9\/2r\@xs\+ {
    height: 4.5rem; }
  .h-5r\@xs\+, .sz-5r\@xs\+ {
    height: 5rem; }
  .h-11\/2r\@xs\+, .sz-11\/2r\@xs\+ {
    height: 5.5rem; }
  .h-6r\@xs\+, .sz-6r\@xs\+ {
    height: 6rem; } }

@media screen and (max-width: 1439px) {
  .h-1\/4r\@xl-, .sz-1\/4r\@xl- {
    height: 0.25rem; }
  .h-1\/2r\@xl-, .sz-1\/2r\@xl- {
    height: 0.5rem; }
  .h-3\/4r\@xl-, .sz-3\/4r\@xl- {
    height: 0.75rem; }
  .h-1r\@xl-, .sz-1r\@xl- {
    height: 1rem; }
  .h-3\/2r\@xl-, .sz-3\/2r\@xl- {
    height: 1.5rem; }
  .h-2r\@xl-, .sz-2r\@xl- {
    height: 2rem; }
  .h-5\/2r\@xl-, .sz-5\/2r\@xl- {
    height: 2.5rem; }
  .h-3r\@xl-, .sz-3r\@xl- {
    height: 3rem; }
  .h-7\/2r\@xl-, .sz-7\/2r\@xl- {
    height: 3.5rem; }
  .h-4r\@xl-, .sz-4r\@xl- {
    height: 4rem; }
  .h-9\/2r\@xl-, .sz-9\/2r\@xl- {
    height: 4.5rem; }
  .h-5r\@xl-, .sz-5r\@xl- {
    height: 5rem; }
  .h-11\/2r\@xl-, .sz-11\/2r\@xl- {
    height: 5.5rem; }
  .h-6r\@xl-, .sz-6r\@xl- {
    height: 6rem; } }

@media screen and (max-width: 1199px) {
  .h-1\/4r\@lg-, .sz-1\/4r\@lg- {
    height: 0.25rem; }
  .h-1\/2r\@lg-, .sz-1\/2r\@lg- {
    height: 0.5rem; }
  .h-3\/4r\@lg-, .sz-3\/4r\@lg- {
    height: 0.75rem; }
  .h-1r\@lg-, .sz-1r\@lg- {
    height: 1rem; }
  .h-3\/2r\@lg-, .sz-3\/2r\@lg- {
    height: 1.5rem; }
  .h-2r\@lg-, .sz-2r\@lg- {
    height: 2rem; }
  .h-5\/2r\@lg-, .sz-5\/2r\@lg- {
    height: 2.5rem; }
  .h-3r\@lg-, .sz-3r\@lg- {
    height: 3rem; }
  .h-7\/2r\@lg-, .sz-7\/2r\@lg- {
    height: 3.5rem; }
  .h-4r\@lg-, .sz-4r\@lg- {
    height: 4rem; }
  .h-9\/2r\@lg-, .sz-9\/2r\@lg- {
    height: 4.5rem; }
  .h-5r\@lg-, .sz-5r\@lg- {
    height: 5rem; }
  .h-11\/2r\@lg-, .sz-11\/2r\@lg- {
    height: 5.5rem; }
  .h-6r\@lg-, .sz-6r\@lg- {
    height: 6rem; } }

@media screen and (max-width: 991px) {
  .h-1\/4r\@md-, .sz-1\/4r\@md- {
    height: 0.25rem; }
  .h-1\/2r\@md-, .sz-1\/2r\@md- {
    height: 0.5rem; }
  .h-3\/4r\@md-, .sz-3\/4r\@md- {
    height: 0.75rem; }
  .h-1r\@md-, .sz-1r\@md- {
    height: 1rem; }
  .h-3\/2r\@md-, .sz-3\/2r\@md- {
    height: 1.5rem; }
  .h-2r\@md-, .sz-2r\@md- {
    height: 2rem; }
  .h-5\/2r\@md-, .sz-5\/2r\@md- {
    height: 2.5rem; }
  .h-3r\@md-, .sz-3r\@md- {
    height: 3rem; }
  .h-7\/2r\@md-, .sz-7\/2r\@md- {
    height: 3.5rem; }
  .h-4r\@md-, .sz-4r\@md- {
    height: 4rem; }
  .h-9\/2r\@md-, .sz-9\/2r\@md- {
    height: 4.5rem; }
  .h-5r\@md-, .sz-5r\@md- {
    height: 5rem; }
  .h-11\/2r\@md-, .sz-11\/2r\@md- {
    height: 5.5rem; }
  .h-6r\@md-, .sz-6r\@md- {
    height: 6rem; } }

@media screen and (max-width: 767px) {
  .h-1\/4r\@sm-, .sz-1\/4r\@sm- {
    height: 0.25rem; }
  .h-1\/2r\@sm-, .sz-1\/2r\@sm- {
    height: 0.5rem; }
  .h-3\/4r\@sm-, .sz-3\/4r\@sm- {
    height: 0.75rem; }
  .h-1r\@sm-, .sz-1r\@sm- {
    height: 1rem; }
  .h-3\/2r\@sm-, .sz-3\/2r\@sm- {
    height: 1.5rem; }
  .h-2r\@sm-, .sz-2r\@sm- {
    height: 2rem; }
  .h-5\/2r\@sm-, .sz-5\/2r\@sm- {
    height: 2.5rem; }
  .h-3r\@sm-, .sz-3r\@sm- {
    height: 3rem; }
  .h-7\/2r\@sm-, .sz-7\/2r\@sm- {
    height: 3.5rem; }
  .h-4r\@sm-, .sz-4r\@sm- {
    height: 4rem; }
  .h-9\/2r\@sm-, .sz-9\/2r\@sm- {
    height: 4.5rem; }
  .h-5r\@sm-, .sz-5r\@sm- {
    height: 5rem; }
  .h-11\/2r\@sm-, .sz-11\/2r\@sm- {
    height: 5.5rem; }
  .h-6r\@sm-, .sz-6r\@sm- {
    height: 6rem; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .h-1\/4r\@lg, .sz-1\/4r\@lg {
    height: 0.25rem; }
  .h-1\/2r\@lg, .sz-1\/2r\@lg {
    height: 0.5rem; }
  .h-3\/4r\@lg, .sz-3\/4r\@lg {
    height: 0.75rem; }
  .h-1r\@lg, .sz-1r\@lg {
    height: 1rem; }
  .h-3\/2r\@lg, .sz-3\/2r\@lg {
    height: 1.5rem; }
  .h-2r\@lg, .sz-2r\@lg {
    height: 2rem; }
  .h-5\/2r\@lg, .sz-5\/2r\@lg {
    height: 2.5rem; }
  .h-3r\@lg, .sz-3r\@lg {
    height: 3rem; }
  .h-7\/2r\@lg, .sz-7\/2r\@lg {
    height: 3.5rem; }
  .h-4r\@lg, .sz-4r\@lg {
    height: 4rem; }
  .h-9\/2r\@lg, .sz-9\/2r\@lg {
    height: 4.5rem; }
  .h-5r\@lg, .sz-5r\@lg {
    height: 5rem; }
  .h-11\/2r\@lg, .sz-11\/2r\@lg {
    height: 5.5rem; }
  .h-6r\@lg, .sz-6r\@lg {
    height: 6rem; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .h-1\/4r\@md, .sz-1\/4r\@md {
    height: 0.25rem; }
  .h-1\/2r\@md, .sz-1\/2r\@md {
    height: 0.5rem; }
  .h-3\/4r\@md, .sz-3\/4r\@md {
    height: 0.75rem; }
  .h-1r\@md, .sz-1r\@md {
    height: 1rem; }
  .h-3\/2r\@md, .sz-3\/2r\@md {
    height: 1.5rem; }
  .h-2r\@md, .sz-2r\@md {
    height: 2rem; }
  .h-5\/2r\@md, .sz-5\/2r\@md {
    height: 2.5rem; }
  .h-3r\@md, .sz-3r\@md {
    height: 3rem; }
  .h-7\/2r\@md, .sz-7\/2r\@md {
    height: 3.5rem; }
  .h-4r\@md, .sz-4r\@md {
    height: 4rem; }
  .h-9\/2r\@md, .sz-9\/2r\@md {
    height: 4.5rem; }
  .h-5r\@md, .sz-5r\@md {
    height: 5rem; }
  .h-11\/2r\@md, .sz-11\/2r\@md {
    height: 5.5rem; }
  .h-6r\@md, .sz-6r\@md {
    height: 6rem; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .h-1\/4r\@sm, .sz-1\/4r\@sm {
    height: 0.25rem; }
  .h-1\/2r\@sm, .sz-1\/2r\@sm {
    height: 0.5rem; }
  .h-3\/4r\@sm, .sz-3\/4r\@sm {
    height: 0.75rem; }
  .h-1r\@sm, .sz-1r\@sm {
    height: 1rem; }
  .h-3\/2r\@sm, .sz-3\/2r\@sm {
    height: 1.5rem; }
  .h-2r\@sm, .sz-2r\@sm {
    height: 2rem; }
  .h-5\/2r\@sm, .sz-5\/2r\@sm {
    height: 2.5rem; }
  .h-3r\@sm, .sz-3r\@sm {
    height: 3rem; }
  .h-7\/2r\@sm, .sz-7\/2r\@sm {
    height: 3.5rem; }
  .h-4r\@sm, .sz-4r\@sm {
    height: 4rem; }
  .h-9\/2r\@sm, .sz-9\/2r\@sm {
    height: 4.5rem; }
  .h-5r\@sm, .sz-5r\@sm {
    height: 5rem; }
  .h-11\/2r\@sm, .sz-11\/2r\@sm {
    height: 5.5rem; }
  .h-6r\@sm, .sz-6r\@sm {
    height: 6rem; } }

.h-0 {
  height: 0; }

.h-auto {
  height: auto; }

.h-100p {
  height: 100%; }

.h-100vh {
  height: 100vh; }

@media screen and (min-width: 1440px) {
  .h-0\@xl\+ {
    height: 0; }
  .h-auto\@xl\+ {
    height: auto; }
  .h-100p\@xl\+ {
    height: 100%; }
  .h-100vh\@xl\+ {
    height: 100vh; } }

@media screen and (min-width: 1200px) {
  .h-0\@lg\+ {
    height: 0; }
  .h-auto\@lg\+ {
    height: auto; }
  .h-100p\@lg\+ {
    height: 100%; }
  .h-100vh\@lg\+ {
    height: 100vh; } }

@media screen and (min-width: 992px) {
  .h-0\@md\+ {
    height: 0; }
  .h-auto\@md\+ {
    height: auto; }
  .h-100p\@md\+ {
    height: 100%; }
  .h-100vh\@md\+ {
    height: 100vh; } }

@media screen and (min-width: 768px) {
  .h-0\@sm\+ {
    height: 0; }
  .h-auto\@sm\+ {
    height: auto; }
  .h-100p\@sm\+ {
    height: 100%; }
  .h-100vh\@sm\+ {
    height: 100vh; } }

@media screen and (min-width: 0) {
  .h-0\@xs\+ {
    height: 0; }
  .h-auto\@xs\+ {
    height: auto; }
  .h-100p\@xs\+ {
    height: 100%; }
  .h-100vh\@xs\+ {
    height: 100vh; } }

@media screen and (max-width: 1439px) {
  .h-0\@xl- {
    height: 0; }
  .h-auto\@xl- {
    height: auto; }
  .h-100p\@xl- {
    height: 100%; }
  .h-100vh\@xl- {
    height: 100vh; } }

@media screen and (max-width: 1199px) {
  .h-0\@lg- {
    height: 0; }
  .h-auto\@lg- {
    height: auto; }
  .h-100p\@lg- {
    height: 100%; }
  .h-100vh\@lg- {
    height: 100vh; } }

@media screen and (max-width: 991px) {
  .h-0\@md- {
    height: 0; }
  .h-auto\@md- {
    height: auto; }
  .h-100p\@md- {
    height: 100%; }
  .h-100vh\@md- {
    height: 100vh; } }

@media screen and (max-width: 767px) {
  .h-0\@sm- {
    height: 0; }
  .h-auto\@sm- {
    height: auto; }
  .h-100p\@sm- {
    height: 100%; }
  .h-100vh\@sm- {
    height: 100vh; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .h-0\@lg {
    height: 0; }
  .h-auto\@lg {
    height: auto; }
  .h-100p\@lg {
    height: 100%; }
  .h-100vh\@lg {
    height: 100vh; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .h-0\@md {
    height: 0; }
  .h-auto\@md {
    height: auto; }
  .h-100p\@md {
    height: 100%; }
  .h-100vh\@md {
    height: 100vh; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .h-0\@sm {
    height: 0; }
  .h-auto\@sm {
    height: auto; }
  .h-100p\@sm {
    height: 100%; }
  .h-100vh\@sm {
    height: 100vh; } }

.mw-100p {
  max-width: 100%; }

.mh-100p {
  max-height: 100%; }

@media screen and (min-width: 1440px) {
  .mw-100p\@xl\+ {
    max-width: 100%; }
  .mh-100p\@xl\+ {
    max-height: 100%; } }

@media screen and (min-width: 1200px) {
  .mw-100p\@lg\+ {
    max-width: 100%; }
  .mh-100p\@lg\+ {
    max-height: 100%; } }

@media screen and (min-width: 992px) {
  .mw-100p\@md\+ {
    max-width: 100%; }
  .mh-100p\@md\+ {
    max-height: 100%; } }

@media screen and (min-width: 768px) {
  .mw-100p\@sm\+ {
    max-width: 100%; }
  .mh-100p\@sm\+ {
    max-height: 100%; } }

@media screen and (min-width: 0) {
  .mw-100p\@xs\+ {
    max-width: 100%; }
  .mh-100p\@xs\+ {
    max-height: 100%; } }

@media screen and (max-width: 1439px) {
  .mw-100p\@xl- {
    max-width: 100%; }
  .mh-100p\@xl- {
    max-height: 100%; } }

@media screen and (max-width: 1199px) {
  .mw-100p\@lg- {
    max-width: 100%; }
  .mh-100p\@lg- {
    max-height: 100%; } }

@media screen and (max-width: 991px) {
  .mw-100p\@md- {
    max-width: 100%; }
  .mh-100p\@md- {
    max-height: 100%; } }

@media screen and (max-width: 767px) {
  .mw-100p\@sm- {
    max-width: 100%; }
  .mh-100p\@sm- {
    max-height: 100%; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .mw-100p\@lg {
    max-width: 100%; }
  .mh-100p\@lg {
    max-height: 100%; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .mw-100p\@md {
    max-width: 100%; }
  .mh-100p\@md {
    max-height: 100%; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .mw-100p\@sm {
    max-width: 100%; }
  .mh-100p\@sm {
    max-height: 100%; } }

.ta-c {
  text-align: center !important; }

.ta-l {
  text-align: left !important; }

.ta-r {
  text-align: right !important; }

@media screen and (min-width: 1440px) {
  .ta-c\@xl\+ {
    text-align: center !important; }
  .ta-l\@xl\+ {
    text-align: left !important; }
  .ta-r\@xl\+ {
    text-align: right !important; } }

@media screen and (min-width: 1200px) {
  .ta-c\@lg\+ {
    text-align: center !important; }
  .ta-l\@lg\+ {
    text-align: left !important; }
  .ta-r\@lg\+ {
    text-align: right !important; } }

@media screen and (min-width: 992px) {
  .ta-c\@md\+ {
    text-align: center !important; }
  .ta-l\@md\+ {
    text-align: left !important; }
  .ta-r\@md\+ {
    text-align: right !important; } }

@media screen and (min-width: 768px) {
  .ta-c\@sm\+ {
    text-align: center !important; }
  .ta-l\@sm\+ {
    text-align: left !important; }
  .ta-r\@sm\+ {
    text-align: right !important; } }

@media screen and (min-width: 0) {
  .ta-c\@xs\+ {
    text-align: center !important; }
  .ta-l\@xs\+ {
    text-align: left !important; }
  .ta-r\@xs\+ {
    text-align: right !important; } }

@media screen and (max-width: 1439px) {
  .ta-c\@xl- {
    text-align: center !important; }
  .ta-l\@xl- {
    text-align: left !important; }
  .ta-r\@xl- {
    text-align: right !important; } }

@media screen and (max-width: 1199px) {
  .ta-c\@lg- {
    text-align: center !important; }
  .ta-l\@lg- {
    text-align: left !important; }
  .ta-r\@lg- {
    text-align: right !important; } }

@media screen and (max-width: 991px) {
  .ta-c\@md- {
    text-align: center !important; }
  .ta-l\@md- {
    text-align: left !important; }
  .ta-r\@md- {
    text-align: right !important; } }

@media screen and (max-width: 767px) {
  .ta-c\@sm- {
    text-align: center !important; }
  .ta-l\@sm- {
    text-align: left !important; }
  .ta-r\@sm- {
    text-align: right !important; } }

@media screen and (min-width: 1199px) and (max-width: 1439px) {
  .ta-c\@lg {
    text-align: center !important; }
  .ta-l\@lg {
    text-align: left !important; }
  .ta-r\@lg {
    text-align: right !important; } }

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .ta-c\@md {
    text-align: center !important; }
  .ta-l\@md {
    text-align: left !important; }
  .ta-r\@md {
    text-align: right !important; } }

@media screen and (min-width: 767px) and (max-width: 991px) {
  .ta-c\@sm {
    text-align: center !important; }
  .ta-l\@sm {
    text-align: left !important; }
  .ta-r\@sm {
    text-align: right !important; } }

.tt-n {
  text-transform: none !important; }

.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.td-n {
  text-decoration: none !important; }

.td-o {
  text-decoration: overline !important; }

.td-lt {
  text-decoration: line-through !important; }

.td-u {
  text-decoration: underline !important; }

.whs-nw {
  white-space: nowrap !important; }

.whs-p {
  white-space: pre !important; }

.whs-n {
  white-space: normal !important; }

.wob-n {
  word-break: normal !important; }

.wob-ba {
  word-break: break-all !important; }

.wob-k {
  word-break: keep-all !important; }

.wow-bw {
  word-wrap: break-word !important; }

.wow-n {
  word-wrap: normal !important; }

.tov-e {
  text-overflow: ellipsis !important; }

.fsz-xs {
  font-size: 0.75rem !important; }

.fsz-sm {
  font-size: 0.87rem !important; }

.fsz-def {
  font-size: 1rem !important; }

.fsz-md {
  font-size: 1.15rem !important; }

.fsz-lg {
  font-size: 1.4rem !important; }

.fsz-xl {
  font-size: 1.7rem !important; }

.fw-100 {
  font-weight: 100 !important; }

.fw-200 {
  font-weight: 200 !important; }

.fw-300 {
  font-weight: 300 !important; }

.fw-400 {
  font-weight: 400 !important; }

.fw-500 {
  font-weight: 500 !important; }

.fw-600 {
  font-weight: 600 !important; }

.fw-700 {
  font-weight: 700 !important; }

.fw-800 {
  font-weight: 800 !important; }

.fw-900 {
  font-weight: 900 !important; }

.lh-0 {
  line-height: 0 !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-3\/2 {
  line-height: 1.5 !important; }

.bd {
  border: 1px solid rgba(0, 0, 0, 0.0625) !important; }

.bdT {
  border-top: 1px solid rgba(0, 0, 0, 0.0625) !important; }

.bdR {
  border-right: 1px solid rgba(0, 0, 0, 0.0625) !important; }

.bdB {
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625) !important; }

.bdL {
  border-left: 1px solid rgba(0, 0, 0, 0.0625) !important; }

.bdw-0 {
  border-width: 0px !important; }

.bdwT-0 {
  border-top-width: 0px !important; }

.bdwR-0 {
  border-right-width: 0px !important; }

.bdwB-0 {
  border-bottom-width: 0px !important; }

.bdwL-0 {
  border-left-width: 0px !important; }

.bdw-1 {
  border-width: 1px !important; }

.bdwT-1 {
  border-top-width: 1px !important; }

.bdwR-1 {
  border-right-width: 1px !important; }

.bdwB-1 {
  border-bottom-width: 1px !important; }

.bdwL-1 {
  border-left-width: 1px !important; }

.bdw-2 {
  border-width: 2px !important; }

.bdwT-2 {
  border-top-width: 2px !important; }

.bdwR-2 {
  border-right-width: 2px !important; }

.bdwB-2 {
  border-bottom-width: 2px !important; }

.bdwL-2 {
  border-left-width: 2px !important; }

.bdw-3 {
  border-width: 3px !important; }

.bdwT-3 {
  border-top-width: 3px !important; }

.bdwR-3 {
  border-right-width: 3px !important; }

.bdwB-3 {
  border-bottom-width: 3px !important; }

.bdwL-3 {
  border-left-width: 3px !important; }

.bdw-4 {
  border-width: 4px !important; }

.bdwT-4 {
  border-top-width: 4px !important; }

.bdwR-4 {
  border-right-width: 4px !important; }

.bdwB-4 {
  border-bottom-width: 4px !important; }

.bdwL-4 {
  border-left-width: 4px !important; }

.bdw-5 {
  border-width: 5px !important; }

.bdwT-5 {
  border-top-width: 5px !important; }

.bdwR-5 {
  border-right-width: 5px !important; }

.bdwB-5 {
  border-bottom-width: 5px !important; }

.bdwL-5 {
  border-left-width: 5px !important; }

.bdrs-0 {
  border-radius: 0px !important; }

.bdrsT-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important; }

.bdrsR-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.bdrsB-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.bdrsL-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.bdrs-1 {
  border-radius: 1px !important; }

.bdrsT-1 {
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important; }

.bdrsR-1 {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important; }

.bdrsB-1 {
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important; }

.bdrsL-1 {
  border-top-left-radius: 1px !important;
  border-bottom-left-radius: 1px !important; }

.bdrs-2 {
  border-radius: 2px !important; }

.bdrsT-2 {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important; }

.bdrsR-2 {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.bdrsB-2 {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important; }

.bdrsL-2 {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important; }

.bdrs-3 {
  border-radius: 3px !important; }

.bdrsT-3 {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important; }

.bdrsR-3 {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.bdrsB-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important; }

.bdrsL-3 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important; }

.bdrs-4 {
  border-radius: 4px !important; }

.bdrsT-4 {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.bdrsR-4 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.bdrsB-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.bdrsL-4 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.bdrs-50p {
  border-radius: 50% !important; }

.bdrs-10em {
  border-radius: 10em !important; }

.bds-n {
  border-style: none !important; }

.bds-s {
  border-style: solid !important; }

.bds-dt {
  border-style: dotted !important; }

.bds-ds {
  border-style: dashed !important; }

.bds-db {
  border-style: double !important; }

.bds-g {
  border-style: groove !important; }

.bds-r {
  border-style: ridge !important; }

.bds-i {
  border-style: inset !important; }

.bds-o {
  border-style: outset !important; }

.no-after::after {
  display: none !important; }

.no-before::before {
  display: none !important; }

.c-white, .cH-white:hover {
  color: #ffffff !important; }

.bgc-white, .bgcH-white:hover {
  background-color: #ffffff !important; }

.bdc-white, .bdcH-white:hover {
  border-color: #ffffff !important; }

.fill-white, .fillH-white:hover {
  fill: #ffffff !important; }

.str-white, .strH-white:hover {
  stroke: #ffffff !important; }

.c-red-50, .cH-red-50:hover {
  color: #ffebee !important; }

.bgc-red-50, .bgcH-red-50:hover {
  background-color: #ffebee !important; }

.bdc-red-50, .bdcH-red-50:hover {
  border-color: #ffebee !important; }

.fill-red-50, .fillH-red-50:hover {
  fill: #ffebee !important; }

.str-red-50, .strH-red-50:hover {
  stroke: #ffebee !important; }

.c-red-100, .cH-red-100:hover {
  color: #ffcdd2 !important; }

.bgc-red-100, .bgcH-red-100:hover {
  background-color: #ffcdd2 !important; }

.bdc-red-100, .bdcH-red-100:hover {
  border-color: #ffcdd2 !important; }

.fill-red-100, .fillH-red-100:hover {
  fill: #ffcdd2 !important; }

.str-red-100, .strH-red-100:hover {
  stroke: #ffcdd2 !important; }

.c-red-200, .cH-red-200:hover {
  color: #ef9a9a !important; }

.bgc-red-200, .bgcH-red-200:hover {
  background-color: #ef9a9a !important; }

.bdc-red-200, .bdcH-red-200:hover {
  border-color: #ef9a9a !important; }

.fill-red-200, .fillH-red-200:hover {
  fill: #ef9a9a !important; }

.str-red-200, .strH-red-200:hover {
  stroke: #ef9a9a !important; }

.c-red-300, .cH-red-300:hover {
  color: #e57373 !important; }

.bgc-red-300, .bgcH-red-300:hover {
  background-color: #e57373 !important; }

.bdc-red-300, .bdcH-red-300:hover {
  border-color: #e57373 !important; }

.fill-red-300, .fillH-red-300:hover {
  fill: #e57373 !important; }

.str-red-300, .strH-red-300:hover {
  stroke: #e57373 !important; }

.c-red-400, .cH-red-400:hover {
  color: #ef5350 !important; }

.bgc-red-400, .bgcH-red-400:hover {
  background-color: #ef5350 !important; }

.bdc-red-400, .bdcH-red-400:hover {
  border-color: #ef5350 !important; }

.fill-red-400, .fillH-red-400:hover {
  fill: #ef5350 !important; }

.str-red-400, .strH-red-400:hover {
  stroke: #ef5350 !important; }

.c-red-500, .cH-red-500:hover {
  color: #f44336 !important; }

.bgc-red-500, .bgcH-red-500:hover {
  background-color: #f44336 !important; }

.bdc-red-500, .bdcH-red-500:hover {
  border-color: #f44336 !important; }

.fill-red-500, .fillH-red-500:hover {
  fill: #f44336 !important; }

.str-red-500, .strH-red-500:hover {
  stroke: #f44336 !important; }

.c-red-600, .cH-red-600:hover {
  color: #e53935 !important; }

.bgc-red-600, .bgcH-red-600:hover {
  background-color: #e53935 !important; }

.bdc-red-600, .bdcH-red-600:hover {
  border-color: #e53935 !important; }

.fill-red-600, .fillH-red-600:hover {
  fill: #e53935 !important; }

.str-red-600, .strH-red-600:hover {
  stroke: #e53935 !important; }

.c-red-700, .cH-red-700:hover {
  color: #d32f2f !important; }

.bgc-red-700, .bgcH-red-700:hover {
  background-color: #d32f2f !important; }

.bdc-red-700, .bdcH-red-700:hover {
  border-color: #d32f2f !important; }

.fill-red-700, .fillH-red-700:hover {
  fill: #d32f2f !important; }

.str-red-700, .strH-red-700:hover {
  stroke: #d32f2f !important; }

.c-red-800, .cH-red-800:hover {
  color: #c62828 !important; }

.bgc-red-800, .bgcH-red-800:hover {
  background-color: #c62828 !important; }

.bdc-red-800, .bdcH-red-800:hover {
  border-color: #c62828 !important; }

.fill-red-800, .fillH-red-800:hover {
  fill: #c62828 !important; }

.str-red-800, .strH-red-800:hover {
  stroke: #c62828 !important; }

.c-red-900, .cH-red-900:hover {
  color: #b71c1c !important; }

.bgc-red-900, .bgcH-red-900:hover {
  background-color: #b71c1c !important; }

.bdc-red-900, .bdcH-red-900:hover {
  border-color: #b71c1c !important; }

.fill-red-900, .fillH-red-900:hover {
  fill: #b71c1c !important; }

.str-red-900, .strH-red-900:hover {
  stroke: #b71c1c !important; }

.c-red-a100, .cH-red-a100:hover {
  color: #ff8a80 !important; }

.bgc-red-a100, .bgcH-red-a100:hover {
  background-color: #ff8a80 !important; }

.bdc-red-a100, .bdcH-red-a100:hover {
  border-color: #ff8a80 !important; }

.fill-red-a100, .fillH-red-a100:hover {
  fill: #ff8a80 !important; }

.str-red-a100, .strH-red-a100:hover {
  stroke: #ff8a80 !important; }

.c-red-a200, .cH-red-a200:hover {
  color: #ff5252 !important; }

.bgc-red-a200, .bgcH-red-a200:hover {
  background-color: #ff5252 !important; }

.bdc-red-a200, .bdcH-red-a200:hover {
  border-color: #ff5252 !important; }

.fill-red-a200, .fillH-red-a200:hover {
  fill: #ff5252 !important; }

.str-red-a200, .strH-red-a200:hover {
  stroke: #ff5252 !important; }

.c-red-a400, .cH-red-a400:hover {
  color: #ff1744 !important; }

.bgc-red-a400, .bgcH-red-a400:hover {
  background-color: #ff1744 !important; }

.bdc-red-a400, .bdcH-red-a400:hover {
  border-color: #ff1744 !important; }

.fill-red-a400, .fillH-red-a400:hover {
  fill: #ff1744 !important; }

.str-red-a400, .strH-red-a400:hover {
  stroke: #ff1744 !important; }

.c-red-a700, .cH-red-a700:hover {
  color: #d50000 !important; }

.bgc-red-a700, .bgcH-red-a700:hover {
  background-color: #d50000 !important; }

.bdc-red-a700, .bdcH-red-a700:hover {
  border-color: #d50000 !important; }

.fill-red-a700, .fillH-red-a700:hover {
  fill: #d50000 !important; }

.str-red-a700, .strH-red-a700:hover {
  stroke: #d50000 !important; }

.c-pink-50, .cH-pink-50:hover {
  color: #fce4ec !important; }

.bgc-pink-50, .bgcH-pink-50:hover {
  background-color: #fce4ec !important; }

.bdc-pink-50, .bdcH-pink-50:hover {
  border-color: #fce4ec !important; }

.fill-pink-50, .fillH-pink-50:hover {
  fill: #fce4ec !important; }

.str-pink-50, .strH-pink-50:hover {
  stroke: #fce4ec !important; }

.c-pink-100, .cH-pink-100:hover {
  color: #f8bbd0 !important; }

.bgc-pink-100, .bgcH-pink-100:hover {
  background-color: #f8bbd0 !important; }

.bdc-pink-100, .bdcH-pink-100:hover {
  border-color: #f8bbd0 !important; }

.fill-pink-100, .fillH-pink-100:hover {
  fill: #f8bbd0 !important; }

.str-pink-100, .strH-pink-100:hover {
  stroke: #f8bbd0 !important; }

.c-pink-200, .cH-pink-200:hover {
  color: #f48fb1 !important; }

.bgc-pink-200, .bgcH-pink-200:hover {
  background-color: #f48fb1 !important; }

.bdc-pink-200, .bdcH-pink-200:hover {
  border-color: #f48fb1 !important; }

.fill-pink-200, .fillH-pink-200:hover {
  fill: #f48fb1 !important; }

.str-pink-200, .strH-pink-200:hover {
  stroke: #f48fb1 !important; }

.c-pink-300, .cH-pink-300:hover {
  color: #f06292 !important; }

.bgc-pink-300, .bgcH-pink-300:hover {
  background-color: #f06292 !important; }

.bdc-pink-300, .bdcH-pink-300:hover {
  border-color: #f06292 !important; }

.fill-pink-300, .fillH-pink-300:hover {
  fill: #f06292 !important; }

.str-pink-300, .strH-pink-300:hover {
  stroke: #f06292 !important; }

.c-pink-400, .cH-pink-400:hover {
  color: #ec407a !important; }

.bgc-pink-400, .bgcH-pink-400:hover {
  background-color: #ec407a !important; }

.bdc-pink-400, .bdcH-pink-400:hover {
  border-color: #ec407a !important; }

.fill-pink-400, .fillH-pink-400:hover {
  fill: #ec407a !important; }

.str-pink-400, .strH-pink-400:hover {
  stroke: #ec407a !important; }

.c-pink-500, .cH-pink-500:hover {
  color: #e91e63 !important; }

.bgc-pink-500, .bgcH-pink-500:hover {
  background-color: #e91e63 !important; }

.bdc-pink-500, .bdcH-pink-500:hover {
  border-color: #e91e63 !important; }

.fill-pink-500, .fillH-pink-500:hover {
  fill: #e91e63 !important; }

.str-pink-500, .strH-pink-500:hover {
  stroke: #e91e63 !important; }

.c-pink-600, .cH-pink-600:hover {
  color: #d81b60 !important; }

.bgc-pink-600, .bgcH-pink-600:hover {
  background-color: #d81b60 !important; }

.bdc-pink-600, .bdcH-pink-600:hover {
  border-color: #d81b60 !important; }

.fill-pink-600, .fillH-pink-600:hover {
  fill: #d81b60 !important; }

.str-pink-600, .strH-pink-600:hover {
  stroke: #d81b60 !important; }

.c-pink-700, .cH-pink-700:hover {
  color: #c2185b !important; }

.bgc-pink-700, .bgcH-pink-700:hover {
  background-color: #c2185b !important; }

.bdc-pink-700, .bdcH-pink-700:hover {
  border-color: #c2185b !important; }

.fill-pink-700, .fillH-pink-700:hover {
  fill: #c2185b !important; }

.str-pink-700, .strH-pink-700:hover {
  stroke: #c2185b !important; }

.c-pink-800, .cH-pink-800:hover {
  color: #ad1457 !important; }

.bgc-pink-800, .bgcH-pink-800:hover {
  background-color: #ad1457 !important; }

.bdc-pink-800, .bdcH-pink-800:hover {
  border-color: #ad1457 !important; }

.fill-pink-800, .fillH-pink-800:hover {
  fill: #ad1457 !important; }

.str-pink-800, .strH-pink-800:hover {
  stroke: #ad1457 !important; }

.c-pink-900, .cH-pink-900:hover {
  color: #880e4f !important; }

.bgc-pink-900, .bgcH-pink-900:hover {
  background-color: #880e4f !important; }

.bdc-pink-900, .bdcH-pink-900:hover {
  border-color: #880e4f !important; }

.fill-pink-900, .fillH-pink-900:hover {
  fill: #880e4f !important; }

.str-pink-900, .strH-pink-900:hover {
  stroke: #880e4f !important; }

.c-pink-a100, .cH-pink-a100:hover {
  color: #ff80ab !important; }

.bgc-pink-a100, .bgcH-pink-a100:hover {
  background-color: #ff80ab !important; }

.bdc-pink-a100, .bdcH-pink-a100:hover {
  border-color: #ff80ab !important; }

.fill-pink-a100, .fillH-pink-a100:hover {
  fill: #ff80ab !important; }

.str-pink-a100, .strH-pink-a100:hover {
  stroke: #ff80ab !important; }

.c-pink-a200, .cH-pink-a200:hover {
  color: #ff4081 !important; }

.bgc-pink-a200, .bgcH-pink-a200:hover {
  background-color: #ff4081 !important; }

.bdc-pink-a200, .bdcH-pink-a200:hover {
  border-color: #ff4081 !important; }

.fill-pink-a200, .fillH-pink-a200:hover {
  fill: #ff4081 !important; }

.str-pink-a200, .strH-pink-a200:hover {
  stroke: #ff4081 !important; }

.c-pink-a400, .cH-pink-a400:hover {
  color: #f50057 !important; }

.bgc-pink-a400, .bgcH-pink-a400:hover {
  background-color: #f50057 !important; }

.bdc-pink-a400, .bdcH-pink-a400:hover {
  border-color: #f50057 !important; }

.fill-pink-a400, .fillH-pink-a400:hover {
  fill: #f50057 !important; }

.str-pink-a400, .strH-pink-a400:hover {
  stroke: #f50057 !important; }

.c-pink-a700, .cH-pink-a700:hover {
  color: #c51162 !important; }

.bgc-pink-a700, .bgcH-pink-a700:hover {
  background-color: #c51162 !important; }

.bdc-pink-a700, .bdcH-pink-a700:hover {
  border-color: #c51162 !important; }

.fill-pink-a700, .fillH-pink-a700:hover {
  fill: #c51162 !important; }

.str-pink-a700, .strH-pink-a700:hover {
  stroke: #c51162 !important; }

.c-purple-50, .cH-purple-50:hover {
  color: #f3e5f5 !important; }

.bgc-purple-50, .bgcH-purple-50:hover {
  background-color: #f3e5f5 !important; }

.bdc-purple-50, .bdcH-purple-50:hover {
  border-color: #f3e5f5 !important; }

.fill-purple-50, .fillH-purple-50:hover {
  fill: #f3e5f5 !important; }

.str-purple-50, .strH-purple-50:hover {
  stroke: #f3e5f5 !important; }

.c-purple-100, .cH-purple-100:hover {
  color: #e1bee7 !important; }

.bgc-purple-100, .bgcH-purple-100:hover {
  background-color: #e1bee7 !important; }

.bdc-purple-100, .bdcH-purple-100:hover {
  border-color: #e1bee7 !important; }

.fill-purple-100, .fillH-purple-100:hover {
  fill: #e1bee7 !important; }

.str-purple-100, .strH-purple-100:hover {
  stroke: #e1bee7 !important; }

.c-purple-200, .cH-purple-200:hover {
  color: #ce93d8 !important; }

.bgc-purple-200, .bgcH-purple-200:hover {
  background-color: #ce93d8 !important; }

.bdc-purple-200, .bdcH-purple-200:hover {
  border-color: #ce93d8 !important; }

.fill-purple-200, .fillH-purple-200:hover {
  fill: #ce93d8 !important; }

.str-purple-200, .strH-purple-200:hover {
  stroke: #ce93d8 !important; }

.c-purple-300, .cH-purple-300:hover {
  color: #ba68c8 !important; }

.bgc-purple-300, .bgcH-purple-300:hover {
  background-color: #ba68c8 !important; }

.bdc-purple-300, .bdcH-purple-300:hover {
  border-color: #ba68c8 !important; }

.fill-purple-300, .fillH-purple-300:hover {
  fill: #ba68c8 !important; }

.str-purple-300, .strH-purple-300:hover {
  stroke: #ba68c8 !important; }

.c-purple-400, .cH-purple-400:hover {
  color: #ab47bc !important; }

.bgc-purple-400, .bgcH-purple-400:hover {
  background-color: #ab47bc !important; }

.bdc-purple-400, .bdcH-purple-400:hover {
  border-color: #ab47bc !important; }

.fill-purple-400, .fillH-purple-400:hover {
  fill: #ab47bc !important; }

.str-purple-400, .strH-purple-400:hover {
  stroke: #ab47bc !important; }

.c-purple-500, .cH-purple-500:hover {
  color: #9c27b0 !important; }

.bgc-purple-500, .bgcH-purple-500:hover {
  background-color: #9c27b0 !important; }

.bdc-purple-500, .bdcH-purple-500:hover {
  border-color: #9c27b0 !important; }

.fill-purple-500, .fillH-purple-500:hover {
  fill: #9c27b0 !important; }

.str-purple-500, .strH-purple-500:hover {
  stroke: #9c27b0 !important; }

.c-purple-600, .cH-purple-600:hover {
  color: #8e24aa !important; }

.bgc-purple-600, .bgcH-purple-600:hover {
  background-color: #8e24aa !important; }

.bdc-purple-600, .bdcH-purple-600:hover {
  border-color: #8e24aa !important; }

.fill-purple-600, .fillH-purple-600:hover {
  fill: #8e24aa !important; }

.str-purple-600, .strH-purple-600:hover {
  stroke: #8e24aa !important; }

.c-purple-700, .cH-purple-700:hover {
  color: #7b1fa2 !important; }

.bgc-purple-700, .bgcH-purple-700:hover {
  background-color: #7b1fa2 !important; }

.bdc-purple-700, .bdcH-purple-700:hover {
  border-color: #7b1fa2 !important; }

.fill-purple-700, .fillH-purple-700:hover {
  fill: #7b1fa2 !important; }

.str-purple-700, .strH-purple-700:hover {
  stroke: #7b1fa2 !important; }

.c-purple-800, .cH-purple-800:hover {
  color: #6a1b9a !important; }

.bgc-purple-800, .bgcH-purple-800:hover {
  background-color: #6a1b9a !important; }

.bdc-purple-800, .bdcH-purple-800:hover {
  border-color: #6a1b9a !important; }

.fill-purple-800, .fillH-purple-800:hover {
  fill: #6a1b9a !important; }

.str-purple-800, .strH-purple-800:hover {
  stroke: #6a1b9a !important; }

.c-purple-900, .cH-purple-900:hover {
  color: #4a148c !important; }

.bgc-purple-900, .bgcH-purple-900:hover {
  background-color: #4a148c !important; }

.bdc-purple-900, .bdcH-purple-900:hover {
  border-color: #4a148c !important; }

.fill-purple-900, .fillH-purple-900:hover {
  fill: #4a148c !important; }

.str-purple-900, .strH-purple-900:hover {
  stroke: #4a148c !important; }

.c-purple-a100, .cH-purple-a100:hover {
  color: #ea80fc !important; }

.bgc-purple-a100, .bgcH-purple-a100:hover {
  background-color: #ea80fc !important; }

.bdc-purple-a100, .bdcH-purple-a100:hover {
  border-color: #ea80fc !important; }

.fill-purple-a100, .fillH-purple-a100:hover {
  fill: #ea80fc !important; }

.str-purple-a100, .strH-purple-a100:hover {
  stroke: #ea80fc !important; }

.c-purple-a200, .cH-purple-a200:hover {
  color: #e040fb !important; }

.bgc-purple-a200, .bgcH-purple-a200:hover {
  background-color: #e040fb !important; }

.bdc-purple-a200, .bdcH-purple-a200:hover {
  border-color: #e040fb !important; }

.fill-purple-a200, .fillH-purple-a200:hover {
  fill: #e040fb !important; }

.str-purple-a200, .strH-purple-a200:hover {
  stroke: #e040fb !important; }

.c-purple-a400, .cH-purple-a400:hover {
  color: #d500f9 !important; }

.bgc-purple-a400, .bgcH-purple-a400:hover {
  background-color: #d500f9 !important; }

.bdc-purple-a400, .bdcH-purple-a400:hover {
  border-color: #d500f9 !important; }

.fill-purple-a400, .fillH-purple-a400:hover {
  fill: #d500f9 !important; }

.str-purple-a400, .strH-purple-a400:hover {
  stroke: #d500f9 !important; }

.c-purple-a700, .cH-purple-a700:hover {
  color: #aa00ff !important; }

.bgc-purple-a700, .bgcH-purple-a700:hover {
  background-color: #aa00ff !important; }

.bdc-purple-a700, .bdcH-purple-a700:hover {
  border-color: #aa00ff !important; }

.fill-purple-a700, .fillH-purple-a700:hover {
  fill: #aa00ff !important; }

.str-purple-a700, .strH-purple-a700:hover {
  stroke: #aa00ff !important; }

.c-deep-purple-50, .cH-deep-purple-50:hover {
  color: #ede7f6 !important; }

.bgc-deep-purple-50, .bgcH-deep-purple-50:hover {
  background-color: #ede7f6 !important; }

.bdc-deep-purple-50, .bdcH-deep-purple-50:hover {
  border-color: #ede7f6 !important; }

.fill-deep-purple-50, .fillH-deep-purple-50:hover {
  fill: #ede7f6 !important; }

.str-deep-purple-50, .strH-deep-purple-50:hover {
  stroke: #ede7f6 !important; }

.c-deep-purple-100, .cH-deep-purple-100:hover {
  color: #d1c4e9 !important; }

.bgc-deep-purple-100, .bgcH-deep-purple-100:hover {
  background-color: #d1c4e9 !important; }

.bdc-deep-purple-100, .bdcH-deep-purple-100:hover {
  border-color: #d1c4e9 !important; }

.fill-deep-purple-100, .fillH-deep-purple-100:hover {
  fill: #d1c4e9 !important; }

.str-deep-purple-100, .strH-deep-purple-100:hover {
  stroke: #d1c4e9 !important; }

.c-deep-purple-200, .cH-deep-purple-200:hover {
  color: #b39ddb !important; }

.bgc-deep-purple-200, .bgcH-deep-purple-200:hover {
  background-color: #b39ddb !important; }

.bdc-deep-purple-200, .bdcH-deep-purple-200:hover {
  border-color: #b39ddb !important; }

.fill-deep-purple-200, .fillH-deep-purple-200:hover {
  fill: #b39ddb !important; }

.str-deep-purple-200, .strH-deep-purple-200:hover {
  stroke: #b39ddb !important; }

.c-deep-purple-300, .cH-deep-purple-300:hover {
  color: #9575cd !important; }

.bgc-deep-purple-300, .bgcH-deep-purple-300:hover {
  background-color: #9575cd !important; }

.bdc-deep-purple-300, .bdcH-deep-purple-300:hover {
  border-color: #9575cd !important; }

.fill-deep-purple-300, .fillH-deep-purple-300:hover {
  fill: #9575cd !important; }

.str-deep-purple-300, .strH-deep-purple-300:hover {
  stroke: #9575cd !important; }

.c-deep-purple-400, .cH-deep-purple-400:hover {
  color: #7e57c2 !important; }

.bgc-deep-purple-400, .bgcH-deep-purple-400:hover {
  background-color: #7e57c2 !important; }

.bdc-deep-purple-400, .bdcH-deep-purple-400:hover {
  border-color: #7e57c2 !important; }

.fill-deep-purple-400, .fillH-deep-purple-400:hover {
  fill: #7e57c2 !important; }

.str-deep-purple-400, .strH-deep-purple-400:hover {
  stroke: #7e57c2 !important; }

.c-deep-purple-500, .cH-deep-purple-500:hover {
  color: #673ab7 !important; }

.bgc-deep-purple-500, .bgcH-deep-purple-500:hover {
  background-color: #673ab7 !important; }

.bdc-deep-purple-500, .bdcH-deep-purple-500:hover {
  border-color: #673ab7 !important; }

.fill-deep-purple-500, .fillH-deep-purple-500:hover {
  fill: #673ab7 !important; }

.str-deep-purple-500, .strH-deep-purple-500:hover {
  stroke: #673ab7 !important; }

.c-deep-purple-600, .cH-deep-purple-600:hover {
  color: #5e35b1 !important; }

.bgc-deep-purple-600, .bgcH-deep-purple-600:hover {
  background-color: #5e35b1 !important; }

.bdc-deep-purple-600, .bdcH-deep-purple-600:hover {
  border-color: #5e35b1 !important; }

.fill-deep-purple-600, .fillH-deep-purple-600:hover {
  fill: #5e35b1 !important; }

.str-deep-purple-600, .strH-deep-purple-600:hover {
  stroke: #5e35b1 !important; }

.c-deep-purple-700, .cH-deep-purple-700:hover {
  color: #512da8 !important; }

.bgc-deep-purple-700, .bgcH-deep-purple-700:hover {
  background-color: #512da8 !important; }

.bdc-deep-purple-700, .bdcH-deep-purple-700:hover {
  border-color: #512da8 !important; }

.fill-deep-purple-700, .fillH-deep-purple-700:hover {
  fill: #512da8 !important; }

.str-deep-purple-700, .strH-deep-purple-700:hover {
  stroke: #512da8 !important; }

.c-deep-purple-800, .cH-deep-purple-800:hover {
  color: #4527a0 !important; }

.bgc-deep-purple-800, .bgcH-deep-purple-800:hover {
  background-color: #4527a0 !important; }

.bdc-deep-purple-800, .bdcH-deep-purple-800:hover {
  border-color: #4527a0 !important; }

.fill-deep-purple-800, .fillH-deep-purple-800:hover {
  fill: #4527a0 !important; }

.str-deep-purple-800, .strH-deep-purple-800:hover {
  stroke: #4527a0 !important; }

.c-deep-purple-900, .cH-deep-purple-900:hover {
  color: #311b92 !important; }

.bgc-deep-purple-900, .bgcH-deep-purple-900:hover {
  background-color: #311b92 !important; }

.bdc-deep-purple-900, .bdcH-deep-purple-900:hover {
  border-color: #311b92 !important; }

.fill-deep-purple-900, .fillH-deep-purple-900:hover {
  fill: #311b92 !important; }

.str-deep-purple-900, .strH-deep-purple-900:hover {
  stroke: #311b92 !important; }

.c-deep-purple-a100, .cH-deep-purple-a100:hover {
  color: #b388ff !important; }

.bgc-deep-purple-a100, .bgcH-deep-purple-a100:hover {
  background-color: #b388ff !important; }

.bdc-deep-purple-a100, .bdcH-deep-purple-a100:hover {
  border-color: #b388ff !important; }

.fill-deep-purple-a100, .fillH-deep-purple-a100:hover {
  fill: #b388ff !important; }

.str-deep-purple-a100, .strH-deep-purple-a100:hover {
  stroke: #b388ff !important; }

.c-deep-purple-a200, .cH-deep-purple-a200:hover {
  color: #7c4dff !important; }

.bgc-deep-purple-a200, .bgcH-deep-purple-a200:hover {
  background-color: #7c4dff !important; }

.bdc-deep-purple-a200, .bdcH-deep-purple-a200:hover {
  border-color: #7c4dff !important; }

.fill-deep-purple-a200, .fillH-deep-purple-a200:hover {
  fill: #7c4dff !important; }

.str-deep-purple-a200, .strH-deep-purple-a200:hover {
  stroke: #7c4dff !important; }

.c-deep-purple-a400, .cH-deep-purple-a400:hover {
  color: #651fff !important; }

.bgc-deep-purple-a400, .bgcH-deep-purple-a400:hover {
  background-color: #651fff !important; }

.bdc-deep-purple-a400, .bdcH-deep-purple-a400:hover {
  border-color: #651fff !important; }

.fill-deep-purple-a400, .fillH-deep-purple-a400:hover {
  fill: #651fff !important; }

.str-deep-purple-a400, .strH-deep-purple-a400:hover {
  stroke: #651fff !important; }

.c-deep-purple-a700, .cH-deep-purple-a700:hover {
  color: #6200ea !important; }

.bgc-deep-purple-a700, .bgcH-deep-purple-a700:hover {
  background-color: #6200ea !important; }

.bdc-deep-purple-a700, .bdcH-deep-purple-a700:hover {
  border-color: #6200ea !important; }

.fill-deep-purple-a700, .fillH-deep-purple-a700:hover {
  fill: #6200ea !important; }

.str-deep-purple-a700, .strH-deep-purple-a700:hover {
  stroke: #6200ea !important; }

.c-indigo-50, .cH-indigo-50:hover {
  color: #e8eaf6 !important; }

.bgc-indigo-50, .bgcH-indigo-50:hover {
  background-color: #e8eaf6 !important; }

.bdc-indigo-50, .bdcH-indigo-50:hover {
  border-color: #e8eaf6 !important; }

.fill-indigo-50, .fillH-indigo-50:hover {
  fill: #e8eaf6 !important; }

.str-indigo-50, .strH-indigo-50:hover {
  stroke: #e8eaf6 !important; }

.c-indigo-100, .cH-indigo-100:hover {
  color: #c5cae9 !important; }

.bgc-indigo-100, .bgcH-indigo-100:hover {
  background-color: #c5cae9 !important; }

.bdc-indigo-100, .bdcH-indigo-100:hover {
  border-color: #c5cae9 !important; }

.fill-indigo-100, .fillH-indigo-100:hover {
  fill: #c5cae9 !important; }

.str-indigo-100, .strH-indigo-100:hover {
  stroke: #c5cae9 !important; }

.c-indigo-200, .cH-indigo-200:hover {
  color: #9fa8da !important; }

.bgc-indigo-200, .bgcH-indigo-200:hover {
  background-color: #9fa8da !important; }

.bdc-indigo-200, .bdcH-indigo-200:hover {
  border-color: #9fa8da !important; }

.fill-indigo-200, .fillH-indigo-200:hover {
  fill: #9fa8da !important; }

.str-indigo-200, .strH-indigo-200:hover {
  stroke: #9fa8da !important; }

.c-indigo-300, .cH-indigo-300:hover {
  color: #7986cb !important; }

.bgc-indigo-300, .bgcH-indigo-300:hover {
  background-color: #7986cb !important; }

.bdc-indigo-300, .bdcH-indigo-300:hover {
  border-color: #7986cb !important; }

.fill-indigo-300, .fillH-indigo-300:hover {
  fill: #7986cb !important; }

.str-indigo-300, .strH-indigo-300:hover {
  stroke: #7986cb !important; }

.c-indigo-400, .cH-indigo-400:hover {
  color: #5c6bc0 !important; }

.bgc-indigo-400, .bgcH-indigo-400:hover {
  background-color: #5c6bc0 !important; }

.bdc-indigo-400, .bdcH-indigo-400:hover {
  border-color: #5c6bc0 !important; }

.fill-indigo-400, .fillH-indigo-400:hover {
  fill: #5c6bc0 !important; }

.str-indigo-400, .strH-indigo-400:hover {
  stroke: #5c6bc0 !important; }

.c-indigo-500, .cH-indigo-500:hover {
  color: #3f51b5 !important; }

.bgc-indigo-500, .bgcH-indigo-500:hover {
  background-color: #3f51b5 !important; }

.bdc-indigo-500, .bdcH-indigo-500:hover {
  border-color: #3f51b5 !important; }

.fill-indigo-500, .fillH-indigo-500:hover {
  fill: #3f51b5 !important; }

.str-indigo-500, .strH-indigo-500:hover {
  stroke: #3f51b5 !important; }

.c-indigo-600, .cH-indigo-600:hover {
  color: #3949ab !important; }

.bgc-indigo-600, .bgcH-indigo-600:hover {
  background-color: #3949ab !important; }

.bdc-indigo-600, .bdcH-indigo-600:hover {
  border-color: #3949ab !important; }

.fill-indigo-600, .fillH-indigo-600:hover {
  fill: #3949ab !important; }

.str-indigo-600, .strH-indigo-600:hover {
  stroke: #3949ab !important; }

.c-indigo-700, .cH-indigo-700:hover {
  color: #303f9f !important; }

.bgc-indigo-700, .bgcH-indigo-700:hover {
  background-color: #303f9f !important; }

.bdc-indigo-700, .bdcH-indigo-700:hover {
  border-color: #303f9f !important; }

.fill-indigo-700, .fillH-indigo-700:hover {
  fill: #303f9f !important; }

.str-indigo-700, .strH-indigo-700:hover {
  stroke: #303f9f !important; }

.c-indigo-800, .cH-indigo-800:hover {
  color: #283593 !important; }

.bgc-indigo-800, .bgcH-indigo-800:hover {
  background-color: #283593 !important; }

.bdc-indigo-800, .bdcH-indigo-800:hover {
  border-color: #283593 !important; }

.fill-indigo-800, .fillH-indigo-800:hover {
  fill: #283593 !important; }

.str-indigo-800, .strH-indigo-800:hover {
  stroke: #283593 !important; }

.c-indigo-900, .cH-indigo-900:hover {
  color: #1a237e !important; }

.bgc-indigo-900, .bgcH-indigo-900:hover {
  background-color: #1a237e !important; }

.bdc-indigo-900, .bdcH-indigo-900:hover {
  border-color: #1a237e !important; }

.fill-indigo-900, .fillH-indigo-900:hover {
  fill: #1a237e !important; }

.str-indigo-900, .strH-indigo-900:hover {
  stroke: #1a237e !important; }

.c-indigo-a100, .cH-indigo-a100:hover {
  color: #8c9eff !important; }

.bgc-indigo-a100, .bgcH-indigo-a100:hover {
  background-color: #8c9eff !important; }

.bdc-indigo-a100, .bdcH-indigo-a100:hover {
  border-color: #8c9eff !important; }

.fill-indigo-a100, .fillH-indigo-a100:hover {
  fill: #8c9eff !important; }

.str-indigo-a100, .strH-indigo-a100:hover {
  stroke: #8c9eff !important; }

.c-indigo-a200, .cH-indigo-a200:hover {
  color: #536dfe !important; }

.bgc-indigo-a200, .bgcH-indigo-a200:hover {
  background-color: #536dfe !important; }

.bdc-indigo-a200, .bdcH-indigo-a200:hover {
  border-color: #536dfe !important; }

.fill-indigo-a200, .fillH-indigo-a200:hover {
  fill: #536dfe !important; }

.str-indigo-a200, .strH-indigo-a200:hover {
  stroke: #536dfe !important; }

.c-indigo-a400, .cH-indigo-a400:hover {
  color: #3d5afe !important; }

.bgc-indigo-a400, .bgcH-indigo-a400:hover {
  background-color: #3d5afe !important; }

.bdc-indigo-a400, .bdcH-indigo-a400:hover {
  border-color: #3d5afe !important; }

.fill-indigo-a400, .fillH-indigo-a400:hover {
  fill: #3d5afe !important; }

.str-indigo-a400, .strH-indigo-a400:hover {
  stroke: #3d5afe !important; }

.c-indigo-a700, .cH-indigo-a700:hover {
  color: #304ffe !important; }

.bgc-indigo-a700, .bgcH-indigo-a700:hover {
  background-color: #304ffe !important; }

.bdc-indigo-a700, .bdcH-indigo-a700:hover {
  border-color: #304ffe !important; }

.fill-indigo-a700, .fillH-indigo-a700:hover {
  fill: #304ffe !important; }

.str-indigo-a700, .strH-indigo-a700:hover {
  stroke: #304ffe !important; }

.c-blue-50, .cH-blue-50:hover {
  color: #e3f2fd !important; }

.bgc-blue-50, .bgcH-blue-50:hover {
  background-color: #e3f2fd !important; }

.bdc-blue-50, .bdcH-blue-50:hover {
  border-color: #e3f2fd !important; }

.fill-blue-50, .fillH-blue-50:hover {
  fill: #e3f2fd !important; }

.str-blue-50, .strH-blue-50:hover {
  stroke: #e3f2fd !important; }

.c-blue-100, .cH-blue-100:hover {
  color: #bbdefb !important; }

.bgc-blue-100, .bgcH-blue-100:hover {
  background-color: #bbdefb !important; }

.bdc-blue-100, .bdcH-blue-100:hover {
  border-color: #bbdefb !important; }

.fill-blue-100, .fillH-blue-100:hover {
  fill: #bbdefb !important; }

.str-blue-100, .strH-blue-100:hover {
  stroke: #bbdefb !important; }

.c-blue-200, .cH-blue-200:hover {
  color: #90caf9 !important; }

.bgc-blue-200, .bgcH-blue-200:hover {
  background-color: #90caf9 !important; }

.bdc-blue-200, .bdcH-blue-200:hover {
  border-color: #90caf9 !important; }

.fill-blue-200, .fillH-blue-200:hover {
  fill: #90caf9 !important; }

.str-blue-200, .strH-blue-200:hover {
  stroke: #90caf9 !important; }

.c-blue-300, .cH-blue-300:hover {
  color: #64b5f6 !important; }

.bgc-blue-300, .bgcH-blue-300:hover {
  background-color: #64b5f6 !important; }

.bdc-blue-300, .bdcH-blue-300:hover {
  border-color: #64b5f6 !important; }

.fill-blue-300, .fillH-blue-300:hover {
  fill: #64b5f6 !important; }

.str-blue-300, .strH-blue-300:hover {
  stroke: #64b5f6 !important; }

.c-blue-400, .cH-blue-400:hover {
  color: #42a5f5 !important; }

.bgc-blue-400, .bgcH-blue-400:hover {
  background-color: #42a5f5 !important; }

.bdc-blue-400, .bdcH-blue-400:hover {
  border-color: #42a5f5 !important; }

.fill-blue-400, .fillH-blue-400:hover {
  fill: #42a5f5 !important; }

.str-blue-400, .strH-blue-400:hover {
  stroke: #42a5f5 !important; }

.c-blue-500, .cH-blue-500:hover {
  color: #2196f3 !important; }

.bgc-blue-500, .bgcH-blue-500:hover {
  background-color: #2196f3 !important; }

.bdc-blue-500, .bdcH-blue-500:hover {
  border-color: #2196f3 !important; }

.fill-blue-500, .fillH-blue-500:hover {
  fill: #2196f3 !important; }

.str-blue-500, .strH-blue-500:hover {
  stroke: #2196f3 !important; }

.c-blue-600, .cH-blue-600:hover {
  color: #1e88e5 !important; }

.bgc-blue-600, .bgcH-blue-600:hover {
  background-color: #1e88e5 !important; }

.bdc-blue-600, .bdcH-blue-600:hover {
  border-color: #1e88e5 !important; }

.fill-blue-600, .fillH-blue-600:hover {
  fill: #1e88e5 !important; }

.str-blue-600, .strH-blue-600:hover {
  stroke: #1e88e5 !important; }

.c-blue-700, .cH-blue-700:hover {
  color: #1976d2 !important; }

.bgc-blue-700, .bgcH-blue-700:hover {
  background-color: #1976d2 !important; }

.bdc-blue-700, .bdcH-blue-700:hover {
  border-color: #1976d2 !important; }

.fill-blue-700, .fillH-blue-700:hover {
  fill: #1976d2 !important; }

.str-blue-700, .strH-blue-700:hover {
  stroke: #1976d2 !important; }

.c-blue-800, .cH-blue-800:hover {
  color: #1565c0 !important; }

.bgc-blue-800, .bgcH-blue-800:hover {
  background-color: #1565c0 !important; }

.bdc-blue-800, .bdcH-blue-800:hover {
  border-color: #1565c0 !important; }

.fill-blue-800, .fillH-blue-800:hover {
  fill: #1565c0 !important; }

.str-blue-800, .strH-blue-800:hover {
  stroke: #1565c0 !important; }

.c-blue-900, .cH-blue-900:hover {
  color: #0d47a1 !important; }

.bgc-blue-900, .bgcH-blue-900:hover {
  background-color: #0d47a1 !important; }

.bdc-blue-900, .bdcH-blue-900:hover {
  border-color: #0d47a1 !important; }

.fill-blue-900, .fillH-blue-900:hover {
  fill: #0d47a1 !important; }

.str-blue-900, .strH-blue-900:hover {
  stroke: #0d47a1 !important; }

.c-blue-a100, .cH-blue-a100:hover {
  color: #82b1ff !important; }

.bgc-blue-a100, .bgcH-blue-a100:hover {
  background-color: #82b1ff !important; }

.bdc-blue-a100, .bdcH-blue-a100:hover {
  border-color: #82b1ff !important; }

.fill-blue-a100, .fillH-blue-a100:hover {
  fill: #82b1ff !important; }

.str-blue-a100, .strH-blue-a100:hover {
  stroke: #82b1ff !important; }

.c-blue-a200, .cH-blue-a200:hover {
  color: #448aff !important; }

.bgc-blue-a200, .bgcH-blue-a200:hover {
  background-color: #448aff !important; }

.bdc-blue-a200, .bdcH-blue-a200:hover {
  border-color: #448aff !important; }

.fill-blue-a200, .fillH-blue-a200:hover {
  fill: #448aff !important; }

.str-blue-a200, .strH-blue-a200:hover {
  stroke: #448aff !important; }

.c-blue-a400, .cH-blue-a400:hover {
  color: #2979ff !important; }

.bgc-blue-a400, .bgcH-blue-a400:hover {
  background-color: #2979ff !important; }

.bdc-blue-a400, .bdcH-blue-a400:hover {
  border-color: #2979ff !important; }

.fill-blue-a400, .fillH-blue-a400:hover {
  fill: #2979ff !important; }

.str-blue-a400, .strH-blue-a400:hover {
  stroke: #2979ff !important; }

.c-blue-a700, .cH-blue-a700:hover {
  color: #2962ff !important; }

.bgc-blue-a700, .bgcH-blue-a700:hover {
  background-color: #2962ff !important; }

.bdc-blue-a700, .bdcH-blue-a700:hover {
  border-color: #2962ff !important; }

.fill-blue-a700, .fillH-blue-a700:hover {
  fill: #2962ff !important; }

.str-blue-a700, .strH-blue-a700:hover {
  stroke: #2962ff !important; }

.c-light-blue-50, .cH-light-blue-50:hover {
  color: #e1f5fe !important; }

.bgc-light-blue-50, .bgcH-light-blue-50:hover {
  background-color: #e1f5fe !important; }

.bdc-light-blue-50, .bdcH-light-blue-50:hover {
  border-color: #e1f5fe !important; }

.fill-light-blue-50, .fillH-light-blue-50:hover {
  fill: #e1f5fe !important; }

.str-light-blue-50, .strH-light-blue-50:hover {
  stroke: #e1f5fe !important; }

.c-light-blue-100, .cH-light-blue-100:hover {
  color: #b3e5fc !important; }

.bgc-light-blue-100, .bgcH-light-blue-100:hover {
  background-color: #b3e5fc !important; }

.bdc-light-blue-100, .bdcH-light-blue-100:hover {
  border-color: #b3e5fc !important; }

.fill-light-blue-100, .fillH-light-blue-100:hover {
  fill: #b3e5fc !important; }

.str-light-blue-100, .strH-light-blue-100:hover {
  stroke: #b3e5fc !important; }

.c-light-blue-200, .cH-light-blue-200:hover {
  color: #81d4fa !important; }

.bgc-light-blue-200, .bgcH-light-blue-200:hover {
  background-color: #81d4fa !important; }

.bdc-light-blue-200, .bdcH-light-blue-200:hover {
  border-color: #81d4fa !important; }

.fill-light-blue-200, .fillH-light-blue-200:hover {
  fill: #81d4fa !important; }

.str-light-blue-200, .strH-light-blue-200:hover {
  stroke: #81d4fa !important; }

.c-light-blue-300, .cH-light-blue-300:hover {
  color: #4fc3f7 !important; }

.bgc-light-blue-300, .bgcH-light-blue-300:hover {
  background-color: #4fc3f7 !important; }

.bdc-light-blue-300, .bdcH-light-blue-300:hover {
  border-color: #4fc3f7 !important; }

.fill-light-blue-300, .fillH-light-blue-300:hover {
  fill: #4fc3f7 !important; }

.str-light-blue-300, .strH-light-blue-300:hover {
  stroke: #4fc3f7 !important; }

.c-light-blue-400, .cH-light-blue-400:hover {
  color: #29b6f6 !important; }

.bgc-light-blue-400, .bgcH-light-blue-400:hover {
  background-color: #29b6f6 !important; }

.bdc-light-blue-400, .bdcH-light-blue-400:hover {
  border-color: #29b6f6 !important; }

.fill-light-blue-400, .fillH-light-blue-400:hover {
  fill: #29b6f6 !important; }

.str-light-blue-400, .strH-light-blue-400:hover {
  stroke: #29b6f6 !important; }

.c-light-blue-500, .cH-light-blue-500:hover {
  color: #03a9f4 !important; }

.bgc-light-blue-500, .bgcH-light-blue-500:hover {
  background-color: #03a9f4 !important; }

.bdc-light-blue-500, .bdcH-light-blue-500:hover {
  border-color: #03a9f4 !important; }

.fill-light-blue-500, .fillH-light-blue-500:hover {
  fill: #03a9f4 !important; }

.str-light-blue-500, .strH-light-blue-500:hover {
  stroke: #03a9f4 !important; }

.c-light-blue-600, .cH-light-blue-600:hover {
  color: #039be5 !important; }

.bgc-light-blue-600, .bgcH-light-blue-600:hover {
  background-color: #039be5 !important; }

.bdc-light-blue-600, .bdcH-light-blue-600:hover {
  border-color: #039be5 !important; }

.fill-light-blue-600, .fillH-light-blue-600:hover {
  fill: #039be5 !important; }

.str-light-blue-600, .strH-light-blue-600:hover {
  stroke: #039be5 !important; }

.c-light-blue-700, .cH-light-blue-700:hover {
  color: #0288d1 !important; }

.bgc-light-blue-700, .bgcH-light-blue-700:hover {
  background-color: #0288d1 !important; }

.bdc-light-blue-700, .bdcH-light-blue-700:hover {
  border-color: #0288d1 !important; }

.fill-light-blue-700, .fillH-light-blue-700:hover {
  fill: #0288d1 !important; }

.str-light-blue-700, .strH-light-blue-700:hover {
  stroke: #0288d1 !important; }

.c-light-blue-800, .cH-light-blue-800:hover {
  color: #0277bd !important; }

.bgc-light-blue-800, .bgcH-light-blue-800:hover {
  background-color: #0277bd !important; }

.bdc-light-blue-800, .bdcH-light-blue-800:hover {
  border-color: #0277bd !important; }

.fill-light-blue-800, .fillH-light-blue-800:hover {
  fill: #0277bd !important; }

.str-light-blue-800, .strH-light-blue-800:hover {
  stroke: #0277bd !important; }

.c-light-blue-900, .cH-light-blue-900:hover {
  color: #01579b !important; }

.bgc-light-blue-900, .bgcH-light-blue-900:hover {
  background-color: #01579b !important; }

.bdc-light-blue-900, .bdcH-light-blue-900:hover {
  border-color: #01579b !important; }

.fill-light-blue-900, .fillH-light-blue-900:hover {
  fill: #01579b !important; }

.str-light-blue-900, .strH-light-blue-900:hover {
  stroke: #01579b !important; }

.c-light-blue-a100, .cH-light-blue-a100:hover {
  color: #80d8ff !important; }

.bgc-light-blue-a100, .bgcH-light-blue-a100:hover {
  background-color: #80d8ff !important; }

.bdc-light-blue-a100, .bdcH-light-blue-a100:hover {
  border-color: #80d8ff !important; }

.fill-light-blue-a100, .fillH-light-blue-a100:hover {
  fill: #80d8ff !important; }

.str-light-blue-a100, .strH-light-blue-a100:hover {
  stroke: #80d8ff !important; }

.c-light-blue-a200, .cH-light-blue-a200:hover {
  color: #40c4ff !important; }

.bgc-light-blue-a200, .bgcH-light-blue-a200:hover {
  background-color: #40c4ff !important; }

.bdc-light-blue-a200, .bdcH-light-blue-a200:hover {
  border-color: #40c4ff !important; }

.fill-light-blue-a200, .fillH-light-blue-a200:hover {
  fill: #40c4ff !important; }

.str-light-blue-a200, .strH-light-blue-a200:hover {
  stroke: #40c4ff !important; }

.c-light-blue-a400, .cH-light-blue-a400:hover {
  color: #00b0ff !important; }

.bgc-light-blue-a400, .bgcH-light-blue-a400:hover {
  background-color: #00b0ff !important; }

.bdc-light-blue-a400, .bdcH-light-blue-a400:hover {
  border-color: #00b0ff !important; }

.fill-light-blue-a400, .fillH-light-blue-a400:hover {
  fill: #00b0ff !important; }

.str-light-blue-a400, .strH-light-blue-a400:hover {
  stroke: #00b0ff !important; }

.c-light-blue-a700, .cH-light-blue-a700:hover {
  color: #0091ea !important; }

.bgc-light-blue-a700, .bgcH-light-blue-a700:hover {
  background-color: #0091ea !important; }

.bdc-light-blue-a700, .bdcH-light-blue-a700:hover {
  border-color: #0091ea !important; }

.fill-light-blue-a700, .fillH-light-blue-a700:hover {
  fill: #0091ea !important; }

.str-light-blue-a700, .strH-light-blue-a700:hover {
  stroke: #0091ea !important; }

.c-cyan-50, .cH-cyan-50:hover {
  color: #e0f7fa !important; }

.bgc-cyan-50, .bgcH-cyan-50:hover {
  background-color: #e0f7fa !important; }

.bdc-cyan-50, .bdcH-cyan-50:hover {
  border-color: #e0f7fa !important; }

.fill-cyan-50, .fillH-cyan-50:hover {
  fill: #e0f7fa !important; }

.str-cyan-50, .strH-cyan-50:hover {
  stroke: #e0f7fa !important; }

.c-cyan-100, .cH-cyan-100:hover {
  color: #b2ebf2 !important; }

.bgc-cyan-100, .bgcH-cyan-100:hover {
  background-color: #b2ebf2 !important; }

.bdc-cyan-100, .bdcH-cyan-100:hover {
  border-color: #b2ebf2 !important; }

.fill-cyan-100, .fillH-cyan-100:hover {
  fill: #b2ebf2 !important; }

.str-cyan-100, .strH-cyan-100:hover {
  stroke: #b2ebf2 !important; }

.c-cyan-200, .cH-cyan-200:hover {
  color: #80deea !important; }

.bgc-cyan-200, .bgcH-cyan-200:hover {
  background-color: #80deea !important; }

.bdc-cyan-200, .bdcH-cyan-200:hover {
  border-color: #80deea !important; }

.fill-cyan-200, .fillH-cyan-200:hover {
  fill: #80deea !important; }

.str-cyan-200, .strH-cyan-200:hover {
  stroke: #80deea !important; }

.c-cyan-300, .cH-cyan-300:hover {
  color: #4dd0e1 !important; }

.bgc-cyan-300, .bgcH-cyan-300:hover {
  background-color: #4dd0e1 !important; }

.bdc-cyan-300, .bdcH-cyan-300:hover {
  border-color: #4dd0e1 !important; }

.fill-cyan-300, .fillH-cyan-300:hover {
  fill: #4dd0e1 !important; }

.str-cyan-300, .strH-cyan-300:hover {
  stroke: #4dd0e1 !important; }

.c-cyan-400, .cH-cyan-400:hover {
  color: #26c6da !important; }

.bgc-cyan-400, .bgcH-cyan-400:hover {
  background-color: #26c6da !important; }

.bdc-cyan-400, .bdcH-cyan-400:hover {
  border-color: #26c6da !important; }

.fill-cyan-400, .fillH-cyan-400:hover {
  fill: #26c6da !important; }

.str-cyan-400, .strH-cyan-400:hover {
  stroke: #26c6da !important; }

.c-cyan-500, .cH-cyan-500:hover {
  color: #00bcd4 !important; }

.bgc-cyan-500, .bgcH-cyan-500:hover {
  background-color: #00bcd4 !important; }

.bdc-cyan-500, .bdcH-cyan-500:hover {
  border-color: #00bcd4 !important; }

.fill-cyan-500, .fillH-cyan-500:hover {
  fill: #00bcd4 !important; }

.str-cyan-500, .strH-cyan-500:hover {
  stroke: #00bcd4 !important; }

.c-cyan-600, .cH-cyan-600:hover {
  color: #00acc1 !important; }

.bgc-cyan-600, .bgcH-cyan-600:hover {
  background-color: #00acc1 !important; }

.bdc-cyan-600, .bdcH-cyan-600:hover {
  border-color: #00acc1 !important; }

.fill-cyan-600, .fillH-cyan-600:hover {
  fill: #00acc1 !important; }

.str-cyan-600, .strH-cyan-600:hover {
  stroke: #00acc1 !important; }

.c-cyan-700, .cH-cyan-700:hover {
  color: #0097a7 !important; }

.bgc-cyan-700, .bgcH-cyan-700:hover {
  background-color: #0097a7 !important; }

.bdc-cyan-700, .bdcH-cyan-700:hover {
  border-color: #0097a7 !important; }

.fill-cyan-700, .fillH-cyan-700:hover {
  fill: #0097a7 !important; }

.str-cyan-700, .strH-cyan-700:hover {
  stroke: #0097a7 !important; }

.c-cyan-800, .cH-cyan-800:hover {
  color: #00838f !important; }

.bgc-cyan-800, .bgcH-cyan-800:hover {
  background-color: #00838f !important; }

.bdc-cyan-800, .bdcH-cyan-800:hover {
  border-color: #00838f !important; }

.fill-cyan-800, .fillH-cyan-800:hover {
  fill: #00838f !important; }

.str-cyan-800, .strH-cyan-800:hover {
  stroke: #00838f !important; }

.c-cyan-900, .cH-cyan-900:hover {
  color: #006064 !important; }

.bgc-cyan-900, .bgcH-cyan-900:hover {
  background-color: #006064 !important; }

.bdc-cyan-900, .bdcH-cyan-900:hover {
  border-color: #006064 !important; }

.fill-cyan-900, .fillH-cyan-900:hover {
  fill: #006064 !important; }

.str-cyan-900, .strH-cyan-900:hover {
  stroke: #006064 !important; }

.c-cyan-a100, .cH-cyan-a100:hover {
  color: #84ffff !important; }

.bgc-cyan-a100, .bgcH-cyan-a100:hover {
  background-color: #84ffff !important; }

.bdc-cyan-a100, .bdcH-cyan-a100:hover {
  border-color: #84ffff !important; }

.fill-cyan-a100, .fillH-cyan-a100:hover {
  fill: #84ffff !important; }

.str-cyan-a100, .strH-cyan-a100:hover {
  stroke: #84ffff !important; }

.c-cyan-a200, .cH-cyan-a200:hover {
  color: #18ffff !important; }

.bgc-cyan-a200, .bgcH-cyan-a200:hover {
  background-color: #18ffff !important; }

.bdc-cyan-a200, .bdcH-cyan-a200:hover {
  border-color: #18ffff !important; }

.fill-cyan-a200, .fillH-cyan-a200:hover {
  fill: #18ffff !important; }

.str-cyan-a200, .strH-cyan-a200:hover {
  stroke: #18ffff !important; }

.c-cyan-a400, .cH-cyan-a400:hover {
  color: #00e5ff !important; }

.bgc-cyan-a400, .bgcH-cyan-a400:hover {
  background-color: #00e5ff !important; }

.bdc-cyan-a400, .bdcH-cyan-a400:hover {
  border-color: #00e5ff !important; }

.fill-cyan-a400, .fillH-cyan-a400:hover {
  fill: #00e5ff !important; }

.str-cyan-a400, .strH-cyan-a400:hover {
  stroke: #00e5ff !important; }

.c-cyan-a700, .cH-cyan-a700:hover {
  color: #00b8d4 !important; }

.bgc-cyan-a700, .bgcH-cyan-a700:hover {
  background-color: #00b8d4 !important; }

.bdc-cyan-a700, .bdcH-cyan-a700:hover {
  border-color: #00b8d4 !important; }

.fill-cyan-a700, .fillH-cyan-a700:hover {
  fill: #00b8d4 !important; }

.str-cyan-a700, .strH-cyan-a700:hover {
  stroke: #00b8d4 !important; }

.c-teal-50, .cH-teal-50:hover {
  color: #e0f2f1 !important; }

.bgc-teal-50, .bgcH-teal-50:hover {
  background-color: #e0f2f1 !important; }

.bdc-teal-50, .bdcH-teal-50:hover {
  border-color: #e0f2f1 !important; }

.fill-teal-50, .fillH-teal-50:hover {
  fill: #e0f2f1 !important; }

.str-teal-50, .strH-teal-50:hover {
  stroke: #e0f2f1 !important; }

.c-teal-100, .cH-teal-100:hover {
  color: #b2dfdb !important; }

.bgc-teal-100, .bgcH-teal-100:hover {
  background-color: #b2dfdb !important; }

.bdc-teal-100, .bdcH-teal-100:hover {
  border-color: #b2dfdb !important; }

.fill-teal-100, .fillH-teal-100:hover {
  fill: #b2dfdb !important; }

.str-teal-100, .strH-teal-100:hover {
  stroke: #b2dfdb !important; }

.c-teal-200, .cH-teal-200:hover {
  color: #80cbc4 !important; }

.bgc-teal-200, .bgcH-teal-200:hover {
  background-color: #80cbc4 !important; }

.bdc-teal-200, .bdcH-teal-200:hover {
  border-color: #80cbc4 !important; }

.fill-teal-200, .fillH-teal-200:hover {
  fill: #80cbc4 !important; }

.str-teal-200, .strH-teal-200:hover {
  stroke: #80cbc4 !important; }

.c-teal-300, .cH-teal-300:hover {
  color: #4db6ac !important; }

.bgc-teal-300, .bgcH-teal-300:hover {
  background-color: #4db6ac !important; }

.bdc-teal-300, .bdcH-teal-300:hover {
  border-color: #4db6ac !important; }

.fill-teal-300, .fillH-teal-300:hover {
  fill: #4db6ac !important; }

.str-teal-300, .strH-teal-300:hover {
  stroke: #4db6ac !important; }

.c-teal-400, .cH-teal-400:hover {
  color: #26a69a !important; }

.bgc-teal-400, .bgcH-teal-400:hover {
  background-color: #26a69a !important; }

.bdc-teal-400, .bdcH-teal-400:hover {
  border-color: #26a69a !important; }

.fill-teal-400, .fillH-teal-400:hover {
  fill: #26a69a !important; }

.str-teal-400, .strH-teal-400:hover {
  stroke: #26a69a !important; }

.c-teal-500, .cH-teal-500:hover {
  color: #009688 !important; }

.bgc-teal-500, .bgcH-teal-500:hover {
  background-color: #009688 !important; }

.bdc-teal-500, .bdcH-teal-500:hover {
  border-color: #009688 !important; }

.fill-teal-500, .fillH-teal-500:hover {
  fill: #009688 !important; }

.str-teal-500, .strH-teal-500:hover {
  stroke: #009688 !important; }

.c-teal-600, .cH-teal-600:hover {
  color: #00897b !important; }

.bgc-teal-600, .bgcH-teal-600:hover {
  background-color: #00897b !important; }

.bdc-teal-600, .bdcH-teal-600:hover {
  border-color: #00897b !important; }

.fill-teal-600, .fillH-teal-600:hover {
  fill: #00897b !important; }

.str-teal-600, .strH-teal-600:hover {
  stroke: #00897b !important; }

.c-teal-700, .cH-teal-700:hover {
  color: #00796b !important; }

.bgc-teal-700, .bgcH-teal-700:hover {
  background-color: #00796b !important; }

.bdc-teal-700, .bdcH-teal-700:hover {
  border-color: #00796b !important; }

.fill-teal-700, .fillH-teal-700:hover {
  fill: #00796b !important; }

.str-teal-700, .strH-teal-700:hover {
  stroke: #00796b !important; }

.c-teal-800, .cH-teal-800:hover {
  color: #00695c !important; }

.bgc-teal-800, .bgcH-teal-800:hover {
  background-color: #00695c !important; }

.bdc-teal-800, .bdcH-teal-800:hover {
  border-color: #00695c !important; }

.fill-teal-800, .fillH-teal-800:hover {
  fill: #00695c !important; }

.str-teal-800, .strH-teal-800:hover {
  stroke: #00695c !important; }

.c-teal-900, .cH-teal-900:hover {
  color: #004d40 !important; }

.bgc-teal-900, .bgcH-teal-900:hover {
  background-color: #004d40 !important; }

.bdc-teal-900, .bdcH-teal-900:hover {
  border-color: #004d40 !important; }

.fill-teal-900, .fillH-teal-900:hover {
  fill: #004d40 !important; }

.str-teal-900, .strH-teal-900:hover {
  stroke: #004d40 !important; }

.c-teal-a100, .cH-teal-a100:hover {
  color: #a7ffeb !important; }

.bgc-teal-a100, .bgcH-teal-a100:hover {
  background-color: #a7ffeb !important; }

.bdc-teal-a100, .bdcH-teal-a100:hover {
  border-color: #a7ffeb !important; }

.fill-teal-a100, .fillH-teal-a100:hover {
  fill: #a7ffeb !important; }

.str-teal-a100, .strH-teal-a100:hover {
  stroke: #a7ffeb !important; }

.c-teal-a200, .cH-teal-a200:hover {
  color: #64ffda !important; }

.bgc-teal-a200, .bgcH-teal-a200:hover {
  background-color: #64ffda !important; }

.bdc-teal-a200, .bdcH-teal-a200:hover {
  border-color: #64ffda !important; }

.fill-teal-a200, .fillH-teal-a200:hover {
  fill: #64ffda !important; }

.str-teal-a200, .strH-teal-a200:hover {
  stroke: #64ffda !important; }

.c-teal-a400, .cH-teal-a400:hover {
  color: #1de9b6 !important; }

.bgc-teal-a400, .bgcH-teal-a400:hover {
  background-color: #1de9b6 !important; }

.bdc-teal-a400, .bdcH-teal-a400:hover {
  border-color: #1de9b6 !important; }

.fill-teal-a400, .fillH-teal-a400:hover {
  fill: #1de9b6 !important; }

.str-teal-a400, .strH-teal-a400:hover {
  stroke: #1de9b6 !important; }

.c-teal-a700, .cH-teal-a700:hover {
  color: #00bfa5 !important; }

.bgc-teal-a700, .bgcH-teal-a700:hover {
  background-color: #00bfa5 !important; }

.bdc-teal-a700, .bdcH-teal-a700:hover {
  border-color: #00bfa5 !important; }

.fill-teal-a700, .fillH-teal-a700:hover {
  fill: #00bfa5 !important; }

.str-teal-a700, .strH-teal-a700:hover {
  stroke: #00bfa5 !important; }

.c-green-50, .cH-green-50:hover {
  color: #e8f5e9 !important; }

.bgc-green-50, .bgcH-green-50:hover {
  background-color: #e8f5e9 !important; }

.bdc-green-50, .bdcH-green-50:hover {
  border-color: #e8f5e9 !important; }

.fill-green-50, .fillH-green-50:hover {
  fill: #e8f5e9 !important; }

.str-green-50, .strH-green-50:hover {
  stroke: #e8f5e9 !important; }

.c-green-100, .cH-green-100:hover {
  color: #c8e6c9 !important; }

.bgc-green-100, .bgcH-green-100:hover {
  background-color: #c8e6c9 !important; }

.bdc-green-100, .bdcH-green-100:hover {
  border-color: #c8e6c9 !important; }

.fill-green-100, .fillH-green-100:hover {
  fill: #c8e6c9 !important; }

.str-green-100, .strH-green-100:hover {
  stroke: #c8e6c9 !important; }

.c-green-200, .cH-green-200:hover {
  color: #a5d6a7 !important; }

.bgc-green-200, .bgcH-green-200:hover {
  background-color: #a5d6a7 !important; }

.bdc-green-200, .bdcH-green-200:hover {
  border-color: #a5d6a7 !important; }

.fill-green-200, .fillH-green-200:hover {
  fill: #a5d6a7 !important; }

.str-green-200, .strH-green-200:hover {
  stroke: #a5d6a7 !important; }

.c-green-300, .cH-green-300:hover {
  color: #81c784 !important; }

.bgc-green-300, .bgcH-green-300:hover {
  background-color: #81c784 !important; }

.bdc-green-300, .bdcH-green-300:hover {
  border-color: #81c784 !important; }

.fill-green-300, .fillH-green-300:hover {
  fill: #81c784 !important; }

.str-green-300, .strH-green-300:hover {
  stroke: #81c784 !important; }

.c-green-400, .cH-green-400:hover {
  color: #66bb6a !important; }

.bgc-green-400, .bgcH-green-400:hover {
  background-color: #66bb6a !important; }

.bdc-green-400, .bdcH-green-400:hover {
  border-color: #66bb6a !important; }

.fill-green-400, .fillH-green-400:hover {
  fill: #66bb6a !important; }

.str-green-400, .strH-green-400:hover {
  stroke: #66bb6a !important; }

.c-green-500, .cH-green-500:hover {
  color: #4caf50 !important; }

.bgc-green-500, .bgcH-green-500:hover {
  background-color: #4caf50 !important; }

.bdc-green-500, .bdcH-green-500:hover {
  border-color: #4caf50 !important; }

.fill-green-500, .fillH-green-500:hover {
  fill: #4caf50 !important; }

.str-green-500, .strH-green-500:hover {
  stroke: #4caf50 !important; }

.c-green-600, .cH-green-600:hover {
  color: #43a047 !important; }

.bgc-green-600, .bgcH-green-600:hover {
  background-color: #43a047 !important; }

.bdc-green-600, .bdcH-green-600:hover {
  border-color: #43a047 !important; }

.fill-green-600, .fillH-green-600:hover {
  fill: #43a047 !important; }

.str-green-600, .strH-green-600:hover {
  stroke: #43a047 !important; }

.c-green-700, .cH-green-700:hover {
  color: #388e3c !important; }

.bgc-green-700, .bgcH-green-700:hover {
  background-color: #388e3c !important; }

.bdc-green-700, .bdcH-green-700:hover {
  border-color: #388e3c !important; }

.fill-green-700, .fillH-green-700:hover {
  fill: #388e3c !important; }

.str-green-700, .strH-green-700:hover {
  stroke: #388e3c !important; }

.c-green-800, .cH-green-800:hover {
  color: #2e7d32 !important; }

.bgc-green-800, .bgcH-green-800:hover {
  background-color: #2e7d32 !important; }

.bdc-green-800, .bdcH-green-800:hover {
  border-color: #2e7d32 !important; }

.fill-green-800, .fillH-green-800:hover {
  fill: #2e7d32 !important; }

.str-green-800, .strH-green-800:hover {
  stroke: #2e7d32 !important; }

.c-green-900, .cH-green-900:hover {
  color: #1b5e20 !important; }

.bgc-green-900, .bgcH-green-900:hover {
  background-color: #1b5e20 !important; }

.bdc-green-900, .bdcH-green-900:hover {
  border-color: #1b5e20 !important; }

.fill-green-900, .fillH-green-900:hover {
  fill: #1b5e20 !important; }

.str-green-900, .strH-green-900:hover {
  stroke: #1b5e20 !important; }

.c-green-a100, .cH-green-a100:hover {
  color: #b9f6ca !important; }

.bgc-green-a100, .bgcH-green-a100:hover {
  background-color: #b9f6ca !important; }

.bdc-green-a100, .bdcH-green-a100:hover {
  border-color: #b9f6ca !important; }

.fill-green-a100, .fillH-green-a100:hover {
  fill: #b9f6ca !important; }

.str-green-a100, .strH-green-a100:hover {
  stroke: #b9f6ca !important; }

.c-green-a200, .cH-green-a200:hover {
  color: #69f0ae !important; }

.bgc-green-a200, .bgcH-green-a200:hover {
  background-color: #69f0ae !important; }

.bdc-green-a200, .bdcH-green-a200:hover {
  border-color: #69f0ae !important; }

.fill-green-a200, .fillH-green-a200:hover {
  fill: #69f0ae !important; }

.str-green-a200, .strH-green-a200:hover {
  stroke: #69f0ae !important; }

.c-green-a400, .cH-green-a400:hover {
  color: #00e676 !important; }

.bgc-green-a400, .bgcH-green-a400:hover {
  background-color: #00e676 !important; }

.bdc-green-a400, .bdcH-green-a400:hover {
  border-color: #00e676 !important; }

.fill-green-a400, .fillH-green-a400:hover {
  fill: #00e676 !important; }

.str-green-a400, .strH-green-a400:hover {
  stroke: #00e676 !important; }

.c-green-a700, .cH-green-a700:hover {
  color: #00c853 !important; }

.bgc-green-a700, .bgcH-green-a700:hover {
  background-color: #00c853 !important; }

.bdc-green-a700, .bdcH-green-a700:hover {
  border-color: #00c853 !important; }

.fill-green-a700, .fillH-green-a700:hover {
  fill: #00c853 !important; }

.str-green-a700, .strH-green-a700:hover {
  stroke: #00c853 !important; }

.c-light-green-50, .cH-light-green-50:hover {
  color: #f1f8e9 !important; }

.bgc-light-green-50, .bgcH-light-green-50:hover {
  background-color: #f1f8e9 !important; }

.bdc-light-green-50, .bdcH-light-green-50:hover {
  border-color: #f1f8e9 !important; }

.fill-light-green-50, .fillH-light-green-50:hover {
  fill: #f1f8e9 !important; }

.str-light-green-50, .strH-light-green-50:hover {
  stroke: #f1f8e9 !important; }

.c-light-green-100, .cH-light-green-100:hover {
  color: #dcedc8 !important; }

.bgc-light-green-100, .bgcH-light-green-100:hover {
  background-color: #dcedc8 !important; }

.bdc-light-green-100, .bdcH-light-green-100:hover {
  border-color: #dcedc8 !important; }

.fill-light-green-100, .fillH-light-green-100:hover {
  fill: #dcedc8 !important; }

.str-light-green-100, .strH-light-green-100:hover {
  stroke: #dcedc8 !important; }

.c-light-green-200, .cH-light-green-200:hover {
  color: #c5e1a5 !important; }

.bgc-light-green-200, .bgcH-light-green-200:hover {
  background-color: #c5e1a5 !important; }

.bdc-light-green-200, .bdcH-light-green-200:hover {
  border-color: #c5e1a5 !important; }

.fill-light-green-200, .fillH-light-green-200:hover {
  fill: #c5e1a5 !important; }

.str-light-green-200, .strH-light-green-200:hover {
  stroke: #c5e1a5 !important; }

.c-light-green-300, .cH-light-green-300:hover {
  color: #aed581 !important; }

.bgc-light-green-300, .bgcH-light-green-300:hover {
  background-color: #aed581 !important; }

.bdc-light-green-300, .bdcH-light-green-300:hover {
  border-color: #aed581 !important; }

.fill-light-green-300, .fillH-light-green-300:hover {
  fill: #aed581 !important; }

.str-light-green-300, .strH-light-green-300:hover {
  stroke: #aed581 !important; }

.c-light-green-400, .cH-light-green-400:hover {
  color: #9ccc65 !important; }

.bgc-light-green-400, .bgcH-light-green-400:hover {
  background-color: #9ccc65 !important; }

.bdc-light-green-400, .bdcH-light-green-400:hover {
  border-color: #9ccc65 !important; }

.fill-light-green-400, .fillH-light-green-400:hover {
  fill: #9ccc65 !important; }

.str-light-green-400, .strH-light-green-400:hover {
  stroke: #9ccc65 !important; }

.c-light-green-500, .cH-light-green-500:hover {
  color: #8bc34a !important; }

.bgc-light-green-500, .bgcH-light-green-500:hover {
  background-color: #8bc34a !important; }

.bdc-light-green-500, .bdcH-light-green-500:hover {
  border-color: #8bc34a !important; }

.fill-light-green-500, .fillH-light-green-500:hover {
  fill: #8bc34a !important; }

.str-light-green-500, .strH-light-green-500:hover {
  stroke: #8bc34a !important; }

.c-light-green-600, .cH-light-green-600:hover {
  color: #7cb342 !important; }

.bgc-light-green-600, .bgcH-light-green-600:hover {
  background-color: #7cb342 !important; }

.bdc-light-green-600, .bdcH-light-green-600:hover {
  border-color: #7cb342 !important; }

.fill-light-green-600, .fillH-light-green-600:hover {
  fill: #7cb342 !important; }

.str-light-green-600, .strH-light-green-600:hover {
  stroke: #7cb342 !important; }

.c-light-green-700, .cH-light-green-700:hover {
  color: #689f38 !important; }

.bgc-light-green-700, .bgcH-light-green-700:hover {
  background-color: #689f38 !important; }

.bdc-light-green-700, .bdcH-light-green-700:hover {
  border-color: #689f38 !important; }

.fill-light-green-700, .fillH-light-green-700:hover {
  fill: #689f38 !important; }

.str-light-green-700, .strH-light-green-700:hover {
  stroke: #689f38 !important; }

.c-light-green-800, .cH-light-green-800:hover {
  color: #558b2f !important; }

.bgc-light-green-800, .bgcH-light-green-800:hover {
  background-color: #558b2f !important; }

.bdc-light-green-800, .bdcH-light-green-800:hover {
  border-color: #558b2f !important; }

.fill-light-green-800, .fillH-light-green-800:hover {
  fill: #558b2f !important; }

.str-light-green-800, .strH-light-green-800:hover {
  stroke: #558b2f !important; }

.c-light-green-900, .cH-light-green-900:hover {
  color: #33691e !important; }

.bgc-light-green-900, .bgcH-light-green-900:hover {
  background-color: #33691e !important; }

.bdc-light-green-900, .bdcH-light-green-900:hover {
  border-color: #33691e !important; }

.fill-light-green-900, .fillH-light-green-900:hover {
  fill: #33691e !important; }

.str-light-green-900, .strH-light-green-900:hover {
  stroke: #33691e !important; }

.c-light-green-a100, .cH-light-green-a100:hover {
  color: #ccff90 !important; }

.bgc-light-green-a100, .bgcH-light-green-a100:hover {
  background-color: #ccff90 !important; }

.bdc-light-green-a100, .bdcH-light-green-a100:hover {
  border-color: #ccff90 !important; }

.fill-light-green-a100, .fillH-light-green-a100:hover {
  fill: #ccff90 !important; }

.str-light-green-a100, .strH-light-green-a100:hover {
  stroke: #ccff90 !important; }

.c-light-green-a200, .cH-light-green-a200:hover {
  color: #b2ff59 !important; }

.bgc-light-green-a200, .bgcH-light-green-a200:hover {
  background-color: #b2ff59 !important; }

.bdc-light-green-a200, .bdcH-light-green-a200:hover {
  border-color: #b2ff59 !important; }

.fill-light-green-a200, .fillH-light-green-a200:hover {
  fill: #b2ff59 !important; }

.str-light-green-a200, .strH-light-green-a200:hover {
  stroke: #b2ff59 !important; }

.c-light-green-a400, .cH-light-green-a400:hover {
  color: #76ff03 !important; }

.bgc-light-green-a400, .bgcH-light-green-a400:hover {
  background-color: #76ff03 !important; }

.bdc-light-green-a400, .bdcH-light-green-a400:hover {
  border-color: #76ff03 !important; }

.fill-light-green-a400, .fillH-light-green-a400:hover {
  fill: #76ff03 !important; }

.str-light-green-a400, .strH-light-green-a400:hover {
  stroke: #76ff03 !important; }

.c-light-green-a700, .cH-light-green-a700:hover {
  color: #64dd17 !important; }

.bgc-light-green-a700, .bgcH-light-green-a700:hover {
  background-color: #64dd17 !important; }

.bdc-light-green-a700, .bdcH-light-green-a700:hover {
  border-color: #64dd17 !important; }

.fill-light-green-a700, .fillH-light-green-a700:hover {
  fill: #64dd17 !important; }

.str-light-green-a700, .strH-light-green-a700:hover {
  stroke: #64dd17 !important; }

.c-lime-50, .cH-lime-50:hover {
  color: #f9fbe7 !important; }

.bgc-lime-50, .bgcH-lime-50:hover {
  background-color: #f9fbe7 !important; }

.bdc-lime-50, .bdcH-lime-50:hover {
  border-color: #f9fbe7 !important; }

.fill-lime-50, .fillH-lime-50:hover {
  fill: #f9fbe7 !important; }

.str-lime-50, .strH-lime-50:hover {
  stroke: #f9fbe7 !important; }

.c-lime-100, .cH-lime-100:hover {
  color: #f0f4c3 !important; }

.bgc-lime-100, .bgcH-lime-100:hover {
  background-color: #f0f4c3 !important; }

.bdc-lime-100, .bdcH-lime-100:hover {
  border-color: #f0f4c3 !important; }

.fill-lime-100, .fillH-lime-100:hover {
  fill: #f0f4c3 !important; }

.str-lime-100, .strH-lime-100:hover {
  stroke: #f0f4c3 !important; }

.c-lime-200, .cH-lime-200:hover {
  color: #e6ee9c !important; }

.bgc-lime-200, .bgcH-lime-200:hover {
  background-color: #e6ee9c !important; }

.bdc-lime-200, .bdcH-lime-200:hover {
  border-color: #e6ee9c !important; }

.fill-lime-200, .fillH-lime-200:hover {
  fill: #e6ee9c !important; }

.str-lime-200, .strH-lime-200:hover {
  stroke: #e6ee9c !important; }

.c-lime-300, .cH-lime-300:hover {
  color: #dce775 !important; }

.bgc-lime-300, .bgcH-lime-300:hover {
  background-color: #dce775 !important; }

.bdc-lime-300, .bdcH-lime-300:hover {
  border-color: #dce775 !important; }

.fill-lime-300, .fillH-lime-300:hover {
  fill: #dce775 !important; }

.str-lime-300, .strH-lime-300:hover {
  stroke: #dce775 !important; }

.c-lime-400, .cH-lime-400:hover {
  color: #d4e157 !important; }

.bgc-lime-400, .bgcH-lime-400:hover {
  background-color: #d4e157 !important; }

.bdc-lime-400, .bdcH-lime-400:hover {
  border-color: #d4e157 !important; }

.fill-lime-400, .fillH-lime-400:hover {
  fill: #d4e157 !important; }

.str-lime-400, .strH-lime-400:hover {
  stroke: #d4e157 !important; }

.c-lime-500, .cH-lime-500:hover {
  color: #cddc39 !important; }

.bgc-lime-500, .bgcH-lime-500:hover {
  background-color: #cddc39 !important; }

.bdc-lime-500, .bdcH-lime-500:hover {
  border-color: #cddc39 !important; }

.fill-lime-500, .fillH-lime-500:hover {
  fill: #cddc39 !important; }

.str-lime-500, .strH-lime-500:hover {
  stroke: #cddc39 !important; }

.c-lime-600, .cH-lime-600:hover {
  color: #c0ca33 !important; }

.bgc-lime-600, .bgcH-lime-600:hover {
  background-color: #c0ca33 !important; }

.bdc-lime-600, .bdcH-lime-600:hover {
  border-color: #c0ca33 !important; }

.fill-lime-600, .fillH-lime-600:hover {
  fill: #c0ca33 !important; }

.str-lime-600, .strH-lime-600:hover {
  stroke: #c0ca33 !important; }

.c-lime-700, .cH-lime-700:hover {
  color: #afb42b !important; }

.bgc-lime-700, .bgcH-lime-700:hover {
  background-color: #afb42b !important; }

.bdc-lime-700, .bdcH-lime-700:hover {
  border-color: #afb42b !important; }

.fill-lime-700, .fillH-lime-700:hover {
  fill: #afb42b !important; }

.str-lime-700, .strH-lime-700:hover {
  stroke: #afb42b !important; }

.c-lime-800, .cH-lime-800:hover {
  color: #9e9d24 !important; }

.bgc-lime-800, .bgcH-lime-800:hover {
  background-color: #9e9d24 !important; }

.bdc-lime-800, .bdcH-lime-800:hover {
  border-color: #9e9d24 !important; }

.fill-lime-800, .fillH-lime-800:hover {
  fill: #9e9d24 !important; }

.str-lime-800, .strH-lime-800:hover {
  stroke: #9e9d24 !important; }

.c-lime-900, .cH-lime-900:hover {
  color: #827717 !important; }

.bgc-lime-900, .bgcH-lime-900:hover {
  background-color: #827717 !important; }

.bdc-lime-900, .bdcH-lime-900:hover {
  border-color: #827717 !important; }

.fill-lime-900, .fillH-lime-900:hover {
  fill: #827717 !important; }

.str-lime-900, .strH-lime-900:hover {
  stroke: #827717 !important; }

.c-lime-a100, .cH-lime-a100:hover {
  color: #f4ff81 !important; }

.bgc-lime-a100, .bgcH-lime-a100:hover {
  background-color: #f4ff81 !important; }

.bdc-lime-a100, .bdcH-lime-a100:hover {
  border-color: #f4ff81 !important; }

.fill-lime-a100, .fillH-lime-a100:hover {
  fill: #f4ff81 !important; }

.str-lime-a100, .strH-lime-a100:hover {
  stroke: #f4ff81 !important; }

.c-lime-a200, .cH-lime-a200:hover {
  color: #eeff41 !important; }

.bgc-lime-a200, .bgcH-lime-a200:hover {
  background-color: #eeff41 !important; }

.bdc-lime-a200, .bdcH-lime-a200:hover {
  border-color: #eeff41 !important; }

.fill-lime-a200, .fillH-lime-a200:hover {
  fill: #eeff41 !important; }

.str-lime-a200, .strH-lime-a200:hover {
  stroke: #eeff41 !important; }

.c-lime-a400, .cH-lime-a400:hover {
  color: #c6ff00 !important; }

.bgc-lime-a400, .bgcH-lime-a400:hover {
  background-color: #c6ff00 !important; }

.bdc-lime-a400, .bdcH-lime-a400:hover {
  border-color: #c6ff00 !important; }

.fill-lime-a400, .fillH-lime-a400:hover {
  fill: #c6ff00 !important; }

.str-lime-a400, .strH-lime-a400:hover {
  stroke: #c6ff00 !important; }

.c-lime-a700, .cH-lime-a700:hover {
  color: #aeea00 !important; }

.bgc-lime-a700, .bgcH-lime-a700:hover {
  background-color: #aeea00 !important; }

.bdc-lime-a700, .bdcH-lime-a700:hover {
  border-color: #aeea00 !important; }

.fill-lime-a700, .fillH-lime-a700:hover {
  fill: #aeea00 !important; }

.str-lime-a700, .strH-lime-a700:hover {
  stroke: #aeea00 !important; }

.c-yellow-50, .cH-yellow-50:hover {
  color: #fffde7 !important; }

.bgc-yellow-50, .bgcH-yellow-50:hover {
  background-color: #fffde7 !important; }

.bdc-yellow-50, .bdcH-yellow-50:hover {
  border-color: #fffde7 !important; }

.fill-yellow-50, .fillH-yellow-50:hover {
  fill: #fffde7 !important; }

.str-yellow-50, .strH-yellow-50:hover {
  stroke: #fffde7 !important; }

.c-yellow-100, .cH-yellow-100:hover {
  color: #fff9c4 !important; }

.bgc-yellow-100, .bgcH-yellow-100:hover {
  background-color: #fff9c4 !important; }

.bdc-yellow-100, .bdcH-yellow-100:hover {
  border-color: #fff9c4 !important; }

.fill-yellow-100, .fillH-yellow-100:hover {
  fill: #fff9c4 !important; }

.str-yellow-100, .strH-yellow-100:hover {
  stroke: #fff9c4 !important; }

.c-yellow-200, .cH-yellow-200:hover {
  color: #fff59d !important; }

.bgc-yellow-200, .bgcH-yellow-200:hover {
  background-color: #fff59d !important; }

.bdc-yellow-200, .bdcH-yellow-200:hover {
  border-color: #fff59d !important; }

.fill-yellow-200, .fillH-yellow-200:hover {
  fill: #fff59d !important; }

.str-yellow-200, .strH-yellow-200:hover {
  stroke: #fff59d !important; }

.c-yellow-300, .cH-yellow-300:hover {
  color: #fff176 !important; }

.bgc-yellow-300, .bgcH-yellow-300:hover {
  background-color: #fff176 !important; }

.bdc-yellow-300, .bdcH-yellow-300:hover {
  border-color: #fff176 !important; }

.fill-yellow-300, .fillH-yellow-300:hover {
  fill: #fff176 !important; }

.str-yellow-300, .strH-yellow-300:hover {
  stroke: #fff176 !important; }

.c-yellow-400, .cH-yellow-400:hover {
  color: #ffee58 !important; }

.bgc-yellow-400, .bgcH-yellow-400:hover {
  background-color: #ffee58 !important; }

.bdc-yellow-400, .bdcH-yellow-400:hover {
  border-color: #ffee58 !important; }

.fill-yellow-400, .fillH-yellow-400:hover {
  fill: #ffee58 !important; }

.str-yellow-400, .strH-yellow-400:hover {
  stroke: #ffee58 !important; }

.c-yellow-500, .cH-yellow-500:hover {
  color: #ffeb3b !important; }

.bgc-yellow-500, .bgcH-yellow-500:hover {
  background-color: #ffeb3b !important; }

.bdc-yellow-500, .bdcH-yellow-500:hover {
  border-color: #ffeb3b !important; }

.fill-yellow-500, .fillH-yellow-500:hover {
  fill: #ffeb3b !important; }

.str-yellow-500, .strH-yellow-500:hover {
  stroke: #ffeb3b !important; }

.c-yellow-600, .cH-yellow-600:hover {
  color: #fdd835 !important; }

.bgc-yellow-600, .bgcH-yellow-600:hover {
  background-color: #fdd835 !important; }

.bdc-yellow-600, .bdcH-yellow-600:hover {
  border-color: #fdd835 !important; }

.fill-yellow-600, .fillH-yellow-600:hover {
  fill: #fdd835 !important; }

.str-yellow-600, .strH-yellow-600:hover {
  stroke: #fdd835 !important; }

.c-yellow-700, .cH-yellow-700:hover {
  color: #fbc02d !important; }

.bgc-yellow-700, .bgcH-yellow-700:hover {
  background-color: #fbc02d !important; }

.bdc-yellow-700, .bdcH-yellow-700:hover {
  border-color: #fbc02d !important; }

.fill-yellow-700, .fillH-yellow-700:hover {
  fill: #fbc02d !important; }

.str-yellow-700, .strH-yellow-700:hover {
  stroke: #fbc02d !important; }

.c-yellow-800, .cH-yellow-800:hover {
  color: #f9a825 !important; }

.bgc-yellow-800, .bgcH-yellow-800:hover {
  background-color: #f9a825 !important; }

.bdc-yellow-800, .bdcH-yellow-800:hover {
  border-color: #f9a825 !important; }

.fill-yellow-800, .fillH-yellow-800:hover {
  fill: #f9a825 !important; }

.str-yellow-800, .strH-yellow-800:hover {
  stroke: #f9a825 !important; }

.c-yellow-900, .cH-yellow-900:hover {
  color: #f57f17 !important; }

.bgc-yellow-900, .bgcH-yellow-900:hover {
  background-color: #f57f17 !important; }

.bdc-yellow-900, .bdcH-yellow-900:hover {
  border-color: #f57f17 !important; }

.fill-yellow-900, .fillH-yellow-900:hover {
  fill: #f57f17 !important; }

.str-yellow-900, .strH-yellow-900:hover {
  stroke: #f57f17 !important; }

.c-yellow-a100, .cH-yellow-a100:hover {
  color: #ffff8d !important; }

.bgc-yellow-a100, .bgcH-yellow-a100:hover {
  background-color: #ffff8d !important; }

.bdc-yellow-a100, .bdcH-yellow-a100:hover {
  border-color: #ffff8d !important; }

.fill-yellow-a100, .fillH-yellow-a100:hover {
  fill: #ffff8d !important; }

.str-yellow-a100, .strH-yellow-a100:hover {
  stroke: #ffff8d !important; }

.c-yellow-a200, .cH-yellow-a200:hover {
  color: #ffff00 !important; }

.bgc-yellow-a200, .bgcH-yellow-a200:hover {
  background-color: #ffff00 !important; }

.bdc-yellow-a200, .bdcH-yellow-a200:hover {
  border-color: #ffff00 !important; }

.fill-yellow-a200, .fillH-yellow-a200:hover {
  fill: #ffff00 !important; }

.str-yellow-a200, .strH-yellow-a200:hover {
  stroke: #ffff00 !important; }

.c-yellow-a400, .cH-yellow-a400:hover {
  color: #ffea00 !important; }

.bgc-yellow-a400, .bgcH-yellow-a400:hover {
  background-color: #ffea00 !important; }

.bdc-yellow-a400, .bdcH-yellow-a400:hover {
  border-color: #ffea00 !important; }

.fill-yellow-a400, .fillH-yellow-a400:hover {
  fill: #ffea00 !important; }

.str-yellow-a400, .strH-yellow-a400:hover {
  stroke: #ffea00 !important; }

.c-yellow-a700, .cH-yellow-a700:hover {
  color: #ffd600 !important; }

.bgc-yellow-a700, .bgcH-yellow-a700:hover {
  background-color: #ffd600 !important; }

.bdc-yellow-a700, .bdcH-yellow-a700:hover {
  border-color: #ffd600 !important; }

.fill-yellow-a700, .fillH-yellow-a700:hover {
  fill: #ffd600 !important; }

.str-yellow-a700, .strH-yellow-a700:hover {
  stroke: #ffd600 !important; }

.c-amber-50, .cH-amber-50:hover {
  color: #fff8e1 !important; }

.bgc-amber-50, .bgcH-amber-50:hover {
  background-color: #fff8e1 !important; }

.bdc-amber-50, .bdcH-amber-50:hover {
  border-color: #fff8e1 !important; }

.fill-amber-50, .fillH-amber-50:hover {
  fill: #fff8e1 !important; }

.str-amber-50, .strH-amber-50:hover {
  stroke: #fff8e1 !important; }

.c-amber-100, .cH-amber-100:hover {
  color: #ffecb3 !important; }

.bgc-amber-100, .bgcH-amber-100:hover {
  background-color: #ffecb3 !important; }

.bdc-amber-100, .bdcH-amber-100:hover {
  border-color: #ffecb3 !important; }

.fill-amber-100, .fillH-amber-100:hover {
  fill: #ffecb3 !important; }

.str-amber-100, .strH-amber-100:hover {
  stroke: #ffecb3 !important; }

.c-amber-200, .cH-amber-200:hover {
  color: #ffe082 !important; }

.bgc-amber-200, .bgcH-amber-200:hover {
  background-color: #ffe082 !important; }

.bdc-amber-200, .bdcH-amber-200:hover {
  border-color: #ffe082 !important; }

.fill-amber-200, .fillH-amber-200:hover {
  fill: #ffe082 !important; }

.str-amber-200, .strH-amber-200:hover {
  stroke: #ffe082 !important; }

.c-amber-300, .cH-amber-300:hover {
  color: #ffd54f !important; }

.bgc-amber-300, .bgcH-amber-300:hover {
  background-color: #ffd54f !important; }

.bdc-amber-300, .bdcH-amber-300:hover {
  border-color: #ffd54f !important; }

.fill-amber-300, .fillH-amber-300:hover {
  fill: #ffd54f !important; }

.str-amber-300, .strH-amber-300:hover {
  stroke: #ffd54f !important; }

.c-amber-400, .cH-amber-400:hover {
  color: #ffca28 !important; }

.bgc-amber-400, .bgcH-amber-400:hover {
  background-color: #ffca28 !important; }

.bdc-amber-400, .bdcH-amber-400:hover {
  border-color: #ffca28 !important; }

.fill-amber-400, .fillH-amber-400:hover {
  fill: #ffca28 !important; }

.str-amber-400, .strH-amber-400:hover {
  stroke: #ffca28 !important; }

.c-amber-500, .cH-amber-500:hover {
  color: #ffc107 !important; }

.bgc-amber-500, .bgcH-amber-500:hover {
  background-color: #ffc107 !important; }

.bdc-amber-500, .bdcH-amber-500:hover {
  border-color: #ffc107 !important; }

.fill-amber-500, .fillH-amber-500:hover {
  fill: #ffc107 !important; }

.str-amber-500, .strH-amber-500:hover {
  stroke: #ffc107 !important; }

.c-amber-600, .cH-amber-600:hover {
  color: #ffb300 !important; }

.bgc-amber-600, .bgcH-amber-600:hover {
  background-color: #ffb300 !important; }

.bdc-amber-600, .bdcH-amber-600:hover {
  border-color: #ffb300 !important; }

.fill-amber-600, .fillH-amber-600:hover {
  fill: #ffb300 !important; }

.str-amber-600, .strH-amber-600:hover {
  stroke: #ffb300 !important; }

.c-amber-700, .cH-amber-700:hover {
  color: #ffa000 !important; }

.bgc-amber-700, .bgcH-amber-700:hover {
  background-color: #ffa000 !important; }

.bdc-amber-700, .bdcH-amber-700:hover {
  border-color: #ffa000 !important; }

.fill-amber-700, .fillH-amber-700:hover {
  fill: #ffa000 !important; }

.str-amber-700, .strH-amber-700:hover {
  stroke: #ffa000 !important; }

.c-amber-800, .cH-amber-800:hover {
  color: #ff8f00 !important; }

.bgc-amber-800, .bgcH-amber-800:hover {
  background-color: #ff8f00 !important; }

.bdc-amber-800, .bdcH-amber-800:hover {
  border-color: #ff8f00 !important; }

.fill-amber-800, .fillH-amber-800:hover {
  fill: #ff8f00 !important; }

.str-amber-800, .strH-amber-800:hover {
  stroke: #ff8f00 !important; }

.c-amber-900, .cH-amber-900:hover {
  color: #ff6f00 !important; }

.bgc-amber-900, .bgcH-amber-900:hover {
  background-color: #ff6f00 !important; }

.bdc-amber-900, .bdcH-amber-900:hover {
  border-color: #ff6f00 !important; }

.fill-amber-900, .fillH-amber-900:hover {
  fill: #ff6f00 !important; }

.str-amber-900, .strH-amber-900:hover {
  stroke: #ff6f00 !important; }

.c-amber-a100, .cH-amber-a100:hover {
  color: #ffe57f !important; }

.bgc-amber-a100, .bgcH-amber-a100:hover {
  background-color: #ffe57f !important; }

.bdc-amber-a100, .bdcH-amber-a100:hover {
  border-color: #ffe57f !important; }

.fill-amber-a100, .fillH-amber-a100:hover {
  fill: #ffe57f !important; }

.str-amber-a100, .strH-amber-a100:hover {
  stroke: #ffe57f !important; }

.c-amber-a200, .cH-amber-a200:hover {
  color: #ffd740 !important; }

.bgc-amber-a200, .bgcH-amber-a200:hover {
  background-color: #ffd740 !important; }

.bdc-amber-a200, .bdcH-amber-a200:hover {
  border-color: #ffd740 !important; }

.fill-amber-a200, .fillH-amber-a200:hover {
  fill: #ffd740 !important; }

.str-amber-a200, .strH-amber-a200:hover {
  stroke: #ffd740 !important; }

.c-amber-a400, .cH-amber-a400:hover {
  color: #ffc400 !important; }

.bgc-amber-a400, .bgcH-amber-a400:hover {
  background-color: #ffc400 !important; }

.bdc-amber-a400, .bdcH-amber-a400:hover {
  border-color: #ffc400 !important; }

.fill-amber-a400, .fillH-amber-a400:hover {
  fill: #ffc400 !important; }

.str-amber-a400, .strH-amber-a400:hover {
  stroke: #ffc400 !important; }

.c-amber-a700, .cH-amber-a700:hover {
  color: #ffab00 !important; }

.bgc-amber-a700, .bgcH-amber-a700:hover {
  background-color: #ffab00 !important; }

.bdc-amber-a700, .bdcH-amber-a700:hover {
  border-color: #ffab00 !important; }

.fill-amber-a700, .fillH-amber-a700:hover {
  fill: #ffab00 !important; }

.str-amber-a700, .strH-amber-a700:hover {
  stroke: #ffab00 !important; }

.c-orange-50, .cH-orange-50:hover {
  color: #fff3e0 !important; }

.bgc-orange-50, .bgcH-orange-50:hover {
  background-color: #fff3e0 !important; }

.bdc-orange-50, .bdcH-orange-50:hover {
  border-color: #fff3e0 !important; }

.fill-orange-50, .fillH-orange-50:hover {
  fill: #fff3e0 !important; }

.str-orange-50, .strH-orange-50:hover {
  stroke: #fff3e0 !important; }

.c-orange-100, .cH-orange-100:hover {
  color: #ffe0b2 !important; }

.bgc-orange-100, .bgcH-orange-100:hover {
  background-color: #ffe0b2 !important; }

.bdc-orange-100, .bdcH-orange-100:hover {
  border-color: #ffe0b2 !important; }

.fill-orange-100, .fillH-orange-100:hover {
  fill: #ffe0b2 !important; }

.str-orange-100, .strH-orange-100:hover {
  stroke: #ffe0b2 !important; }

.c-orange-200, .cH-orange-200:hover {
  color: #ffcc80 !important; }

.bgc-orange-200, .bgcH-orange-200:hover {
  background-color: #ffcc80 !important; }

.bdc-orange-200, .bdcH-orange-200:hover {
  border-color: #ffcc80 !important; }

.fill-orange-200, .fillH-orange-200:hover {
  fill: #ffcc80 !important; }

.str-orange-200, .strH-orange-200:hover {
  stroke: #ffcc80 !important; }

.c-orange-300, .cH-orange-300:hover {
  color: #ffb74d !important; }

.bgc-orange-300, .bgcH-orange-300:hover {
  background-color: #ffb74d !important; }

.bdc-orange-300, .bdcH-orange-300:hover {
  border-color: #ffb74d !important; }

.fill-orange-300, .fillH-orange-300:hover {
  fill: #ffb74d !important; }

.str-orange-300, .strH-orange-300:hover {
  stroke: #ffb74d !important; }

.c-orange-400, .cH-orange-400:hover {
  color: #ffa726 !important; }

.bgc-orange-400, .bgcH-orange-400:hover {
  background-color: #ffa726 !important; }

.bdc-orange-400, .bdcH-orange-400:hover {
  border-color: #ffa726 !important; }

.fill-orange-400, .fillH-orange-400:hover {
  fill: #ffa726 !important; }

.str-orange-400, .strH-orange-400:hover {
  stroke: #ffa726 !important; }

.c-orange-500, .cH-orange-500:hover {
  color: #ff9800 !important; }

.bgc-orange-500, .bgcH-orange-500:hover {
  background-color: #ff9800 !important; }

.bdc-orange-500, .bdcH-orange-500:hover {
  border-color: #ff9800 !important; }

.fill-orange-500, .fillH-orange-500:hover {
  fill: #ff9800 !important; }

.str-orange-500, .strH-orange-500:hover {
  stroke: #ff9800 !important; }

.c-orange-600, .cH-orange-600:hover {
  color: #fb8c00 !important; }

.bgc-orange-600, .bgcH-orange-600:hover {
  background-color: #fb8c00 !important; }

.bdc-orange-600, .bdcH-orange-600:hover {
  border-color: #fb8c00 !important; }

.fill-orange-600, .fillH-orange-600:hover {
  fill: #fb8c00 !important; }

.str-orange-600, .strH-orange-600:hover {
  stroke: #fb8c00 !important; }

.c-orange-700, .cH-orange-700:hover {
  color: #f57c00 !important; }

.bgc-orange-700, .bgcH-orange-700:hover {
  background-color: #f57c00 !important; }

.bdc-orange-700, .bdcH-orange-700:hover {
  border-color: #f57c00 !important; }

.fill-orange-700, .fillH-orange-700:hover {
  fill: #f57c00 !important; }

.str-orange-700, .strH-orange-700:hover {
  stroke: #f57c00 !important; }

.c-orange-800, .cH-orange-800:hover {
  color: #ef6c00 !important; }

.bgc-orange-800, .bgcH-orange-800:hover {
  background-color: #ef6c00 !important; }

.bdc-orange-800, .bdcH-orange-800:hover {
  border-color: #ef6c00 !important; }

.fill-orange-800, .fillH-orange-800:hover {
  fill: #ef6c00 !important; }

.str-orange-800, .strH-orange-800:hover {
  stroke: #ef6c00 !important; }

.c-orange-900, .cH-orange-900:hover {
  color: #e65100 !important; }

.bgc-orange-900, .bgcH-orange-900:hover {
  background-color: #e65100 !important; }

.bdc-orange-900, .bdcH-orange-900:hover {
  border-color: #e65100 !important; }

.fill-orange-900, .fillH-orange-900:hover {
  fill: #e65100 !important; }

.str-orange-900, .strH-orange-900:hover {
  stroke: #e65100 !important; }

.c-orange-a100, .cH-orange-a100:hover {
  color: #ffd180 !important; }

.bgc-orange-a100, .bgcH-orange-a100:hover {
  background-color: #ffd180 !important; }

.bdc-orange-a100, .bdcH-orange-a100:hover {
  border-color: #ffd180 !important; }

.fill-orange-a100, .fillH-orange-a100:hover {
  fill: #ffd180 !important; }

.str-orange-a100, .strH-orange-a100:hover {
  stroke: #ffd180 !important; }

.c-orange-a200, .cH-orange-a200:hover {
  color: #ffab40 !important; }

.bgc-orange-a200, .bgcH-orange-a200:hover {
  background-color: #ffab40 !important; }

.bdc-orange-a200, .bdcH-orange-a200:hover {
  border-color: #ffab40 !important; }

.fill-orange-a200, .fillH-orange-a200:hover {
  fill: #ffab40 !important; }

.str-orange-a200, .strH-orange-a200:hover {
  stroke: #ffab40 !important; }

.c-orange-a400, .cH-orange-a400:hover {
  color: #ff9100 !important; }

.bgc-orange-a400, .bgcH-orange-a400:hover {
  background-color: #ff9100 !important; }

.bdc-orange-a400, .bdcH-orange-a400:hover {
  border-color: #ff9100 !important; }

.fill-orange-a400, .fillH-orange-a400:hover {
  fill: #ff9100 !important; }

.str-orange-a400, .strH-orange-a400:hover {
  stroke: #ff9100 !important; }

.c-orange-a700, .cH-orange-a700:hover {
  color: #ff6d00 !important; }

.bgc-orange-a700, .bgcH-orange-a700:hover {
  background-color: #ff6d00 !important; }

.bdc-orange-a700, .bdcH-orange-a700:hover {
  border-color: #ff6d00 !important; }

.fill-orange-a700, .fillH-orange-a700:hover {
  fill: #ff6d00 !important; }

.str-orange-a700, .strH-orange-a700:hover {
  stroke: #ff6d00 !important; }

.c-deep-orange-50, .cH-deep-orange-50:hover {
  color: #fbe9e7 !important; }

.bgc-deep-orange-50, .bgcH-deep-orange-50:hover {
  background-color: #fbe9e7 !important; }

.bdc-deep-orange-50, .bdcH-deep-orange-50:hover {
  border-color: #fbe9e7 !important; }

.fill-deep-orange-50, .fillH-deep-orange-50:hover {
  fill: #fbe9e7 !important; }

.str-deep-orange-50, .strH-deep-orange-50:hover {
  stroke: #fbe9e7 !important; }

.c-deep-orange-100, .cH-deep-orange-100:hover {
  color: #ffccbc !important; }

.bgc-deep-orange-100, .bgcH-deep-orange-100:hover {
  background-color: #ffccbc !important; }

.bdc-deep-orange-100, .bdcH-deep-orange-100:hover {
  border-color: #ffccbc !important; }

.fill-deep-orange-100, .fillH-deep-orange-100:hover {
  fill: #ffccbc !important; }

.str-deep-orange-100, .strH-deep-orange-100:hover {
  stroke: #ffccbc !important; }

.c-deep-orange-200, .cH-deep-orange-200:hover {
  color: #ffab91 !important; }

.bgc-deep-orange-200, .bgcH-deep-orange-200:hover {
  background-color: #ffab91 !important; }

.bdc-deep-orange-200, .bdcH-deep-orange-200:hover {
  border-color: #ffab91 !important; }

.fill-deep-orange-200, .fillH-deep-orange-200:hover {
  fill: #ffab91 !important; }

.str-deep-orange-200, .strH-deep-orange-200:hover {
  stroke: #ffab91 !important; }

.c-deep-orange-300, .cH-deep-orange-300:hover {
  color: #ff8a65 !important; }

.bgc-deep-orange-300, .bgcH-deep-orange-300:hover {
  background-color: #ff8a65 !important; }

.bdc-deep-orange-300, .bdcH-deep-orange-300:hover {
  border-color: #ff8a65 !important; }

.fill-deep-orange-300, .fillH-deep-orange-300:hover {
  fill: #ff8a65 !important; }

.str-deep-orange-300, .strH-deep-orange-300:hover {
  stroke: #ff8a65 !important; }

.c-deep-orange-400, .cH-deep-orange-400:hover {
  color: #ff7043 !important; }

.bgc-deep-orange-400, .bgcH-deep-orange-400:hover {
  background-color: #ff7043 !important; }

.bdc-deep-orange-400, .bdcH-deep-orange-400:hover {
  border-color: #ff7043 !important; }

.fill-deep-orange-400, .fillH-deep-orange-400:hover {
  fill: #ff7043 !important; }

.str-deep-orange-400, .strH-deep-orange-400:hover {
  stroke: #ff7043 !important; }

.c-deep-orange-500, .cH-deep-orange-500:hover {
  color: #ff5722 !important; }

.bgc-deep-orange-500, .bgcH-deep-orange-500:hover {
  background-color: #ff5722 !important; }

.bdc-deep-orange-500, .bdcH-deep-orange-500:hover {
  border-color: #ff5722 !important; }

.fill-deep-orange-500, .fillH-deep-orange-500:hover {
  fill: #ff5722 !important; }

.str-deep-orange-500, .strH-deep-orange-500:hover {
  stroke: #ff5722 !important; }

.c-deep-orange-600, .cH-deep-orange-600:hover {
  color: #f4511e !important; }

.bgc-deep-orange-600, .bgcH-deep-orange-600:hover {
  background-color: #f4511e !important; }

.bdc-deep-orange-600, .bdcH-deep-orange-600:hover {
  border-color: #f4511e !important; }

.fill-deep-orange-600, .fillH-deep-orange-600:hover {
  fill: #f4511e !important; }

.str-deep-orange-600, .strH-deep-orange-600:hover {
  stroke: #f4511e !important; }

.c-deep-orange-700, .cH-deep-orange-700:hover {
  color: #e64a19 !important; }

.bgc-deep-orange-700, .bgcH-deep-orange-700:hover {
  background-color: #e64a19 !important; }

.bdc-deep-orange-700, .bdcH-deep-orange-700:hover {
  border-color: #e64a19 !important; }

.fill-deep-orange-700, .fillH-deep-orange-700:hover {
  fill: #e64a19 !important; }

.str-deep-orange-700, .strH-deep-orange-700:hover {
  stroke: #e64a19 !important; }

.c-deep-orange-800, .cH-deep-orange-800:hover {
  color: #d84315 !important; }

.bgc-deep-orange-800, .bgcH-deep-orange-800:hover {
  background-color: #d84315 !important; }

.bdc-deep-orange-800, .bdcH-deep-orange-800:hover {
  border-color: #d84315 !important; }

.fill-deep-orange-800, .fillH-deep-orange-800:hover {
  fill: #d84315 !important; }

.str-deep-orange-800, .strH-deep-orange-800:hover {
  stroke: #d84315 !important; }

.c-deep-orange-900, .cH-deep-orange-900:hover {
  color: #bf360c !important; }

.bgc-deep-orange-900, .bgcH-deep-orange-900:hover {
  background-color: #bf360c !important; }

.bdc-deep-orange-900, .bdcH-deep-orange-900:hover {
  border-color: #bf360c !important; }

.fill-deep-orange-900, .fillH-deep-orange-900:hover {
  fill: #bf360c !important; }

.str-deep-orange-900, .strH-deep-orange-900:hover {
  stroke: #bf360c !important; }

.c-deep-orange-a100, .cH-deep-orange-a100:hover {
  color: #ff9e80 !important; }

.bgc-deep-orange-a100, .bgcH-deep-orange-a100:hover {
  background-color: #ff9e80 !important; }

.bdc-deep-orange-a100, .bdcH-deep-orange-a100:hover {
  border-color: #ff9e80 !important; }

.fill-deep-orange-a100, .fillH-deep-orange-a100:hover {
  fill: #ff9e80 !important; }

.str-deep-orange-a100, .strH-deep-orange-a100:hover {
  stroke: #ff9e80 !important; }

.c-deep-orange-a200, .cH-deep-orange-a200:hover {
  color: #ff6e40 !important; }

.bgc-deep-orange-a200, .bgcH-deep-orange-a200:hover {
  background-color: #ff6e40 !important; }

.bdc-deep-orange-a200, .bdcH-deep-orange-a200:hover {
  border-color: #ff6e40 !important; }

.fill-deep-orange-a200, .fillH-deep-orange-a200:hover {
  fill: #ff6e40 !important; }

.str-deep-orange-a200, .strH-deep-orange-a200:hover {
  stroke: #ff6e40 !important; }

.c-deep-orange-a400, .cH-deep-orange-a400:hover {
  color: #ff3d00 !important; }

.bgc-deep-orange-a400, .bgcH-deep-orange-a400:hover {
  background-color: #ff3d00 !important; }

.bdc-deep-orange-a400, .bdcH-deep-orange-a400:hover {
  border-color: #ff3d00 !important; }

.fill-deep-orange-a400, .fillH-deep-orange-a400:hover {
  fill: #ff3d00 !important; }

.str-deep-orange-a400, .strH-deep-orange-a400:hover {
  stroke: #ff3d00 !important; }

.c-deep-orange-a700, .cH-deep-orange-a700:hover {
  color: #dd2c00 !important; }

.bgc-deep-orange-a700, .bgcH-deep-orange-a700:hover {
  background-color: #dd2c00 !important; }

.bdc-deep-orange-a700, .bdcH-deep-orange-a700:hover {
  border-color: #dd2c00 !important; }

.fill-deep-orange-a700, .fillH-deep-orange-a700:hover {
  fill: #dd2c00 !important; }

.str-deep-orange-a700, .strH-deep-orange-a700:hover {
  stroke: #dd2c00 !important; }

.c-brown-50, .cH-brown-50:hover {
  color: #efebe9 !important; }

.bgc-brown-50, .bgcH-brown-50:hover {
  background-color: #efebe9 !important; }

.bdc-brown-50, .bdcH-brown-50:hover {
  border-color: #efebe9 !important; }

.fill-brown-50, .fillH-brown-50:hover {
  fill: #efebe9 !important; }

.str-brown-50, .strH-brown-50:hover {
  stroke: #efebe9 !important; }

.c-brown-100, .cH-brown-100:hover {
  color: #d7ccc8 !important; }

.bgc-brown-100, .bgcH-brown-100:hover {
  background-color: #d7ccc8 !important; }

.bdc-brown-100, .bdcH-brown-100:hover {
  border-color: #d7ccc8 !important; }

.fill-brown-100, .fillH-brown-100:hover {
  fill: #d7ccc8 !important; }

.str-brown-100, .strH-brown-100:hover {
  stroke: #d7ccc8 !important; }

.c-brown-200, .cH-brown-200:hover {
  color: #bcaaa4 !important; }

.bgc-brown-200, .bgcH-brown-200:hover {
  background-color: #bcaaa4 !important; }

.bdc-brown-200, .bdcH-brown-200:hover {
  border-color: #bcaaa4 !important; }

.fill-brown-200, .fillH-brown-200:hover {
  fill: #bcaaa4 !important; }

.str-brown-200, .strH-brown-200:hover {
  stroke: #bcaaa4 !important; }

.c-brown-300, .cH-brown-300:hover {
  color: #a1887f !important; }

.bgc-brown-300, .bgcH-brown-300:hover {
  background-color: #a1887f !important; }

.bdc-brown-300, .bdcH-brown-300:hover {
  border-color: #a1887f !important; }

.fill-brown-300, .fillH-brown-300:hover {
  fill: #a1887f !important; }

.str-brown-300, .strH-brown-300:hover {
  stroke: #a1887f !important; }

.c-brown-400, .cH-brown-400:hover {
  color: #8d6e63 !important; }

.bgc-brown-400, .bgcH-brown-400:hover {
  background-color: #8d6e63 !important; }

.bdc-brown-400, .bdcH-brown-400:hover {
  border-color: #8d6e63 !important; }

.fill-brown-400, .fillH-brown-400:hover {
  fill: #8d6e63 !important; }

.str-brown-400, .strH-brown-400:hover {
  stroke: #8d6e63 !important; }

.c-brown-500, .cH-brown-500:hover {
  color: #795548 !important; }

.bgc-brown-500, .bgcH-brown-500:hover {
  background-color: #795548 !important; }

.bdc-brown-500, .bdcH-brown-500:hover {
  border-color: #795548 !important; }

.fill-brown-500, .fillH-brown-500:hover {
  fill: #795548 !important; }

.str-brown-500, .strH-brown-500:hover {
  stroke: #795548 !important; }

.c-brown-600, .cH-brown-600:hover {
  color: #6d4c41 !important; }

.bgc-brown-600, .bgcH-brown-600:hover {
  background-color: #6d4c41 !important; }

.bdc-brown-600, .bdcH-brown-600:hover {
  border-color: #6d4c41 !important; }

.fill-brown-600, .fillH-brown-600:hover {
  fill: #6d4c41 !important; }

.str-brown-600, .strH-brown-600:hover {
  stroke: #6d4c41 !important; }

.c-brown-700, .cH-brown-700:hover {
  color: #5d4037 !important; }

.bgc-brown-700, .bgcH-brown-700:hover {
  background-color: #5d4037 !important; }

.bdc-brown-700, .bdcH-brown-700:hover {
  border-color: #5d4037 !important; }

.fill-brown-700, .fillH-brown-700:hover {
  fill: #5d4037 !important; }

.str-brown-700, .strH-brown-700:hover {
  stroke: #5d4037 !important; }

.c-brown-800, .cH-brown-800:hover {
  color: #4e342e !important; }

.bgc-brown-800, .bgcH-brown-800:hover {
  background-color: #4e342e !important; }

.bdc-brown-800, .bdcH-brown-800:hover {
  border-color: #4e342e !important; }

.fill-brown-800, .fillH-brown-800:hover {
  fill: #4e342e !important; }

.str-brown-800, .strH-brown-800:hover {
  stroke: #4e342e !important; }

.c-brown-900, .cH-brown-900:hover {
  color: #3e2723 !important; }

.bgc-brown-900, .bgcH-brown-900:hover {
  background-color: #3e2723 !important; }

.bdc-brown-900, .bdcH-brown-900:hover {
  border-color: #3e2723 !important; }

.fill-brown-900, .fillH-brown-900:hover {
  fill: #3e2723 !important; }

.str-brown-900, .strH-brown-900:hover {
  stroke: #3e2723 !important; }

.c-grey-50, .cH-grey-50:hover {
  color: #fafafa !important; }

.bgc-grey-50, .bgcH-grey-50:hover {
  background-color: #fafafa !important; }

.bdc-grey-50, .bdcH-grey-50:hover {
  border-color: #fafafa !important; }

.fill-grey-50, .fillH-grey-50:hover {
  fill: #fafafa !important; }

.str-grey-50, .strH-grey-50:hover {
  stroke: #fafafa !important; }

.c-grey-100, .cH-grey-100:hover {
  color: #f5f5f5 !important; }

.bgc-grey-100, .bgcH-grey-100:hover {
  background-color: #f5f5f5 !important; }

.bdc-grey-100, .bdcH-grey-100:hover {
  border-color: #f5f5f5 !important; }

.fill-grey-100, .fillH-grey-100:hover {
  fill: #f5f5f5 !important; }

.str-grey-100, .strH-grey-100:hover {
  stroke: #f5f5f5 !important; }

.c-grey-200, .cH-grey-200:hover {
  color: #eeeeee !important; }

.bgc-grey-200, .bgcH-grey-200:hover {
  background-color: #eeeeee !important; }

.bdc-grey-200, .bdcH-grey-200:hover {
  border-color: #eeeeee !important; }

.fill-grey-200, .fillH-grey-200:hover {
  fill: #eeeeee !important; }

.str-grey-200, .strH-grey-200:hover {
  stroke: #eeeeee !important; }

.c-grey-300, .cH-grey-300:hover {
  color: #e0e0e0 !important; }

.bgc-grey-300, .bgcH-grey-300:hover {
  background-color: #e0e0e0 !important; }

.bdc-grey-300, .bdcH-grey-300:hover {
  border-color: #e0e0e0 !important; }

.fill-grey-300, .fillH-grey-300:hover {
  fill: #e0e0e0 !important; }

.str-grey-300, .strH-grey-300:hover {
  stroke: #e0e0e0 !important; }

.c-grey-400, .cH-grey-400:hover {
  color: #bdbdbd !important; }

.bgc-grey-400, .bgcH-grey-400:hover {
  background-color: #bdbdbd !important; }

.bdc-grey-400, .bdcH-grey-400:hover {
  border-color: #bdbdbd !important; }

.fill-grey-400, .fillH-grey-400:hover {
  fill: #bdbdbd !important; }

.str-grey-400, .strH-grey-400:hover {
  stroke: #bdbdbd !important; }

.c-grey-500, .cH-grey-500:hover {
  color: #9e9e9e !important; }

.bgc-grey-500, .bgcH-grey-500:hover {
  background-color: #9e9e9e !important; }

.bdc-grey-500, .bdcH-grey-500:hover {
  border-color: #9e9e9e !important; }

.fill-grey-500, .fillH-grey-500:hover {
  fill: #9e9e9e !important; }

.str-grey-500, .strH-grey-500:hover {
  stroke: #9e9e9e !important; }

.c-grey-600, .cH-grey-600:hover {
  color: #757575 !important; }

.bgc-grey-600, .bgcH-grey-600:hover {
  background-color: #757575 !important; }

.bdc-grey-600, .bdcH-grey-600:hover {
  border-color: #757575 !important; }

.fill-grey-600, .fillH-grey-600:hover {
  fill: #757575 !important; }

.str-grey-600, .strH-grey-600:hover {
  stroke: #757575 !important; }

.c-grey-700, .cH-grey-700:hover {
  color: #616161 !important; }

.bgc-grey-700, .bgcH-grey-700:hover {
  background-color: #616161 !important; }

.bdc-grey-700, .bdcH-grey-700:hover {
  border-color: #616161 !important; }

.fill-grey-700, .fillH-grey-700:hover {
  fill: #616161 !important; }

.str-grey-700, .strH-grey-700:hover {
  stroke: #616161 !important; }

.c-grey-800, .cH-grey-800:hover {
  color: #424242 !important; }

.bgc-grey-800, .bgcH-grey-800:hover {
  background-color: #424242 !important; }

.bdc-grey-800, .bdcH-grey-800:hover {
  border-color: #424242 !important; }

.fill-grey-800, .fillH-grey-800:hover {
  fill: #424242 !important; }

.str-grey-800, .strH-grey-800:hover {
  stroke: #424242 !important; }

.c-grey-900, .cH-grey-900:hover {
  color: #212121 !important; }

.bgc-grey-900, .bgcH-grey-900:hover {
  background-color: #212121 !important; }

.bdc-grey-900, .bdcH-grey-900:hover {
  border-color: #212121 !important; }

.fill-grey-900, .fillH-grey-900:hover {
  fill: #212121 !important; }

.str-grey-900, .strH-grey-900:hover {
  stroke: #212121 !important; }

.c-blue-grey-50, .cH-blue-grey-50:hover {
  color: #eceff1 !important; }

.bgc-blue-grey-50, .bgcH-blue-grey-50:hover {
  background-color: #eceff1 !important; }

.bdc-blue-grey-50, .bdcH-blue-grey-50:hover {
  border-color: #eceff1 !important; }

.fill-blue-grey-50, .fillH-blue-grey-50:hover {
  fill: #eceff1 !important; }

.str-blue-grey-50, .strH-blue-grey-50:hover {
  stroke: #eceff1 !important; }

.c-blue-grey-100, .cH-blue-grey-100:hover {
  color: #cfd8dc !important; }

.bgc-blue-grey-100, .bgcH-blue-grey-100:hover {
  background-color: #cfd8dc !important; }

.bdc-blue-grey-100, .bdcH-blue-grey-100:hover {
  border-color: #cfd8dc !important; }

.fill-blue-grey-100, .fillH-blue-grey-100:hover {
  fill: #cfd8dc !important; }

.str-blue-grey-100, .strH-blue-grey-100:hover {
  stroke: #cfd8dc !important; }

.c-blue-grey-200, .cH-blue-grey-200:hover {
  color: #b0bec5 !important; }

.bgc-blue-grey-200, .bgcH-blue-grey-200:hover {
  background-color: #b0bec5 !important; }

.bdc-blue-grey-200, .bdcH-blue-grey-200:hover {
  border-color: #b0bec5 !important; }

.fill-blue-grey-200, .fillH-blue-grey-200:hover {
  fill: #b0bec5 !important; }

.str-blue-grey-200, .strH-blue-grey-200:hover {
  stroke: #b0bec5 !important; }

.c-blue-grey-300, .cH-blue-grey-300:hover {
  color: #90a4ae !important; }

.bgc-blue-grey-300, .bgcH-blue-grey-300:hover {
  background-color: #90a4ae !important; }

.bdc-blue-grey-300, .bdcH-blue-grey-300:hover {
  border-color: #90a4ae !important; }

.fill-blue-grey-300, .fillH-blue-grey-300:hover {
  fill: #90a4ae !important; }

.str-blue-grey-300, .strH-blue-grey-300:hover {
  stroke: #90a4ae !important; }

.c-blue-grey-400, .cH-blue-grey-400:hover {
  color: #78909c !important; }

.bgc-blue-grey-400, .bgcH-blue-grey-400:hover {
  background-color: #78909c !important; }

.bdc-blue-grey-400, .bdcH-blue-grey-400:hover {
  border-color: #78909c !important; }

.fill-blue-grey-400, .fillH-blue-grey-400:hover {
  fill: #78909c !important; }

.str-blue-grey-400, .strH-blue-grey-400:hover {
  stroke: #78909c !important; }

.c-blue-grey-500, .cH-blue-grey-500:hover {
  color: #607d8b !important; }

.bgc-blue-grey-500, .bgcH-blue-grey-500:hover {
  background-color: #607d8b !important; }

.bdc-blue-grey-500, .bdcH-blue-grey-500:hover {
  border-color: #607d8b !important; }

.fill-blue-grey-500, .fillH-blue-grey-500:hover {
  fill: #607d8b !important; }

.str-blue-grey-500, .strH-blue-grey-500:hover {
  stroke: #607d8b !important; }

.c-blue-grey-600, .cH-blue-grey-600:hover {
  color: #546e7a !important; }

.bgc-blue-grey-600, .bgcH-blue-grey-600:hover {
  background-color: #546e7a !important; }

.bdc-blue-grey-600, .bdcH-blue-grey-600:hover {
  border-color: #546e7a !important; }

.fill-blue-grey-600, .fillH-blue-grey-600:hover {
  fill: #546e7a !important; }

.str-blue-grey-600, .strH-blue-grey-600:hover {
  stroke: #546e7a !important; }

.c-blue-grey-700, .cH-blue-grey-700:hover {
  color: #455a64 !important; }

.bgc-blue-grey-700, .bgcH-blue-grey-700:hover {
  background-color: #455a64 !important; }

.bdc-blue-grey-700, .bdcH-blue-grey-700:hover {
  border-color: #455a64 !important; }

.fill-blue-grey-700, .fillH-blue-grey-700:hover {
  fill: #455a64 !important; }

.str-blue-grey-700, .strH-blue-grey-700:hover {
  stroke: #455a64 !important; }

.c-blue-grey-800, .cH-blue-grey-800:hover {
  color: #37474f !important; }

.bgc-blue-grey-800, .bgcH-blue-grey-800:hover {
  background-color: #37474f !important; }

.bdc-blue-grey-800, .bdcH-blue-grey-800:hover {
  border-color: #37474f !important; }

.fill-blue-grey-800, .fillH-blue-grey-800:hover {
  fill: #37474f !important; }

.str-blue-grey-800, .strH-blue-grey-800:hover {
  stroke: #37474f !important; }

.c-blue-grey-900, .cH-blue-grey-900:hover {
  color: #263238 !important; }

.bgc-blue-grey-900, .bgcH-blue-grey-900:hover {
  background-color: #263238 !important; }

.bdc-blue-grey-900, .bdcH-blue-grey-900:hover {
  border-color: #263238 !important; }

.fill-blue-grey-900, .fillH-blue-grey-900:hover {
  fill: #263238 !important; }

.str-blue-grey-900, .strH-blue-grey-900:hover {
  stroke: #263238 !important; }

.c-grey-100, .cH-grey-100:hover {
  color: #f9fafb !important; }

.bgc-grey-100, .bgcH-grey-100:hover {
  background-color: #f9fafb !important; }

.bdc-grey-100, .bdcH-grey-100:hover {
  border-color: #f9fafb !important; }

.fill-grey-100, .fillH-grey-100:hover {
  fill: #f9fafb !important; }

.str-grey-100, .strH-grey-100:hover {
  stroke: #f9fafb !important; }

.c-grey-200, .cH-grey-200:hover {
  color: #f2f3f5 !important; }

.bgc-grey-200, .bgcH-grey-200:hover {
  background-color: #f2f3f5 !important; }

.bdc-grey-200, .bdcH-grey-200:hover {
  border-color: #f2f3f5 !important; }

.fill-grey-200, .fillH-grey-200:hover {
  fill: #f2f3f5 !important; }

.str-grey-200, .strH-grey-200:hover {
  stroke: #f2f3f5 !important; }

.c-grey-300, .cH-grey-300:hover {
  color: #e6eaf0 !important; }

.bgc-grey-300, .bgcH-grey-300:hover {
  background-color: #e6eaf0 !important; }

.bdc-grey-300, .bdcH-grey-300:hover {
  border-color: #e6eaf0 !important; }

.fill-grey-300, .fillH-grey-300:hover {
  fill: #e6eaf0 !important; }

.str-grey-300, .strH-grey-300:hover {
  stroke: #e6eaf0 !important; }

.c-grey-400, .cH-grey-400:hover {
  color: #d3d9e3 !important; }

.bgc-grey-400, .bgcH-grey-400:hover {
  background-color: #d3d9e3 !important; }

.bdc-grey-400, .bdcH-grey-400:hover {
  border-color: #d3d9e3 !important; }

.fill-grey-400, .fillH-grey-400:hover {
  fill: #d3d9e3 !important; }

.str-grey-400, .strH-grey-400:hover {
  stroke: #d3d9e3 !important; }

.c-grey-500, .cH-grey-500:hover {
  color: #b9c2d0 !important; }

.bgc-grey-500, .bgcH-grey-500:hover {
  background-color: #b9c2d0 !important; }

.bdc-grey-500, .bdcH-grey-500:hover {
  border-color: #b9c2d0 !important; }

.fill-grey-500, .fillH-grey-500:hover {
  fill: #b9c2d0 !important; }

.str-grey-500, .strH-grey-500:hover {
  stroke: #b9c2d0 !important; }

.c-grey-600, .cH-grey-600:hover {
  color: #7c8695 !important; }

.bgc-grey-600, .bgcH-grey-600:hover {
  background-color: #7c8695 !important; }

.bdc-grey-600, .bdcH-grey-600:hover {
  border-color: #7c8695 !important; }

.fill-grey-600, .fillH-grey-600:hover {
  fill: #7c8695 !important; }

.str-grey-600, .strH-grey-600:hover {
  stroke: #7c8695 !important; }

.c-grey-700, .cH-grey-700:hover {
  color: #72777a !important; }

.bgc-grey-700, .bgcH-grey-700:hover {
  background-color: #72777a !important; }

.bdc-grey-700, .bdcH-grey-700:hover {
  border-color: #72777a !important; }

.fill-grey-700, .fillH-grey-700:hover {
  fill: #72777a !important; }

.str-grey-700, .strH-grey-700:hover {
  stroke: #72777a !important; }

.c-grey-800, .cH-grey-800:hover {
  color: #565a5c !important; }

.bgc-grey-800, .bgcH-grey-800:hover {
  background-color: #565a5c !important; }

.bdc-grey-800, .bdcH-grey-800:hover {
  border-color: #565a5c !important; }

.fill-grey-800, .fillH-grey-800:hover {
  fill: #565a5c !important; }

.str-grey-800, .strH-grey-800:hover {
  stroke: #565a5c !important; }

.c-grey-900, .cH-grey-900:hover {
  color: #313435 !important; }

.bgc-grey-900, .bgcH-grey-900:hover {
  background-color: #313435 !important; }

.bdc-grey-900, .bdcH-grey-900:hover {
  border-color: #313435 !important; }

.fill-grey-900, .fillH-grey-900:hover {
  fill: #313435 !important; }

.str-grey-900, .strH-grey-900:hover {
  stroke: #313435 !important; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 320px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd; }

.react-autosuggest__suggestion--highlighted {
  background-color: #0C7EAF;
  color: #fff; }

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat; }

.sticky-top {
  top: 5.5em; }
